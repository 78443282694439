/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import { Box, Paper, Alert } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { HeaderList } from '../../components/HeaderList';
import Layout from '../../components/Layout';
import ModalLoading from '../../components/ModalLoading';
import { apiEcotechConstancy } from '../../api/modules/EcotechConstancy';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import Caso1 from './caso1';
import Caso2 from './caso2';
import Caso3 from './caso3';
import { IResponse, IDocument } from '../../interfaces/EcotechConstancy';

const EcotechConstancy = () => {
	const { user, fontSizeLevel } = useSelector((state: RootState) => state.session);
	const { credit } = useSelector((state: RootState) => state.credit);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<IResponse>({});
	const [pdf, setPdf] = useState<IDocument>({});
	const [error, setError] = useState('');
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	useEffect(() => {
		const req = async () => {
			try {
				setLoading(true);
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const nss_encript = await encrypt(
					user?.nss.toString() || '',
					key
				);
				const credito_encript = await encrypt(
					credit.toString() || '',
					key
				);
				const validResponse = await apiEcotechConstancy.getValid(
					nss_encript
				);
				const response = await apiEcotechConstancy.getValidDocument(
					credito_encript
				);
				if (validResponse?.result) {
					setData(validResponse.result);
					setPdf(response?.result || '');
					setError('');
				} else {
					setError(
						validResponse?.error?.description ||
						INTERNAL_MESSAGES.ERROR_RETRIEVING_DATA
					);
				}
			} catch (erno: any) {
				setError(INTERNAL_MESSAGES.ERROR_RETRIEVING_DATA);
			} finally {
				setLoading(false);
			}
		};
		req();
	}, [user, credit]);

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<Layout updateTagText={updateTagText}>
			<Box ref={divRef}>
				<HeaderList title="Constancia de crédito para ecotecnologías" />
				<ModalLoading loading={loading} />
				<Paper>
					{error && (
						<>
							<h3 style={{ padding: '0px 40px', paddingTop: 40 }}>
								Aspectos importantes
							</h3>
							<div style={{ padding: 20 }}>
								<Alert severity="error">
									<label>{error}</label>
								</Alert>
							</div>
						</>
					)}

					{['1', '2'].includes(data?.pantalla || '') && (
						<Caso1 data={data} pdf={pdf} />
					)}

					{['3'].includes(data?.pantalla || '') && (
						<Caso2 data={data} pdf={pdf} />
					)}

					{['0'].includes(data?.pantalla || '') && (
						<Caso3 data={data} />
					)}
				</Paper>
			</Box>
		</Layout>
	);
};

export default EcotechConstancy;
