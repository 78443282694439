// RESOURCES
import { Box } from '@mui/material';
// COMPONENTS
import CustomButton from '../../../components/Button';
// ASSETS
import { useStyles } from './styles';
import { useNavigate } from 'react-router-dom';
import ModalLoading from '../../../components/ModalLoading';
import CustomAlert from '../../../components/CustomAlert';
import imgTel from '../../../assets/img/ima-socio-infonavit-img.jpg';
import TextInput from '../../../components/TextInput';
import { useConfirmPassword } from './hooks/useConfirmPassword';
import { IConfirmPasswordProps } from './interface/props-confirm-password.interface';

const ConfirmPassword = (props: IConfirmPasswordProps) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const {
		getInformationDevice,
		loading,
		setPassword,
		alert,
		password
	} = useConfirmPassword(props);
	return (
		<>
			<ModalLoading loading={loading} />
			<div className={classes.container}>
				<div className={classes.containerMin}>
					<div className={classes.containerInfoPassword}>
						<p className={classes.maxContent}>
							Este servicio te permitirá desvincular tu
							dispositivo móvil que tienes asociado a tu número de
							seguridad social (NSS). Recuerda que para actualizar
							la aplicación móvil deberás volver a asociar tu
							nuevo dispositivo.
						</p>
						<p className={classes.maxContent}>
							Para tu seguridad deberás confirmar tu contraseña de
							Mi Cuenta Infonavit:
						</p>
					</div>
					<div className={classes.containerInfoImage}>
						<img src={imgTel} alt="img-tel"></img>
					</div>
				</div>

				<Box>
					<label>Confirma contraseña</label>
					<TextInput
						data-testid="inputCurrentPassword"
						id="currentPassword"
						name="currentPassword"
						type="password"
						onChange={(e) => setPassword(e.target.value)}
						value={password}
					/>
				</Box>

				<CustomAlert
					show={alert.show}
					severity={alert.severity}
					message={alert.message}
				/>

				<Box className={classes.containerButtons}>
					<div className={classes.custombuttom}>
						<CustomButton
							label={'Salir'}
							onClick={() => navigate('/')}
							styles={{ width: '260px', height: '100%' }}
							variant={'outlined'}
						/>
					</div>
					<div className={classes.custombuttom}>
						<CustomButton
							label={'Continuar'}
							onClick={getInformationDevice}
							styles={{ width: '260px', height: '100%' }}
							variant={'solid'}
							disabled={password === '' ? true : false}
						/>
					</div>
				</Box>
			</div>
		</>
	);
};

export default ConfirmPassword;
