/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Grid } from '@mui/material';
import { useStyles } from './styles';

import { useNavigate } from 'react-router-dom';
//Assets
import imgAltanto from '../../../assets/img/mantente-al-tanto.png';
import imgSolicitaDev from '../../../assets/img/solicita-dev-banner1.png';
import imgCartaCan from '../../../assets/img/banner-carta-canelacion.png';
import imgAvisoRetencion from '../../../assets/img/aviso-retencion-grande-banner.jpg';
// import iconoPeso from '../../../assets/img/simbolo-peso.png';

// import ViewMoreButton from '../components/viewMoreButton';

const AccreditedHistory = () => {
	const classes: any = useStyles();
	const navigate = useNavigate();

	return (
		<>
			<Grid container spacing={2}>
				<Grid item sm={12} md={12} lg={4}>
					<Paper className={classes.card}>
						<div className={classes.titleCard}>
							<h3>
								¡Felicidades por terminar de pagar tu crédito!
							</h3>
						</div>
						<div className={classes.textCard}>
							<p>
								Si por algún motivo se realizaron pagos de más
								a tu crédito, solicita la devolución de Pagos
								en exceso.
							</p>
							<p>
								Recuerda solicitar tu Carta de Cancelación de
								Hipoteca para liberar tu crédito con el notario.
							</p>
							<p>
								Continúa trabajando para generar más ingresos
								al ahorro en tu Subcuenta de Vivienda.
							</p>
						</div>
					</Paper>
				</Grid>

				<Grid item sm={12} md={6} lg={4}>
					<Grid item lg={12} style={{ height: '100%' }}>
						<div
							onClick={() =>
								navigate('/mi-credito/saldos-movimientos')
							}
						>
							<img
								src={imgAltanto}
								className={classes.imageStyle}
								alt="Mantente al tanto con toda la información de tu saldo y movimientos de tu credito"
							/>
						</div>
					</Grid>
				</Grid>

				<Grid item md={6} lg={4} style={{ height: '100%' }}>
					<Grid container spacing={2}>
						<Grid item sm={12} md={12} lg={12}>
							<div
								onClick={() =>
									navigate(
										'/mi-credito/devolucion-pagos-exceso'
									)
								}
							>
								<img
									src={imgSolicitaDev}
									className={classes.imageStyle}
									alt="Devolución de pagos en exceso"
								/>
							</div>
						</Grid>

						<Grid item sm={12} md={12} lg={12}>
							<div
								onClick={() =>
									navigate(
										'/mi-credito/carta-cancelacion-hipoteca'
									)
								}
							>
								<img
									src={imgCartaCan}
									className={classes.imageStyle}
									alt="carta de cancelación de hipoteca"
								/>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Grid container justifyContent="flex-end" mt={2}>
				<Grid item sm={12} lg={8}>
					<div
						onClick={() =>
							navigate(
								'/mi-credito/avisos-suspension-retencion-modificacion-descuentos'
							)
						}
					>
						<img
							src={imgAvisoRetencion}
							className={classes.imageStyle}
							alt="Aviso de retención, modificación y/o suspensión"
						/>
					</div>
				</Grid>
			</Grid>
		</>
	);
};

export default AccreditedHistory;
