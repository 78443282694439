import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	titleNoticeContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
	},
	titleNotice: {
		margin: 0,
		fontSize: 22,
		color: '#293990',
	},
	subTitleNotice: {
		fontSize: 12,
		color: '#333333',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			textAlign: 'right',
			marginTop: 15,
		},
	},
	container: {
		borderRadius: 6,
		backgroundColor: 'white',
		padding: 20,
		paddingBlock: 50,
		marginTop: 30,
		boxShadow: '0px 5px 30px #00000017',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
	containerMin: {
		maxWidth: 900,
		display: 'flex',
		marginTop: '40px',
		marginBottom: '40px',
		[theme.breakpoints.down('md')]: {
			justifyContent: 'center',
		},
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap'
		},
	},
	phoneImage: {
		width: '90%',
		[theme.breakpoints.down('sm')]: {
			width: '250px',
			height: '250px',
		},
	},
	containerImage: {
		width: '50%',
		display: 'flex',
		justifyContent: 'center',
		paddingLeft: '20%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			paddingLeft: '0%',
		},
	},
	containerInfo: {
		width: '50%',
		paddingTop: '30px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	txtContainer: {
		width: '100%',
		maxWidth: 'max-content',
		color: '#000000',
		fontSize: 18,
		letterSpacing: 0,
		margin: 0,
		marginBottom: 5,
		[theme.breakpoints.down('sm')]: {
			fontSize: 16,
			margin: '0 auto'
		},
	},
	normalText: {
		width: '100%',
		maxWidth: 'max-content',
		color: '#7F7F7F',
		fontSize: 16,
		marginTop: 0,
		[theme.breakpoints.down('sm')]: {
			margin: '0 auto'
		},
	},
	containerButtons: {
		display: 'flex',
		justifyContent: 'space-around',
		width: '600px',
		margin: '30px auto',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
	custombuttom: {
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginBottom: '15px',
			display: 'flex',
			justifyContent: 'center',
		},
	},
}));
