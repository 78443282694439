/* eslint-disable react-hooks/exhaustive-deps */
import {
	Grid,
	FormControlLabel,
	Checkbox,
	RadioGroup,
	Radio,
	Box,
} from '@mui/material';
import { useStyles } from '../Register/styles';
import { useTheme } from '@mui/material/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import BaseModal from '../../components/Modal';
import CustomButton from '../../components/Button';
import TextInput from '../../components/TextInput';

import CustomAlert from '../../components/CustomAlert';
import TermsAndConditions from './termsAndConditions';
import PrivacyTerms from './privacy';
import ModalLoading from '../../components/ModalLoading';
import cel from '../../assets/img/cel.png';
import EmailConfirmation2 from '../Profile/Contact/ContactInformation/component/EmailConfirmation2';

import {
	IContactData,
} from './interfaces/register.interface';
import { useContactDataRegister } from './hooks/useContactDataRegister';

const ContactData = (props: IContactData) => {
	const theme = useTheme();
	const classes = useStyles();

	const {
		setData,
		handleOnChangeRadioButton,
		handleOnChangeModalButtonPriv,
		handleOnChangeModalButtonTerm,
		handleValidateEmail,
		handleValidatePhone,
		initialConfirmPhone,
		loader,
		errorValidationPhone,
		errorValidationEmail,
		credentials,
		phoneValidate,
		phone1Validate,
		emailValidate,
		email1Validate,
		hasError,
		buttonValidate,
		alert,
		modalOpenPriv,
		setModalButtonPriv,
		setModalOpenPriv,
		modalOpenTerm,
		setModalOpenTerm,
		setModalButtonTerm,
		onContinue,
		onCloseError,
		secondConfirmEmail,
		modalButtonPriv,
		modalButtonTerm,
	} = useContactDataRegister(props)
	return (
		<div style={{ width: '100%' }}>
			<ModalLoading loading={loader} />
			{!errorValidationPhone && !errorValidationEmail && (
				<Grid container className={classes.container} spacing={0}>
					<Grid item xs={0} sm={0} md={3} lg={3}></Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<div className={classes.containerInput}>
							<TextInput
								id="phone"
								name="phone"
								label="Número de celular"
								value={credentials.phone}
								error={!phoneValidate.valid}
								helperText={
									!phoneValidate.valid
										? phoneValidate.msg
										: ''
								}
								size="small"
								isRequired={true}
								onChange={setData}
								onBlur={handleValidatePhone}
								noCopy={true}
								noPaste={true}
							/>
						</div>
						<div className={classes.containerInput}>
							<TextInput
								id="phone1"
								name="phone1"
								label="Confirma tu número de celular"
								value={credentials.phone1}
								error={!phone1Validate.valid}
								helperText={
									!phone1Validate.valid
										? phone1Validate.msg
										: ''
								}
								size="small"
								isRequired={true}
								onChange={setData}
								disabled={!phoneValidate.valid}
								noCopy={true}
								noPaste={true}
							/>
						</div>
						<div className={classes.containerInput}>
							<TextInput
								id="email"
								name="email"
								label="Correo electrónico"
								value={credentials.email}
								error={!emailValidate.valid}
								helperText={
									!emailValidate.valid
										? emailValidate.msg
										: ''
								}
								size="small"
								isRequired={true}
								onChange={setData}
								onBlur={handleValidateEmail}
								disabled={!phoneValidate.valid}
								noCopy={true}
								noPaste={true}
							/>
						</div>
						<div className={classes.containerInput}>
							<TextInput
								id="email1"
								name="email1"
								label="Confirma tu correo electrónico"
								value={credentials.email1}
								error={!email1Validate.valid}
								helperText={
									!email1Validate.valid
										? email1Validate.msg
										: ''
								}
								size="small"
								isRequired={true}
								onChange={setData}
								disabled={!emailValidate.valid}
								noCopy={true}
								noPaste={true}
							/>
						</div>
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							name="radio-buttons-group"
						>
							<p>
								¿Por cuál medio te gustaría que el Infonavit te
								mantuviera informado?
							</p>
							<FormControlLabel
								sx={{
									marginLeft: '20px',
									[theme.breakpoints.down('md')]: {
										marginLeft: 0,
									},
								}}
								control={
									<Radio
										onChange={handleOnChangeRadioButton}
										name="Por celular"
									/>
								}
								label="Por celular"
								value="celular"
							/>
							<FormControlLabel
								sx={{
									marginLeft: '20px',
									[theme.breakpoints.down('md')]: {
										marginLeft: 0,
									},
								}}
								control={
									<Radio
										onChange={handleOnChangeRadioButton}
										name="Por correo electrónico"
									/>
								}
								label="Por correo electrónico"
								value="email"
							/>
						</RadioGroup>
						<div className={classes.buttonCenter}>
							<div className={classes.movilButton}>
								<div style={{ width: '100%' }}>
									<CustomAlert
										show={hasError.show}
										severity={hasError.severity}
										message={hasError.message}
										onClose={onCloseError}
									/>
								</div>
								<CustomButton
									label="Continuar"
									onClick={() => initialConfirmPhone()}
									variant="solid"
									disabled={buttonValidate}
								/>
							</div>
						</div>
						<br />
					</Grid>
					<Grid item xs={0} sm={0} md={3} lg={3}></Grid>
				</Grid>
			)}
			{errorValidationPhone && (
				<div>
					<Box className={classes.VerificacionLogo}>
						<img alt="Icono de celular" src={cel} />
					</Box>
					<Box
						sx={{
							alignItems: 'center',
							justifyContent: 'center',
							display: 'flex',
							marginTop: '20px',
						}}
					>
						<label className={classes.VerificacionCodigoTitle}>
							<strong>Confirmación de número celular</strong>
						</label>
					</Box>
					<Box
						sx={{
							alignItems: 'center',
							justifyContent: 'center',
							display: 'flex',
							marginBottom: '35px',
						}}
					>
						<label className={classes.VerificacionText}>
							<strong>
								El número de teléfono celular que nos
								proporcionaste no existe, por favor actualízalo{' '}
								<br />
								para continuar con tu registro.
							</strong>
						</label>
					</Box>
					<div className={classes.numbersVerification}>
						<div className={classes.containerInput}>
							<TextInput
								id="phone"
								name="phone"
								label="Número de celular"
								value={credentials.phone}
								error={!phoneValidate.valid}
								helperText={
									!phoneValidate.valid
										? phoneValidate.msg
										: ''
								}
								size="small"
								isRequired={true}
								onChange={setData}
								onBlur={handleValidatePhone}
								noCopy={true}
								noPaste={true}
							/>
						</div>
						<div className={classes.containerInput}>
							<TextInput
								id="phone1"
								name="phone1"
								label="Confirma tu número de celular"
								value={credentials.phone1}
								error={!phone1Validate.valid}
								helperText={
									!phone1Validate.valid
										? phone1Validate.msg
										: ''
								}
								size="small"
								isRequired={true}
								onChange={setData}
								disabled={!phoneValidate.valid}
								noCopy={true}
								noPaste={true}
							/>
						</div>
						<div className={classes.buttonCenter}>
							<div className={classes.movilButton}>
								<CustomButton
									label="Validar"
									onClick={() => initialConfirmPhone()}
									variant="solid"
									disabled={
										!phoneValidate.valid ||
										credentials.phone !== credentials.phone1
									}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
			{errorValidationEmail && (
				<>
					<div style={{ marginTop: '-20px' }}></div>
					<EmailConfirmation2 />
					<div className={classes.numbersVerification}>
						<div className={classes.containerInput}>
							<TextInput
								id="email"
								name="email"
								label="Correo electrónico"
								value={credentials.email}
								error={!emailValidate.valid}
								helperText={
									!emailValidate.valid
										? emailValidate.msg
										: ''
								}
								size="small"
								isRequired={true}
								onChange={setData}
								onBlur={handleValidateEmail}
								disabled={!phoneValidate.valid}
								noCopy={true}
								noPaste={true}
							/>
						</div>
						<div className={classes.containerInput}>
							<TextInput
								id="email1"
								name="email1"
								label="Confirma tu correo electrónico"
								value={credentials.email1}
								error={!email1Validate.valid}
								helperText={
									!email1Validate.valid
										? email1Validate.msg
										: ''
								}
								size="small"
								isRequired={true}
								onChange={setData}
								disabled={!emailValidate.valid}
								noCopy={true}
								noPaste={true}
							/>
						</div>
						<div className={classes.buttonCenter}>
							<div className={classes.movilButton}>
								<CustomButton
									label="Validar"
									onClick={() => secondConfirmEmail()}
									variant="solid"
									disabled={
										!emailValidate.valid ||
										credentials.email !== credentials.email1
									}
								/>
							</div>
						</div>
					</div>
				</>
			)}
			<div className={classes.containerAlert}>
				<CustomAlert
					show={Boolean(alert.message)}
					severity={alert.severity}
					message={alert.message}
				/>
			</div>
			<BaseModal
				open={modalOpenPriv}
				title="Aviso de privacidad"
				width="md"
				onClose={() => {
					setModalButtonPriv(true);
					setModalOpenPriv(false);
				}}
				children={
					<>
						<PrivacyTerms />
						<div className={classes.dialogCheck}>
							<FormControlLabel
								control={
									<Checkbox
										className={classes.formControlLabel}
										icon={<CheckBoxOutlineBlankIcon />}
										checkedIcon={<CheckBoxIcon />}
										onChange={handleOnChangeModalButtonPriv}
										name="checkModal"
									/>
								}
								style={{
									color: !modalButtonPriv
										? theme.palette.primary.main
										: 'black',
								}}
								label="Acepto el Aviso de privacidad"
								value="checkAviso"
							/>
						</div>
						<div className={classes.dialogButtonGroup}>
							<div className={classes.dialogButtonRight}>
								<CustomButton
									label="Cancelar"
									onClick={() => {
										setModalButtonPriv(true);
										setModalOpenPriv(false);
									}}
									variant="outlined"
									styles={{ marginTop: 10 }}
								/>
							</div>
							<div className={classes.dialogButtonRight}>
								<CustomButton
									label="Continuar"
									onClick={() => {
										setModalButtonPriv(true);
										setModalOpenPriv(false);
										setModalOpenTerm(true);
									}}
									variant="solid"
									disabled={modalButtonPriv}
									styles={{ marginTop: 10 }}
								/>
							</div>
						</div>
					</>
				}
			/>
			<BaseModal
				open={modalOpenTerm}
				title="Términos y condiciones"
				width="md"
				onClose={() => {
					setModalOpenTerm(false);
					setModalButtonTerm(true);
				}}
				children={
					<>
						<TermsAndConditions />
						<div className={classes.dialogCheck}>
							<FormControlLabel
								control={
									<Checkbox
										className={classes.formControlLabel}
										icon={<CheckBoxOutlineBlankIcon />}
										checkedIcon={<CheckBoxIcon />}
										onChange={handleOnChangeModalButtonTerm}
										name="checkModal"
									/>
								}
								style={{
									color: !modalButtonTerm
										? theme.palette.primary.main
										: 'black',
								}}
								label="Acepto términos y condiciones"
								value="checkAviso"
							/>
						</div>
						<div className={classes.dialogButtonGroup}>
							<div className={classes.dialogButtonRight}>
								<CustomButton
									label="Cancelar"
									onClick={() => {
										setModalButtonTerm(true);
										setModalOpenTerm(false);
									}}
									variant="outlined"
									styles={{ marginTop: 10 }}
								/>
							</div>
							<div className={classes.dialogButtonRight}>
								<CustomButton
									label="Continuar"
									onClick={() => {
										setModalOpenTerm(false);
										setModalButtonTerm(true);
										onContinue();
									}}
									variant="solid"
									disabled={modalButtonTerm}
									styles={{ marginTop: 10 }}
								/>
							</div>
						</div>
					</>
				}
			/>
		</div>
	);
};

export default ContactData;
