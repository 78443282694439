import { useState, useEffect } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { CheckCircleOutlined } from '@mui/icons-material';
import { Grid } from '@mui/material';
import TextInput from '../../components/TextInput';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useStyles } from '../NipInfonatel/styles';
import CustomButtom from '../../components/Button';
import { nipInfonatelAPI } from '../../api/modules/NipInfonatel';
import { IAlert } from '../../interfaces/alert';
import CustomAlert from '../../components/CustomAlert';
import ModalLoading from '../../components/ModalLoading';
import { encrypt } from '../../utils/encrypt';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { isNumber } from '../../utils/expressions';
import { useBitacora } from '../../hooks/useBitacora';

interface IPersonalData {
	onContinue: () => void;
	typeInformation: number;
	validations: any;
	nipsRecents: any;
}
const NipGenaration = (props: IPersonalData) => {
	const { onContinue, typeInformation, validations, nipsRecents } = props;
	const { regBitacoraNotOld } = useBitacora();
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const navigate = useNavigate();
	const [nip, setNip] = useState('' as any);
	const [confirmaNip, setConfirmaNip] = useState<number | string>('');
	const [loading, setLoading] = useState(false);
	const style = useStyles();
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'error',
	});
	const [consecutiveAlert, setConsecutiveAlert] = useState(false);
	const [nipMismatchAlert, setNipMismatchAlert] = useState(false);
	const { user } = useSelector((state: RootState) => state.session);

	useEffect(() => {
		if (consecutiveAlert) {
			setAlert({
				show: true,
				message: 'No puedes escribir números consecutivos',
				severity: 'error',
			});
		} else if ((nip && nip.toString().length < 4) || (confirmaNip && confirmaNip.toString().length < 4)) {
			setAlert({
				show: true,
				message: 'Para continuar debes proporcionar un NIP de 4 números.',
				severity: 'error',
			});
		} else if (nipMismatchAlert) {
			setAlert({
				show: true,
				message: 'La confirmación de NIP Infonatel no coincide, favor de verificarlo.',
				severity: 'error',
			});
		} else {
			setAlert({
				show: false,
				message: '',
				severity: 'error',
			});
		}
	}, [consecutiveAlert, nipMismatchAlert, nip, confirmaNip]);

	const hasConsecutiveDigits = (num: number | string) => {
		const digits = num.toString().split('').map(Number);
		for (let i = 0; i < digits.length - 1; i++) {
			if (
				digits[i] === digits[i + 1] + 1 ||
				digits[i] === digits[i + 1] - 1 ||
				digits[i] === digits[i + 1]
			) {
				return true;
			}
		}

		return false;
	};

	const disableBTN = () => {
		return (
			nip === '' ||
			confirmaNip === '' ||
			nip.toString().length !== 4 ||
			confirmaNip.toString().length !== 4 ||
			nip.toString() !== confirmaNip.toString() ||
			hasConsecutiveDigits(nip) ||
			hasConsecutiveDigits(confirmaNip) ||
			consecutiveAlert ||
			nipMismatchAlert
		);
	};

	const validateNipInput = (event: any) => {
		const { value } = event.target;
		const valueInt = parseInt(value);
		if (!isNaN(valueInt) && Number.isInteger(valueInt)) {
			if (value.toString().length < 5 && !hasConsecutiveDigits(value)) {
				setNip(value);
				setConsecutiveAlert(false); // Oculta la alerta de números consecutivos
				if (confirmaNip.toString().length === 4 && value.toString() !== confirmaNip.toString()) {
					setNipMismatchAlert(true); // Muestra la alerta de NIP no coincidente
				} else {
					setNipMismatchAlert(false); // Oculta la alerta de NIP no coincidente
				}
			} else if (value.toString().length < 5 && hasConsecutiveDigits(value)) {
				setConsecutiveAlert(true); // Muestra la alerta de números consecutivos
			}
		} else if (value === '') {
			setNip('');
			setConsecutiveAlert(false); // Oculta la alerta de números consecutivos
			setNipMismatchAlert(false); // Oculta la alerta de NIP no coincidente
		}
	};

	const validateConfirmNipInput = (event: any) => {
		const { value } = event.target;
		const valueInt = parseInt(value);
		if (!isNaN(valueInt) && Number.isInteger(valueInt)) {
			if (value.toString().length < 5 && !hasConsecutiveDigits(value)) {
				setConfirmaNip(value);
				setConsecutiveAlert(false); // Oculta la alerta de números consecutivos
				if (nip.toString().length === 4 && value.toString() !== nip.toString()) {
					setNipMismatchAlert(true); // Muestra la alerta de NIP no coincidente
				} else {
					setNipMismatchAlert(false); // Oculta la alerta de NIP no coincidente
				}
			} else if (value.toString().length < 5 && hasConsecutiveDigits(value)) {
				setConsecutiveAlert(true); // Muestra la alerta de números consecutivos
			}
		} else if (value === '') {
			setConfirmaNip('');
			setConsecutiveAlert(false); // Oculta la alerta de números consecutivos
			setNipMismatchAlert(false); // Oculta la alerta de NIP no coincidente
		}
	};

	const sendEmailConfirmation = async (serv: string, desc: string) => {
		try {
			setLoading(true);
			setAlert({
				...alert,
				show: false,
			});

			const data = {
				servicio: serv,
				descripcion: desc,
			};
			const dataEncripted = await encrypt(
				JSON.stringify(data),
				user?.k || ''
			);

			const { result } = await nipInfonatelAPI.emailConfirmation({
				data: dataEncripted,
			});

			if (result.code === 'EM0000') {
				onContinue();
			} else {
				setAlert({
					show: true,
					message: result.message || INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
			}
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const activateNip = async (nip: any) => {
		try {
			setLoading(true);
			setAlert({
				...alert,
				show: false,
			});

			const data = {
				nip_telefonico: nip.toString(),
			};

			const dataEncripted = await encrypt(
				JSON.stringify(data),
				user?.k || ''
			);
			const { result } = await nipInfonatelAPI.activeNip({
				data: dataEncripted,
			});

			if (Number(result.contenido.code) === 0) {
				const serv = 'Activación de NIP';
				const desc =
					'Tu NIP Infonatel ha sido activado exitosamente, continúa disfrutando de los servicios que Mi Cuenta Infonavit tiene para ti.';
				regBitacoraNotOld(nipInfonatelAPI.schemeUrl, data);
				sendEmailConfirmation(serv, desc);
			} else {
				setAlert({
					show: true,
					message: result.message || INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
				setLoading(false);
			}
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const changedNip = async (nip: any) => {
		try {
			setLoading(true);
			setAlert({
				...alert,
				show: false,
			});

			const data = {
				nip_telefonico: nip.toString(),
			};

			const dataEncripted = await encrypt(
				JSON.stringify(data),
				user?.k || ''
			);
			const { result } = await nipInfonatelAPI.changeNip({
				data: dataEncripted,
			});

			if (Number(result.contenido.code) === 0) {
				const serv = 'Cambio de NIP';
				const desc =
					'Tu NIP Infonatel ha sido cambiado exitosamente, continúa disfrutando de los servicios que Mi Cuenta Infonavit tiene para ti.';
				regBitacoraNotOld(nipInfonatelAPI.schemeUrl, data);
				sendEmailConfirmation(serv, desc);
			} else {
				setAlert({
					show: true,
					message: result.message || INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
				setLoading(false);
			}
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const handleContinue = () => {
		const validateNipData = validateNewNip(nip.toString());
		if (validateNipData) {
			if (typeInformation === 3) {
				changedNip(nip);
			} else {
				activateNip(nip);
			}
		}
	};

	const validateNewNip = (nip: any) => {
		const arrayValidations = validations;
		const arrayNipRecents = nipsRecents;
		let isValidate = false;
		if (!arrayValidations.includes(nip)) {
			if (!arrayNipRecents.includes(nip)) {
				setAlert({
					show: false,
					message: '',
					severity: 'error',
				})
				isValidate = true;
			} else {
				setAlert({
					show: true,
					message: 'Escribe tu NIP que no correspondan a los 3 últimos utilizados.',
					severity: 'error',
				})
				isValidate = false;
			}
		} else {
			setAlert({
				show: true,
				message: 'Escribe tu NIP que no correspondan a tu fecha de nacimiento MMDD, MMAA, DDAA, AAMM, DDMM.',
				severity: 'error',
			})
			isValidate = false;
		}
		return isValidate;
	}

	return (
		<>
			<ModalLoading loading={loading} />

			<Box className={style.BoxContainerInputs}>
				<Box className={style.nipGenerationText}>
					<h3 className={`${style.lineText} ${style.maxContent}`}>
						Debes crear un NIP Infonatel que cumpla con las siguientes características:
					</h3>
					<Box className={style.lineText}>
						<CheckCircleOutlined
							color="info"
							style={{ marginRight: 5, width: 22 }}
						/>{' '}
						<p className={`${style.parrafo} ${style.noMargin}`}>
							Debe de ser 4 números.
						</p>
					</Box>
					<Box className={style.lineText}>
						<CheckCircleOutlined
							color="info"
							style={{ marginRight: 5, width: 22 }}
						/>{' '}
						<p className={`${style.parrafo} ${style.noMargin}`}>
							Que no correspondan a tu fecha de nacimiento MMDD, MMAA, DDAA, AAMM, DDMM.
						</p>
					</Box>
					<Box className={style.lineText}>
						<CheckCircleOutlined
							color="info"
							style={{ marginRight: 5, width: 22 }}
						/>{' '}
						<p className={`${style.parrafo} ${style.noMargin}`}>Solo números.</p>
					</Box>
					<Box className={style.lineText}>
						<CheckCircleOutlined
							color="info"
							style={{ marginRight: 5, width: 22 }}
						/>{' '}
						<p className={`${style.parrafo} ${style.noMargin}`}>
							No más de 2 números consecutivos.
						</p>
					</Box>
					{typeInformation === 3 && (
						<Box className={style.lineText}>
							<CheckCircleOutlined
								color="info"
								style={{ marginRight: 5, width: 22 }}
							/>{' '}
							<p className={`${style.parrafo} ${style.noMargin}`}>
								No debe coincidir con los últimos tres utilizados.
							</p>
						</Box>
					)}
					{/* <p>Para crear tu NIP Infonatel deberás proporcionar el <b>Token Infonavit</b> generado desde la aplicación móvil Infonavit.</p> */}
				</Box>
				<TextInput
					id="Escribe tu NIP"
					name="Escribe tu NIP"
					label="Escribe tu NIP"
					placeholder="Escribe tu NIP"
					type="password"
					noCopy={true}
					onChange={(event: any) => {
						if (isNumber(event.target.value)) {
							validateNipInput(event);
						} else {
							return;
						}
					}}
					isRequired
					value={nip ?? ''}
					onFocus={() => {
						if (nip.length < 4) {
							setAlert({
								show: true,
								message: 'Para continuar debes proporcionar un NIP de 4 números.',
								severity: 'error',
							});
						}
					}}
				/>
				<TextInput
					id="Confirma tu NIP"
					name="Confirma tu NIP"
					label="Confirma tu NIP"
					placeholder="Confirma tu NIP"
					type="password"
					noPaste={true}
					onChange={(event: any) => {
						if (isNumber(event.target.value)) {
							validateConfirmNipInput(event)
						} else {
							return;
						}
					}}
					isRequired
					value={confirmaNip ?? ''}
				/>
			</Box>
			<Box className={style.lookConfirmationAlert}>
				<div style={{ width: '100%' }}>
					<CustomAlert
						message={alert.message}
						severity={alert.severity}
						show={alert.show}
					/>
				</div>
			</Box>
			<Box className={style.containerButtonsEnd}>
				<Box margin={'10px'} width={260}>
					<CustomButtom
						label={'Salir'}
						onClick={() => navigate('/')}
						variant="outlined"
						styles={{ height: '40px' }}
					/>
				</Box>
				<Box margin={'10px'} width={260}>
					<CustomButtom
						label={'Continuar'}
						onClick={handleContinue}
						variant="solid"
						disabled={disableBTN()}
					/>
				</Box>
			</Box>
			<Grid container columns={12}>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<hr
						style={{
							opacity: 0.4,
							marginTop: 50,
							marginBottom: 0,
						}}
					/>
					<h3
						className={style.maxContent}
						style={{
							marginLeft: matches ? 10 : 20,
							marginTop: 8,
							color: '#293990',
							fontSize: matches ? 16 : 18,
							paddingBottom: 15,
							textAlign: 'left',
						}}
					>
						*Datos obligatorios
					</h3>
				</Grid>
			</Grid>
		</>
	);
};

export default NipGenaration;
