import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	divider: {
		marginTop: 30,
		color: '#8A919C',
		height: '1px',
		opacity: '20%',
	},
	pBold: {
		color: '#293990',
		fontWeight: 500,
	},
	pBoldWouthColor: {
		fontWeight: 500,
	},
	boxButtons: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 4,
		flexDirection: 'row',

		[theme.breakpoints.down('md')]: {
			flexDirection: 'column-reverse',
		},
	},
	buttons: {
		width: 250,
		margin: '10px',

		[theme.breakpoints.down('md')]: {
			width: 'auto',
		},
	},
	boxPadding: {
		padding: '0px 10px',
	},
	boxMain: {
		padding: '0px 20px',
	},
	containerGrid: {
		maxWidth: 800,
		margin: '0px auto',
	},
}));
