import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	containerCalculator: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		padding: 25,
	},
	titleCalculator: {
		fontWeight: 'bold',
		fontSize: 18,
		width: '100%',
		maxWidth: 'max-content'
	},
	containerElementsCalculator: {
		display: 'flex',
		alignItems: 'flex-end',
		marginBottom: '10px !important'
	},
	containerTotals: {
		backgroundColor: '#F2F2F2',
		boxSizing: 'border-box',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingTop: 20,
		paddingBottom: 20,
		paddingLeft: 20,
		paddingRight: 20,
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 5,
			paddingRight: 5,
			minWidth: 0,
		},
	},
	containerCurrentSaving: {
		backgroundColor: '#FFFFFF',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		borderWidth: 1,
		borderColor: theme.palette.info.dark,
		borderStyle: 'solid',
		borderRadius: 5,
		minHeight: 90,
		minWidth: '100%',
		[theme.breakpoints.between('md', 'lg')]: {
			minWidth: 0,
		},
	},
	titleCurrentSaving: {
		fontSize: 14,
		fontWeight: 'bold',
		margin: 0,
	},
	titleRetirement: {
		fontSize: 14,
		fontWeight: 'bold',
		color: theme.palette.info.dark,
		margin: 0,
	},
	containerQtyCurrentSaving: {
		display: 'flex',
		alignItems: 'flex-end',
		marginTop: 5,
	},
	qtyCurrentSaving: {
		fontSize: 23,
		margin: 0,
	},
	qtyCurrencyCurrentSaving: {
		fontSize: 16,
		color: '#7f7f7f',
		margin: 0,
		marginBottom: 2,
		marginLeft: 2,
	},
	qtyRetirement: {
		fontSize: 23,
		margin: 0,
		color: theme.palette.info.dark,
	},
	qtyCurrencyRetirement: {
		fontSize: 16,
		color: theme.palette.info.dark,
		margin: 0,
		marginBottom: 2,
		marginLeft: 2,
	},
	titleYears: {
		fontWeight: 'bold',
		fontSize: 23,
		color: theme.palette.info.dark,
	},
	imgMoney: {
		width: 35,
		height: 35,
		marginRight: 5,
	},
	containerMoneyYears: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		minWidth: 250,
	},
	tableTitleHeader: {
		fontWeight: 'bold !important',
	},
	divider: {
		backgroundColor: '#F0F0F0',
		width: '100%',
		height: 1,
		marginTop: 20,
		marginBottom: 20,
	},
	infoContainer: {
		width: '100%',
		padding: 25,
	},
	listItem: {
		margin: 0,
		fontSize: 14,
		display: 'flex',
		width: '100%',
		maxWidth: 'max-content'
	},
	colorNote: {
		color: theme.palette.info.dark,
		fontWeight: 'bold',
	},
	savingLink: {
		display: 'flex',
		width: '100%',
		maxWidth: 'max-content',
		margin: '0 auto',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
	},
	link: {
		color: theme.palette.primary.main,
		marginLeft: 5,
		cursor: 'pointer',
		textDecoration: 'underline',
	},
	containerTitle: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		marginTop: 40,
	},
	headerTableTitle: {
		width: '100%',
		height: '50px',
		backgroundColor: '#333333',
		justifyContent: 'center',
		display: 'flex',
		alignItems: 'center',
		// [theme.breakpoints.up('xs')]: {
		// 	marginLeft: 16,
		// 	width: '91%',
		// 	textAlign: 'center',
		// 	height: '58px',
		// },
		// [theme.breakpoints.up('sm')]: {
		// 	marginLeft: 22,
		// 	width: '95%',
		// 	textAlign: 'center',
		// 	height: '58px',
		// },
		// [theme.breakpoints.up('md')]: {
		// 	width: '95%',
		// 	height: '50px',
		// 	backgroundColor: '#333333',
		// 	justifyContent: 'center',
		// 	display: 'flex',
		// 	alignItems: 'center',
		// 	marginLeft: 30,
		// },
	},
	maxContent: {
		width: '100%',
		maxWidth: 'max-content'
	},
	block: {
		display: 'block !important',
	},
}));
