/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Paper, useMediaQuery } from '@mui/material';
import { useStyles } from './styles';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../../../components/Button';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { INTERNAL_MESSAGES } from '../../../config/messageCatalog';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Resumable from 'resumablejs';
import { HeaderList } from '../../../components/HeaderList';
import ModalLoading from '../../../components/ModalLoading';
import CustomAlert from '../../../components/CustomAlert';
import { IAlert } from '../../../interfaces/alert';

interface Props {
	handleConsultCase: () => void;
	handleAttachCase: () => void;
	files: {
		file1: {
			name: string;
			data: null;
			type: string;
			size: number;
		};
	};
}

export const ChargeView = ({ handleConsultCase, files, handleAttachCase }: Props) => {
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [progress, setProgress] = useState(0);
	const [disableContinue, setDisableContinue] = useState(true);
	const [intervalID, setIntervalID] = useState<any>(0);
	const [countTime, setCountTime] = useState(0);
	const setUploadReady = useState(false)[1];
	const [loading, setLoading] = useState(false);
	const [alertFile, setAlertFile] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});

	useEffect(() => {
		uploadFilesWebRes();
	}, []);

	useEffect(() => {
		//Calcula el porcentaje de Avance
		setProgress(() => {
			let calculo = (90 * countTime) / 10;
			return calculo >= 100 ? 100 : calculo < 0 ? 0 : calculo;
		});

		//Si se cumple el tiempo de 10 segundos se detiene el intervalo
		if (countTime >= 10) {
			clearInterval(intervalID);
			setIntervalID(0);
			setCountTime(100);
			setProgress(90);
		}
	}, [countTime]);

	const resumableFile = new Resumable({
		target: `${process.env.REACT_APP_API_URL_OPENTEXT}/CartasProduccion-web/upload`,
		testChunks: false,
		chunkSize: 1 * 1024 * 1024,
		simultaneousUploads: 1,
		maxFiles: 1,
		uploadMethod: 'POST',
		method: 'octet',
	});
	resumableFile.on('fileAdded', function () {
		setAlertFile({
			show: false,
			message: '',
			severity: 'success',
		});

		setProgress(0);
		resumableFile.upload();
		setProgress(80);
	});

	resumableFile.on('progress', function () {
		setUploadReady(true);
	});

	resumableFile.on('fileSuccess', function (file: any, message: string) {
		setUploadReady(false);
		setDisableContinue(false);
		setProgress(100);
	});

	resumableFile.on('fileError', function (file, message) {
		setUploadReady(false);

		setAlertFile({
			show: true,
			message:
				INTERNAL_MESSAGES.ERROR_MESSAGE,
			severity: 'error',
		});
	});

	const uploadFilesWebRes = async () => {
		if (files.file1?.data) {
			resumableFile.addFile(files.file1?.data as unknown as File);
		}
	};

	function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
		return (
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Box sx={{ width: '100%', mr: 1 }}>
					<LinearProgress
						variant="determinate"
						{...props}
						className={classes.root}
					/>
				</Box>
				<Box sx={{ minWidth: 35 }}>
					<Typography variant="body2" color="text.secondary">{`${Math.round(
						props.value
					)}%`}</Typography>
				</Box>
			</Box>
		);
	}

	return (
		<div>
			<HeaderList
				title="Carta de instrucción de cancelación de hipoteca"
				date="Información al 12 julio 2021"
				list={[
					'Esta carta debes entregarla al notario que elegiste para que lleve a cabo la liberación de tu hipoteca y quede registrada en el Registro Público de la Propiedad.',
					'La vigencia de la carta de cancelación es de 180 días naturales, si en ese tiempo no la entregas al notario (solo notario), deberás iniciar el trámite nuevamente.',
				]}
			/>
			<Paper className={classes.divPrincipal}>
				<p className={classes.txtPrincipales} style={{ fontWeight: 'bold' }}>
					Estamos cargando tu documentación.
				</p>
				<div>
					<div className={classes.contentFile}>
						<Box sx={{ width: '50%', marginTop: 5 }}>
							<LinearProgressWithLabel value={progress} />
						</Box>
					</div>
				</div>
				<div style={{ margin: matches ? '5px 10px' : '20px 200px' }}>
					<p style={{ width: '100%', maxWidth: 'max-content', color: '#7F7F7F', fontSize: 15, margin: '0 auto' }}>
						Tiempo aproximado de espera 3 min.
					</p>
				</div>
				<div className={classes.contentFileDataButtons}>
					<CustomAlert
						data-testid="mensageAlertthree"
						message={alertFile.message}
						severity={alertFile.severity}
						show={alertFile.show}
						onClose={() => {
							setAlertFile({
								show: false,
								message: '',
								severity: 'success',
							});
						}}
					/>
				</div>
				<div className={classes.contentFileDataButtons}>
					<CustomButton
						onClick={() => {
							handleConsultCase();
						}}
						variant="outlined"
						styles={{
							width: 260,
							marginInline: 10,
						}}
						label="Regresar"
					/>
					<CustomButton
						onClick={() => {
							setLoading(true);
							handleAttachCase();

							setTimeout(() => {
								setLoading(false);
								handleConsultCase();
							}, 15000);
						}}
						disabled={disableContinue}
						variant="solid"
						styles={{
							width: 260,
							marginInline: 10,
						}}
						label="Continuar"
					/>
				</div>

				<div style={{ margin: matches ? '5px 10px' : '10px 200px' }}>
					<p className={classes.txtPrincipales}>
						Antes de enviarlos verifica que estén correctos.<br></br>El único
						formato permitido es un PDF con un máximo de 20 MB.
					</p>
				</div>
			</Paper>
			<ModalLoading loading={loading} />
		</div>
	);
};

export default ChargeView;
