import { IArchivos, ICasosCerrados, ICesiDefault } from "../interface";

export const SERVICESID = process.env.REACT_APP_URL_ENTREGA_ESCRITURAS_CITA_ID;

export const MSG_ERROR =
	'Por el momento el servicio no está disponible. Intenta más tarde.';

export const CASOS_CERRADOS: { [key: string]: ICasosCerrados } = {
	ZENE: { casos: ['E0024'], moduloAnterior: false },
	ZEES: { casos: ['E0004', 'E0019', 'E0020'], moduloAnterior: true },
};

export const CODIGO_CASO_VIEJO = 'ZEES';

// SI EL CÓDIGO ES DIFERENTE A ESTE PROCEDE HABILITAR EL BOTÓN DE CREAR UN NUEVO CASO
// ESTA VALIDACIÓN VA EN LA ULTIMA PANTALLA
export const CODIGO_RAZON_EXITOSO = 'ZN01';

export const CLAVE_TANTOS: { [key: string]: number } = {
	ZN01: 2,
	ZN02: 1,
	ZN03: 0,
};

export const CesiDefaultConsulta: ICesiDefault = {
	estado: '',
	centro: '',
	fecha: null,
	horario: '',
};

export const tabsFlag = [
	{
		id: 1,
		title: '1.- Solicitud',
		disabled: false,
		checked: false,
		columns: 3,
		orderMD: 0,
		orderSM: 0,
	},
	{
		id: 2,
		title: '2.- Seguimiento de caso asignación de proceso',
		disabled: false,
		checked: false,
		columns: 3,
		orderMD: 1,
		orderSM: 2,
	},
	{
		id: 3,
		title: '3.- Cita en Cesi',
		disabled: false,
		checked: false,
		columns: 3,
		orderMD: 2,
		orderSM: 3,
	},
	{
		id: 4,
		title: '4.- Entrega de escrituras',
		disabled: false,
		checked: false,
		columns: 3,
		orderMD: 3,
		orderSM: 4,
	},
];

export const getDateParse = (dateString?: string) => {
	if (dateString && dateString.length === 8) {
		const anio = dateString.slice(0, 4);
		const mes = dateString.slice(4, 6);
		const dia = dateString.slice(6, 8);

		return dia + '/' + mes + '/' + anio;
	}

	return dateString;
};

export const MESSAGES_ERROR = {
	errorSucursales:
		'Lo sentimos, en este momento no tenemos centros de servicios disponibles en el estado seleccionado. Intenta más tarde',
	errorDays:
		'Lo sentimos, en este momento no tenemos días disponibles para programar una cita. Intenta más tarde',
	errorHorario:
		'Lo sentimos, en este momento no tenemos horarios disponibles para programar una cita. Intenta más tarde',
	errorService:
		'Por el momento el servicio no está disponible. Intenta más tarde',
};

export const getHourParse = (hourString?: string) => {
	try {
		if (hourString) {
			const [horaStr, minutoStr] = hourString.split(':');
			let sufijo = Number(horaStr) > 11 ? 'PM' : 'AM';
			if (Number(horaStr) > 12) {
				const horaFinStr = String(
					Number(horaStr) - 12 > 0 ? Number(horaStr) - 12 : 1
				);
				return (
					horaFinStr.padStart(2, '0') +
					':' +
					minutoStr.padStart(2, '0') +
					' ' +
					sufijo
				);
			}
			return (
				horaStr.padStart(2, '0') +
				':' +
				minutoStr.padStart(2, '0') +
				' ' +
				sufijo
			);
		}
		return hourString ?? '';
	} catch (error) {
		return hourString ?? '';
	}
};

export const documentos: IArchivos[] = [
	{
		codigo: 'Z202',
		titulo: 'Escritura',
		fileKey: 'escritura',
		descripcion: 'Instrumento Privado o Público',
		name: '',
		type: '',
		file: null,
	},
	{
		codigo: 'Z203',
		titulo: 'Carta de otorgamiento de crédito',
		fileKey: 'cartaOtorgamientoCredito',
		descripcion:
			'Documento expedido por el Infonavit que autoriza el crédito',
		name: '',
		type: '',
		file: null,
	},
	{
		codigo: 'Z204',
		titulo: 'Certificado de vivienda',
		fileKey: 'certificadoVivienda',
		descripcion: 'Documento que acredita la entrega de la vivienda',
		name: '',
		type: '',
		file: null,
	},
	{
		codigo: 'Z205',
		titulo: 'Recibo de predial',
		fileKey: 'reciboPredial',
		descripcion:
			'Pago del impuesto predial expedido por la Tesorería Municipal',
		name: '',
		type: '',
		file: null,
	},
];

export const CODE_STATUS = {
	ABIERTO: 'E0001',
	INICIO_ATENCION: 'E0002',
	ESPERA_CONFIRMACION: 'E0003',
	ESCRITURA_IDENTIFICADA: 'E0004',
	SOLICITUD_COTEJO: 'E0005',
	VALIDACION_ESCRITURA: 'E0006',
	REGISTRO_DIGITALIZACION: 'E0007',
	ESPERA_DOCUMENTOS: 'E0008',
	SOLICITUD_REGULARIZACION: 'E0009',
	EXPEDIENTE_ASIGNADO: 'E0010',
	REGULARICACION_ADMINISTRATIVA: 'E0011',
	REGULARICACION_JUDICIAL: 'E0012',
	ESCRITURAS_ENVIADAS_ANEC: 'E0013',
	RECEPCION_ANEC: 'E0014',
	PREPARACION_ENVIO_CESI: 'E0015',
	ENVIADO_CESI: 'E0016',
	ESCRITURAS_RECIBIDAS_CESI: 'E0017',
	SOLICITAR_CITA: 'E0018',
	ABIERTO_CITA: 'E0019',
	ESCRITURA_ENTREGADA_SOL: 'E0020',
	ENVIO_ACUSE_ANEC: 'E0021',
	PREPARACION_DEV_ESCRITURAS: 'E0022',
	DEV_ESCRITURAS_ANEC: 'E0023',
	CERRADO: 'E0024',
};
