import { CheckCircleOutlined } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useStyles } from '../styles';

const HeaderPayCredit = () => {
    const classes = useStyles();
	return (
		<>
			<div className={classes.titleContainer}>
				<h2>Opciones de pagos</h2>
			</div>
			<Grid container marginBottom={4} spacing={2}>
				<Grid item xs={12}>
					<p className={classes.listItem}>
						<CheckCircleOutlined
							color="info"
							style={{ marginRight: 5, width: 22 }}
						/>
						<label style={{ marginTop: 2.45 }}>
							Haz tus pagos completos antes del último dia del mes
							actual.
						</label>
						<br />
					</p>
					<p className={classes.listItem}>
						<CheckCircleOutlined
							color="info"
							style={{ marginRight: 5, width: 22 }}
						/>
						<label style={{ marginTop: 2.45 }}>
							si vas a liquidar tu crédito, paga directamente en
							cualquier banco autorizado.
						</label>
						<br />
					</p>
				</Grid>
			</Grid>
		</>
	);
};

export default HeaderPayCredit;
