import { useNavigate } from 'react-router-dom';
import BaseModal from '../../../../components/Modal';
import { useStyles } from './styles/modal-notario.style';
import { IDataNotario } from '../interface';

interface IProps {
	dataNotario: IDataNotario;
}

const ModalNotarios = ({ dataNotario }: IProps) => {
	const { messages, message, data } = dataNotario;
	const navigate = useNavigate();
	const classes = useStyles();

	return (
		<BaseModal
			open={messages && messages.length > 0 ? true : false}
			onConfirm={() => navigate('/')}
			confirmLabel="Cerrar"
			hideCloseButton
			width="sm"
			children={
				<div className={classes.container}>
					<h3 className={classes.title}>
						No es posible la entrega de tus escrituras debido a las
						siguientes condiciones de tu crédito:
					</h3>
					<p className={classes.label1}>
						{messages && messages.length > 0
							? `${messages[0][0]} ${messages[0][1]}`
							: ''}
					</p>
					{data && Object.keys(data).length > 0 ? (
						<p className={classes.label2}>
							{`Acude a la notaría No. ${data?.notario_numero || ''
								} 
							con el notario ${data?.notario_nombre || ''}; a la
							dirección Calle ${data?.calle || ''} colonia ${data?.colonia || ''}, CP ${data?.cp || ''
								} o comunícate al ${data?.telefono1 || ''}.`}
						</p>
					) : (
						<p className={classes.label2}>{message}</p>
					)}
				</div>
			}
		/>
	);
};

export default ModalNotarios;
