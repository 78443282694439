//Dependencies
import React, { Fragment, useEffect, useState } from 'react';
import { isBefore } from 'date-fns';

// Material Components
import { Box, Link } from '@mui/material';

// Components
import CustomButton from '../../../components/Button';
import CustomAlert from '../../../components/CustomAlert';

// Assets
import { useStyles } from './styles';
import mail from '../../../assets/img/mail.png';
import { IAlert } from '../../../interfaces/alert';
import { formatDate } from '../../../utils/dates';

// Resources

// Interface
interface IVerificationCode {
	onContinue: () => void;
	onResend: () => void;
	isActivated: boolean;
	superError: boolean;
	onCloseError: () => void;
	hasError: IAlert;
	hasTimer?: boolean;
	noResend: boolean;
}

const INITIAL_TIME = 300000;

const VerificationEmail = ({
	onContinue,
	onResend,
	isActivated,
	hasError,
	onCloseError,
	hasTimer,
	noResend,
	superError,
}: IVerificationCode) => {
	const styles = useStyles();
	let activationTimer = JSON.parse(
		localStorage.getItem('activationTimer') || '0'
	);
	let activationMultiplier = JSON.parse(
		localStorage.getItem('activationMultiplier') || '1'
	);
	const [timer, setTimer] = useState(activationTimer);
	const [checkTime, setCheckTime] = useState(0);
	const [multiplier, setMultiplier] = useState(activationMultiplier);

	// SETTER PARA TEMPORIZADOR EN LOCALSTORAGE
	useEffect(() => {
		localStorage.setItem('activationTimer', JSON.stringify(timer));
	}, [timer]);

	// SETTER PARA MULTIPLICADOR EN LOCALSTORAGE
	useEffect(() => {
		localStorage.setItem(
			'activationMultiplier',
			JSON.stringify(multiplier)
		);
	}, [multiplier]);

	useEffect(() => {
		let now = new Date().getTime();
		if (isBefore(now, timer)) {
			let rest = timer - now;
			setCheckTime(rest);
		}
	}, [timer]);

	setTimeout(() => {
		if (checkTime > 0) setCheckTime(checkTime - 1000);
	}, 1000);
	// SOLICITUD DE REENVIO, ACTIVANDO EL TEMPORIZADOR
	const resendWithTimer = () => {
		onResend();
		let now = new Date().getTime();
		if (timer && now - timer < INITIAL_TIME) {
			setMultiplier(multiplier + (multiplier < 6 ? 1 : 0));
			setTimer(
				now + INITIAL_TIME * (multiplier + (multiplier < 6 ? 1 : 0))
			);
		} else {
			setMultiplier(1);
			setTimer(now + INITIAL_TIME);
		}
	};

	return (
		<Fragment>
			<Box className={styles.VerificacionLogo}>
				<img alt="Icono de email" src={mail} />
			</Box>
			<Box
				sx={{
					alignItems: 'center',
					justifyContent: 'center',
					display: 'flex',
				}}
			>
				<label className={styles.VerificacionCodigoTitle}>
					<strong>Confirmación de correo electrónico</strong>
				</label>
			</Box>
			<Box
				sx={{
					alignItems: 'center',
					justifyContent: 'center',
					display: 'flex',
					marginBottom: '35px',
				}}
			>
				<span className={styles.VerificacionText}>
					<br />
					{!superError && (
						<>
							<br />
							{isActivated ? (
								<strong>
									<label>
										Gracias por confirmar tu correo
										electrónico{' '}
									</label>
								</strong>
							) : (
								<p style={{ margin: 0 }}>
									<strong>
										Se envió un correo electrónico al e-mail
									</strong>{' '}
									que nos proporcionaste.
								</p>
							)}
							<br />
							{isActivated && (
								<label>
									Da clic en el botón de continuar para seguir
									con el proceso de registro.
								</label>
							)}
						</>
					)}
				</span>
			</Box>
			{!noResend && (
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'column',
					}}
				>
					{checkTime > 0 && (
						<h4 style={{ marginTop: 0 }}>
							{formatDate(
								'mm:ss',
								new Date(checkTime > 0 ? checkTime : 0)
							)}
						</h4>
					)}
					<Link
						className={styles.link}
						component={'button'}
						underline="none"
						onClick={hasTimer ? resendWithTimer : onResend}
						disabled={hasTimer && checkTime > 0}
					>
						Volver a enviar
					</Link>
				</Box>
			)}
			<div style={{ width: '100%' }}>
				<CustomAlert
					show={hasError.show}
					severity={hasError.severity}
					message={hasError.message}
					onClose={onCloseError}
				/>
			</div>
			{isActivated && (
				<Box className={styles.divboton}>
					<CustomButton
						onClick={onContinue}
						variant="solid"
						label="Continuar"
					/>
				</Box>
			)}
		</Fragment>
	);
};

export default VerificationEmail;
