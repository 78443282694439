import { Info } from '@mui/icons-material';
import { useStyles } from './styles';
import { useContextMoment } from '../../context';
import { formatMoney3 } from '../../../../utils/validators';

export const FooterCredit = () => {
	const classes = useStyles();
	const { balance } = useContextMoment();
	return (
		<div className={classes.container}>
			<p className={classes.labelImportant}>
				<label>Importante: </label>Recuerda que desde noviembre de 2024
				congelamos los saldos y mensualidades de los créditos en Veces
				Salario Mínimo otorgados antes de 2013 para apoyarlos eliminando
				los incrementos anuales por la inflación.
			</p>
			<p className={classes.labelTitle}>Mensualidades REA y ROA</p>
			<div className={classes.sectionInfoPrices}>
				<label className={classes.labelTitleInfoPrice}>
					Si pagas por tu cuenta debes pagar la mensualidad REA
				</label>
				<label className={classes.labelSubTitleInfoPrice}>
					{formatMoney3(
						balance?.mensualidadReaRoa?.mensualidadRea || '0'
					)}
				</label>
			</div>
			<p className={classes.labelTitleInfo}>
				REA significa que no tienes relación laboral formal de trabajo
				por lo que los pagos los debes de hacer por tu cuenta.
			</p>
			<div className={classes.divider} />
			<div className={classes.sectionInfoPrices}>
				<label className={classes.labelTitleInfoPrice}>
					Si el pago es por nómina se paga una mensualidad ROA
				</label>
				<label className={classes.labelSubTitleInfoPrice}>
					{formatMoney3(
						balance?.mensualidadReaRoa?.mensualidadRoa || '0'
					)}
				</label>
			</div>
			<p className={classes.labelTitleInfo}>
				ROA significa que tienes relación laboral formal de trabajo por
				lo que tu pago se hace a través del descuento de nómina que hace
				tu empleador (patrón).
			</p>
			<div className={classes.containerAlert}>
				<div className={classes.labelAlert}>
					<Info color="info" className={classes.tooltipIcon} />
					<label>
						Aunque el pago para en cada régimen es el mismo, la
						cantidad puede variar un poco debido a los accesorios.
					</label>
					<p>
						Esta es la misma cantidad que debes de pagar para cubrir
						tus mensualidades y mantener tu crédito al corriente.
					</p>
				</div>
			</div>
			<p className={classes.labelTitle}>Pagos parciales</p>
			<label className={classes.labelTitleInfoPrice}>
				¿Qué son y cómo aplican los pagos parciales?
			</label>
			<p className={classes.pInfoReaPayment}>
				Los pagos parciales son aquellos pagos que puedes hacer durante
				el mes, para completar poco a poco la cantidad que se observa en
				el renglón de <strong>"Pago por tu cuenta REA"</strong> en la
				sección de <strong>Mensualidad REA y ROA</strong>, considerando
				como fecha límite para completarla antes del último día del mes.
				<br />
				<br />
				En caso de que con las parcialidades que realices antes del
				último día del mes, no cubras el total indicado en "Pago por tu
				cuenta REA", los pagos hechos, se aplicarán al saldo de tu
				crédito, no se tomará en cuenta como pago efectivo del mes
				efectivo.
				<br />
				<br />
				Si antes del último día del mes terminas de cubrir el monto
				total de "Pago por tu cuenta REA" y continúas realizando pagos,
				lo que pagues adicional, se aplicará al saldo del crédito.
			</p>
		</div>
	);
};
