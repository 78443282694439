import React, { useState } from 'react';
import {
	Pagination,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	TableSortLabel,
} from '@mui/material';

interface ISimpleTable {
	columns: any;
	data: any;
	count?: number;
	page?: number;
	setPage?: (value: number) => void;
	hidePagination?: boolean;
	rowsPerPage?: number;
}

const SimpleTable = ({
	columns = [],
	data = [],
	count = 1,
	page = 1,
	setPage = (value: number) => null,
	hidePagination = false,
	rowsPerPage = 10,
}: ISimpleTable) => {
	const [order, setOrder] = useState<'desc' | 'asc'>('desc');
	const [orderBy, setOrderBy] = useState<string>('');

	const handleOrder = (key: string) => (event: React.MouseEvent<unknown>) => {
		const isAsc = orderBy === key && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(key);
	};

	const handleSort = (a: any, b: any) => {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	};

	const handleCompare = (a: any, b: any) =>
		order === 'desc' ? handleSort(a, b) : -handleSort(a, b);

	return (
		<TableContainer>
			<Table stickyHeader style={{ minWidth: 700 }}>
				<TableHead>
					<TableRow>
						{columns.map((column: any) => (
							<TableCell
								sortDirection={
									order && orderBy === column.column
										? order
										: false
								}
								key={column.label}
								style={column.headerStyles}
							>
								{column.sort ? (
									<TableSortLabel
										active={orderBy === column.column}
										direction={
											order && orderBy === column.column
												? order
												: 'asc'
										}
										onClick={handleOrder(column.column)}
									>
										<label>
											{column.label}
										</label>
									</TableSortLabel>
								) : (
									<label>
										{column.label}
									</label>
								)}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{data
						.sort(handleCompare)
						.slice(
							hidePagination ? 0 : (page - 1) * rowsPerPage,
							hidePagination
								? data.length
								: (page - 1) * rowsPerPage + rowsPerPage
						)
						.map((item: any, i: number, oArray: any) => (
							<TableRow key={item.id || i}>
								{columns.map(
									(column: any, j: number, columns: any) => (
										<TableCell
											key={column.column + j}
											style={column.style}
										>
											<label>
												{column.renderValue
													? column.renderValue(
															item[column.column],
															i,
															oArray
													)
													: item[column.column]}
											</label>
										</TableCell>
									)
								)}
							</TableRow>
						))}
				</TableBody>
				{!hidePagination && (
					<TableFooter>
						<TableRow>
							<TableCell colSpan={columns.length}>
								<Pagination
									style={{
										display: 'flex',
										justifyContent: 'center',
									}}
									color="primary"
									shape="rounded"
									count={Math.ceil(count / rowsPerPage)}
									page={page}
									onChange={(
										e: React.ChangeEvent<unknown>,
										value: number
									) => setPage(value)}
								/>
							</TableCell>
						</TableRow>
					</TableFooter>
				)}
			</Table>
		</TableContainer>
	);
};

export default SimpleTable;
