import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('102', 'impersonalizacion');

export const ENDPOINTS_IMPERSONALIZACION: IBitacora = {
    '/impersonalizacion/login': _bitacora.Read(
        '102100',
        'Login al sistema'
    ),
    '/impersonalizacion/buscar-usuario': _bitacora.Read(
        '102101',
        'Buscar el usuario'
    ),
    '/impersonalizacion/impersonalizar-usuario': _bitacora.Read(
        '102102',
        'Impersonaliza el usuario'
    ),
};
