import { useEffect, useState } from 'react';
import Resumable from 'resumablejs';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomAlert from '../../../components/CustomAlert';
import { useStyles } from './styles/styles';
import CustomButton from '../../../components/Button';
import { Clear, Search } from '@mui/icons-material';
import DocumentView from './DocumentView';
import ModalConfirmDoc from './ModalConfirmDoc';
import { MSG_ERROR, documentos, getDateParse } from './utils';
import ModalLoading from '../../../components/ModalLoading';
import { writingRequestAPI } from '../../../api/modules/WritingRequest';
import { encodeAllJSONData } from '../../../utils/encrypt';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { INTERNAL_MESSAGES } from '../../../config/messageCatalog';
import { useEscrituras } from '../hooks/useEscrituras';
import { CASE_FOLLOW_STATUS } from './utils/pagesStatus';
import { IArchivos } from './interface';
import { useBitacora } from '../../../hooks/useBitacora';
import { REFERENCES_ENDPOINTS_ENTREGA_ESCRITURAS } from '../../../config/bitacora/entrega-escritura';

interface IProps {
	setPage: (arg: number) => void;
}

const AttachDocuments = ({ setPage }: IProps) => {
	const { user } = useSelector((state: RootState) => state.session);
	const { escrituras, getConsultDetail } = useEscrituras();
	const style = useStyles();
	const navigate = useNavigate();
	const [view, setView] = useState<File | null>(null);
	const [modalConfirm, setModalConfirm] = useState(false);
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState('');
	const [archivos, setArchivos] = useState<IArchivos[]>([]);
	const { regBitacoraReferences } = useBitacora();

	const resumableFile = new Resumable({
		target: `${process.env.REACT_APP_URL_ENTREGA_ESCRITURAS}/mejoraescrituras/websocket/upload`,
		chunkSize: 1024 * 1024 * 1,
		simultaneousUploads: 4,
		testChunks: false,
		method: 'octet',
		headers: {
			keyId: process.env.REACT_APP_URL_ENTREGA_ESCRITURAS_KEY || '',
		},
	});
	resumableFile.on('fileAdded', function () {
		resumableFile.upload();
	});
	resumableFile.on('fileSuccess', async (file: any) => {
		const files = archivos.filter((f) => f.file);
		if (files && files.length > 0) {
			const { codigo } = files[files.length - 1];
			if (file.fileName.includes(codigo)) {
				const filesUpdate = archivos
					.filter((item) => item.file)
					.map((file) => file.name);
				regBitacoraReferences(
					REFERENCES_ENDPOINTS_ENTREGA_ESCRITURAS.UPLOAD_FILES,
					{ files: filesUpdate }
				);
				await getConsultDetail({});
				setPage(CASE_FOLLOW_STATUS.HOME);
				resetFiles();
			}
		}
	});
	resumableFile.on('fileError', function () {
		setMessage(MSG_ERROR);
		setModalConfirm(false);
		setLoading(false);
	});

	const handleChangeFile = (
		{ target }: React.ChangeEvent<HTMLInputElement>,
		codigo: string
	) => {
		setMessage('');
		const { files } = target;
		if (files) {
			const { type, name, size } = files[0];
			if (
				![
					'image/png',
					'image/jpg',
					'image/tiff',
					'application/pdf',
				].includes(type)
			) {
				setMessage(
					'Verifica que el formato del archivo es el correcto. Los formatos permitidos son jpg, png, tiff, pdf.'
				);
				return;
			}
			const totalSize =
				archivos.reduce((arr, item) => {
					return arr + (item.file?.size ?? 0);
				}, 0) + size;

			const sizeReal = Math.ceil(totalSize / 1000000);

			if (sizeReal > 20) {
				setMessage(INTERNAL_MESSAGES.FILE_SIZE);
				return;
			}
			const newData = archivos.map((item) => {
				if (item.codigo === codigo) {
					item.name = name;
					item.type = type;
					item.file = files[0];
				}
				return item;
			});
			setArchivos(newData);
		}
	};

	const handleDeleteFile = (codigo: string) => {
		const newData = archivos.map((item) => {
			if (item.codigo === codigo) {
				item.name = '';
				item.type = '';
				item.file = null;
			}
			return item;
		});
		setArchivos(newData);
	};

	const handleSubmit = () => {
		try {
			setLoading(true);
			setMessage('');
			const filesUpdate = archivos.filter((item) => item.file);
			filesUpdate.forEach((item, k) => {
				if (item.file) {
					const nombreSp = item.file.name.split('.');
					const nuevoArchivo = new File(
						[item.file],
						`${escrituras.noCaso}_${item.codigo}${
							k === filesUpdate.length - 1 ? '_X' : ''
						}.${nombreSp[nombreSp.length - 1]}`,
						{
							type: item.type,
						}
					);
					resumableFile.addFile(nuevoArchivo);
				}
			});
			if (filesUpdate.length === 0) {
				setMessage(MSG_ERROR);
				setLoading(false);
			}
		} catch {
			setMessage(MSG_ERROR);
			setModalConfirm(false);
			setLoading(false);
		}
	};

	const resetFiles = () => {
		const newData = archivos.map((item) => {
			item.name = '';
			item.type = '';
			item.file = null;
			return item;
		});
		setArchivos(newData);
	};

	const consultaDocumentos = async () => {
		try {
			setLoading(true);
			const data = {
				caso: escrituras.noCaso,
			};
			const dataEncrypt = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const { result } = await writingRequestAPI.consultDocs(dataEncrypt);
			if (Number(result.code) === 0) {
				const docsResult = result.data.documentos.map(
					(doc: any) => doc.clave
				);
				const newDocuments = documentos.filter((f) =>
					docsResult.includes(f.codigo)
				);
				setArchivos(newDocuments);
				return;
			}
			setMessage(result?.message || MSG_ERROR);
		} catch (error) {
			setMessage(MSG_ERROR);
		} finally {
			setLoading(false);
		}
	};

	const finishUpdate = async () => {
		try {
			setLoading(true);
			const data = {
				caso: escrituras.noCaso,
			};
			const dataEncrypted = await encodeAllJSONData(
				{ data: JSON.stringify(data) },
				user?.k || ''
			);
			const { result } = await writingRequestAPI.adjuntaDocumento(
				dataEncrypted
			);
			if (result && Number(result?.code) === 0) {
				await getConsultDetail({});
				setPage(CASE_FOLLOW_STATUS.HOME);
				resetFiles();
				return;
			}
			setMessage(MSG_ERROR);
		} catch (error) {
			setMessage(MSG_ERROR);
		} finally {
			setModalConfirm(false);
			setLoading(false);
		}
	};

	useEffect(() => {
		consultaDocumentos();
		/* eslint-disable react-hooks/exhaustive-deps */
	}, []);

	return (
		<Box className={style.containerAttchMain}>
			<div className={style.containerWhitout}>
				<Box className={style.containerPaperAttach}>
					<h3 className={style.labelAttach}>Entrega de escrituras</h3>
					<CustomAlert
						message={
							<>
								<p className={style.sinMarginP}>
									Adjuntar alguno de los documentos permite
									realizar el trámite con mayor agilidad.
								</p>
								<p className={style.sinMarginP}>
									En caso de que no cuentes con ninguno de los
									documentos podemos continuar con tu trámite.
								</p>
							</>
						}
						severity="warning"
						show
					/>
				</Box>
			</div>
			<div className={style.containerAttachDoc}>
				<span>Número de caso</span>
				<div>{escrituras.noCaso}</div>
			</div>
			<div className={style.containerAttachDoc}>
				<span>Fecha de solicitud</span>
				<div>{getDateParse(escrituras.fecha)}</div>
			</div>

			<p className={style.titleAttach}>
				Estos son los documentos que debes adjuntar
			</p>

			{archivos.map((item, k) => (
				<div key={k} className={style.container3}>
					<section className="title">
						<span>{item.titulo}</span>
						<p>{item.descripcion}</p>
					</section>
					<section className="file">
						<p>
							{item.name
								? item.name
								: 'No se eligió ningún archivo.'}
						</p>
					</section>
					<section>
						<Box className={style.formFile}>
							{item.file && (
								<>
									<label
										onClick={() =>
											handleDeleteFile(item.codigo)
										}
									>
										<Clear className={style.iconAttach} />
										Eliminar archivo
									</label>
									<label onClick={() => setView(item.file)}>
										<Search className={style.iconAttach} />
										Ver archivo
									</label>
								</>
							)}
							{!item.file && (
								<label>
									Elegir archivo
									<input
										onChange={(event) =>
											handleChangeFile(event, item.codigo)
										}
										accept="image/png, image/jpg, image/tiff, application/pdf"
										type="file"
										className={style.inputFileAttach}
									/>
								</label>
							)}
						</Box>
					</section>
				</div>
			))}

			<Box className={style.containerAttch}>
				<p className={style.sinMarginP}>
					Antes de enviar tus documentos verifica que estén correctos.
				</p>
				<p className={style.sinMarginP}>
					Los formatos permitidos son jpg, png, tiff, pdf con un
					tamaño máximo de 20 MB por todos los documentos adjuntos.
				</p>
			</Box>

			<CustomAlert
				message={message}
				show={Boolean(message)}
				severity="warning"
			/>

			<Box className={style.boxButtonsGroupAttach}>
				<Box className={style.buttonItem}>
					<CustomButton
						label={'Salir'}
						variant="text"
						onClick={() => navigate('/inicio')}
					/>
				</Box>
				<Box className={style.buttonItem}>
					<CustomButton
						label={'Continuar sin documentos'}
						variant="outlined"
						onClick={finishUpdate}
					/>
				</Box>
				<Box className={style.buttonItem}>
					<CustomButton
						label={'Adjuntar documentos'}
						disabled={!archivos.some((e) => e.file != null)}
						onClick={() => setModalConfirm(true)}
					/>
				</Box>
			</Box>

			<DocumentView file={view} setClose={setView} />
			<ModalLoading loading={loading} />
			<ModalConfirmDoc
				show={modalConfirm}
				setShow={setModalConfirm}
				execute={handleSubmit}
			/>
		</Box>
	);
};

export default AttachDocuments;
