import { useState } from 'react';
import {
	Checkbox,
	Divider,
	FormControl,
	FormControlLabel,
} from '@mui/material';
// COMPONENTS
import CustomButton from '../../../components/Button';
// RESOURCES
import { useStyles } from './styles';
import { IDataInfo } from '../utils';

interface Props {
	onContinue: any;
	onReturn: any;
	dataInfo: IDataInfo;
	setDataInfo: (arg: IDataInfo) => void;
	setLoading: (arg: boolean) => void;
}

const LayoutNotice = ({
	onContinue,
	onReturn,
	dataInfo,
}: Props) => {
	const classes = useStyles();
	const [auth, setAuth] = useState(false);

	return (
		<>
			<div className={classes.containerFirma}>
				<div className={classes.containerBank}>
					<h3
						className={`${classes.contentFileDataTitle} ${classes.maxContent}`}
						style={{ textAlign: 'center' }}
					>
						Verifica que los datos sean correctos
					</h3>
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<h4 className={classes.contentFileDataTitle}>
									Banco
								</h4>
							</div>
							<div className={classes.contentFileData}>
								<p className={classes.noMargin}>{dataInfo?.banco}</p>
							</div>
						</div>
					</div>
					<Divider style={{ marginBlock: 10 }} />
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<h4 className={classes.contentFileDataTitle}>
									Nombre
								</h4>
							</div>
							<div className={classes.contentFileData}>
								<p className={classes.noMargin}>{dataInfo?.nombre}</p>
							</div>
						</div>
					</div>
					<Divider style={{ marginBlock: 10 }} />
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<h4 className={classes.contentFileDataTitle}>
									Clave Bancaria Estandarizada (CLABE)
								</h4>
							</div>
							<div className={classes.contentFileData}>
								<p className={classes.noMargin}>{dataInfo?.clabe}</p>
							</div>
						</div>
					</div>
					<div style={{ textAlign: 'center' }}>
						<FormControl>
							<FormControlLabel
								label="Autorizo que mis recursos sean depositados en la cuenta bancaria que se muestra"
								control={
									<Checkbox
										onChange={(e) => {
											setAuth(e.target.checked);
										}}
									/>
								}
							/>
						</FormControl>
					</div>

					<div className={classes.contentFileDataButtons}>
						<CustomButton
							onClick={() => {
								onReturn();
							}}
							variant="outlined"
							styles={{
								marginTop: 30,
								width: 200,
								marginInline: 10,
								height: 40
							}}
							label="Regresar"
						/>
						<CustomButton
							onClick={() => onContinue()}
							variant="solid"
							disabled={!auth}
							styles={{
								marginTop: 30,
								width: 200,
								marginInline: 10,
							}}
							label="Continuar"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default LayoutNotice;
