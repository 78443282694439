// Dependencies
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Material Components
import { Box, Paper } from '@mui/material';
// Assets
import { useStyles } from './styles';
// Resource
import { encrypt } from '../../utils/encrypt';

// Components
import NssView from './tabNSS';
import DatosContactoView from './tabDatosContacto';
import VerificacionView from './tabVerificacion';
import ContraseñaView from './tabContraseña';
import ConfirmacionView from './tabConfirmacion';
import Tabs from '../../components/Tabs';
import Layout from '../../components/Layout';
import ModalLoading from '../../components/ModalLoading';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';

// Interfaces
import { ITabValue } from '../../interfaces/tabValue';
import { IAlert } from '../../interfaces/alert';

// Api-Modules
import { resetPasswordAPI } from '../../api/modules/resetPassword';
import { keyAPI } from '../../api/modules/key';
import {
	//passwordRecovery,
	sendMessage,
	validCode,
	resetPassword,
} from '../../api/modules/resetPassword';
import { useBitacora } from '../../hooks/useBitacora';

const TabsView = () => {
	const [dataObj, setdataObj] = React.useState<ITabValue>({
		nss: '',
		medioComucacion: '',
		email: '',
		phone: '',
		code: '',
		password: '',
		nssValidate: false,
	});
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [tabActive, setTabActive] = useState(0); //0
	const [refreshCaptcha, setRefreshCaptcha] = useState(false);
	const [containerPosition, setContainerPosition] = useState(1);
	const [tabsEle, setTabsEle] = useState([
		{
			id: 1,
			title: '1.- NSS',
			disabled: true,
			checked: false,
			columns: 2,
			orderMD: 0,
			orderSM: 0,
		},
		{
			id: 2,
			title: '2.- Datos de contacto',
			disabled: true,
			checked: false,
			columns: 2,
			orderMD: 1,
			orderSM: 2,
		},
		{
			id: 3,
			title: '3.- Verificación',
			disabled: true,
			checked: false,
			columns: 2,
			orderMD: 2,
			orderSM: 3,
		},
		{
			id: 4,
			title: '4.- Nueva Contraseña',
			disabled: true,
			checked: false,
			columns: 2,
			orderMD: 3,
			orderSM: 4,
		},
		{
			id: 5,
			title: '5.- Confirmación',
			disabled: true,
			checked: false,
			columns: 2,
			orderMD: 4,
			orderSM: 5,
		},
	]);

	const [alertNSS, setAlertNSS] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [alertNSS2, setAlertNSS2] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});

	const [alertDatosContacto, setAlertDatosContacto] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [alertPassword, setAlertPassword] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [alertVerificacion, setAlertVerificacion] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const { regBitacoraNotOld, getMaskString } = useBitacora();

	const changeTabNext = () => {
		const tabsFlag = [...tabsEle];
		const tabFlag = { ...tabsFlag[tabActive], checked: true };
		const nextTabActive = {
			...tabsFlag[tabActive + 1],
			disabled: false,
			orderSM: tabsFlag[tabActive + 1].orderSM - 1,
		};

		tabsFlag[tabActive] = { ...tabFlag };
		tabsFlag[tabActive + 1] = { ...nextTabActive };
		setContainerPosition(containerPosition + 1);
		setTabsEle(tabsFlag);
		setTabActive(tabActive + 1);
	};

	const changeTabPrevious = () => {
		const tabsFlag = [...tabsEle];
		const tabFlag = {
			...tabsFlag[tabActive],
			disabled: false,
			orderSM: tabsFlag[tabActive].orderSM + 1,
		};
		const previousTabActive = {
			...tabsFlag[tabActive - 1],
			checked: false,
			disabled: false,
		};

		tabsFlag[tabActive] = { ...tabFlag };
		tabsFlag[tabActive - 1] = { ...previousTabActive };
		setTabsEle(tabsFlag);
		setContainerPosition(containerPosition - 1);
		setTabActive(tabActive - 1);
	};

	const onValues = (values: any) => {
		let ObjTemp: any = dataObj;

		if (values.name === 'NSS') {
			ObjTemp.nss = values.value;
			setdataObj(ObjTemp);
		}

		if (values.name === 'DatosContactoMedioComunicacion') {
			ObjTemp.medioComucacion = values.value;
			setdataObj(ObjTemp);
		}
		if (values.name === 'DatosContactoEmail') {
			ObjTemp.email = values.value;
			setdataObj(ObjTemp);
		}
		if (values.name === 'DatosContactoPhone') {
			ObjTemp.phone = values.value;
			setdataObj(ObjTemp);
		}

		if (values.name === 'codigoVerificacion') {
			ObjTemp.code = values.value;
			setdataObj(ObjTemp);
		}

		if (values.name === 'Password') {
			ObjTemp.password = values.value;
			setdataObj(ObjTemp);
		}
	};

	const onPasswordRecovery = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(dataObj.nss || '', key);

			resetPasswordAPI
				.passwordRecovery({
					nss: nss_base64,
				})
				.then((res: any) => {
					if (res?.msg) {
						let ObjTemp: any = dataObj;
						const { enableUpdateEmail, enableUpdatePhone } =
							res?.msg;
						ObjTemp.email = res.msg?.email || '';
						ObjTemp.phone = res.msg?.celular || '';
						ObjTemp.enableUpdateEmail = enableUpdateEmail || false;
						ObjTemp.enableUpdatePhone = enableUpdatePhone || false;
						setdataObj(ObjTemp);
						regBitacoraNotOld(
							resetPasswordAPI.schemeUrl,
							{ ...ObjTemp },
							{ signalKey: key, identificador: dataObj.nss }
						);
						changeTabNext();
					} else {
						setAlertNSS({
							show: true,
							message: res.description
								? res.description
								: INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
					}
				})
				.catch((error) => {
					setAlertNSS({
						show: true,
						message: error.description
							? error.description
							: 'Por el momento el servicio no se encuentra disponible, intenta más tarde',
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error: any) {
			setAlertNSS({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const onReSendMessage = async () => {
		let medio = dataObj.medioComucacion;
		setdataObj({
			...dataObj,
			medioComucacion: medio,
		});
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const band_base64 = await encrypt(
				dataObj.medioComucacion === 'Por celular' ? '1' : '2',
				key
			);
			const nss_base64 = await encrypt(dataObj.nss || '', key);
			sendMessage
				.post<any, any>('', {
					band: band_base64,
					nss: nss_base64,
				})
				.then(() => {
					regBitacoraNotOld(
						sendMessage.schemeUrl,
						{
							medioComucacion: dataObj.medioComucacion,
							nss: dataObj.nss,
						},
						{ signalKey: key, identificador: dataObj.nss }
					);
				})
				.catch((error) => {
					setAlertDatosContacto({
						show: true,
						message:
							error?.err?.description ||
							INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => {
					setLoading(false);
				});
		} catch {
			setAlertDatosContacto({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const onSendMessage = async () => {
		setLoading(true);

		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const band_base64 = await encrypt(
				dataObj.medioComucacion === 'Por celular' ? '1' : '2',
				key
			);
			const nss_base64 = await encrypt(dataObj.nss, key);
			sendMessage
				.post<any, any>('', {
					band: band_base64,
					nss: nss_base64,
				})
				.then(() => {
					regBitacoraNotOld(
						sendMessage.schemeUrl,
						{
							medioComucacion: dataObj.medioComucacion,
							nss: dataObj.nss,
						},
						{ signalKey: key, identificador: dataObj.nss }
					);
					changeTabNext();
				})
				.catch((error: any) => {
					setAlertDatosContacto({
						show: true,
						message:
							error.err?.description ||
							INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => {
					setLoading(false);
				});
		} catch (error: any) {
			setAlertDatosContacto({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const onValidCode = async () => {
		setLoading(true);

		try {
			if (dataObj.medioComucacion !== 'Por celular') {
				navigate('/login');
				return;
			}

			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(dataObj.nss || '', key);
			const code_base64 = await encrypt(dataObj.code || '', key);
			validCode
				.post<any, any>('', {
					nss: nss_base64,
					code: code_base64,
				})
				.then((res: any) => {
					if (!res.result.valid) {
						setAlertVerificacion({
							show: true,
							message:
								res.result.message ||
								INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});

						return;
					}
					if (res?.result?.valid !== 'Codigo Valido') {
						setAlertVerificacion({
							show: true,
							message: res.result.valid,
							severity: 'error',
						});
						return;
					}

					regBitacoraNotOld(
						validCode.schemeUrl,
						{ code: dataObj.code, nss: dataObj.nss },
						{ signalKey: key, identificador: dataObj.nss }
					);
					changeTabNext();
				})
				.catch((error) => {
					setAlertVerificacion({
						show: true,
						message:
							error?.err?.description ||
							INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => {
					setLoading(false);
				});
		} catch (error: any) {
			setAlertVerificacion({
				show: true,
				message:
					error?.err?.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const onResetPassword = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();

			const nss_base64 = await encrypt(dataObj.nss || '', key);
			const email_base64 = await encrypt(dataObj.email || '', key);
			const password_base64 = await encrypt(dataObj.password || '', key);

			resetPassword
				.post<any, any>('', {
					nss: nss_base64,
					email: email_base64,
					password: password_base64,
				})
				.then((res: any) => {
					if (!res.msg) {
						setAlertPassword({
							show: true,
							message: INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
						return;
					}

					if (!res.msg.result) {
						setAlertPassword({
							show: true,
							message: INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
						return;
					}

					if (res?.msg?.result !== 'Actualizacion exitosa') {
						setAlertPassword({
							show: true,
							message: INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
						return;
					}

					regBitacoraNotOld(
						resetPassword.schemeUrl,
						{
							email: dataObj.email,
							nss: dataObj.nss,
							password: getMaskString(dataObj.password),
						},
						{ signalKey: key, identificador: dataObj.nss }
					);
					changeTabNext();
				})
				.catch((error) => {
					setAlertPassword({
						show: true,
						message:
							error.err?.description ||
							error?.description ||
							INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => {
					setLoading(false);
				});
		} catch {
			setAlertPassword({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const onClearAlerts = (tabName: string) => {
		if (tabName === 'NSS') {
			setAlertNSS({
				show: false,
				message: '',
				severity: 'success',
			});
		}
		if (tabName === 'NSS2') {
			setAlertNSS2({
				show: false,
				message: '',
				severity: 'success',
			});
		}
		if (tabName === 'Datos Contacto') {
			setAlertDatosContacto({
				show: false,
				message: '',
				severity: 'success',
			});
		}
	};

	const onChangeNSSValidation = (value: boolean) => {
		let ObjTemp: any = dataObj;
		ObjTemp.nssValidate = value;
		setdataObj(ObjTemp);
	};

	const onValidateNSS = (values: any) => {
		const _nss: string = values.target.value;
		onChangeNSSValidation(false);
		onClearAlerts('NSS2');

		if (_nss.length === 11) {
			onValidateDeathMarkNSS(_nss);
		}
	};

	const onValidateCheckNSS = async (nss: string) => {
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(nss || '', key);
			const dataFlag = {
				nss: nss_base64,
			};
			resetPasswordAPI
				.ValidCheckNSS({ ...dataFlag })
				.then((res: any) => {
					if (res?.result?.nss !== '') {
						onChangeNSSValidation(false);
						setRefreshCaptcha(!refreshCaptcha);
						setAlertNSS2({
							show: true,
							message: res.result.nss,
							severity: 'error',
						});
					} else {
						regBitacoraNotOld(
							resetPasswordAPI.schemeUrl,
							{ nss },
							{ signalKey: key, identificador: nss }
						);
						onChangeNSSValidation(true);
					}
				})
				.catch((error) => {
					setAlertNSS2({
						show: true,
						message:
							error.err?.description ||
							INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});

					setRefreshCaptcha(!refreshCaptcha);
				});
		} catch (error) {
			setRefreshCaptcha(!refreshCaptcha);
			setLoading(false);
		}
	};

	const onValidateDeathMarkNSS = async (nss: string) => {
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(nss || '', key);
			const dataFlag = {
				nss: nss_base64,
			};
			resetPasswordAPI
				.ValideDeathMark({ ...dataFlag })
				.then((res: any) => {
					if (res?.result?.marca !== 'activo') {
						onChangeNSSValidation(false);

						setAlertNSS2({
							show: true,
							message: INTERNAL_MESSAGES.INCONSISTENT_SSN,
							severity: 'error',
						});
					} else {
						onValidateCheckNSS(nss);
					}
				})
				.catch((error) => {
					setAlertNSS2({
						show: true,
						message:
							error.err?.description ||
							INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => {});
		} catch (error) {}
	};

	const styles = useStyles();
	return (
		<>
			<ModalLoading loading={loading} />
			<Tabs
				tabs={tabsEle}
				containerPosition={containerPosition}
				active={tabActive}
				cardsType
			>
				<Paper>
					{tabActive === 0 && (
						<Box sx={{ width: '100%' }}>
							<NssView
								onNextTab={onPasswordRecovery}
								onPrevTab={changeTabPrevious}
								onValues={onValues}
								getValue={dataObj}
								onAlert={alertNSS}
								onAlert2={alertNSS2}
								onClearAlert={onClearAlerts}
								onValidateNSS={onValidateNSS}
								refreshCaptcha={refreshCaptcha}
							/>
						</Box>
					)}
					{tabActive === 1 && (
						<Box sx={{ width: '100%' }}>
							<DatosContactoView
								onNextTab={onSendMessage}
								onPrevTab={changeTabPrevious}
								onValues={onValues}
								getValue={dataObj}
								onAlert={alertDatosContacto}
								onClearAlert={onClearAlerts}
							/>
						</Box>
					)}
					{tabActive === 2 && (
						<Box sx={{ width: '100%' }}>
							<VerificacionView
								onNextTab={onValidCode}
								onPrevTab={changeTabPrevious}
								onValues={onValues}
								getValue={dataObj}
								onReSendMessage={onReSendMessage}
								onAlert={alertVerificacion}
								onClearAlert={onClearAlerts}
							/>
						</Box>
					)}

					{tabActive === 3 && (
						<Box sx={{ width: '100%' }}>
							<ContraseñaView
								onNextTab={onResetPassword}
								onPrevTab={changeTabPrevious}
								onValues={onValues}
								getValue={dataObj}
								onAlert={alertPassword}
								onClearAlert={onClearAlerts}
							/>
						</Box>
					)}
					{tabActive === 4 && (
						<Box sx={{ width: '100%' }}>
							<ConfirmacionView
								onNextTab={changeTabNext}
								onPrevTab={changeTabPrevious}
								onValues={onValues}
								getValue={dataObj}
								onAlert={alertNSS}
								onClearAlert={onClearAlerts}
							/>
						</Box>
					)}
					<Box
						sx={{
							margin: '20px',
							height: '40px',
							display:
								tabActive === 0 ||
								tabActive === 1 ||
								tabActive === 3
									? 'block'
									: 'none',
						}}
					>
						<div className={styles.divider} />
						<span className={styles.DatosObligatorios}>
							* Datos obligatorios
						</span>
					</Box>
				</Paper>
			</Tabs>
		</>
	);
};

const LayoutView = () => {
	return (
		<Layout onlyTitle title="Restablece tu contraseña">
			<TabsView />
		</Layout>
	);
};
export default LayoutView;
