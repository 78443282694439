// DEPENDENCIES
import React, { useState } from 'react';
import { Divider, LinearProgress, Box } from '@mui/material';
import { useSelector } from 'react-redux';
// COMPONENTS
import VerificationCode from '../../../../components/VerificationCode';
import ModalLoading from '../../../../components/ModalLoading';
import CustomButton from '../../../../components/Button';
import TextInput from '../../../../components/TextInput';
// RESOUCERS
import { isNumber } from '../../../../utils/expressions';
import { INTERNAL_MESSAGES } from '../../../../config/messageCatalog';
import { encrypt } from '../../../../utils/encrypt';
import { defaultMobileForm } from '../utils/utils';
// ASSETS
import { useStyles } from '../styles';
import { contactRequestAPI } from '../../../../api/modules/profile';
import { createAccountAPI } from '../../../../api/modules/createAccount';
import { RootState } from '../../../../store';
import { keyAPI } from '../../../../api/modules/key';
import { IAlert } from '../../../../interfaces/alert';
import CustomAlert from '../../../../components/CustomAlert';
import cel from '../../../../assets/img/cel.png';

interface IContactMobile {
	fetchContactData: () => void;
	cancel: () => void;
}

const ContactMobile = (props: IContactMobile) => {
	const { fetchContactData, cancel } = props;
	const { user } = useSelector((state: RootState) => state.session);
	const classes = useStyles();
	const [progress, setProgress] = useState(0);
	const [step, setStep] = useState(1);
	const [contact, setContact] = useState(defaultMobileForm);
	const [errors, setErrors] = useState(defaultMobileForm);
	const [loader, setLoader] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		message: '',
		severity: 'success',
		show: false,
	});
	const [alertLocal, setAlertLocal] = useState<IAlert>({
		message: '',
		severity: 'success',
		show: false,
	});
	const [errorValidation, setErrorValidation] = useState(false);
	const [errorPhoneFinal, setErrorPhoneFinal] = useState(false);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		if (isNumber(value) && value.length <= 10) {
			setContact({ ...contact, [name]: value });
			if (!value) {
				setErrors({
					...errors,
					[name]: INTERNAL_MESSAGES.WRITE_PHONE_NUMBER,
				});
				if (name === 'phoneNumber') {
					setErrorPhoneFinal(false)
				}
			} else if (value.length !== 10) {
				setErrors({
					...errors,
					[name]: INTERNAL_MESSAGES.LAST_TEN_DIGITS_CELL_PHONE,
				});
				if (name === 'phoneNumber') {
					setErrorPhoneFinal(false)
				}
			} else if (name === 'confirm_phoneNumber' && value !== contact.phoneNumber) {
				setErrors({
					...errors,
					confirm_phoneNumber:
						INTERNAL_MESSAGES.VERIFY_CELL_PHONE,
				});
			} else {
				setErrors(defaultMobileForm);
			}
		}
	};

	const handleStep = (newStep: number, newProgress: number) => {
		setStep(newStep);
		setProgress(newProgress);
	};

	const handleSendCode = async () => {
		try {
			setLoader(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const phone_base64 = await encrypt(contact?.phoneNumber?.toString() || '', key);

			contactRequestAPI.updatePhoneNumber
				.post<{ phoneNumber: string }, any>('', {
					phoneNumber: phone_base64,
				})
				.then((res) => {
					if (res?.ok) {
						handleStep(2, 50);
					} else {
						setAlertLocal({
							show: true,
							message: INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
					}
				})
				.catch((error) => {
					setAlertLocal({
						show: true,
						message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => setLoader(false));
		} catch (error: any) {
			setAlertLocal({
				show: true,
				message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoader(false);
		}
	};

	const handleConfirm = async () => {
		try {
			setLoader(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const phone_base64 = await encrypt(contact?.phoneNumber?.toString() || '', key);

			contactRequestAPI.validationPhone
				.post<{ celular: string }, any>('', {
					celular: phone_base64,
				})
				.then((res) => {
					handleStep(2, 50);
					if (res?.result?.codigo === '1') {
						setErrorValidation(false);
						handleSendCode();
					} else {
						setErrorValidation(true);
					}
				})
				.catch((e) => {
					setAlertLocal({
						show: true,
						message: e.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
					throw e;
				})
				.finally(() => setLoader(false));
		} catch (error: any) {
			setAlertLocal({
				show: true,
				message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoader(false);
		}
	};

	const confirmPhone = async (values: any) => {
		try {
			setLoader(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const code_base64 = await encrypt(values, key);
			const phone_base64 = await encrypt(contact?.phoneNumber?.toString() || '', key);
			const USER_NSS_BASE64 = await encrypt(user?.nss?.toString() || '', key);

			contactRequestAPI.confirmPhoneNumber
				.post<{ nss: string; code: string; phoneNumber: string }, any>('', {
					nss: USER_NSS_BASE64,
					code: code_base64,
					phoneNumber: phone_base64,
				})
				.then((res) => {
					if (res?.ok) {
						handleStep(3, 100);
						setAlert({
							message: INTERNAL_MESSAGES.APPLICATION_COMPLETED,
							severity: 'success',
							show: true,
						});
					} else {
						setAlertLocal({
							show: true,
							message: INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
					}
				})
				.catch((error) => {
					setAlertLocal({
						show: true,
						message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => setLoader(false));
		} catch (error: any) {
			setAlertLocal({
				show: true,
				message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoader(false);
		}
	};

	const handleClose = () => {
		setAlert({ message: '', severity: 'success', show: false });
	};

	const handleValidatePhone = async () => {
		if (!contact.phoneNumber) {
			return;
		}
		if (contact.phoneNumber.length < 10) {
			return;
		}
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const phone_base64 = await encrypt(contact.phoneNumber, key);
			createAccountAPI
				.validatePhone(phone_base64)
				.then((res) => {
					setErrors(defaultMobileForm);
					setErrorPhoneFinal(true)
				})
				.catch((err) => {
					setErrorPhoneFinal(false)
					if (err.description) {
						setErrors({
							...errors,
							phoneNumber: err.description
						});
					}
				});
		} catch (error) {
			setErrorPhoneFinal(false)
		}
	};

	return (
		<>
			<ModalLoading loading={loader} />
			<p>Tu teléfono celular se modificará.</p>
			<p className={classes.stepLabel}>{step} de 3 completados</p>
			<LinearProgress variant="determinate" value={progress} color="info" />
			{step === 1 && !errorValidation && (
				<div className={classes.mailformContainer}>
					<TextInput
						isRequired
						label="Teléfono celular"
						id="mobile"
						name="phoneNumber"
						error={Boolean(errors.phoneNumber)}
						helperText={errors.phoneNumber}
						value={contact.phoneNumber}
						onChange={handleChange}
						noCopy={true}
						noPaste={true}
						onBlur={handleValidatePhone}
					/>
					<TextInput
						isRequired
						label="Confirmar teléfono celular"
						id="confirm_phoneNumber"
						name="confirm_phoneNumber"
						error={Boolean(errors.confirm_phoneNumber)}
						helperText={errors.confirm_phoneNumber}
						value={contact.confirm_phoneNumber}
						onChange={handleChange}
						noCopy={true}
						noPaste={true}
						disabled={!errorPhoneFinal}
					/>
				</div>
			)}
			{step === 2 && !errorValidation && (
				<div className={classes.confirmationContainer}>
					<VerificationCode
						isEmail={false}
						isSixDigits={true}
						setCode={confirmPhone}
						reSend={handleSendCode}
						hasError={alert}
						onCloseError={handleClose}
					/>
				</div>
			)}
			{step === 3 && !errorValidation && (
				<div className={classes.congratulationsContainer}>
					<h3>¡Felicidades!</h3>
					<p>
						Tu número celular se ha modificado exitosamente, ahora puedes
						seguir disfrutando de los servicios que Mi Cuenta Infonavit tiene
						para ti.
					</p>
				</div>
			)}
			{errorValidation && (
				<div className={classes.confirmationContainer}>
					<Box className={classes.VerificacionLogo}>
						<img alt="mobile" src={cel} />
					</Box>
					<Box
						sx={{
							alignItems: 'center',
							justifyContent: 'center',
							display: 'flex',
							marginTop: '20px',
						}}
					>
						<p style={{ margin: 0 }} className={classes.VerificacionCodigoTitle}>
							<strong>Confirmación de número celular</strong>
						</p>
					</Box>
					<Box
						sx={{
							alignItems: 'center',
							justifyContent: 'center',
							display: 'flex',
							marginBottom: '35px',
						}}
					>
						<p className={classes.VerificacionText}>
							<strong>
								El número de teléfono celular que nos proporcionaste no
								existe, por favor actualízalo <br />
								para continuar con tu registro.
							</strong>
						</p>
					</Box>
					<div className={classes.numbersVerification}>
						<TextInput
							isRequired
							label="Teléfono celular"
							id="mobile"
							name="phoneNumber"
							error={Boolean(errors.phoneNumber)}
							helperText={errors.phoneNumber}
							value={contact.phoneNumber}
							onChange={handleChange}
						/>
						<TextInput
							isRequired
							label="Confirmar teléfono celular"
							id="confirm_phoneNumber"
							name="confirm_phoneNumber"
							error={Boolean(errors.confirm_phoneNumber)}
							helperText={errors.confirm_phoneNumber}
							value={contact.confirm_phoneNumber}
							onChange={handleChange}
						/>
					</div>
				</div>
			)}
			<CustomAlert
				show={alertLocal.show || false}
				severity={alertLocal.severity || 'success'}
				message={alertLocal.message || ''}
				onClose={() =>
					setAlertLocal({ message: '', severity: 'success', show: false })
				}
			/>
			<div className={classes.actionsContainer}>
				{step === 1 && !errorValidation && (
					<CustomButton
						disabled={false}
						variant="outlined"
						onClick={() => {
							setErrors(defaultMobileForm);
							setAlert({ show: false, message: '', severity: 'success' });
							setContact(defaultMobileForm);
							setAlertLocal({
								message: '',
								severity: 'success',
								show: false,
							});
							handleClose();
							cancel();
						}}
						label="Cancelar"
					/>
				)}
				{step === 1 && !errorValidation && (
					<CustomButton
						disabled={
							!Boolean(contact.phoneNumber) ||
							contact.phoneNumber.length < 10 ||
							contact.phoneNumber !== contact.confirm_phoneNumber
						}
						variant="solid"
						onClick={handleConfirm}
						label="Continuar"
					/>
				)}
				{step === 3 && !errorValidation && (
					<CustomButton
						disabled={false}
						variant="solid"
						onClick={() => {
							handleStep(1, 0);
							setContact(defaultMobileForm);
							fetchContactData();
							cancel();
						}}
						label="Finalizar"
					/>
				)}
				{errorValidation && (
					<CustomButton
						disabled={
							!Boolean(contact.phoneNumber) ||
							contact.phoneNumber.length < 10 ||
							contact.phoneNumber !== contact.confirm_phoneNumber
						}
						variant="solid"
						onClick={handleConfirm}
						label="Validar"
					/>
				)}
			</div>
			<div className={classes.containerRequiredFields}>
				<Divider />
				<p className={classes.requiredFieldsLabel}>* Datos obligatorios</p>
			</div>
		</>
	);
};

export default ContactMobile;
