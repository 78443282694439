import { Box } from '@mui/material';
import GetViews from './components/GetViews';
import logotipo from '../../../../assets/img/taller-saber-mas/logo-saber-mas-para-decidir-mejor-2022.png';
import { TallerContext } from './context';
import useTallerMas from './hooks/useElements';

const TallerSaberMas = () => {
	const { matches } = useTallerMas();
	return (
		<TallerContext>
			<Box
				display={'flex'}
				flexDirection={matches ? 'column' : 'row'}
				justifyContent={matches ? 'center' : 'normal'}
				margin={'0px 0px 40px 0px'}
			>
				<Box textAlign={'center'}>
					<img
						src={logotipo}
						alt="Logotipo taller saber mas"
						style={{ width: '200px' }}
					/>
				</Box>
				<Box>
					<h2
						style={{
							color: '#283990',
							margin: matches ? '0px 0px 10px 0px' : 0,
							fontWeight: 500,
						}}
					>
						Curso Saber más para decidir mejor
					</h2>
					<p style={{ margin: '5px 0px 0px 0px' }}>
						Bienvenido a tu curso{' '}
						<b>Saber más para decidir mejor,</b> que te orientará
						acerca del uso que le puedes dar a tu ahorro, además de
						brindarte información de educación financiera que te
						ayudará a tomar la mejor opción para generar tu
						patrimonio familiar.
					</p>
					<p style={{ margin: '5px 0px 0px 0px' }}>
						Este curso lo debes hacer antes de solicitar tu crédito,
						es requisito obligatorio individual y su constancia
						tiene una vigencia de un año.
					</p>
				</Box>
			</Box>
			{/* Pantallas principales */}
			<GetViews />
		</TallerContext>
	);
};

export default TallerSaberMas;
