import API from '../';
interface IMainResponse<I> {
	result: {
		code: string;
		message: string;
		data: I;
	};
}
class WritingRequestAPI extends API {
	getCaso(): Promise<IMainResponse<any>> {
		return this.requestWrapper(
			this.request({
				url: `entrega-escritura/consulta-caso`,
				method: 'POST',
				data: {},
			})
		);
	}

	getValidarCaso(data: string): Promise<IMainResponse<any>> {
		return this.requestWrapper(
			this.request({
				url: `entrega-escritura/generico-valida`,
				method: 'POST',
				data,
			})
		);
	}

	getCatalogoEstado(): Promise<IMainResponse<any>> {
		return this.requestWrapper(
			this.request({
				url: `entrega-escritura/catalogo-estado`,
				method: 'POST',
				data: {},
			})
		);
	}

	getCatalogoCesi(data: string): Promise<IMainResponse<any>> {
		return this.requestWrapper(
			this.request({
				url: `entrega-escritura/catalogo-cesi`,
				method: 'POST',
				data,
			})
		);
	}

	createCaso(data: string): Promise<IMainResponse<any>> {
		return this.requestWrapper(
			this.request({
				url: `entrega-escritura/crea-caso`,
				method: 'POST',
				data,
			})
		);
	}

	consultDetailCaso(data: string): Promise<IMainResponse<any>> {
		return this.requestWrapper(
			this.request({
				url: `entrega-escritura/consulta-detalle-caso`,
				method: 'POST',
				data,
			})
		);
	}

	consultDocs(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `entrega-escritura/consulta-documentos`,
				method: 'POST',
				data,
			})
		);
	}

	adjuntaDocumento(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `entrega-escritura/adjunta-documentos`,
				method: 'POST',
				data,
			})
		);
	}

	actualizarEstatus(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `entrega-escritura/actualiza-estatus`,
				method: 'POST',
				data,
			})
		);
	}

	// --------------- Todo relacionado con las citas --------------------------------------------
	documentosRequeridos(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: '/v1/citas-24/obtener-requisitos',
				method: 'POST',
				data,
			})
		);
	}

	statusService(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: '/v1/citas-24/obtener-regiones',
				method: 'POST',
				data,
			})
		);
	}

	cesis(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: '/v1/citas-24/obtener-sucursales',
				method: 'POST',
				data,
			})
		);
	}

	meses(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: '/v1/citas-24/obtener-programacion-mensual',
				method: 'POST',
				data,
			})
		);
	}

	horario(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: '/v1/citas-24/obtener-programacion-laboral',
				method: 'POST',
				data,
			})
		);
	}

	reservar(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/reservar-cita`,
				method: 'POST',
				data,
			})
		);
	}

	crear(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/crear-cita`,
				method: 'POST',
				data,
			})
		);
	}
	
	actualizar(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/crear-cita`,
				method: 'POST',
				data,
			})
		);
	}

	cancelar(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/cancelar-cita`,
				method: 'POST',
				data,
			})
		);
	}

	citasActuales(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/obtener-cita`,
				method: 'POST',
				data,
			})
		);
	}

	downloadPDF(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/generar-comprobante`,
				method: 'POST',
				data,
			})
		);
	}
}

export const writingRequestAPI = new WritingRequestAPI('');
