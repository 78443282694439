import { Paper, Grid } from '@mui/material';
import { useStyles } from './styles';
import CustomButton from '../../../components/Button';
import { HeaderList } from '../../../components/HeaderList';
import checkicon from '../../../assets/img/mini-check-azul.png';
import { IAlert } from '../../../interfaces/alert';
import CustomAlert from '../../../components/CustomAlert';
import MessageCustom from '../../../components/MessageCustom';

interface ListToHave {
	id: number;
	icon: string;
	title: string;
}

const LISTTODO: ListToHave[] = [
	{
		id: 1,
		icon: checkicon,
		title: 'La carta de instrucción de cancelación de hipoteca es el documento que te expide el Infonavit cuando terminas de pagar tu crédito.',
	},
	{
		id: 2,
		icon: checkicon,
		title: 'Esta carta debes entregarla al notario que elegiste para que lleve a cabo la liberación de tu hipoteca y quede registrada en el Registro Público de la Propiedad.',
	},
	{
		id: 3,
		icon: checkicon,
		title: 'En algunos estados, ese trámite también se puede realizar directamente ante el Registro Público de la Propiedad.',
	},
	{
		id: 4,
		icon: checkicon,
		title: 'La vigencia de la carta de cancelación es de 180 días naturales, si en ese tiempo no la entregas al notario (solo al notario), deberás iniciar el trámite nuevamente.',
	},
];

interface Props {
	setPage: (data: number) => void;
	caso: boolean;
	hasError: IAlert;
}

export const InfoView = ({ setPage, caso, hasError }: Props) => {
	const classes = useStyles();

	const handleClick = () => {
		if (caso === true) {
			setPage(6);
		} else {
			setPage(0);
		}
	};

	return (
		<div>
			<HeaderList
				title="Carta de instrucción de cancelación de hipoteca"
				date="Información al 12 julio 2021"
				list={[]}
			/>
			<Paper className={classes.divPrincipal}>
				<h4 style={{ marginLeft: -15 }} className={classes.maxContent}>Aspectos Importantes</h4>

				{LISTTODO.map((sm) => (
					<Grid
						item
						xs={12}
						lg={12}
						className={classes.divChecks}
						key={sm.id}
					>
						<img
							className={classes.iconsImg}
							alt="checkicon"
							src={sm.icon}
						></img>
						<p className={classes.txtPrincipales}>
							{sm.title}
						</p>
						<br></br>
					</Grid>
				))}
				<br></br>
				<br></br>

				<CustomAlert
					show={hasError.show}
					severity={hasError.severity}
					message={''}
				>
					<MessageCustom
						msg={hasError.message.toString()}
					></MessageCustom>
				</CustomAlert>
				<CustomButton
					onClick={handleClick}
					variant="solid"
					styles={{ width: 260, margin: '50px auto 30px' }}
					disabled={hasError.show}
					label="Continuar"
				/>
			</Paper>
		</div>
	);
};

export default InfoView;
