/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';

import CustomButton from '../../../components/Button';
import CustomAlert from '../../../components/CustomAlert';
import ModalLoading from '../../../components/ModalLoading';
import { Select, MenuItem } from '@mui/material';

import { dataReturnSavingsContext } from '../contextData';
import { IRequestCreacionCaso } from '../interfaces';
import { useStyles } from '../style';
import { typeDemand } from '../utils';

const SelectTypeDemand = () => {
	const style = useStyles();
	const [disabledButton, setDisabledButton] = useState<boolean>(true);
	const [optionsTypeDemand, setOptionsTypeDemand] = useState<
		{ value: string; text: string }[]
	>([]);
	const [optionSelected, setOptionSelected] = useState<string>('');

	const {
		handlePrevPage,
		setData,
		data,
		handleCreacionCaso,
		alert,
		loading,
		setAlert,
	} = useContext(dataReturnSavingsContext);

	useEffect(() => {
		let newArray: { value: string; text: string }[] = [];
		typeDemand.forEach((state: { name: string; code: string }) => {
			newArray.push({ value: state.code, text: state.name });
		});

		setOptionsTypeDemand(newArray);
	}, [typeDemand]);

	useEffect(() => {
		//TODO.- Pasar a Enum

		let HasDemand =
			optionSelected === '1' || optionSelected === '3'
				? true
				: optionSelected === '2'
				? false
				: data?.hasDemand;
		let Tipificacion =
			optionSelected === '1'
				? 'A1ZN000186ZN01'
				: optionSelected === '2'
				? 'A1ZN000127ZN02'
				: optionSelected === '3'
				? 'A1ZN000186ZN01'
				: data?.tipificacion;

		let ClaseOperacion =
			optionSelected === '1'
				? 'ZG1C'
				: optionSelected === '2'
				? 'ZG1D'
				: optionSelected === '3'
				? 'ZG1C'
				: data?.claseOperacion;

		let Grupo =
			optionSelected === '1'
				? '2'
				: optionSelected === '2'
				? '4'
				: optionSelected === '3'
				? '3'
				: data?.grupo;

		setData({
			...data,
			typeDemand: optionSelected,
			claseOperacion: ClaseOperacion,
			grupo: Grupo,
			hasDemand: HasDemand,
			tipificacion: Tipificacion,
		});
	}, [optionSelected]);

	const handleOnSubmit = () => {
		const params: IRequestCreacionCaso = {
			evento: '4',
			opcion: data?.returnOptionType || '',
			demanda: data?.hasDemand || false,
			tipoDemanda: data?.typeDemand || '',
			entidad: data?.returnSavingsStates || '',
			montoDevolver: data?.savingsInformationTotal || '0',
			montoSaldo92: data?.savingsInformation?.subaccount_1992 || '0',
			montoSaldo97: data?.savingsInformation?.subaccount_1997 || '0',
			montoSaldoFA: data?.savingsInformation?.savingFund || '0',
			montoAdicionalSaldoFA:
				data?.savingsInformation?.additionalQuantity || '0',
			descMontoAdicionalSaldoFA: '',
			numeroCredito: '',
			nrp: '',
			codigoRechazoFa: '',
			grupo: data?.grupo || '',
		} as IRequestCreacionCaso;
		handleCreacionCaso(params);
	};

	return (
		<div>
			<ModalLoading loading={loading}></ModalLoading>
			<label className={style.TextBlack_H3}>Tipo de demanda</label>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className={style.divNRP}>
					<Select
						displayEmpty
						name="typeDemand"
						value={optionSelected}
						onChange={(event: any) => {
							setDisabledButton(false);
							setOptionSelected(event.target.value);
							setAlert({
								show: false,
								severity: 'success',
								message: '',
							});
						}}
						renderValue={
							optionSelected !== ''
								? undefined
								: () => (
										<label
											style={{
												fontSize: 15,
												color: '#7F7F7F',
											}}
										>
											Selecciona el tipo de demanda
										</label>
								  )
						}
						style={{
							height: 40,
							width: '100%',
						}}
					>
						{optionsTypeDemand.map(
							(element: { value: string; text: string }) => (
								<MenuItem
									key={element.value}
									value={element.value}
								>
									{element.text}
								</MenuItem>
							)
						)}
					</Select>
				</div>
			</div>
			<div className={style.divAlert}>
				<CustomAlert
					message={alert.message}
					show={alert.show}
					severity={alert.severity}
				/>
			</div>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className={style.divButtons}>
					<div className={style.Buttons}>
						<CustomButton
							onClick={handlePrevPage}
							variant="outlined"
							styles={{
								width: '100%',
								height: '100%',
							}}
							label="Regresar"
						/>
					</div>
					<div className={style.Buttons}>
						<CustomButton
							onClick={handleOnSubmit}
							variant="solid"
							styles={{
								width: '100%',
							}}
							label="Continuar"
							disabled={disabledButton}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SelectTypeDemand;
