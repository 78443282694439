import { useState } from 'react';
import Layout from '../../components/Layout';
import HomePage from './pages/HomePage';
import ProcessPage from './pages/ProcessPage';
import { HeaderList } from '../../components/HeaderList';
import EscriturasContext from './context';
import { IEscrituras } from './context/utils';
import DeedsDelivery from '../DeedsDelivery';
import {
	LABELS,
	PROCESS_PAGE_STATUS,
	WRITING_REQUEST_STATUS,
} from './pages/utils/pagesStatus';

const WritingRequest = () => {
	const [page, setPage] = useState(WRITING_REQUEST_STATUS.HOME);
	const [pageTab, setPageTab] = useState(PROCESS_PAGE_STATUS.FORM_EDITION);
	const [escrituras, setEscrituras] = useState<IEscrituras>(
		{} as IEscrituras
	);

	return (
		<Layout>
			<EscriturasContext.Provider value={[escrituras, setEscrituras]}>
				<>
					<HeaderList
						title={
							page === WRITING_REQUEST_STATUS.PAGE_OLD
								? LABELS.HOME_OLD // Titulo para el flujo anterior
								: LABELS.HOME_NOW // Titulo para el flujo nuevo
						}
					/>
					{/* Inicio del flujo */}
					{page === WRITING_REQUEST_STATUS.HOME && (
						<HomePage setPage={setPage} setPageTab={setPageTab} />
					)}
					{/* Modulo del proceso del flujo */}
					{page === WRITING_REQUEST_STATUS.PROCESS && (
						<ProcessPage setPage={setPage} pageTab={pageTab} />
					)}
					{/* Flujo anterior */}
					{page === WRITING_REQUEST_STATUS.PAGE_OLD && (
						<DeedsDelivery />
					)}
				</>
			</EscriturasContext.Provider>
		</Layout>
	);
};

export default WritingRequest;
