import React, { useCallback, useEffect, useState } from 'react';
import { sharedResponsabilityAPI } from '../../../../api/modules/sharedResponsability';
import SelectInput from '../../../../components/SelectInput';
import TextInput from '../../../../components/TextInput';
import { isNumberValidate } from '../../../../utils/expressions';
import { RoundBtn } from '../../../AssociateNssCredit/Styles';
import { useStyles } from '../../styles';

const Location = ({ step, setStep, creditData, selectedCredit, setSelectedCredit }: any) => {
	const classes = useStyles();
	const [stateOptions, setStateOptions] = useState([])
	const fetchStates = useCallback(async () => {
		const { result } = await sharedResponsabilityAPI.statesCatalog()
		if (result && result.length > 0)
			setStateOptions(result.map(({ codigo, descripcion }: any) => ({ value: codigo, text: descripcion })))
	}, [])

	useEffect(() => {
		fetchStates()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleChange = ({ target }: any) => {
		const { name, value } = target
		setSelectedCredit({ ...selectedCredit, [name]: value })
	}

	return (
		<>
			<h5 className={classes.maxContent}>Datos donde obtuviste tu crédito:</h5>
			<div className="location-form">
				<SelectInput
					name='estado'
					label="Estado"
					onChange={handleChange}
					id="responsability-state"
					value={selectedCredit?.estado || ''}
					options={stateOptions}
				/>
				<TextInput
					name='cp'
					label="Código postal"
					id="responsability-zipcode"
					value={selectedCredit?.cp || ''}
					onChange={(e: any) => isNumberValidate(e.target.value) && e.target.value.length <= 5 && handleChange(e)}
				/>
			</div>
			<div className='actions-buttons'>
				<RoundBtn disabled={!selectedCredit?.estado || !selectedCredit?.cp || selectedCredit?.cp?.length !== 5} variant='contained' onClick={() => setStep(step + 1)}>Continuar</RoundBtn>
			</div>
		</>
	);
};

export default Location;
