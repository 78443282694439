export const PROFILE_ROUTES: { [key: string]: string } = {
	'creditos': 'Créditos DH',
	'confirmacion-de-correo': 'Confirmación de Correo',
	'impersonalizacion': 'Impersonalizacion',
	'inicio': 'Inicio',
	'login': 'Login',
	'logout': 'Cierre de Sesión',
	'mi-perfil': 'Mi perfil',
	'registro': 'Registro',
	'restablece-tu-contrasena': 'Restablece Contraseña',
	'cambiar-contrasenia': 'Cambiar contraseña',
	'actualizar-datos-contacto': 'Actualizar datos de contacto',
	'correccion-rfc': 'Registro o corrección RFC',
	'correccion-curp-nombre': 'Corrección CURP y nombre',
	'socio-infonavit': 'Socio Infonavit',
	'desvincula-tu-dispositivo-movil': 'Desvincula tu dispositivo móvil',
	'nip-infonatel': 'NIP Infonatel',
	'mi-ahorro': 'Mi ahorro',
	'cuanto-ahorro-tengo': '¿Cuánto ahorro tengo?',
	'haz-aportaciones-extraordinarias': 'Haz aportaciones extraordinarias',
	'calculadora-ahorro': 'Calculadora del ahorro',
	'resumen-movimientos-ahorro': 'Resumen de movimientos de mi ahorro',
	'consulta-relaciones-laborales': 'Consulta de relaciones laborales',
	'devolucion-fondo-ahorro-72-92': 'Devolución de mis ahorros',
	portabilidad: 'Portabilidad (Infonavit/Fovissste)',
	'mi-tramite-credito': 'Quiero un crédito',
	'sumar-creditos': 'Sumar créditos',
	'precalificacion-puntos': 'Precalificación y puntos',
	'en-que-va-mi-solicitud-credito': 'En que va mi solicitud de crédito',
	'seleccion-notarios': 'Selecciona tu notario',
	'confirmacion-tramite': 'Confirmación de trámite',
	'taller-saber-mas': 'Curso Saber más para decidir mejor',
	'hogar-medida': 'Hogar a tu medida',
	'devolucion-pagos-creditos-cancelados':
		'Devolución de pagos por créditos cancelados',
	'mi-credito': 'Mi crédito',
	'saldos-movimientos': 'Saldos y movimientos',
	'avisos-suspension-retencion-modificacion-descuentos':
		'Avisos de Suspensión, retención y modificación de descuentos',
	'constancia-intereses-declaracion-anual':
		'Constancia de intereses para tu declaración anual',
	'constancia-ecotecnologias': 'Constancia de ecotecnologías',
	'programa-descuentos-liquidacion-anticipada':
		'Programa de descuento por liquidación anticipada',
	'devolucion-pagos-exceso': 'Devolución de pagos en exceso',
	'responsabilidad-compartida-programa-conversion-pesos':
		'Responsabilidad compartida: Programa de conversión a pesos',
	'entrega-escrituras': 'Entrega de escrituras',
	'carta-cancelacion-hipoteca':
		'Carta de instrucción de cancelación de hipoteca',
	'solicitud-reestructuras': 'Solicitud de reestructura',
	'solicitud-prorroga': 'Solicitud de prórroga',
	'aclaracion-pagos': 'Aclaración de pagos',
	mejoravit: 'Mejoravit',
	'correccion-tasa-interes': 'Correccón de tasa de interés',
	contactanos: 'Contáctanos',
	'sigue-caso-adjunta-documentos': 'Sigue tu caso y adjunta documentos',
	'presenta-sigue-queja': 'Haz y sigue tu queja',
	'oficinas-atencion': 'Oficinas de atención',
	'canales-servicio': 'Canales de servicio',
	'haz-una-cita': 'Haz una cita',
	accesorios: 'Accesorios',
	'calculadora-pesos-unidad-medida-actualizacion-uma':
		'Calculadora de pesos y Unidad de medida y actualizacion (UMA)',
	'mis-alertas': 'Mis alertas',
	'certificado-apoyo-infonavit': 'Certificado apoyo infonavit',
	/*'asocia-nss': 'Asocia tu NSS',
	'apoyos-beneficios': 'Apoyos y beneficios',
	'consulta-oferta-empleos': 'Consulta oferta de empleos',
	'haz-cita': 'Agendar una cita',
	'conoce-bolsa-inmobiliaria': 'Conoce la bolsa inmobiliaria',
	'conoce-la-bolsa-inmobiliaria': 'Conoce la bolsa inmobiliaria',
	'programa-recompensas-infonavit': 'Programa de recompensas Infonavit',
	'medidas-proteccion-covid-19': 'Medidas de protección de COVID 19',
	'apoyo-solidario-infonavit': 'Apoyo solidario',*/
};
