/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { styled } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CustomButton from '../../../components/Button';

import { currencyFormat } from '../../../utils/expressions';

import { useStyles } from '../style';
import { PropsRowInfo2, PropsRowInfo } from '../interfaces';
import { dataReturnSavingsContext } from '../contextData';
import ModalLoading from '../../../components/ModalLoading';

interface TableProps {
	tableLabels?: PropsRowInfo2[] | undefined;
	close?: any;
}

const TotalSaving = () => {
	const style = useStyles();
	const { data } = useContext(dataReturnSavingsContext);
	return (
		<div className={style.divTotalSavingInfo}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<label style={{ fontWeight: 'bold' }}>Ahorro total</label>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					lineHeight: '0px',
				}}
			>
				<h2>
					$
					{currencyFormat(
						parseFloat(data?.savingsInformationTotal || '0')
					)}
				</h2>
				{'  '}
				<label style={{ color: '#C1C1C1', marginLeft: '5px' }}>
					MXN
				</label>
			</div>
		</div>
	);
};

const RowInfo = ({
	cantidad,
	label,
	setFlagOpen,
	flagOpen,
	index,
	close,
}: PropsRowInfo) => {
	const [open, setOpen] = React.useState(false);
	const style = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		setOpen(flagOpen === index ? true : false);
	}, [flagOpen]);

	useEffect(() => {
		if (open) {
			setOpen(false);
			if (!flagOpen) {
				setFlagOpen(0);
			}
		}
	}, [close]);

	const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			maxWidth: matches ? 300 : 400,
			left: 30,
			top: 20,
		},
	}));

	return (
		<div key={'divRowInfo_' + index}>
			<div className={style.divRowInfo}>
				<div
					style={{ display: 'flex', flexDirection: 'row' }}
					className={style.divRowTooltip}
				>
					<h3 className={style.RowInfoH3}>{label}</h3>
					{index === 1 && (
						<div>
							<HtmlTooltip
								arrow
								placement={matches ? 'bottom-start' : 'right'}
								title={
									<React.Fragment>
										<div>
											<p style={{ fontWeight: 'bold' }}>Subcuenta de vivienda 1997:</p>
											<p>
												Esta cuenta se formó con las
												aportaciones que hizo tu patrón
												a partir del 1° de julio de
												1997. Se te entregará tu ahorro
												con los rendimientos que haya
												generado hasta el momento de tu
												retiro.
											</p>
											<p>
												Si estás pensionado bajo el
												régimen de la Ley del Seguro
												Social 1997, acude a tu Afore
												para que te informen sobre el
												tipo de pensión que elegiste. Si
												necesitas más información entra
												a la página de la Consar.
											</p>
										</div>
									</React.Fragment>
								}
							>
								<label
									style={{
										color: '#D1001F',
										position: 'relative',
									}}
								>
									<ErrorOutlinedIcon
										sx={{
											color: '#D1001F',
											position: 'absolute',
											top: '18px',
											fontSize: '1.4rem',
											left: '10px',
											cursor: 'pointer',
											transform: 'rotate(180deg)',
										}}
									/>
								</label>
							</HtmlTooltip>
						</div>
					)}
					{index === 2 && (
						<div>
							<HtmlTooltip
								arrow
								placement={matches ? 'bottom-start' : 'right'}
								title={
									<React.Fragment>
										<div>
											<p style={{ fontWeight: 'bold' }}>Subcuenta de vivienda 1992:</p>
											<p>
												Este fondo se constituyó con las
												aportaciones del 5% del salario
												integrado que hicieron los
												patrones de 1992 a junio de
												1997.
											</p>
											<p>
												Estos ahorros se entregan con
												los rendimientos que hayan
												generado hasta el momento del
												retiro del trabajador. Se pueden
												solicitar en la Administración
												de Fondos para el Retiro
												(Afore), pero si el trabajador
												se pensionó después del 13 de
												enero de 2012, el Infonavit lo
												devolverá junto con el saldo de
												la Subcuenta 1997.
											</p>
										</div>
									</React.Fragment>
								}
							>
								<label
									style={{
										color: '#D1001F',
										position: 'relative',
									}}
								>
									<ErrorOutlinedIcon
										sx={{
											color: '#D1001F',
											position: 'absolute',
											top: '18px',
											fontSize: '1.4rem',
											left: '10px',
											cursor: 'pointer',
											transform: 'rotate(180deg)',
										}}
									/>
								</label>
							</HtmlTooltip>
						</div>
					)}
					{index === 3 && (
						<div>
							<HtmlTooltip
								arrow
								placement={matches ? 'bottom-start' : 'right'}
								title={
									<React.Fragment>
										<div>
											<p style={{ fontWeight: 'bold' }}>Fondo de ahorro 72-92:</p>
											<p>
												Aportaciones que enteró tu
												patrón o empresa en la que
												laboraste entre mayo de 1972 y
												febrero de 1992.
											</p>
										</div>
									</React.Fragment>
								}
							>
								<label
									style={{
										color: '#D1001F',
										position: 'relative',
									}}
								>
									<ErrorOutlinedIcon
										sx={{
											color: '#D1001F',
											position: 'absolute',
											top: '18px',
											fontSize: '1.4rem',
											left: '10px',
											cursor: 'pointer',
											transform: 'rotate(180deg)',
										}}
									/>
								</label>
							</HtmlTooltip>
						</div>
					)}
					{index === 4 && (
						<div>
							<HtmlTooltip
								arrow
								placement={matches ? 'bottom-start' : 'right'}
								title={
									<React.Fragment>
										<div>
											<p style={{ fontWeight: 'bold' }}>Cantidad adicional:</p>
											<p>
												Tus aportaciones no generaron
												rendimientos, por lo cual el
												Infonavit te otorga una cantidad
												igual a tu saldo.
											</p>
										</div>
									</React.Fragment>
								}
							>
								<label
									style={{
										color: '#D1001F',
										position: 'relative',
									}}
								>
									<ErrorOutlinedIcon
										sx={{
											color: '#D1001F',
											position: 'absolute',
											top: '18px',
											fontSize: '1.4rem',
											left: '10px',
											cursor: 'pointer',
											transform: 'rotate(180deg)',
										}}
									/>
								</label>
							</HtmlTooltip>
						</div>
					)}
				</div>
				<div className={style.divRowInfo_Cantidad}>
					<span
						style={{
							lineHeight: '0px',
							fontWeight: cantidad > 0 ? 'bold' : 'normal',
						}}
						aria-label={`${currencyFormat(cantidad)} mxn`}
						className="tag-to-read"
					>
						$ {currencyFormat(cantidad)} MXN
					</span>
				</div>
			</div>
			<hr
				style={{
					marginTop: 4,
					color: '#8A919C',
					height: '1px',
					opacity: '20%',
				}}
			/>
		</div>
	);
};

const TableInfo = ({ tableLabels, close }: TableProps) => {
	const [flagOpen, setFlagOpen] = useState(0);

	const handleTooltipOpen = (index: number) => {
		if (flagOpen === 0) {
			setFlagOpen(index);
		} else {
			if (flagOpen === index) {
				setFlagOpen(0);
			} else {
				setFlagOpen(index);
			}
		}
	};

	return (
		<div>
			{tableLabels?.map((row, index) => (
				<RowInfo
					cantidad={row.cantidad}
					label={row.label}
					flagOpen={flagOpen}
					setFlagOpen={handleTooltipOpen}
					index={index + 1}
					key={'RowInfo' + index}
					close={close}
				></RowInfo>
			))}
		</div>
	);
};

const SavingsInformation = () => {
	const style = useStyles();
	const [tableLabels, settableLabels] = useState<PropsRowInfo2[] | undefined>(
		undefined
	);
	const {
		setData,
		data,
		handleNextPage,
		handleGoPage,
		handleCuentaDestino,
		loading,
	} = useContext(dataReturnSavingsContext);

	const handlerSubmit = (value: boolean) => {
		if (!value) {
			setData(
				{
					...(data || undefined),
					accordingAmount: value,
				}
			);

			return;
		}

		handleCuentaDestino(value);
	};
	const [disableTooltip, setDisableTooltip] = useState(false);

	useEffect(() => {
		if (data?.accordingAmount === undefined) {
			return;
		}

		if (data?.accordingAmount === false) {
			handleGoPage(16);
		}
	}, [data?.accordingAmount]);

	useEffect(() => {
		//TODO.- Revisar validación para no enviar a otra pantalla.
		if (data?.accordingAmount === undefined) {
			return;
		}
		if (data?.accordingAmount === true) {
			handleNextPage();
		}

		if (data?.accordingAmount === false) {
			handleGoPage(16);
		}
	}, [data?.accordingAmount]);

	useEffect(() => {
		settableLabels([
			{
				cantidad: parseFloat(
					data?.savingsInformation?.subaccount_1997 || '0'
				),

				label: 'Subcuenta de vivienda 1997',
			},
			{
				cantidad: parseFloat(
					data?.savingsInformation?.subaccount_1992 || '0'
				),
				label: 'Subcuenta de vivienda 1992',
			},
			{
				cantidad: parseFloat(
					data?.savingsInformation?.savingFund || '0'
				),
				label: 'Fondo de ahorro 72-92',
			},
			{
				cantidad: parseFloat(
					data?.savingsInformation?.additionalQuantity || '0'
				),
				label: 'Cantidad adicional',
			},
		]);
	}, [data?.savingsInformation]);

	return (
		<div onClick={() => setDisableTooltip(!disableTooltip)}>
			<ModalLoading loading={loading}></ModalLoading>
			<div className={style.divTotalSaving}>
				<TotalSaving></TotalSaving>
			</div>
			<div>
				<TableInfo
					tableLabels={tableLabels}
					close={disableTooltip}
				></TableInfo>
			</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					paddingTop: '10px',
				}}
			>
				<p>¿Estás de acuerdo con tu saldo?</p>
			</div>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className={style.divButtons}>
					<div className={style.Buttons}>
						<CustomButton
							onClick={() => handlerSubmit(false)}
							variant="outlined"
							styles={{
								width: '100%',
								height: '100%',
							}}
							label="No"
						/>
					</div>

					<div className={style.Buttons}>
						<CustomButton
							onClick={() => handlerSubmit(true)}
							variant="solid"
							styles={{
								width: '100%',
							}}
							label="Sí"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SavingsInformation;
