import {
	Box,
	Divider,
	Link,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Theme,
} from '@mui/material';
import coinIcon from '../../../../assets/svg/coin.svg';
import { formatMoney } from '../../../../utils/validators';
import { InfoBanner } from '../InfoBanner/infoBanner';
import { RoundBtn } from '../RoundBtn/roundBtn';

import {
	textInfoBanner,
	textInfonavit,
} from '../../utils/selectionResume.utils';
import Header from '../Header';
import { useSelectionResume } from './useSelectionResume';
import CustomAlert from '../../../../components/CustomAlert';
import ModalLoading from '../../../../components/ModalLoading';
import { useGeneralStyles } from '../../views/styles/general.styles';

export const SelectionResume = ({
	hideAmortization,
}: {
	hideAmortization?: boolean;
}) => {
	const {
		newStep,
		classes,
		returnStep,
		nextStep,
		tableRowTitles,
		alert,
		loading,
		image,
		title,
		text,
		totalCuantity,
		conyuge,
		getCellData,
		getCellDownload,
	} = useSelectionResume();

	const generalClass = useGeneralStyles();

	return (
		<>
			<Header steps={newStep}></Header>
			<Box className={generalClass.container}>
				<Box className={classes.container}>
					<h3 className={classes.title}>
						Este es el resultado de tu precalificación
					</h3>
					{text}
					<Box className={classes.savingContainer}>
						<img src={image} alt={title} />
						<Box className={classes.saving}>
							<img src={coinIcon} alt="" />
							<h2>
								{formatMoney(totalCuantity)}
								{' MXN'}
							</h2>
						</Box>
					</Box>
					<TableContainer>
						<Table>
							<TableBody>
								{tableRowTitles.map((title, idx) => (
									<TableRow key={title.key}>
										<TableCell
											className={classes.cellTitle}
											sx={{ ...title.styleTitle }}
										>
											<Box>{title.title}</Box>
											{title?.description && (
												<Box
													sx={{
														fontSize: '0.75rem',
													}}
												>
													{title.description}
												</Box>
											)}
										</TableCell>

										{getCellData(title, idx, false)}
										{conyuge &&
											getCellData(title, idx, conyuge)}
									</TableRow>
								))}
								{!hideAmortization && (
									<TableRow>
										<TableCell sx={{ fontWeight: 'bold' }}>
											Tabla Amortización
										</TableCell>

										{getCellDownload(false)}
										{conyuge && getCellDownload(conyuge)}
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<Box mt={2}>
						<InfoBanner
							text={textInfoBanner}
							textPosition="center"
						/>
					</Box>
					<CustomAlert
						show={alert.show}
						severity={alert.severity}
						message={alert.message}
					/>
					<Divider sx={{ marginY: 2 }} />
					<Box className={classes.text}>{textInfonavit}</Box>
					<div className="buttons">
						<RoundBtn
							variant="outlined"
							onClick={() => returnStep()}
						>
							Regresar
						</RoundBtn>
						<RoundBtn
							variant="contained"
							onClick={() => nextStep()}
						>
							Continuar
						</RoundBtn>
					</div>
				</Box>
			</Box>
			<ModalLoading loading={loading} />
		</>
	);
};
