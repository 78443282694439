import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	divPrincipal: {
		padding: '40px 60px',
		[theme.breakpoints.down('sm')]: {
			padding: '30px',
		},
	},
	contentFileDataButtons: {
		display: 'flex',
		justifyContent: 'center',
		marginBlock: 10,
		marginBottom: 30,
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			width: '100%',
		},
		[theme.breakpoints.down('md')]: {},

		'&>button': {
			[theme.breakpoints.down('sm')]: {
				margin: '20px 0px !important',
				width: '100% !important',
			},
		},
	},
	maxContent: {
		width: '100%',
		maxWidth: 'max-content'
	},
	noMargin: {
		marginTop: 0,
		marginBottom: 0
	}
}));
