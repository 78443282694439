import { Paper, Box } from '@mui/material';
//Assets
import CustomAlert from '../../../components/CustomAlert';

const ServiceNotAvailable = () => {

	return (
		<Paper>
			<Box padding={3}>
				<CustomAlert show message={'Por el momento el servicio no se encuentra disponible, intenta más tarde.'} severity={'warning'} />
			</Box>
		</Paper>
	);
};

export default ServiceNotAvailable;
