/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import parseISO from 'date-fns/parseISO';
import { TextField } from '@mui/material';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { formatDate } from '../../../utils/dates';
import { IAlert } from '../../../interfaces/alert';

import CustomButton from '../../../components/Button';

import { useStyles } from '../style';
import { dataReturnSavingsContext } from '../contextData';
import { IRequestValidaFecha } from '../interfaces';
import CustomAlert from '../../../components/CustomAlert';
import ModalLoading from '../../../components/ModalLoading';

const PensionResolutionDate = () => {
	const style = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [disabledButton, setDisabledButton] = useState<boolean>(true);
	const [startDate, setStartDate] = useState<string | Date | null>('');
	const [resolutionDate, setResolutionDate] = useState<string | Date | null>(
		''
	);
	const { handlePrevPage, alert, setAlert, handleValidaFechas, loading } =
		useContext(dataReturnSavingsContext);

	useEffect(() => {
		const startDateValidate = startDate instanceof Date;
		const resolutionDateValidate = resolutionDate instanceof Date;

		const validation =
			startDateValidate === true && resolutionDateValidate === true
				? false
				: true;

		setDisabledButton(validation);
		setAlert({
			show: false,
			message: '',
			severity: 'error',
		} as IAlert);
	}, [startDate, resolutionDate]);

	const handleSubmit = () => {
		const params: IRequestValidaFecha = {
			fechaInicio: formatDate(
				'yyyyMMdd',
				new Date(startDate as Date)
			).toString(),
			fechaFin: formatDate(
				'yyyyMMdd',
				new Date(resolutionDate as Date)
			).toString(),
		};
		handleValidaFechas(params);
	};

	const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(() => ({
		[`& .${tooltipClasses.tooltip}`]: {
			maxWidth: matches ? 300 : 400,
			left: 30,
		},
	}));

	return (
		<div>
			<ModalLoading loading={loading}></ModalLoading>
			<div>
				<label className={style.TextBlack_H3}>
					Fecha del inicio resolución de pensión
				</label>
			</div>
			<div className={style.divFechas}>
				{/* TODO.- falta el modo responsivo */}
				<div className={style.divFechaResolution}>
					<div style={{ marginBottom: 5 }}>
						<label>Fecha de inicio</label>
						<HtmlTooltip
							arrow
							placement={matches ? 'bottom-start' : 'right'}
							title={
								<label>
									Esta fecha se encuentra en tu documento de
									resolución de pensión
								</label>
							}
						>
							<label
								style={{
									color: '#D1001F',
									position: 'relative',
								}}
							>
								<ErrorOutlinedIcon
									sx={{
										color: '#D1001F',
										position: 'absolute',
										top: '-6px',
										fontSize: '1.4rem',
										left: '10px',
										cursor: 'pointer',
										transform: 'rotate(180deg)',
									}}
								/>
							</label>
						</HtmlTooltip>
					</div>
					<div>
						<DesktopDatePicker
							inputFormat="dd/MM/yyyy"
							value={startDate}
							minDate={parseISO('1972-05-01')}
							onChange={(value) => {
								setStartDate(value ? value : '');
							}}
							InputProps={{
								style: { height: 40, width: '100%' },
							}}
							renderInput={(params) => (
								<TextField
									fullWidth
									{...params}
									inputProps={{
										...params.inputProps,
										placeholder: 'DD/MM/AAAA',
									}}
								/>
							)}
							onYearChange={(year) => {
								setStartDate(year ? year : '');
							}}
						/>
					</div>
				</div>
				<div className={style.divFechaResolution}>
					<div style={{ marginBottom: 5 }}>
						<label>Fecha de resolución</label>
						<HtmlTooltip
							arrow
							placement={matches ? 'bottom-start' : 'right'}
							title={
								<label>
									Esta fecha se encuentra en tu documento de
									resolución de pensión y corresponde al día
									en el que la resolución fue emitida.
								</label>
							}
						>
							<label
								style={{
									color: '#D1001F',
									position: 'relative',
								}}
							>
								<ErrorOutlinedIcon
									sx={{
										color: '#D1001F',
										position: 'absolute',
										top: '-6px',
										fontSize: '1.4rem',
										left: '10px',
										cursor: 'pointer',
										transform: 'rotate(180deg)',
									}}
								/>
							</label>
						</HtmlTooltip>
					</div>
					<div>
						<DesktopDatePicker
							inputFormat="dd/MM/yyyy"
							value={resolutionDate}
							minDate={parseISO('1972-05-01')}
							onChange={(value) => {
								setResolutionDate(value ? value : '');
							}}
							InputProps={{
								style: { height: 40, width: '100%' },
							}}
							renderInput={(params) => (
								<TextField
									fullWidth
									{...params}
									inputProps={{
										...params.inputProps,
										placeholder: 'DD/MM/AAAA',
									}}
								/>
							)}
							onYearChange={(year) => {
								setResolutionDate(year ? year : '');
							}}
						/>
					</div>
				</div>
			</div>
			<div className={style.divAlert}>
				<CustomAlert
					message={alert.message}
					show={alert.show}
					severity={alert.severity}
					onClose={() => {
						setAlert({
							show: false,
							message: '',
							severity: 'error',
						} as IAlert);
					}}
				/>
			</div>
			<div className={style.divFechasButtons}>
				<div className={style.ResolutionButtons}>
					<CustomButton
						onClick={handlePrevPage}
						variant="outlined"
						styles={{
							height: '100%',
							margin: '0 auto',
						}}
						label="Regresar"
					/>
				</div>
				<div className={style.ResolutionButtons}>
					<CustomButton
						onClick={handleSubmit}
						variant="solid"
						styles={{
							margin: '0 auto',
						}}
						label="Continuar"
						disabled={disabledButton}
					/>
				</div>
			</div>
		</div>
	);
};

export default PensionResolutionDate;
