import { Fragment, useEffect, useState } from 'react';

interface Props {
	msg: string;
}

const MessageCustom = (props: Props) => {
	const [msgArray, setMsgArray] = useState<string[]>([]);

	useEffect(() => {
		setMsgArray([]);
		if (props.msg && props.msg.length > 0) {
			setMsgArray(props.msg.split('<br/>'));
		}
	}, [props.msg]);

	return (
		<>
			{msgArray.map((item: string, index) => (
				<Fragment key={index}>
					<span
						className="tag-to-read"
						aria-label={item.replaceAll('&nbsp;', '\u00A0')}
					>
						{' '}
						{item.replaceAll('&nbsp;', '\u00A0')}{' '}
					</span>
					<br />
				</Fragment>
			))}
		</>
	);
};

export default MessageCustom;
