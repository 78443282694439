import { Bitacora, IBitacora } from "../../interfaces/bitacora";
const _bitacora = new Bitacora('134', 'solicitud-prorroga');

export const ENDPOINTS_SOLICITUD_DE_PRORROGA: IBitacora = {
    '/registro-solicitud-prorroga': _bitacora.Create('134100', 'Registro de solicitud'),
    '/prorroga-create-case': _bitacora.Create('134101', 'Crear caso'),
    '/prorroga-search-case': _bitacora.Read('134102', 'Buscar caso'),
    '/prorroga-generate-pdf': _bitacora.Read('134103', 'Generar PDF'),
    '/prorroga-upload-doc': _bitacora.Read('134104', 'Obtener documento por OTX'),
    '/prorroga-get-doc-mq': _bitacora.Read('134105', 'Obtener documento por MQ'),
    '/prorroga-get-doc-otx': _bitacora.Update('134106', 'Subir documentación'),
};
