/* eslint-disable react-hooks/exhaustive-deps */
import { Paper } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import RadioButtonInput from '../../components/RadioButtonInput';
import { HeaderList } from '../../components/HeaderList';
import CustomButton from '../../components/Button';
import Layout from '../../components/Layout';
import CreateComplaint from './CreateComplaint';
import MyComplaints from './MyComplaints';
import Suggestions from './Suggestions';
import { useStyles } from './styles';
import { typesOptions } from './utils';
import AttachDocument from './AttachDocument';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const FollowComplaint = () => {
	const { fontSizeLevel } = useSelector((state: RootState) => state.session);
	const classes = useStyles();
	const navigation = useNavigate();
	const [type, setType] = useState<number>(0);
	const [selectedType, setSelectedType] = useState<number>(0);
	const [queja, setQueja] = useState({});
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<Layout updateTagText={updateTagText}>
			<div ref={divRef}>
				{[0, 2].includes(selectedType) && (
					<HeaderList
						title="Haz y sigue una queja"
						list={[
							selectedType === 0
								? 'Se entenderá por queja o reclamación a la manifestación de descontento o insatisfacción que se tiene en relación con un servicio o atención ofrecido por el Infonavit.'
								: 'Para conocer la respuesta a tu queja comunícate a Infonatel al 55 9171-5050 en la Ciudad de México, o al 800 008 3900 desde cualquier parte del país.',
						]}
					/>
				)}
				{selectedType === 0 && (
					<Paper className={classes.mainContainer}>
						<RadioButtonInput
							label="Elige una de las siguientes opciones"
							value={type}
							options={typesOptions}
							name="complaintType"
							onChange={({ target }: any) =>
								setType(Number(target.value))
							}
						/>
						<div className="actions-container">
							<CustomButton
								onClick={() => navigation('/')}
								variant="outlined"
								label="Salir"
							/>
							<CustomButton
								disabled={type === 0}
								onClick={() => setSelectedType(type)}
								variant="solid"
								label="Continuar"
							/>
						</div>
					</Paper>
				)}
				{selectedType === 1 && (
					<CreateComplaint setSelectedType={setSelectedType} />
				)}
				{selectedType === 2 && (
					<MyComplaints
						setSelectedType={setSelectedType}
						setQueja={setQueja}
					/>
				)}
				{selectedType === 3 && (
					<Suggestions setSelectedType={setSelectedType} />
				)}
				{selectedType === 4 && (
					<AttachDocument setSelectedType={setSelectedType} queja={queja} />
				)}
			</div>
		</Layout>
	);
};

export default FollowComplaint;
