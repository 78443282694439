/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Grid } from '@mui/material';
import { useStyles } from './styles';

import { useNavigate } from 'react-router-dom';
//Assets

import imgAltanto from '../../../assets/img/mantente-al-tanto.png';
import imgRetencion from '../../../assets/img/aviso-retencion-banner.png';
import imgCartaCan from '../../../assets/img/banner-carta-canelacion.png';
import imgSolicitaDev from '../../../assets/img/solicita-dev-banner1.png';
import imgPension from '../../../assets/img/pensionado-banner.png';

const PensionerCreditClose = () => {
	const classes = useStyles();

	const navigate = useNavigate();

	return (
		<Grid container spacing={2}>
			<Grid item sm={12} md={12} lg={4}>
				<Paper className={classes.card}>
					<div className={classes.titleCard}>
						<h3>
							¿Eres pensionado(a) por parte del IMSS, ISSSTE
							o si alguna vez también trabajaste para el Estado?
						</h3>
					</div>
					<div className={classes.textCard}>
						<p>
							Puedes solicitar tu ahorro de la Subcuenta de Vivienda.
						</p>
						<p>
							¿Ya terminaste de pagar tu crédito? Solicita tu
							Aviso de suspensión para concluir el trámite de tu crédito.
						</p>
						<p>
							Verifica en Saldos y movimientos si no pagaste de más
							y solicita la Devolución de Pagos en Exceso.
						</p>
						<p>
							Recuerda obtener tu Carta de Cancelación de Hipoteca,
							para que liberes de gravamen tu vivienda ante el
							Registro Público de la Propiedad.
						</p>
					</div>
				</Paper>
			</Grid>

			<Grid item sm={12} md={12} lg={8}>
				<Grid container spacing={2}>
					<Grid item sm={6} md={6} lg={6}>
						<div
							onClick={() =>
								navigate('/mi-credito/saldos-movimientos')
							}
						>
							<img
								src={imgAltanto}
								className={classes.imageStyle}
								alt="Mantente al tanto con toda la información de tu saldo y movimientos de tu credito"
							/>
						</div>
					</Grid>
					<Grid item sm={6} md={6} lg={6}>
						<Grid item lg={12}>
							<div
								onClick={() =>
									navigate(
										'/mi-credito/avisos-suspension-retencion-modificacion-descuentos'
									)
								}
							>
								<img
									src={imgRetencion}
									className={classes.imageStyle}
									alt="Aviso de retención, modificación y/o suspensión"
								/>
							</div>
						</Grid>
						<Grid item lg={12} mt={2}>
							<div
								onClick={() =>
									navigate(
										'/mi-credito/carta-cancelacion-hipoteca'
									)
								}
							>
								<img
									src={imgCartaCan}
									className={classes.imageStyle}
									alt="carta de cancelación de hipoteca"
								/>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Grid container justifyContent="flex-end" mt={2}>
				<Grid
					container
					spacing={2}
					direction="row"
					xs={12}
					sm={12}
					md={12}
					lg={8}
				>
					<Grid item sm={6} md={6} lg={6}>
						<div
							onClick={() =>
								navigate('/mi-credito/devolucion-pagos-exceso')
							}
						>
							<img
								src={imgSolicitaDev}
								className={classes.imageStyle}
								alt="Devolución de pagos en exceso"
							/>
						</div>
					</Grid>
					<Grid item sm={6} md={6} lg={6}>
						<div
							onClick={() =>
								navigate(
									'/mi-ahorro/devolucion-fondo-ahorro-72-92'
								)
							}
						>
							<img
								src={imgPension}
								className={classes.imageStyle}
								alt="Solicita la devolución de tu ahorro"
							/>
						</div>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default PensionerCreditClose;
