import { Box } from '@mui/material';
import { useStyles } from './styles/styles';
import { getDateParse } from './utils';
import GroupButtons from './components/GroupButtons';
import { useEscrituras } from '../hooks/useEscrituras';
import StepCustom from './components/StepCustom';

interface IProps {
	nextProcess: () => void;
	setPage: (arg: number) => void;
}

const CaseFollowHome = ({ nextProcess, setPage }: IProps) => {
	const { escrituras } = useEscrituras();
	const styleCss = useStyles();

	return (
		<>
			<h3 className={styleCss.labelCaseFollowHome}>
				Entrega de escrituras
			</h3>
			<Box className={styleCss.containerCaseFollowHome}>
				<Box className={styleCss.container}>
					<span>Número de caso</span>
					<Box>{escrituras.noCaso}</Box>
				</Box>
				<Box className={styleCss.container}>
					<span>Fecha de solicitud</span>
					<Box>{getDateParse(escrituras.fecha)}</Box>
				</Box>
				<Box className={styleCss.container}>
					<span>Fecha de actualización de estatus</span>
					<Box>{getDateParse(escrituras.fechaEstatus)}</Box>
				</Box>
				<Box className={styleCss.container}>
					<span>Servicio</span>
					<Box>Entrega de escrituras</Box>
				</Box>
				<Box className={styleCss.container}>
					<span>Cesi de entrega</span>
					<Box>
						{escrituras.cesiTramite}
						<p className={styleCss.sinMarginP}>
							{escrituras.edoTramite}
						</p>
					</Box>
				</Box>
				<Box className={styleCss.container}>
					<span>Estatus</span>
					<Box>
						<StepCustom />
					</Box>
				</Box>
			</Box>
			<GroupButtons setPage={setPage} nextProcess={nextProcess} />
		</>
	);
};

export default CaseFollowHome;
