/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react"
import Layout from "../../../../components/Layout"
import { MIUC_PRODUCTS } from "../../constants/products"
import { useController } from "../../hooks/useController"
import { EvaluationUnit } from "./evaluationUnit"
import ChooseValuation from "../../views/construir/pages/ChooseValuation"
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

export const EvaluationFlow = () => {
	const { fontSizeLevel } = useSelector((state: RootState) => state.session);
	const { dataProcessController } = useController();
	const [page, setPage] = useState(
		dataProcessController?.processInfo?.credit === MIUC_PRODUCTS.COMPRA ? 0 : 1
	)

	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	const pages = [
		<EvaluationUnit setPage={setPage} />,
		<ChooseValuation />,
	]

	return (
		<Layout updateTagText={updateTagText}>
			<div ref={divRef}>
				{pages[page]}
			</div>
		</Layout>
	)
}
