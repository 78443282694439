/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Box, Paper, Grid, useMediaQuery } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { useStyles } from './styles';
import { CheckCircleOutlined } from '@mui/icons-material';

//Components
import CustomButton from '../../components/Button';
import { useTheme } from '@mui/material/styles';
import { HeaderList } from '../../components/HeaderList';
import CustomAlert from '../../components/CustomAlert';
import ModalLoading from '../../components/ModalLoading';
import DocumentView from './DocumentView';
import BaseModal from '../../components/Modal';

//Resources
import { IAlert } from '../../interfaces/alert';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { keyAPI } from '../../api/modules/key';
import { encrypt } from '../../utils/encrypt';
import { PaymentRequestAPI } from '../../api/modules/PaymentClarification';
import { ResponseCaso } from '../../interfaces/PaymentClarification';
import { casesRequestAPI } from '../../api/modules/FollowCases';

interface Props {
	setPage: (data: number) => void;
	updateCaso: () => void;
	dataCaso: {
		noCaso: string;
		estatus: string;
		fecha: string;
		fechaEstatus: string;
		descripcion: string;
		tipificacion: string;
		estatusDetalle: {
			descripcion: string;
			estatus: string
		},
		casoCerradoDetalle: {
			razon: string;
			id: string;
			descripcion: string;
		};
	};
}

const AttachDocument = ({ setPage, dataCaso, updateCaso }: Props) => {
	const { user } = useSelector((state: RootState) => state.session);
	const theme = useTheme();
	const [loading, setLoading] = useState(false);
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [pageAttach, setPageAttach] = useState(false);
	const classes = useStyles();
	const [modalView, setModalView] = useState(null);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [file, setFile] = useState({
		nombre: 'No se eligió ningún archivo',
		archivo: null,
		size: 0
	});
	const [document, setDocument] = useState({
		nombre: 'No se eligió ningún archivo',
		archivo: null,
		size: 0
	});
	const [documents, setDocuments] = useState([]);
	const [documentsRequired, setDocumentsRequired] = useState([]);
	const [documentsString, setDocumentsString] = useState('')
	const [documentsRequiredString, setDocumentsRequiredString] = useState('')
	const [modalAcceptActive, setModalAcceptActive] = useState(false);
	const [modalDeleteActive, setModalDeleteActive] = useState(false);
	const [typeDelete, setTypeDelete] = useState(0);

	const handleFile = (e: any) => {
		const type = e.target.files[0].type;
		let size = e.target.files[0].size / 1024 ** 2
		if (size < 20) {
			if (/jpg|png|tiff|pdf|mpeg|avi|wmv|mp4|mp3/g.test(type)) {
				setFile({ nombre: e.target.files[0].name, archivo: e.target.files[0], size: size });
				e.target.value = null;
			} else {
				setAlert({
					show: true,
					message: 'Tu documento no está en el formato, recuerda que sólo puedes enviar documentos con los siguientes formatos: jpg, png, tiff, pdf, mpeg, avi, wmv, mp4 o mp3.',
					severity: 'error',
				});
			}
		} else {
			setAlert({
				show: true,
				message:
					'El tamaño del archivo no debe de ser mayor a 20 MB.',
				severity: 'error',
			});
		}
	};
	const handleDocument = (e: any) => {
		const type = e.target.files[0].type;
		let size = e.target.files[0].size / 1024 ** 2
		if (size < 20) {
			if (/jpg|png|tiff|pdf|mpeg|avi|wmv|mp4|mp3/g.test(type)) {
				setDocument({ nombre: e.target.files[0].name, archivo: e.target.files[0], size: size });
				e.target.value = null;
			} else {
				setAlert({
					show: true,
					message: 'Tu documento no está en el formato, recuerda que sólo puedes enviar documentos con los siguientes formatos: jpg, png, tiff, pdf, mpeg, avi, wmv, mp4 o mp3.',
					severity: 'error',
				});
			}
		} else {
			setAlert({
				show: true,
				message:
					'El tamaño del archivo no debe de ser mayor a 20 MB.',
				severity: 'error',
			});
		}
	};
	const handleSubmit = async () => {
		setModalAcceptActive(false);
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();

			const file64_1: any = await blobToBase64(
				file.archivo !== null ? file.archivo : ''
			);
			const file64_2: any = await blobToBase64(
				document.archivo !== null ? document.archivo : ''
			);

			const paramsData = {
				nss: user?.nss,
				caso: dataCaso.noCaso,
				archivos: [
					{
						archivo: file64_1,
						nombreArchivo: file.nombre
					},
					{
						archivo: file64_2,
						nombreArchivo: document.nombre
					}
				]
			}
			const dataSendEncrypt = await encrypt(
				JSON.stringify(paramsData), key
			);

			PaymentRequestAPI.adjuntaArchivos
				.post<
					{
						data: string;
					},
					ResponseCaso
				>('', {
					data: dataSendEncrypt
				})
				.then((res: ResponseCaso) => {
					if (res.code === '0000') {
						updateCaso()
					} else {
						setAlert({
							show: true,
							message: res.message
								? res.message
								: 'Por el momento el servicio no está disponible. Intenta más tarde',
							severity: 'error',
						});
					}
				})
				.catch((err: any) => {
					setAlert({
						show: true,
						message: err.description ? err.description : 'Por el momento el servicio no está disponible. Intenta más tarde',
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description ? error.description : 'Por el momento el servicio no está disponible. Intenta más tarde',
				severity: 'error',
			});
			setLoading(false);
		}
	};
	const blobToBase64 = (blob: any) => {
		if (blob) {
			return new Promise((resolve, reject) => {
				const reader: any = new FileReader();
				reader.readAsDataURL(blob);
				reader.onloadend = () => {
					resolve(reader.result.split(',')[1]);
				};
			});
		} else {
			return '';
		}
	};
	const deleteFiles = () => {
		setModalDeleteActive(false)
		if (typeDelete === 1) {
			setFile({
				nombre: 'No se eligió ningún archivo',
				archivo: null,
				size: 0
			})
		} else {
			setDocument({
				nombre: 'No se eligió ningún archivo',
				archivo: null,
				size: 0
			})
		}
		setAlert({
			show: false,
			message: '',
			severity: 'success',
		})
		setTypeDelete(0)
	}

	useEffect(() => {
		const getList = async () => {
			try {
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const CASO_BASE64 = await encrypt(dataCaso.noCaso.toString() || '', key);
				const OPERACION_BASE64 = await encrypt(
					dataCaso.tipificacion.toString() || '',
					key
				);
				const ORIGEN_BASE64 = await encrypt('CRM',
					key
				);
				casesRequestAPI.listDocuments
					.post<
						{
							caso: string;
							operacion: string;
							origen: string;
						},
						any
					>('', {
						caso: CASO_BASE64,
						operacion: OPERACION_BASE64,
						origen: ORIGEN_BASE64,
					})
					.then((res: any) => {
						if (res.result.listaDocumentos && res.result.listaDocumentos.length > 0) {
							let documentsOptional: any = []
							let documentsObligatorio: any = []
							let stringDocuments: any = ''
							let stringDocumentsObligatorio: any = ''
							res.result.listaDocumentos.forEach((element: any) => {
								if (element.obligatorio && element.obligatorio === 'X') {
									documentsObligatorio.push(element)
									stringDocumentsObligatorio += element.descripcion + ', '
								} else {
									documentsOptional.push(element)
									stringDocuments += element.descripcion + ', '
								}
							});
							setDocumentsRequired(documentsObligatorio)
							setDocuments(documentsOptional);
							setDocumentsString(stringDocuments)
							setDocumentsRequiredString(stringDocumentsObligatorio)
						}
					})
					.catch((err: any) => {
						setAlert({
							show: true,
							message:
								err && err.description
									? err.description
									: 'Por el momento el servicio no está disponible. Intenta más tarde.',
							severity: 'error',
						});
					});
			} catch (error: any) {
				setAlert({
					show: true,
					message:
						error && error.description
							? error.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde.',
					severity: 'error',
				});
			}
		};
		getList();
	}, []);
	useEffect(() => {
		let total = file.size + document.size
		if (total > 20) {
			setAlert({
				show: true,
				message:
					'El tamaño total de los archivos no debe de ser mayor a 20 MB.',
				severity: 'error',
			});
		}
	}, [file, document])

	return (
		<Box>
			<HeaderList title="Aclaración de pagos" />
			<ModalLoading loading={loading} />
			<DocumentView file={modalView} setClose={setModalView} />
			<Paper sx={{ pt: 5, pb: 5, pr: matches ? 5 : 15, pl: matches ? 5 : 15 }}>
				{!pageAttach && (
					<div id="radioOptions">
						<h3 style={{ textAlign: 'center', marginTop: '30px' }}>
							Para hacer una aclaración deberás adjuntar los siguientes
							documentos:
						</h3>
						{documentsString.length > 0 && (
							<div style={{ display: 'flex', marginBottom: 5 }}>
								<CheckCircleOutlined
									color="info"
									style={{ marginRight: 5, width: 20 }}
								/>
								<label style={{ marginTop: 2 }}>
									{documentsString.slice(0, -2)}.
								</label>
							</div>
						)}
						{documentsRequiredString.length > 0 && (
							<div style={{ display: 'flex', marginBottom: 5 }}>
								<CheckCircleOutlined
									color="info"
									style={{ marginRight: 5, width: 20 }}
								/>
								<label style={{ marginTop: 2 }}>
									{documentsRequiredString.slice(0, -2)}.
								</label>
							</div>
						)}
						<div className={classes.message}>
							<p>¿Quieres adjuntar tus documentos?</p>
						</div>
						<div
							style={{
								width: matches ? '100%' : '600px',
								margin: '30px auto 20px',
								display: matches ? 'block' : 'flex',
								justifyContent: 'space-between',
							}}
						>
							<CustomButton
								label="No"
								onClick={() => setPage(1)}
								variant="outlined"
								styles={{
									width: 260,
									height: 40,
									margin: '10px auto',
								}}
							/>
							<CustomButton
								label="Si"
								onClick={() => setPageAttach(true)}
								variant="solid"
								styles={{
									width: 260,
									height: 40,
									margin: '10px auto',
								}}
							/>
						</div>
					</div>
				)}
				{pageAttach && (
					<div className={classes.form}>
						<Grid
							container
							columns={12}
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
							}}
						>
							<Grid
								item
								xs={6}
								sm={6}
								md={6}
								lg={6}
								style={{ textAlign: 'left' }}
							>
								<b><label>Número de caso</label></b>
							</Grid>
							<Grid
								item
								xs={6}
								sm={6}
								md={6}
								lg={6}
								style={{ textAlign: 'right' }}
							>
								<label>{dataCaso.noCaso}</label>
							</Grid>
						</Grid>
						<hr
							style={{
								opacity: 0.2,
								marginTop: 20,
								marginBottom: 20,
							}}
						/>

						<p style={{ textAlign: matches ? 'center' : 'initial' }}>
							<b>Estos son los documentos que debes adjuntar</b>
						</p>
						<div className={classes.containerFiles}>
							<div className={classes.containerFilesOne}>
								<h4 style={{ marginTop: 0 }}>
									Identificación oficial vigente
									<span style={{ color: 'red' }}>*</span>
								</h4>
								<p
									className={classes.p}
									style={{ color: 'black', margin: '-10px 0 20px' }}
								>
									Recuerda adjuntar solo un documento de la lista:
								</p>
								{documents.map((dato: any, index) => (
									<p key={index} className={classes.p}>
										{dato.descripcion}
									</p>
								))}
							</div>
							<div className={classes.containerFilesTwo}>
								<p className={classes.textFile}>{file.nombre}</p>
							</div>
							<div className={classes.containerFilesThree}>
								{!file.archivo && (
									<>
										<label
											style={{
												cursor: 'pointer',
												color: 'red',
											}}
										>
											<input
												type="file"
												accept=".pdf"
												name="archivo"
												onChange={handleFile}
												style={{ display: 'none' }}
											/>
											Elegir archivo
										</label>
									</>
								)}

								{file.archivo && (
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: matches ? 'center' : 'right',
										}}
									>
										<div
											style={{
												display: 'flex',
												justifyContent: 'right',
												marginLeft: 10,
											}}
										>
											<label
												style={{
													cursor: 'pointer',
													color: 'red',
												}}
												onClick={() => {
													setModalDeleteActive(true)
													setTypeDelete(1)
												}}
											>
												<ClearIcon
													style={{
														fontSize: 16,
														color: 'red',
													}}
												/>{' '}
												Eliminar archivo
											</label>
										</div>
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												marginLeft: 10,
											}}
										>
											<label
												style={{
													cursor: 'pointer',
													color: 'red',
												}}
												onClick={() => setModalView(file.archivo)}
											>
												<SearchIcon
													style={{
														fontSize: 16,
														color: 'red',
													}}
												/>{' '}
												Ver archivo
											</label>
										</div>
									</div>
								)}
							</div>
						</div>
						<hr
							style={{
								opacity: 0.2,
								marginTop: 20,
								marginBottom: 20,
							}}
						/>

						<div className={classes.containerFiles}>
							<div className={classes.containerFilesOne}>
								<h4 style={{ marginTop: 0 }}>
									Documento obligatorio
									<span style={{ color: 'red' }}>*</span>
								</h4>
								{documentsRequired.map((dato: any, index) => (
									<p key={index} className={classes.p}>
										{dato.descripcion}
									</p>
								))}
							</div>
							<div className={classes.containerFilesTwo}>
								<p className={classes.textFile}>{document.nombre}</p>
							</div>
							<div className={classes.containerFilesThree}>
								{!document.archivo && (
									<>
										<label
											style={{
												cursor: 'pointer',
												color: 'red',
											}}
										>
											<input
												type="file"
												accept=".pdf"
												name="file"
												onChange={handleDocument}
												style={{ display: 'none' }}
											/>
											Elegir archivo
										</label>
									</>
								)}

								{document.archivo && (
									<div
										style={{
											display: 'flex',
											justifyContent: matches ? 'center' : 'right',
										}}
									>
										<div
											style={{
												display: 'flex',
												justifyContent: 'right',
												marginLeft: 10,
											}}
										>
											<label
												style={{
													cursor: 'pointer',
													color: 'red',
												}}
												onClick={() => {
													setModalDeleteActive(true)
													setTypeDelete(2)
												}}
											>
												<ClearIcon
													style={{
														fontSize: 16,
														color: 'red',
													}}
												/>{' '}
												Eliminar archivo
											</label>
										</div>
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												marginLeft: 10,
											}}
										>
											<label
												style={{
													cursor: 'pointer',
													color: 'red',
												}}
												onClick={() => setModalView(document.archivo)}
											>
												<SearchIcon
													style={{
														fontSize: 16,
														color: 'red',
													}}
												/>{' '}
												Ver archivo
											</label>
										</div>
									</div>
								)}
							</div>
						</div>

						<div className={classes.message} style={{ padding: '0 45px' }}>
							<p>
								Antes de enviarlos verifica que estén correctos.
								Los formatos permitidos: jpg, gif, png, tiff, pdf, mpeg, avi, wmv, mp4 y mp3,
								con un tamaño máximo de 20 MB por todos los documentos adjuntos.
							</p>
						</div>

						<div className={classes.buttons}>
							<CustomButton
								onClick={() => {
									setPageAttach(false);
								}}
								variant="outlined"
								styles={{
									width: 260,
									margin: matches ? '10px auto' : 10,
								}}
								label="Regresar"
							/>

							<CustomButton
								disabled={(file.archivo && document.archivo ? false : true) || alert.show}
								onClick={() => {
									setModalAcceptActive(true)
								}}
								variant="solid"
								styles={{
									width: 260,
									margin: matches ? '10px auto' : 10,
								}}
								label="Continuar"
							/>
						</div>

						<CustomAlert
							data-testid="mensageAlertthree"
							message={alert.message}
							severity={alert.severity}
							show={alert.show}
						/>

						<Grid container columns={12}>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<hr
									style={{
										opacity: 0.4,
										marginTop: 50,
										marginBottom: 0,
									}}
								/>
								<h3
									style={{
										marginLeft: 20,
										marginTop: 8,
										color: '#293990',
										fontSize: 18,
										paddingBottom: 15,
										textAlign: 'left',
									}}
								>
									*Datos obligatorios
								</h3>
							</Grid>
						</Grid>
					</div>
				)}
			</Paper>
			<BaseModal
				title={
					<label style={{ color: '#293990', textAlign: 'center', display: 'block' }}>
						¿Estás seguro que quieres enviar los documentos?
					</label>
				}
				open={modalAcceptActive}
				width="sm"
				disableOnConfirm={true}
			>
				<div style={{ textAlign: 'center' }}>
					<div
						style={{
							display: 'flex',
							width: 540,
							justifyContent: 'space-between',
							margin: '20px auto 10px',
						}}
					>
						<div style={{ width: 260 }}>
							<CustomButton
								label="No"
								variant="outlined"
								onClick={() => setModalAcceptActive(false)}
							/>
						</div>
						<div style={{ width: 260 }}>
							<CustomButton
								label="Sí"
								variant="solid"
								onClick={() => handleSubmit()}
							/>
						</div>
					</div>
				</div>
			</BaseModal>
			<BaseModal
				title={
					<label style={{ color: '#293990', textAlign: 'center', display: 'block' }}>
						¿Estás seguro que quieres eliminar el documento?
					</label>
				}
				open={modalDeleteActive}
				width="sm"
				disableOnConfirm={true}
			>
				<div style={{ textAlign: 'center' }}>
					<div
						style={{
							display: 'flex',
							width: 540,
							justifyContent: 'space-between',
							margin: '20px auto 10px',
						}}
					>
						<div style={{ width: 260 }}>
							<CustomButton
								label="No"
								variant="outlined"
								onClick={() => setModalDeleteActive(false)}
							/>
						</div>
						<div style={{ width: 260 }}>
							<CustomButton
								label="Sí"
								variant="solid"
								onClick={() => deleteFiles()}
							/>
						</div>
					</div>
				</div>
			</BaseModal>
		</Box>
	);
};

export default AttachDocument;
