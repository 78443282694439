import { IUser } from '../../interfaces/user';

export interface SesionState {
	user: IUser | null;
	isLogged: boolean;
	tokenExpired: boolean;
	isImpersonalized: boolean;
	dataGeo: string;
	validateDatos: boolean;
	validatePensionado: boolean;
	validateCorreccion: boolean;
	activeLector: boolean;
	zoomCursorActive: boolean;
	activeDislexic: boolean;
	fontSizeLevel: number;
	activeGrayScale: boolean;
	activeContrast: boolean;
	activeMask: boolean;
}

export const SET_USER = 'session/setUser';
export const SIGN_OUT = 'session/signOut';
export const SIGN_IN = 'session/signIn';
export const UPDATE_USER = 'session/updateUser';
export const CHECK_TOKEN = 'session/checkToken';
export const SET_DATA_GEO = 'session/setDataGeo';
export const CHANGE_VALIDATE_DATA = 'session/changeValidateData';
export const CHANGE_VALIDATE_PENSIONADO = 'session/changeValidatePensionado';
export const CHANGE_VALIDATE_CORRECCION = 'session/changeValidateCorreccion';
export const CHANGE_LECTOR = 'session/changeLector';
export const CHANGE_ZOOM = 'session/changeZoom';
export const CHANGE_DISLEXIC = 'session/changeDislexic';
export const CHANGE_FONT_SIZE = 'session/changeFontSize';
export const CHANGE_GRAY_SCALE = 'session/changeGrayScale';
export const CHANGE_CONTRAST = 'session/changeContrast';
export const CHANGE_MASK = 'session/changeMask';

export interface SignInProps {
	email: string;
	password: string;
}

export const INITIAL_STATE: SesionState = {
	user: null,
	isLogged: false,
	tokenExpired: false,
	isImpersonalized: false,
	dataGeo: '',
	validateDatos: false,
	validatePensionado: false,
	validateCorreccion: false,
	activeLector: false,
	zoomCursorActive: false,
	activeDislexic: false,
	fontSizeLevel: 0,
	activeGrayScale: false,
	activeContrast: false,
	activeMask: false,
};
