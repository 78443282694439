/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useStyles } from '../../styles';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store';
import { actions } from '../../../../store/modules/session';

import IconContrast from '../../../../assets/img/iconsToolAccessibility/iconContrast-1.svg';
import IconContrastActive from '../../../../assets/img/iconsToolAccessibility/iconContrast-2.svg';

interface Props {
	isContrast: boolean;
	setIsContrast: (data: boolean) => void;
}

const Contrast = ({ isContrast, setIsContrast }: Props) => {
	const { activeContrast } = useSelector((state: RootState) => state.session);
	const dispatch = useDispatch();
	const classes = useStyles();

	useEffect(() => {
		setIsContrast(activeContrast);
	}, []);

	return (
		<div>
			<div className={`${classes.containerItem} ${isContrast ? classes.itemActive : ''}`}
				onClick={() => {
					setIsContrast(!isContrast);
					dispatch(actions.changeContrast(!isContrast));
				}}>
				<div className={`${classes.iconItem} ${isContrast ? classes.iconItemActive : ''}`}>
					<img src={!isContrast ? IconContrast : IconContrastActive} alt="Cambiar contraste del color" />
				</div>
				<label className={classes.labelItem}>Cambiar contraste del color</label>
			</div>
		</div>
	)
}

export default Contrast;