/* eslint-disable react-hooks/exhaustive-deps */
// DEPENDENCIES
import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
// COMPONENTS
import Tabs from '../../../components/Tabs';
import Layout from '../../../components/Layout';
// VIEWS
import ContactInformation from './ContactInformation';
import ContactAddress from './ContactAddress';
import ContactReference from './ContactReference';
import ValidateContact from './ValidateContact';
// RESOURCES
import { formatDate } from '../../../utils/dates';
// ASSETS
import { tabsOptions } from './utils/utils';
import { HeaderList } from '../../../components/HeaderList';
import { TABS_CONTACT } from './utils/tabs.utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

const listItems = [
	'En este servicio puedes actualizar tus datos de contacto, domicilio actual y referencia. Asegúrate de escribir tus datos de manera correcta para facilitar futuros trámites.',
	'Recuerda que sólo puede haber un número celular y un correo electrónico vinculado a un Número de Seguridad Social (NSS).',
];

const ContactPage = () => {
	const { fontSizeLevel } = useSelector((state: RootState) => state.session);
	let params = useParams();
	const [selectedTab, setSelectedTab] = useState(0);
	const [validateDatos, setValidateDatos] = useState(false);
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	useEffect(() => {
		setValidateDatos(params.validate ? true : false);
	}, [params]);

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<Layout updateTagText={updateTagText}>
			<div ref={divRef}>
				<HeaderList
					title="Actualizar datos de contacto"
					date={formatDate('PP')}
					list={listItems}
				/>
				{validateDatos && <ValidateContact setValidateDatos={setValidateDatos} />}
				{!validateDatos && (
					<Tabs
						tabs={tabsOptions}
						onChangeTab={(index, tab) => setSelectedTab(index)}
						active={selectedTab}
						containerPosition={selectedTab + 1}
						cardsType
					>
						<div style={{ width: '100%' }}>
							{selectedTab === TABS_CONTACT.CONTACT_INFORMATION && <ContactInformation />}
							{selectedTab === TABS_CONTACT.CONTACT_ADDRESS && <ContactAddress />}
							{selectedTab === TABS_CONTACT.CONTACT_REFERENCE && <ContactReference />}
						</div>
					</Tabs>
				)}
			</div>
		</Layout>
	);
};

export default ContactPage;
