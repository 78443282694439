/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Paper, Divider, Link, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import Layout from '../../components/Layout';
import TableCustom from '../../components/TableCustom';
import IconDes from '../../assets/img/icono-descarga.png';
import { apiConsultationWorkRelations } from '../../api/modules/ConsultationWorkRelations';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import ModalLoading from '../../components/ModalLoading';
import { RootState } from '../../store';
import CustomAlert from '../../components/CustomAlert';
import { HeaderList } from '../../components/HeaderList';
import { downloadPDF } from '../../utils/downloadPDF';

const colsWith: Array<string | number> = ['auto', 230, 'auto', 'auto', 'auto'];

const columnas: Array<string> = [
	'No.',
	'Número de Registro Patronal (NRP)',
	'Nombre del patrón',
	'Fecha en que inició la relación laboral',
	'Fecha en que finalizó la relación laboral',
];

interface IData {
	fechaDesde: string;
	fechaHasta: string;
	no: number;
	nrp: string;
	razonSocial: string;
}

const ConsultationWorkRelations = () => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<Array<Array<string | number>>>([]);
	const { user, fontSizeLevel } = useSelector((state: RootState) => state.session);
	const [alert, setAlert] = useState({
		show: false,
		message: '',
	});
	const [nssBlock, setNssBlock] = useState(false);
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	useEffect(() => {
		if (user?.nss === '01008115881' || user?.nss === '62917507774') {
			setNssBlock(true);
		} else {
			const req = async () => {
				try {
					setLoading(true);
					setAlert({
						message: '',
						show: false,
					});
					const keyRes = await keyAPI.get<'', { k: string }>();
					const key = keyRes['k'].toString();
					const nss_encript = await encrypt(
						user?.nss?.toString() || '',
						key
					);
					const { result } =
						await apiConsultationWorkRelations.getRelacion(
							nss_encript
						);
					const array = result.map((element: IData) => {
						return [
							element.no,
							element.nrp,
							element.razonSocial,
							element.fechaDesde,
							element.fechaHasta,
						];
					});
					setData(array);
				} catch (error: any) {
					setAlert({
						message:
							error?.description || INTERNAL_MESSAGES.CHECK_DATA,
						show: true,
					});
				} finally {
					setLoading(false);
				}
			};
			req();
		}
	}, []);

	const PdfDownload = async () => {
		try {
			setLoading(true);
			setAlert({
				message: '',
				show: false,
			});
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_encript = await encrypt(user?.nss.toString() || '', key);
			const name_encript = await encrypt(
				user?.given_name.toString() || '',
				key
			);
			const rfc_encript = await encrypt(user?.rfc.toString() || '', key);
			const curp_encript = await encrypt(
				user?.curp.toString() || '',
				key
			);
			const { data } = await apiConsultationWorkRelations.getPdfRelacion(
				nss_encript,
				name_encript,
				rfc_encript,
				curp_encript
			);
			if (data) {
				downloadPDF({
					payload: data as string,
					name: 'Relaciones laborales',
				});
			} else {
				setAlert({
					message: INTERNAL_MESSAGES.UNABLE_LOAD_PDF,
					show: true,
				});
			}
		} catch (error: any) {
			setAlert({
				message:
					error?.description || INTERNAL_MESSAGES.ERROR_GETTING_DATA,
				show: true,
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<Layout updateTagText={updateTagText}>
			<div style={{ fontFamily: 'Geomanist', fontSize: 15 }} ref={divRef}>
				<ModalLoading loading={loading} />
				<HeaderList
					title="Consulta de relaciones laborales"
					list={[
						'En este servicio puedes consultar tus relaciones laborales, periodos en los que prestaste servicios de forma retribuida.',
						`Esta información se utiliza para calcular tu puntuación, generar
					avisos de suspensión o retención, denunciar indicios de evasión o
					hacer tu devolución del ahorro.`,
					]}
				/>
				<Paper style={{ padding: 20 }}>
					{nssBlock && (
						<Grid>
							<Grid item xs={12} style={{ marginBottom: 15 }}>
								<CustomAlert
									show={true}
									severity={'error'}
									message={
										'Conforme a tu solicitud, el servicio se encuentra bloqueado para su consulta por este medio. Para obtener tu información deberás acudir a un Centro de Servicio Infonavit.'
									}
								/>
							</Grid>
						</Grid>
					)}
					{!nssBlock && (
						<Grid container columns={12}>
							{alert.message !== '' && (
								<Grid item xs={12} style={{ marginBottom: 15 }}>
									<CustomAlert
										show={alert.show}
										severity={'error'}
										message={alert.message}
										onClose={() => {
											setAlert({
												message: '',
												show: false,
											});
										}}
									/>
								</Grid>
							)}

							<Grid item xs={12}>
								<TableCustom
									data={data}
									columns={columnas}
									widthColum={colsWith}
								/>
							</Grid>

							{data.length > 0 && (
								<Grid item xs={12}>
									<Link
										underline="none"
										href="#dowload"
										style={{
											display: 'flex',
											width: 320,
											margin: 'auto',
											marginBottom: 30,
											alignItems: 'center',
										}}
										onClick={() => PdfDownload()}
									>
										Descargar comprobante de tu información
										<img
											src={IconDes}
											style={{
												width: 24,
												height: 24,
												marginLeft: 5,
												marginBottom: 3,
											}}
											alt="Icono de descarga"
										/>
									</Link>
								</Grid>
							)}
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid
								item
								xs={12}
								style={{
									padding: matches ? '0px 10px' : '0px 30px',
									fontSize: 16,
								}}
							>
								<p style={{ fontWeight: 500, width: '100%', maxWidth: 'max-content' }}>
									Aspectos importantes
								</p>
								<p>
									Tu historia y situación laboral se conforma
									de movimientos como altas, bajas y
									reingresos que reporta tu patrón o empleador
									al Instituto Mexicano del Seguro Social
									(IMSS). Para los Números de Registro
									Patronal (NRP) que inicia con 99, ésta
									información la reportan directamente a
									Infonavit.
								</p>
								<p style={{ width: '100%', maxWidth: 'max-content' }}>
									Mostramos{' '}
									<strong style={{ color: '#293990' }}>
										hasta tus últimas 20 relaciones
										laborales a partir del 3er bimestre de
										2005;
									</strong>{' '}
									aparecen de la más reciente a la más
									antigua.
								</p>
							</Grid>
						</Grid>
					)}
				</Paper>
			</div>
		</Layout>
	);
};

export default ConsultationWorkRelations;
