import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	title: {
		fontSize: '14px !important',
	},
	title1: {
		fontSize: '15px !important',
	},
	title2: {
		fontSize: '16px !important',
	},
	title3: {
		fontSize: '17px !important',
	},
	title4: {
		fontSize: '18px !important',
	},
	title5: {
		fontSize: '19px !important',
	},
	titleMin1: {
		fontSize: '13px !important',
	},
	titleMin2: {
		fontSize: '12px !important',
	},
	titleMin3: {
		fontSize: '11px !important',
	},
}));
