// RESOURCES
import { Box } from '@mui/material';
// COMPONENTS
import CustomButton from '../../../components/Button';
// ASSETS
import { useStyles } from './styles';
import CustomAlert from '../../../components/CustomAlert';
import { useNavigate } from 'react-router-dom';

const CloseCase = () => {
	const navigate = useNavigate();
	const classes = useStyles();

	return (
		<>
			<div className={classes.container}>
				<div className={classes.panelAsociacion}>
					<Box className={classes.TextoCentrado}>
						<p className={classes.title}>
							Gracias por utilizar los servicios digitales del
							Infonavit
						</p>
					</Box>
					<Box
						sx={{
							alignItems: 'center',
							justifyContent: 'center',
							display: 'flex',
						}}
					>
						<p className={classes.VerificacionText}>
							Tu dispositivo se ha desvinculado correctamente. La
							próxima vez que incies
							<br />
							sesión desde tu aplicación deberás vincular el nuevo
							dispositivo.
						</p>
					</Box>
					<CustomAlert
						show={true}
						severity="success"
						message="Dispositivo desvinculado"
					/>
				</div>
				<Box className={classes.containerButtons}>
					<CustomButton
						onClick={() => navigate('/')}
						styles={{ width: '260px' }}
						variant="solid"
						label="Finalizar"
					/>
				</Box>
			</div>
		</>
	);
};

export default CloseCase;
