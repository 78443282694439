import { makeStyles, createStyles } from '@mui/styles';
import { Theme, styled } from '@mui/material/styles';
import { Button, Select } from '@mui/material';
import PickersDay, { PickersDayProps } from '@mui/lab/PickersDay';
import { grey } from '@mui/material/colors';

export const UseStyle = makeStyles((theme: Theme) =>
	createStyles({
		BoxContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: 35,
			background: '#F2F2F2',
			color: '#293990',
			fontSize: 15,
			fontWeight: 500,
		},
		flexContainer: {
			display: 'grid',
			gridTemplateRows: 'auto auto auto',
			gridGap: '10px',
		},
		PaysModuleItem: {
			fontSize: 15,
			margin: 0,
			minWidth: 185,
			'& span': {
				color: '#7F7F7F',
				display: 'block',
			},
			'& p': {
				margin: 0,
				color: '#000000',
				fontSize: 16,
				fontWeight: 500,
			},
			'& label': {
				margin: 0,
				color: '#293990',
				fontSize: 16,
				fontWeight: 500,
			},
		},
		infoPer: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			color: '#373737',
			fontSize: 14,
			marginBottom: 8,
			'& label': {
				color: '#000',
				fontWeight: 500,
				margin: '0px 5px',
			},
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				justifyContent: 'normal',
				alignItems: 'normal',
			},
		},
		infoMount: {
			display: 'flex',
			padding: '0px 5px',
			flexDirection: 'column',
			color: '#7F7F7F',
			fontSize: 14,
			flexGrow: 1,
			'& label': {
				fontWeight: 500,
				fontSize: 18,
			},
			[theme.breakpoints.down('md')]: {
				display: 'flex',
				marginRight: 0,
			},
		},
		inforea: {
			display: 'flex',
			justifyContent: 'space-between',
			fontSize: 14,
			borderBottom: '1px solid rgba(0,0,0,0.1)',
			padding: '10px 20px',
			marginBottom: 12,
			'& label': {
				fontWeight: 500,
			},
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				justifyContent: 'normal',
				textAlign: 'left',
			},
		},
		modalTab: {
			background: '#F2F2F2',
			fontSize: 13,
			fontWeight: 500,
			padding: 10,
			maxWidth: 170,
			height: 55,
			textAlign: 'center',
			margin: 'auto',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			cursor: 'pointer',
			'& label': {
				color: '#7F7F7F',
				userSelect: 'none',
				cursor: 'pointer',
			},
			[theme.breakpoints.down('md')]: {
				maxWidth: 'none',
			},
		},
		modalTabActive: {
			background: '#D1001F',
			fontSize: 13,
			fontWeight: 500,
			padding: 10,
			maxWidth: 170,
			height: 55,
			textAlign: 'center',
			margin: 'auto',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			cursor: 'pointer',
			'& label': {
				color: '#FFFFFF',
				userSelect: 'none',
				cursor: 'pointer',
			},
			[theme.breakpoints.down('md')]: {
				maxWidth: 'none',
			},
		},
		ItemCheck: {
			display: 'flex',
			fontSize: 14,
			color: '#333333',
			margin: '5px 0px',
			maxWidth: 800,
		},
		myCreditDataItem: {
			background: '#F2F2F2',
			minHeight: 136,
			marginBottom: 45,
			color: '#7F7F7F',
			fontSize: 15,
			textAlign: 'center',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			'& div': {
				margin: 20,
				background: '#FFFFFF',
				border: '1px solid #293990',
				padding: '20px 10px',
				borderRadius: 5,
			},
			'& p': {
				display: 'block',
				color: '#333333',
				margin: 0,
				marginBottom: 2,
				fontWeight: 500,
			},
			'& span': {
				fontSize: 20,
				margin: '0px 5px',
				color: '#333333',
				fontWeight: 500,
			},
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				padding: 20,
			},
		},
		myCreditDataElemnt: {
			display: 'grid',
			gridTemplateColumns: 'repeat(auto-fill, 33.33%)',
			textAlign: 'left',
			margin: '20px 10px',
			borderBottom: '1px solid rgba(0,0,0,0.1)',
			fontSize: 15,
			paddingBottom: 15,
			'& span': {
				fontWeight: 500,
			},
			'& label': {
				color: '#CE0020',
				display: 'flex',
				alignItems: 'center',
			},
			[theme.breakpoints.down('md')]: {
				gridTemplateColumns: '1fr',
			},
		},
		ClarificactionPayItem: {
			fontSize: 15,
			color: '#333333',
			display: 'flex',
			alignItems: 'center',
		},
		WhoPayItems: {
			userSelect: 'none',
			display: 'flex',
			alignItems: 'flex-start',
			margin: '15px 0px',
		},
		AttachDocumentItem: {
			fontSize: 15,
			color: '#333333',
			display: 'flex',
			alignItems: 'baseline',
		},
		AttachsDocumentItem: {
			fontSize: 15,
			color: '#333333',
			display: 'flex',
			margin: '15px 0px',
			alignItems: 'center',
			'& span': {
				fontWeight: 500,
				flexGrow: 1,
			},
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				alignItems: 'normal',
			},
		},
		AttachsDocumentItem2: {
			fontSize: 15,
			color: '#333333',
			display: 'flex',
			margin: '15px 0px',
			alignItems: 'center',
			'& span': {
				fontWeight: 500,
			},
			'& article': {
				flexGrow: 1,
				fontSize: 14,
			},
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				alignItems: 'normal',
			},
		},
		Subtitle: {
			fontSize: 15,
			color: '#000',
			display: 'inline-block',
			margin: '10px 0px',
			fontWeight: 500,
		},
		paymentOptionsContainer: {
			padding: '20px 9vw',
			[theme.breakpoints.down('md')]: {
				padding: 30,
			},
		},
		paymentMethodContainer: {
			width: '48%',
			border: `solid 1px ${grey[200]}`,
			borderRadius: 3,
			padding: '10px 30px',
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			'&>.payment-image-container': {
				'&>img': {
					margin: '0 5px',
				},
			},
			[theme.breakpoints.down('md')]: {
				width: '70%',
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
			},
		},
		proceedPaymentContainer: {
			padding: '20px 10vw',
			'&>.info-text': {
				textAlign: 'center',
			},
			'&>.proceed-actions': {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				'&>button': {
					maxWidth: 200,
					[theme.breakpoints.down('sm')]: {
						maxWidth: '100%',
					},
				},
				[theme.breakpoints.down('sm')]: {
					flexDirection: 'column',
				},
			},
		},
		accountStatusContainer: {
			padding: '20px 10vw',
		},
		actionsContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'end',
			'&>button': {
				maxWidth: 230,
				[theme.breakpoints.down('sm')]: {
					maxWidth: '100%',
				},
			},
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				alignItems: 'center',
			},
		},
		centeredItems: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			textAlign: 'center',
		},
		customArrow: {
			background: 'white',
		},
		tableZebra: {
			'& tbody > tr:nth-child(even)': {
				backgroundColor: '#f2f2f2',
			},
		},
		paymentFormsContainer: {
			flexWrap: 'wrap',
			justifyContent: 'center',
			padding: '0px',
			gap: '10px',
			marginBottom: '20px',
		},
		paymentMessage: {
			padding: '0 100px',
			[theme.breakpoints.down('sm')]: {
				padding: '0 20px',
			},
		},
		maxContent: {
			width: '100%',
			maxWidth: 'max-content'
		},
		noMargin: {
			margin: 0
		}
	})
);

export const RoundBtn = styled(Button)(({ theme }) => ({
	textTransform: 'none',
	borderRadius: 40,
	width: '100%',
	margin: 10,
}));

export const CustomBtn = styled(Button)(({ theme }) => ({
	textTransform: 'none',
}));

export const CustomBtn2 = styled(Button)(({ theme }) => ({
	textTransform: 'none',
	fontWeight: 400,
	fontSize: 13,
	margin: '0px 10px',
	padding: 0,
}));

export const CustomSelect = styled(Select)(({ theme }) => ({
	height: 40,
	width: '100%',
}));

type CustomPickerDayProps = PickersDayProps<Date> & {
	isFirstDay: boolean;
	isLastDay: boolean;
	typeDay?: string | null;
};

export const CustomPickersDay = styled(PickersDay, {
	shouldForwardProp: (prop) => prop !== 'isFirstDay' && prop !== 'isLastDay',
})<CustomPickerDayProps>(({ isFirstDay, isLastDay, typeDay }) => ({
	...(typeDay
		? {
			borderRadius: 0,
			'&:hover, &:focus': {
				backgroundColor: 'rgba(41, 57, 144, 0.7)',
				color: '#fff',
			},
		}
		: {
			color: 'gray',
		}),
	...(isFirstDay && {
		borderTopLeftRadius: '50%',
		borderBottomLeftRadius: '50%',
	}),
	...(isLastDay && {
		borderTopRightRadius: '50%',
		borderBottomRightRadius: '50%',
	}),
	...(typeDay === 'alta' && {
		backgroundColor: 'rgba(58, 190, 0, 0.4)',
		color: 'rgb(58, 190, 0)',
	}),
	...(typeDay === 'media' && {
		backgroundColor: 'rgba(253, 156, 0, 0.4)',
		color: 'rgb(253, 156, 0)',
	}),
	...(typeDay === 'baja' && {
		backgroundColor: 'rgba(255, 29, 29, 0.4)',
		color: 'rgb(255, 29, 29)',
	}),
	'&.Mui-selected': {
		backgroundColor: 'rgb(41, 57, 144) !important',
	},
})) as React.ComponentType<CustomPickerDayProps>;
