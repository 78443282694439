import { useState, useEffect } from 'react';
import { Paper, Grid, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { useStyles } from './styles';
import { defaultMedio } from '../utils';
import CustomButton from '../../../components/Button';
import { HeaderList } from '../../../components/HeaderList';
import checkicon from '../../../assets/img/admiracion-icon.png';

interface Props {
	setPage: (data: number) => void;
	setResp2: (data: string) => void;
}

export const MedioView = ({ setPage, setResp2 }: Props) => {
	const [formMedio, setFormMedio] = useState(defaultMedio);
	const [buttonValidate, setButtonValidate] = useState(true);
	const classes = useStyles();

	useEffect(() => {
		if (formMedio.medio !== '') {
			setButtonValidate(false);
		} else {
			setButtonValidate(true);
		}
	}, [formMedio.medio]);

	const handleChange = ({ target }: any) => {
		const { name, value } = target;
		setFormMedio({ ...formMedio, [name]: value });
	};

	return (
		<div>
			<HeaderList
				title="Carta de instrucción de cancelación de hipoteca"
				date="Información al 12 julio 2021"
				list={[
					'Esta carta debes entregarla al notario que elegiste para que lleve a cabo la liberación de tu hipoteca y quede registrada en el Registro Público de la Propiedad.',
					'La vigencia de la carta de cancelación es de 180 días naturales, si en ese tiempo no la entregas al notario (solo notario), deberás iniciar el trámite nuevamente.',
				]}
			/>
			<Paper className={classes.divPrincipal}>
				<p className={`${classes.noMargin} ${classes.maxContent}`}>
					<b>
						Elige el medio por el que vas a hacer tu cancelación de tu
						hipoteca
					</b>
				</p>
				<RadioGroup
					aria-labelledby="demo-radio-buttons-group-label"
					name="radio-buttons-group"
					sx={{ marginLeft: '30px', marginTop: '10px' }}
				>
					<FormControlLabel
						value="registro"
						control={
							<Radio
								name="registro"
								onChange={(e: any) =>
									handleChange({
										target: { name: 'medio', value: e.target.value },
									})
								}
							/>
						}
						label={<p className={classes.noMargin}>Carta al Registro Público de la Propiedad</p>}
					/>
					<Grid item xs={12} lg={12} className={classes.divChecks}>
						<img
							className={classes.iconsImg}
							alt="check icon"
							src={checkicon}
						></img>
						<p className={classes.txtPrincipales}>
							La carta que se presenta para el Registro de la Propiedad es
							la que se certifica que el crédito fue liquidado a favor del
							acreditado (la cual debe aprobar el Instituto), se debe
							presentar ante el Registro Público de la Propiedad para que
							proceda a la liberación de la hipoteca.
						</p>
						<br></br>
					</Grid>
					<FormControlLabel
						value="notario"
						control={
							<Radio
								name="notario"
								onChange={(e: any) =>
									handleChange({
										target: { name: 'medio', value: e.target.value },
									})
								}
							/>
						}
						label={<p className={classes.noMargin}>Carta al notario</p>}
					/>
					<Grid item xs={12} lg={12} className={classes.divChecks}>
						<img
							className={classes.iconsImg}
							alt="check icon"
							src={checkicon}
						></img>
						<p className={classes.txtPrincipales}>
							La carta al notario es la que certifica que el crédito fue
							liquidado a favor del acreditado y es enviada al notario para
							que elabore la carta de cancelación (la cual debe aprobar el
							Instituto), así como el pago de los impuestos y derechos al
							notario por sus servicios, se deben presentar ante el Registro
							Público de la Propiedad para que proceda con la liberación de
							la hipoteca.
						</p>
						<br></br>
					</Grid>
				</RadioGroup>
				<br></br>
				<br></br>
				<div className={classes.contentFileDataButtons}>
					<CustomButton
						onClick={() => {
							setPage(1);
						}}
						variant="outlined"
						styles={{
							width: 260,
							marginInline: 10,
						}}
						label="Regresar"
					/>
					<CustomButton
						onClick={() => {
							setPage(3);
							setResp2(formMedio.medio);
						}}
						disabled={buttonValidate}
						variant="solid"
						styles={{
							width: 260,
							marginInline: 10,
						}}
						label="Continuar"
					/>
				</div>
			</Paper>
		</div>
	);
};

export default MedioView;
