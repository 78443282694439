import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		alertContainer: {
			width: '70%',
		},
		parrafoBold: {
			fontFamily: 'Geomanist, Regular',
			fontSize: '16px',
			lineHeight: '1.5',
			fontWeight: '500',
		},
		parrafoBoldSpace: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			paddingLeft: '80px',
			[theme.breakpoints.down('sm')]: {
				paddingLeft: 0,
				display: 'flex',
				justifyContent: 'center',
			},
		},
		radioButton: {
			marginTop: 20,
			display: 'flex',
			alignItems: 'flex-start',
			'& p': {
				margin: 0,
				color: theme.palette.info.main,
				fontSize: 14,
				display: 'flex',
				alignItems: 'center',
			},
			'& label': {
				display: 'block',
				fontSize: 16,
				fontWeight: 400,
				marginBottom: 5,
			},
		},
		titleContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			paddingLeft: '80px',
			'&>h1': {
				color: theme.palette.info.dark,
			},
			'&>p': {
				[theme.breakpoints.down('sm')]: {
					display: 'none',
				},
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: '0px',
				justifyContent: 'center',
			},
		},
		textGray14: {
			fontSize: '16px',
			color: '#858585',
		},
		link: {
			textDecoration: 'underline',
			color: 'red',
			cursor: 'pointer',
			'&:hover': {
				cursor: 'pointer',
			},
		},
		VerificacionCodigo: {
			width: '50px',
			background: '#F2F2F2',
			borderRadius: '5px',
			height: '50px',
			color: '#000000',
			borderColor: '#BABABA',
			borderStyle: 'solid',
			borderWidth: '1',
			display: 'flex',
			alignItems: 'center',
			paddingleft: '10',
			fontSize: '2em',
			textAlign: 'center',
		},
		VerificacionCodigoTitle: {
			fontSize: '20px',
			textAlign: 'center',
			color: '#293990',
			width: '100%',
			display: 'block',
			clear: 'both',
		},

		textMovil: {
			[theme.breakpoints.down('sm')]: {
				fontSize: '16px',
			},
		},
		divCodigos: {
			width: '100%',
			maxWidth: '400px',
			margin: '0 auto 35px',
			alignItems: 'center',
			justifyContent: 'space-between',
			flexDirection: 'row',
			display: 'flex',
			alignContent: 'center',
		},
		VerificacionText: {
			fontSize: '17px',
			textAlign: 'center',
			width: '100%',
		},
		VerificacionLogo: {
			alignItems: 'center',
			justifyContent: 'center',
			display: 'flex',
			marginBottom: 10,
		},
		parrafo: {
			fontFamily: 'Geomanist, Regular',
			fontSize: '16px',
			lineHeight: '1.5',
			[theme.breakpoints.down('sm')]: {
				display: 'flex',
				alignItems: 'center',
				padding: '5px',
				paddingLeft: '10px',
			},
		},
		containerNip: {
			display: 'flex',
			flexDirection: 'row',
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
			},
		},
		imageContainer: {
			paddingRight: 80,
			[theme.breakpoints.down('md')]: {
				paddingRight: 0,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				marginBottom: '5px',
			},
			[theme.breakpoints.down('sm')]: {
				paddingRight: 0,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				marginBottom: '5px',
			},
		},
		firstAlert: {
			display: 'flex',
			paddingLeft: '20%',
			justifyContent: 'center',
			paddingRight: '20%',
			flexDirection: 'row',
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				paddingRight: 30,
				paddingLeft: 30,
			},
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
				paddingRight: 10,
				paddingLeft: 10,
			},
		},
		containerButtonsEnd: {
			paddingTop: 10,
			paddingBottom: 10,
			display: 'flex',
			justifyContent: 'center',
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			},
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			},
		},
		parrafospace: {
			justifyContent: 'space-between',
			alignItems: 'center',
			marginLeft: '100px',
			marginRight: '100px',
			marginTop: '4%',
			[theme.breakpoints.down('sm')]: {
				marginLeft: '10px',
				marginRight: '10px',
				fontSize: '14px',
			},
		},
		formControlRadioGroupContainer: {
			margin: '10px 150px',
			width: '250px',
			[theme.breakpoints.down('sm')]: {
				margin: 0,
				display: 'flex',
				justifyContent: 'center',
				width: '100%',
			},
		},
		lineText: {
			display: 'flex',
			justifyContent: 'start',
			flexDirection: 'row',
			alignItems: 'center',
			height: 'auto',
			marginBottom: '1%',
			[theme.breakpoints.down('sm')]: {
				marginBottom: '1%',
			},
		},
		containerCheckbox: {
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
		},
		BoxTextContainer: {
			marginBottom: '40px',
			marginTop: '25px',
			width: '100%',
			paddingLeft: '100px',
			paddingRight: '40px',

			[theme.breakpoints.down('md')]: {
				paddingLeft: '15px',
				paddingRight: '15px',
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: '0',
				paddingRight: '0',
				marginBottom: '10px',
			},
		},
		BoxTextContainerList: {
			marginBottom: '40px',
			marginTop: '3%',
			width: '100%',
			paddingLeft: '110px',
			paddingRight: '50px',

			[theme.breakpoints.down('md')]: {
				paddingLeft: '15px',
				paddingRight: '15px',
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: '15px',
				paddingRight: '15px',
				marginBottom: '10px',
			},
		},
		BoxContainerInputs: {
			marginBottom: '40px',
			marginTop: '3%',
			width: '100%',
			paddingLeft: '180px',
			paddingRight: '200px',

			[theme.breakpoints.down('md')]: {
				paddingLeft: '15px',
				paddingRight: '15px',
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: '15px',
				paddingRight: '15px',
				marginBottom: '10px',
			},
		},
		nipGenerationText: {
			display: 'flex',
			justifyContent: 'center',
			flexDirection: 'column',
			marginBottom: '20px',
			[theme.breakpoints.down('sm')]: {
				paddingLeft: '5px',
			},
		},
		BoxTextContainerTitle: {
			marginBottom: '40px',
			marginTop: '25px',
			width: '200%',
			paddingLeft: '50px',
			paddingRight: '100px',
			[theme.breakpoints.down('md')]: {
				paddingLeft: '0',
				paddingRight: '0',
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: '0',
				paddingRight: '0',
			},
		},
		Checklabel: {
			// Estilos para el texto del label
			color: '#DD0528', // Color rojo cuando el Checkbox esté habilitado
		},
		unlokNipContainerFormControl: {
			marginTop: '10px',
			marginBottom: '10px',
			marginLeft: '150px',
			marginRight: '150px',
			[theme.breakpoints.down('sm')]: {
				marginLeft: '15px',
				marginRight: '15px',
			},
		},
		nipTitle: {
			width: '100%',
			textAlign: 'center',
			color: '#a9a9a9',
			[theme.breakpoints.down('md')]: {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexWrap: 'wrap',
				'&>div': {
					width: '100%',
				},
			},
			[theme.breakpoints.down('sm')]: {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			},
		},
		nipTitleBloked: {
			paddingLeft: '420px',
			[theme.breakpoints.down('md')]: {
				paddingLeft: '15px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: '15px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			},
		},
		halfUnlokTextContainer: {
			padding: '10px 200px',
			// border: '1px solid red',
			[theme.breakpoints.down('md')]: {
				padding: '10px 15px',
			},
			[theme.breakpoints.down('sm')]: {
				padding: '10px 15px',
			},
		},
		lookConfirmationAlert: {
			marginTop: '50px',
			paddingLeft: '130px',
			paddingRight: '130px',
			[theme.breakpoints.down('md')]: {
				marginTop: '5px',
				paddingLeft: '10px',
				paddingRight: '10px',
			},
			[theme.breakpoints.down('sm')]: {
				marginTop: '10px',
				paddingLeft: '15px',
				paddingRight: '15px',
			},
		},
		noMargin: {
			margin: '0',
		},
		containerCheck: {
			width: '100%',
			maxWidth: 'max-content',
			margin: '0 auto',
			[theme.breakpoints.down('sm')]: {
				padding: '0 20px',
				textAlign: 'center'
			},
		},
		maxContent: {
			width: '100%',
			maxWidth: 'max-content',
		}
	})
);
