import { Box, useMediaQuery } from '@mui/material';
import { useStyles } from './style';
import ErrorIcon from '@mui/icons-material/Error';
import { ReactChild, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
	PropsCheckParagraph,
	PropsRowInfo3,
	RowUploadFileProps,
	TableProps,
} from './interfaces';
import { useTheme } from '@mui/material/styles';
import DocumentView from './DocumentView';

export const CheckParagraph = ({ children }: PropsCheckParagraph) => {
	const style = useStyles();
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
			}}
			className={style.CheckParagraph}
		>
			<ErrorIcon
				color={'info'}
				style={{
					marginRight: '5px',
					height: '18px',
				}}
			/>
			<div>
				<span
					style={{
						color: '#293990',
						fontSize: '12px',
						lineHeight: '0px',
					}}
				>
					{children}
				</span>
			</div>
		</Box>
	);
};

export const typeDemand = [
	{ name: 'A favor', code: '1' },
	{ name: 'En contra', code: '2' },
	{ name: 'Con desistimiento', code: '3' },
];

export const saveBase64File = (
	_file: File,
	name: string,
	setFileBase64: React.Dispatch<
		React.SetStateAction<{
			file: string | ArrayBuffer | null;
			name: string;
		}>
	>
) => {
	const reader = new FileReader();
	reader.readAsDataURL(_file);
	reader.onload = () => {
		setFileBase64({ file: reader.result, name: name } as {
			file: string | ArrayBuffer | null;
			name: string;
		});
	};
};

export const TableInfo = ({ tableLabels }: TableProps) => {
	return (
		<div key={'TableInfo' + tableLabels?.length}>
			{tableLabels?.map((row) => (
				<RowInfo
					value={row.value || ''}
					label={row.label}
					showSeparatorBottom={
						row.showSeparatorBottom === undefined
							? true
							: row.showSeparatorBottom
					}
				></RowInfo>
			))}
		</div>
	);
};

export const RowInfo = ({
	value,
	label,
	showSeparatorBottom,
}: PropsRowInfo3) => {
	const style = useStyles();
	return (
		<div style={{ marginTop: 15 }}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<label className={style.TextBlack_H3}>{label}</label>
				</div>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						maxWidth: '70%',
					}}
				>
					<label
						style={{ wordBreak: 'break-word', textAlign: 'right' }}
					>
						{value || ''}
					</label>
				</div>
			</div>
			{showSeparatorBottom !== false && (
				<hr
					style={{
						marginTop: 15,
						color: '#8A919C',
						height: '1px',
						opacity: '20%',
					}}
				/>
			)}
		</div>
	);
};

export const ReintentoFlow: number[] = [8, 9, 10, 11, 12, 13, 14];

export const notHasDemandpensioFlow: number[] = [
	0, 1, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 14,
];
export const notAccordingAmount: number[] = [0, 1, 3, 16, 15, 14];
//TODO.- REVISAR FLUJOS
export const jobTerminationFlow: number[] = [0, 1, 3, 4, 8, 12, 13, 14];
export const pensioBaseFlow: number[] = [0, 1, 3, 4, 5, 6, 7];
export const pensio1Flow: number[] = [8, 9, 10, 11, 12, 13, 14, 15]; // Validar si adjuntar documentos va
export const pensio2Flow: number[] = [];
export const pensio3Flow: number[] = [];
export const pensio4Flow: number[] = [];
export const privatePensionPlanContributionsFlow: number[] = [
	0, 1, 2, 3, 4, 8, 12, 13, 14,
];
export const commissionResolutioFlow: number[] = [0, 1, 15, 14];
export const previousPensionFlow: number[] = [0, 1, 15, 14];
export const MessageScreenFlow: number[] = [18];

export function getNumberPage(
	arrayFlow: number[],
	currentPage: number,
	isNext: boolean
): number {
	const indexFlow: number = arrayFlow.findIndex((num) => num === currentPage);

	let newIndexFlow: number = isNext ? indexFlow + 1 : indexFlow - 1;
	if (arrayFlow.length === 1) {
		newIndexFlow = indexFlow;
	}
	return arrayFlow[newIndexFlow];
}

function getArrayFlowPension(
	grupo: string | undefined,
	demandType: string | undefined
) {
	let _grupo: string = grupo || '';

	if (_grupo === '234' || _grupo === '') {
		return pensioBaseFlow;
	}

	if (_grupo === '1') {
		let _array = pensioBaseFlow.concat(pensio1Flow);

		return _array;
	}

	if (_grupo === '2' || _grupo === '3') {
		let _array = pensioBaseFlow.concat(pensio2Flow);

		if (demandType === '1' || demandType === '3') {
			//adjunta documentos
			_array = _array.concat([15, 8, 9, 10, 11, 12, 13, 14]);
		} else if (demandType === '2') {
			//certificados
			_array = _array.concat([8, 9, 10, 11, 12, 13, 14]);
		} else {
			//nada
			_array = _array.concat([8, 9, 10, 11, 12, 13, 14, 15]);
		}
		return _array;
	}

	if (_grupo === '4') {
		let _array = pensioBaseFlow.concat(pensio4Flow);

		if (demandType === '1' || demandType === '3') {
			//adjunta doctos
			_array = _array.concat([8, 9, 10, 11, 12, 13, 14]);
		} else if (demandType === '2') {
			//certificados
			_array = _array.concat([8, 9, 10, 11, 12, 13, 14]);
		} else {
			//nada
			_array = _array.concat([8, 9, 10, 11, 12, 13, 14]);
		}
		return _array;
	}
}

export function getArrayFlow(
	flow: string,
	page: number,
	accordingAmount?: boolean,
	hasDemand?: boolean,
	viewMessageScreen?: boolean,
	isReintentoSolicitud?: boolean,
	grupo?: string,
	demandType?: string,
	isDate?: boolean
): number[] {
	let newArray: number[] = [];
	if (
		(page === 0 && flow === '' && isDate !== true) ||
		(page === 1 && flow === '' && isDate !== true)
	) {
		newArray = [0, 1];
		return newArray;
	}

	if (isReintentoSolicitud === true) {
		newArray = ReintentoFlow;
		return newArray;
	}
	if (viewMessageScreen === true) {
		newArray = MessageScreenFlow;
		return newArray;
	}

	if (accordingAmount === false) {
		newArray = notAccordingAmount;
		return newArray;
	}

	if (hasDemand === false) {
		newArray = notHasDemandpensioFlow;
		return newArray;
	}

	switch (flow) {
		case 'terminacionLaboral':
			newArray = jobTerminationFlow;
			break;
		case 'resolucionPensionImss':
			newArray = getArrayFlowPension(grupo, demandType) || [];
			break;
		case 'planPrivadoPension':
			newArray = privatePensionPlanContributionsFlow;
			break;
		case 'porInconformidades':
			newArray = commissionResolutioFlow;
			break;
		case 'pensionAnterior1997':
			newArray = previousPensionFlow;
			break;
		default:
			newArray = [page];
			break;
	}

	return newArray;
}

export const RowUploadFile = ({
	file,
	setFile,
	setAlert,
	label,
	subtitle,
	formats,
	separatorDown = false,
	viewFile = true,
	subItems,
}: RowUploadFileProps) => {
	const [modalView, setModalView] = useState(null);

	const handleFile = async (e: any) => {
		const type = e.target.files[0].type;
		let size = e.target.files[0].size / 1024 ** 2;
		if (size < 20) {
			if (/jpg|jpeg|png|tiff|pdf/g.test(type)) {
				setAlert({
					show: false,
					message: '',
					severity: 'success',
				});
			} else {
				setAlert({
					show: true,
					message:
						'Verifica que el formato del archivo es el correcto. Los formatos permitidos son: jpg, png, tiff, pdf',
					severity: 'error',
				});
				return;
			}
		} else {
			setAlert({
				show: true,
				message: 'El tamaño del archivo no debe de ser mayor a 20 MB.',
				severity: 'error',
			});
			return;
		}
		setFile({
			name: e.target.files[0].name,
			data: e.target.files[0],
			type: type,
			size: size,
		});
		e.target.value = null;
	};
	const handleDeleteFile = () => {
		setFile({
			name: 'No se eligió ningún archivo',
			data: null,
			type: '',
			size: 0,
		});
	};

	const style = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<div>
			{!separatorDown && (
				<hr
					style={{
						opacity: 0.2,
						marginTop: '4px',
						marginBottom: '4px',
					}}
				/>
			)}
			<DocumentView file={modalView} setClose={setModalView} />
			<div className={style.containerFiles}>
				<div className={style.containerFilesOne}>
					<h4 style={{ margin: 0 }}>
						{label}
						<span style={{ color: 'red' }}>*</span>
					</h4>
					{subtitle && (
						<p className={style.p} style={{ color: 'black' }}>
							Recuerda adjuntar un solo documento en cada lista:
						</p>
					)}
				</div>
				<div className={style.containerFilesTwo}>
					<p className={style.textFile}>{file.name}</p>
				</div>
				<div className={style.containerFilesThree}>
					{file.name === 'No se eligió ningún archivo' && (
						<>
							<label
								style={{
									cursor: 'pointer',
									color: 'red',
									fontSize: 16,
									marginTop: '-2px',
								}}
							>
								<input
									type="file"
									accept={formats}
									name="file1"
									onChange={handleFile}
									style={{ display: 'none' }}
								/>
								Elegir archivo
							</label>
						</>
					)}

					{file.name !== 'No se eligió ningún archivo' && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: matches ? 'center' : 'right',
							}}
						>
							<div
								style={{
									display: 'flex',
									justifyContent: 'right',
									marginLeft: 10,
									marginTop: '-10px',
								}}
							>
								<label
									style={{ cursor: 'pointer', color: 'red' }}
									onClick={handleDeleteFile}
								>
									<ClearIcon
										style={{
											fontSize: 16,
											marginTop: 10,
											color: 'red',
										}}
									/>{' '}
									Eliminar archivo
								</label>
							</div>
							{viewFile === true && (
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										marginLeft: 10,
										marginTop: '-10px',
									}}
								>
									<label
										style={{
											cursor: 'pointer',
											color: 'red',
										}}
										onClick={() => setModalView(file.data)}
									>
										<SearchIcon
											style={{
												fontSize: 16,
												marginTop: 10,
												color: 'red',
											}}
										/>{' '}
										Ver archivo
									</label>
								</div>
							)}
						</div>
					)}
				</div>
			</div>

			{subItems !== undefined && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					{subItems?.map((item) => (
						<span style={{ color: '#7F7F7F' }}>{item}</span>
					))}
				</div>
			)}

			{separatorDown && (
				<hr
					style={{
						opacity: 0.2,
						marginTop: '4px',
						marginBottom: '4px',
					}}
				/>
			)}
		</div>
	);
};

export function getHeaderList(page: number) {
	let result: string[] | ReactChild[] | undefined;

	switch (page) {
		case 0:
		case 1:
			result = [
				'En este servicio podrás solicitar la devolución de tu ahorro si eres un derechohabiente pensionado. No podrá ser utilizado por los beneficiarios.',
			];
			break;
		case 10:
			result = [
				'Captura tu tipo de pensión de acuerdo a tu documento de resolución de pensión sin acentos y sin espacios al final de la palabra.',
			];
			break;
		case 11:
			result = ['Elige la AFORE en la que actualmente estás registrado.'];
			break;
		case 12:
			result = [
				'Para hacer la transferencia ingresa tu Clave Bancaria Estandarizada (CLABE). Verifica que la CLABE sea igual a la de tu estado de cuenta bancaria.',
			];
			break;
		default:
			result = [];
			break;
	}
	return result;
}

export const MESSAGES_ERROR = {
	errorSucursales:
		'Lo sentimos, en este momento no tenemos centros de servicios disponibles en el estado seleccionado. Intenta más tarde',
	errorDays:
		'Lo sentimos, en este momento no tenemos días disponibles para programar una cita. Intenta más tarde',
	errorHorario:
		'Lo sentimos, en este momento no tenemos horarios disponibles para programar una cita. Intenta más tarde',
	errorService:
		'Por el momento el servicio no está disponible. Intenta más tarde',
};
