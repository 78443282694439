/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Paper } from '@mui/material';
import Layout from '../../../../components/Layout';
import { MIUC_PRODUCTS_COMPONENT } from '../../utils/home.utils';
import { ProductCard } from '../../components/ProductCard/productCard';
import ButtonGroup from '../../components/ButtonGroup';
import { useNavigate } from 'react-router-dom';
import { useProfile } from '../../hooks/useProfile';
import { useEffect, useState, useRef } from 'react';
import ErrnoPage from '../../../CreditRequest/ErrnoPage';
import { MIUC_PRODUCTS } from '../../constants/products';
import { useController } from '../../hooks/useController';
import { encodeAllJSONData } from '../../../../utils/encrypt';
import {
	IDataconsultarAgroconsultaRes,
	apiMIUC,
} from '../../../../api/modules/MIUC';
import { RETURN_CODE } from '../../constants/returnCode';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import ModalMessageTempMiuc from '../../components/ModalMessageTempMiuc';

export const Precalificacion = () => {
	const {
		getInitialProfile,
		getMIUCProfile,
		errorMesage,
		showModalTemp,
		setShowModalTemp,
		getInitialProfileHome,
		salioDeAgro,
		getDataAgro,
		savedAgroData
	} = useProfile({
		initProcess: true,
	});
	const { saveData, dataProcessController } = useController();
	const navigate = useNavigate();
	const [isCrediterreno, setIsCrediterreno] = useState(false);
	const { user, fontSizeLevel } = useSelector((state: RootState) => state.session);
	const [agroRegistrado, setAgroRegistrado] = useState('');
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	useEffect(() => {
		getInitialProfile({
			...MIUC_PRODUCTS_COMPONENT[0],
		});
		getDataAgro()
	}, []);

	useEffect(() => {
		if (isCrediterreno) {
			navigate('/precalificacion-puntos/comprar-terreno-o-comprar-terreno-y-construir-una-vivienda');
		}
	}, [dataProcessController]);

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<Layout updateTagText={updateTagText}>
			<Box maxWidth={1500} margin={'auto'} ref={divRef}>
				<ModalMessageTempMiuc
					show={showModalTemp}
					setsShow={setShowModalTemp}
				/>
				<Paper>
					{errorMesage !== '' && (
						<ErrnoPage msgString={errorMesage} />
					)}
					{errorMesage === '' && (
						<>
							<Box
								sx={{
									boxShadow: '0px 3px 14px #00000014',
									paddingY: 3,
									marginBottom: {
										xs: '32px',
										md: '100px',
									},
								}}
							>
								<h2
									style={{
										textAlign: 'center',
										color: '#293990',
									}}
								>
									Me interesa un crédito para:
								</h2>
							</Box>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									gap: {
										xs: '16px',
										md: '40px',
									},
									flexWrap: 'wrap',
									paddingBottom: {
										xs: '32px',
										md: '64px',
									},
								}}
							>
								{savedAgroData?.sitioRegistrado === 'SR' ||
									salioDeAgro
									? MIUC_PRODUCTS_COMPONENT.map((item) => (
										<ProductCard
											key={item.code}
											icon={item.icon}
											product={item.code}
											title={item.name}
											description=""
											onClick={async () => {
												if (
													item.code ===
													MIUC_PRODUCTS.CREDITERRENO
												) {
													saveData(
														{
															processInfo: {
																creditName:
																	'comprar-terreno-o-comprar-terreno-y-construir-una-vivienda',
															},
															processData: {
																productLine:
																	item.line,
															},
														},
														true
													);
													setIsCrediterreno(true);
												} else {
													await getMIUCProfile({
														code: item.code,
														model: item.model,
														name: item.name,
														line: item.line,
													});
												}
											}}
										/>
									))
									: MIUC_PRODUCTS_COMPONENT.filter(
										(item) =>
											item.code ===
											MIUC_PRODUCTS.COMPRA
									).map((item) => (
										<ProductCard
											key={item.code}
											icon={item.icon}
											product={item.code}
											title={item.name}
											description=""
											onClick={async () => {
												await getMIUCProfile({
													code: item.code,
													model: item.model,
													name: item.name,
													line: item.line,
												});
											}}
										/>
									))}
							</Box>
							<Box pb={'32px'}>
								<ButtonGroup
									buttons={[
										{
											label: 'Regresar',
											execute: () => navigate('/inicio'),
											variant: 'solid',
										},
									]}
								/>
							</Box>
						</>
					)}
				</Paper>
			</Box>
		</Layout>
	);
};
