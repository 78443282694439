/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Grid } from '@mui/material';
import { useStyles } from './styles';

import { useNavigate } from 'react-router-dom';
//Assets
import imgAltanto from '../../../assets/img/mantente-al-tanto-2.png';
import imgProrroga from '../../../assets/img/prorroga.png';
import imgReestruct from '../../../assets/img/reestructura.png';
import imgAgendaCita from '../../../assets/img/Agenda-tu-cita.png';
import imgAclaracion from '../../../assets/img/aclaracion.png';

const PensionerPaymentProblems = () => {
	const classes = useStyles();

	const navigate = useNavigate();

	return (
		<>
			<Grid container spacing={2}>
				<Grid item sm={12} md={12} lg={4}>
					<Paper className={classes.card}>
						<div className={classes.titleCard}>
							<h3>
								¿Estás a punto de pensionarte o eres
								pensionado(a) y tienes problemas para
								terminar de pagar tu crédito?
							</h3>
						</div>
						<div className={classes.textCard}>
							<p>
								Consulta en Saldos y movimientos el estatus
								de tu crédito, cuánto debes, si te aplica
								prórroga total o si cuentas con la opción
								de alguna reestructura.
							</p>
							<p>
								¿Tienes duda de algún pago? Solicita una
								aclaración de pagos para evaluar tu situación.
							</p>
							<p>
								¿Deseas realizar tus pagos? Ingresa a Pagos
								en línea para realizar tu pago o para localizar
								los lugares donde puedes pagar.
							</p>
						</div>
					</Paper>
				</Grid>

				<Grid item md={12} lg={8}>
					<Grid container spacing={2}>
						<Grid item lg={12}>
							<div
								onClick={() =>
									navigate('/mi-credito/saldos-movimientos')
								}
								style={{ height: '100%' }}
							>
								<img
									src={imgAltanto}
									className={classes.imageStyle}
									alt="Mantente al tanto con toda la información de tu saldo y movimientos de tu credito"
								/>
							</div>
						</Grid>
					</Grid>

					<Grid container spacing={2} mt={1}>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<div
								onClick={() =>
									navigate('/mi-credito/saldos-movimientos')
								}
								style={{ height: '100%' }}
							>
								<img
									src={imgProrroga}
									className={classes.imageStyle}
									alt="Con Prórroga total te damos tiempo para regularizar tu situación"
								/>
							</div>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<div
								onClick={() =>
									navigate('/contactanos/haz-una-cita')
								}
								style={{ height: '100%' }}
							>
								<img
									src={imgAgendaCita}
									className={classes.imageStyle}
									alt="Agenda tu cita"
								/>
							</div>
						</Grid>
					</Grid>

					<Grid container spacing={2} mt={1}>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<div
								onClick={() =>
									navigate(
										'/mi-credito/solicitud-reestructuras'
									)
								}
								style={{ height: '100%' }}
							>
								<img
									src={imgReestruct}
									className={classes.imageStyle}
									alt="¿Te gustaría disminuir tu pago mensual?"
								/>
							</div>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<div
								onClick={() =>
									navigate('/mi-credito/aclaracion-pagos')
								}
								style={{ height: '100%' }}
							>
								<img
									src={imgAclaracion}
									className={classes.imageStyle}
									alt="¡Realiza la aclaración de un pago aquí!"
								/>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default PensionerPaymentProblems;
