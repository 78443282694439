/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { useStyles } from '../../styles';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store';
import { actions } from '../../../../store/modules/session';

import Icon from '../../../../assets/img/iconsToolAccessibility/iconLector-1.svg';
import Icon2 from '../../../../assets/img/iconsToolAccessibility/iconLector-2.svg';

const validTags = ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'button', 'a', 'img', 'li', 'label', 'input', 'span', 'iframe'];

const Lector = () => {
	const { activeLector } = useSelector((state: RootState) => state.session);
	const dispatch = useDispatch();
	const classes = useStyles();
	const [active, setActive] = useState(false);
	const elementCooldown = useRef(new Map()); // Mapa para gestionar cooldown por elemento
	const cooldownTime = 5000; // Tiempo de cooldown en milisegundos (5 segundos)
	const [cancelSpeaking] = useState(false); //Activar para cortar el audio si se selecciona otra etiqueta de lectura
	let textActual = '';

	const handleMouseOver = (event: MouseEvent) => {
		if (active && event.target instanceof HTMLElement) {
			const tagName = event.target.tagName.toLowerCase();
			const element = event.target;

			if (validTags.includes(tagName)) {
				const lastReadTime = elementCooldown.current.get(element);

				if (cancelSpeaking) {
					speechSynthesis.cancel();
				}

				if (!lastReadTime || Date.now() - lastReadTime > cooldownTime) {

					if (tagName === 'img') {
						const text = (event.target as HTMLImageElement).alt;
						if (text.length > 0) {
							if (text !== textActual) {
								textActual = text;
								handleSpeakText(text);
								elementCooldown.current.set(element, Date.now()); // Registrar el tiempo actual
							}
						}
					} else if ((tagName === 'button' || tagName === 'span') && event.target.classList.contains('tag-to-read')) {
						const ariaLabel = event.target.getAttribute('aria-label');
						if (ariaLabel) {
							if (ariaLabel !== textActual) {
								textActual = ariaLabel;
								handleSpeakText(ariaLabel);
								elementCooldown.current.set(element, Date.now());
							}
						}
					} else if (tagName === 'iframe' && event.target.classList.contains('tag-to-read')) {
						const ariaLabel = event.target.getAttribute('aria-label');
						if (ariaLabel) {
							if (ariaLabel !== textActual) {
								textActual = ariaLabel;
								handleSpeakText(ariaLabel);
								elementCooldown.current.set(element, Date.now());
							}
						}
					} else if (tagName !== 'span') {
						const text = event.target.innerText;
						if (text.length > 0) {
							if (text !== textActual) {
								if (text.includes('$')) {
									const modifiedText = text.replace('$', '').trim();
									handleSpeakText(modifiedText);
									elementCooldown.current.set(element, Date.now());
								} else {
									textActual = text;
									handleSpeakText(text);
									elementCooldown.current.set(element, Date.now());
								}
							}
						}
					}
				}
			}
		}
	};

	const handleSpeakText = (text: string) => {
		const speech = new SpeechSynthesisUtterance(text);
		speech.lang = "es-MX";
		speechSynthesis.speak(speech);
	};

	useEffect(() => {
		const eventType = "ontouchstart" in window ? "click" : "mouseover";
		if (active) {
			document.addEventListener(eventType, handleMouseOver as EventListener);
		} else {
			document.removeEventListener(eventType, handleMouseOver as EventListener);
		}
		return () => {
			document.removeEventListener(eventType, handleMouseOver as EventListener);
		};
	}, [active]);

	useEffect(() => {
		setActive(activeLector);
	}, [activeLector]);

	return (
		<div>
			<div
				className={`${classes.containerItem} ${active ? classes.itemActive : ''}`}
				onClick={() => {
					setActive(!active);
					dispatch(actions.changeActiveLector(!active));
				}}
			>
				<div className={`${classes.iconItem} ${active ? classes.iconItemActive : ''}`}>
					<img src={active ? Icon2 : Icon} alt="" />
				</div>
				<label className={classes.labelItem}>Usar un lector de pantalla</label>
			</div>
		</div>
	);
};

export default Lector;
