/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useStyles } from '../../styles';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store';
import { actions } from '../../../../store/modules/session';

import IconGrayScale from '../../../../assets/img/iconsToolAccessibility/iconGray-1.svg';
import IconGrayScaleActive from '../../../../assets/img/iconsToolAccessibility/iconGray-2.svg';

interface Props {
	isGrayScale: boolean;
	setIsGrayScale: (data: boolean) => void;
}

const GrayScale = ({ isGrayScale, setIsGrayScale }: Props) => {
	const { activeGrayScale } = useSelector((state: RootState) => state.session);
	const dispatch = useDispatch();
	const classes = useStyles();

	useEffect(() => {
		setIsGrayScale(activeGrayScale);
	}, []);

	return (
		<div>
			<div className={`${classes.containerItem} ${isGrayScale ? classes.itemActive : ''}`}
				onClick={() => {
					setIsGrayScale(!isGrayScale);
					dispatch(actions.changeGrayScale(!isGrayScale));
				}}>
				<div className={`${classes.iconItem} ${isGrayScale ? classes.iconItemActive : ''}`}>
					<img src={!isGrayScale ? IconGrayScale : IconGrayScaleActive} alt="Cambiar a escala de grises" />
				</div>
				<label className={classes.labelItem}>Cambiar a escala de grises</label>
			</div>
		</div>
	)
}

export default GrayScale;