import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	contentFileDataButtons: {
		display: 'flex',
		justifyContent: 'center',
		marginBlock: 10,
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			width: '100%',
		},
		'&>button': {
			[theme.breakpoints.down('sm')]: {
				margin: '20px auto !important',
			},
		},
	},
	container: {
		borderRadius: 6,
		backgroundColor: 'white',
		padding: 20,
		paddingBlock: 50,
		marginTop: 30,
		boxShadow: '0px 5px 30px #00000017',
	},
	containerFirma: {
		borderRadius: 6,
		backgroundColor: 'white',
		padding: 20,
		boxShadow: '0px 5px 30px #00000017',
	},
	contentFile: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap',
		},
	},
	contentFileData: {
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			width: '100%',
			textAlign: 'center',
		},
	},
	contentFileDataBox: {
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			width: '100%',
			textAlign: 'center',
		},
	},
	contentFileDataTitle: {
		width: '100%',
		maxWidth: 'max-content',
		fontSize: 16,
		fontWeight: 'bold',
		[theme.breakpoints.down('sm')]: {
			fontSize: 15,
			marginLeft: 'auto',
			marginRight: 'auto'
		},
	},
	contentFileDataText: {
		marginLeft: '15px',
		fontSize: 15,
		color: '#7F7F7F',
		[theme.breakpoints.down('sm')]: {
			fontSize: 13,
			marginLeft: 'unset',
		},
	},
	contentFileDataLink: {
		color: '#D1001F',
		fontSize: 14,
		[theme.breakpoints.down('sm')]: {
			margin: '0 auto'
		},
	},
	contentFileDataLink2: {
		fontSize: 14,
	},
	contentFileDataInput: {
		width: 300,
		border: '1px solid #CFD6D9',
		borderRadius: 4,
		padding: 10,
		marginLeft: 250,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			marginLeft: 0,
		},
	},
	principalTitle: {
		color: '#293990',
		fontSize: 16,
		maxWidth: 'max-content'
	},
	checkHeader: {
		display: 'flex',
		alignItems: 'flex-start',
		marginBottom: 7,
	},
	checkHeaderText: {
		color: '#333',
		fontSize: 15,
		textAlign: 'left',
		padding: 0,
		margin: 0,
	},
	amountText: {
		color: '#293990',
		fontSize: 16,
		margin: 0,
		padding: 0,
		fontWeight: 600,
		marginTop: 15,
	},
	amount: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-end',
		color: '#293990',
		fontSize: 24,
		margin: 0,
		padding: 0,
		textAlign: 'center',
	},
	txtMonto: {
		fontSize: 50,
		fontWeight: 'bold',
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			margin: '0 auto',
			width: '100%',
			maxWidth: 'max-content'
		},
	},
	currency: {
		fontSize: 15,
		margin: 0,
		marginLeft: 5,
		marginBottom: 5,
		padding: 0,
		color: '#7F7F7F',
		fontWeight: 400,
	},
	amountContainer: {
		border: '1px solid #293990',
		borderRadius: 4,
		maxWidth: 300,
		margin: '10px auto',
		padding: '5px 40px',
	},
	descriptionContainerTitles: {
		display: 'flex',
		alignItems: 'center',
		padding: '0px 80px',
		cursor: 'pointer',
		[theme.breakpoints.down('sm')]: {
			padding: '0px 30px',
		},
	},
	descriptionContainerTitle: {
		fontSize: 15,
	},
	contentFormClabe: {
		maxWidth: 400,
		marginLeft: 200,
		[theme.breakpoints.down('sm')]: {
			marginLeft: 0,
		},
		[theme.breakpoints.down('md')]: {},
	},
	contentFooterData: {
		maxWidth: 800,
		margin: '20px auto',
		textAlign: 'center',
	},
	contentFooterDataLink: {
		color: '#D1001F',
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		maxWidth: 'max-content',
		margin: '0 auto'
	},
	containerResume: {
		borderRadius: 6,
		backgroundColor: 'white',
		padding: '20px 80px',
		paddingBlock: 50,
		boxShadow: '0px 5px 30px #00000017',
		[theme.breakpoints.down('sm')]: {
			padding: 20,
		},
		'& h4': {
			marginTop: '10px !important',
			marginBottom: '10px !important',
		},
	},
	noMargin: {
		margin: '0',
	},
	maxContent: {
		width: '100%',
		maxWidth: 'max-content',
		margin: '0 auto'
	},
	containerBank: {
		padding: '10px 80px',
		[theme.breakpoints.down('sm')]: {
			padding: '20px',
		},
		'& h4': {
			marginTop: '10px !important',
			marginBottom: '10px !important',
		},
	}
}));
