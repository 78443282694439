import { Divider } from '@mui/material';
import { useStyles } from './styles';

const DatosObligatorios = () => {
	const styles = useStyles();

	return (
		<>
			<div className={styles.containerObligatorio}>
				<Divider />
				<p className={`${styles.txtBlue} ${styles.maxContent}`}>
					<b>* Datos obligatorios</b>
				</p>
			</div>
		</>
	);
};

export default DatosObligatorios;
