import React, { useState } from 'react';
import {
	Alert,
	Fade,
	FormControlLabel,
	IconButton,
	InputAdornment,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import { Close, Info, Search } from '@mui/icons-material';
import BaseModal from '../../components/Modal';

//Assets
import { useStyles } from './styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export interface ITextField {
	id: string;
	name: string;
	label?: string;
	labelComponent?: React.DetailedHTMLProps<
		React.HTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	>;
	value: string | number | '';
	error?: boolean;
	helperText?: string | false;
	type?: 'password' | 'search' | 'text';
	size?: 'small' | 'medium' | undefined;
	isRequired?: boolean;
	labelStyles?: React.CSSProperties;
	sx?: React.CSSProperties;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	placeholder?: string;
	onBlur?: (e: any) => void;
	onFocus?: (e: any) => void;
	noCopy?: boolean;
	noPaste?: boolean;
	disabled?: boolean;
	inputProps?: object;
	InputProps?: object; //Referente a las propiedades de los Input's
	select?: boolean;
	startAdornment?: React.ReactElement;
	tooltipText?: string;
	tooltipIconStyle?: React.CSSProperties;
	contentStyles?: object;
	maxLength?: number | undefined;
	multiline?: boolean;
	rows?: number;
	handleSearch?: () => void;
	handleClearSearch?: () => void;
	labelHelper?: string;
	tooltipModal?: {
		show: boolean;
		type: string;
		urlImage: string;
		alt?: string;
	};
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#FFFFFF',
		// color: 'black',
		maxWidth: 450,
		// fontSize: '12px',
		border: '1px solid #FFFFFF',

		boxShadow: theme.shadows[5],
	},
}));

const TextInput = (props: ITextField) => {
	const {
		label,
		value,
		onChange,
		id,
		name,
		helperText,
		type,
		isRequired,
		size,
		sx,
		labelStyles,
		placeholder,
		onBlur,
		onFocus,
		noPaste,
		noCopy,
		disabled,
		inputProps,
		InputProps, //Referente a las propiedades de los Input's
		select,
		labelComponent,
		startAdornment,
		tooltipText,
		tooltipIconStyle,
		contentStyles = {},
		maxLength,
		multiline,
		rows,
		handleSearch,
		handleClearSearch,
		labelHelper,
		tooltipModal,
	} = props;
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const handleMouseDownPassword = () => setShowPassword(!showPassword);
	const [showModal, setShowModal] = useState(false);
	const classes = useStyles();

	return (
		<div style={{ width: '100%', ...contentStyles }}>
			<FormControlLabel
				label={
					<>
						<span
							style={{ ...labelStyles }}
							className={classes.labelContainerInput}
						>
							<p style={{ margin: 0, display: 'inline' }}>{labelComponent ? labelComponent : label}</p>
							{isRequired && (
								<label className={classes.labelRequired}>
									*{' '}
								</label>
							)}
							{tooltipText && (
								<label
									style={{
										color: '#DD0528',
										position: 'relative',
									}}
								>
									<HtmlTooltip
										title={
											<React.Fragment>
												<Typography
													color="inherit"
													sx={{
														fontFamily:
															'Geomanist, Regular',
														fontSize: '14px',
														color: 'black',
													}}
												>
													{tooltipText}
												</Typography>
											</React.Fragment>
										}
										placement="right"
										arrow
										TransitionComponent={Fade}
										TransitionProps={{ timeout: 600 }}
									>
										<Info
											style={{
												fontSize: 20,
												marginLeft: 15,
												...tooltipIconStyle,
											}}
										/>
									</HtmlTooltip>
								</label>
							)}
							{tooltipModal && (
								<>
									<label
										style={{
											color: '#DD0528',
											position: 'relative',
										}}
									>
										<HtmlTooltip
											title={
												<React.Fragment>
													<Typography
														color="inherit"
														sx={{
															fontFamily:
																'Geomanist, Regular',
															fontSize: '14px',
															color: 'black',
														}}
													></Typography>
												</React.Fragment>
											}
											placement="right"
											disableFocusListener={true}
											disableHoverListener={true}
											TransitionComponent={Fade}
											TransitionProps={{ timeout: 600 }}
											onClick={() => setShowModal(true)}
										>
											<Info
												style={{
													fontSize: 20,
													marginLeft: 15,
													...tooltipIconStyle,
												}}
											/>
										</HtmlTooltip>
									</label>
									<BaseModal
										open={showModal}
										width={'md'}
										hideCloseButton={true}
										onClose={() => {
											setShowModal(false);
										}}
									>
										<div>
											{tooltipModal.type === 'image' && (
												<div>
													<img
														className={
															classes.textField
														}
														src={
															tooltipModal.urlImage
														}
														alt={tooltipModal.alt || 'banner'}
													/>
												</div>
											)}
										</div>
									</BaseModal>
								</>
							)}
						</span>
						<>
							{labelHelper && (
								<div
									style={{ ...labelStyles }}
									className={`${classes.labelContainerInput} ${classes.helperText}`}
									dangerouslySetInnerHTML={{
										__html: labelHelper || '',
									}}
								></div>
							)}
						</>
					</>
				}
				className={classes.containerInput}
				labelPlacement="top"
				control={
					<TextField
						inputProps={{
							...inputProps,
							'data-testid': id,
							maxLength: maxLength,
						}}
						InputProps={{
							...InputProps,
							endAdornment:
								(type === 'password' && (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={
												handleMouseDownPassword
											}
										>
											{showPassword ? (
												<Visibility />
											) : (
												<VisibilityOff />
											)}
										</IconButton>
									</InputAdornment>
								)) ||
								(type === 'search' && (
									<InputAdornment position="end">
										<IconButton
											size="small"
											onClick={handleSearch}
										>
											<Search fontSize="small" />
										</IconButton>
										<IconButton
											size="small"
											onClick={handleClearSearch}
										>
											<Close fontSize="small" />
										</IconButton>
									</InputAdornment>
								)),
							startAdornment,
						}}
						id={id}
						name={name}
						type={
							type === 'password'
								? showPassword
									? 'text'
									: 'password'
								: 'text'
						}
						variant="outlined"
						margin="dense"
						size={size || 'small'}
						fullWidth
						sx={sx}
						className={classes.textField}
						value={value}
						onChange={onChange}
						onBlur={onBlur}
						onFocus={onFocus}
						placeholder={placeholder}
						onPaste={(e) => {
							if (noPaste) {
								e.preventDefault();
								return false;
							}
						}}
						onCopy={(e) => {
							if (noCopy) {
								e.preventDefault();
								return false;
							}
						}}
						disabled={disabled}
						select={select}
						multiline={multiline}
						rows={rows}
					/>
				}
			/>
			{helperText && (
				<Alert
					style={{
						marginTop: 5,
						marginBottom: 5,
						boxSizing: 'border-box',
						backgroundColor: '#FFE2E5',
						color: '#D1001F',
					}}
					severity="error"
					sx={{ width: '100%' }}
					icon={
						<CloseIcon
							fontSize="inherit"
							style={{ color: '#D1001F' }}
						/>
					}
				>
					<span className="tag-to-read" aria-label={helperText}>
						{helperText}
					</span>
				</Alert>
			)}
		</div>
	);
};

export default TextInput;
