import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		VerificacionLogo: {
			alignItems: 'center',
			justifyContent: 'center',
			display: 'flex',
			marginBottom: 20,
		},
		numbersVerification: {
			width: '100%',
			maxWidth: '600px',
			margin: '0 auto',
		},
		VerificacionText: {
			fontSize: '17px',
			textAlign: 'center',
			width: '100%',
		},
		header: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		root: {
			backgroundColor: '#f2f2f2',
			padding: `${theme.spacing(2.5)} 0`,
		},
		container: {
			paddingTop: 50,
			paddingBottom: 25,
			[theme.breakpoints.down('md')]: {
				paddingLeft: 20,
				paddingRight: 20,
				paddingTop: 25,
				paddingBottom: 25,
			},
		},
		movilButton: {
			marginTop: '20px',
			width: '65%',
			textAlign: 'center',
			[theme.breakpoints.down('sm')]: {
				width: '100%',
			},
		},
		textMovil: {
			[theme.breakpoints.down('sm')]: {
				fontSize: '16px',
			},
		},
		containerInput: {
			marginBottom: 15,
		},
		textConfirmation: {
			textAlign: 'center',
			alignItems: 'center',
		},
		p: {
			textAlign: 'left',
			fontSize: 18,
			color: theme.palette.primary.main,
			marginBottom: '20px !important',
			[theme.breakpoints.down('md')]: {
				fontSize: 16,
			},
		},
		input: {
			width: '100%',
			background: '#FFFFFF 0% 0% no-repeat padding-box',
			border: '1px solid #CFD6D9',
			borderRadius: '4',
			opacity: '1',
			margin: 0,
		},
		a: {
			textDecoration: 'underline',
			fontSize: '14px',
			letterSpacing: '0',
			color: '#D1001F',
			opacity: '1',
		},
		paper: {
			height: '100%',
		},
		captcha: {
			marginTop: '20px',
		},
		required: {
			color: '#D1001F',
		},
		legend: {
			textAlign: 'left',
			marginTop: theme.spacing(4),
		},
		passToggle: {
			position: 'absolute',
			zIndex: '1000',
			cursor: 'pointer',
		},
		formInput: {
			position: 'relative',
			zIndex: '90',
			width: '100%',
		},
		formControlLabel: {
			marginLeft: '50px',
		},
		dialogBody: {
			fontSize: 13,
			fontWeight: 400,
			height: 300,
			overflowY: 'auto',
		},
		dialogBodyPrivacy: {
			fontSize: 13,
			fontWeight: 400,
			paddingRight: 10,
			height: 300,
			overflowY: 'auto',
			whiteSpace: 'pre-line',
		},
		dialogCheck: {
			textAlign: 'left',
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(3),
		},
		dialogButtonGroup: {
			justifyContent: 'space-between',
			display: 'flex',
			width: '100%',
			[theme.breakpoints.down('md')]: {
				display: 'flex',
				flexWrap: 'wrap',
				flexDirection: 'column-reverse',
				//textAlign: 'center',
			},
		},
		dialogButtonRight: {
			width: '50%',
			[theme.breakpoints.down('md')]: {
				margin: 0,
				width: '100%',
			},
		},
		dialogButtonLeft: {
			[theme.breakpoints.down('md')]: {
				display: 'inline-block',
			},
		},
		buttonCenter: {
			marginTop: '50px',
			display: 'flex',
			justifyContent: 'center',
			width: '100%',
			[theme.breakpoints.down('md')]: {
				marginTop: '10px',
			},
		},
		VerificacionCodigoTitle: {
			marginLeft: '10%',
			marginRight: '10%',
			fontSize: '35px',
			textAlign: 'center',
			color: '#293990',
			width: '100%',
		},
		containerModalInfo: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			textAlign: 'center',
		},
		containerBtnAcceptModal: {
			width: '50%',
			[theme.breakpoints.down('sm')]: {
				width: '100%',
			},
		},
		textAlert: {
			fontSize: 22,
			fontWeight: 'bold',
		},
		// containerCaptcha: {
		// 	display: 'flex',
		// 	justifyContent: 'center',
		// },
		alertMessageRFC: {
			color: theme.palette.primary.main,
			fontSize: 12,
		},
		containerCaptcha: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			textAlign: 'center',
			marginTop: 20,
			marginBottom: 20,
		},
		containerInformation: {
			width: '100%',
			maxWidth: '570px',
			margin: '0 auto',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			textAlign: 'center',
			flexDirection: 'column',
		},
		containerInformationUpdate: {
			width: '100%',
			maxWidth: '700px',
			margin: '0 auto',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			textAlign: 'center',
			flexDirection: 'column',
		},
		labelName: {
			fontWeight: 'bold',
			fontSize: 18,
			marginTop: 35,
		},
		labelAnswer: {
			fontSize: 14,
			marginBottom: 0,
		},
		titleModalAlert: {
			textAlign: 'center',
			'& a': {
				color: theme.palette.primary.main,
				textDecoration: 'underline',
			},
		},
		containerRequiredFields: {
			width: '100%',
			padding: 15,
		},
		requiredFieldsLabel: {
			fontWeight: 'bold',
			color: theme.palette.info.main,
		},
		titleTermsCenter: {
			textAlign: 'center',
			fontSize: 16,
		},
		titleTerms: {
			fontSize: 16,
			marginBottom: 0,
		},
		privacyTable: {
			borderCollapse: 'collapse',
			width: '100%',
			'& tr': {
				border: '1px solid #dddddd',
				padding: 8,
			},
			'& td': {
				border: '1px solid #dddddd',
				padding: 8,
			},
			'& th': {
				border: '1px solid #dddddd',
				padding: 8,
			},
		},
		containerAlert: {
			width: '90%',
			maxWidth: '800px',
			margin: '0 auto',
		},
	})
);

export const CustomButton1 = styled(Button)({
	width: '100%',
	borderRadius: '50px',
	marginBottom: '120px',
	marginTop: '50px',
});

export const DialogButton = styled(Button)({
	borderRadius: '50px',
	width: 300,
});
