/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useStyles } from '../../styles';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store';
import { actions } from '../../../../store/modules/session';

import IconZoomIn from '../../../../assets/img/iconsToolAccessibility/iconZoomIn-1.svg';
import IconZoomOut from '../../../../assets/img/iconsToolAccessibility/iconZoomOut-1.svg';
import IconZoomInActive from '../../../../assets/img/iconsToolAccessibility/iconZoomIn-2.svg';
import IconZoomOutActive from '../../../../assets/img/iconsToolAccessibility/iconZoomOut-2.svg';

interface Props {
	reset: boolean;
	updateText: boolean;
	setReset: (data: boolean) => void;
}

const TextSizeAdjuster = ({ reset, updateText, setReset }: Props) => {
	const { fontSizeLevel } = useSelector((state: RootState) => state.session);
	const dispatch = useDispatch();
	const classes = useStyles();

	const increaseTextSize = (type: string, valueFont: number) => {
		const value = type === 'add' ? valueFont + 1 : valueFont - 1;
		if (value > 5 || value < -3) return;

		const validTags = document.querySelectorAll<HTMLElement>('p, h1, h2, h3, h4, h5, h6, button, a, li, label, input, span, strong');
		validTags.forEach((element) => {
			if (element instanceof HTMLElement) {
				// Verifica si el elemento tiene hijos válidos
				const hasValidChild = Array.from(element.children).some((child) =>
					Array.from(validTags).includes(child as HTMLElement)
				);
				// Aplica el ajuste solo si no tiene hijos válidos
				if (!hasValidChild) {
					const currentFontSize = window.getComputedStyle(element).fontSize;
					const newSize = parseFloat(currentFontSize) + (type === 'add' ? 1 : -1);
					element.style.fontSize = `${newSize}px`;
					element.classList.add('font-adjusted');
				}
			}
		});
		dispatch(actions.changeFontSizeLevel(value));
	};

	const onlyChange = (value: number, isReset: boolean) => {
		const validTags = document.querySelectorAll<HTMLElement>('p, h1, h2, h3, h4, h5, h6, button, a, li, label, input, span, strong');
		validTags.forEach((element: any) => {
			if (element instanceof HTMLElement) {
				const hasValidChild = Array.from(element.children).some((child) =>
					Array.from(validTags).includes(child as HTMLElement)
				);
				if (!hasValidChild) {
					if (!reset) {
						if (!element.classList.contains('font-adjusted') && !element.classList.contains('tab-item')) {
							const currentFontSize = window.getComputedStyle(element).fontSize;
							const newSize = parseFloat(currentFontSize) + value;
							element.style.fontSize = `${newSize}px`;
							element.classList.add('font-adjusted');
						}
					} else {
						const currentFontSize = window.getComputedStyle(element).fontSize;
						const newSize = parseFloat(currentFontSize) + value;
						element.style.fontSize = `${newSize}px`;
						element.classList.remove('font-adjusted');
					}
				}
			}
		});
		dispatch(actions.changeFontSizeLevel(isReset ? 0 : value));
		setReset(false);
	};

	const calculateDifZero = (data: number) => {
		return 0 - data;
	};

	useEffect(() => {
		if (fontSizeLevel !== 0) {
			onlyChange(fontSizeLevel, false);
		};
	}, []);

	useEffect(() => {
		if (reset) {
			const diference = calculateDifZero(fontSizeLevel);
			onlyChange(diference, true);
		};
	}, [reset]);

	useEffect(() => {
		if (updateText) {
			onlyChange(fontSizeLevel, false);
		};
	}, [updateText])

	return (
		<div>
			<div className={`
				${classes.containerItem} 
				${classes.containerItemLarge} 
				${fontSizeLevel && fontSizeLevel !== 0 ? classes.itemActive : ''}`}>
				<div
					className={`${classes.iconItem} ${classes.iconItemMedium} ${fontSizeLevel < 0 ? classes.iconItemActive : ''}`}
					onClick={() => { increaseTextSize('reduce', fontSizeLevel) }}>
					<img src={fontSizeLevel < 0 ? IconZoomOutActive : IconZoomOut} alt="Reducir tamaño de texto" />
				</div>
				<label className={classes.labelItem}>Cambiar tamaño <br />de texto</label>
				<div
					className={`${classes.iconItem} ${classes.iconItemMedium} ${fontSizeLevel > 0 ? classes.iconItemActive : ''}`}
					onClick={() => { increaseTextSize('add', fontSizeLevel) }}
				>
					<img src={fontSizeLevel > 0 ? IconZoomInActive : IconZoomIn} alt="Aumentar tamaño de texto" />
				</div>
			</div>
		</div>
	)
}

export default TextSizeAdjuster;