import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	buttonLogInSolid: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: '#FFFFFF',
		borderRadius: 50,
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: 'white',
		minHeight: 40,
		height: 'auto',
		width: '100%',
		fontWeight: 'bold',
		fontSize: 14,
		backgroundColor: theme.palette.primary.main,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
			transition: '0.5s',
		},
		'&:active': {
			backgroundColor: theme.palette.primary.main,
			transition: '0.5s',
		},
		'&:disabled': {
			backgroundColor: '#C7C6C5',
			borderColor: '#C7C6C5',
		},
	},
	buttonLogInOutlined: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.primary.main,
		borderRadius: 50,
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: theme.palette.primary.main,
		minHeight: 40,
		height: 'auto',
		width: '100%',
		fontWeight: 'bold',
		fontSize: 14,
		backgroundColor: '#FFFFFF',
		cursor: 'pointer',
		'&:disabled': {
			backgroundColor: '#C7C6C5',
			borderColor: '#C7C6C5',
			color: 'white',
		},
	},
    buttonLogInText: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.primary.main,
		borderRadius: 50,
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: 'transparent',
		minHeight: 35,
		height: 'auto',
		width: '100%',
		fontWeight: 'bold',
		fontSize: 14,
		backgroundColor: '#FFFFFF',
		cursor: 'pointer',
		'&:disabled': {
			backgroundColor: '#C7C6C5',
			borderColor: '#C7C6C5',
			color: 'white',
		},
	},
}));
