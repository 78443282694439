import { useSelector } from 'react-redux';
import { writingRequestAPI } from '../../../api/modules/WritingRequest';
import { encodeAllJSONData } from '../../../utils/encrypt';
import {
	CLAVE_TANTOS,
	CODE_STATUS,
	MSG_ERROR,
	SERVICESID,
} from '../pages/utils';
import { useEscriturasContext } from './useEscriturasContext';
import { RootState } from '../../../store';
import { ICita } from '../pages/interface';
import { CODE_RESPONSE } from '../pages/utils/pagesStatus';
import { ESTATUS } from '../pages/utils/stepsStatus';

interface IEstadoCesi {
	cesiTramite: string;
	edoTramite: string;
}

interface IConsultDetail {
	caso?: string;
	datos?: any;
	isGetCesis?: boolean;
}

export const useEscrituras = () => {
	const { user } = useSelector((state: RootState) => state.session);
	const [escrituras, setEscrituras] = useEscriturasContext();

	// Consultar Detalles
	const getConsultDetail = async ({
		caso,
		datos,
		isGetCesis,
	}: IConsultDetail): Promise<string> => {
		try {
			const data = {
				caso: caso || escrituras.noCaso,
			};
			const dataEncripted = await encodeAllJSONData(
				{ data: JSON.stringify(data) },
				user?.k || ''
			);
			const { result: rDetalle } =
				await writingRequestAPI.consultDetailCaso(dataEncripted);
			if (Number(rDetalle.code) === 0) {
				if (isGetCesis) {
					const { cesiTramite, edoTramite } = await getConsultEstCesi(
						rDetalle?.data?.detalle.edoCesi,
						rDetalle?.data?.detalle.cesi
					);
					setEscrituras({
						...escrituras,
						...rDetalle?.data?.detalle,
						...datos,
						cesiTramite,
						edoTramite,
						estatus: rDetalle?.data?.tabla?.claveEstatus,
						process: rDetalle?.data?.tabla?.process,
						fechaRegAdministrativa:
							rDetalle?.data?.tabla?.fechaRegAdministrativa,
						fechaRegJudical: rDetalle?.data?.tabla?.fechaRegJudical,
						noTantos:
							CLAVE_TANTOS[
								String(rDetalle?.data?.tabla?.claveTantos)
							],
						consultarCita: false,
					});
					return '';
				}
				setEscrituras({
					...escrituras,
					...rDetalle?.data?.detalle,
					...datos,
					estatus: rDetalle?.data?.tabla?.claveEstatus,
					process: rDetalle?.data?.tabla?.process,
					fechaRegAdministrativa:
						rDetalle?.data?.tabla?.fechaRegAdministrativa,
					fechaRegJudical: rDetalle?.data?.tabla?.fechaRegJudical,
					noTantos:
						CLAVE_TANTOS[
							String(rDetalle?.data?.tabla?.claveTantos)
						],
					consultarCita: false,
				});
				return '';
			}
			return rDetalle.message || MSG_ERROR;
		} catch (error) {
			return MSG_ERROR;
		}
	};

	// Consultar Cita
	const getConsultCita = async (): Promise<string> => {
		if (escrituras.estatus === CODE_STATUS.ABIERTO_CITA) {
			try {
				const data = {
					pAppointmentCode: '',
				};
				const dataEncript = await encodeAllJSONData(
					{
						data: JSON.stringify(data),
					},
					user?.k || ''
				);
				const response = await writingRequestAPI.citasActuales(
					dataEncript
				);
				if (response.code === CODE_RESPONSE.SUCCESS) {
					const { citas } = response.data;
					if (citas && citas.length > 0) {
						const findCita: ICita = citas.find(
							(cita: any) => cita.serviceId === SERVICESID
						);
						if (findCita) {
							setEscrituras({
								...escrituras,
								citaId: findCita?.id,
							});
						}
					}
					return '';
				}
				return MSG_ERROR;
			} catch (error) {
				return MSG_ERROR;
			}
		}
		return '';
	};

	// Consultar Estado y Cesi
	const getConsultEstCesi = async (
		edoCesi: string,
		cesi: string
	): Promise<IEstadoCesi> => {
		try {
			const data = {
				idDelEdo: edoCesi,
			};
			const dataEncripted = await encodeAllJSONData(
				{ data: JSON.stringify(data) },
				user?.k || ''
			);
			const { result: rEstado } =
				await writingRequestAPI.getCatalogoEstado();
			const { result: rCesi } = await writingRequestAPI.getCatalogoCesi(
				dataEncripted
			);
			// Consultamos el estado del cesi
			const edoTramite =
				rEstado?.data?.estados?.find((f: any) => f.idDelEdo === edoCesi)
					?.delEdo ?? '';
			// Consultamos el cesi
			const cesiTramite =
				rCesi?.data?.cesis?.find((f: any) => f.idPlaza === cesi)
					?.nombre ?? '';
			return {
				cesiTramite,
				edoTramite,
			};
		} catch (error) {
			return {} as IEstadoCesi;
		}
	};

	const getSubStatus = (): number => {
		const estatus = ESTATUS[escrituras?.estatus];
		if (estatus?.subEstatus) {
			const idSubEstatus = estatus?.subEstatus.find(
				(f) => f.code === escrituras?.idSubEstatus
			);
			return idSubEstatus?.position || -1;
		}
		return -1;
	};

	return {
		escrituras,
		setEscrituras,
		getConsultDetail,
		getConsultCita,
		getConsultEstCesi,
		getSubStatus,
	};
};
