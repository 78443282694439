import { Dialog, Divider } from '@mui/material';
import CustomButton from '../../../../components/Button';
import { useStyles } from './styles/error-message.style';
import { useNavigate } from 'react-router-dom';

const ErrorMessage = ({ message, show }: any) => {
	const classes = useStyles();
	const navigate = useNavigate();
	return (
		<Dialog open={show} fullScreen maxWidth={'md'}>
			<div className={classes.container}>
				<h2>Importante</h2>

				<p>{message}</p>

				<Divider />

				<div className={classes.containerButton}>
					<div className={classes.button}>
						<CustomButton
							label={'Salir'}
							onClick={() => navigate('/')}
						/>
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default ErrorMessage;
