import { Box, Paper } from '@mui/material';
import { useEffect } from 'react';
import Tabs from '../../../components/Tabs';
import { useTabs } from '../../../hooks/useTabs';
import { tabsFlag } from './utils/index';
import RequestPage from './RequestPage';
import CaseFollowUp from './CaseFollowUp';
import CesiQuote from './CesiQuote';
import DeliveryOfDeeds from './DeliveryOfDeeds';
import { PROCESS_PAGE_STATUS } from './utils/pagesStatus';

interface IProps {
	pageTab: number;
	setPage: (arg: number) => void;
}

const ProcessPage = ({ pageTab, setPage }: IProps) => {
	const {
		containerPosition,
		tabActive,
		tabsEle,
		changeTabNext,
		changeTabNextCustom,
	} = useTabs(tabsFlag, 0, 0);

	useEffect(() => {
		if (pageTab > 0) {
			changeTabNextCustom(pageTab);
		}
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [pageTab]);

	return (
		<>
			<Tabs
				tabs={tabsEle}
				containerPosition={containerPosition}
				active={tabActive}
				cardsType={true}
				containerColumns={12}
			>
				<Paper>
					{tabActive === PROCESS_PAGE_STATUS.FORM_EDITION && (
						<Box>
							<RequestPage nextProcess={changeTabNext} />
						</Box>
					)}
					{tabActive === PROCESS_PAGE_STATUS.HISTORY_CASE && (
						<Box>
							<CaseFollowUp nextProcess={changeTabNext} />
						</Box>
					)}
					{tabActive === PROCESS_PAGE_STATUS.CREATE_CESI && (
						<Box>
							<CesiQuote nextProcess={changeTabNext} />
						</Box>
					)}
					{tabActive === PROCESS_PAGE_STATUS.FINISH_PAGE && (
						<Box>
							<DeliveryOfDeeds
								customProcess={changeTabNextCustom}
								setPage={setPage}
							/>
						</Box>
					)}
				</Paper>
			</Tabs>
		</>
	);
};

export default ProcessPage;
