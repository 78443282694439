/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Paper } from '@mui/material';
// Context
import { useNavigation } from '../../hooks/useNavigation';
import { MIUC_PROCESS_VIEWS } from '../../constants/process';
// Components
import FlowCard from '../../components/FlowCard';
import { SelectionCredit } from '../../components/SelectionCredit/selectionCredit';
import { CreditOptions } from '../../components/CreditOptions/creditOptions';
import { BuroForm } from '../../components/BuroForm/buroForm';
import { SelectionResume } from '../../components/SelectionResume/selectionResume';
import ViewPoints from '../../components/ViewPoints';
import FollowSteps from '../../components/FollowSteps';
// Pages
import Products from './pages/products';
// Assets
import Layout from '../../../../components/Layout';
import ResumeCard from '../../components/ResumeCard';
import { useEffect, useState, useRef } from 'react';
import { BuroMssg } from '../../components/BuroMssg/BuroMssg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

const pages = (onSelectCredit: any) => {
	return [
		// <SelectionCredit onSelectCredit={onSelectCredit} />,
		<CreditOptions returnMain />,
		<BuroForm />,
		<Products />,
		<SelectionResume />,
	]
}

const RoutesHipoteca = () => {
	const {
		calcProgress,
		dataProcessController,
		saveData,
		addSelection,
		nextStep,
	} = useNavigation();

	const onSelectCredit = (credit: string) => {
		addSelection({
			title: 'Uso de tu crédito para:',
			description: 'Pagar mi Hipoteca',
		});
		saveData({
			processInfo: {
				credit,
			},
			processData: {
				step: 0,
				active: 1,
				total: 4
			},
		});
		nextStep();
	};
	const { fontSizeLevel } = useSelector((state: RootState) => state.session);
	const step = dataProcessController?.processData?.step;
	const showViewPoints = ![3, 4].includes(step);
	const [mssgFlag, setMssgFlag] = useState(true);
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	useEffect(() => {
		if (dataProcessController.processData.step === undefined) {
			addSelection({
				title: 'Crédito seleccionado para:',
				description: 'Pagar mi Hipoteca',
			});
			saveData({
				processData: {
					active: 1,
					total: 4,
				},
			});
			nextStep(0);
		}
	}, [])

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	//console.log(step)
	return (
		<>
			<Layout updateTagText={updateTagText}>
				<Grid container spacing={2} ref={divRef}>
					{dataProcessController.processInfo?.lineaBC8 && mssgFlag ? (
						<Grid item xs={12} md={12}>
							<Paper>
								<BuroMssg setFlag={setMssgFlag} />
							</Paper>
						</Grid>
					) : (
						<>
							<Grid item xs={12} md={8}>
								<Paper>
									{pages(onSelectCredit)[step || 0]}
								</Paper>
								{step === 4 && (
									<FollowSteps guideLink={require('../../../../assets/files/guia-pasos-a-seguir/GPSLV.pdf')} />
								)}
							</Grid>
							<Grid item xs={12} md={4}>
								{showViewPoints && (
									<Box sx={{ textAlign: 'center' }} mb={2}>
										<ViewPoints />
									</Box>
								)}
								{step === 4 && (
									<Box sx={{ textAlign: 'center' }} mb={2}>
										<ResumeCard />
									</Box>
								)}
								<Box>
									<FlowCard
										title="Tu selección"
										flow={
											dataProcessController.processInfo
												.selection
										}
										progress={calcProgress()}
									/>
								</Box>
							</Grid>
						</>)}</Grid>
			</Layout>
		</>
	);
};

export default RoutesHipoteca;
