/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import Layout from '../../components/Layout';
import GotElecSig from './gotElecSig';
import GotPayslip from './gotPayslip';
import GotSAT from './gotSAT';
import MakeDate from './makeDate';
import RfcHome from './rfcCorrectionHome';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import ModalLoading from '../../components/ModalLoading';
import { IAlert } from '../../interfaces/alert';
import { apiRfcCorrection } from '../../api/modules/rfcCorrection';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import Status from './status';
import CurpRegister from './registraCURP';
import NotCurp from './notCurp';
import NotName from './notName';
import CloseCase from './closeCase';
import { IDataStatus } from './interfaces/register.interface';
import { PAGES_RFC } from './utils/pages.utils';

const RFCCorrection = () => {
	const [page, setPage] = useState(PAGES_RFC.HOME);
	const [loading, setLoading] = useState(false);
	const { user, fontSizeLevel } = useSelector((state: RootState) => state.session);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [dataStatus, setDataStatus] = useState<IDataStatus>({});
	useEffect(() => {
		handleOnSubmit();
	}, [user?.curp, user?.nss, user?.rfc]);
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	const handleOnSubmit = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const rfc_base64 = await encrypt(user?.rfc.toString() || '', key);
			const nss_base64 = await encrypt(user?.nss.toString() || '', key);
			const curp_base64 = await encrypt(user?.curp.toString() || '', key);

			apiRfcCorrection
				.getInitialStatus(nss_base64, rfc_base64, curp_base64)
				.then((response) => {
					if (response?.result) {
						setDataStatus(response.result);
						if (response?.result?.isEmpty) {
							setPage(PAGES_RFC.HOME);
						} else {
							setPage(PAGES_RFC.ESTATUS);
						}
					}
				})
				.catch((error: any) => {
					if (error?.code && error?.code === 'CURP') {
						setPage(PAGES_RFC.REGISTRO_CURP);
					} else if (user?.rfc === '' && error?.code !== 'CURP') {
						setPage(PAGES_RFC.HOME);
					} else {
						setAlert({
							show: true,
							message:
								error && error.description
									? error.description
									: INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
					}
				})
				.finally(() => setLoading(false));
		} catch (error: any) {
			if (error?.code && error?.code === 'CURP') {
				setPage(PAGES_RFC.REGISTRO_CURP);
			} else if (user?.rfc === '' && error?.code !== 'CURP') {
				setPage(PAGES_RFC.HOME);
				setAlert({
					show: true,
					message: error.description
						? error.description
						: INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
			} else {
				setAlert({
					show: true,
					message: error.description
						? error.description
						: INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
			}
		}
	};

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<Layout updateTagText={updateTagText}>
			<Box ref={divRef}>
				<ModalLoading loading={loading} />
				{page === PAGES_RFC.HOME && (
					<RfcHome
						setPage={setPage}
						rfc={user?.rfc}
						alertInitialStatus={alert}
					/>
				)}
				{page === PAGES_RFC.FIRMA_ELECTRONICA && <GotElecSig setPage={setPage} />}
				{page === PAGES_RFC.NOMINA && <GotPayslip setPage={setPage} />}
				{page === PAGES_RFC.SAT && <GotSAT setPage={setPage} />}
				{page === PAGES_RFC.CITAS && <MakeDate setPage={setPage} status={dataStatus} />}
				{page === PAGES_RFC.ESTATUS && <Status status={dataStatus} setPage={setPage} />}
				{page === PAGES_RFC.CASO_CERRADO && <CloseCase status={dataStatus} setPage={setPage} />}
				{page === PAGES_RFC.NO_CURP && <NotCurp setPage={setPage} />}
				{page === PAGES_RFC.NO_NOMBRE && <NotName setPage={setPage} />}
				{page === PAGES_RFC.REGISTRO_CURP && <CurpRegister setPage={setPage} alert={alert} />}
				<div style={{ textAlign: 'center', paddingTop: 15 }}>
					<p style={{ width: '100%', maxWidth: 'max-content', margin: '16px auto' }}>
						Responsable de la información:{' '}
						<strong>
							Gerencia de Administración del Patrimonio Social y Servicios
						</strong>
					</p>
				</div>
			</Box>
		</Layout>
	);
};

export default RFCCorrection;
