/* eslint-disable */
import React from 'react';
import CustomButton from '../../components/Button';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useStyles } from '../Modal/styles';

export interface IModalProps {
	open: boolean;
	title?: string | React.ReactElement;
	cancelButton?: boolean;
	width?: 'md' | 'lg' | 'xl' | 'sm' | 'xs';
	onClose?: () => void;
	onConfirm?: () => void;
	cancelLabel?: string;
	confirmLabel?: string;
	disableOnConfirm?: boolean;
	showDialogTitle?: boolean;
	showDialogActions?: boolean;
	displayColumn?: boolean;
	color?:
	| 'primary'
	| 'inherit'
	| 'secondary'
	| 'success'
	| 'error'
	| 'info'
	| 'warning'
	| undefined;
	children?: React.ReactChild;
	stylesContent?: React.CSSProperties;
	showTitle?: boolean;
}

const BaseModal = (props: IModalProps) => {
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const {
		open,
		cancelButton,
		width,
		onClose,
		onConfirm,
		confirmLabel,
		disableOnConfirm,
		children,
		showDialogActions,
		displayColumn,
		stylesContent,
		cancelLabel,
		showTitle,
		title,
	} = props;
	const { activeDislexic, zoomCursorActive, activeGrayScale, activeContrast, fontSizeLevel } = useSelector((state: RootState) => state.session);

	const getClasses = () => {
		const classMap: any = {
			'0': classes.title,
			'1': classes.title1,
			'2': classes.title2,
			'3': classes.title3,
			'4': classes.title4,
			'5': classes.title5,
			'-1': classes.titleMin1,
			'-2': classes.titleMin2,
			'-3': classes.titleMin3,
		};
		return classMap[fontSizeLevel] || classes.title;
	};

	return (
		<Dialog
			open={open}
			fullWidth
			onClose={(reason) => {
				if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
					onClose;
				}
			}}
			maxWidth={width || 'md'}
			PaperProps={{
				elevation: 1,
			}}
			className={`${zoomCursorActive ? 'custom-cursor' : ''} ${activeDislexic ? 'fontDislexic' : ''} ${activeGrayScale ? 'grayScales' : ''} ${activeContrast ? 'activeContrast' : ''}`}
			TransitionProps={{
				style: {
					backgroundColor: 'rgb(0 0 0 / 50%)',
				},
			}}
		>
			{showTitle && (
				<DialogTitle>
					<div
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						{title}
						<div>
							<IconButton onClick={onClose} className='tag-to-read' aria-label="Cerrar modal">
								<CloseIcon />
							</IconButton>
						</div>
					</div>
				</DialogTitle>
			)}
			<DialogContent style={{ ...stylesContent }} className={fontSizeLevel !== 0 ? getClasses() : ''}>
				{children}
			</DialogContent>
			{cancelButton || onConfirm ? (
				<DialogActions
					style={{
						display: showDialogActions ? 'none' : 'flex',
						flexDirection: matches ? 'column-reverse' : 'row',
						borderTop: '1px solid rgba(0,0,0,0.1)',
						justifyContent: 'center',
						padding: '20px 0',
					}}
				>
					{cancelButton && (
						<div style={{ width: matches ? 250 : 200 }} className={`${getClasses()}`}>
							<CustomButton
								variant="outlined"
								onClick={onClose ? onClose : () => { }}
								label={cancelLabel || 'Cancelar'}
							/>
						</div>
					)}
					{onConfirm && (
						<div
							style={{
								width: matches ? 250 : 200,
								margin: matches ? 0 : 15,
								paddingBottom: matches ? 10 : 0,
							}}
							className={`${getClasses()}`}
						>
							<CustomButton
								disabled={disableOnConfirm}
								variant="solid"
								onClick={onConfirm}
								label={confirmLabel || 'Aceptar'}
							/>
						</div>
					)}
				</DialogActions>
			) : null}
		</Dialog>
	);
};

export default BaseModal;
