import { createContext, Dispatch, SetStateAction } from 'react';
import {
	defaultEscrituras,
	IEscrituras,
} from './utils';

const EscriturasContext = createContext<
	[IEscrituras, Dispatch<SetStateAction<IEscrituras>>]
>([defaultEscrituras, () => {}]);

export default EscriturasContext;
