import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	iconsImg: {
		width: 15,
		marginRight: 5,
	},
	txtPrincipales: {
		color: '#333333',
		fontSize: '14px',
		verticalAlign: 'top',
		letterSpacing: 0,
		opacity: 1,
		margin: 0,
		display: 'inline',
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px',
		},
	},
	divPrincipal: {
		padding: '20px 80px',
		[theme.breakpoints.down('sm')]: {
			padding: '30px',
		},
	},
	divChecks: {
		paddingTop: '5px',
	},
	txt: {
		width: '100%',
		maxWidth: 'max-content',
		[theme.breakpoints.down('sm')]: {
			fontSize: 12,
		},
	},
	txtQuestion: {
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			fontSize: 12,
		},
	},
	contentFileDataButtons: {
		display: 'flex',
		justifyContent: 'center',
		marginBlock: 10,
		marginBottom: 50,
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			width: '100%',
		},
		[theme.breakpoints.down('md')]: {},

		'&>button': {
			[theme.breakpoints.down('sm')]: {
				margin: '20px 0px !important',
				width: '100% !important',
			},
		},
	},
}));
