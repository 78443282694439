/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import Tabs from '../../components/Tabs';
import { Box } from '@mui/system';
import Contributions from './lastContributions';
import Layout from '../../components/Layout';
import MySaving from './mySaving';
import { useTabs } from '../../hooks/useTabs';
import HowItWorks from './howItWorks';
import { HeaderList } from '../../components/HeaderList';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const tabsFlag = [
	{
		id: 1,
		title: 'Mi ahorro',
		disabled: false,
		checked: false,
		columns: 4,
		orderMD: 0,
		orderSM: 0,
	},
	{
		id: 2,
		title: 'Mis últimas aportaciones',
		disabled: false,
		checked: false,
		columns: 4,
		orderMD: 1,
		orderSM: 2,
	},
	{
		id: 3,
		title: '¿Cómo funciona?',
		disabled: false,
		checked: false,
		columns: 4,
		orderMD: 2,
		orderSM: 3,
	},
];

const describeText = [
	'En este servicio puedes consultar la cantidad total de tu ahorro desde el inicio de tu relación laboral hasta el momento.',
	'También, puedes consultar las aportaciones que ha hecho tu patrón desde el año anterior y el presente.',
	'Recuerda que puedes usar tu ahorro para obtener un Crédito Infonavit o conservarlo para el momento de tu retiro.',
];

const MySavingPage = () => {
	const { containerPosition, tabActive, tabsEle, changeTabCustom } = useTabs(
		tabsFlag,
		1,
		0
	);
	const { fontSizeLevel } = useSelector((state: RootState) => state.session);
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<Layout updateTagText={updateTagText}>
			<Box ref={divRef}>
				<HeaderList
					title="Mi Ahorro"
					date="12 de Julio de 2021"
					list={describeText}
				/>
				<Tabs
					tabs={tabsEle}
					containerPosition={containerPosition}
					active={tabActive}
					cardsType={true}
					containerColumns={12}
					onChangeTab={(indexTab: number) => changeTabCustom(indexTab)}
				>
					<>
						{tabActive === 0 && (
							<Box sx={{ width: '100%' }}>
								<MySaving />
							</Box>
						)}
						{tabActive === 1 && (
							<Box sx={{ width: '100%' }}>
								<Contributions />
							</Box>
						)}
						{tabActive === 2 && (
							<Box sx={{ width: '100%' }}>
								<HowItWorks />
							</Box>
						)}
					</>
				</Tabs>
			</Box>
		</Layout>
	);
};

export default MySavingPage;
