import { makeStyles, createStyles } from '@mui/styles';
import { Theme, styled } from '@mui/material/styles';
import { TableCell } from '@mui/material';

export const Styles = makeStyles((theme: Theme) =>
	createStyles({
		cell: {
			color: '#000',
			fontWeight: 'bolder !important',
			backgroundColor: 'transparent !important',
		},
		tableCell: {
			borderBottom: 'none',
		},
	})
);

export const TableCellCustom = styled(TableCell)(({ theme }) => ({
	verticalAlign: 'baseline',
}));
