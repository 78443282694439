import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	iconsImg: {
		width: 15,
		marginRight: 5,
	},
	txtPrincipales: {
		color: '#333333',
		fontSize: '14px',
		verticalAlign: 'top',
		letterSpacing: 0,
		opacity: 1,
		margin: 0,
		display: 'inline',
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px !important',
		},
	},
	divPrincipal: {
		padding: '20px 80px',
		[theme.breakpoints.down('sm')]: {
			padding: '30px',
		},
	},
	divChecks: {
		paddingTop: '5px',
	},
	maxContent: {
		width: '100%',
		maxWidth: 'max-content'
	}
}));
