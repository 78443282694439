/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react'
import { Grid } from '@mui/material';
import { useStyles } from './styles';

import { useDispatch } from 'react-redux';
import { actions } from '../../store/modules/session';

import IconReset from '../../assets/img/iconsToolAccessibility/iconReset-1.svg';
import IconTool from '../../assets/img/iconsToolAccessibility/iconTool.svg';
import IconToolSmall from '../../assets/img/iconsToolAccessibility/iconToolSmall.svg';
import IconClose from '../../assets/img/iconsToolAccessibility/iconClose.svg';
import IconArrow from '../../assets/img/iconsToolAccessibility/arrow.svg';

import Lector from './components/Lector';
import CursorZoom from './components/CursorZoom';
import ReadingMask from './components/ReadingMask';
import Dislexia from './components/Dislexia';
import TextSizeAdjuster from './components/TextSizeAdjuster';
import GrayScale from './components/GrayScale';
import Contrast from './components/Contrast';

import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const ItemsTools = {
	'fontSize': true,
	'reset': true,
	'lector': true,
	'cursor': true,
	'mask': true,
	'contrast': true,
	'grayScale': true,
	'dislexia': true
}

const countTrueValues = Object.values(ItemsTools).filter(value => value === true).length;

interface Props {
	zoomCursor: boolean;
	setZoomCursor: (data: boolean) => void;
	isDislexic: boolean;
	setIsDislexic: (data: boolean) => void;
	isGrayScale: boolean;
	setIsGrayScale: (data: boolean) => void;
	isContrast: boolean;
	setIsContrast: (data: boolean) => void;
	updateText: boolean;
}

const AccessibilityTool = ({
	zoomCursor,
	setZoomCursor,
	isDislexic,
	setIsDislexic,
	isGrayScale,
	setIsGrayScale,
	isContrast,
	setIsContrast,
	updateText,
}: Props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { fontSizeLevel } = useSelector((state: RootState) => state.session);
	const [activeTool, setActiveTool] = useState(false);
	const [reset, setReset] = useState(false);
	const toolRef = useRef<HTMLDivElement | null>(null);
	const [toolDimensions, setToolDimensions] = useState({ width: 860, height: 0 });

	const resetTool = () => {
		dispatch(actions.changeFontSizeLevel(0));
		setZoomCursor(false);
		dispatch(actions.changeZoomCursor(false));
		setIsDislexic(false);
		dispatch(actions.changeDislexic(false));
		dispatch(actions.changeActiveLector(false));
		dispatch(actions.changeGrayScale(false));
		setIsGrayScale(false);
		dispatch(actions.changeContrast(false));
		setIsContrast(false);
		dispatch(actions.changeMask(false));
		if (fontSizeLevel !== 0) {
			setReset(true);
		}
	};

	const updateDimensions = () => {
		if (toolRef.current) {
			const { offsetWidth, offsetHeight } = toolRef.current;
			setToolDimensions({ width: offsetWidth, height: offsetHeight });
		}
	};

	useEffect(() => {
		updateDimensions();
		window.addEventListener('resize', updateDimensions);
		return () => {
			window.removeEventListener('resize', updateDimensions);
		};
	}, []);

	return (
		<div className={`${classes.tool} ${activeTool ? classes.activeTool : ''} ${countTrueValues <= 2 ? classes.toolSmall : ''}`}
			style={{ left: activeTool ? 0 : 0 - toolDimensions.width }}
			ref={toolRef}>
			<div className={`${classes.iconShow} ${!activeTool ? classes.hide : ''}`} onClick={() => setActiveTool(false)}>
				<div className={classes.iconShowRed}>
					<img src={IconTool} alt="Cerrar herramienta de accesibilidad" />
				</div>
			</div>
			<div className={`${classes.iconShowSmall} ${activeTool ? classes.hide : ''}`} onClick={() => setActiveTool(true)}>
				<img className={classes.iconArrow} src={IconArrow} alt="" />
				<div className={classes.iconShowRedSmall}>
					<img src={IconToolSmall} alt="Abrir herramienta de accesibilidad" />
				</div>
			</div>
			<div className={classes.container}>
				<div className={classes.header}>
					<p>Menú de accesibilidad</p>
					<img className={classes.close} src={IconClose} alt="Cerrar herramienta de accesibilidad" onClick={() => setActiveTool(false)} />
				</div>
				<Grid className={classes.body} container spacing={{ xs: 2, sm: 2, md: 3, xl: 4 }}>
					{ItemsTools.fontSize && (
						<Grid item xs={6} sm={6} md={8}>
							<TextSizeAdjuster reset={reset} updateText={updateText} setReset={setReset} />
						</Grid>
					)}
					{ItemsTools.reset && (
						<Grid item xs={6} sm={6} md={4}>
							<div className={`${classes.containerItem} ${classes.containerItemSmall}`} onClick={resetTool}>
								<div className={`${classes.iconItem} ${classes.iconItemSmall}`}>
									<img src={IconReset} alt="Restablecer herramienta de accesibilidad" />
								</div>
								<label className={classes.labelItem}>Restablecer</label>
							</div>
						</Grid>
					)}
					{ItemsTools.lector && (
						<Grid item xs={6} sm={6} md={4} lg={4}>
							<Lector />
						</Grid>
					)}
					{ItemsTools.cursor && (
						<Grid item xs={6} sm={6} md={4} lg={4}>
							<CursorZoom zoomCursor={zoomCursor} setZoomCursor={setZoomCursor} />
						</Grid>
					)}
					{ItemsTools.mask && (
						<Grid item xs={6} sm={6} md={4} lg={4}>
							<ReadingMask />
						</Grid>
					)}
					{ItemsTools.contrast && (
						<Grid item xs={6} sm={6} md={4} lg={4}>
							<Contrast isContrast={isContrast} setIsContrast={setIsContrast} />
						</Grid>
					)}
					{ItemsTools.grayScale && (
						<Grid item xs={6} sm={6} md={4} lg={4}>
							<GrayScale isGrayScale={isGrayScale} setIsGrayScale={setIsGrayScale} />
						</Grid>
					)}
					{ItemsTools.dislexia && (
						<Grid item xs={6} sm={6} md={4} lg={4}>
							<Dislexia isDislexic={isDislexic} setIsDislexic={setIsDislexic} />
						</Grid>
					)}
				</Grid>
			</div>
		</div>
	)
}

export default AccessibilityTool;
