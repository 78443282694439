/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useState, useEffect, useRef } from 'react';
import Layout from '../../../../../components/Layout';
import { IContextTaller } from './interfaces';
import { IRequestTaller, IWorkshopItem, WORKSHOP_VIEWS } from '../utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

const ContextTaller = createContext<IContextTaller>({} as IContextTaller);

const TallerContext: React.FC = ({ children }) => {
	const [elementos, setElementos] = useState<IRequestTaller>(
		{} as IRequestTaller
	);
	const [activeView, setActiveView] = useState<IWorkshopItem>(
		WORKSHOP_VIEWS.FINANTIAL_EDUCATION
	);
	const [finishLearn, setFinishLearn] = useState(false);
	const { fontSizeLevel } = useSelector((state: RootState) => state.session);
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<Layout updateTagText={updateTagText}>
			<ContextTaller.Provider
				value={{
					elementos,
					setElementos,
					activeView,
					setActiveView,
					finishLearn,
					setFinishLearn
				}}
			>
				<div ref={divRef}>
					{children}
				</div>
			</ContextTaller.Provider>
		</Layout>
	);
};

const useContextTaller = () => {
	const context = useContext(ContextTaller);
	if (context === undefined) {
		throw new Error('Error Context');
	}
	return context;
};

export { TallerContext, useContextTaller };
