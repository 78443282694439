import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('100', 'login');

export const ENDPOINTS_LOGIN: IBitacora = {
    '/login': _bitacora.Read(
        '100100',
        'Ingreso a MCI por login'
    ),
    '/login-confirmacion': _bitacora.Read(
        '100101',
        'Validación de NSS'
    ),
    '/logout': _bitacora.Read(
        '100102',
        'Cierre del sistema'
    ),
    '/token-refresh-v2': _bitacora.Read(
        '100103',
        'Refrescar Sesión'
    ),
};


export const REFERENCES_ENDPOINTS_LOGIN = {
    LOGIN: _bitacora.Read('100100', 'Ingreso a MCI por login'),
    LOGIN_CONFIRMATION: _bitacora.Read('100200', 'Validación de NSS'),
};