import CustomButton from '../../../components/Button';
import checkBlue from '../../../assets/img/mini-check-azul.png';
import { useStyles } from './styles';
interface Props {
	setPage: (data: number) => void;
}
const Info = ({ setPage }: Props) => {
	const classes = useStyles();

	return (
		<>
			<div className={classes.container}>
				<div className={classes.containerMin}>
					<div className={classes.checkHeader}>
						<img
							src={checkBlue}
							className={classes.checkHeaderImg}
							alt="icono de viñeta"
						/>
						<div className={classes.checkHeaderText}>
							<label style={{ marginTop: 2.3 }}>
								Si tienes problemas para obtener tu Aviso de
								Suspensión y Retención de Descuentos, esta
								información es importante para ti. Si tu Aviso
								no se genera en el portal, se puede deber a
								alguna de las siguientes situaciones:
							</label>
							<div style={{ marginLeft: 15, fontSize: 14 }}>
								<p>
									· Te encuentras en proceso de aclaración de
									homonimia, por lo tanto tu Aviso te llegará
									mediante un correo electrónico. No podrás
									consultarlo en el portal hasta que concluyas
									tu trámite ante el IMSS y tu Afore, y el
									Infonavit haya separado los datos de las
									personas involucradas.
								</p>
								<p>
									· Ya firmaste tu escritura ante el notario
									pero la descarga de tu Aviso está
									presentando dificultades. Si estás en este
									caso llama al Infonatel y repórtalo.
								</p>
								<p>
									{' '}
									· Tu crédito es cofinanciado o es Apoyo
									Infonavit. Recuerda que tu patrón no está
									obligado a retener de tu salario el pago de
									la mensualidad de estos créditos y por esta
									razón no se emite el Aviso.
								</p>
								<p>
									{' '}
									· Liquidaste tu crédito con un patrón
									diferente al que tenías cuando lo
									solicitaste o mientras no tenías relación
									laboral y en tales casos el Aviso de
									Suspensión no se genera.
								</p>
								<p>
									{' '}
									· Te descuentan tu crédito Infonavit pero no
									apareces en la cédula bimestral que emite el
									IMSS y por eso no se expidió tu Aviso de
									Retención; en este caso repórtalo al
									Infonatel.
								</p>
							</div>
						</div>
					</div>
					<div className={classes.checkHeader}>
						<img
							src={checkBlue}
							className={classes.checkHeaderImg}
							alt="icono de viñeta"
						/>
						<div className={classes.checkHeaderText}>
							<label style={{ marginTop: 2.3 }}>
								Si tu Aviso se genera de manera incorrecta en el
								portal, se puede deber a alguna de las
								siguientes situaciones:
							</label>
							<div style={{ marginLeft: 15, fontSize: 14 }}>
								<p>
									· Aún no concluyes tu trámite de unificación
									de NSS ante el IMSS y la Afore, y tu Aviso
									sale con el NSS que tenías cuando obtuviste
									tu crédito. Es importante que agilices tu
									trámite en la Afore.
								</p>
								<p>
									{' '}
									· Tienes dos Avisos de Retención porque
									trabajas en dos empresas obligadas a aportar
									al Infonavit. Comunícate al Infonatel y
									solicita que el Aviso se dirija sólo a uno
									de tus patrones.
								</p>
								<p>
									{' '}
									· Ya liquidaste tu crédito pero la descarga
									de tu Aviso de Suspensión presenta
									dificultades o sale con los datos erróneos
									del patrón con el que laboras actualmente.
									En este caso, llama al Infonatel y
									repórtalo.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div>
					<CustomButton
						onClick={() => {
							setPage(1);
						}}
						variant="solid"
						styles={{ marginTop: 50, width: 200, margin: '0 auto' }}
						disabled={false}
						label="Salir"
					/>
				</div>
			</div>
		</>
	);
};

export default Info;
