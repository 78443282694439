// DEPENDENCIES
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';
// COMPONENTS
import TwoColumnTable from '../../../../components/TwoColumnTable';
import ModalLoading from '../../../../components/ModalLoading';
import { RoundBtn } from '../../../AssociateNssCredit/Styles';
import CustomAlert from '../../../../components/CustomAlert';
import { INTERNAL_MESSAGES } from '../../../../config/messageCatalog';
// RESOURCES
import { sharedResponsabilityAPI } from '../../../../api/modules/sharedResponsability';
import { summaryRows } from '../../utils';
import { keyAPI } from '../../../../api/modules/key';
import { encrypt } from '../../../../utils/encrypt';
import iconoPDF from '../../../../assets/img/icono-descarga.png';
import theme from '../../../../config/theme';
import { useStyles } from '../../styles';

const Summary = ({ step, setStep, selectedCredit }: any) => {
	const classes = useStyles();
	const navigation = useNavigate();
	const [alert, setAlert] = useState<any>(null);
	const [loading, setLoading] = useState(false);

	const downloadPdf = async (title: string) => {
		if (selectedCredit.caso) {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const CASO_BASE64 = await encrypt(selectedCredit.caso || '', key);
			try {
				const { result } =
					await sharedResponsabilityAPI.responsabilityCase(
						CASO_BASE64
					);
				if (result?.documents) {
					let position = result?.documents?.findIndex((doc: any) =>
						doc?.nameDocument?.includes(title)
					);
					if (position !== -1) {
						const file = result?.documents[position];
						const dataBase64 = file?.dataDocument;
						const linkSource = `data:application/pdf;base64,${dataBase64}`;
						const downloadLink = document.createElement('a');
						const fileName = file?.nameDocument;

						downloadLink.href = linkSource;
						downloadLink.download = fileName;
						downloadLink.click();
					}
				} else {
					setAlert({
						message: result?.mesageResponse || INTERNAL_MESSAGES.ERROR_MESSAGE,
						color: 'warning',
					});
				}
			} catch (error) {
				setAlert({
					message:
						INTERNAL_MESSAGES.ERROR_MESSAGE,
					color: 'warning',
				});
			} finally {
				setLoading(false);
			}
		} else
			setAlert({
				message:
					INTERNAL_MESSAGES.ERROR_MESSAGE,
				color: 'warning',
			});
	};

	return (
		<>
			<div style={{ textAlign: 'center' }}>
				<h2 style={{ color: theme.palette.info.main }} className={`${classes.marginCenter} ${classes.maxContent}`}>
					Gracias por utilizar los servicios electrónicos del
					Infonavit
				</h2>
				<TwoColumnTable data={selectedCredit} principal={summaryRows} />
				<ModalLoading loading={loading} />
				<CustomAlert
					show={Boolean(alert?.message)}
					severity={alert?.color}
					message={alert?.message}
					onClose={(): any => setAlert(null)}
				/>
				{loading && (
					<h5 style={{ color: theme.palette.info.main }} className={`${classes.marginCenter} ${classes.maxContent}`}>
						Por favor espera un minuto mientras se generan tus
						documentos de Responsabilidad Compartida
					</h5>
				)}
				<Link
					onClick={() => downloadPdf('Bienvenida')}
					component="button"
				>
					Descargar tu Carta de Responsabilidad Compartida en formato
					PDF{' '}
					<img src={iconoPDF} alt="iconoPDF" style={{ width: 24 }} />
				</Link>
				<br />
				<Link
					onClick={() => downloadPdf('Condiciones')}
					component="button"
				>
					Descargar las Condiciones Financieras en formato PDF{' '}
					<img src={iconoPDF} alt="iconoPDF" style={{ width: 24 }} />
				</Link>
			</div>
			<br />
			<div className="actions-buttons">
				<RoundBtn variant="contained" onClick={() => navigation('/')}>
					Salir
				</RoundBtn>
			</div>
		</>
	);
};

export default Summary;
