import { Box } from '@mui/material';
import { useState } from 'react';
import FormEdition from './FormEdition';
import ChooseBranch from './ChooseBranch';
import { REQUEST_PAGES_STATUS } from './utils/pagesStatus';
import { IFormGeneral } from './interface';

interface IProps {
	nextProcess: () => void;
}

const RequestPage = ({ nextProcess }: IProps) => {
	const [page, setPage] = useState(REQUEST_PAGES_STATUS.EDITION);
	const [formCreate, setFormCreate] = useState<IFormGeneral>({});

	return (
		<Box padding={2}>
			{page === REQUEST_PAGES_STATUS.EDITION && (
				<FormEdition
					setPage={setPage}
					formCreate={formCreate}
					setFormCreate={setFormCreate}
				/>
			)}
			{page === REQUEST_PAGES_STATUS.SELECTION_BRANCH && (
				<ChooseBranch
					nextProcess={nextProcess}
					formCreate={formCreate}
					setFormCreate={setFormCreate}
				/>
			)}
		</Box>
	);
};

export default RequestPage;
