/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { parseISO, differenceInSeconds } from 'date-fns';

interface Props {
	signOff: () => void;
	active: () => void;
	timeToExpire: number;
	isLogged: boolean;
	expiresIn: string;
}

const timeInactividad = 1000 * 600;
const timeToModal = 1000 * 60;

export default function useIdle({
	signOff,
	active,
	timeToExpire,
	isLogged,
	expiresIn,
}: Props) {
	const timeout: number = timeToExpire > 0 ? timeToExpire : timeInactividad;
	const promptBeforeIdle: number = timeToModal;
	const [openModalSession, setOpenModalSession] = useState(false);
	const [remaining, setRemaining] = useState<number>(0);

	useEffect(() => {
		if (isLogged) {
			const expirationDate = parseISO(expiresIn);
			const interval = setInterval(() => {
				const now = new Date();
				const diffInSeconds = differenceInSeconds(expirationDate, now);
				if (diffInSeconds <= 30) {
					active();
				}
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [expiresIn, isLogged, active]);

	useEffect(() => {
		if (isLogged) {
			const interval = setInterval(() => {
				active();
			}, timeout - promptBeforeIdle);
			return () => clearInterval(interval);
		}
	}, [timeout, promptBeforeIdle, isLogged, active]);

	const onPrompt = () => {
		if (isLogged) {
			setOpenModalSession(true);
			setRemaining(promptBeforeIdle);
			const interval = setInterval(() => {
				setRemaining((prev) => Math.max(prev - 1000, 0));
			}, 1000);
			return () => clearInterval(interval);
		}
	};

	const onIdle = () => {
		if (isLogged) {
			setOpenModalSession(false);
			setRemaining(0);
			signOff();
		}
	};
	const onActive = () => {
		if (isLogged) {
			setOpenModalSession(false);
			setRemaining(0);
		}
	};

	const { getRemainingTime, isPrompted, activate } = useIdleTimer({
		timeout,
		promptBeforeIdle,
		onPrompt,
		onIdle,
		onActive,
	});

	return {
		getRemainingTime,
		isPrompted,
		activate,
		remaining,
		setRemaining,
		openModalSession,
		setOpenModalSession,
		onActive,
	};
}
