/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState, useEffect, useRef } from 'react';
import {
	Grid,
	InputAdornment,
	Pagination,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { CheckCircleOutlined } from '@mui/icons-material';
import { HeaderList } from '../../components/HeaderList';
import Layout from '../../components/Layout';

// Components
import TextInput from '../../components/TextInput';
import CustomButton from '../../components/Button';

// Resources
import annualReturns from '../../config/annualReturns';

// Assets
import { useStyles } from './styles';
import iconMoney from '../../assets/img/icono-pesos.png';
import { formatMoney } from '../../utils/validators';
import CustomAlert from '../../components/CustomAlert';
import { Box } from '@mui/system';
import ModalLoading from '../../components/ModalLoading';
import { LIST_MESSAGES } from './utils/constants-values.utils';
import { useSavingCalculator } from './hooks/useSavingCalculator';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

// Service

const SavingCalculator = () => {
	const classes = useStyles();
	const { fontSizeLevel } = useSelector((state: RootState) => state.session);
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);
	const {
		viewTable,
		setViewTable,
		loading,
		disabledButton,
		page,
		rowsPerPage,
		retireSaving,
		alert,
		rows,
		handleChangePage,
		handleSetData,
		handleCalculate,
		setAlert,
		currentSalary,
		setCurrentSalary,
		currentSaving,
		inYears,
	} = useSavingCalculator();

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<Layout updateTagText={updateTagText}>
			<div ref={divRef}>
				<HeaderList
					title="Calculadora del ahorro"
					list={LIST_MESSAGES}
					date="12 julio 2021"
				/>
				<Paper elevation={0}>
					<div className={classes.containerCalculator}>
						<p className={classes.titleCalculator}>
							Calcula tu ahorro
						</p>
						<CustomAlert
							message={alert.message}
							severity={alert.severity}
							show={alert.show}
							onClose={() => {
								setAlert({
									show: false,
									message: '',
									severity: alert.severity,
								});
							}}
						/>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<TextInput
									id="currentSalary"
									name="currentSalary"
									label="Escribe tu sueldo actual mensual"
									placeholder="0.00"
									value={currentSalary}
									onChange={handleSetData}
									onBlur={() => {
										if (currentSalary) {
											if (currentSalary === '.') {
												setCurrentSalary('');
											} else {
												setCurrentSalary(
													formatMoney(
														Number(currentSalary)
													).substring(1)
												);
											}
										}
									}}
									onFocus={() => {
										if (currentSalary) {
											setCurrentSalary(
												currentSalary
													.toString()
													.replaceAll(',', '')
													.replaceAll(' ', '')
											);
										}
									}}
									startAdornment={
										<InputAdornment position="start">
											$
										</InputAdornment>
									}
								/>
							</Grid>
							<Grid
								className={classes.containerElementsCalculator}
								item
								xs={12}
								sm={6}
								md={4}
								lg={3}
							>
								<CustomButton
									onClick={handleCalculate}
									label="Calcular"
									variant="solid"
									styles={{ height: 40 }}
									disabled={disabledButton}
								/>
							</Grid>
						</Grid>
					</div>
					<Grid
						container
						spacing={0}
						className={classes.containerTotals}
					>
						<Grid item xs={12} md={4} lg={4}>
							<div className={classes.containerCurrentSaving}>
								<p className={classes.titleCurrentSaving}>
									Tu ahorro en la actualidad
								</p>
								<div
									className={
										classes.containerQtyCurrentSaving
									}
								>
									<span
										className={`tag-to-read ${classes.qtyCurrentSaving}`}
										aria-label={`${formatMoney(
											currentSaving || 0
										)} mxn`}
									>
										<strong>
											{formatMoney(currentSaving || 0)}
										</strong>
									</span>
									<span
										className={
											classes.qtyCurrencyCurrentSaving
										}
									>
										MXN
									</span>
								</div>
							</div>
						</Grid>
						<Grid item xs={12} md={4} lg={4}>
							<div className={classes.containerMoneyYears}>
								<img
									src={iconMoney}
									className={classes.imgMoney}
									alt="Imagen de monedas"
								/>
								<p className={classes.titleYears}>
									En {inYears} años
								</p>
							</div>
						</Grid>
						<Grid item xs={12} md={4} lg={4}>
							<div className={classes.containerCurrentSaving}>
								<p className={classes.titleRetirement}>
									Tu ahorro cuando te jubiles podría ser de
								</p>
								<label
									className={
										classes.containerQtyCurrentSaving
									}
								>
									<span
										className={`tag-to-read ${classes.qtyRetirement}`}
										aria-label={`${formatMoney(
											retireSaving || 0
										)} mxn`}
									>
										<strong>
											{formatMoney(retireSaving || 0)}
										</strong>
									</span>
									<span
										className={
											classes.qtyCurrencyRetirement
										}
									>
										MXN
									</span>
								</label>
							</div>
						</Grid>
					</Grid>
					<div className={classes.infoContainer}>
						{rows.length > 0 && (
							<Fragment>
								<TableContainer>
									<Table aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell
													className={
														classes.tableTitleHeader
													}
													component="p"
												>
													Tu edad
												</TableCell>
												<TableCell
													className={
														classes.tableTitleHeader
													}
													component="p"
												>
													Tu salario acumulado en el
													año (1)
												</TableCell>
												<TableCell
													className={
														classes.tableTitleHeader
													}
													component="p"
												>
													La aportación de tu patrón
													acumulada en el año (2)
												</TableCell>
												<TableCell
													className={
														classes.tableTitleHeader
													}
													component="p"
												>
													Tu ahorro con rendimientos
													(3)
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{(rows || [])
												.slice(
													(page - 1) * rowsPerPage,
													(page - 1) * rowsPerPage +
													rowsPerPage
												)
												.map((row, index) => (
													<TableRow
														key={index}
														sx={{
															'&:last-child td, &:last-child th':
															{
																border: 0,
															},
														}}
													>
														<TableCell
															className={
																classes.tableTitleHeader
															}
															component="p"
															scope="row"
														>
															{row.edad}
														</TableCell>
														<TableCell
															component="span"
															className="tag-to-read"
															aria-label={`${row.columnaSalarioAnual} mxn`}
														>
															{row.columnaSalarioAnual >
																0 ? (
																<strong>
																	{formatMoney(
																		row.columnaSalarioAnual
																	)}
																</strong>
															) : (
																formatMoney(
																	row.columnaSalarioAnual
																)
															)}
														</TableCell>
														<TableCell
															component="span"
															className="tag-to-read"
															aria-label={`${row.columnaAportacionPatronalAnual} mxn`}
														>
															{row.columnaAportacionPatronalAnual >
																0 ? (
																<strong>
																	{formatMoney(
																		row.columnaAportacionPatronalAnual
																	)}
																</strong>
															) : (
																formatMoney(
																	row.columnaAportacionPatronalAnual
																)
															)}
														</TableCell>
														<TableCell
															component="span"
															className="tag-to-read"
															aria-label={`${row.columnaAhorro} mxn`}
														>
															{row.columnaAhorro >
																0 ? (
																<strong>
																	{formatMoney(
																		row.columnaAhorro
																	)}
																</strong>
															) : (
																formatMoney(
																	row.columnaAhorro
																)
															)}
														</TableCell>
													</TableRow>
												))}
										</TableBody>
									</Table>
								</TableContainer>
								<Box
									display="flex"
									justifyContent="center"
									flex={1}
									padding={1}
									margin={2}
								>
									<Stack spacing={2}>
										<Pagination
											color="primary"
											count={Math.ceil(
												rows.length / rowsPerPage
											)}
											variant="text"
											shape="rounded"
											onChange={(e, page) =>
												handleChangePage(page)
											}
										/>
									</Stack>
								</Box>
							</Fragment>
						)}
					</div>
					<div className={classes.divider} />
					<div className={classes.infoContainer}>
						<p className={classes.maxContent}>
							<strong>Aspectos importantes</strong>
						</p>
						<p className={classes.listItem}>
							<CheckCircleOutlined
								color="info"
								style={{ marginRight: 5, width: 20 }}
							/>
							<label style={{ marginTop: 3 }}>
								Tu sueldo anual considerando un porcentaje de
								inflación
							</label>
							<br />
						</p>
						<p className={classes.listItem}>
							<CheckCircleOutlined
								color="info"
								style={{ marginRight: 5, width: 20 }}
							/>
							<label style={{ marginTop: 3 }}>
								Son las aportaciones de tu patrón a lo largo del
								año, que corresponden al 5% de tu salario el
								cual está topado al 25 VSMDF
							</label>
							<br />
						</p>
						<p className={classes.listItem}>
							<CheckCircleOutlined
								color="info"
								style={{ marginRight: 5, width: 20 }}
							/>
							<label style={{ marginTop: 3 }}>
								Es la suma de tu ahorro más los rendimientos
								estimados a futuro que otorga el Instituto
							</label>
							<br />
						</p>
						<p>
							Los rendimientos que genera tu ahorro se abonan al
							final de cada mes. El consejo de Administración de
							Infonavit establece la tasa de interés y es superior
							al incremento del salario mínimo que se paga en la
							CDMX.
						</p>
						<p>
							<label className={classes.colorNote}>Nota:</label>{' '}
							<span>
								Recuerda que esta es una proyección de carácter
								informativo, no oficial derivado del comportamiento
								salarial y todos los factores que intervienen en el
								mismo; por otro lado recuerda que si tienes un
								crédito vigente tus aportaciones son destinadas al
								pago del mismo.
							</span>
						</p>
						<p className={classes.savingLink}>
							<span>
								Tu ahorro genera rendimientos
							</span>
							<label
								onClick={() => setViewTable(!viewTable)}
								className={classes.link}
							>
								consulta aquí.
							</label>
						</p>
						{viewTable && (
							<Fragment>
								<p className={classes.containerTitle}>
									<strong>Tabla de rendimientos</strong>
								</p>
								<div className={classes.headerTableTitle}>
									<strong
										style={{ color: 'white', fontSize: 14 }}
									>
										<label>Rendimientos generados</label>
									</strong>
								</div>
								<TableContainer>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell
													variant="head"
													className={
														classes.tableTitleHeader
													}
													style={{ width: '50%' }}
												>
													<label>Año</label>
												</TableCell>
												<TableCell
													variant="head"
													className={
														classes.tableTitleHeader
													}
												>
													<label>
														Tasa de rendimiento
														nominal anual
													</label>
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{annualReturns.map((row, index) => (
												<TableRow
													key={index}
													sx={{
														'&:last-child td, &:last-child th':
														{
															border: 0,
														},
													}}
												>
													<TableCell scope="row">
														<label>{row.year}</label>
													</TableCell>
													<TableCell>
														<label>{row.tasaRen}</label>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Fragment>
						)}
					</div>
				</Paper>
				<ModalLoading loading={loading} />
			</div>
		</Layout>
	);
};

export default SavingCalculator;
