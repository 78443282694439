import { red } from '@mui/material/colors';
import { formatDate } from '../../utils/dates';
import { centeredItems } from './styles';
import AmortizationTable from './components/AmortizationTable';
import { formatMoney } from '../../utils/validators';

export const tabsOptions = [
	{
		id: 1,
		title: 'Trámite',
		disabled: false,
		checked: false,
		columns: 6,
		orderMD: 0,
		orderSM: 0,
	},
	{
		id: 2,
		title: 'Preguntas frecuentes',
		disabled: false,
		checked: false,
		columns: 6,
		orderMD: 1,
		orderSM: 2,
	},
];

export const actualConditionsRows = [
	{
		key: 'numeroCredito',
		title: 'Número de crédito',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	{
		key: 'fechaOtorgamiento',
		title: 'Fecha de otorgamiento',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
		renderValue: (value: any) =>
			`${value.slice(6)}/${value.slice(4, 6)}/${value.slice(0, 4)}`,
	},
	{
		key: 'saldoOriginacion',
		title: 'Saldo de originación en pesos',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
		renderValue: (value: any) => `${formatMoney(value)}`,
	},
	{
		key: 'saldoProyecto',
		title: 'Saldo actual en pesos',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
		renderValue: (value: any) => `${formatMoney(value)}`,
	},
	{
		key: 'regimen',
		title: 'Régimen actual',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	{
		key: 'estatusContable',
		title: 'Estatus contable actual',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	{
		key: 'numeroPagosOmisos',
		title: 'Número de pagos omisos',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
];

export const actualConditionsCompareRows = [
	{
		key: 'pagasHoy',
		title: (
			<>
				Pago mensual actual (en pesos)
				<sup style={{ color: red[800] }}>*</sup>
			</>
		),
		titleTooltip: 'Esto es lo que pagas hoy en pesos',
		styleTitle: {
			fontWeight: 'bold',
			...centeredItems,
			justifyContent: 'start',
		},
		alignDescription: 'right',
		renderValue: (value: any) => `${formatMoney(value)}`,
	},
	{
		key: 'pagoAcordado',
		title: 'Pago en la Originación (en pesos)',
		titleTooltip:
			'Esto es lo que se acordó que pagarias cuando obtuviste tu crédito',
		styleTitle: {
			fontWeight: 'bold',
			...centeredItems,
			justifyContent: 'start',
		},
		alignDescription: 'right',
		renderValue: (value: any) => `${formatMoney(value)}`,
	},
	{
		key: 'tasaInteres',
		title: 'Tasa de interés anual actual',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
		renderValue: (value: any) => `${Number(value).toFixed(2)}%`,
	},
	{
		key: 'plazoPermanente',
		title: 'Pagos efectivos pendientes',
		titleTooltip:
			'Plazo máximo de pagos efectivos pendientes (los pagos que te hacen falta realizar)',
		styleTitle: {
			fontWeight: 'bold',
			...centeredItems,
			justifyContent: 'start',
		},
		alignDescription: 'right',
	},
];

export const responsabilityCompare = [
	{
		key: 'factor',
		title: (
			<>
				Pago fijo en pesos <sup style={{ color: red[800] }}>*</sup>
			</>
		),
		titleTooltip:
			'Esto es lo que pagarás de manera fija en pesos con el programa Responsabilidad Compartida',
		styleTitle: {
			fontWeight: 'bold',
			...centeredItems,
			justifyContent: 'start',
		},
		alignDescription: 'right',
		renderValue: (value: any) => `${formatMoney(value)}`,
	},
	{
		key: 'tasaInteres',
		title: 'Tasa de interés anual',
		titleTooltip:
			'Tu tasa de interés con base en el salario registrado por tu patrón. En caso que te encuentres desempleado, se calcula con base en los pagos que has realizado o en tu salario de originación',
		styleTitle: {
			fontWeight: 'bold',
			...centeredItems,
			justifyContent: 'start',
		},
		alignDescription: 'right',
		renderValue: (value: any) => `${value}%`,
	},
	{
		key: 'plazo',
		title: (
			<>
				Pagos efectivos pendientes
				<sup style={{ color: red[800] }}>1</sup>
			</>
		),
		titleTooltip:
			'Plazo máximo de pagos efectivos pendientes (los pagos que te hacen falta realizar) con el programa Responsabilidad Compartida',
		styleTitle: {
			fontWeight: 'bold',
			...centeredItems,
			justifyContent: 'start',
		},
		alignDescription: 'right',
	},
	{
		key: 'codigoGrupo',
		title: 'Tabla de amortización',
		styleTitle: { fontWeight: 'bold' },
		styleDescription: {
			...centeredItems,
			justifyContent: 'end',
			color: red[500],
		},
		renderValue: (value: any, data: any) => (
			<AmortizationTable codigoGrupo={value} tasa={data?.tasaInteres} />
		),
	},
];

export const responsabilityCompareA = [
	{
		key: 'factor',
		title: (
			<>
				Pago fijo en pesos <sup style={{ color: red[800] }}>*</sup>
			</>
		),
		titleTooltip:
			'Esto es lo que pagarás de manera fija en pesos con el programa Responsabilidad Compartida',
		styleTitle: {
			fontWeight: 'bold',
			...centeredItems,
			justifyContent: 'start',
		},
		alignDescription: 'right',
		renderValue: (value: any) => `${formatMoney(value)}`,
	},
	{
		key: 'tasaInteres',
		title: 'Tasa de interés anual',
		titleTooltip:
			'Tu tasa de interés con base en el salario registrado por tu patrón. En caso que te encuentres desempleado, se calcula con base en los pagos que has realizado o en tu salario de originación',
		styleTitle: {
			fontWeight: 'bold',
			...centeredItems,
			justifyContent: 'start',
		},
		alignDescription: 'right',
		renderValue: (value: any) => `${value}%`,
	},
	{
		key: 'plazo',
		title: (
			<>
				Pagos efectivos pendientes
				<sup style={{ color: red[800] }}>1</sup>
			</>
		),
		titleTooltip:
			'Plazo máximo de pagos efectivos pendientes (los pagos que te hacen falta realizar) con el programa Responsabilidad Compartida',
		styleTitle: {
			fontWeight: 'bold',
			...centeredItems,
			justifyContent: 'start',
		},
		alignDescription: 'right',
	},
	{
		key: 'montoBeneficio',
		title: 'Monto de beneficio (pesos)',
		titleTooltip:
			'Plazo máximo de pagos efectivos pendientes (los pagos que te hacen falta realizar) con el programa Responsabilidad Compartida',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
		renderValue: (value: any) => `${formatMoney(value)}`,
	},
	{
		key: 'codigoGrupo',
		title: 'Tabla de amortización',
		styleTitle: { fontWeight: 'bold' },
		styleDescription: {
			...centeredItems,
			justifyContent: 'end',
			color: red[500],
		},
		renderValue: (value: any, data: any) => (
			<AmortizationTable codigoGrupo={value} tasa={data?.tasaInteres} />
		),
	},
];

export const responsabilityCompareB = [
	{
		key: 'pagoMensual',
		title: (
			<>
				Pago fijo en pesos <sup style={{ color: red[800] }}>*</sup>
			</>
		),
		titleTooltip:
			'Esto es lo que pagarás de manera fija en pesos con el programa Responsabilidad Compartida',
		styleTitle: {
			fontWeight: 'bold',
			...centeredItems,
			justifyContent: 'start',
		},
		alignDescription: 'right',
		renderValue: (value: any) => `${formatMoney(value)}`,
	},
	{
		key: 'tasaInteres',
		title: 'Tasa de interés anual',
		titleTooltip:
			'Tu tasa de interés con base en el salario registrado por tu patrón. En caso que te encuentres desempleado, se calcula con base en los pagos que has realizado o en tu salario de originación',
		styleTitle: {
			fontWeight: 'bold',
			...centeredItems,
			justifyContent: 'start',
		},
		alignDescription: 'right',
		renderValue: (value: any) => `${value}%`,
	},
	{
		key: 'plazo',
		title: (
			<>
				Pagos efectivos pendientes
				<sup style={{ color: red[800] }}>1</sup>
			</>
		),
		titleTooltip:
			'Plazo máximo de pagos efectivos pendientes (los pagos que te hacen falta realizar) con el programa Responsabilidad Compartida',
		styleTitle: {
			fontWeight: 'bold',
			...centeredItems,
			justifyContent: 'start',
		},
		alignDescription: 'right',
	},
	{
		key: 'montoDescuento',
		title: 'Monto de beneficio (pesos)',
		titleTooltip:
			'Plazo máximo de pagos efectivos pendientes (los pagos que te hacen falta realizar) con el programa Responsabilidad Compartida',
		styleTitle: {
			fontWeight: 'bold',
			...centeredItems,
			justifyContent: 'start',
		},
		alignDescription: 'right',
		renderValue: (value: any) => `${formatMoney(value)}`,
	},
	{
		key: 'codigoGrupo',
		title: 'Tabla de amortización',
		styleTitle: { fontWeight: 'bold' },
		styleDescription: {
			...centeredItems,
			justifyContent: 'end',
			color: red[500],
		},
		renderValue: (value: any, data: any) => (
			<AmortizationTable codigoGrupo={value} tasa={data?.tasaInteres} />
		),
	},
];

export const summaryRows = [
	{
		key: 'CREDITO',
		title: 'Número de crédito',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	{
		key: 'caso',
		title: 'Caso',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	{
		key: 'FCH_OTORG',
		title: 'Fecha de otorgamiento',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
		renderValue: (value: any) =>
			`${value.slice(6)}/${value.slice(4, 6)}/${value.slice(0, 4)}`,
	},
	{
		key: 'estatus',
		title: 'Estatus',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	{
		key: 'fechaEstatus',
		title: 'Fecha de estatus',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	{
		key: 'fechaSolicitud',
		title: 'Fecha de solicitud',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	{
		key: 'saldoOriginacion',
		title: 'Saldo de originación en pesos',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
		renderValue: (value: any) => `${formatMoney(value)}`,
	},
	{
		key: 'saldoProyecto',
		title: 'Saldo actual en pesos',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
		renderValue: (value: any) => `${formatMoney(value)}`,
	},
	{
		key: 'regimen',
		title: 'Régimen actual',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
];

export const selectedCreditRows = (group: string) => [
	{
		key: group === '2B' ? 'pagoMensual' : 'factor',
		title: 'Pagarás mensualmente',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
		renderValue: (value: any) => `${formatMoney(value)}`,
	},
	{
		key: 'tasaInteres',
		title: 'Tasa de interés será',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
		renderValue: (value: any) => `${value}%`,
	},
	{
		key: 'plazo',
		title: 'Meses restantes para pagar de manera completa',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	group !== '1' && {
		key: group === '2A' ? 'montoBeneficio' : 'montoDescuento',
		title: 'El monto de descuento en tu saldo es de',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
		renderValue: (value: any) => `${formatMoney(value)}`,
	},
];

export const mockSelectedCredit = {
	payment: '$XXXX.00',
	interes: 'XX%',
	months: 'XX',
	discount: '$XX.XX',
};

export const mockActualConditions = {
	credit: '436216',
	date: formatDate('P'),
	balance: '$512,232.32',
	balance_pesos: '$133,433.42',
	regimen: 'Con relación laboral/Sin relación laboral',
	estatus: 'Vigente',
	payments: '10',
};

export const accordionQuestions = [
	{
		id: 1,
		title: '¿Todos los tipos de crédito pueden tener este beneficio?',
		description:
			'Responsabilidad Compartida ha ampliado el número de acreditadas y acreditados con créditos en VSM que podrán ser beneficiados sin restricción por edad, saldo insoluto ni monto de la originación.',
	},
	{
		id: 2,
		title: '¿Aplica para acreditados con o sin relación laboral?',
		description:
			'Aplica para ambos. Aquellos que actualmente tengan una prórroga no podrán usar este beneficio.',
	},
	{
		id: 3,
		title: '¿Cuántos años debe tener mi crédito para poder aplicar a este programa?',
		description:
			'No existe una restricción por edad para aplicar el beneficio de Responsabilidad Compartida.',
	},
	{
		id: 4,
		title: '¿Todos los créditos obtienen el mismo descuento?',
		description:
			'El monto de descuento es único y particular para cada crédito, ya que depende de las condiciones financieras que presente.',
	},
	{
		id: 5,
		title: '¿Se tiene que firmar algún documento para aceptar este beneficio?',
		description:
			'Para tu comodidad se han elaborado procesos seguros para que realices tu trámite  por nuestros canales digitales y obtener tu aceptación  electrónica.',
	},
	{
		id: 6,
		title: '¿Deberé entregar un nuevo aviso de retención una vez que se aplique la conversión de VSM a pesos?',
		description: 'Sí, el cual deberás entregar a tu patrón.',
	},
	{
		id: 7,
		title: '¿Se cobrará una cuota de administración?',
		description:
			'Para este beneficio no se cobrará la cuota de administración. ',
	},
	{
		id: 8,
		title: '¿En dónde se reflejará la aplicación del beneficio de Responsabilidad Compartida?',
		description:
			'Al mes siguiente de tu aceptación lo verás reflejado en Mi Cuenta Infonavit en el servicio de Saldos y Movimientos. Dos meses después en el estado de cuenta de tu crédito.',
	},
	{
		id: 9,
		title: '¿Se puede liquidar el saldo del nuevo crédito convertido a pesos?',
		description:
			'Sí, puedes liquidarlo únicamente hasta que veas aplicado el beneficio en tu crédito.',
	},
	{
		id: 10,
		title: 'Después de aceptar, ¿puedo cambiar mis condiciones financieras a VSM?',
		description:
			'Para este programa no hay reversos, por lo que te pedimos revises bien las condiciones que te ofrecemos y si estás seguro de tu decisión, acepta el beneficio.',
	},
];
