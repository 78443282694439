// RESOURCES
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
// COMPONENTS
import CustomButton from '../../../components/Button';
// ASSETS
import mail from '../../../assets/img/mail.png';
import mobil from '../../../assets/img/cel.png';
import { useStyles } from './styles';

// Resources
import { isNumberValidate } from '../../../utils/expressions';
import ModalLoading from '../../../components/ModalLoading';
import { unpairDeviceAPI } from '../../../api/modules/unpairDevice';
import { keyAPI } from '../../../api/modules/key';
import { encrypt } from '../../../utils/encrypt';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { IAlert } from '../../../interfaces/alert';
import CustomAlert from '../../../components/CustomAlert';
import { useBitacora } from '../../../hooks/useBitacora';

interface Props {
	setPage: (data: number) => void;
	device: number; //Prop para elegir el medio de desvinculacion
	setDevice: (data: number) => void; //Prop funcion para elegir el medio de desvinculación
	dataRequest: any;
}

const textInformation1 = (
	<>
		<strong>Se envió un mensaje de texto al número de celular</strong> que
		nos proporcionaste.
		<br />
		Ingresa el código que te hemos enviado.
		<br />
	</>
);

const textInformation2 = (
	<>
		<strong>Se envió un correo electrónico al email</strong> que nos
		proporcionaste.
		<br />
		<p>
			Para continuar con el proceso de desvinculación, escribe el código
			que se te envió por correo electrónico, en <br /> caso de no recibir
			el mensaje, comunícate al Infonatel desde la Ciudad del México al 55
			9171 5050 o desde cualquier <br /> parte del país al 800 008 3900.
		</p>
		De no encontrar en la Bandeja de entrada el correo, revisa tu carpeta de
		no <br />
		deseados.
	</>
);

// const textInformation3 = (
// 	<>
// 		<strong>
// 			Escribe el código de seguridad generado en tu token Infonavit
// 		</strong>{' '}
// 	</>
// );

const SendCode = ({ setPage, device, dataRequest }: Props) => {
	const classes = useStyles();
	const { user } = useSelector((state: RootState) => state.session);
	const { regBitacoraNotOld } = useBitacora();
	const [code1, setCode1] = React.useState({ valid: false, value: '' });
	const [code2, setCode2] = React.useState({ valid: false, value: '' });
	const [code3, setCode3] = React.useState({ valid: false, value: '' });
	const [code4, setCode4] = React.useState({ valid: false, value: '' });
	const [code5, setCode5] = React.useState({ valid: false, value: '' });
	const [code6, setCode6] = React.useState({ valid: false, value: '' });
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'error',
	});

	const [electionDevice, setElectionDevice] = useState({
		img: '',
		alt: '',
		text: <></>,
		time: '',
		box: '',
	});

	const setFocusElementByName = (name: string) => {
		let element = document.getElementsByName(name)[0];
		element.focus();
	};

	const validateNumber = (value: any) => {
		const esValido = isNumberValidate(value.target.value);

		if (esValido) {
			let valString = value.target.value;

			if (valString.length > 1) {
				valString = valString.charAt(valString.length - 1);
			}

			if (value.target.name === 'code1') {
				setCode1({ valid: true, value: valString });
				setFocusElementByName('code2');
			}
			if (value.target.name === 'code2') {
				setCode2({ valid: true, value: valString });
				setFocusElementByName('code3');
			}
			if (value.target.name === 'code3') {
				setCode3({ valid: true, value: valString });
				setFocusElementByName('code4');
			}
			if (value.target.name === 'code4') {
				setCode4({ valid: true, value: valString });
				setFocusElementByName('code5');
			}
			if (value.target.name === 'code5') {
				setCode5({ valid: true, value: valString });
				setFocusElementByName('code6');
			}
			if (value.target.name === 'code6') {
				setCode6({ valid: true, value: valString });
			}
		} else {
			if (value.target.name === 'code1') {
				setCode1({ valid: false, value: '' });
			}
			if (value.target.name === 'code2') {
				setCode2({ valid: false, value: '' });
			}
			if (value.target.name === 'code3') {
				setCode3({ valid: false, value: '' });
			}
			if (value.target.name === 'code4') {
				setCode4({ valid: false, value: '' });
			}
			if (value.target.name === 'code5') {
				setCode5({ valid: false, value: '' });
			}
			if (value.target.name === 'code6') {
				setCode6({ valid: false, value: '' });
			}
		}
	};

	useEffect(() => {
		if (device === 1) {
			setElectionDevice({
				img: mobil,
				alt: 'imagen de móvil',
				text: textInformation1,
				time: 'Válido por 4 horas',
				box: '1',
			});
			return;
		}
		if (device === 2) {
			setElectionDevice({
				img: mail,
				alt: 'imagen de email',
				text: textInformation2,
				time: 'Válido por 4 horas',
				box: '1',
			});
			return;
		}
		// if (device === 3) {
		// 	setElectionDevice({
		// 		img: mobil,
		// 		alt: 'mobil',
		// 		text: textInformation3,
		// 		time: 'Válido por 2:00 min',
		// 		box: '2',
		// 	});
		// }
	}, [device]);

	const validCodeDevice = async () => {
		try {
			setLoading(true);
			setAlert({
				...alert,
				show: false,
			});

			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_encript = await encrypt(user?.nss.toString() || '', key);
			const code_encript = await encrypt(
				code1.value +
				code2.value +
				code3.value +
				code4.value +
				code5.value +
				code6.value,
				key
			);
			const codeValues = [
				code1.value,
				code2.value,
				code3.value,
				code4.value,
				code5.value,
				code6.value,
			];
			let dataBitacora = codeValues.join('');

			const { result } = await unpairDeviceAPI.validCode(
				nss_encript,
				code_encript
			);

			if (Number(result.code) === 0) {
				const { result: vincular } =
					await unpairDeviceAPI.unbindDevice();

				if (Number(vincular.code) === 0) {
					setPage(4);
					regBitacoraNotOld(unpairDeviceAPI.schemeUrl, { code: dataBitacora });
					return;
				}

				setAlert({
					show: true,
					message:
						'Ha ocurrido un error al obtener los datos, intente nuevamente.',
					severity: 'error',
				});
				return;
			}

			setAlert({
				show: true,
				message:
					result.description ||
					'Ha ocurrido un error al obtener los datos, intente nuevamente.',
				severity: 'error',
			});
		} catch (error) {
			setAlert({
				show: true,
				message:
					'Ha ocurrido un error al obtener los datos, intente nuevamente.',
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const SendCodeDevice = async (typeInformation: number) => {
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_encript = await encrypt(user?.nss.toString() || '', key);
			if (typeInformation === 1) {
				await unpairDeviceAPI.sendSMS(nss_encript);
			}
			if (typeInformation === 2) {
				await unpairDeviceAPI.sendEmail(nss_encript);
			}
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<ModalLoading loading={loading} />
			<div className={classes.container}>
				<div>
					<Box className={classes.VerificacionLogo}>
						<img
							alt={electionDevice.alt}
							src={electionDevice.img}
						/>
					</Box>
					<Box
						sx={{
							alignItems: 'center',
							justifyContent: 'center',
							display: 'flex',
							marginBottom: '35px',
						}}
					>
						<label className={classes.VerificacionText}>
							{electionDevice.text}
						</label>
					</Box>

					{electionDevice.box === '1' && (
						<Box
							className={classes.divCodigos}
							sx={{
								display: 'flex',
							}}
						>
							<input
								id="code1"
								name="code1"
								pattern="[0-9]"
								className={classes.VerificacionCodigo}
								value={code1.value}
								onChange={validateNumber}
							/>
							<input
								id="code2"
								name="code2"
								pattern="[0-9]"
								className={classes.VerificacionCodigo}
								value={code2.value}
								onChange={validateNumber}
							/>
							<input
								id="code3"
								name="code3"
								pattern="[0-9]"
								className={classes.VerificacionCodigo}
								value={code3.value}
								onChange={validateNumber}
							/>
							<input
								id="code4"
								name="code4"
								pattern="[0-9]"
								className={classes.VerificacionCodigo}
								value={code4.value}
								onChange={validateNumber}
							/>
							<input
								id="code5"
								name="code5"
								pattern="[0-9]"
								className={classes.VerificacionCodigo}
								value={code5.value}
								onChange={validateNumber}
							/>
							<input
								id="code6"
								name="code6"
								pattern="[0-9]"
								className={classes.VerificacionCodigo}
								value={code6.value}
								onChange={validateNumber}
							/>
						</Box>
					)}

					{electionDevice.box === '2' && (
						<Box
							className={classes.divCodigosDos}
							sx={{
								display: 'flex',
							}}
						>
							<input
								id="code1"
								name="code1"
								pattern="[0-9]"
								className={classes.VerificacionCodigo}
								value={code1.value}
								onChange={validateNumber}
							/>
							<input
								id="code2"
								name="code2"
								pattern="[0-9]"
								className={classes.VerificacionCodigo}
								value={code2.value}
								onChange={validateNumber}
							/>
							<input
								id="code3"
								name="code3"
								pattern="[0-9]"
								className={classes.VerificacionCodigo}
								value={code3.value}
								onChange={validateNumber}
							/>
							<input
								id="code4"
								name="code4"
								pattern="[0-9]"
								className={classes.VerificacionCodigo}
								value={code4.value}
								onChange={validateNumber}
							/>
							<input
								id="code5"
								name="code5"
								pattern="[0-9]"
								className={classes.VerificacionCodigo}
								value={code5.value}
								onChange={validateNumber}
							/>
							<input
								id="code6"
								name="code6"
								pattern="[0-9]"
								className={classes.VerificacionCodigo}
								value={code6.value}
								onChange={validateNumber}
							/>
						</Box>
					)}

					<Box
						sx={{
							alignItems: 'center',
							justifyContent: 'center',
							marginBottom: '2%',
							display: 'flex',
						}}
					>
						<label className={classes.textGray14}>
							{electionDevice.time}{' '}
						</label>
					</Box>
					{device === 3 ? null : (
						<Box
							sx={{
								alignItems: 'center',
								justifyContent: 'center',
								display: 'flex',
							}}
						>
							<label
								className={classes.link}
								onClick={() => SendCodeDevice(device)}
							>
								Volver a enviar
							</label>
						</Box>
					)}
				</div>

				<CustomAlert
					message={alert.message}
					severity={alert.severity}
					show={alert.show}
				/>

				<Box className={classes.containerButtons}>
					<div className={classes.custombuttom}>
						<CustomButton
							label="Salir"
							onClick={() => setPage(1)}
							styles={{ width: '260px', height: '100%' }}
							variant="outlined"
						/>
					</div>
					<div className={classes.custombuttom}>
						<CustomButton
							onClick={validCodeDevice}
							styles={{ width: '260px', height: '100%' }}
							variant="solid"
							label="Continuar"
							disabled={
								!code1.valid ||
									!code2.valid ||
									!code3.valid ||
									!code4.valid ||
									!code5.valid ||
									!code6.valid
									? true
									: false
							}
						/>
					</div>
				</Box>
			</div>
		</>
	);
};

export default SendCode;
