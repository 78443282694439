import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { keyAPI } from '../../../../api/modules/key';
import { apiProrogation } from '../../../../api/modules/Prorogation';
import InfonavitAnios from '../../../../assets/img/logo-infonavit-rojo.png';
import CustomButton from '../../../../components/Button';
import CustomAlert from '../../../../components/CustomAlert';
import ModalLoading from '../../../../components/ModalLoading';
import { IAlert } from '../../../../interfaces/alert';
import { encrypt } from '../../../../utils/encrypt';
import AcceptProrogationModal from '../../Components/AcceptProrogationModal';
import RejectProrogationModal from '../../Components/RejectProrogationModal';
import { IProrroga } from '../../utils';

interface IProps {
	setStep: (arg: number) => void;
	prorroga: IProrroga;
	refreshData: () => void;
}

const Terms = ({ setStep, prorroga, refreshData }: IProps) => {
	const navigate = useNavigate();
	const [agreed, setAgreed] = useState('');
	const [loading, setLoagin] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});

	const direccion = [
		prorroga.register.mensajes?.datos.calle || '',
		prorroga.register.mensajes?.datos.colonia || '',
		prorroga.register.mensajes?.datos.cp || '',
		prorroga.register.mensajes?.datos.estado || '',
		prorroga.register.mensajes?.datos.poblacion || '',
	]
		.filter((a) => a !== '')
		.join(',')
		.trim();

	const handleStep = async () => {
		try {
			setLoagin(true);
			setAlert({
				message: '',
				severity: 'warning',
				show: false,
			});

			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const credit_encript = await encrypt(
				prorroga.register.mensajes?.credito || '',
				key
			);

			const direccion_encript = await encrypt(direccion || '', key);

			const { result } = await apiProrogation.createCaso(
				credit_encript,
				direccion_encript
			);

			if (result.code === '0000') {
				const fecha_encript = await encrypt(
					new Date().toLocaleDateString(),
					key
				);
				const nss_encript = await encrypt(
					prorroga.register.mensajes?.datos.nss || '',
					key
				);
				const credito_encript = await encrypt(
					prorroga.register.mensajes?.credito || '',
					key
				);

				const request = await apiProrogation.generatePDF(
					fecha_encript,
					direccion_encript,
					nss_encript,
					credito_encript
				);

				const idtramite_encript = await encrypt(result.folio, key);
				const base64_encript = await encrypt(request.data || '', key);

				await apiProrogation.uploadDoc(idtramite_encript, base64_encript);

				refreshData();
				setStep(3);
				return;
			}

			throw new Error('Error');
		} catch (ex) {
			setAlert({
				message:
					'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'warning',
				show: true,
			});
		} finally {
			setAgreed('');
			setLoagin(false);
		}
	};

	return (
		<>
			<ModalLoading loading={loading} />
			<div style={{ display: 'flex', width: '100%' }}>
				<h4 style={{ flexGrow: 1, textAlign: 'center' }}>
					Términos y condiciones de la aplicación de una Prórroga
					Temporal por la Pérdida de la Relación Laboral
				</h4>
				<img src={InfonavitAnios} alt="Infonavit 50 años" width={80} />
			</div>
			<div className="terms-content">
				<h5>Prórroga por pérdida de la relación laboral</h5>
				<ul className="terms-user-info">
					<li>
						<strong>Fecha de la solicitud de la prórroga: </strong>
						<span>{new Date().toLocaleDateString()}</span>
					</li>
					<li>
						<strong>Domicilio de garantía: </strong>
						<span>{direccion}</span>
					</li>
					<li>
						<strong>NSS del trabajador: </strong>
						<span>{prorroga.register.mensajes?.datos.nss}</span>
					</li>
					<li>
						<strong>Número de crédito: </strong>
						<span>{prorroga.register.mensajes?.credito}</span>
					</li>
				</ul>
				<p style={{ margin: 0 }}>
					Con base al artículo 41 de la ley del Infonavit, el trabajador
					tendrá el derecho de elegir la vivienda nueva o usada, a la que
					se aplique el importe del crédito que reciba con cargo al Fondo
					Nacional de la Vivienda, misma que podrá o no ser parte de
					conjuntos habitacionales financiados con recursos de dicho
					fondo.
				</p>
				<br />
				<p style={{ margin: 0 }}>
					Cuando un trabajador hubiere recibido crédito del Instituto,
					éste le otorgará a partir de la fecha en que haya dejado de
					percibir ingresos salariales (Omiso más antiguo), prórrogas en
					los pagos de la amortización que tenga que hacer por concepto de
					capital e intereses ordinarios. Para tal efecto, el trabajador
					acreditado deberá presentar su solicitud al Instituto dentro del
					mes siguiente a la fecha en que deje de percibir ingresos
					salariales.
				</p>
				<br />
				<p style={{ margin: 0 }}>
					<b>
						Durante dicha prórroga los pagos de principal y los
						intereses ordinarios que se generen se capitalizarán al
						saldo insoluto del crédito.
					</b>
				</p>
				<br />
				<p style={{ margin: 0 }}>
					En caso de que el trabajador no solicite la prórroga en el plazo
					de 30 días, ésta no se le autorizará.
				</p>
				<br />
				<p style={{ margin: 0 }}>
					Las prórrogas que se otorguen al trabajador de conformidad con
					el párrafo anterior no podrán ser mayores de doce meses cada
					una, ni exceder en su conjunto más de veinticuatro meses, y esta
					prórroga terminará por los siguientes supuestos:
				</p>
				<ul>
					<li>Cuando expira el periodo otorgado de la prórroga.</li>
					<li>
						Cuando el acreditado(a) inicia una nueva relación
						laboral, mediate el cambio de régimen al Régimen
						Ordinario de Amortizaciones (ROA).
					</li>
					<li>
						Cuando el acreditado (a) firma un convenio de
						restructura de crédito.
					</li>
					<li>
						Cuando el Acreditado (a) realiza un pago Régimen
						Extraordinario de Amortización (REA) por el total de la
						factura (Importe del factor REA + Importe de las
						comisiones).
					</li>
				</ul>
				<p style={{ margin: 0 }}>
					Una vez que la aplicación de esta prórroga concluya el
					acreditado queda obligado a realizar los pagos en las mismas
					condiciones financieras que se encontraban vigentes en el
					momento previo a la aplicación de este programa de apoyo por
					perdida de la relación laboral.
				</p>
				<br />
				<p style={{ margin: 0 }}>
					Esta información es exclusivamente de carácter informativo, no
					crea ni derechos ni obligaciones y no es válida para ningún
					trámite.
				</p>
			</div>
			<h5 style={{ margin: '15px 0' }}>
				¿Estás de acuerdo con las condiciones de la prórroga?
			</h5>

			<CustomAlert
				message={alert.message}
				severity={alert.severity}
				show={alert.show}
			/>

			<div className="actions-container">
				<CustomButton
					label="No"
					variant="outlined"
					onClick={() => setAgreed('no')}
				/>
				<CustomButton label="Si" onClick={() => setAgreed('si')} />
			</div>
			<AcceptProrogationModal
				open={agreed === 'si'}
				setOpen={setAgreed}
				handleAccept={handleStep}
			/>
			<RejectProrogationModal
				open={agreed === 'no'}
				setOpen={setAgreed}
				handleReject={() => navigate('/')}
			/>
		</>
	);
};

export default Terms;
