/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import { useGeneralStyles } from '../../views/styles/general.styles';
import { useController } from '../../hooks/useController';
import { MIUC_PROCESS_VIEWS } from '../../constants/process';
import ProofOfBalance from './views/ProofOfBalance/ProofOfBalance';
import { Box, Grid, Paper, useMediaQuery } from '@mui/material';
import Layout from '../../../../components/Layout';
import FollowSteps from '../../components/FollowSteps';
import theme from '../../../../config/theme';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

const CICB = () => {
	const { fontSizeLevel } = useSelector((state: RootState) => state.session);
	const generalStyle = useGeneralStyles();
	const matches = useMediaQuery(theme.breakpoints.down('md'));

	const { getStepView, dataProcessController } = useController();
	const [view, setView] = useState(getStepView);

	useEffect(() => {
		navigation();
	}, [dataProcessController]);

	const navigation = () => {
		setView(getStepView());
	};

	const layoutSize =
		view === MIUC_PROCESS_VIEWS.SUMMARY_REQUEST && !matches
			? '70%'
			: '100%';

	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<Layout updateTagText={updateTagText}>
			<Box sx={{ width: layoutSize }} className={generalStyle.container} ref={divRef}>
				{view === MIUC_PROCESS_VIEWS.CICB ? (
					<Paper>
						<div className={generalStyle.content}>
							<ProofOfBalance />
						</div>
					</Paper>
				) : view === MIUC_PROCESS_VIEWS.SUMMARY_REQUEST ? (
					<Grid>
						<Grid item xs={12} md={8}>
							<FollowSteps
								guideLink={require('../../../../assets/files/guia-pasos-a-seguir/Guia_pasos_a_seguir_CICB.pdf')}
								creditLink={require('../../../../assets/files/guia-pasos-a-seguir/Guia_producto_CICB.pdf')}
							/>
						</Grid>
					</Grid>
				) : (
					<></>
				)}
			</Box>
		</Layout>
	);
};

export default CICB;
