/* eslint-disable jsx-a11y/anchor-is-valid */
// Dependencies
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

// Material Components
import { Box, Paper, Container, Grid, Divider } from '@mui/material';

// Components
import CustomButton from '../../../components/Button';
import TextInputPassword from '../../../components/TextInputPassword';
import ModalLoading from '../../../components/ModalLoading';
import CustomAlert from '../../../components/CustomAlert';
import { INTERNAL_MESSAGES } from '../../../config/messageCatalog';

// Assets
import { useStyles } from './style';
import logoInfonavitRojo from '../../../assets/img/logo-infonavit-rojo.png';
import logoMciColor2 from '../../../assets/img/logo-mci-color-2.png';

// Interfaces
import { IInputPasswordResult } from '../../../interfaces/inputPassword';
import { IAlert } from '../../../interfaces/alert';

// Api-Modules
import { encrypt } from '../../../utils/encrypt';
import { keyAPI } from '../../../api/modules/key';
import { resetPasswordAPI } from '../../../api/modules/resetPassword';
import { useBitacora } from '../../../hooks/useBitacora';

const ConfirmRecoveryPassword = () => {
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const navigate = useNavigate();
	const [SuccessConfirmacion, setSuccessConfirmacion] = useState(false);
	const [Button, setButton] = useState({ pass: '', enable: true });
	const [loading, setLoading] = useState(false);
	const [Params, setParams] = useState({
		decos: '',
		infSns: '',
		lime: '',
		password: '',
	});
	const { regBitacoraNotOld, getMaskString } = useBitacora();

	useEffect(() => {
		getParamsUrl();
	}, []);

	const getParamsUrl = () => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());

		if (params) {
			let values = {
				decos: params.decos,
				infSns: params.infSns,
				lime: params.lime,
				password: '',
				edt: params.edt,
			};

			setParams(values);
		}
	};

	const onChange = (value: IInputPasswordResult) => {
		setButton({ pass: value.password, enable: !value.valid });
	};
	const onSubmit = async () => {
		setLoading(true);

		getParamsUrl();
		const keyRes = await keyAPI.get<'', { k: string }>();
		const key = keyRes['k'].toString();

		let parametros = Params;
		parametros.password = await encrypt(Button.pass || '', key);
		setParams(parametros);
		resetPasswordAPI
			.confirmRecoverPassword({ ...parametros })
			.then((res: any) => {
				if (
					res.result &&
					typeof res.result === 'object' &&
					res.result.hasOwnProperty('nss')
				) {
					regBitacoraNotOld(
						resetPasswordAPI.schemeUrl,
						{
							...Params,
							password: getMaskString(Button.pass),
						},
						{
							signalKey: key,
							identificador: res.result?.nss,
						}
					);
					setSuccessConfirmacion(true);
				} else {
					setAlert({
						show: true,
						message:
							res?.result?.valid ||
							INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				}
			})
			.catch((error: any) => {
				setAlert({
					show: true,
					message: error.err?.description
						? error.err?.description
						: error.description
						? error.description
						: INTERNAL_MESSAGES.DATA_ERROR,
					severity: 'error',
				});
			})
			.finally(() => setLoading(false));
	};

	const styles = useStyles();
	return (
		<>
			<ModalLoading loading={loading} />
			<Box
				sx={{
					height: '100vh',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
				}}
			>
				<Container>
					<Paper className={styles.Paper}>
						<Box sx={{ JustifyContent: 'center', paddingTop: 4 }}>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<Box className={styles.logosSpacing}>
									<img
										alt="logo infonavit"
										src={logoInfonavitRojo}
										className={styles.imagenCentro1}
									/>
									<Divider
										orientation="vertical"
										sx={{ width: '2%' }}
										flexItem
									/>
									<img
										alt="logo mci"
										src={logoMciColor2}
										className={styles.imagenCentro2}
									/>
								</Box>
							</Box>
							<Box
								sx={{
									display: SuccessConfirmacion
										? 'block'
										: 'none',
									marginTop: 5,
									marginBottom: 3,

									justifyContent: 'center',
								}}
							>
								<Box className={styles.boxTextFelicidades}>
									<span
										className={
											styles.VerificacionCodigoTitle
										}
									>
										<strong style={{ marginTop: 10 }}>
											¡Felicidades!
										</strong>
									</span>
									<span className={styles.VerificacionText}>
										Tu contraseña ha sido reestablecida
										exitosamente, ahora puedes seguir
										disfrutando de los servicios que Mi
										Cuenta Infonavit tiene para ti.
									</span>
								</Box>

								<Grid
									container
									spacing={0}
									justifyContent="center"
									className={styles.spacingGridsRows}
								>
									<Box className={styles.BoxButton}>
										<CustomButton
											onClick={() => {
												navigate('/login');
											}}
											variant="solid"
											styles={{}}
											label="Entra a Mi Cuenta Infonavit"
										/>
									</Box>
								</Grid>
							</Box>
							<Box
								sx={{
									display: !SuccessConfirmacion
										? 'block'
										: 'none',
								}}
							>
								<Box className={styles.boxPassword}>
									<TextInputPassword
										labelStyles={{}}
										value={Params.password}
										onChange={onChange}
										isRequired
										confirmPassword
									/>
								</Box>

								<Grid
									container
									spacing={0}
									justifyContent="center"
									className={styles.spacingGridsRows}
								>
									<Box className={styles.BoxButton}>
										<CustomAlert
											message={alert.message}
											severity={alert.severity}
											show={alert.show}
											onClose={() => {
												setAlert({
													show: false,
													message: '',
													severity: alert.severity,
												});
											}}
										/>
									</Box>
									<Box className={styles.BoxButton}>
										<CustomButton
											onClick={onSubmit}
											variant="solid"
											styles={{}}
											label="Continuar"
											disabled={Button.enable}
										/>
									</Box>
								</Grid>
							</Box>

							<Grid
								container
								spacing={0}
								justifyContent="center"
								className={styles.spacingGridsRows}
							>
								<Grid
									item
									sx={{
										textAlign: 'center',
										paddingLeft: 2,
										paddingRight: 2,
										width: '100%',
									}}
								>
									<label className={styles.Text_Black}>
										<strong>
											Si tú no solicitaste el cambio de
											contraseña, llama a infonatel para
											hacer la aclaración.
										</strong>
									</label>
									<br />
									<br />
									<label className={styles.Text_Blue}>
										Marca al 55 9171 5050 en la Ciudad de
										México, o al 800 008 3900 desde
										cualquier parte del país, de lunes a
										viernes de 7:30 de la mañana a 9 de la
										noche; sábado, domingo y días festivos
										de 9 de la mañana a 3 de la tarde.
									</label>
									<br />
									<br />
									<label className={styles.Text_Blue_Black}>
										<strong>
											En el infonavit todos los trámites
											son gratuitos
										</strong>
									</label>
								</Grid>
							</Grid>
							<div className={styles.Paperfooter}>
								<label className={styles.Text_White}>
									<strong>Aviso de privacidad: </strong>Para
									darte un mejor servicio reunimos tus datos
									sencibles y personales y contamos con
									medidas de seguridad que los protegen.
									<br />
									Si tienes dudas consulta nuestro{' '}
									<a href="#">
										Aviso de privacidad y la Politíca de
										privacidad
									</a>{' '}
									en{' '}
									<a href="http://www.infonavit.org.mx/">
										www.infonavit.org.mx
									</a>
								</label>
							</div>
						</Box>
					</Paper>
				</Container>
			</Box>
		</>
	);
};

export default ConfirmRecoveryPassword;
