import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	title: {
		width: '100%',
		maxWidth: 'max-content',
		margin: '0 auto',
		[theme.breakpoints.up('xs')]: {
			fontSize: 25,
			// fontWeight: 'bold',
			paddingBottom: 20,
			paddingTop: 55,
			color: '#293990',
			textAlign: 'center',
		},
		[theme.breakpoints.up('sm')]: {
			fontSize: 22,
			// fontWeight: 'bold',
			paddingBottom: 20,
			paddingTop: 5,
		},
	},
	contentFileDataButtons: {
		display: 'flex',
		justifyContent: 'center',
		marginBlock: 10,
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			width: '100%',
		},
		[theme.breakpoints.down('md')]: {},

		'&>button': {
			[theme.breakpoints.down('sm')]: {
				margin: '20px 0px !important',
				width: '100% !important',
			},
		},
	},
	textContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		marginBottom: 7,
	},
	text: {
		color: '#333',
		fontSize: 18,
		padding: 0,
		margin: 0,
	},
	container: {
		borderRadius: 6,
		backgroundColor: 'white',
		padding: 20,
		paddingBlock: 50,
		marginTop: 30,
		boxShadow: '0px 5px 30px #00000017',

	},
	containerMin: {
		maxWidth: 900,
		margin: '0 auto',
	},
}));
