/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import {
	Box,
	Grid,
	Paper,
	useMediaQuery,
	Select,
	Tooltip,
	MenuItem,
	TextField,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { useTheme } from '@mui/material/styles';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';

// Components
import { HeaderList } from '../../components/HeaderList';
import CustomButton from '../../components/Button';
import TextInput from '../../components/TextInput';
import CustomAlert from '../../components/CustomAlert';
import ModalLoading from '../../components/ModalLoading';

//Assets
import { isNumberFloat, isNumber } from '../../utils/expressions';
import { parseISO, subYears } from 'date-fns';
import { formatDate } from '../../utils/dates';
import { isAfter } from 'date-fns';

//Resources
import { IAlert } from '../../interfaces/alert';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { keyAPI } from '../../api/modules/key';
import { encrypt } from '../../utils/encrypt';
import { PaymentRequestAPI } from '../../api/modules/PaymentClarification';
import {
	ResponseCaso,
	ResponseSici,
	ResponseAls,
	ResponseEmisoras,
	DataPagos,
} from '../../interfaces/PaymentClarification';
import { useBitacora } from '../../hooks/useBitacora';
import BaseModal from '../../components/Modal';
import { useStyles } from './styles';

interface Props {
	setPage: (data: number) => void;
	updateCaso: () => void;
	status: number;
	setPanelErrorStatus: (data: number) => void;
	setDataPayment: (data: any) => void;
}

const Form = ({
	setPage,
	updateCaso,
	status,
	setPanelErrorStatus,
	setDataPayment,
}: Props) => {
	const classes = useStyles();
	const { regBitacoraNotOld } = useBitacora();
	const { user } = useSelector((state: RootState) => state.session);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [form, setForm] = useState({
		amount: '',
		banco: '',
		payment: '',
		folio: '',
	});
	const [bimestre, setBimestre] = useState(0);
	const [errorMonto, setErrorMonto] = useState(false);
	const [paymentError, setPaymentError] = useState('');
	const [emisoras, setEmisoras] = useState<any>([]);
	const [initialDate, setInitialDate] = useState<Date | any>('');
	const [modalPagos, setModalPagos] = useState(false);

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		let validate = false;
		if (name === 'amount') {
			if (isNumberFloat(value)) {
				setForm({
					...form,
					[name]: value,
				});
			}
			validate = isTwoDecimals(value);
			setErrorMonto(validate);
		} else if (name === 'payment') {
			if (isNumber(value)) {
				if (value.length <= 10) {
					setForm({
						...form,
						[name]: value,
					});
				}
				if (value.length < 10) {
					setPaymentError(
						'La referencia de pago debe de ser de 10 dígitos.'
					);
				} else {
					setPaymentError('');
				}
			}
		} else if (name === 'folio') {
			if (isNumber(value)) {
				if (value.length <= 30) {
					setForm({
						...form,
						[name]: value,
					});
				}
			}
		} else {
			setForm({ ...form, [name]: value });
		}
		setAlert({
			show: false,
			message: '',
			severity: 'success',
		});
	};

	const isTwoDecimals = (value: string) => {
		const reg = /^[0-9]*(\.[0-9]{0,1})?$/;
		if (value === '') return true;
		if (!value.match(reg)) {
			return false;
		}
		return true;
	};

	const handleConsultaSici = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();

			const paramsPayment = {
				...form,
				fecha: formatDate(
					'yyyyMMdd',
					new Date(initialDate as Date)
				).toString(),
			};
			const paramsData = {
				credito: form.payment,
				monto: form.amount,
				fecha: paramsPayment.fecha,
				emisora: form.banco,
			};
			const dataSendEncrypt = await encrypt(
				JSON.stringify(paramsData),
				key
			);
			PaymentRequestAPI.consultaSici
				.post<
					{
						data: string;
					},
					ResponseSici
				>('', {
					data: dataSendEncrypt,
				})
				.then((res: ResponseSici) => {
					if (res?.code && res?.code === '0000') {
						handleConsultaAls(paramsData);
					} else if (res?.code && res?.code === '0001') {
						//consulta crear caso crm con clase operacion ZANP
						let message =
							'En nuestro sistema no tenemos registrado tu pago, revisa que la referencia con la que pagaste esté correcta y acude a la institución financiera para su aclaración.';
						creaCaso(
							'ZANP',
							message,
							'No se encontró el pago en SICI'
						);
					} else {
						setAlert({
							show: true,
							message: res.message
								? res.message
								: 'Por el momento el servicio no está disponible. Intenta más tarde.',
							severity: 'error',
						});
						setLoading(false);
					}
				})
				.catch((err: any) => {
					setAlert({
						show: true,
						message: err.description
							? err.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde.',
						severity: 'error',
					});
					setLoading(false);
				});
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description
					? error.description
					: 'Por el momento el servicio no está disponible. Intenta más tarde.',
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const handleConsultaAls = async (dataPayment: {
		credito: string;
		monto: any;
		fecha: string;
		emisora: string;
	}) => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const fecha = formatDate(
				'yyyy-MM-dd',
				new Date(initialDate as Date)
			).toString();
			const paramsData = {
				fechaPago: fecha,
				importePago: dataPayment.monto,
				numCredito: user?.credit,
				numCreditoIncorrecto:
					dataPayment.credito === user?.credit
						? ''
						: dataPayment.credito,
			};
			const dataSendEncrypt = await encrypt(
				JSON.stringify(paramsData),
				key
			);
			PaymentRequestAPI.consultaAls
				.post<
					{
						data: string;
					},
					ResponseAls
				>('', {
					data: dataSendEncrypt,
				})
				.then((res: ResponseAls) => {
					if (res.code === '0000') {
						if (status === 1) {
							//Tipo REA
							if (dataPayment.credito !== user?.credit) {
								if (res.data.estatusCreditoCorrecto === '3') {
									noAls(
										res.data.estatusCreditoCorrecto,
										res.data.estatusCreditoIncorrecto,
										res.data.tipoAclaracionList[0]
											.detallePago
									);
								} else {
									if (
										res.data.tipoAclaracionList[0]
											.detallePago[0].ubicacionPago !==
										'0'
									) {
										validaCaso(
											'ZPOC',
											res.data.estatusCreditoCorrecto,
											'',
											res.data.tipoAclaracionList[0]
												.detallePago
										);
									} else {
										noAls(
											res.data.estatusCreditoCorrecto,
											res.data.estatusCreditoIncorrecto,
											res.data.tipoAclaracionList[0]
												.detallePago
										);
									}
								}
							} else {
								if (
									res?.data?.estatusCreditoCorrecto === '1' ||
									res?.data?.estatusCreditoCorrecto === '2'
								) {
									//credito correcto
									if (
										res?.data?.tipoAclaracionList[0]
											?.detallePago.length > 1
									) {
										//setear tipificacion igual a ZAPA
										validaCaso(
											'ZAPA',
											res.data.estatusCreditoCorrecto,
											'',
											res.data.tipoAclaracionList[0]
												.detallePago
										);
									} else {
										if (
											res?.data?.tipoAclaracionList[0]
												?.tipoAclaracion === '1' ||
											res?.data?.tipoAclaracionList[0]
												?.tipoAclaracion === '4'
										) {
											//setear tipificacion igual a ZAPA
											validaCaso(
												'ZAPA',
												res.data.estatusCreditoCorrecto,
												'',
												res.data.tipoAclaracionList[0]
													.detallePago
											);
										} else if (
											res.data.tipoAclaracionList[0]
												.tipoAclaracion === '5'
										) {
											if (
												res.data
													.estatusCreditoCorrecto ===
												'1'
											) {
												//setear tipificacion igual a ZAPA
												validaCaso(
													'ZAPA',
													res.data
														.estatusCreditoCorrecto,
													'',
													res.data
														.tipoAclaracionList[0]
														.detallePago
												);
											} else {
												//setear tipificacion igual a ZPEX
												validaCaso(
													'ZPEX',
													res.data
														.estatusCreditoCorrecto,
													'',
													[]
												);
											}
										} else if (
											res?.data?.tipoAclaracionList[0]
												?.tipoAclaracion === '2' ||
											res?.data?.tipoAclaracionList[0]
												?.tipoAclaracion === '3'
										) {
											//setear tipificacion igual a ZANP
											let message = '';
											res.data.tipoAclaracionList[0]
												.tipoAclaracion === '2'
												? (message =
													'Tus pagos fueron aplicados y se verán reflejados en tu próximo estado de cuenta.')
												: (message =
													'Tu pago no ha sido aplicado, por favor acude a revisarlo con tu Institución Bancaria.');
											res.data.tipoAclaracionList[0]
												.tipoAclaracion === '2'
												? validaCaso(
													'ZANP',
													res.data
														.estatusCreditoCorrecto,
													message,
													[],
													'Liquidado y aplicado -  no procede'
												)
												: validaCaso(
													'ZANP',
													res.data
														.estatusCreditoCorrecto,
													message,
													[],
													'Reversado -  no procede'
												);
										} else if (
											res.data.tipoAclaracionList[0]
												.tipoAclaracion === '6'
										) {
											//Mostrar pantalla de mensualidad no completado
											setPanelErrorStatus(1);
											setPage(6);
											setLoading(false);
										} else if (
											res.data.tipoAclaracionList[0]
												.tipoAclaracion === '0'
										) {
											noAls(
												res.data.estatusCreditoCorrecto,
												res.data
													.estatusCreditoIncorrecto,
												res.data.tipoAclaracionList[0]
													.detallePago
											);
										} else {
											//setear tipificacion igual a ZPNL
											validaCaso(
												'ZPNL',
												res.data.estatusCreditoCorrecto,
												'',
												res.data.tipoAclaracionList[0]
													.detallePago
											);
										}
									}
								} else if (
									res?.data?.estatusCreditoCorrecto === '3'
								) {
									if (
										res?.data?.tipoAclaracionList[0]
											?.tipoAclaracion === '1'
									) {
										//setear tipificacion igual a ZAPA
										validaCaso(
											'ZAPA',
											res.data.estatusCreditoCorrecto,
											'',
											res.data.tipoAclaracionList[0]
												.detallePago
										);
									} else {
										noAls(
											res.data.estatusCreditoCorrecto,
											res.data.estatusCreditoIncorrecto,
											res.data.tipoAclaracionList[0]
												.detallePago
										);
									}
								} else {
									//credito incorrecto
									if (
										res?.data?.tipoAclaracionList[0]
											?.tipoAclaracion === '1'
									) {
										//setear tipificacion igual a ZAPA
										noAls(
											res.data.estatusCreditoCorrecto,
											res.data.estatusCreditoIncorrecto,
											res.data.tipoAclaracionList[0]
												.detallePago
										);
									} else {
										//setear tipificacion igual a ZPNL
										validaCaso(
											'ZPNL',
											res.data.estatusCreditoCorrecto,
											res.data.estatusCreditoIncorrecto,
											res.data.tipoAclaracionList[0]
												.detallePago
										);
									}
								}
							}
						} else if (status === 3) {
							//Tipo PASIVO
							if (
								res?.data?.tipoAclaracionList[0]?.detallePago
									.length > 0
							) {
								if (
									res?.data?.tipoAclaracionList[0]
										?.tipoAclaracion === '2'
								) {
									//setear tipificacion igual a ZANP
									let message =
										'Tus pagos fueron aplicados y se verán reflejados en tu próximo estado de cuenta.';
									creaCaso(
										'ZANP',
										message,
										'Aplicado - no procede'
									);
								} else {
									//setear tipificacion igual a ZAPA
									validaCaso(
										'ZAPA',
										res.data.estatusCreditoCorrecto,
										'',
										res.data.tipoAclaracionList[0]
											.detallePago
									);
								}
							} else {
								validaCaso(
									'Z2C5',
									res.data.estatusCreditoCorrecto,
									'',
									res.data.tipoAclaracionList[0].detallePago
								);
							}
						}
					} else {
						setAlert({
							show: true,
							message: res.message
								? res.message
								: 'Por el momento el servicio no está disponible. Intenta más tarde.',
							severity: 'error',
						});
						setLoading(false);
					}
				})
				.catch((err: any) => {
					setAlert({
						show: true,
						message: err.description
							? err.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde.',
						severity: 'error',
					});
					setLoading(false);
				});
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description
					? error.description
					: 'Por el momento el servicio no está disponible. Intenta más tarde.',
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const creaCaso = async (
		type: string,
		messageError: string,
		nota: string | undefined
	) => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			let fecha = formatDate(
				'yyyy-MM-dd',
				new Date(initialDate as Date)
			).toString();
			let dataType = {};
			if (type === 'ZPEX' || type === 'ZPNL') {
				dataType = {
					credito: user?.credit,
					monto: form.amount,
					anioPago: fecha.substring(0, 4),
					mesPago: fecha.substring(4, 2),
					diaPago: fecha.substring(6, 2),
					referenciaPago: form.payment,
					folioPago: form.folio,
					emisora: form.banco,
				};
			} else if (type === 'ZSUA') {
				dataType = {
					credito: user?.credit,
					nombreCompleto: user?.given_name,
					bimestre: bimestre,
					anioPago: fecha.substring(0, 4),
				};
			} else {
				//validar tipoAclaracion
				dataType = {
					credito: user?.credit,
					nota: nota ? nota : '',
				};
			}

			const paramsData = {
				...dataType,
				nss: user?.nss,
				correo: user?.email,
				claseOperacion: type,
				nombreCompleto: user?.given_name,
			};
			const dataSendEncrypt = await encrypt(
				JSON.stringify(paramsData),
				key
			);
			PaymentRequestAPI.crearCaso
				.post<
					{
						data: string;
					},
					ResponseCaso
				>('', {
					data: dataSendEncrypt,
				})
				.then((res: ResponseCaso) => {
					if (res?.code === '0000') {
						regBitacoraNotOld(
							PaymentRequestAPI.crearCaso.scheme,
							paramsData
						);
						if (status === 1 && type === 'ZANP') {
							setPanelErrorStatus(2);
							setPage(6);
							setLoading(false);
						} else {
							if (res?.data?.tipificacion !== 'ZANP') {
								updateCaso();
							} else {
								setAlert({
									show: true,
									message: messageError,
									severity: 'warning',
								});
								setLoading(false);
							}
						}
					} else {
						setAlert({
							show: true,
							message: res.message
								? res.message
								: 'Por el momento el servicio no está disponible. Intenta más tarde.',
							severity: 'error',
						});
						setLoading(false);
					}
				})
				.catch((err: any) => {
					setAlert({
						show: true,
						message: err.description
							? err.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde.',
						severity: 'error',
					});
					setLoading(false);
				});
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description
					? error.description
					: 'Por el momento el servicio no está disponible. Intenta más tarde.',
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const creaFondoAhorro = async (
		type: string,
		creditCorrect: string,
		detallePago: any
	) => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			let fecha = formatDate(
				'yyyy-MM-dd',
				new Date(initialDate as Date)
			).toString();
			const paramsData = {
				nss: user?.nss,
				correo: user?.email,
				claseOperacion: type,
				nombreCompleto: user?.given_name,
				credito: user?.credit,
				monto: form.amount,
				anioPago: fecha.slice(0, 4),
				mesPago: fecha.substring(5, 7),
				diaPago: fecha.substring(8, 10),
				referenciaPago: form.payment,
				folioPago: form.folio,
				emisora: form.banco,
				tipoPago: '',
				nota: '',
				estatusCreditoCorrecto: creditCorrect,
				estatusCreditoIncorrecto: creditCorrect !== '0' ? '0' : '1',
				detallePago: detallePago,
			};
			const dataSendEncrypt = await encrypt(
				JSON.stringify(paramsData),
				key
			);
			PaymentRequestAPI.crearFondoAhorro
				.post<
					{
						data: string;
					},
					ResponseCaso
				>('', {
					data: dataSendEncrypt,
				})
				.then((res: ResponseCaso) => {
					if (res?.code === '0000') {
						updateCaso();
					} else {
						setAlert({
							show: true,
							message: res.message
								? res.message
								: 'Por el momento el servicio no está disponible. Intenta más tarde.',
							severity: 'error',
						});
						setLoading(false);
					}
				})
				.catch((err: any) => {
					setAlert({
						show: true,
						message: err.description
							? err.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde.',
						severity: 'error',
					});
					setLoading(false);
				});
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description
					? error.description
					: 'Por el momento el servicio no está disponible. Intenta más tarde.',
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const validaCaso = async (
		type: string,
		creditCorrect: string,
		incorrect: string,
		pagos: any,
		nota?: string
	) => {
		const keyRes = await keyAPI.get<'', { k: string }>();
		const key = keyRes['k'].toString();
		setLoading(true);
		try {
			const paramsData = {
				//Se ajusta validacion por incidente IM114229
				valida: status === 1 ? '1' : '0',
				claseOperacion: type,
				nss: user?.nss,
			};
			const dataSendEncrypt = await encrypt(
				JSON.stringify(paramsData),
				key
			);
			PaymentRequestAPI.consultaCaso
				.post<
					{
						data: string;
					},
					ResponseCaso
				>('', {
					data: dataSendEncrypt,
				})
				.then((res: ResponseCaso) => {
					if (res?.data?.casoValido === '0') {
						if (
							type === 'ZSUA' ||
							type === 'ZPEX' ||
							type === 'ZANP'
						) {
							if (creditCorrect !== '10') {
								creaCaso(type, incorrect, nota);
							} else {
								setLoading(false);
							}
						} else if (
							type === 'ZAPA' ||
							type === 'ZPNL' ||
							type === 'Z2C5'
						) {
							if (creditCorrect !== '10') {
								creaFondoAhorro(type, creditCorrect, pagos);
							} else {
								setLoading(false);
							}
						} else if (type === 'ZPOC') {
							//crear fondo ahorro
							const fecha = formatDate(
								'yyyy-MM-dd',
								new Date(initialDate as Date)
							).toString();
							setDataPayment({
								date: fecha,
								type: type,
								amount: form.amount,
								payment: form.payment,
								folio: form.folio,
								banco: form.banco,
								creditCorrect: creditCorrect,
								detallePago: pagos,
							});
							setPage(5);
						}
					} else {
						if (status === 2 || status === 3) {
							setPanelErrorStatus(0);
							setPage(6);
							setLoading(false);
						} else {
							setAlert({
								show: true,
								message:
									'Por el momento el servicio no está disponible. Intenta más tarde.',
								severity: 'error',
							});
							setLoading(false);
						}
					}
				})
				.catch((err: any) => {
					setAlert({
						show: true,
						message: err.description
							? err.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde.',
						severity: 'error',
					});
					setLoading(false);
				});
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description
					? error.description
					: 'Por el momento el servicio no está disponible. Intenta más tarde.',
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const noAls = async (credit: string, incorrect: string, pagos: any) => {
		validaCaso('ZPNL', credit, incorrect, pagos);
	};

	const getEmisoras = async () => {
		setLoading(true);
		try {
			PaymentRequestAPI.catalogoEmisoras
				.post<any, any>('', {})
				.then((res: ResponseEmisoras) => {
					if (res?.data?.emisoras) {
						setEmisorasToSort(res.data.emisoras);
					}
				})
				.catch((err: any) => {
					setAlert({
						show: true,
						message: err.description
							? err.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde.',
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description
					? error.description
					: 'Por el momento el servicio no está disponible. Intenta más tarde.',
				severity: 'error',
			});
		}
	};

	const setEmisorasToSort = (data: any) => {
		const resultSort = data.sort(function (a: any, b: any) {
			if (parseInt(a.id) > parseInt(b.id)) {
				return 1;
			}
			if (parseInt(a.id) < parseInt(b.id)) {
				return -1;
			}
			return 0;
		});
		setEmisoras(resultSort);
	};

	const onKeyDown = (e: any) => {
		e.preventDefault();
	};

	useEffect(() => {
		getEmisoras();
		if (status === 3) {
			validaCaso('Z2C5', '10', '0', []);
		}
		if (status === 2) {
			validaCaso('ZSUA', '10', '0', []);
		}
	}, []);

	return (
		<Box>
			<HeaderList title="Aclaración de pagos" />
			<ModalLoading loading={loading} />
			<Paper
				sx={{
					pt: 5,
					pb: 5,
					pr: matches ? 5 : 15,
					pl: matches ? 5 : 15,
				}}
			>
				{status !== 2 && (
					<Grid container columns={12}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<div
								style={{
									display: 'grid',
									fontSize: matches ? 14 : 16,
									marginBottom: 10,
								}}
							>
								<p style={{ margin: 0 }}>
									<span>
										Monto
									</span>
									<label
										style={{
											position: 'relative',
										}}
									>
										<Tooltip
											arrow
											title={
												<label>
													Escribe el monto con
													decimales, Ej: 500.00
												</label>
											}
											placement="right"
										>
											<ErrorOutlinedIcon
												sx={{
													color: '#D1001F',
													position: 'absolute',
													top: '-2px',
													fontSize: '1.2rem',
													left: '12px',
													cursor: 'pointer',
													transform: 'rotate(180deg)',
												}}
											/>
										</Tooltip>
									</label>
								</p>
								<TextInput
									id="amount"
									name="amount"
									placeholder="0.00"
									onChange={handleChange}
									value={form.amount}
									helperText={
										errorMonto
											? 'El monto debe contener 2 decimales.'
											: ''
									}
								/>
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<div
								style={{
									display: 'grid',
									fontSize: matches ? 14 : 16,
									marginBottom: 10,
								}}
							>
								<p style={{ margin: 0 }}>Fecha de pago</p>
								<DesktopDatePicker
									inputFormat="dd/MM/yyyy"
									value={initialDate ? initialDate : ''}
									minDate={parseISO('2008-01-01')}
									maxDate={new Date()}
									onChange={(value) => {
										if (value) {
											setInitialDate(value);
											setAlert({
												show: false,
												message: '',
												severity: 'success',
											});
										}
									}}
									InputProps={{
										style: { height: 40, width: '100%' },
									}}
									renderInput={(params) => (
										<TextField
											fullWidth
											{...params}
											inputProps={{
												...params.inputProps,
												placeholder: 'dd/mm/aaaa',
											}}
										/>
									)}
								/>
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<div
								style={{
									display: 'grid',
									fontSize: matches ? 14 : 16,
									marginBottom: 10,
								}}
							>
								<p style={{ margin: 0 }}>
									<span>
										Referencia de pago
									</span>
									<label
										style={{
											position: 'relative',
										}}
									>
										<Tooltip
											arrow
											title={
												<label>
													La referencia de pago es el
													número de crédito.
												</label>
											}
											placement="right"
										>
											<ErrorOutlinedIcon
												sx={{
													color: '#D1001F',
													position: 'absolute',
													top: '-2px',
													fontSize: '1.2rem',
													left: '12px',
													cursor: 'pointer',
													transform: 'rotate(180deg)',
												}}
											/>
										</Tooltip>
									</label>
								</p>
								<TextInput
									id="payment"
									name="payment"
									placeholder="0"
									onChange={handleChange}
									value={form.payment}
									helperText={paymentError}
								/>
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<div
								style={{
									display: 'grid',
									fontSize: matches ? 14 : 16,
									marginBottom: 10,
								}}
							>
								<p style={{ margin: 0 }}>
									<span>
										Folio de autorización de pago
									</span>
									<label
										style={{
											position: 'relative',
										}}
									>
										<Tooltip
											arrow
											title={
												<label>
													El folio bancario es el
													número consecutivo que le da
													el banco a cada transacción
													que ingresa, viene en el
													comprobante que le da el
													banco, se localiza como:
													Consecutivo, Operación,
													Folio.
												</label>
											}
											placement="right"
										>
											<ErrorOutlinedIcon
												sx={{
													color: '#D1001F',
													position: 'absolute',
													top: '-2px',
													fontSize: '1.2rem',
													left: '12px',
													cursor: 'pointer',
													transform: 'rotate(180deg)',
												}}
											/>
										</Tooltip>
									</label>
								</p>
								<TextInput
									id="folio"
									name="folio"
									placeholder="0"
									onChange={handleChange}
									value={form.folio}
								/>
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<div
								style={{
									display: 'grid',
									fontSize: matches ? 14 : 16,
									marginBottom: 10,
								}}
							>
								<p style={{ margin: 0 }}>
									<span>
										Banco o tienda de conveniencia
									</span>
									<label
										style={{
											position: 'relative',
										}}
									>
										<Tooltip
											arrow
											title={
												<label>
													Establecimiento donde
													realizaste tu pago.
												</label>
											}
											placement="right"
										>
											<ErrorOutlinedIcon
												sx={{
													color: '#D1001F',
													position: 'absolute',
													top: '-2px',
													fontSize: '1.2rem',
													left: '12px',
													cursor: 'pointer',
													transform: 'rotate(180deg)',
												}}
											/>
										</Tooltip>
									</label>
								</p>
								<Select
									displayEmpty
									name="banco"
									value={form.banco}
									onChange={handleChange}
									renderValue={
										form.banco !== ''
											? undefined
											: () => (
												<label
													style={{
														fontSize: 15,
														color: '#7F7F7F',
													}}
												>
													Selecciona
												</label>
											)
									}
									style={{
										height: 40,
										width: '100%',
									}}
								>
									<MenuItem value="">Selecciona</MenuItem>
									{emisoras.map(
										(element: {
											id: string;
											descripcion: string;
										}) => (
											<MenuItem
												key={element.id}
												value={element.id}
											>
												{element.descripcion}
											</MenuItem>
										)
									)}
								</Select>
							</div>
						</Grid>
						<CustomAlert
							message={alert.message}
							severity={alert.severity}
							show={alert.show}
						/>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<div
								style={{
									width: matches ? '100%' : '600px',
									margin: '30px auto 20px',
									display: matches ? 'block' : 'flex',
									justifyContent: 'space-between',
								}}
							>
								<CustomButton
									label="Regresar"
									onClick={() => setPage(1)}
									variant="outlined"
									styles={{
										width: 260,
										height: 40,
										margin: '10px auto',
									}}
								/>
								<CustomButton
									label="Continuar"
									onClick={() => {
										status === 1
											? handleConsultaSici()
											: handleConsultaAls({
												credito: form.payment,
												monto: form.amount,
												fecha: formatDate(
													'yyyy-MM-dd',
													new Date(
														initialDate as Date
													)
												).toString(),
												emisora: form.banco,
											});
									}}
									disabled={
										alert.show ||
										errorMonto ||
										paymentError.length > 0 ||
										initialDate?.toString().length === 0 ||
										form.banco.length === 0
									}
									variant="solid"
									styles={{
										width: 260,
										height: 40,
										margin: '10px auto',
									}}
								/>
							</div>
						</Grid>
					</Grid>
				)}
				{status === 2 && (
					<Grid container columns={12}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<div className={classes.textTopLeft}>
								<p className={classes.TextBlack_H3} style={{ margin: '0 0 10px' }}>
									Selecciona los siguientes datos:
								</p>
							</div>
							<div
								style={{
									display: 'grid',
									fontSize: matches ? 14 : 16,
									marginBottom: 10,
								}}
							>
								<label>Año:</label>
								<DesktopDatePicker
									openTo="year"
									views={['year']}
									inputFormat="yyyy"
									value={initialDate}
									minDate={subYears(new Date(), 10)}
									maxDate={new Date()}
									onChange={(value) => {
										if (value) {
											setInitialDate(value);
											setAlert({
												show: false,
												message: '',
												severity: 'success',
											});
										}
									}}
									InputProps={{
										style: { height: 40, width: '100%' },
									}}
									renderInput={(params) => (
										<TextField
											fullWidth
											{...params}
											inputProps={{
												...params.inputProps,
												placeholder: 'aaaa',
											}}
											onKeyDown={onKeyDown}
										/>
									)}
								/>
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<div
								style={{
									display: 'grid',
									fontSize: matches ? 14 : 16,
									marginBottom: 10,
								}}
							>
								<label
									style={{
										position: 'relative',
									}}
								>
									Bimestre:
									<Tooltip
										arrow
										title=""
										placement="right"
										onClick={() => setModalPagos(true)}
									>
										<ErrorOutlinedIcon
											sx={{
												color: '#D1001F',
												position: 'absolute',
												top: '-2px',
												fontSize: '1.4rem',
												left: '65px',
												cursor: 'pointer',
												transform: 'rotate(180deg)',
											}}
										/>
									</Tooltip>
								</label>
								<Select
									displayEmpty
									name="bimestre"
									value={bimestre}
									onChange={(e: any) => {
										setBimestre(e.target.value);
										setAlert({
											show: false,
											message: '',
											severity: 'success',
										});
									}}
									style={{
										height: 40,
										width: '100%',
									}}
								>
									<MenuItem value="0">Selecciona</MenuItem>
									<MenuItem value="1">
										Enero - Febrero
									</MenuItem>
									<MenuItem value="2">Marzo - Abril</MenuItem>
									<MenuItem value="3">Mayo - Junio</MenuItem>
									<MenuItem value="4">
										Julio - Agosto
									</MenuItem>
									<MenuItem value="5">
										Septiembre - Octubre
									</MenuItem>
									<MenuItem value="6">
										Noviembre - Diciembre
									</MenuItem>
								</Select>
							</div>
						</Grid>
						<CustomAlert
							message={alert.message}
							severity={alert.severity}
							show={alert.show}
						/>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<div
								style={{
									width: matches ? '100%' : '600px',
									margin: '30px auto 20px',
									display: matches ? 'block' : 'flex',
									justifyContent: 'space-between',
								}}
							>
								<CustomButton
									label="Regresar"
									onClick={() => setPage(1)}
									variant="outlined"
									styles={{
										width: 260,
										height: 40,
										margin: '10px auto',
									}}
								/>
								<CustomButton
									label="Continuar"
									disabled={
										alert.show ||
										bimestre == 0 ||
										(initialDate.length === 0 &&
											!isAfter(
												initialDate,
												parseISO('1971-12-31')
											))
									}
									onClick={() => {
										creaCaso('ZSUA', '', '');
									}}
									variant="solid"
									styles={{
										width: 260,
										height: 40,
										margin: '10px auto',
									}}
								/>
							</div>
						</Grid>
					</Grid>
				)}
			</Paper>
			<BaseModal
				open={modalPagos}
				width={'md'}
				hideCloseButton={true}
				onClose={() => {
					setModalPagos(false);
				}}
			>
				<div>
					<div>
						<p>
							<strong>Calendario de pagos</strong>
						</p>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell variant="head">
											Bimestre a pagar
										</TableCell>
										<TableCell
											variant="head"
											style={{ width: '30%' }}
										>
											¿Cuándo se debe pagar?
										</TableCell>
										<TableCell
											variant="head"
											style={{ width: '30%' }}
										>
											¿Cuándo se vera reflejado el pago?
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{DataPagos.map((row, index) => (
										<TableRow
											key={index}
										>
											<TableCell component="th" scope="row">
												{row.bimestre}
											</TableCell>
											<TableCell>{row.fechaPago}</TableCell>
											<TableCell style={{ textAlign: 'center' }}>{row.mesPago}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
						<p style={{ fontSize: '14px' }}>
							* Si tu patrón realiza el pago después de la fecha
							indicada, el pago se verá reflejado hasta el
							siguiente mes
						</p>
					</div>
				</div>
			</BaseModal>
		</Box>
	);
};

export default Form;
