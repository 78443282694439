import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('124', 'saldos-movimientos');

export const ENDPOINTS_SALDOS_Y_MOVIMIENTOS: IBitacora = {
	'/balance': _bitacora.Read('124100', 'Consultar informacion principal'),
	'/datos-mi-credito': _bitacora.Read(
		'124101',
		'Consultar datos de mi credito'
	),
	'/periods': _bitacora.Read('124102', 'Consultar periodos'),
	'/anios': _bitacora.Read('124103', 'Consultar años'),
	'/paperless/status': _bitacora.Read(
		'124104',
		'Actualizar estatus del paperless'
	),
	'/paperless': _bitacora.Read('124105', 'Consultar paperless'),
	'/genera-estado-cuenta-historico-pdf': _bitacora.Read(
		'124106',
		'Generar estado de cuenta historico PDF'
	),
	'/genera-estado-cuenta-mensual-pdf': _bitacora.Read(
		'124107',
		'Generar estado de cuenta mensual PDF'
	),
	'/genera-reporte-pagos-pdf': _bitacora.Read(
		'124108',
		'Generar reporte de pagos PDF'
	),
	'/balance/credit-movements': _bitacora.Read(
		'124109',
		'Consultar movimientos'
	),
	'/balance/credit-movements-pdf': _bitacora.Read(
		'124110',
		'Generar reporte de movimientos PDF'
	),
	'/saldos-movimientos/operacion-bancomer': _bitacora.Read(
		'124111',
		'Consultar operaciones bancomer'
	),
	'/saldos-movimientos/operacion-codi': _bitacora.Read(
		'124112',
		'Consultar operaciones codi'
	),
	'/aportaciones-ext-pago-cuenta': _bitacora.Read(
		'124113',
		'Consultar pago de cuenta ext'
	),
	'/salmov/pagos-parciales': _bitacora.Read(
		'124114',
		'Consultar pagos parciales'
	),
	'/saldos-movimientos/certificado-calidad': _bitacora.Read(
		'124115',
		'Consultar certificado de calidad'
	),
	// -------- Nuevas URL para esta version -------------
	'/v1/saldos-y-movimientos/obtener-saldos-y-movimientos': _bitacora.Read(
		'124116',
		'Consultar Datos principales'
	),
	'/v1/saldos-y-movimientos/obtener-movimientos-credito': _bitacora.Read(
		'124117',
		'Consultar movimientos del crédito'
	),
	'/v1/saldos-y-movimientos/obtener-reporte-movimientos-credito': _bitacora.Read(
		'124118',
		'Consultar reporte de movimientos del crédito'
	),
	'/v1/saldos-y-movimientos/obtener-periodos-reporte-mensual': _bitacora.Read(
		'124119',
		'Consultar periodos del reporte de mensual del crédito'
	),
	'/v1/saldos-y-movimientos/obtener-reporte-mensual': _bitacora.Read(
		'124120',
		'Consultar reporte de mensual del crédito'
	),
	'/v1/saldos-y-movimientos/obtener-reporte-historico': _bitacora.Read(
		'124121',
		'Consultar reporte de histórico del crédito'
	),
	'/v1/saldos-y-movimientos/obtener-certificado-calidad': _bitacora.Read(
		'124122',
		'Consultar certificado de calidad del crédito'
	),
	'/v1/saldos-y-movimientos/paperless/consultar-estatus': _bitacora.Read(
		'124123',
		'Consultar paperless del crédito'
	),
	'/v1/saldos-y-movimientos/paperless/registrar': _bitacora.Update(
		'124124',
		'Actualizar paperless del crédito'
	),
	'/v1/saldos-y-movimientos/generar-pago-codi': _bitacora.Read(
		'124125',
		'Consultar datos para pago CODI'
	),
	'/v1/saldos-y-movimientos/generar-pago-bancomer': _bitacora.Read(
		'124126',
		'Consultar datos para pago Bancomer'
	),
	'/v1/saldos-y-movimientos/obtener-alertamiento-creditos-liquidados': _bitacora.Read(
		'124127',
		'Consultar alertamientos por creditos liquidados'
	),
	// ---- No Localizados ----
	'/v1/saldos-y-movimientos/obtener-anios-reporte-pagos': _bitacora.Read(
		'124128',
		'Consultar años del reporte'
	),
	'/v1/saldos-y-movimientos/obtener-reporte-pagos': _bitacora.Read(
		'124128',
		'Consultar reporte de pagos'
	),
	// ---- No Localizados ----
};
