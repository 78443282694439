/* eslint-disable react-hooks/exhaustive-deps */
// DEPENDENCIES
import { Box, Divider, Paper } from '@mui/material';
// COMPONENTS
import ModalLoading from '../../../../components/ModalLoading';
import CustomButton from '../../../../components/Button';
import TextInput from '../../../../components/TextInput';
import SelectInput from '../../../../components/SelectInput';
import CustomAlert from '../../../../components/CustomAlert';
// RESOURCES
import { isNumber } from '../../../../utils/expressions';
import { useStyles } from '../styles';
// ASSETS
import { useNavigate } from 'react-router-dom';
import { useContactAddress } from './hooks/useContactAddress';

const ContactAddress = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const {
		loader,
		alert,
		stateOptions,
		delegacionOptions,
		coloniaOptions,
		handleChange,
		handleConfirm,
		handleClose,
		address,
		errors,
	} = useContactAddress()

	return (
		<>
			<ModalLoading loading={loader} />
			<Paper className={classes.paperContainer}>
				<Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
					<label className={classes.tituloAcordion}>
						Domicilio actual
					</label>
				</Box>
				<Divider />
				<div className={classes.mailformContainer}>
					<TextInput
						isRequired
						label="Código postal"
						id="cp"
						name="cp"
						value={address.cp}
						error={Boolean(errors.cp)}
						helperText={errors.cp}
						onChange={(event) =>
							isNumber(event.target.value) &&
							event.target.value.length <= 5 &&
							handleChange(event)
						}
					/>
					<SelectInput
						disabled
						isRequired
						id="state"
						name="state"
						label="Estado"
						value={address.state}
						helperText={errors.state}
						onChange={handleChange}
						options={stateOptions}
					/>
					<SelectInput
						disabled
						isRequired
						label="Delegación o municipio"
						id="delegacion"
						name="delegacion"
						value={address.delegacion}
						helperText={errors.delegacion}
						onChange={handleChange}
						options={delegacionOptions}
					/>
					<SelectInput
						isRequired
						label="Colonia"
						id="colony"
						name="colony"
						value={address.colony}
						helperText={errors.colony}
						onChange={handleChange}
						options={coloniaOptions}
					/>
					<TextInput
						isRequired
						label="Calle"
						id="street"
						name="street"
						value={address.street}
						error={Boolean(errors.street)}
						helperText={errors.street}
						onChange={handleChange}
					/>
					<TextInput
						isRequired
						label="Número exterior"
						id="noExterior"
						name="noExterior"
						value={address.noExterior}
						error={Boolean(errors.noExterior)}
						helperText={errors.noExterior}
						onChange={(event) =>
							isNumber(event.target.value) && handleChange(event)
						}
					/>
					<TextInput
						label="Número interior"
						id="noInterior"
						name="noInterior"
						value={address.noInterior}
						onChange={handleChange}
					/>
				</div>
				<CustomAlert
					show={Boolean(alert.message)}
					severity={alert.color}
					message={alert.message}
					onClose={handleClose}
				/>
				<div className={classes.actionsContainer}>
					<CustomButton
						label="Salir"
						disabled={false}
						variant="outlined"
						onClick={() => navigate('/')}
					/>
					<CustomButton
						label={'Guardar cambios'}
						disabled={
							!Boolean(
								address.cp &&
								address.state &&
								address.delegacion &&
								address.colony &&
								address.street &&
								address.noExterior
							) || Boolean(alert.message)
						}
						variant="solid"
						onClick={handleConfirm}
					/>
				</div>
				<div className={classes.containerRequiredFields}>
					<Divider />
					<p className={classes.requiredFieldsLabel}>
						* Datos obligatorios
					</p>
				</div>
			</Paper>
		</>
	);
};

export default ContactAddress;
