import React, { Fragment } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useStyles } from './styles';

function createData(bimestre: string, pagoPatron: string, saldoMov: string) {
	return { bimestre, pagoPatron, saldoMov };
}

const rows = [
	createData('Enero - Febrero', 'Marzo', 'Primera semana de abril'),
	createData('Marzo - Abril', 'Mayo', 'Primera semana de junio'),
	createData('Mayo - Junio', 'Julio', 'Primera semana de agosto'),
	createData('Julio - Agosto', 'Septiembre', 'Primera semana de octubre'),
	createData(
		'Septiembre - Octubre',
		'Noviembre',
		'Primera semana de diciembre'
	),
	createData('Noviembre - Diciembre', 'Enero', 'Primera semana de febrero'),
];

export default function MySavingTable() {
	const classes = useStyles();
	return (
		<Fragment>
			<p className={`${classes.containerTitle} ${classes.maxContent}`} style={{ fontWeight: 'bold', margin: '20px auto' }}>
				Calendario de actualización de los movimientos y del saldo
				de la Subcuenta de Vivienda, por aportaciones patronales
			</p>
			<div className={classes.headerTableTitle}>
				<p style={{ color: 'white', fontSize: 14, fontWeight: 'bold' }}>
					Verifica cuándo aparecerá tu aportación en nuestro sistema
				</p>
			</div>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell
								variant="head"
								className={classes.tableTitleHeader}
							>
								<p className={`${classes.noMargin} ${classes.maxContent}`}>Bimestre</p>
							</TableCell>
							<TableCell
								variant="head"
								className={classes.tableTitleHeader}
							>
								<p className={`${classes.noMargin} ${classes.maxContent}`}>Pago de tu patrón</p>
							</TableCell>
							<TableCell
								variant="head"
								className={classes.tableTitleHeader}
							>
								<p className={`${classes.noMargin} ${classes.maxContent}`}>Saldo y movimientos actualizados*</p>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row, index) => (
							<TableRow
								key={index}
								sx={{
									'&:last-child td, &:last-child th': {
										border: 0,
									},
								}}
							>
								<TableCell component="th" scope="row">
									<p className={`${classes.noMargin} ${classes.maxContent}`}>{row.bimestre}</p>
								</TableCell>
								<TableCell
									align="left"
									sx={{
										color: '#293990',
									}}
								>
									<p className={`${classes.noMargin} ${classes.maxContent}`}>{row.pagoPatron}</p>
								</TableCell>
								<TableCell>
									<p className={`${classes.noMargin} ${classes.maxContent}`}>{row.saldoMov}</p>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Fragment>
	);
}
