import { Box, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomButton from '../../../components/Button';
import ModalLoading from '../../../components/ModalLoading';
import { writingRequestAPI } from '../../../api/modules/WritingRequest';
import { RootState } from '../../../store';
import { encodeAllJSONData, encrypt } from '../../../utils/encrypt';
import CustomAlert from '../../../components/CustomAlert';
import { CODIGO_CASO_VIEJO, MSG_ERROR } from './utils';
import { useEscrituras } from '../hooks/useEscrituras';
import {
	CODE_DEEDS,
	CODE_VALID_GENERIC,
	PROCESS_PAGE_STATUS,
	WRITING_REQUEST_STATUS,
} from './utils/pagesStatus';
import { defaultEscrituras } from '../context/utils';
import { apiDeedsDelivery } from '../../../api/modules/Deeds';
import { keyAPI } from '../../../api/modules/key';
import { INTERNAL_MESSAGES } from '../../../config/messageCatalog';
import ModalNotarios from './components/ModalNotarios';
import { useStyles } from './styles/homePage-styles';
import ErrorMessage from './components/ErrorMessage';
import { IDataNotario } from './interface';

interface IProps {
	setPage: (arg: number) => void;
	setPageTab: (arg: number) => void;
}

const HomePage = ({ setPage, setPageTab }: IProps) => {
	const classes = useStyles();
	const { escrituras, setEscrituras, getConsultDetail } = useEscrituras();
	const { user } = useSelector((state: RootState) => state.session);
	const { credit } = useSelector((state: RootState) => state.credit);
	const [loading, setLoading] = useState(false);
	const [msgError, setMsgError] = useState('');
	const [showModalError, setShowModalError] = useState(false);
	const [dataNotario, setDataNotario] = useState<IDataNotario>({});

	const validarEscritura = async () => {
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const credito_base64 = await encrypt(credit.toString() || '', key);
			const validaEscritura = await apiDeedsDelivery.getEscrituraValida(
				credito_base64
			);
			// Validamos la escritura con el flujo anterior y sus validaciones
			// correspondientes.
			if (validaEscritura?.result?.codigo_respuesta) {
				setMsgError(
					validaEscritura?.result?.descripcion ||
					INTERNAL_MESSAGES.ERROR_MESSAGE
				);
				return false;
			}

			const dataOrigin = {
				credito: credit,
			};
			const dataEncrypted = await encodeAllJSONData(
				{ data: JSON.stringify(dataOrigin) },
				user?.k || ''
			);
			const { result: valida } = await writingRequestAPI.getValidarCaso(
				dataEncrypted
			);
			// Se valida el valor de la entrega escritura habilitada
			if (validaEscritura?.result?.entrega_escritura_habilitado) {
				// Si la respuesta del servicio genérico es exitoso deja continuar con el proceso
				if (valida?.code === CODE_VALID_GENERIC.SUCCESS) {
					return true;
				}

				// Si el código es error muestra pantalla o mensaje de error
				// Que no deje continuar con el flujo
				if (valida?.code === CODE_VALID_GENERIC.ERROR) {
					setMsgError(valida?.message || MSG_ERROR);
					setShowModalError(true);
					return false;
				}
			}
			// Si el valor de entrega_escritura_habilitado es falso procede abrir el modal de notarios
			// o en su caso mostrara un mensaje de error
			if (!validaEscritura?.result?.entrega_escritura_habilitado) {
				const codigoValidador = String(validaEscritura?.result?.codigo).slice(1, 4);
				if (codigoValidador !== '100') {
					setDataNotario({
						messages: validaEscritura?.result?.mensajes || [],
						data: {},
					});
					return false;
				}
				if (valida?.code === CODE_VALID_GENERIC.ERROR) {
					setDataNotario({
						messages: validaEscritura?.result?.mensajes || [],
						data: valida?.data || {},
						message: 'Para obtener la escritura, debes presentarte al archivo general de notarias de tu Estado.'
					});
					return false;
				}
				setDataNotario({
					messages: validaEscritura.result?.mensajes || [],
					data: validaEscritura.result?.notario_informacion || {},
					message: 'Para obtener la escritura, debes presentarte al archivo general de notarias de tu Estado.'
				});
				return false;
			}
			setMsgError(MSG_ERROR);
			return false;
		} catch (error) {
			setMsgError(MSG_ERROR);
			return false;
		}
	};

	useEffect(() => {
		const consultaInicial = async () => {
			try {
				setLoading(true);
				setMsgError('');
				const { result } = await writingRequestAPI.getCaso();

				// Validamos si tiene caso
				if (result?.code === CODE_DEEDS.EXIST_CASE) {
					// Es un caso viejo
					if (result?.data?.proccess === CODIGO_CASO_VIEJO) {
						const response = await validarEscritura();
						if (response) {
							setPage(WRITING_REQUEST_STATUS.PAGE_OLD);
							return;
						}
						return;
					}
					if (escrituras?.restablecerProceso) {
						setEscrituras(defaultEscrituras);
						setPageTab(PROCESS_PAGE_STATUS.FORM_EDITION);
						return;
					}
					if (result?.data) {
						const dtCaso = {
							noCaso: result?.data.noCaso,
							fecha: result?.data.fecha,
							fechaEstatus: result?.data.fechaEstatus,
							razonEstatus: result?.data?.razonEstatus,
						};
						const getDetail = await getConsultDetail({
							caso: result?.data.noCaso,
							isGetCesis: true,
							datos: dtCaso,
						});
						if (!getDetail) {
							const code = Number(
								(result?.data?.estatus || 'E').replace('E', '0')
							);
							setPage(WRITING_REQUEST_STATUS.PROCESS);
							setPageTab(
								code >= 19
									? PROCESS_PAGE_STATUS.FINISH_PAGE
									: PROCESS_PAGE_STATUS.HISTORY_CASE
							);
							return;
						}
						setMsgError(MSG_ERROR);
						return;
					}
				}
				// Validamos si no tiene caso
				if (result?.code === CODE_DEEDS.NOT_EXIST_CASE) {
					const response = await validarEscritura();
					if (response) {
						return;
					}
				}
			} catch (error) {
				setMsgError(MSG_ERROR);
			} finally {
				setLoading(false);
			}
		};

		consultaInicial();

		return () => {
			setLoading(false);
		};
		/* eslint-disable react-hooks/exhaustive-deps */
	}, []);

	return (
		<Paper>
			<Box className={classes.container}>
				<p className={classes.title}>Aspectos importantes</p>

				<Box className={classes.subContainer}>
					<p>
						<b>
							Si ejerciste tu crédito entre el periodo de 1972 y
							2007
						</b>
						, te sugerimos solicitar tu escritura debido a que es el
						documento que acredita la propiedad del inmueble.
					</p>

					<p>
						⋅ Dependiendo de la información que se encuentre en el
						Archivo Nacional de Expedientes de Crédito [ANEC] del
						Infonavit, relacionada con el crédito, el tiempo de
						resolución podrá variar.
					</p>

					<p>
						⋅ Una vez que obtengas tu número de caso, debes darle
						seguimiento a tu solicitud por este medio, hasta que tu
						escritura sea entregada en el Centro de servicio
						Infonavit que elegiste. En caso de que necesites
						asesoría, puedes comunicar a Infonatel al 55 9171 5050
						desde la Ciudad de México 0 al 800 008 3900 desde
						cualquier parte del país.
					</p>

					<p>
						⋅ Al recibir tu escritura te sugerimos iniciar la
						liberación de la misma con el Notario. El trámite corre
						por tu cuenta y debes considerar que tiene un costo.
					</p>
				</Box>

				<CustomAlert
					message={msgError}
					show={Boolean(msgError) && !showModalError}
					severity="warning"
				/>

				<Box className={classes.buttons}>
					<CustomButton
						label={'Continuar'}
						disabled={msgError === '' ? false : true}
						onClick={() => setPage(WRITING_REQUEST_STATUS.PROCESS)}
					/>
				</Box>
			</Box>

			<ModalLoading loading={loading} />
			<ModalNotarios dataNotario={dataNotario} />
			<ErrorMessage message={msgError} show={showModalError} />
		</Paper>
	);
};

export default HomePage;
