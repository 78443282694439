import { makeStyles } from '@mui/styles';
import { Theme, styled } from '@mui/material/styles';
import PickersDay, { PickersDayProps } from '@mui/lab/PickersDay';
import { Button } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
	titleNoticeContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
	},
	maxContent: {
		width: '100%',
		maxWidth: 'max-content'
	},
	marginCenter: {
		marginRight: 'auto',
		marginLeft: 'auto'
	}
}));

type CustomPickerDayProps = PickersDayProps<Date> & {
	isFirstDay: boolean;
	isLastDay: boolean;
	typeDay?: string | null;
};

export const RoundBtn = styled(Button)(({ theme }) => ({
	textTransform: 'none',
	borderRadius: 40,
	width: '100%',
	margin: 10,
}));

export const CustomPickersDay = styled(PickersDay, {
	shouldForwardProp: (prop) =>
		prop !== 'isFirstDay' && prop !== 'isLastDay' && prop !== 'typeDay',
})<CustomPickerDayProps>(({ isFirstDay, isLastDay, typeDay }) => ({
	...(typeDay
		? {
			borderRadius: 0,
			'&:hover, &:focus': {
				backgroundColor: 'rgba(41, 57, 144, 0.7)',
				color: '#fff',
			},
		}
		: {
			color: 'gray',
		}),
	...(isFirstDay && {
		borderTopLeftRadius: '50%',
		borderBottomLeftRadius: '50%',
	}),
	...(isLastDay && {
		borderTopRightRadius: '50%',
		borderBottomRightRadius: '50%',
	}),
	...(typeDay === 'alta' && {
		backgroundColor: 'rgba(58, 190, 0, 0.4)',
		color: 'rgb(58, 190, 0)',
	}),
	...(typeDay === 'media' && {
		backgroundColor: 'rgba(253, 156, 0, 0.4)',
		color: 'rgb(253, 156, 0)',
	}),
	...(typeDay === 'baja' && {
		backgroundColor: 'rgba(255, 29, 29, 0.4)',
		color: 'rgb(255, 29, 29)',
	}),
	'&.Mui-selected': {
		backgroundColor: 'rgb(41, 57, 144) !important',
	},
})) as React.ComponentType<CustomPickerDayProps>;
