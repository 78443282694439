import { Dialog, Box, Divider } from '@mui/material';
import CustomButton from '../../../components/Button';
import { useStyles } from './styles/modal-confirm-styles';

interface IProps {
	show: boolean;
	setShow: (arg: boolean) => void;
	execute: () => void;
}

const ModalConfirmDoc = ({ show, setShow, execute }: IProps) => {
	const styles = useStyles();
	return (
		<Dialog open={show} fullWidth maxWidth={'md'}>
			<Box className={styles.container}>
				<h2 className={styles.textHeader}>
					¿Estás seguro que adjuntaste todos tus documentos?
				</h2>

				<Divider />

				<Box className={styles.buttonGroup}>
					<Box className={styles.buttonItem}>
						<CustomButton
							variant="outlined"
							label={'No'}
							onClick={() => setShow(false)}
						/>
					</Box>
					<Box className={styles.buttonItem}>
						<CustomButton label={'Si'} onClick={execute} />
					</Box>
				</Box>
			</Box>
		</Dialog>
	);
};

export default ModalConfirmDoc;
