import React from 'react';
import { AlertColor, Alert } from '@mui/material';
import { useStyles } from './styles';
import warningRedAssets from '../../assets/img/icono-advertencia-rojo.png';
import DoneIcon from '@mui/icons-material/Done';
import MessageCustom from '../MessageCustom';

interface ICustomAlert {
	show: boolean;
	severity: AlertColor;
	message: string | React.ReactChild;
	onClose?: () => void;
	children?: JSX.Element;
}

const CustomAlert = (props: ICustomAlert) => {
	const { show, severity, message, onClose, children } = props;

	const getColorBackground = (): string => {
		let color = '';
		switch (severity) {
			case 'warning':
				color = '#FAE295';
				break;
			case 'info':
				color = '#EEFAFA';
				break;
			case 'error':
				color = '#FFE2E5';
				break;
			case 'success':
				color = '#D0F0C9';
				break;
			default:
				break;
		}
		return color;
	};

	const getColorLabel = (): string => {
		let color = '';
		switch (severity) {
			case 'warning':
				color = '#E35700';
				break;
			case 'info':
				color = '#293990';
				break;
			case 'error':
				color = '#D1001F';
				break;
			case 'success':
				color = '#3ABE21';
				break;
			default:
				break;
		}
		return color;
	};
	const classes = useStyles(getColorLabel())();
	return (
		<Alert
			style={{
				borderRadius: '6px',
				display: show ? 'flex' : 'none',
				boxSizing: 'border-box',
				// justifyContent: 'center',
				alignItems: 'center',
				marginTop: 10,
				marginBottom: 10,
				backgroundColor: getColorBackground(),
				color: getColorLabel(),
			}}
			className={classes.alertIcon}
			onClose={severity === 'warning' ? onClose : undefined}
			severity={severity}
			sx={{ width: '100%' }}
			icon={
				severity === 'success' ? (
					<DoneIcon />
				) : (
					<img
						src={warningRedAssets}
						className={classes.sizeIcon}
						alt="Icono de alerta"
					/>
				)
			}
			//icon={severity === 'success' ? <DoneIcon /> : <WarningAmberIcon />}
		>
			{children !== undefined && children}
			{children === undefined && typeof message === 'string' && (
				<MessageCustom
					msg={typeof message === 'string' ? message.toString() : ''}
				></MessageCustom>
			)}
			{children === undefined && typeof message !== 'string' && (
				<label>{message}</label>
			)}
		</Alert>
	);
};

export default CustomAlert;
