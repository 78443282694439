export interface IEscrituras {
	noCaso: string;
	proccess: string;
	estatus: string;
	noTantos: number;
	descripcion: string;
	fecha: string;
	fechaEstatus: string;
	edoTramite: string;
	cesiTramite: string;
	citaId: string;
	cesi: string;
	edoCesi: string;
	fechaRegAdministrativa: string;
	fechaRegJudical: string;
	idSubEstatus?: string;
	tabPage?: number;
	consultarCita?: boolean;
	razonEstatus?: string;
	restablecerProceso?: boolean;
}

export const defaultEscrituras: IEscrituras = {
	noCaso: '',
	proccess: '',
	estatus: '',
	noTantos: 0,
	descripcion: '',
	fecha: '',
	fechaEstatus: '',
	tabPage: 0,
	edoTramite: '',
	cesiTramite: '',
	citaId: '',
	cesi: '',
	edoCesi: '',
	fechaRegAdministrativa: '',
	fechaRegJudical: '',
	consultarCita: false,
	idSubEstatus: '',
	razonEstatus: '',
	restablecerProceso: false,
};
