import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		textAlign: 'center',
	},
	containerVideo: {
		maxWidth: 550,
		width: '100%',
	},
	containerAudio: {
		maxWidth: 600,
		width: '100%',
		height: 500,
	},
}));
