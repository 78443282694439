import { Fragment, useState } from 'react';
import {
	Collapse,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	Pagination,
	TableRow,
	Link,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { mappedEstatus } from '../MyComplaints/utils';

const CollapseTable = ({
	data,
	columns,
	page,
	handlePage,
	attachDocument,
}: any) => {
	const [selected, setSelected] = useState();

	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						{columns.map((column: any) => (
							<TableCell
								key={column.label}
								style={column.headerStyles}
							>
								<label>
									{column.label}
								</label>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{data
						.slice(page * 10, page * 10 + 10)
						.map((item: any, i: number, oArray: any) => (
							<Fragment key={item.id || i}>
								<TableRow>
									{columns.map((column: any, j: number) => (
										<TableCell
											key={column.column + j}
											style={column.style}
										>
											<label>
												{column.renderValue
													? column.renderValue(
															item[column.column],
															i,
															oArray,
															selected,
															setSelected
													)
													: item[column.column]}
											</label>
										</TableCell>
									))}
								</TableRow>
								<TableRow>
									<TableCell
										colSpan={6}
										style={{
											padding: 0,
											background: grey[200],
										}}
									>
										<Collapse in={item.caso === selected}>
											<Table>
												<TableBody>
													<TableRow>
														<TableCell
															colSpan={1}
															style={{
																fontWeight: 600,
															}}
														>
															<label>
																Descripción
															</label>
														</TableCell>
														<TableCell
															colSpan={5}
															style={{
																width: '83%',
															}}
														>
															<label>
																{
																	mappedEstatus(
																		item.estatus,
																		item.descripcion
																	).descripcion2
																}
															</label>
														</TableCell>
													</TableRow>
													{item.estatus ===
														'E0004' && (
														<TableRow>
															<TableCell
																colSpan={6}
																style={{
																	textAlign:
																		'center',
																}}
															>
																<Link
																	onClick={
																		() => attachDocument(item)
																	}
																>
																	Adjuntar
																	documento
																</Link>
															</TableCell>
														</TableRow>
													)}
												</TableBody>
											</Table>
										</Collapse>
									</TableCell>
								</TableRow>
							</Fragment>
						))}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TableCell colSpan={columns.length}>
							<Pagination
								style={{
									display: 'flex',
									justifyContent: 'center',
								}}
								color="primary"
								shape="rounded"
								count={Math.ceil(data.length / 10)}
								page={page + 1}
								onChange={handlePage}
							/>
						</TableCell>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
};

export default CollapseTable;
