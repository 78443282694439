/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import Layout from '../../components/Layout';
import { HeaderList } from '../../components/HeaderList';
import FormAppointment from './formAppointment';
import TicketAppointment from './ticketAppointment';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { IAlert } from '../../interfaces/alert';
import { encodeAllJSONData } from '../../utils/encrypt';
import { makeDate24API } from '../../api/modules/makeDate24';
import TabsAppointment from './tabs';
import { IResponseCitasActuales, IDataReservar, IDataCitasActuales } from '../../interfaces/makeDate24';
import { formatDate } from '../../utils/dates';
import { downloadPDF } from '../../utils/downloadPDF';
import ModalLoading from '../../components/ModalLoading';
import { useBitacora } from '../../hooks/useBitacora';

interface IDataConfirm {
	appointmentCode: string;
	appointmentEndDate: string;
	centroAtencionDes: string;
	centroAtencionId: string;
	centroAtencionLocation: string;
	clientNSS: string;
	creationDate: Date;
	customField1: number;
	date: Date;
	day: string;
	hour: string;
	id: string;
	minute: string;
	serviceDes: string;
	serviceId: string;
	stateId: string;
	organizationLocation: string;
}
interface IDataServicios {
	serviceDes: string;
	serviceId: string;
}
const MakeAppointment24 = () => {
	const { regBitacoraNotOld } = useBitacora();
	const [doctosRequeridos, setDoctosRequeridos] = useState('');
	const [motivo, setMotivo] = useState('');
	const [services, setServices] = useState<Array<IDataServicios>>([]);
	const [ResponseCitasActuales, setResponseCitasActuales] = useState([]);
	const [ResponseServices, setReponseServices] = useState([]);
	const [allServices, setAllServices] = useState([]);
	const [errorResponseCitas, setErrorResponseCitas] = useState(false);
	const [errorResponseServices, setErrorResponseServices] = useState(false);
	const [alertCitasActuales, setAlertCitasActuales] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	let params = useParams();
	const [loading, setLoading] = useState(false);
	const [timerActive, setTimerActive] = useState(false);
	const [page, setPage] = useState(0);
	const [tabAct, setTabAct] = useState(0);
	const [service, setService] = useState('');
	const [serviceDes, setServiceDes] = useState('');
	const [cedisForm, setCedisForm] = useState('');
	const [horarioForm, setHorarioForm] = useState('');
	const [reprogramar, setReprogramar] = useState(false);
	const [fechaCita, setFechaCita] = useState<Date | null>(null);
	const { user, fontSizeLevel } = useSelector((state: RootState) => state.session);
	const [dataConfirm, setDataConfirm] = useState<IDataConfirm>({
		appointmentCode: '',
		appointmentEndDate: '',
		centroAtencionDes: '',
		centroAtencionId: '',
		centroAtencionLocation: '',
		clientNSS: '',
		creationDate: new Date(),
		customField1: 0,
		date: new Date(),
		day: '',
		hour: '',
		id: '',
		minute: '',
		serviceDes: '',
		serviceId: '',
		stateId: '',
		organizationLocation: ''
	});
	const [responseCita, setResponseCita] = useState({
		data: {
			appointmentReservationId: '',
			AttendantCode: '',
		},
	});
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [Params, setParams] = useState<{
		caso?: string;
		modulo?: string;
	}>({
		caso: '',
		modulo: '',
	});
	const [paramsToReprogramar, setParamsToReprogramar] = useState({
		appointmentCode: '',
		creationDate: '',
		date: '',
		id: '',
		caso: '',
		organizationDes: '',
		organizationLocation: '',
		organizationCode: '',
		service: '',
		serviceCode: '',
		ts: '',
	});
	const [agendaId, setAgendaId] = useState('');
	const [dataHorario, setDataHorario] = useState({
		intervaloId: '',
		cupoCanalId: '',
		numeroDeCupo: ''
	});
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	useEffect(() => {
		GetParametros();
	}, []);

	const GetParametros = () => {
		if (params) {
			const newModule = (params.modulo || '').replaceAll('-', ' ');
			let values = {
				caso: params.caso,
				modulo: newModule,
			};
			setParams(values);
		}
	};

	const saltosDeLinea = (docs: any) => {
		let str = docs;
		let search = '';
		let replacer;

		if (!str || str.length === 0) {
			return '';
		}

		str = str.replace(replacer, '\n');

		search = '</p>';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, '');

		search = '<br />';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, '\n');

		search = '&nbsp;';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, ' ');

		search = '&gt;';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, '->');

		search = '&bull;';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, '');
		str = str.split('<p>');

		str = str.map((a: any) => {
			return a.trim();
		});

		return str;
	};

	const onDownloadPDF = async (value: IResponseCitasActuales, bash: any) => {
		const arrayItemsSinBullet = value.doctosRequerido ? value.doctosRequerido
			.split('<br/>')
			.map(item => item.replace(/^•\s*/, ''))
			.filter(item => item !== 'Cualquier de los siguientes documentos dependiendo cómo se llevará a cabo el trámite') : '';
		try {
			setLoading(true);
			const params = {
				service: value.serviceDes || '',
				estatus: '',
				fechaEstatus:
					value.creationDate,
				codigoCita: value.appointmentCode || '',
				fechaCita:
					value.date || new Date(),
				horarioCita:
					value.date || new Date(),
				organizationDes: value.organizationDes || '',
				organizationLocation:
					value.organizationLocation || '',
				listadoFormateado: value?.doctosRequerido ? arrayItemsSinBullet : [''],
				numeroCaso: 'Sin caso',
				CreationDate: value.creationDate,
			};
			const dataEncript = await encodeAllJSONData(
				{
					data: JSON.stringify(params),
				},
				user?.k || ''
			);
			const responsePDF = await makeDate24API.downloadPDF(dataEncript);
			if (responsePDF.code === '0000' && responsePDF.data?.base64) {
				downloadPDF({
					payload: responsePDF.data.base64,
					name: `Haz_tu_cita-${formatDate('P')}`,
				});
			} else {
				setAlert({
					show: true,
					message: responsePDF.message || 'Ocurrio un error al descargar el PDF, intentalo de nuevo.',
					severity: 'error',
				})
			}
		} catch (error) {
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const handleCrear = async () => {
		setLoading(true);
		setTimerActive(false);
		if (reprogramar) {
			try {
				let day = String(fechaCita?.getDate()).padStart(2, '0');
				let month = String((fechaCita?.getMonth() || 0) + 1).padStart(2, '0');
				let year = fechaCita?.getFullYear();
				const hr = horarioForm.split(':', 3);
				const data = {
					citaId: paramsToReprogramar?.id,
					motivoReprogracion: '',
					tipoReprogramacion: '1',
					pCentroAtencionId: cedisForm,
					pServicioId: service,
					pDate: `${year}-${month}-${day} ${hr[0]}:${hr[1]}:00`,
					pHour: hr[0],
					pMinute: hr[1],
					pReservationId: responseCita.data.appointmentReservationId,
					agendaId: agendaId,
					intervaloId: dataHorario.intervaloId,
					cupoCanalId: dataHorario.cupoCanalId,
					canalId: '4',
					numeroDeCupo: dataHorario.numeroDeCupo
				}
				const dataEncript = await encodeAllJSONData(
					{
						data: JSON.stringify(data),
					},
					user?.k || ''
				);
				const responseRsv = await makeDate24API.actualizar(dataEncript)
				if (responseRsv.code === '0000' && responseRsv.data) {
					regBitacoraNotOld(makeDate24API.schemeUrl, data);
					setLoading(false);
					setDataConfirm(responseRsv.data);
					setAlert({
						show: false,
						message: '',
						severity: 'error',
					});
					if (responseRsv.data?.citaId) {
						handlecitasActuales('', responseRsv.data?.citaId);
						setPage(2);
					} else {
						setPage(0);
						setTabAct(1)
					}
				} else {
					setLoading(false);
					setAlert({
						show: true,
						message: responseRsv.message || 'Por el momento el servicio no está disponible. Intenta más tarde',
						severity: 'error',
					});
				}
			} catch (error) {
				setAlert({
					show: true,
					message: 'Por el momento el servicio no está disponible. Intenta más tarde',
					severity: 'error',
				});
				setLoading(false);
			} finally {
				setLoading(false);
			}
		} else {
			try {
				let day = String(fechaCita?.getDate()).padStart(2, '0');
				let month = String((fechaCita?.getMonth() || 0) + 1).padStart(2, '0');
				let year = fechaCita?.getFullYear();
				const hr = horarioForm.split(':', 3);
				const data = {
					pCentroAtencionId: cedisForm,
					pServicioId: service,
					pDate: `${year}-${month}-${day} ${hr[0]}:${hr[1]}:00`,
					pHour: hr[0],
					pMinute: hr[1],
					pReservationId: responseCita.data.appointmentReservationId,
					agendaId: agendaId,
					intervaloId: dataHorario.intervaloId,
					cupoCanalId: dataHorario.cupoCanalId,
					canalId: '4',
					numeroDeCupo: dataHorario.numeroDeCupo,
					noCaso: Params.caso || 'Sin caso'
				}
				const dataEncript = await encodeAllJSONData(
					{
						data: JSON.stringify(data),
					},
					user?.k || ''
				);
				const responseRsv = await makeDate24API.crear(dataEncript)
				if (responseRsv.code === '0000' && responseRsv.data) {
					regBitacoraNotOld(makeDate24API.schemeUrl, data);
					setLoading(false);
					setDataConfirm(responseRsv.data);
					setAlert({
						show: false,
						message: '',
						severity: 'error',
					});
					if (responseRsv.data?.citaId) {
						handlecitasActuales('', responseRsv.data?.citaId);
						setPage(2);
					} else {
						setPage(0);
						setTabAct(1)
					}
				} else {
					setLoading(false);
					setAlert({
						show: true,
						message: responseRsv.message || 'Por el momento el servicio no está disponible. Intenta más tarde',
						severity: 'error',
					});
				}
			} catch (error) {
				setAlert({
					show: true,
					message: 'Por el momento el servicio no está disponible. Intenta más tarde',
					severity: 'error',
				});
				setLoading(false);
			} finally {
				setLoading(false);
			}
		}
	};

	const handleReservar = async () => {
		try {
			setLoading(true);
			let day = String(fechaCita?.getDate()).padStart(2, '0');
			let month = String((fechaCita?.getMonth() || 0) + 1).padStart(2, '0');
			let year = fechaCita?.getFullYear();
			const hr = horarioForm.split(':', 3);
			const data: IDataReservar = {
				pRequestedDate: `${year}-${month}-${day} ${hr[0]}:${hr[1]}:00`,
				servicioId: service,
				agendaId: agendaId,
				numeroCupo: dataHorario.numeroDeCupo,
			}
			const dataEncript = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const responseRsv = await makeDate24API.reservar(dataEncript)
			if (responseRsv.code === '0000' && responseRsv?.data) {
				setResponseCita(responseRsv);
				setTimerActive(true);
				setLoading(true);
			} else {
				setLoading(false);
				setAlert({
					show: true,
					message: responseRsv.message || 'Por el momento el servicio no está disponible. Intenta más tarde',
					severity: 'error',
				});
			}
		} catch (error) {
			setAlert({
				show: true,
				message: 'Por el momento el servicio no está disponible. Intenta más tarde',
				severity: 'error',
			});
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const handlecitasActuales = async (pAppointmentCode: string, idCita?: string) => {
		try {
			setLoading(true);
			const data: IDataCitasActuales = {
				pAppointmentCode: pAppointmentCode
			}
			const dataEncript = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const response = await makeDate24API.citasActuales(dataEncript);
			if (response.code === '0000') {
				if (response.data?.citas && response.data.citas.length > 0) {
					setResponseCitasActuales(response.data.citas);
					setErrorResponseCitas(false);
					response.data.citas.forEach(async (element: any) => {
						if (idCita && idCita === element.id) {
							setDataConfirm(element);
						}
						const params = {
							pOrganizationCode: element?.organizationCode,
							pServiceId: element?.serviceId,
						};
						const dataEncript = await encodeAllJSONData(
							{
								data: JSON.stringify(params),
							},
							user?.k || ''
						);
						const responseDocReq = await makeDate24API.documentosRequeridos(dataEncript);
						if (responseDocReq.code === '0000') {
							const listado = saltosDeLinea(
								responseDocReq?.data?.requirementsList[0]?.requirementText
							);
							if (listado && listado.length > 0) {
								let anyElement: any = element;
								anyElement.doctosRequerido =
									responseDocReq?.data?.requirementsList[0]?.requirementText.replace(
										'style="color:windowtext"',
										"style={{color:'windowtext'}}"
									) || '';
								anyElement.doctosRequerido = anyElement.doctosRequerido.replace(/&bull;• /g, '• ');
								anyElement.doctosRequerido = anyElement.doctosRequerido.replace(/&bull;/g, '• ');
								element = anyElement;
							}
						}
					});
				}
				await handleServices(response.data.citas);
			} else {
				if (response.code === '0002') {
					setErrorResponseCitas(false);
				} else {
					setErrorResponseCitas(true);
					setAlertCitasActuales({
						show: true,
						message: response.message || 'Por el momento el servicio no está disponible. Intenta más tarde',
						severity: 'error',
					});
				}
				await handleServices([]);
			}
		} catch (err: any) {
			setErrorResponseCitas(true);
			setAlertCitasActuales({
				show: true,
				message: 'Ha ocurrido un error de conexión al obtener datos, intente nuevamente',
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleServices = async (citasActuales: any) => {
		try {
			const data: any = {
				filtro: params.modulo || '',
				perfil: user?.idPerfil || ''
			}
			const dataEncript = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const responseTotal = await makeDate24API.services(dataEncript);
			if (responseTotal.code === '0000') {
				if (citasActuales && citasActuales.length > 0) {
					let servicesAvailables: any = responseTotal.data.servicios.filter((objetoPrincipal: any) => {
						return !citasActuales.some((objetoEvitar: any) => {
							return objetoPrincipal.serviceId === objetoEvitar.serviceId;
						});
					});
					setReponseServices(servicesAvailables);
					setServices(servicesAvailables);
				} else {
					setReponseServices(responseTotal.data.servicios);
					setServices(responseTotal.data.servicios);
				}
				setAllServices(responseTotal.data.servicios);
			} else {
				setAlert({
					show: true,
					message: responseTotal.message || 'Por el momento el servicio no está disponible. Intenta más tarde.',
					severity: 'error',
				});
			}
		} catch (error) {
			setErrorResponseServices(true);
			setAlert({
				show: true,
				message: 'Por el momento el servicio no está disponible. Intenta más tarde.',
				severity: 'error',
			});
		}
	};

	const handleCancelar = async (pAppoimentId: string) => {
		try {
			setLoading(true);
			const data = {
				pAppointmentId: pAppoimentId,
				motivoCancelacion: motivo
			}
			const dataEncript = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const responseRsv = await makeDate24API.cancelar(dataEncript)
			if (responseRsv.code === '0000') {
				window.location.reload();
			} else {
				setLoading(false);
				setAlert({
					show: true,
					message: responseRsv.message || 'Por el momento el servicio no está disponible. Intenta más tarde',
					severity: 'error',
				});
			}
		} catch (error) {
			setErrorResponseServices(true);
			setAlert({
				show: true,
				message: 'Por el momento el servicio no está disponible. Intenta más tarde.',
				severity: 'error',
			});
		}
	};

	const handleDoctosRequeridos = async (
		pOrganizationCode: string,
		pServiceId: string
	) => {
		setLoading(true);
		let doctosRequerido: string = '';
		const params = {
			pOrganizationCode: pOrganizationCode,
			pServiceId: pServiceId,
		};
		const dataEncript = await encodeAllJSONData(
			{
				data: JSON.stringify(params),
			},
			user?.k || ''
		);
		makeDate24API.documentosRequeridos(dataEncript)
			.then((response: any) => {
				if (response.code === '0000') {
					const listado = saltosDeLinea(
						response?.data?.requirementsList[0]?.requirementText
					);
					if (listado && listado.length > 0) {
						doctosRequerido = response?.data?.requirementsList[0]?.requirementText.replace(
							'style="color:windowtext"',
							"style={{color:'windowtext'}}",
						) || '';
						doctosRequerido = doctosRequerido.replace(/&bull;• /g, '• ');
						doctosRequerido = doctosRequerido.replace(/&bull;/g, '• ');
					}
				}
				setDoctosRequeridos(doctosRequerido);
			})
			.catch((err) => {
				setLoading(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const sendDataReprogramar = (data: any) => {
		setReprogramar(true)
		setParamsToReprogramar(data.data)
		setService(data.data.service)
		setServiceDes(data.data.serviceDes)
		setPage(1)
	};

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<Layout updateTagText={updateTagText}>
			<Box ref={divRef}>
				<HeaderList title="Haz tu cita aquí" date="" />
				{page === 0 && (
					<TabsAppointment
						setPage={setPage}
						setTabAct={tabAct}
						motivo={motivo}
						setMotivo={setMotivo}
						handleCancelar={handleCancelar}
						setService={setService}
						setServiceDes={setServiceDes}
						downloadPDF={onDownloadPDF}
						handlecitasActuales={handlecitasActuales}
						ResponseCitasActuales={ResponseCitasActuales}
						ResponseServices={ResponseServices}
						errorResponseCitas={errorResponseCitas}
						errorResponseServices={errorResponseServices}
						modulo={Params.modulo}
						setServices={setServices}
						services={services}
						sendDataReprogramar={sendDataReprogramar}
						alertCitasActuales={alertCitasActuales}
					/>
				)}
				{page === 1 && (
					<FormAppointment
						setPage={setPage}
						service={service}
						serviceDes={serviceDes}
						setFechaCita={setFechaCita}
						setCedisForm={setCedisForm}
						setHorarioForm={setHorarioForm}
						onConfirm={handleReservar}
						caso={Params.caso}
						acceptCita={handleCrear}
						timerActive={timerActive}
						setTimerActive={setTimerActive}
						hasError={alert}
						services={allServices}
						handleDoctosRequeridos={handleDoctosRequeridos}
						onCloseError={() => {
							setAlert({
								show: false,
								message: '',
								severity: alert.severity,
							});
						}}
						doctosRequeridos={doctosRequeridos}
						agendaId={agendaId}
						setAgendaId={setAgendaId}
						setDataHorario={setDataHorario}
					/>
				)}
				{page === 2 && (
					<TicketAppointment
						setPage={setPage}
						setTabAct={setTabAct}
						motivo={motivo}
						setMotivo={setMotivo}
						handleCancelar={handleCancelar}
						dataConfirm={dataConfirm}
						setReprogramar={setReprogramar}
						downloadPDF={onDownloadPDF}
						doctosRequeridos={doctosRequeridos}
					/>
				)}
				<ModalLoading loading={loading} />
			</Box>
		</Layout>
	);
};

export default MakeAppointment24;
