import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		paddingLeft: 40,
		paddingRight: 40,
		paddingTop: 30,
		paddingBottom: 0,
	},
	title: {
		color: '#293990',
		display: 'flex',
		textAlign: 'center',
		justifyContent: 'center',
		margin: '0px 0px 20px 0px',
	},
	label1: {
		color: 'black',
		display: 'flex',
		textAlign: 'center',
		justifyContent: 'center',
		margin: 0,
	},
	label2: {
		color: 'black',
		fontWeight: 500,
		display: 'flex',
		textAlign: 'center',
		justifyContent: 'center',
		margin: '15px 0px 0px 0px',
	},
}));
