import React, { useEffect, useState } from 'react';
import { apiFollowComplaint } from '../../../../api/modules/followComplaint';
import { keyAPI } from '../../../../api/modules/key';
import DateInput from '../../../../components/DateInput';
import ModalLoading from '../../../../components/ModalLoading';
import SelectInput from '../../../../components/SelectInput';
import TextInput from '../../../../components/TextInput';
import { encrypt } from '../../../../utils/encrypt';
import { pattern } from '../../utils';
import { complaintForm } from './utils';

const ComplaintReason = ({ formValues, setFormValues }: any) => {
	const [entities, setEntities] = useState([]);
	const [loading, setLoading] = useState(false);

	const fetchEntities = async () => {
		try {
			setLoading(true);
			const data = {
				catalogo: 'Z0003',
				idN1: 'ZN100001',
			};
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const dataEncripted = await encrypt(JSON.stringify(data) || '', key);
			const result = await apiFollowComplaint.consultarCatalogo(
				dataEncripted
			);
			if (result.code && result.code === '000')
				setEntities(
					result.data.catalogos.map(
						({ idN2, n2Txt, ...other }: any) => ({
							value: idN2,
							text: n2Txt,
							...other,
						})
					)
				);
		} catch (error) {
			//console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchEntities();
	}, []);

	const handleChange = ({ target }: any) => {
		const { name, value } = target;
		if (name === 'telPersona') {
			if (isNaN(value)) return;
			if (value.length > 10) return;
		} else if (name === 'casoPrevio') {
			if (isNaN(value)) return;
			if (value.length > 10) return;
		} else if (name === 'notas') {
			if (value.length > 1500) return;
		} else if (name === 'direcPersona') {
			if (value.length > 80) return;
		} else if (value.length > 50) return;
		setFormValues({ ...formValues, [name]: value });
	};

	return (
		<>
			<ModalLoading loading={loading} />
			<h5 style={{ fontSize: 15 }}>
				Escribe la información que se solicita
			</h5>
			<div className="form-container">
				{complaintForm(entities).map(
					({ id, name, label, options, required, limit = 50 }: any) =>
						options ? (
							<SelectInput
								key={id}
								id={id}
								name={name}
								label={label}
								value={formValues[name]}
								onChange={handleChange}
								options={options}
								isRequired={required}
							/>
						) : (
							<TextInput
								key={id}
								id={id}
								name={name}
								label={label}
								value={formValues[name]}
								onChange={handleChange}
								isRequired={required}
								helperText={
									!pattern.test(formValues[name]) &&
									'Caracter no permitido'
								}
							/>
						)
				)}
				<DateInput
					name="fechaOcurrido"
					id="follow-fecha_ocurrido"
					label="Fecha en la que ocurrio la situación"
					value={formValues.fechaOcurrido}
					onChange={handleChange}
					disableFuture
					required
				/>
				<TextInput
					multiline
					rows={4}
					isRequired
					id="follow-complaint-notas"
					placeholder="Máximo 1,500 caracteres."
					label="Descripción de la queja"
					value={formValues.notas}
					onChange={handleChange}
					name="notas"
					helperText={
						!pattern.test(formValues.notas) &&
						'Caracter no permitido'
					}
				/>
				<label className="notas-helper">
					Te quedan {1500 - formValues.notas.length} caracteres.
				</label>
			</div>
		</>
	);
};

export default ComplaintReason;
