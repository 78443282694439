import { Grid, Paper } from '@mui/material';
import React from 'react';
import IconDes from '../../assets/img/icono-descarga.png';
import { CheckCircleOutlined } from '@mui/icons-material';
import { useStyles } from './styles';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';

interface Props {
	pdfDoc?: string;
}

const ValeHome = ({ pdfDoc }: Props) => {
	const classes = useStyles();
	const { user } = useSelector((state: RootState) => state.session);

	const pdf64 = () => {
		let link = document.createElement('a');
		link.download = user?.nss.toString() + '_ValeHogarATuMedida.pdf';
		link.href = 'data:application/octet-stream;base64,' + pdfDoc;
		link.click();
	};

	return (
		<Paper sx={{ pt: 5, pb: 3, pl: 2, pr: 2, textAlign: 'center' }}>
			<div className={classes.container}>
				<p className={`${classes.pHeader} ${classes.maxContent}`}>
					Imprime tu vale, recuerda que tiene una vigencia de 6 meses a partir
					de su fecha de emisión.
				</p>
				<Grid container columns={12} fontSize={16}>
					<Grid item xs={0} sm={0} md={3} lg={3}></Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								textAlign: 'center',
								marginBottom: 30,
								marginTop: 30,
								flexWrap: 'wrap',
							}}
							onClick={pdf64}
						>
							<label
								style={{
									textDecoration: 'underline #D1001F',
									color: '#D1001F',
									cursor: 'pointer',
								}}
							>
								Descarga tu vale de Hogar a tu medida<span> </span>
							</label>
							<img
								src={IconDes}
								style={{
									width: 24,
									height: 'max-content',
									marginLeft: 7,
									cursor: 'pointer',
								}}
								alt="Descarga"
							/>
						</div>
					</Grid>
					<Grid item xs={0} sm={0} md={3} lg={3}></Grid>
				</Grid>

				<p className={`${classes.parrafo1} ${classes.maxContent}`}>
					<a
						href="https://portalmx.infonavit.org.mx/wps/portal/infonavit.web/proveedores-externos/para-tu-casa/hogar-a-tu-medida/"
						className={classes.link1}
						target="blank"
					>
						Elige a un proveedor autorizado
					</a>
					<span> </span>por el Instituto para que lleve a cabo las adecuaciones
					que requieres en tu vivienda.
				</p>
				<p className={`${classes.parrafo1} ${classes.maxContent}`}>
					Para consultar este documento es necesario que:{' '}
				</p>

				<div className={classes.list}>
					<div style={{ display: 'flex' }}>
						<CheckCircleOutlined
							color="info"
							style={{
								marginRight: 5,
								width: 18,
							}}
						/>
						<label style={{ textAlign: 'left' }}>
							Tengas instalada la última versión de Acrobat Reader.
							<span> </span>
							<a
								target="_blank"
								href="https://get.adobe.com/reader/?loc=es"
								style={{
									textDecoration: 'underline #D1001F',
									color: '#D1001F',
									display: 'initial',
								}}
								rel="noreferrer"
							>
								Descárgalo aquí.
							</a>
						</label>
					</div>
					<div
						style={{
							display: 'flex',
							marginTop: 5,
							paddingBottom: 20,
						}}
					>
						<CheckCircleOutlined
							color="info"
							style={{
								marginRight: 5,
								width: 18,
							}}
						/>
						<label style={{ textAlign: 'left' }}>
							Permitas las ventanas emergentes en tu explorador de internet.
						</label>
					</div>
				</div>
			</div>
		</Paper>
	);
};

export default ValeHome;
