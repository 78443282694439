/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import Layout from '../../../../components/Layout';
import { Box, Grid, Paper } from '@mui/material';
import MapCrediterreno from './pages/MapCrediterreno';
import CreditConditions from './pages/CreditConditions';
import CreditBureau from './pages/CreditBureau';
import ProductsCrediterreno from './pages/ProductsCrediterreno';
import CreditSummary from './pages/CreditSummary';
import ViewPoints from '../../components/ViewPoints';
import FollowSteps from '../../components/FollowSteps';
import FlowCard from '../../components/FlowCard';
import { useController } from '../../hooks/useController';
import ResumeCard from '../../components/ResumeCard';
import { SelectionCredit } from '../../components/SelectionCredit/selectionCredit';
import { MIUC_PRODUCTS } from '../../constants/products';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

const pageViews = (followStepPath: any) => [
	<MapCrediterreno />,
	<CreditConditions />,
	<CreditBureau />,
	<ProductsCrediterreno />,
	<CreditSummary />,
	<FollowSteps guideLink={followStepPath} />,
];

const CrediterrenoMIUC = () => {
	const { fontSizeLevel } = useSelector((state: RootState) => state.session);
	const { dataProcessController, calcProgress, saveData, nextStep } =
		useController();
	const step = dataProcessController?.processData?.step;
	const showViewPoints = [0, 1, 2, 3].includes(step);

	const onSelect = async (option: string) => {
		let credit = '';

		if (option === MIUC_PRODUCTS.CREDITERRENO) {
			credit = MIUC_PRODUCTS.CREDITERRENO;
		} else {
			credit = MIUC_PRODUCTS.SUELO_MAS_CONSTRUCCION;
		}
		saveData(
			{
				processInfo: {
					credit: credit,
				},
			},
			true
		);
		nextStep();
	};

	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<Layout updateTagText={updateTagText}>
			<Grid container columns={12} spacing={2} ref={divRef}>
				<Grid item xs={12} md={8}>
					{pageViews(
						dataProcessController.processInfo?.credit ===
							MIUC_PRODUCTS.CREDITERRENO
							? require('../../../../assets/files/guia-pasos-a-seguir/GPSTerreno.pdf')
							: require('../../../../assets/files/guia-pasos-a-seguir/Guia_de_pasos_a_seguir_S+C_091123_vf.pdf')
					)[step] ?? (
							<Paper>
								<SelectionCredit
									ignoreDestinies
									onSelectCredit={onSelect}
								/>
							</Paper>
						)}
				</Grid>
				<Grid item xs={12} md={4}>
					{showViewPoints && (
						<Box sx={{ textAlign: 'center' }} mb={2}>
							<ViewPoints showPoints={step >= 1} />
						</Box>
					)}
					{step === 5 && (
						<Box sx={{ textAlign: 'center' }} mb={2}>
							<ResumeCard />
						</Box>
					)}
					{step >= 1 && (
						<Box>
							<FlowCard
								title="Tu selección"
								flow={
									dataProcessController.processInfo?.selection
								}
								progress={calcProgress()}
							/>
						</Box>
					)}
				</Grid>
			</Grid>
		</Layout>
	);
};

export default CrediterrenoMIUC;
