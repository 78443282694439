import React, { Fragment, ReactChild } from 'react';
import { CheckCircleOutlined } from '@mui/icons-material';
import Link from '@mui/material/Link';
/*
style
 */
import { useStyles } from './styles';

interface IHeaderListItems {
	name: string;
	childs: string[];
}
interface IHeaderList {
	title?: string | ReactChild;
	date?: string;
	hiddenInfo?: boolean;
	list?: string[] | ReactChild[] | false;
	listSizeIcon?: number;
	listChilds?: IHeaderListItems[];
	listLink?: any;
	linkExterno?: boolean;
}

export const HeaderList = (props: IHeaderList) => {
	const {
		title,
		list,
		listChilds,
		hiddenInfo,
		listLink,
		listSizeIcon,
		linkExterno,
	} = props;
	const classes = useStyles();
	return (
		<Fragment>
			{title && (
				<div className={classes.titleContainer}>
					<h2>{title}</h2>
					<p></p>
				</div>
			)}
			<div className={classes.infoContainer}>
				{!hiddenInfo &&
					(list || []).map((text, index) => (
						<div key={index}>
							{listLink && index === listLink[4] && (
								<p
									className={classes.listItem}
								>
									<CheckCircleOutlined
										color="info"
										style={{ marginRight: 5, width: 22 }}
									/>
									<label>
										{listLink[0]}{' '}
										{linkExterno && (
											<Link
												href={listLink[3]}
												target="blank"
											>
												{listLink[1]}
											</Link>
										)}
										{!linkExterno && (
											<Link href={listLink[3]}>
												{listLink[1]}
											</Link>
										)}
										{listLink[2]}
									</label>
									<br />
								</p>
							)}
							<p
								key={'listItem' + index}
								className={classes.listItem}
							>
								<CheckCircleOutlined
									color="info"
									style={{
										fontSize: listSizeIcon,
									}}
								/>
								<label>{text}</label>
								<br />
							</p>
						</div>
					))}

				{listLink && listLink[4] === 99 && (
					<p className={classes.listItem}>
						<CheckCircleOutlined
							color="info"
							style={{ marginRight: 5, width: 22 }}
						/>
						<label>
							{listLink[0]}{' '}
							{linkExterno && (
								<Link href={listLink[3]} target="blank">
									{listLink[1]}
								</Link>
							)}
							{!linkExterno && (
								<Link href={listLink[3]}>{listLink[1]}</Link>
							)}
							{listLink[2]}
						</label>
						<br />
					</p>
				)}

				{!hiddenInfo &&
					(listChilds || []).map((item, index) => (
						<Fragment key={'listItemChild' + index}>
							<p className={classes.listItem}>
								<CheckCircleOutlined
									color="info"
									style={{ marginRight: 5, width: 22 }}
								/>
								<label style={{ marginTop: 2.45 }}>
									{item.name}
								</label>
								<br />
							</p>
							{item.childs.map((child) => (
								<p
									key={'listChild' + child}
									className={classes.listItemChild}
								>
									<CheckCircleOutlined
										color="info"
										style={{ marginRight: 5, width: 18 }}
									/>
									<label style={{ marginTop: 3 }}>
										{child}
									</label>
									<br />
								</p>
							))}
						</Fragment>
					))}
				<div style={{ marginTop: 10 }}></div>
			</div>
		</Fragment>
	);
};
