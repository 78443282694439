import { Paper, Link, Grid } from '@mui/material';
import { CheckCircleOutlineRounded } from '@mui/icons-material';
import Layout from '../../components/Layout';
import IconDes from '../../assets/img/icono-descarga.png';
import { HeaderList } from '../../components/HeaderList';
import { useBitacora } from '../../hooks/useBitacora';
import { REFERENCES_PROGRAMA_DESCUENTOS_LIQUIDACION_ANTICIPADA } from '../../config/bitacora/programa-descuentos-liquidacion-anticipada';

const EarlySettlement = () => {
	const { regBitacoraReferences } = useBitacora();

	const exitOutBrowser = async () => {
		const references =
			REFERENCES_PROGRAMA_DESCUENTOS_LIQUIDACION_ANTICIPADA.GET_PDF;
		regBitacoraReferences(references);
	};

	return (
		<Layout>
			<div style={{ fontFamily: 'Geomanist', fontSize: 15 }}>
				<HeaderList
					title="Programa de descuentos por liquidación anticipada"
					list={[
						'Recuerda que debes hacer tu pago completo del monto a liquidar con	descuento y en una sola exhibición dentro del mes que está corriendo.',
						'Si no lo haces de esta manera no podremos aplicar el descuento.',
					]}
				/>

				<Paper style={{ padding: 30 }}>
					<p>
						Consulta en la sección de Saldos y Movimientos el monto
						a liquidar con descuento que tiene tu crédito, si solo
						aparece tu saldo a liquidar es que no cumples con las
						políticas del programa.
					</p>
					<p>
						<span>
							El pago se puede realizar en cualquier banco autorizado
							(el listado de bancos se puede obtener en el portal de
							Infonavit{' '}
						</span>
						<Link
							href="https://portalmx.infonavit.org.mx/wps/portal/infonavitmx/mx2/derechohabientes"
							target="_blank"
							underline="none"
						>
							https://portalmx.infonavit.org.mx
						</Link>{' '}
						<span>
							pestaña Derechohabientes, Tengo un Crédito, Pagos, En
							bancos), a nombre del Infonavit, con tu número de
							crédito (que consta de 10 dígitos) como referencia.
						</span>
					</p>
					<p style={{ width: '100%', maxWidth: 'max-content' }}>Recuerda que no participan los siguientes créditos:</p>
					<Grid container columns={12} style={{ color: '#333333' }}>
						<Grid item xs={12}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									height: 30,
								}}
							>
								<CheckCircleOutlineRounded
									style={{
										fontSize: 17,
										marginRight: 6,
										color: '#293990',
										background: 'rgba(41,57,144,0.02)',
										borderRadius: '50%',
										fontWeight: 'lighter',
									}}
								/>
								<label style={{ marginTop: 2.7 }}>
									Segundo Crédito
								</label>
							</div>
						</Grid>
						<Grid item xs={12}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									height: 30,
								}}
							>
								<CheckCircleOutlineRounded
									style={{
										fontSize: 17,
										marginRight: 6,
										color: '#293990',
										background: 'rgba(41,57,144,0.02)',
										borderRadius: '50%',
										fontWeight: 'lighter',
									}}
								/>
								<label style={{ marginTop: 2.7 }}>
									Infonavit Total
								</label>
							</div>
						</Grid>
						<Grid item xs={12}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									height: 30,
								}}
							>
								<CheckCircleOutlineRounded
									style={{
										fontSize: 17,
										marginRight: 6,
										color: '#293990',
										background: 'rgba(41,57,144,0.02)',
										borderRadius: '50%',
										fontWeight: 'lighter',
									}}
								/>
								<label style={{ marginTop: 2.7 }}>
									Infonavit Total AG
								</label>
							</div>
						</Grid>
						<Grid item xs={12}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									height: 30,
								}}
							>
								<CheckCircleOutlineRounded
									style={{
										fontSize: 17,
										marginRight: 6,
										color: '#293990',
										background: 'rgba(41,57,144,0.02)',
										borderRadius: '50%',
										fontWeight: 'lighter',
									}}
								/>
								<label style={{ marginTop: 2.7 }}>
									Mejoravit
								</label>
							</div>
						</Grid>
						<Grid item xs={12}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									height: 30,
								}}
							>
								<CheckCircleOutlineRounded
									style={{
										fontSize: 17,
										marginRight: 6,
										color: '#293990',
										background: 'rgba(41,57,144,0.02)',
										borderRadius: '50%',
										fontWeight: 'lighter',
									}}
								/>
								<label style={{ marginTop: 2.7 }}>
									Arrendavit
								</label>
							</div>
						</Grid>
						<Grid item xs={12}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									height: 30,
								}}
							>
								<CheckCircleOutlineRounded
									style={{
										fontSize: 17,
										marginRight: 6,
										color: '#293990',
										background: 'rgba(41,57,144,0.02)',
										borderRadius: '50%',
										fontWeight: 'lighter',
									}}
								/>
								<label style={{ marginTop: 2.7 }}>
									Tu casa es posible
								</label>
							</div>
						</Grid>
						<Grid item xs={12}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									height: 30,
								}}
							>
								<CheckCircleOutlineRounded
									style={{
										fontSize: 17,
										marginRight: 6,
										color: '#293990',
										background: 'rgba(41,57,144,0.02)',
										borderRadius: '50%',
										fontWeight: 'lighter',
									}}
								/>
								<label style={{ marginTop: 2.7 }}>
									Cumplir cuenta
								</label>
							</div>
						</Grid>
						<Grid item xs={12}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									height: 30,
								}}
							>
								<CheckCircleOutlineRounded
									style={{
										fontSize: 17,
										marginRight: 6,
										color: '#293990',
										background: 'rgba(41,57,144,0.02)',
										borderRadius: '50%',
										fontWeight: 'lighter',
									}}
								/>
								<label style={{ marginTop: 2.7 }}>
									Responsabilidad Compartida
								</label>
							</div>
						</Grid>
						<Grid item xs={12}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									height: 30,
								}}
							>
								<CheckCircleOutlineRounded
									style={{
										fontSize: 17,
										marginRight: 6,
										color: '#293990',
										background: 'rgba(41,57,144,0.02)',
										borderRadius: '50%',
										fontWeight: 'lighter',
									}}
								/>
								<label style={{ marginTop: 2.7 }}>
									ConstruYo
								</label>
							</div>
						</Grid>
						<Grid item xs={12}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									height: 30,
								}}
							>
								<CheckCircleOutlineRounded
									style={{
										fontSize: 17,
										marginRight: 6,
										color: '#293990',
										background: 'rgba(41,57,144,0.02)',
										borderRadius: '50%',
										fontWeight: 'lighter',
									}}
								/>
								<label style={{ marginTop: 2.7 }}>
									Fideicomisos
								</label>
							</div>
						</Grid>
					</Grid>
					<p style={{ fontWeight: 500, marginTop: 20, width: '100%', maxWidth: 'max-content' }}>
						Consulta las políticas del programa.
					</p>
					<Link
						underline="none"
						href="https://portalmx.infonavit.org.mx/wps/wcm/connect/0a3cce7a-c2a9-4db3-acf4-f0712a892ca1/Politicas_Programa_Descuento_por_Liquidacion_Anticipada.pdf?MOD=AJPERES"
						target="_blank"
						onClick={exitOutBrowser}
						style={{
							display: 'flex',
							alignItems: 'center',
							width: 320,
							marginBottom: 40,
							marginTop: 30,
						}}
					>
						<label style={{ marginTop: 6, cursor: 'pointer' }}>
							Descarga de políticas
						</label>
						<img
							src={IconDes}
							style={{ width: 24, marginLeft: 5 }}
							alt="Icono de descarga"
						/>
					</Link>
				</Paper>
			</div>
		</Layout>
	);
};

export default EarlySettlement;
