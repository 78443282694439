import React, { Fragment } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { useStyles } from './styles';
import annualReturns from '../../config/annualReturns';

export default function MyLastContributionTable() {
	const classes = useStyles();

	return (
		<Fragment>
			<p className={`${classes.containerTitle} ${classes.containerCheck}`} style={{ fontWeight: 'bold' }}>
				Tabla de rendimientos
			</p>
			<br />
			<div className={classes.headerTableTitle}>
				<p style={{ color: 'white', fontSize: 14, fontWeight: 'bold' }}>
					Rendimientos generados
				</p>
			</div>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell
								variant="head"
								className={`${classes.tableTitleHeader}`}
							>
								<p className={`${classes.noMargin} ${classes.maxContent}`}>Año</p>
							</TableCell>
							<TableCell
								variant="head"
								className={`${classes.tableTitleHeader}`}
								style={{ width: '50%' }}
							>
								<p className={`${classes.noMargin} ${classes.maxContent}`}>Tasa de rendimiento nominal anual</p>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{annualReturns.map((row, index) => (
							<TableRow
								key={index}
								sx={{
									'&:last-child td, &:last-child th': {
										border: 0,
									},
								}}
							>
								<TableCell component="th" scope="row">
									<p className={`${classes.noMargin} ${classes.maxContent}`}>{row.year}</p>
								</TableCell>
								<TableCell>
									<p className={`${classes.noMargin} ${classes.maxContent}`}>{row.tasaRen}</p>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Fragment>
	);
}
