import { useState, useEffect } from 'react';
import { useStyles } from '../../styles';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store';
import { actions } from '../../../../store/modules/session';

import Icon from '../../../../assets/img/iconsToolAccessibility/iconMask-1.svg';
import Icon2 from '../../../../assets/img/iconsToolAccessibility/iconMask-2.svg';

const ReadingMask = () => {
	const { activeMask } = useSelector((state: RootState) => state.session);
	const dispatch = useDispatch();
	const classes = useStyles();
	const [active, setActive] = useState(false);
	const [mouseY, setMouseY] = useState(0);

	const handleMouseMove = (e: any) => {
		setMouseY(e.clientY);
	};

	useEffect(() => {
		window.addEventListener("mousemove", handleMouseMove);
		return () => {
			window.removeEventListener("mousemove", handleMouseMove);
		};
	}, []);

	useEffect(() => {
		setActive(activeMask);
	}, [activeMask]);

	return (
		<div>
			<div
				className={`${classes.containerItem} ${active ? classes.itemActive : ''}`}
				onClick={() => {
					setActive(!active);
					dispatch(actions.changeMask(!active));
				}}
			>
				<div className={`${classes.iconItem} ${active ? classes.iconItemActive : ''}`}>
					<img src={active ? Icon2 : Icon} alt="" />
				</div>
				<label className={classes.labelItem}>Usar máscara de lectura</label>
			</div>
			{active && (
				<div>
					<div className={classes.readingTop} style={{ height: mouseY - 50 }}></div>
					<div className={classes.readingBottom} style={{ top: mouseY + 50 }}></div>
				</div>
			)}
		</div>
	)
}

export default ReadingMask;