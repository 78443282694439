import React, { Fragment } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Tooltip,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Error } from '@mui/icons-material';

const TwoColumnTable = ({ data = null, principal = [] }: any) => {
	const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
	return (
		<TableContainer>
			<Table>
				<TableBody>
					{principal.map(
						(column: any) =>
							column && (
								isMobile
									? <Fragment key={column.key}>
										<TableRow>
											<TableCell
												style={{
													...column.styleTitle,
													borderBottom: 'none',
													paddingBottom: 5,
												}}
											>
												<p style={{ margin: 0 }}>
													{column.title}{' '}
													{column.titleTooltip && (
														<Tooltip
															title={<p>{column.titleTooltip}</p>}
															placement="right"
															arrow
														>
															<Error color="error" fontSize="small" />
														</Tooltip>
													)}
												</p>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												style={{ ...column.styleDescription, paddingTop: 0 }}
											>
												<p style={{ margin: 0 }}>
													{data &&
														data[column.key] &&
														(column.renderValue
															? column.renderValue(
																data[column.key],
																data
															)
															: data[column.key])}
												</p>
											</TableCell>
										</TableRow>
									</Fragment>
									: <TableRow
										key={column.key}
										style={{ borderBottom: '1px solid #e0e0e0' }}
									>
										<TableCell
											style={{
												...column.styleTitle,
												borderBottom: 'none',
											}}
											align={column.alignTitle}
										>
											<p style={{ width: '100%', maxWidth: 'max-content', margin: 0 }}>
												{column.title}{' '}
												{column.titleTooltip && (
													<Tooltip
														title={<p>{column.titleTooltip}</p>}
														placement="right"
														arrow
													>
														<Error color="error" fontSize="small" />
													</Tooltip>
												)}
											</p>
										</TableCell>
										<TableCell
											style={{
												...column.styleDescription,
												borderBottom: 'none',
											}}
											align={column.alignDescription}
										>
											<p style={{ width: '100%', maxWidth: 'max-content', margin: 0 }}>
												{data &&
													data[column.key] &&
													(column.renderValue
														? column.renderValue(
															data[column.key],
															data
														)
														: data[column.key])}
											</p>
										</TableCell>
									</TableRow>
							)
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default TwoColumnTable;
