import { Bitacora, IBitacora } from "../../interfaces/bitacora";
const _bitacora = new Bitacora('132', 'carta-cancelacion-hipoteca');

export const ENDPOINTS_CARTA_DE_CANCELACION_DE_HIPOTECA: IBitacora = {
    '/adjuntar-escritura': _bitacora.Update('132100', 'Adjuntar escritura'),
    '/subir-documento-carta-cancelacion': _bitacora.Update('132101', 'Subir documento'),
    '/consulta-casos-carta-cancelacion': _bitacora.Read('132102', 'Consulta carta cancelacion'),
    '/consulta-hipoteca': _bitacora.Read('132103', 'Consulta hipoteca'),
    '/crear-carta-cancelacion': _bitacora.Create('132104', 'Crear carta cancelacion'),
    '/descargar-documento': _bitacora.Read('132105', 'Descargar documento'),
    '/descargar-documento-otx': _bitacora.Read('132106', 'Descargar documento OTX'),
};
