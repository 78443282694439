import React from 'react';
import { Divider, Link } from '@mui/material';
import checkBlue from '../../assets/img/mini-check-azul.png';
import { useStyles } from './styles';

const Questions = () => {
	const styles = useStyles();

	return (
		<>
			<br></br>
			<Divider />
			<br></br>
			<div className={styles.divButton}>
				<p className={styles.noMargin} style={{ fontWeight: 'bold' }}>
					Preguntas frecuentes
				</p>
			</div>
			<br></br>
			<p className={styles.txtBlueSmall}>
				¿Dónde puedo realizar el pago de mis Aportaciones Extraordinarias?
			</p>
			<p className={`${styles.noMargin} ${styles.txtInfoBlack}`}>
				Una vez generada tu ficha puedes realizar el pago de tus Aportaciones Extraordinarias a través de los siguientes medios:
			</p>
			<br></br>
			<div className={styles.checkHeader}>
				<img
					src={checkBlue}
					className={styles.checkHeaderImg}
					alt="ícono de check"
				/>
				<p className={styles.checkHeaderText}>
					Utilizando{' '}
					<Link
						target="_blank"
						rel="noopener"
						href={'https://www.codi.org.mx/'}
					>
						CoDi®¹
					</Link>
					{' '}vía notificación a tu número celular o utilizando el código QR².
				</p>
			</div>
			<div className={styles.checkHeader}>
				<img
					src={checkBlue}
					className={styles.checkHeaderImg}
					alt="ícono de check"
				/>
				<p className={styles.checkHeaderText}>
					A través de un depósito referenciado. Las opciones para realizar el depósito se
					indican en la ficha de pago que será enviada a tu correo electrónico registrado en Mi
					Cuenta Infonavit; éstas incluyen ventanillas bancarias, cajeros inteligentes,
					aplicaciones móviles bancarias y tiendas de autoservicio.
				</p>
			</div>
			<div className={styles.checkHeader}>
				<img
					src={checkBlue}
					className={styles.checkHeaderImg}
					alt="ícono de check"
				/>
				<p className={styles.checkHeaderText}>
					Vía SPEI utilizando la CLABE interbancaria directamente en la Banca en línea o la
					aplicación móvil de tu Banco.
				</p>
			</div>
			<div className={styles.checkHeader}>
				<img
					src={checkBlue}
					className={styles.checkHeaderImg}
					alt="ícono de check"
				/>
				<p className={styles.checkHeaderText}>
					Por medio del pago de Cheque en línea opción habilitada en Mi Cuenta Infonavit
					para trabajadores con cuenta de ahorro en BBVA.
				</p>
			</div>
			<p className={`${styles.noMargin} ${styles.txtInfoBlack}`}>¹El pago se realizará a través de tu aplicación bancaria, es necesario registrar tu cuenta en CoDi®.</p>
			<p className={`${styles.noMargin} ${styles.txtInfoBlack}`}>²El pago utilizando CoDi® únicamente se realiza a través de Mi Cuenta Infonavit.</p>
			<br></br>
			<p className={styles.txtBlueSmall}>
				¿Quiénes pueden realizar Aportaciones Extraordinarias?
			</p>
			<p className={`${styles.noMargin} ${styles.txtInfoBlack}`}>
				Cualquier derechohabiente que tenga Número de Seguridad Social (NSS), sin importar si tienen o no una relación laboral formal.
			</p>
			<br></br>
			<p className={styles.txtBlueSmall}>
				¿Puedo realizar más de una Aportación Extraordinaria durante el mismo mes?
			</p>
			<p className={`${styles.noMargin} ${styles.txtInfoBlack}`}>
				Sí, puedes realizar el número de Aportaciones Extraordinarias que consideres
				conveniente, siempre y cuando la suma de los montos de todas ellas en el mes no exceda
				el 5% de 25 Salarios Mínimos Vigentes Mensuales. Consulta previamente el Importe en
				pesos de este límite.
			</p>
			<br></br>
			<p className={styles.txtBlueSmall}>
				¿Cómo puedo verificar que mi aportación extraordinaria se realizó correctamente?
			</p>
			<p className={`${styles.noMargin} ${styles.txtInfoBlack}`}>
				Puedes verificar que tu Aportación Extraordinaria se registró adecuadamente en{' '}
				<Link
					target="_blank"
					rel="noopener"
					href={'https://micuenta.infonavit.org.mx/'}
				>
					Mi Cuenta Infonavit
				</Link>
				, en la sección Mi Ahorro, en el servicio Resumen de Movimientos de Mi
				Ahorro, ahí se verá reflejado tu pago en un plazo máximo de 72 horas.
			</p>
			<p className={`${styles.noMargin} ${styles.txtInfoBlack}`}>
				Si después de este tiempo no se ha visto reflejado llama a Infonatel al 800 008 3900 o
				acude al Centro de Servicio Infonavit más cercano.
			</p>
			<p className={`${styles.noMargin} ${styles.txtInfoBlack}`}>
				<b>Importante:</b> Considera que el total de tus Aportaciones Extraordinarias solo se verán
				reflejadas en Mi Cuenta Infonavit, en la sección Mi Ahorro, en el Resumen de
				Movimientos; Estas aportaciones no se mostrarán en el apartado de “Saldo de Vivienda”
				del estado de cuenta de tu Afore.
			</p>
			<br></br>
			<p className={styles.txtBlueSmall}>
				¿Puedo recuperar las Aportaciones Extraordinarias que realicé?
			</p>
			<p className={`${styles.noMargin} ${styles.txtInfoBlack}`}>
				Sí, conforme lo establece el artículo 40 de la Ley del Infonavit y cumpliendo con los
				requisitos establecidos que puedes consultar en{' '}
				<Link
					target="_blank"
					rel="noopener"
					href={'https://portalmx.infonavit.org.mx/wps/portal/infonavitmx/mx2/derechohabientes/mi_ahorro/retiro_ahorro/!ut/p/z1/pZDLDoIwFEQ_qVOEtiwbGhSJlYaI2I1hZZooujB-v8IOHy2Jd3eTOZkHsaQltu8e7tTd3bXvzq__YNmRLYFVFlO95XkGkyes3qCCqCnZTwWiERJGpzoq2SJaS06shxfsnf80GHj8OImQP5vnn6YqpqVAVFENmMZQVSiOBPE83hPwr_7Cn3_c304lXxYYBb6KoZFtqEUgAye3y264Fq5wT4Kf73M!/dz/d5/L2dBISEvZ0FBIS9nQSEh/'}
				>
					Retiro de mi ahorro | Infonavit
				</Link>
				.
			</p>
			<p className={`${styles.noMargin} ${styles.txtInfoBlack}`}>
				El Instituto, te las devuelve junto con los rendimientos generados hasta el momento de su
				devolución.
			</p>
			<br></br>
			<p className={styles.txtBlueSmall}>
				¿Mis Aportaciones Extraordinarias son deducibles para efectos del
				Impuesto Sobre la Renta (ISR)?
			</p>
			<p className={`${styles.noMargin} ${styles.txtInfoBlack}`}>
				No. Actualmente no se pueden deducir del Impuesto Sobre la Renta en tu declaración
				anual de impuestos, ya que las Aportaciones Extraordinarias a la Subcuenta de Vivienda
				no se encuentran contempladas en el artículo 185 de la Ley del Impuesto Sobre la Renta
				(LISR) ni en el artículo 305 del Reglamento de la LISR.
			</p>
			<br></br>
		</>
	);
};

export default Questions;
