import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../../components/Button';
import { useEscrituras } from '../../hooks/useEscrituras';
import { CODE_STATUS, CODIGO_RAZON_EXITOSO } from '../utils';
import {
	PROCESS_PAGE_STATUS,
	WRITING_REQUEST_STATUS,
} from '../utils/pagesStatus';
import { useStyles } from '../styles/buttons-delivery-styles';

interface IProps {
	setPage: (arg: number) => void;
	nextProcess: (arg: number) => void;
}

const ButtonsDelivery = ({ setPage, nextProcess }: IProps) => {
	const { escrituras, setEscrituras } = useEscrituras();
	const navigate = useNavigate();
	const styles = useStyles();

	const consultCita = () => {
		setEscrituras({
			...escrituras,
			consultarCita: true,
		});
		nextProcess(PROCESS_PAGE_STATUS.CREATE_CESI);
	};

	const returnMain = () => {
		setEscrituras({
			...escrituras,
			restablecerProceso: true,
		});
		setPage(WRITING_REQUEST_STATUS.HOME);
	};

	return (
		<Box className={styles.containerButtons}>
			{(escrituras.estatus === CODE_STATUS.ESCRITURA_ENTREGADA_SOL ||
				(escrituras.estatus === CODE_STATUS.CERRADO &&
					escrituras?.razonEstatus === CODIGO_RAZON_EXITOSO)) && (
				<Box className={styles.buttonItem}>
					<CustomButton
						label={'Carta cancelación de hipoteca'}
						variant={'outlined'}
						onClick={() =>
							navigate('/mi-credito/carta-cancelacion-hipoteca')
						}
					/>
				</Box>
			)}

			{escrituras.estatus === CODE_STATUS.CERRADO &&
				escrituras?.razonEstatus !== CODIGO_RAZON_EXITOSO && (
					<Box className={styles.buttonItem}>
						<CustomButton
							label={'Solicitar entrega de escrituras'}
							variant={'outlined'}
							onClick={returnMain}
						/>
					</Box>
				)}

			<Box className={styles.buttonItem}>
				<CustomButton
					label={'Salir'}
					variant={
						escrituras.estatus === CODE_STATUS.ABIERTO_CITA
							? 'text'
							: escrituras.estatus ===
							  CODE_STATUS.ESCRITURA_ENTREGADA_SOL
							? 'outlined'
							: 'solid'
					}
					onClick={() => navigate('/inicio')}
				/>
			</Box>

			{escrituras.estatus === CODE_STATUS.ABIERTO_CITA && (
				<>
					<Box className={styles.buttonItem}>
						<CustomButton
							label={'Reprogramar cita'}
							variant={'outlined'}
							onClick={() =>
								nextProcess(PROCESS_PAGE_STATUS.CREATE_CESI)
							}
						/>
					</Box>

					<Box className={styles.buttonItem}>
						<CustomButton
							label={'Consultar cita programada'}
							onClick={consultCita}
						/>
					</Box>
				</>
			)}
		</Box>
	);
};

export default ButtonsDelivery;
