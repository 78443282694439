interface ISubEstatus {
	code: string;
	position: number;
	label: string;
	description: string | JSX.Element;
}

interface IJustify {
	label: string;
	description: string | JSX.Element;
}

interface IEstatus {
	position: number;
	label: string;
	description: string | JSX.Element;
	tantos: number[];
	subEstatus?: ISubEstatus[];
	justify?: {
		[key: string]: IJustify;
	};
}

export const ESTATUS: { [key: string]: IEstatus } = {
	E0002: {
		position: 2,
		label: 'Inicio de la Atención',
		tantos: [0, 1, 2],
		description:
			'Recibimos tu solicitud y la estamos revisando. Para que conozcas el avance ingresa nuevamente a Mi Cuenta Infonavit en 5 días hábiles.',
	},
	E0003: {
		position: 3,
		label: 'En espera de confirmación',
		tantos: [0, 1, 2],
		description:
			'Tu solicitud se encuentra en revisión. Te sugerimos revisar tu caso en 15 días hábiles. El tiempo puede variar de acuerdo a la situación de crédito.',
	},
	E0004: {
		position: 4,
		label: 'Escritura identificada',
		tantos: [2],
		description:
			'Hemos localizado tu escritura y la enviaremos al Centro de Servicio Infonavit (Cesi) que seleccionaste al momento de realizar tu solicitud. Para que conozcas el avance del trámite ingresa nuevamente a Mi Cuenta Infonavit en 5 días hábiles.',
	},
	E0005: {
		position: 5,
		label: 'Solicitud de Cotejo',
		tantos: [1],
		description: '',
		subEstatus: [
			{
				code: '001',
				position: 1,
				label: 'Archivo para cotejo encontrado',
				description:
					'Estamos atendiendo tu solicitud. El tiempo estimado en esta etapa del proceso es de 5 días hábiles, este puede variar de acuerdo a la situación de tu escritura. Para consultar el avance de tu solicitud ingresa  a Mi Cuenta Infonavit.',
			},
			{
				code: '002',
				position: 2,
				label: 'Validación de solicitud',
				description:
					'Tus documentos están en revisión para su envió con un notario. Para que conozcas el avance ingresa nuevamente a Mi Cuenta Infonavit en 2 días hábiles.',
			},
			{
				code: '003',
				position: 3,
				label: 'Enviada para cotejo',
				description:
					'Tu escritura se asigno a un notario para su análisis. Para que conozcas el avance ingresa nuevamente a Mi Cuenta Infonavit en 2 días hábiles.',
			},
			{
				code: '004',
				position: 4,
				label: 'En cotejo con notario',
				description:
					'Tu escritura esta siendo analizada por un notario. Para que conozcas el avance de tu solicitud ingresa nuevamente a Mi Cuenta Infonavit en 8 días hábiles.',
			},
			{
				code: '005',
				position: 5,
				label: 'Escritura cotejada',
				description:
					'Tu escritura fue analizada y verificada por un notario, se regreso al Infonavit para su validación. Para que conozcas el avance ingresa nuevamente a Mi Cuenta Infonavit en 2 días hábiles.',
			},
		],
	},
	E0006: {
		position: 6,
		label: 'Validación de escritura',
		tantos: [1],
		description:
			'Recibimos tu escritura por parte del notario y la estamos validando. El tiempo estimado en esta etapa del proceso es de 2 días hábiles. El tiempo puede variar de acuerdo a la situación de crédito.',
		// subEstatus: [
		// 	{
		// 		id: '006',
		// 		position: 6,
		// 		label: 'VALIDACIÓN DE ESCRITURA',
		// 		description: '',
		// 	},
		// ],
	},
	E0007: {
		position: 7,
		label: 'Registro y Digitalización',
		tantos: [1],
		description:
			'Recibimos tu escritura en el Archivo Nacional de Expedientes de Crédito (ANEC) del Infonavit para su registro y digitalización. El tiempo estimado en esta etapa del proceso es de 5 días hábiles. El tiempo puede variar de acuerdo a la situación de credito.',
		// subEstatus: [
		// 	{
		// 		id: '006',
		// 		position: 6,
		// 		label: 'REGISTRO Y DIGITALIZACIÓN',
		// 		description: '',
		// 	},
		// ],
	},
	E0008: {
		position: 8,
		label: 'En espera de documentos',
		tantos: [0],
		description:
			'Cuentas con 5 días hábiles para adjuntar tus documentos, es importante que adjuntes tus documentos para agilizar tu trámite. De no adjuntarlos en este tiempo tu caso se turnará con un especialista.',
	},
	E0009: {
		position: 9,
		label: 'Solicitud de Regularización',
		tantos: [0],
		description: (
			<>
				<p style={{ marginTop: 0 }}>
					Tu solicitud se turnó al área de regularización de
					escrituras para su resolución. El tiempo estimado en esta
					etapa del proceso es de 15 días hábiles. El tiempo puede
					variar de acuerdo a la situación de crédito. Es importante
					mencionar que el tiempo de atención dependerá del tipo de
					solución:
				</p>
				<ul style={{ marginTop: 0 }}>
					<li>
						Trámite administrativo ante el Registro Público de la
						Propiedad (Infonavit asignara un despacho para apoyarte
						en el trámite, sin costo para ti): máximo 21 meses
					</li>
					<li>
						Trámite jurídico para verificar la situación de tu
						escritura (Infonavit asignara un despacho jurídico para
						apoyarte en el trámite, sin costo para ti): máximo 36
						meses
					</li>
				</ul>
			</>
		),
	},
	E0010: {
		position: 10,
		label: 'Expediente Asignado',
		tantos: [0],
		description: '',
		subEstatus: [
			{
				code: '001',
				position: 1,
				label: 'Expediente Asignado a Despacho Jurídico',
				description: (
					<>
						Tu solicitud se asignó al despacho [Nombre del Despacho
						Jurídico] para que te apoye en la resolución de tu
						trámite. El tiempo estimado en esta etapa del proceso es
						de 15 días hábiles. El tiempo puede variar de acuerdo a
						la situación de crédito.
						<p>
							Personal del despacho te contactará para solicitar
							más información que utilizará en tu trámite.
						</p>
					</>
				),
			},
			{
				code: '002',
				position: 2,
				label: 'Buscando antecedente registral',
				description:
					'Tu tramite esta en revisión de antecedentes en el Registro Público de la Propiedad. Para que conozcas el avance ingresa nuevamente a Mi Cuenta Infonavit en 75 días hábiles.',
			},
			{
				code: '003',
				position: 3,
				label: 'Validando datos',
				description:
					'Se están validando tus datos para confirmar el tratamiento de tu tramite, en caso de ser necesario se pondrá en contacto contigo [Nombre del despacho jurídico] para solicitarte información adicional. Para que conozcas el avance ingresa nuevamente a Mi Cuenta Infonavit en 20 días hábiles.',
			},
			{
				code: '004',
				position: 4,
				label: 'Determinar estrategia de regularización',
				description:
					'El despacho jurídico esta validando el tipo de solución de tu tramite. Para que conozcas el avance ingresa nuevamente a Mi Cuenta Infonavit en 20 días hábiles.',
			},
		],
	},
	E0011: {
		position: 11,
		label: 'Regularización Administrativa',
		tantos: [0],
		description: '',
		subEstatus: [
			{
				code: '005',
				position: 1,
				label: 'En Regularización por Vía Administrativa',
				description:
					'Tu trámite se resolverá por la vía administrativa ante el Registro Público de la Propiedad. El tiempo estimado en esta etapa del proceso es de 40 días hábiles. El tiempo puede variar de acuerdo a la situación de crédito.',
			},
			{
				code: '006',
				position: 2,
				label: 'Elaboración proyecto de escritura',
				description:
					'Se elaboro el proyecto de tu escritura. Para que conozcas el avance ingresa nuevamente a Mi Cuenta Infonavit en 40 días hábiles.',
			},
			{
				code: '007',
				position: 3,
				label: 'Validando datos de la escritura',
				description:
					'Los datos de tu escritura están siendo validados. Para que conozcas el avance ingresa nuevamente a Mi Cuenta Infonavit en 30 días hábiles.',
			},
			{
				code: '008',
				position: 4,
				label: 'Inscribiendo instrumento ante el RPP',
				description:
					'Tu escritura esta en proceso de inscripción ante el Registro Publico de la Propiedad (RPP). Para que conozcas el avance ingresa nuevamente a Mi cuenta Infonavit en 30 días hábiles.',
			},
		],
	},
	E0012: {
		position: 12,
		label: 'Regularización Judicial',
		tantos: [0],
		description: '',
		subEstatus: [
			{
				code: '010',
				position: 1,
				label: 'En regularización por vía Judicial',
				description:
					'Tu trámite se realizará por la vía jurídica con el apoyo de [Nombre del Despacho Jurídico], sin costo para ti. El tiempo estimado en esta etapa del proceso es de 20 días hábiles. El tiempo puede variar de acuerdo a la situación de crédito.',
			},
			{
				code: '011',
				position: 2,
				label: 'Verificación de datos',
				description:
					'La información de tu crédito se esta validando. Para que conozcas el avance ingresa nuevamente a Mi Cuenta Infonavit en 20 días hábiles.',
			},
			{
				code: '012',
				position: 3,
				label: 'Gestión de documentos',
				description:
					'Tu tramite esta en la etapa de verificación de documentos. Para que conozcas el avance ingresa nuevamente a Mi cuenta Infonavit en 30 días hábiles.',
			},
			{
				code: '013',
				position: 4,
				label: 'Integrar documentación',
				description:
					'Tu tramite esta en la etapa de integración de los documentos. Para que conozcas el avance ingresa nuevamente a Mi Cuenta Infonavit en 30 días hábiles.',
			},
			{
				code: '014',
				position: 5,
				label: 'Presentar Demanda',
				description:
					'La demanda se realizo en las instancias correspondientes. Para que conozcas el avance ingresa nuevamente a Mi Cuenta Infonavit en 60 días hábiles.',
			},
			{
				code: '015',
				position: 6,
				label: 'Emplazamiento de la demanda',
				description:
					'Se han presentado los documentos para tu tramite jurídico. Para que conozcas el avance ingresa nuevamente a Mi Cuenta Infonavit en 90 días hábiles.',
			},
			{
				code: '016',
				position: 7,
				label: 'Desahogo de pruebas',
				description:
					'Se ha realizado la apertura de pruebas para tu tramite jurídico. Para que conozcas el avance ingresa nuevamente a Mi Cuenta Infonavit en 60 días hábiles.',
			},
			{
				code: '017',
				position: 8,
				label: 'Ejecución de sentencia',
				description:
					'Se ha ejecutado el dictamen de sentencia de tu tramite de jurídico. Para que conozcas el avance ingresa nuevamente a Mi cuenta Infonavit en 90 días hábiles.',
			},
			{
				code: '006',
				position: 9,
				label: 'Elaboración proyecto de escritura',
				description:
					'Se elaboro el proyecto de tu escritura. Para que conozcas el avance ingresa nuevamente a Mi Cuenta Infonavit en 40 días hábiles.',
			},
			{
				code: '007',
				position: 10,
				label: 'Validando datos de la escritura',
				description:
					'Los datos de tu escritura están siendo validados. Para que conozcas el avance ingresa nuevamente a Mi Cuenta Infonavit en 30 días hábiles.',
			},
			{
				code: '008',
				position: 11,
				label: 'Inscribiendo instrumento ante el RPP',
				description:
					'Tu escritura esta en proceso de inscripción ante el Registro Publico de la Propiedad (RPP). Para que conozcas el avance ingresa nuevamente a Mi cuenta Infonavit en 30 días hábiles.',
			},
		],
	},
	E0013: {
		position: 13,
		label: 'Escrituras Enviadas al ANEC',
		tantos: [0],
		description:
			'Tu escritura esta en camino al Archivo Nacional de Expedientes de Crédito (ANEC) del Infonavit para su resguardo. El tiempo estimado en esta etapa del proceso es de 25 días hábiles. El tiempo puede variar de acuerdo a la situación de crédito.',
		// subEstatus: [
		// 	{
		// 		id: '009',
		// 		position: 9,
		// 		label: 'Finaliza seguimiento',
		// 		description: '',
		// 	},
		// ],
	},
	E0014: {
		position: 14,
		label: 'Recepción en el ANEC',
		tantos: [0],
		description:
			'Recibimos tu escritura en el Archivo Nacional de Expedientes de Crédito (ANEC) del Infonavit para su resguardo. El tiempo estimado en esta etapa del proceso es de 5 días hábiles. El tiempo puede variar de acuerdo a la situación de crédito.',
	},
	E0015: {
		position: 15,
		label: 'Preparación de envío al Cesi',
		tantos: [0, 1, 2],
		description:
			'Estamos preparando tu escritura para enviarla al Centro de Servicio Infonavit (Cesi) que seleccionaste al momento de realizar tu solicitud. Para que conozcas el avance del trámite y la fecha de envío ingresa nuevamente a Mi Cuenta Infonavit en 10 días hábiles.',
	},
	E0016: {
		position: 16,
		label: 'Enviado a Cesi',
		tantos: [0, 1, 2],
		description:
			'Tu escritura está en camino al Centro de Servicio Infonavit (Cesi) que seleccionaste al momento de realizar tu solicitud. Te informaremos por correo electrónico cuando puedas pasar a recogerla. Para que confirmes la fecha de recepción de tus escrituras en el Cesi ingresa nuevamente a Mi Cuenta Infonavit en 10 días hábiles.',
	},
	E0017: {
		position: 17,
		label: 'Escrituras recibidas en Cesi',
		tantos: [0, 1, 2],
		description: (
			<>
				<p style={{ margin: '10px 0px 0px 0px', fontWeight: 500 }}>
					Solicitar Cita
				</p>
				<p style={{ margin: 0 }}>
					Ya puedes programar tu cita para acudir al Centro de
					Servicio Infonavit (Cesi) que seleccionaste al momento de
					realizar tu solicitud para recibir tus escrituras. Una vez
					que recibas esta notificación, recuerda que tienes 30 días
					hábiles para que te entreguen tu escritura, de lo contrario
					se regresará al Archivo Nacional de Expedientes de Crédito
					[ANEC] del Infonavit y tendrás que iniciar una vez más tu
					trámite.
				</p>
				<p style={{ margin: '10px 0px 0px 0px', fontWeight: 500 }}>
					Para entregártela:
				</p>
				<p style={{ margin: 0, fontWeight: 500 }}>
					1. Haz una cita mediante cualquiera de nuestros siguientes
					canales de atención:
				</p>
				<p style={{ margin: 0 }}>
					a) Desde el Portal Infonavit, en la sección de Mi Cuenta.
				</p>
				<p style={{ margin: 0 }}>
					b) Comunicándote a Infonatel al 55 9171 5050 en la Ciudad de
					México o al 800 008 3900 desde cualquier parte del país.
				</p>
				<p style={{ margin: 0 }}>
					c) Acudiendo a tu Centro de Servicio Infonavit (Cesi) más
					cercano.
				</p>

				<p style={{ margin: '10px 0px 0px 0px', fontWeight: 500 }}>
					2. Presenta la siguiente documentación en original y copia,
					según sea el caso:
				</p>
				<p style={{ margin: 0 }}>
					a) si eres el titular, tu identificación oficial vigente con
					fotografía (INE, IFE o cédula profesional.
				</p>
				<p style={{ margin: 0 }}>
					b) Si no puedes recoger tu escritura personalmente, puedes
					nombrar a un representante el cual debe llevar una carta
					poder notariada en donde le cedas la facultad para
					recogerla, y la identificación oficial vigente con
					fotografía (INE, IFE o cédula profesional) de ambas
					personas.
				</p>
				<p style={{ margin: 0 }}>
					c) Si el titular no puede valerse por sí mismo, es decir,
					está en un estado de interdicción, el tutor debe presentar
					la sentencia donde se le otorgue carácter de tutor y el
					acuerdo que decreta que ha causado ejecutoria, así como la
					identificación oficial vigente con fotografía [INE, IFE o
					cédula profesional) de ambas personas.
				</p>
				<p style={{ margin: 0 }}>d) Si el titular falleció:</p>
				<ul style={{ marginTop: 0 }}>
					<li>
						En caso de sucesión testamentaria o intestamentaria, la
						persona que solicite la escritura debe presentar el
						documento judicial o notarial en el que conste su
						designación, aceptación, protesta y discernimiento del
						cargo de albacea, así como su identificación oficial
						vigente con fotografía (INE, IFE o cédula profesional).
					</li>
					<li>
						Si se trata de un beneficiario designado después de un
						juicio, la persona que solicite debe presentar una copla
						certificada del laudo, donde se le reconozca como
						beneficiario, así como su identificación oficial vigente
						con fotografía (INE, IFE o cédula profesional).
					</li>
				</ul>

				<p>
					<b>3. Si tienes un crédito conyugal</b>, debes presentar la
					identificación oficial vigente con fotografía [INE, IFE o
					cédula profesional] de ambos cónyuges. La escritura la podrá
					recoger cualquiera de los dos, ya sea el titular o cotitular
					del crédito.
				</p>
			</>
		),
	},
	E0018: {
		position: 18,
		label: 'Solicitar Cita',
		tantos: [0, 1, 2],
		description:
			'Haz tu cita para recibir tus escrituras. Cuentas con 30 días hábiles a partir del aviso; de lo contrario, tu escritura se regresará al Archivo Nacional de Expedientes de Crédito (ANEC) del Infonavit.',
	},
	E0019: {
		position: 19,
		label: 'Abierto con Cita',
		tantos: [0, 1, 2],
		description:
			'Te confirmamos que tu cita quedó programada. Recuerda que es personal y que debes presentar tu identificación oficial vigente con fotografía (INE, IFE o cédula profesional), como requisito indispensable para efectuar tu trámite. Te recomendamos llegar con 10 minutos de anticipación para que no corras el riesgo de perder tu cita y tengas que programar una nueva. Es importante que si por alguna razón no puedes acudir a tu cita la reprogrames o la canceles.',
	},
	E0020: {
		position: 20,
		label: 'Escritura entregada al solicitante',
		tantos: [0, 1, 2],
		description:
			'Tu escritura ha sido entregada. Si liquidaste tu crédito, te sugerimos iniciar tu trámite de Cancelación y Liberación de Hipoteca.',
	},
	E0021: {
		position: 21,
		label: 'Envío de acuse a ANEC',
		tantos: [0, 1, 2],
		description:
			'"¡Ya cuentas con tu escritura! Tu trámite ha concluido, generándose el acuse de recibo correspondiente y el documento de identificación de la persona que la recibió. Si tienes dudas o para más información, comunícate a Infonatel al 55 9171 5050 en la Ciudad de México o al 800 008 3900 desde cualquier parte del país.',
	},
	E0022: {
		position: 22,
		label: 'Preparación de dev escrituras',
		tantos: [0, 1, 2],
		description: 'Texto pendiente', // Falta texto por anexar
	},
	E0023: {
		position: 23,
		label: 'Devolución escrituras al ANEC',
		tantos: [0, 1, 2],
		description:
			'El tiempo para acudir al Cesi y recibir tu escritura concluyó, esta en proceso de devolución al Archivo Nacional de Expedientes de Crédito (ANEC) del Infonavit. Recibirás una notificación para iniciar nuevamente tu trámite para solicitar la entrega de escritura.',
	},
	E0024: {
		position: 24,
		label: 'Cerrado',
		tantos: [0, 1, 2],
		description: '',
		justify: {
			ZN01: {
				label: 'Escritura Entregada',
				description: (
					<>
						¡Felicidades, ya cuentas con tu escritura! Tu caso ha
						concluido, generándose el acuse de recibo
						correspondiente y el documento de identificación de la
						persona que la recibió. Para más información, comunícate
						a Infonatel al 55 9171 5050 en la Ciudad de México o al
						800 008 3900 desde cualquier parte del país.
					</>
				),
			},
			ZN02: {
				label: 'No acudió a su cita',
				description: '',
			},
			ZN03: {
				label: 'No le corresponde',
				description: '',
			},
			ZN04: {
				label: 'Escrituras Extraviadas',
				description: '',
			},
			ZN05: {
				label: 'No agendó su cita',
				description: '',
			},
			ZN06: {
				label: 'No existe seguimiento',
				description: (
					<>
						Como no hubo seguimiento a tu solicitud, tu caso ha
						concluido. Para solicitar tu escritura, deberás iniciar
						una vez más tu trámite mediante cualquiera de nuestros
						siguientes canales de atención: • Desde el Portal de
						Infonavit, en la sección de Mi cuenta Infonavit. •
						Comunicándote al Infonatel al 55 9171 5050 en la Ciudad
						de México o al 800 008 3900 desde cualquier parte del
						país. • Acudiendo al Centro de servicio Infonavit (Cesi)
						de tu entidad.
					</>
				),
			},
			ZN07: {
				label: 'Sistema no disponible',
				description: '',
			},
		},
	},
};
