export interface ResponseCaso {
	code: string;
	message: string;
	data: {
		tipificacion: string;
		caso: {
			id: string;
			estatus: string;
			noCaso: string;
			fecha: string;
			fechaEstatus: string;
			descripcion: string;
			tipificacion: string;
			estatusDetalle: {
				descripcion: string;
				estatus: string;
			};
			casoCerradoDetalle: {
				razon: string;
				id: string;
				descripcion: string;
			};
		};
		casoValido: string;
	};
}
export interface ResponseSici {
	code: string;
	message: string;
}
export interface ResponseEmisoras {
	code: string;
	message: string;
	data: {
		emisoras: string[];
	};
}
export interface ResponseAls {
	code: string;
	message: string;
	data: {
		estatusCreditoCorrecto: string;
		estatusCreditoIncorrecto: string;
		tipoAclaracionList: [
			{
				tipoAclaracion: string;
				cantPagosEncontrados: string;
				detallePago: [
					{
						ubicacionPago: string;
					}
				];
			}
		];
	};
}
export const DataPagos = [
	{
		bimestre: '1 (enero-febrero)',
		fechaPago: '1 al 17 de marzo',
		mesPago: 'Abril',
	},
	{
		bimestre: '2° (marzo-abril)',
		fechaPago: '1 al 17 de mayo',
		mesPago: 'Junio',
	},
	{
		bimestre: '3° (mayo-junio)',
		fechaPago: '1 al 17 de julio',
		mesPago: 'Agosto',
	},
	{
		bimestre: '4° (julio-agosto)',
		fechaPago: '1 al 17 de septiembre',
		mesPago: 'Octubre',
	},
	{
		bimestre: '5° (septiembre-octubre)',
		fechaPago: '1 al 17 de noviembre',
		mesPago: 'Diciembre',
	},
	{
		bimestre: '6° (noviembre-diciembre)',
		fechaPago: '1 al 17 de enero del siguiente año',
		mesPago: 'Febrero',
	},
];
