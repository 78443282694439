import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		containerCenter: {
			display: 'flex',
			justifyContent: 'center',
			marginBottom: '-10px',
			position: 'relative',
			paddingBottom: 18,
			'&:before': {
				position: 'absolute',
				content: `''`,
				width: '100%',
				borderBottom: '1px solid #f2f2f2',
				left: 0,
				bottom: 0,
			}
		},

		hrColor: {
			borderTop: ' 1px solid #E1E3E6',
		},

		fuenteRed: {
			color: 'red',
			fontSize: 16,
			'&:hover': {
				cursor: 'pointer',
			},
		},
		BoxCell3: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			[theme.breakpoints.down('md')]: {
				alignItems: 'start',
			},
			[theme.breakpoints.down('sm')]: {
				alignItems: 'start',
			},
		},
		BoxCell3Link: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'start',
		},
		BoxCell3Container: {
			display: 'flex',
			flexDirection: 'row',
			'& > div': {
				width: '50%',
			},
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
				'& > div': {
					width: '100%',
					padding: '5px 0'
				},
			},
		},
		TextBlack_H4: {
			fontWeight: 'bold',
			fontSize: 14,
		},
		TextBlack_H3: {
			fontWeight: 'bold',
			fontSize: 16,
		},
		Text_H3: {
			fontFamily: 'Roboto',
			// fontSize: '0.8rem',
			lineHeight: 1,
			fontSize: 14,
			letterSpacing: 0.00938,
		},
	})
);
