import { IAlert } from '../../../interfaces/alert';

export interface IUserDataRegister {
	nss: string;
	curp: string;
	rfc: string;
	password: string;
	email: string;
	phone: string;
	byPoE: string;
	name: string;
}

export interface IUserData {
	nombre: string;
	apPaterno: string;
	apMaterno: string;
	curp: string;
	rfc: string;
	fechaNacimiento: string;
	sexo: string;
}

export interface IRegisterViewsProps {
	onContinue: () => void;
	goBack: () => void;
	createCaso: () => void;
	infoUser: string;
	hasError: IAlert;
	onCloseError: () => void;
	dataUser: IUserData;
	messageVerification: string;
}

export interface IContactData {
	onChange: (name: string, value: string | boolean) => void;
	onContinue: () => void;
	onCloseError: () => void;
	hasError: IAlert;
	userData?: IUserDataRegister;
}
export interface ICredentials {
	phone: string;
	phone1: string;
	email: string;
	email1: string;
}
export interface IPasswordData {
	onChange: (name: string, value: string | boolean) => void;
	onContinue: () => void;
	onCloseError: () => void;
	hasError: IAlert;
}
export interface IPersonalData {
	onChange: (name: string, value: string | boolean) => void;
	onContinue: () => void;
	onCloseError: () => void;
	onContinueNSS?: (value: number) => void;
	hasError: IAlert;
	setDataUser: (data: IUserData) => void;
}
export interface ICredentialsPersonalData {
	nss: string;
	rfc: string;
	curp: string;
}

export const defaultDataRegister: IUserDataRegister = {
	nss: '',
	curp: '',
	rfc: '',
	password: '',
	email: '',
	phone: '',
	byPoE: '',
	name: '',
};
export const defaultUserData: IUserData = {
	nombre: '',
	apPaterno: '',
	apMaterno: '',
	curp: '',
	rfc: '',
	fechaNacimiento: '',
	sexo: '',
};
export const defaultUserCredentialsData: ICredentials = {
	phone: '',
	phone1: '',
	email: '',
	email1: '',
};
