import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	containerCalenderText: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		paddingTop: 30,

		[theme.breakpoints.down('md')]: {
			paddingTop: 20,
		},
	},
	labelTitle: {
		marginBottom: 15,
		fontWeight: 500,
		color: '#333333',
	},
	ulList: {
		margin: 0,
		padding: 0,
		listStyle: 'none',
		marginBottom: 0,

		[theme.breakpoints.down('md')]: {
			marginBottom: 20,
		},
	},
	spanTitle1: {
		width: 10,
		height: 10,
		border: '1px solid #293990',
		background: '#293990',
		borderRadius: '50%',
		display: 'inline-block',
		marginRight: 5,
	},
	spanTitle2: {
		width: 10,
		height: 10,
		border: '1px solid #3ABE00',
		background: '#3ABE00',
		borderRadius: '50%',
		display: 'inline-block',
		marginRight: 5,
	},
	spanTitle3: {
		width: 10,
		height: 10,
		border: '1px solid #FF1D1D',
		background: '#FF1D1D',
		borderRadius: '50%',
		display: 'inline-block',
		marginRight: 5,
	},
}));
