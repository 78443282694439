import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		display: 'flex',
		alignItems: 'flex-start !important',
		margin: '0px 0px 5px !important',
		width: '100%',
		overflow: 'hidden',
	},
	label: {
		margin: 0,
		color: '#7E8094',
		fontSize: 14,
		'&>.label-required': {
			color: theme.palette.primary.main,
		},
	},
	labelRequired: {
		color: theme.palette.primary.main,
	},
	helperText: {
		'&>p': {
			margin: 0,
			display: 'inline'
		}
	}
}));
