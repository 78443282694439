// Dependencies
import React from 'react';
import clsx from 'clsx';

// Styles
import { useStyles } from './styles';

interface IButton {
	label: string | React.ReactElement;
	icon?: React.ReactNode;
	endIcon?: React.ReactNode;
	onClick?: () => void;
	variant?: 'solid' | 'outlined' | 'text';
	styles?: React.CSSProperties;
	disabled?: boolean;
	classNames?: string;
}

const CustomButton = (props: IButton) => {
	const {
		onClick,
		label,
		variant = 'solid',
		styles,
		disabled,
		icon,
		endIcon,
		classNames,
	} = props;
	const classes = useStyles();
	return (
		<button
			onClick={onClick}
			style={{ ...styles, backgroundColor: disabled ? '#CDCDCD' : '' }}
			disabled={disabled}
			className={clsx(
				variant === 'solid'
					? classes.buttonLogInSolid
					: variant === 'outlined'
						? classes.buttonLogInOutlined
						: classes.buttonLogInText,
				classNames
			)}
		>
			{icon && <span>{icon}</span>}
			{label}
			{endIcon && <span>{endIcon}</span>}
		</button>
	);
};

export default CustomButton;
