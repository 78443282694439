export const LIST_MESSAGES: Array<string> = [
	'Estos son los factores que consideramos. Tómalos en cuenta para que estimes con más exactitud el valor de tu ahorro en el momento que te retires.',
	'El rendimiento de la Subcuenta de Vivienda y la Tasa de Inflación por año.',
	'Incremento anual del salario mínimo e incremento adicional de tu salario, en su caso.',
	'Tu ahorro y salario mensual a la fecha.',
];

export const totalAge = 65;
export const incrementoSalarioActual = 0.05;
export const rendimientoSCV2011 = 0.0468;
export const tasaDescuentoInflacion = 0.041;
export const incrementoSalarioActualAux = 12;
export const VSMDF25 = 55510.4;
export const topeAportacionPatronal = 33306.24;
