/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { Grid } from '@mui/material';

import CustomButton from '../../../components/Button';

import { dataReturnSavingsContext } from '../contextData';
import { useStyles } from '../style';
import CustomAlert from '../../../components/CustomAlert';
import ModalLoading from '../../../components/ModalLoading';
import ModalBajaTemporal from '../ModalBajaTemporal';
import { LIST_TODO } from '../constants/constants-savings.constants';

const InfoPage = () => {
	const {
		handleNextPage,
		alert,
		handleConsultaDatos,
		loading,
		showModalTemporal,
	} = useContext(dataReturnSavingsContext);
	const style = useStyles();

	useEffect(() => {
		handleConsultaDatos();
	}, []);

	const handleSubmit = () => {
		handleNextPage();
	};
	return (
		<>
			<ModalBajaTemporal show={showModalTemporal} />
			<ModalLoading loading={loading}></ModalLoading>
			<div>
				<p>
					Este servicio podrá ser utilizado por derechohabientes
					pensionados que recibieron su resolución de pensión por
					parte del IMSS y que tengan una firma electrónica avanzada
					vigente. No podrá ser utilizado por los beneficiarios.
				</p>
				<p className={style.maxContent}>Para poder solicitar la devolución en línea deberás:</p>
				{LIST_TODO.map((sm) => (
					<Grid
						item
						xs={12}
						lg={12}
						key={sm.id}
					>
						<img
							className={style.iconsImg}
							alt="icono de check"
							src={sm.icon}
						></img>
						<span
							aria-label={sm.title}
							className={`${style.txtPrincipales} tag-to-read`}
						>
							{sm.title}
						</span>
						<br></br>
					</Grid>
				))}
				<p className={style.maxContent}>
					En caso de ser una pensión por incapacidad, el porcentaje de
					valuación deberá ser mayor o igual al 50%.
				</p>
				<p>
					El Fondo de Ahorro 72-92 anterior al SAR está conformado por
					las aportaciones patronales correspondientes al 5% del
					salario ordinario que tenías registrado y que fueron pagados
					por tu empresa o patrón entre el 1° de mayo de 1972 al 29 de
					febrero de 1992, por lo tanto, te será entregado un tanto
					adicional igual a lo que tengas registrado, en términos del
					Artículo 141 Fracción I de la Ley Federal del Trabajo.
				</p>
				<div className={style.divAlert}>
					<CustomAlert
						message={alert.message}
						show={alert.show}
						severity={alert.severity}
					/>
				</div>
				<div>
					<CustomButton
						onClick={handleSubmit}
						variant="solid"
						styles={{ marginTop: 70, width: 260, margin: '0 auto' }}
						label="Continuar"
						disabled={alert.show}
					/>
				</div>
			</div>
		</>
	);
};

export default InfoPage;
