// Dependencies
import { Link, useNavigate } from 'react-router-dom';

// Material Components
import { Grid, Typography } from '@mui/material';
import theme from '../../config/theme';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

// Components
import CustomButton from '../../components/Button';
import TextInput from '../../components/TextInput';
import ModalConfirmation from '../../components/ModalConfirmation';
import ModalLoading from '../../components/ModalLoading';
import CustomAlert from '../../components/CustomAlert';
import BaseModal from '../../components/Modal';

// Resources
import Captcha from '../../components/Captcha';
import { IPersonalData } from './interfaces/register.interface';
import { usePersonalDataRegister } from './hooks/usePersonalDataRegister';
import { useStyles } from '../Register/styles';


const PersonalData = (props: IPersonalData) => {
	const navigate = useNavigate();
	const classes = useStyles();
	const {
		onChange,
		onContinue,
		onContinueNSS,
		hasError,
		onCloseError,
		setDataUser,
	} = props;

	const {
		credentials,
		setData,
		handleValidateNSS,
		handleValidateCURP,
		handleValidateRFC,
		deletePreRegister,
		errorNSS,
		errorCURP,
		nssValidateFinal,
		errorRFC,
		curpValidateFinal,
		errorRfcFinal,
		codeInput,
		setCodeInput,
		setCode,
		rfcValidateFinal,
		code,
		modalLogged,
		setModalLogged,
		modalCorrectionRfc,
		setModalCorrectionRfc,
		loading,
		modalLocked,
		setModalLocked,
		modalPrivacy,
		setModalPrivacy,
		alert,
		name,
		rfcCrm
	} = usePersonalDataRegister({ onChange, onContinueNSS, setDataUser });

	return (
		<div style={{ width: '100%' }}>
			<Grid container className={classes.container}>
				<Grid item xs={0} sm={0} md={3} lg={3}></Grid>
				<Grid item xs={12} sm={12} md={6} lg={6}>
					<TextInput
						id="nss"
						name="nss"
						label="Número de Seguridad Social (NSS)"
						value={credentials.nss}
						error={!errorNSS.valid}
						helperText={!errorNSS.valid ? errorNSS.msg : ''}
						placeholder="11 Dígitos"
						size="small"
						isRequired
						onChange={setData}
						onBlur={handleValidateNSS}
						onFocus={setData}
					/>
					<Typography
						variant="subtitle1"
						className={classes.p}
						gutterBottom
					>
						<a
							className={classes.a}
							target="_blank"
							href="https://serviciosdigitales.imss.gob.mx/gestionAsegurados-web-externo/asignacionNSS"
							rel="noreferrer"
						>
							Consulta tu NSS
						</a>
					</Typography>
					<TextInput
						id="curp"
						name="curp"
						label="Clave Única de Registro de Población (CURP)"
						value={credentials.curp}
						error={!errorCURP.valid}
						helperText={
							errorCURP.msg !== 'CURP Correcta'
								? errorCURP.msg
								: ''
						}
						placeholder="18 Caracteres"
						size="small"
						isRequired
						onChange={setData}
						onBlur={handleValidateCURP}
						onFocus={setData}
						disabled={!nssValidateFinal}
					/>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<Typography
							variant="subtitle1"
							className={classes.p}
							gutterBottom
						>
							<a
								className={classes.a}
								target="_blank"
								rel="noreferrer"
								href="https://www.gob.mx/curp"
							>
								Consulta tu CURP
							</a>
						</Typography>
					</div>

					<TextInput
						id="rfc"
						name="rfc"
						label="Registro Federal de Contribuyentes (RFC)"
						value={credentials.rfc}
						error={!errorRFC.valid}
						helperText={
							errorRFC.msg !== 'RFC Correcto' ? errorRFC.msg : ''
						}
						placeholder="13 Caracteres"
						size="small"
						isRequired
						onChange={setData}
						onFocus={setData}
						onBlur={handleValidateRFC}
						disabled={!curpValidateFinal}
					/>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<Typography
							variant="subtitle1"
							className={classes.p}
							gutterBottom
						>
							<a
								className={classes.a}
								target="_blank"
								rel="noreferrer"
								href="https://www.sat.gob.mx/aplicacion/operacion/31274/consulta-tu-clave-de-rfc-mediante-curp"
							>
								Consulta tu RFC
							</a>
						</Typography>
						{errorRfcFinal && errorRFC.valid && (
							<Typography
								variant="subtitle1"
								className={classes.p}
								onClick={() => deletePreRegister()}
								gutterBottom
							>
								<p
									className={classes.a}
									style={{
										margin: 0,
										cursor: 'pointer',
										display: 'flex',
										alignItems: 'center',
										paddingTop: '2px',
									}}
								>
									Corregir RFC
									<span
										style={{ marginLeft: 10, fontSize: 0 }}
									>
										<ArrowForwardIosOutlinedIcon
											fontSize={'small'}
										/>
									</span>
								</p>
							</Typography>
						)}
					</div>
					<div className={classes.containerCaptcha}>
						<Captcha
							value={codeInput}
							onChange={(
								newValue: string,
								codeCaptcha: string
							) => {
								setCodeInput(newValue);
								setCode(codeCaptcha);
							}}
						/>
					</div>
					<div style={{ width: '100%' }}>
						<CustomAlert
							show={hasError.show}
							severity={hasError.severity}
							message={hasError.message}
							onClose={onCloseError}
						/>
						<CustomAlert
							show={alert.show}
							severity={alert.severity}
							message={alert.message}
						/>
					</div>
					<CustomButton
						label="Continuar"
						onClick={onContinue}
						variant="solid"
						disabled={
							!Boolean(
								nssValidateFinal &&
									curpValidateFinal &&
									rfcValidateFinal &&
									codeInput &&
									code === codeInput
							)
						}
					/>
				</Grid>
				<Grid item xs={0} sm={0} md={3} lg={3}></Grid>
			</Grid>
			<ModalConfirmation
				open={modalLogged}
				confirmLabel="Cerrar"
				onConfirm={() => setModalLogged(!modalLogged)}
				width="sm"
			>
				<p className={classes.titleModalAlert}>
					Ya tienes una cuenta activa, si no recuerdas tu usuario o
					contraseña, cámbiala en la sección de{' '}
					<Link to="/cambio-contrasenia">Olvidé mi contraseña.</Link>
					<br />
				</p>
			</ModalConfirmation>
			<ModalConfirmation
				open={modalLocked}
				confirmLabel="Cerrar"
				onConfirm={() => setModalLocked(!modalLocked)}
				width="sm"
			>
				<p className={classes.titleModalAlert}>
					El usuario se encuentra bloqueado, para poder desbloquearlo
					favor de visitar el siguiente link:{' '}
					<Link to="/cambio-contrasenia">Restaurar usuario</Link>
					<br />
					<br />
					Si no has solicitado una cuenta comunícate a Infonatel para
					reportarlo.
					<br />
				</p>
			</ModalConfirmation>
			<BaseModal
				open={modalPrivacy}
				confirmLabel="Continuar"
				onClose={() => {
					localStorage.removeItem('dataUserRegister');
					setModalPrivacy && setModalPrivacy(false);
				}}
				onConfirm={() => {
					localStorage.removeItem('dataUserRegister');
					setModalPrivacy(!modalPrivacy);
				}}
				width="sm"
				title={
					<label
						style={{
							color: theme.palette.info.main,
							margin: 0,
							width: '100%',
							display: 'block',
							textAlign: 'center',
						}}
					>
						¡Importante!
					</label>
				}
			>
				<p className={classes.titleModalAlert}>
					Antes de iniciar tu registro a Mi Cuenta Infonavit, es
					necesario que <br />
					consultes el{' '}
					<Link
						to={require('../../assets/files/aviso-de-privacidad.pdf')}
						target={'_blank'}
						download="aviso-de-privacidad.pdf"
					>
						Aviso de Privacidad
					</Link>
					<br />
				</p>
			</BaseModal>
			<BaseModal
				open={modalCorrectionRfc}
				cancelButton={true}
				cancelLabel={'No'}
				confirmLabel="Sí"
				hideCloseButton={true}
				onClose={() => {
					setModalCorrectionRfc && setModalCorrectionRfc(false);
				}}
				onConfirm={() => {
					let values = {
						nss: credentials.nss,
						curp: credentials.curp,
						rfc: credentials.rfc,
						name: name,
						rfcCrm: rfcCrm,
					};
					localStorage.setItem(
						'dataUserRegister',
						JSON.stringify(values)
					);
					setModalCorrectionRfc(!modalCorrectionRfc);
					navigate('/correccion-rfc');
				}}
				width="sm"
				title={
					<label
						style={{
							color: theme.palette.info.main,
							margin: '20px 0px -20px',
							width: '100%',
							display: 'block',
							textAlign: 'center',
						}}
					>
						¿Quieres continuar con el proceso para corregir tu RFC?
					</label>
				}
				stylesActions={{ borderTop: '2px solid #eeeeee' }}
			>
				<p className={classes.titleModalAlert}></p>
			</BaseModal>
			<ModalLoading loading={loading} />
		</div>
	);
};

export default PersonalData;
