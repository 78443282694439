import { useState, useContext, useEffect } from 'react';
import {
	RadioGroup,
	FormControlLabel,
	Radio,
	useMediaQuery,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import CustomButton from '../../../components/Button';
import MessageCustom from '../../../components/MessageCustom';

import { dataReturnSavingsContext } from '../contextData';
import {
	IRequestCreacionCaso,
	itemCheck,
	returnOptionType,
} from '../interfaces';
import { useStyles } from '../style';
import { CheckParagraph } from '../utils';
import CustomAlert from '../../../components/CustomAlert';
import BaseModal from '../../../components/Modal';
import ModalLoading from '../../../components/ModalLoading';

interface Props {
	ListChecksReturnOptions?: itemCheck[] | undefined;
}

const ReturnOptions = ({ ListChecksReturnOptions }: Props) => {
	const style = useStyles();
	const [disabledButton, setDisabledButton] = useState<boolean>(true);
	const {
		handlePrevPage,
		setData,
		data,
		handleConsultaDatosDisponibilidad,
		alert,
		loading,
		MessageToView,
		handleCreacionCaso,
		handleNextPage,
		setOptionSelectedItem,
	} = useContext(dataReturnSavingsContext);
	const [openModal, setOpenModal] = useState(false);
	const theme = useTheme();
	const md = useMediaQuery(theme.breakpoints.down('md'));
	const [optionSelected, setOptionSelected] = useState('');

	useEffect(() => {
		setOpenModal(MessageToView === undefined ? false : true);
	}, [MessageToView]);

	const handleOnSubmit = () => {
		setOptionSelectedItem(optionSelected);
		if (optionSelected === 'planPrivadoPension') {
			handleNextPage();
			return;
		}
		if (
			optionSelected === 'porInconformidades' ||
			optionSelected === 'pensionAnterior1997'
		) {
			const params: IRequestCreacionCaso = {
				evento: '1',
				opcion: optionSelected,
				demanda: data?.hasDemand || false,
				montoDevolver: data?.savingsInformationTotal || '0',
				montoSaldo92: data?.savingsInformation?.subaccount_1992 || '0',
				montoSaldo97: data?.savingsInformation?.subaccount_1997 || '0',
				montoSaldoFA: data?.savingsInformation?.savingFund || '0',
				montoAdicionalSaldoFA:
					data?.savingsInformation?.additionalQuantity || '0',
			} as IRequestCreacionCaso;
			handleCreacionCaso(params);
			return;
		}

		handleConsultaDatosDisponibilidad();
	};

	return (
		<div>
			<ModalLoading loading={loading}></ModalLoading>
			<div className={style.textTopLeft}>
				<p className={style.TextBlack_H3} style={{ margin: '0 0 10px' }}>
					Elige una de las siguientes opciones{' '}
				</p>
			</div>
			<div className={style.divChecks}>
				<RadioGroup>
					{ListChecksReturnOptions?.map((checkItem, index) => (
						<div key={'divRadio-' + index}>
							<FormControlLabel
								control={
									<Radio
										onChange={(
											event: React.ChangeEvent<HTMLInputElement>
										) => {
											const { name } = event.target;
											if (event.target.checked) {
												setOptionSelected(name);
												setData(
													{
														...(data || undefined),
														returnOptionType:
															name as returnOptionType,
														causal:
															data
																?.ConsultaDatosResponse
																?.data
																?.causal !==
																undefined
																? data
																	?.ConsultaDatosResponse
																	?.data
																	?.causal
																: checkItem.Id.toString(),
													}
												);

												setDisabledButton(false);
											}
										}}
										name={checkItem.value}
										data-testid={checkItem.value}
									/>
								}
								style={{
									color: '#000000',
								}}
								label={checkItem.label}
								value={checkItem.value}
								key={'RadioButton_' + index}
							/>
							<CheckParagraph key={'CheckParagraph_' + index}>
								<span
									aria-label={checkItem.sublabel}
									className="tag-to-read"
								>
									{checkItem.sublabel}
								</span>
							</CheckParagraph>
						</div>
					))}
				</RadioGroup>
			</div>
			<div className={style.divAlert}>
				<CustomAlert
					message={alert.message}
					show={alert.show}
					severity={alert.severity}
				/>
			</div>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className={style.divButtons}>
					<div className={style.Buttons}>
						<CustomButton
							onClick={handlePrevPage}
							variant="outlined"
							styles={{
								width: '100%',
								height: '100%',
							}}
							label="Regresar"
						/>
					</div>

					<div className={style.Buttons}>
						<CustomButton
							onClick={() => {
								handleOnSubmit();
							}}
							variant="solid"
							styles={{
								width: '100%',
							}}
							label="Continuar"
							disabled={disabledButton}
						/>
					</div>
				</div>
			</div>
			<BaseModal
				open={openModal}
				width={md ? 'md' : 'sm'}
				onClose={() => setOpenModal(!openModal)}
			>
				<MessageCustom msg={MessageToView || ''}></MessageCustom>
			</BaseModal>
		</div>
	);
};

export default ReturnOptions;
