import { useState } from 'react';
import { SimCardDownload } from '@mui/icons-material';
import { Link, Grid, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../../components/Button';
import { IProrroga } from '../../utils';
import { IAlert } from '../../../../interfaces/alert';
import CustomAlert from '../../../../components/CustomAlert';
import { downloadPDF } from '../../../../utils/downloadPDF';
import ModalLoading from '../../../../components/ModalLoading';
import { keyAPI } from '../../../../api/modules/key';
import { encrypt } from '../../../../utils/encrypt';
import { apiProrogation } from '../../../../api/modules/Prorogation';

interface IProps {
	prorroga: IProrroga;
}

const Summary = ({ prorroga }: IProps) => {
	const navigation = useNavigate();
	const [loading, setLoagin] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});

	const requestDowloand = async () => {
		try {
			setLoagin(true);

			setAlert({
				message: '',
				severity: 'warning',
				show: false,
			});

			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const idTramite_encript = await encrypt(
				prorroga.search.folio?.toString() || '',
				key
			);

			const { result } = await apiProrogation.dowloadDocMq(
				idTramite_encript
			);

			if (result.base64 !== '' && result.base64) {
				downloadPDF({
					payload: result.base64,
					name: 'prorroga_' + prorroga.search.folio,
				});
				return;
			}

			const { result: resultot } = await apiProrogation.dowloadDocOt(
				idTramite_encript
			);

			if (resultot.archivo !== '' && resultot.archivo) {
				downloadPDF({
					payload: resultot.archivo || '',
					name: 'prorroga_' + prorroga.search.folio,
				});
				return;
			}

			setAlert({
				message:
					result.description ||
					'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'warning',
				show: true,
			});
		} catch (error: any) {
			setAlert({
				message:
					'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'warning',
				show: true,
			});
		} finally {
			setLoagin(false);
		}
	};

	return (
		<>
			<ModalLoading loading={loading} />
			<h3 className="title">
				Gracias por utilizar los servicios electrónicos del Infonavit
			</h3>

			<Grid container columns={12} spacing={2} margin={'10px 0px'}>
				<Grid item xs={12} md={6} textAlign={'left'} fontWeight={'700'}>
					<label>
						Número de caso
					</label>
				</Grid>
				<Grid item xs={12} md={6} textAlign={'right'}>
					<label>
						{prorroga.search.folio}
					</label>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid item xs={12} md={6} textAlign={'left'} fontWeight={'700'}>
					<label>Servicio</label>
				</Grid>
				<Grid item xs={12} md={6} textAlign={'right'}>
					<label>{prorroga.search.servicio}</label>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid item xs={12} md={6} textAlign={'left'} fontWeight={'700'}>
					<label>Fecha de solicitud</label>
				</Grid>
				<Grid item xs={12} md={6} textAlign={'right'}>
					<label>{prorroga.search.fechaEstatus}</label>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid item xs={12} md={6} textAlign={'left'} fontWeight={'700'}>
					<label>Estatus</label>
				</Grid>
				<Grid item xs={12} md={6} textAlign={'right'}>
					<label>{prorroga.search.estatus}</label>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				{/* <Grid item xs={12} md={6} textAlign={'left'} fontWeight={'700'}>
					Fecha de estatus
				</Grid>
				<Grid item xs={12} md={6} textAlign={'right'}>
					{prorroga.search.fechaEstatus}
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid> */}
			</Grid>

			<CustomAlert
				message={alert.message}
				severity={alert.severity}
				show={alert.show}
			/>

			<Link
				underline="hover"
				style={{ display: 'flex' }}
				onClick={requestDowloand}
			>
				Descarga <SimCardDownload />
			</Link>
			<div className="actions-container">
				<CustomButton
					label="Finalizar"
					onClick={() => navigation('/')}
				/>
			</div>
		</>
	);
};

export default Summary;
