import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('106', 'sumar-creditos');

export const ENDPOINTS_SUMAR_CREDITOS: IBitacora = {
	'/associates-consult': _bitacora.Read('106100', 'Consultar nss'),
	'/associates-delete': _bitacora.Delete('106101', 'Eliminar nss'),
	'/associates-nss': _bitacora.Update('106102', 'Asociar nss'),
	'/type-associates': _bitacora.Read('106103', 'Obtener tipos de asociación'),
	'/reference-associates': _bitacora.Read(
		'106104',
		'Obtener listado de asociación'
	),
	'/reference-data': _bitacora.Read(
		'106105',
		'Obtener detallado de referencia'
	),
};
