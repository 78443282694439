export const describeText = [
	'En este servicio puedes corregir el Registro Federal de Contribuyentes (RFC) que tenemos registrado en nuestro sistema.',
	'Para proceder con la corrección, es necesario que tu Clave Única de Registro de Población (CURP) sea la que está asociada al sistema del Servicio de Administración Tributaria (SAT).',
	'Si cuentas con firma electrónica avanzada, recibo de nómina digital o tu CURP es la misma que la registrada en el Sistema SAT, elige una opción y continua con el proceso.',
	'En caso de que no cuentes con ninguna de las anteriores, puedes hacer una cita en cualquiera de los centros de atención del Infonavit.',
];
export const messageErrorName = 'Tu firma electrónica avanzada tiene asociado un nombre diferente al registrado en el Infonavit. Revisa que los archivos ingresados sean los correctos o si lo requieres podrás solicitar una cita para realizar el trámite en cualquiera de nuestros Centros de Servicio Infonavit.'
export const messageErrorCurp = 'Tu firma electrónica avanzada tiene asociado un CURP diferente al registrado en el Infonavit. Revisa que los archivos ingresados sean los correctos o si lo requieres podrás solicitar una cita para realizar el trámite en cualquiera de nuestros Centros de Servicio Infonavit.'
export const messageError =
				'No se localizó RFC asociado a la CURP registrada en el Infonavit. Ingresa a la opción de CURP y nombre y verifica tus datos, posteriormente podrás intentar nuevamente el trámite de corrección de RFC. Si quieres verificar tus datos registrados en el SAT ingresa aquí.';
export const DESCRIBE_TEXT_DATE = [
	'Tienes 5 días para adjuntar tus documentos, de lo contrario tu caso se cerrará y tendrás que hacer tu trámite de nuevo.',
];