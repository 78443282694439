// DEPENDENCIES
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
//import { isAfter, isBefore } from 'date-fns';
// COMPONENTS
import ModalLoading from '../../../components/ModalLoading';
import { INTERNAL_MESSAGES } from '../../../config/messageCatalog';
import CustomAlert from '../../../components/CustomAlert';
// VIEWS
import Location from './views/Location';
import ActualConditions from './views/ActualConditions';
import CompareConditions from './views/CompareConditions';
import SelectedCredit from './views/SelectedCredit';
import DenailReason from './views/DenailReason';
import Summary from './views/Summary';
// RESOURCES
import { sharedResponsabilityAPI } from '../../../api/modules/sharedResponsability';
import { RootState } from '../../../store';
import { encrypt } from '../../../utils/encrypt';
import { keyAPI } from '../../../api/modules/key';

const Procedure = () => {
	const { credit, user } = useSelector((state: RootState) => ({
		user: state.session.user,
		credit: state.credit.credit,
	}));
	const [step, setStep] = useState(0);
	const [elegible, setElegible] = useState(true);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<any>([]);
	const [currentCredit, setCurrentCredit] = useState(null);
	const [creditOptions, setCreditOptions] = useState(null);
	const [selectedCredit, setSelectedCredit] = useState<any>(null);
	const [messageConvertion, setMessageConvertion] = useState('')
	/*let now = new Date();
	configuración para fechas del día 1 al 8 de cada mes
	let firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
	let finalDay = new Date(now.getFullYear(), now.getMonth(), 9);
	*/

	const fetchEligibility = useCallback(async () => {
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const USER_NSS_BASE64 = await encrypt(user?.nss?.toString() || '', key);
			const CREDIT_BASE64 = await encrypt(credit || '', key);
			const consultCase = await sharedResponsabilityAPI.consultCase(
				USER_NSS_BASE64
			);
			const { result } = await sharedResponsabilityAPI.elegibility(CREDIT_BASE64);
			if (consultCase?.err) {
				setAlert([
					INTERNAL_MESSAGES.ERROR_MESSAGE,
				]);
			}
			if (
				consultCase?.result?.motivo &&
				['ZN02'].includes(consultCase?.result?.motivo)
			) {
				setStep(4);
				setSelectedCredit({
					...consultCase?.result,
					CREDITO: result?.datosCredito?.numeroCredito,
					FCH_OTORG: result?.datosCredito?.fechaOtorgamiento,
					saldoOriginacion: result?.datosCredito?.saldoOriginacion,
					saldoProyecto: result?.datosCredito?.saldoProyecto,
					regimen: result?.datosCredito?.regimen,
				});
			} else if (
				consultCase?.result?.estatus &&
				consultCase?.result?.estatus !== 'Cerrado'
			) {
				setStep(4);
				setSelectedCredit({
					...consultCase?.result,
					CREDITO: result?.datosCredito?.numeroCredito,
					FCH_OTORG: result?.datosCredito?.fechaOtorgamiento,
					saldoOriginacion: result?.datosCredito?.saldoOriginacion,
					saldoProyecto: result?.datosCredito?.saldoProyecto,
					regimen: result?.datosCredito?.regimen,
				});
			} else {
				if (result?.status === 'Elegible') {
					setCurrentCredit(result?.creditoSinDescuento);
					setCreditOptions(result?.opciones);
					setSelectedCredit(result?.datosCredito);
				} else {
					setElegible(false);
					if (result?.mensaje) {
						//setAlert([result?.mensaje]);
						setMessageConvertion(result?.mensaje)
					} else if (result?.mensajes && result?.mensajes.length > 0) {
						setAlert(result?.mensajes);
					} else {
						setAlert([
							INTERNAL_MESSAGES.ERROR_MESSAGE,
						]);
					}
				}
			}
		} catch (error: any) {
			setError(true);
			setAlert(
				error.description
					? [error?.description]
					: [
						INTERNAL_MESSAGES.ERROR_MESSAGE,
					]
			);
		} finally {
			setLoading(false);
		}
	}, [credit, user?.nss]);

	useEffect(() => {
		if (credit && credit !== '0') fetchEligibility();
	}, [fetchEligibility, credit]);

	if (messageConvertion.length > 0)
		return (
			<>
				<p>
					{messageConvertion}
				</p>
			</>
		);
	if (error)
		return (
			<>
				{alert?.length > 0 &&
					alert.map((msg: string) => (
						<Fragment key={alert.msg}>
							<CustomAlert
								message={msg}
								severity={'error'}
								show={Boolean(msg)}
								onClose={(): any => setAlert([])}
							/>
							<br />
						</Fragment>
					))}
			</>
		);

	if (!elegible)
		return (
			<>
				{alert?.length > 0 &&
					alert.map((msg: string) => (
						<Fragment key={alert.msg}>
							<CustomAlert
								message={msg}
								severity={'error'}
								show={Boolean(msg)}
								onClose={(): any => setAlert([])}
							/>
							<br />
						</Fragment>
					))}
			</>
		);

	return (
		<>
			<ModalLoading loading={loading} />
			{step === 0 && (
				<Location
					step={step}
					setStep={setStep}
					selectedCredit={selectedCredit}
					setSelectedCredit={setSelectedCredit}
				/>
			)}
			{step === 1 && (
				<ActualConditions
					step={step}
					setStep={setStep}
					creditData={selectedCredit}
				/>
			)}
			{step === 2 && (
				<CompareConditions
					step={step}
					setStep={setStep}
					currentCredit={currentCredit}
					creditOptions={creditOptions}
					selectedCredit={selectedCredit}
					setSelectedCredit={setSelectedCredit}
				/>
			)}
			{step === 3 && (
				<SelectedCredit
					step={step}
					setStep={setStep}
					credit={selectedCredit}
					setSelectedCredit={setSelectedCredit}
				/>
			)}
			{step === 4 && (
				<Summary step={step} setStep={setStep} selectedCredit={selectedCredit} />
			)}
			{step === 5 && (
				<DenailReason
					step={step}
					setStep={setStep}
					selectedCredit={selectedCredit}
					setSelectedCredit={setSelectedCredit}
				/>
			)}
		</>
	);
};

export default Procedure;
