/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { Grid, Paper, useMediaQuery, Link } from '@mui/material';
import { useStyles } from './styles';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../../../components/Button';
import CustomAlert from '../../../components/CustomAlert';
import { Search } from '@mui/icons-material';
import ViewArchive from './ViewArchive';
import { IAlert } from '../../../interfaces/alert';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { HeaderList } from '../../../components/HeaderList';
import ClearIcon from '@mui/icons-material/Clear';
import { INTERNAL_MESSAGES } from '../../../config/messageCatalog';

interface Props {
	setPage: (data: number) => void;
	noCaso: string;
	files: {
		file1: {
			name: string;
			data: null;
			type: string;
			size: number;
		};
	};
	setFiles: React.Dispatch<
		React.SetStateAction<{
			file1: {
				name: string;
				data: null;
				type: string;
				size: number;
			};
		}>
	>;
}

export const FilesView = ({ setPage, files, setFiles, noCaso }: Props) => {
	const { user } = useSelector((state: RootState) => state.session);
	const [uploadReady] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [openModal, setOpenModal] = useState(false);
	const [alertFile, setAlertFile] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const classes = useStyles();
	const [itemActiveModal, setItemActiveModal] = useState({
		data: null,
	});
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [existFile, setExistFile] = useState<boolean>(false);
	const [fileBase64, setFileBase64] = useState<{
		file: string | ArrayBuffer | null;
		name: string;
	}>({ file: null, name: '' } as {
		file: string | ArrayBuffer | null;
		name: string;
	});

	const handleFile = (e: any) => {
		setAlertFile({
			show: false,
			message: '',
			severity: 'success',
		});
		if (e.target.files[0].size > 20971520) {
			setAlertFile({
				show: true,
				message: INTERNAL_MESSAGES.FILE_SIZE,
				severity: 'error',
			});

			return;
		}
		if (e.target.files[0].type !== 'application/pdf') {
			setAlertFile({
				show: true,
				message: INTERNAL_MESSAGES.FILE_NOT_SUPPORTED,
				severity: 'error',
			});

			return;
		}

		const newFile = new File(
			[e.target.files[0]],
			`escritura-${user?.nss.toString()}.pdf`,
			{ type: e.target.files[0].type }
		);

		saveBase64File(
			e.target.files[0],
			`escritura-${user?.nss.toString()}.pdf`
		);

		setFiles({
			...files,
			[e.target.name]: {
				name: `escritura-${user?.nss.toString()}.pdf`,
				data: newFile,
				type: newFile.type,
				size: newFile.size,
			},
		});
		setExistFile(true);
	};
	const handleDeleteFile = () => {
		setFiles({
			file1: {
				name: 'No se eligió ningún archivo',
				data: null,
				type: '',
				size: 0,
			},
		});

		setExistFile(false);
		setFileBase64({ file: null, name: '' } as {
			file: string | ArrayBuffer | null;
			name: string;
		});
	};

	const saveBase64File = (_file: File, name: string) => {
		const reader = new FileReader();
		reader.readAsDataURL(_file);
		reader.onload = () => {
			setFileBase64({ file: reader.result, name: name } as {
				file: string | ArrayBuffer | null;
				name: string;
			});
		};
	};

	const Handler_OnClose = () => {
		setAlert({
			show: false,
			message: '',
			severity: alert.severity,
		});
	};

	return (
		<div>
			<HeaderList
				title="Carta de instrucción de cancelación de hipoteca"
				date="Información al 12 julio 2021"
				list={[
					'Esta carta debes entregarla al notario que elegiste para que lleve a cabo la liberación de tu hipoteca y quede registrada en el Registro Público de la Propiedad.',
					'La vigencia de la carta de cancelación es de 180 días naturales, si en ese tiempo no la entregas al notario (solo notario), deberás iniciar el trámite nuevamente.',
				]}
			/>
			<ViewArchive
				show={openModal}
				setShow={setOpenModal}
				file={itemActiveModal?.data}
			/>
			<Paper className={classes.divPrincipal}>
				<Grid
					container
					columns={12}
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<Grid
						item
						xs={2}
						sm={2}
						md={2}
						lg={2}
						style={{ textAlign: 'left', fontWeight: 'bold' }}
					>
						<label>Número de caso</label>
					</Grid>
					<Grid
						item
						xs={2}
						sm={2}
						md={2}
						lg={2}
						style={{ textAlign: 'right' }}
					>
						<label>{noCaso}</label>
					</Grid>
				</Grid>
				<hr
					style={{
						opacity: 0.4,
						marginTop: 20,
						marginBottom: 20,
					}}
				/>
				<div
					className={classes.txtPrincipales}
					style={{ display: 'flex', justifyContent: 'left', fontWeight: 'bold' }}
				>
					<label>Estos son los documentos que debes adjuntar</label>
				</div>
				<div>
					<div className={classes.contentFile}>
						<div className={classes.contentFileData}>
							<h4 className={classes.contentFileDataTitle}>
								<span>Escritura</span><span style={{ color: 'red' }}>*</span>
							</h4>
							<p className={classes.contentFileDataText}>
								{files.file1.name}
							</p>
						</div>
						<div className={classes.contentFileDataLink}>
							{existFile === false && (
								<>
									<label style={{ cursor: 'pointer' }}>
										<input
											type="file"
											accept=".pdf"
											name="file1"
											onChange={handleFile}
											style={{ display: 'none' }}
										/>
										Elegir archivo
									</label>
								</>
							)}

							{existFile === true && (
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											marginLeft: 10,
										}}
									>
										<label
											style={{ cursor: 'pointer' }}
											onClick={handleDeleteFile}
										>
											<ClearIcon
												style={{
													fontSize: 16,
													marginTop: 10,
												}}
											/>{' '}
											Eliminar archivo
										</label>
									</div>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											marginLeft: 10,
										}}
									>
										<Link
											href="#ver"
											underline="none"
											onClick={() => {
												setItemActiveModal(files.file1);
												setOpenModal(true);
											}}
											style={{
												cursor: 'pointer',
											}}
										>
											<Search
												style={{
													fontSize: 16,
													marginTop: 10,
												}}
											/>
											&nbsp;Ver archivo
										</Link>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				<hr style={{ margin: '0px 0px', opacity: 0.6 }} />
				<CustomAlert
					data-testid="mensageAlertthree"
					message={alertFile.message}
					severity={alertFile.severity}
					show={alertFile.show}
					onClose={() => {
						setAlertFile({
							show: false,
							message: '',
							severity: 'success',
						});
					}}
				/>
				<div style={{ margin: matches ? '5px 10px' : '10px 200px' }}>
					<p className={classes.txtPrincipales}>
						Antes de enviarlos verifica que estén correctos. El
						único formato permitido es un PDF con un máximo de 20
						MB.
					</p>
				</div>
				<div className={classes.contentFileDataButtons}>
					<CustomAlert
						data-testid="mensageAlerttwo"
						message={alert.message}
						severity={alert.severity}
						show={alert.show}
						onClose={Handler_OnClose}
					/>
				</div>
				<div className={classes.contentFileDataButtons}>
					<CustomButton
						onClick={() => {
							setPage(3);
						}}
						variant="outlined"
						styles={{
							width: 260,
							marginInline: 10,
						}}
						label="Regresar"
					/>

					<CustomButton
						onClick={() => {
							setPage(5);
						}}
						disabled={
							files.file1.data ? false && !uploadReady : true
						}
						variant="solid"
						styles={{
							width: 260,
							marginInline: 10,
						}}
						label="Continuar"
					/>
				</div>
				<Grid container columns={12}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<hr
							style={{
								opacity: 0.4,
								marginTop: 50,
								marginBottom: 0,
							}}
						/>
						<h3
							style={{
								marginLeft: matches ? 10 : 20,
								marginTop: 8,
								color: '#293990',
								fontSize: matches ? 16 : 18,
								paddingBottom: 15,
								textAlign: 'left',
							}}
							className={classes.maxContent}
						>
							* Datos obligatorios
						</h3>
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
};

export default FilesView;
