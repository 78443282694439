import React, { Fragment } from 'react';
import { Close } from '@mui/icons-material';
import {
	Alert,
	FormControl,
	FormControlLabel,
	ListItemText,
	MenuItem,
	Select,
	Tooltip,
	Typography,
	Fade,
} from '@mui/material';
import { useStyles } from './styles';
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Info } from '@mui/icons-material';

export interface ISelectInput {
	id: string;
	name: string;
	label?: string;
	value: string | number | '';
	helperText?: string;
	type?: string;
	size?: 'small' | 'medium' | undefined;
	isRequired?: boolean;
	labelStyles?: React.CSSProperties;
	onChange?: (e: any) => void;
	disabled?: boolean;
	options?: any;
	placeholder?: string;
	containerStyles?: object;
	hiddenTextSelecciona?: boolean;
	labelHelper?: string;
	tooltipText?: string;
	tooltipIconStyle?: React.CSSProperties;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#FFFFFF',
		// color: 'black',
		maxWidth: 450,
		// fontSize: '12px',
		border: '1px solid #FFFFFF',

		boxShadow: theme.shadows[5],
	},
}));

const SelectInput = ({
	id,
	name,
	label,
	value,
	options = [],
	helperText,
	isRequired,
	onChange,
	disabled,
	placeholder,
	hiddenTextSelecciona,
	containerStyles = {},
	labelStyles,
	labelHelper,
	tooltipText,
	tooltipIconStyle
}: ISelectInput) => {
	const classes = useStyles();

	return (
		<Fragment>
			<FormControlLabel
				className={classes.container}
				style={containerStyles}
				label={
					<div className={classes.label} style={{ ...labelStyles }}>
						<p style={{ margin: 0, display: 'inline' }}>{label}</p>
						{isRequired && (
							<span className="label-required">* </span>
						)}
						{tooltipText && (
							<label style={{ color: '#DD0528', position: 'relative' }}>
								<HtmlTooltip
									title={
										<React.Fragment>
											<Typography
												color="inherit"
												sx={{
													fontFamily:
														'Geomanist, Regular',
													fontSize: '14px',
													color: 'black',
												}}
											>
												{tooltipText}
											</Typography>
										</React.Fragment>
									}
									placement="right"
									arrow
									TransitionComponent={Fade}
									TransitionProps={{ timeout: 600 }}
								>
									<Info
										style={{ fontSize: 20, marginLeft: 5, ...tooltipIconStyle }}
									/>
								</HtmlTooltip>
							</label>
						)}
						{labelHelper && (
							<div style={{ ...labelStyles }}
								className={`${classes.helperText}`}
								dangerouslySetInnerHTML={{
									__html: labelHelper || '',
								}}
							>
							</div>
						)}
					</div>
				}
				labelPlacement={'top'}
				control={
					<FormControl
						fullWidth
						size="small"
						margin="dense"
						variant="outlined"
					>
						<Select
							id={id}
							fullWidth
							displayEmpty
							size="small"
							variant="outlined"
							name={name}
							value={value}
							onChange={onChange}
							disabled={disabled}
						>
							{!hiddenTextSelecciona && (
								<MenuItem value={''}>
									<ListItemText
										style={{
											display: 'flex',
											alignItems: 'end',
											margin: 0,
										}}
										primary="Selecciona"
									/>
								</MenuItem>
							)}
							{options.map(
								(option: any) =>
									option && (
										<MenuItem
											key={
												option.value?.id || option.value
											}
											value={option.value}
											disabled={option.disabled}
										>
											<ListItemText
												style={{
													display: 'flex',
													alignItems: 'end',
													margin: 0,
												}}
												primary={option.text}
												secondary={option.text2}
											/>
										</MenuItem>
									)
							)}
						</Select>
					</FormControl>
				}
			/>
			{helperText && (
				<Alert
					style={{
						marginTop: 5,
						marginBottom: 5,
						boxSizing: 'border-box',
					}}
					severity="error"
					sx={{ width: '100%' }}
					icon={<Close fontSize="inherit" />}
				>
					{helperText}
				</Alert>
			)}
		</Fragment>
	);
};

export default SelectInput;
