import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('101', 'registro');

export const ENDPOINTS_REGISTRO: IBitacora = {
	'/registro/validate-nss': _bitacora.Read('101100', 'Validar NSS'),
	'/registro/validate-curp': _bitacora.Read('101101', 'Validar CURP'),
	'/registro/validate-rfc': _bitacora.Read('101102', 'Validar RFC'),
	'/registro/create-account-first-step': _bitacora.Create(
		'101103',
		'Crear cuenta en primer paso'
	),
	'/registro/create-account-second-step': _bitacora.Create(
		'101104',
		'Crear cuenta en segundo paso'
	),
	'/registro/crear-caso': _bitacora.Create('101105', 'Crear caso'),
	'/registro/validate-phonenumber': _bitacora.Read(
		'101106',
		'Validar numero de telefono'
	),
	'/registro/validate-email': _bitacora.Read('101107', 'Validar correo'),
	'/registro/create-account-third-step': _bitacora.Create(
		'101108',
		'Crear cuenta en tercer paso'
	),
	'/registro/send-email-validation': _bitacora.Read(
		'101109',
		'Enviar codigo por correo'
	),
	'/registro/validate-email-account': _bitacora.Read(
		'101110',
		'Validar correo'
	),
	'/registro/send-sms-validation': _bitacora.Read(
		'101111',
		'Enviar codigo de SMS'
	),
	'/registro/validate-sms-account-code': _bitacora.Read(
		'101112',
		'Validar codigo de SMS'
	),
	'/registro/eliminar-preregistro': _bitacora.Delete(
		'101113',
		'Eliminar PreRegistro'
	),
};
