import { useStyles } from './styles';

const CalenderText = () => {
	const styles = useStyles();

	return (
		<div className={styles.containerCalenderText}>
			<span className={styles.labelTitle}>Disponibilidad</span>
			<ul className={styles.ulList}>
				<li>
					<span className={styles.spanTitle1} />
					Día seleccionado
				</li>
				<li>
					<span className={styles.spanTitle2} />
					Disponibilidad
				</li>
				<li>
					<span className={styles.spanTitle3} />
					Sin disponibilidad
				</li>
			</ul>
		</div>
	);
};

export default CalenderText;
