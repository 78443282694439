/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import Layout from '../../components/Layout';
import FollowAttachDocument from './FollowAttachDocument';
import AttachDocuments from './AttachDocuments';
import Status from './Status';
import '../../assets/font/Geomanist.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const FollowAttachDocuments = () => {
	const { fontSizeLevel } = useSelector((state: RootState) => state.session);
	const [page, setPage] = useState(0);
	const [dataCaso, setDataCaso] = useState({});
	const [getData] = useState(false);
	const [status, setStatus] = useState({});
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	const setData = (dato: any) => {
		setDataCaso(dato);
	};

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<Layout updateTagText={updateTagText}>
			<div style={{ fontFamily: 'Geomanist', fontSize: 15 }} ref={divRef}>
				{page === 0 && (
					<FollowAttachDocument
						setPage={setPage}
						setData={setData}
						getData={getData}
					/>
				)}
				{page === 1 && (
					<AttachDocuments
						setPage={setPage}
						dataCaso={dataCaso}
						setStatus={setStatus}
					/>
				)}
				{page === 2 && <Status setPage={setPage} status={status} />}
			</div>
		</Layout>
	);
};

export default FollowAttachDocuments;
