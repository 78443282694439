import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../../components/Button';
import CustomAlert from '../../../../components/CustomAlert';
import ProrogationCard from '../../Components/ProrogationCard';

interface IProps {
	setStep: (arg: number) => void;
	susceptible: boolean;
}

const AvailableProrogation = ({ setStep, susceptible }: IProps) => {
	const navigation = useNavigate();

	return (
		<>
			<h5>
				La prorroga que tienes disponible
				<br />
				es:
			</h5>

			{susceptible ? (
				<ProrogationCard />
			) : (
				<CustomAlert
					show
					severity="warning"
					message={`Por el momento no eres candidato para recibir algún apoyo de prórroga, posiblemente tu crédito no se encuentre con problemas graves de pago
	Te invitamos a que sigas manteniendo el sano manejo de tu crédito o regularices el pago de tu crédito.`}
				/>
			)}

			<div className="actions-container">
				<CustomButton
					label="Regresar"
					variant="outlined"
					onClick={() => navigation('/')}
				/>
				<CustomButton
					label="Continuar"
					disabled={!susceptible}
					onClick={() => setStep(2)}
				/>
			</div>
		</>
	);
};

export default AvailableProrogation;
