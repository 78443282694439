import { Box, useMediaQuery } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useStyles } from '../styles';
import CustomButtom from '../../../components/Button';

interface IPersonalData {
	onContinue: () => void;
}
const SuccesfulCancellation = (props: IPersonalData) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<>
			<br />
			<Box className={`${classes.VerificacionCodigoTitle} ${classes.containerCheck}`}>
				<h1>¡Cancelación exitosa!</h1>
			</Box>
			<br />
			<Box className={classes.VerificacionText}>
				<p className={`${classes.containerCheck}`}>
					<strong>Tu NIP Infonatel se canceló,</strong>{' '}
					no podrás utilizar los servicios de Infonatel, sin
					<br />embargo puedes volver a solicitarlo.
				</p>
			</Box>
			<br />
			<Box
				display={'flex'}
				justifyContent={'center'}
				flexDirection={matches ? 'column' : 'row'}
				alignItems={matches ? 'center' : ''}
			>
				<Box margin={'10px'} width={260}>
					<CustomButtom
						label={'Finalizar'}
						onClick={() => navigate('/')}
						variant="solid"
					/>
				</Box>
			</Box>
		</>
	);
};

export default SuccesfulCancellation;
