import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	titleContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		'&>h2': {
			color: theme.palette.info.dark,
		},
		'&>p': {
			[theme.breakpoints.down('sm')]: {
				display: 'none',
			},
		},
	},
	infoContainer: {
		marginBottom: 20,
		'&>p': {
			display: 'flex',
			textAlign: 'start',
			alignItems: 'start',
			[theme.breakpoints.down('sm')]: {
				alignItems: 'start',
			},
		},
		'&>:last-child': {
			textAlign: 'right',
			[theme.breakpoints.up('sm')]: {
				display: 'none',
			},
		},
	},
	listItem: {
		maxWidth: 'max-content',
		marginBottom: '4px',
		marginTop: 0,
		[theme.breakpoints.up('xs')]: {
			display: 'flex',
			flexDirection: 'row',
			fontSize: 15,
		},
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			flexDirection: 'row',
			fontSize: 16,
		},
	},
	listItemCustom: {
		display: 'flex',
		flexDirection: 'row',
		margin: 0,
	},
	listItemChild: {
		maxWidth: 'max-content',
		fontSize: 14,
		margin: '0px 0 2px 15px',
	},
}));
