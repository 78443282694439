import Divider from '@mui/material/Divider';
import checkBlue from '../../../assets/img/mini-check-azul.png';
import download from '../../../assets/img/icono-descarga.png';
import { useStyles } from './styles';
import { Link } from '@mui/material';
import { downloadZip } from '../../../utils/downloadZIP';

interface IDataNotice {
	responsable_nombre: string;
	pdf: string;
	aviso: string;
	codigo_respuesta: string;
}

interface Props {
	setPage: (data: number) => void;
	DataNotice?: IDataNotice;
}

const DownloadDoc = ({ setPage, DataNotice }: Props) => {
	const classes = useStyles();
	return (
		<>
			<div className={classes.container}>
				<div className={classes.containerMin}>
					<div className={classes.checkHeader}>
						<img
							src={checkBlue}
							className={classes.checkHeaderImg}
							alt="icono de viñeta"
						/>
						<div className={classes.checkHeaderText}>
							<span
								className="tag-to-read"
								aria-label="Revisa que los datos de tu Aviso sean correctos."
							>
								Revisa que los datos de tu Aviso sean correctos.
							</span>
							<div style={{ marginLeft: 15, fontSize: 14 }}>
								<p>
									· Si hay alguna diferencia en los dígitos de
									tu Numero de Seguridad Social (NSS).{' '}
								</p>
								<p>
									· Si tu Aviso sale a nombre de una empresa a
									la que tú no perteneces.
								</p>
							</div>
						</div>
					</div>
					<div className={classes.checkHeader}>
						<img
							src={checkBlue}
							className={classes.checkHeaderImg}
							alt="icono de viñeta"
						/>
						<div className={classes.checkHeaderText}>
							<span
								className="tag-to-read"
								aria-label="Comunícate al Infonatel; ahí encontrarás ayuda para
								solucionar el problema."
							>
								Comunícate al Infonatel; ahí encontrarás ayuda
								para solucionar el problema.
							</span>
						</div>
					</div>
				</div>

				<Divider style={{ marginBlock: 50 }} />
				<div className={classes.containerMin}>
					<div className={classes.contentDescription}>
						<h3 className={classes.descriptionTitle}>
							Este documento sirve para que la empresa donde
							trabajas sepa cuánto te descontará cada mes para
							pagar tu crédito. No olvides descargar tu Aviso de
							Suspensión en cuanto liquides tu crédito para evitar
							que te sigan descontando.
						</h3>
						<div>
							<div className={classes.descriptionList}>
								<h4 className={classes.descriptionListTitle}>
									Nombre del derechohabiente
								</h4>
								<p className={classes.descriptionListText}>
									{DataNotice?.responsable_nombre}
								</p>
							</div>
							<Divider style={{ marginBlock: 10 }} />
							<div className={classes.descriptionListCustom}>
								<h4 className={classes.descriptionListTitle}>
									Tipo de Aviso
								</h4>
								<div>
									{DataNotice?.pdf && (
										<>
											<p
												className={
													classes.descriptionListText
												}
											>
												{DataNotice?.aviso}
											</p>
											<div
												className={
													classes.descriptionContainerDownload
												}
											>
												<Link
													target="_blank"
													className={
														classes.descriptionContainerDownloadText
													}
													onClick={() =>
														downloadZip({
															base64Data:
																DataNotice?.pdf ||
																'',
															name: 'Aviso',
														})
													}
													style={{
														textDecoration: 'none',
													}}
												>
													<b>Descarga tu Aviso</b>
													<img
														src={download}
														style={{
															width: 20,
															height: 20,
															marginLeft: 5,
															marginBottom: -2,
														}}
														alt="Icono de descarga"
													/>
												</Link>
											</div>
										</>
									)}
									{!DataNotice?.pdf && (
										<p
											className={
												classes.descriptionContainerDownload
											}
										>
											<Link
												target="_blank"
												className={
													classes.descriptionContainerDownloadText
												}
												style={{
													textDecoration: 'none',
												}}
											>
												<b>Solicitud en espera</b>
											</Link>
										</p>
									)}
								</div>
							</div>
							<Divider style={{ marginBlock: 10 }} />
						</div>
						<div className={classes.descriptionContainer}>
							<div className={classes.containerCenter}>
								<p style={{ fontSize: 16 }}>
									Para consultar los documentos anteriores es
									necesario que:
								</p>
								<p
									className={
										classes.contentDescriptionBottomText
									}
								>
									<span>
										Tengas instalada la última versión de
										Acrobat Reader.{' '}
									</span>
									<Link
										href="https://get.adobe.com/reader/?loc=es"
										target="_blank"
										className={
											classes.contentDescriptionBottomLink
										}
									>
										Descárgalo aquí.
									</Link>
								</p>
								<p
									className={
										classes.contentDescriptionBottomText
									}
								>
									<span>
										Permitas las ventanas emergentes en tu
										explorador de internet.{' '}
									</span>
									<Link
										target="_blank"
										href="https://portalmx.infonavit.org.mx/wps/portal/infonavit.web/trabajadores/!ut/p/z1/hY_BDoIwDIafhQPXtUg009tMEDXEmxF7MSOBgRkbmZP4-IJ6FO2t7ff9aYEgBzKyb5T0jTVSD_2ZFpcZR0zXGB1SvuUoNskuWWbxLI0QTv8AGtY4UWL06YVMJRyjD_AjYw-ktC3e5wpTxFwBubIqXenY3Q3j2vtuFWKInXVeataYyo5_emadYu0jxG9qbW8e8gkFujbH61z3mQiCJx2Euk4!/dz/d5/L2dBISEvZ0FBIS9nQSEh/"
										className={
											classes.contentDescriptionBottomLink
										}
									>
										Consulta la guía.
									</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DownloadDoc;
