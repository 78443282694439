import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('116', 'devolucion-fondo-ahorro-72-92');

export const ENDPOINTS_DEVOLUCION_DE_MIS_AHORROS: IBitacora = {
	'/v1/dssv/catalogo-afore': _bitacora.Read(
		'116100',
		'Consultar catalogo afore'
	),
	'/v1/dssv/catalogo-entidades': _bitacora.Read(
		'116101',
		'Consultar catalogo entidades'
	),
	'/v1/dssv/consulta-banco': _bitacora.Read('116102', 'Consultar banco'),
	'/v1/dssv/consulta-datos': _bitacora.Read('116103', 'Consultar datos'),
	'/v1/dssv/valida-causa-raiz': _bitacora.Read(
		'116104',
		'Validar causa raiz'
	),
	'/v1/dssv/cuenta-destino': _bitacora.Read(
		'116105',
		'Consultar cuenta destino'
	),
	'/v1/dssv/consulta-saldo-cero': _bitacora.Read(
		'116106',
		'Consultar saldo cero'
	),
	'/v1/dssv/consulta-datos-disponibilidad': _bitacora.Read(
		'116107',
		'Consultar datos de disponibilidad'
	),
	'/v1/dssv/consulta-datos-disponibilidad-continua-solicitud': _bitacora.Read(
		'116108',
		'Consultar datos de disponibilidad continua'
	),
	'/v1/dssv/busqueda-casos': _bitacora.Read('116109', 'Buscar casos'),
	'/v1/dssv/creacion-caso': _bitacora.Create('116110', 'Creación de caso'),
	'/v1/dssv/valida-afore': _bitacora.Read('116111', 'Validación de afore'),
	'/v1/dssv/valida-fechas': _bitacora.Read('116112', 'Validar fechas'),
	'/v1/dssv/valida-pension': _bitacora.Read('116113', 'Validar pension'),
	'/v1/dssv/marcar-cuenta': _bitacora.Read('116114', 'Marcar cuenta'),
	'/v1/dssv/persiste-datos': _bitacora.Read(
		'116115',
		'Consulta de persistencia de datos'
	),
	'/v1/dssv/obtiene-archivo-acuse': _bitacora.Read(
		'116116',
		'Obtener archivo acuse'
	),
	'/v1/dssv/consulta-documentacion': _bitacora.Read(
		'116117',
		'Consultar documentación'
	),
	'/valid-data-efirma': _bitacora.Read('116118', 'Validar eFirma'),
	'/v1/dssv/reintento-pdf-acuse': _bitacora.Read(
		'116119',
		'Consultar PDF acuse'
	),
	'/v1/dssv/adjuntar-archivo': _bitacora.Update(
		'116120',
		'Adjuntar documentos'
	),
};
