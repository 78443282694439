import { makeStyles } from '@mui/styles';
import { Theme, styled } from '@mui/material/styles';
import { Radio } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
	titleNoticeContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
	},
	titleNotice: {
		marginTop: 0,
		fontSize: 22,
		color: '#293990',
	},
	subTitleNotice: {
		fontSize: 12,
		color: '#333333',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			textAlign: 'right',
			marginTop: 15,
		},
	},
	container: {
		borderRadius: 6,
		backgroundColor: 'white',
		padding: 20,
		paddingBlock: 50,
		marginTop: 30,
		boxShadow: '0px 5px 30px #00000017',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},

	containerButtons: {
		display: 'flex',
		justifyContent: 'space-around',
		width: '600px',
		margin: '30px auto',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
	custombuttom: {
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginBottom: '15px',
			display: 'flex',
			justifyContent: 'center',
		},
	},
	link: {
		textDecoration: 'underline',
		color: 'red',
		cursor: 'pointer',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	textGray14: {
		fontSize: '16px',
		color: '#858585',
	},
	divCodigos: {
		width: '100%',
		maxWidth: '450px',
		margin: '0 auto 35px',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'row',
		display: 'flex',
		alignContent: 'center',
		[theme.breakpoints.down('md')]: {
			maxWidth: '100%',
			paddingLeft: '20%',
			paddingRight: '20%',
			marginRight: 1,
			marginLeft: 1,
		},
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '10%',
			paddingRight: '10%',
			maxWidth: '100%',
			marginRight: 1,
			marginLeft: 1,
		},
	},
	divCodigosDos: {
		width: '100%',
		maxWidth: '500px',
		margin: '0 auto 35px',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'row',
		display: 'flex',
		alignContent: 'center',
		[theme.breakpoints.down('md')]: {
			marginRight: 1,
			marginLeft: 1,
		},
		[theme.breakpoints.down('sm')]: {
			marginRight: 1,
			marginLeft: 1,
		},
	},
	VerificacionCodigo: {
		width: '50px',
		background: '#F2F2F2',
		borderRadius: '5px',
		height: '50px',
		color: '#000000',
		borderColor: '#BABABA',
		borderStyle: 'solid',
		borderWidth: '1',
		display: 'flex',
		alignItems: 'center',
		paddingleft: '10',
		fontSize: '2em',
		textAlign: 'center',
	},
	VerificacionText: {
		fontSize: '17px',
		textAlign: 'center',
		width: '100%',
	},
	VerificacionLogo: {
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		marginBottom: 20,
	},
}));

export const CustomRadio = styled(Radio)(({ theme }) => ({
	margin: 0,
	padding: 0,
	marginRight: 5,
	marginTop: 3,
}));
