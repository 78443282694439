import * as React from 'react';
import Box from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { MenuElements } from '../Navbar/utils';
import { useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import { useStyles } from './styles';

type Props = {
	open: boolean;
	anchorEl: null | HTMLElement;
	subMenuItems: MenuElements[];
	mainItem: null | string;
	setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
};

const FloatMenu = ({ open, setAnchorEl, anchorEl, subMenuItems, mainItem }: Props) => {
	const classes: any = useStyles();
	const { activeDislexic, fontSizeLevel, activeContrast } = useSelector((state: RootState) => state.session);
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDir = (path: string) => {
		if (mainItem !== null) {
			navigate(`/${mainItem}/${path}`);
		} else {
			navigate(`/${path}`);
		}
		setAnchorEl(null);
	};

	const navigate = useNavigate();

	const getClasses = () => {
		const classMap: any = {
			'0': classes.title,
			'1': classes.title1,
			'2': classes.title2,
			'3': classes.title3,
			'4': classes.title4,
			'5': classes.title5,
			'-1': classes.titleMin1,
			'-2': classes.titleMin2,
			'-3': classes.titleMin3,
		};
		return classMap[fontSizeLevel] || classes.title;
	};

	return (
		<Box style={{ position: 'absolute', background: 'transparent !important' }}>
			<Menu
				id="fade-menu"
				MenuListProps={{
					'aria-labelledby': 'fade-button',
				}}
				style={{ zIndex: '9' }}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				TransitionComponent={Fade}
				className={`${activeDislexic ? 'fontDislexic' : ''} ${activeContrast ? 'activeContrast' : ''}`}
			>
				{subMenuItems.map(
					(element) =>
						element.name.length > 0 && (
							<MenuItem
								sx={{ fontSize: 14 }}
								key={element.id}
								onClick={
									() => handleDir(element.path)
									// mainItem !== null
									// 	? navigate(`/${mainItem}/${element.path}`)
									// 	: navigate(`/${element.path}`)
								}
								className={`${getClasses()}`}
							>
								{element.name}
							</MenuItem>
						)
				)}
			</Menu>
		</Box>
	);
};

export default FloatMenu;
