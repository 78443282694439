/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState } from 'react';
import ModalLoading from '../../../components/ModalLoading';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';

import { dataReturnSavingsContext } from '../contextData';
import { IRequestAttachDocument, IRequestValidaCausaRaiz2 } from '../interfaces';
import { useStyles } from '../style';

import ModuloEfirma from '../../../components/Efirma';
import { IDatosEfirma } from '../../../interfaces/Efirma';
declare global {
	interface Window {
		firmar: any;
		validarFiel: any;
	}
}

const UploadCertificatesFiles = () => {
	const style = useStyles();
	const { user } = useSelector((state: RootState) => state.session);
	const [files, setFiles] = useState<{
		cer: any;
		key: any;
	}>({
		cer: {
			name: 'No se eligió ningún archivo',
			data: null,
			type: '',
			size: 0,
		},
		key: {
			name: 'No se eligió ningún archivo',
			data: null,
			type: '',
			size: 0,
		},
	});
	const [eFirmaData, setEFirmaData] = useState<IDatosEfirma>({
		keyBinary: '',
		cerBinary: '',
		cerB64: '',
		password: '',
	});
	const firmar = window.firmar;

	// var serverIP = 'https://serviciosweb.infonavit.org.mx:8993'; //QA
	const serverIP = process.env.REACT_APP_URL_VALIDA_FIEL; //prod

	const {
		handlePrevPage,
		data,
		handleValidaCausaRaiz,
		loading,
		alert,
		handlerAttachDocument,
		setPage,
		setDataEfirm,
		dataEfirm,
		setLoading,
		setPdfSigned,
		setAlert,
	} = useContext(dataReturnSavingsContext);

	const handleBack = () => {
		if (data?.ConsultaDatosDisponibilidadResponse?.data?.grupo === '1') {
			setPage(4);
			return;
		} else if (
			data?.ConsultaDatosDisponibilidadResponse?.data?.grupo !== '1'
		) {
			setPage(6);
			return;
		}
		handlePrevPage();
	};

	const handleSubmitFirmar = (dataEfir: any) => {
		var inputs = {
			keyBinary: dataEfir.keyBinary,
			cerBinary: dataEfir.cerBinary,
			cerB64: dataEfir.cerB64,
			pdfB64:
				data?.marcarCuentaResponse?.data?.pdf ||
				data?.ReintentoPDFAcuseResponse?.data?.documento,
			pdfContentType: 'application/pdf',
			password: dataEfir.password,
			serverIP: serverIP,
		};
		firmar(inputs, (error: any, response: any) => {
			if (error === null) {
				setLoading(false);
				setPdfSigned(true);
				handlerAttachDocument({
					caso: data?.noCaso,
					archivo: response.pdfSignedB64,
				} as IRequestAttachDocument);
			} else {
				setLoading(false);
				setAlert({
					show: true,
					message: error,
					severity: 'error',
				});
			}
		});
	};

	const confirmSubmit = () => {
		if (data?.isReintentoSolicitud) {
			let data = {
				keyBinary: eFirmaData.keyBinary,
				cerBinary: eFirmaData.cerBinary,
				cerB64: eFirmaData.cerB64 || '',
				password: eFirmaData.password,
			};
			handleSubmitFirmar(data);
		} else {
			setDataEfirm({
				keyBinary: eFirmaData.keyBinary,
				cerBinary: eFirmaData.cerBinary,
				cerB64: eFirmaData.cerB64 || '',
				pdfB64: '',
				pdfContentType: 'application/pdf',
				password: eFirmaData.password,
				serverIP: serverIP || '',
			});
			const params: IRequestValidaCausaRaiz2 = {
				rfc: user?.rfc ? user?.rfc : '',
				nombreCompleto: user?.given_name ? user?.given_name : '',
				curp: user?.curp ? user?.curp : '',
				password: eFirmaData.password,
				files: files,
			};
			handleValidaCausaRaiz(
				params,
				data?.isReintentoSolicitud === true ? dataEfirm : undefined
			);
		}
	};

	return (
		<>
			<ModalLoading loading={loading}></ModalLoading>
			<div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						marginBottom: '40px',
					}}
				>
					<p style={{ fontSize: '18px', fontWeight: 'bold' }}>
						La devolución de tus ahorros es viable.
					</p>
				</div>

				<ModuloEfirma
					efirmaData={eFirmaData}
					setEfirmaData={setEFirmaData}
					handleCancel={handleBack}
					handleAccept={confirmSubmit}
					labelAccept={'Continuar'}
					labelCancel={'Regresar'}
					files={files}
					setFiles={setFiles}
					hasError={alert}
				/>

				<h4 style={{ color: '#293990', lineHeight: '0px' }} className={style.maxContent}>
					* Datos obligatorios
				</h4>
			</div>
		</>
	);
};

export default UploadCertificatesFiles;
