/* eslint-disable react-hooks/exhaustive-deps */
// DEPENDENCIES
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper } from '@mui/material';
import { useSelector } from 'react-redux';
// COMPONENTS
import ModalConfirmation from '../../components/ModalConfirmation';
import ModalLoading from '../../components/ModalLoading';
import { HeaderList } from '../../components/HeaderList';
import CustomAlert from '../../components/CustomAlert';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import CustomButton from '../../components/Button';
import Layout from '../../components/Layout';
// RESOURCES
import { notariosAPI } from '../../api/modules/notaries';
import { IAlert } from '../../interfaces/alert';
import { keyAPI } from '../../api/modules/key';
import { encrypt } from '../../utils/encrypt';
import { RootState } from '../../store';
import { useStyles } from './styles';

const SelectionOfNotaries = () => {
	const { user, fontSizeLevel } = useSelector((state: RootState) => state.session);
	const [modalFile, setModalFile] = useState(false);
	const [loading, setLoading] = useState(false);
	const [select, setSelect] = useState(false);
	const [ver, setVer] = useState(false);
	const [url, setUrl] = useState('');
	const navigate = useNavigate();
	const classes = useStyles();
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	const finalice = () => {
		setVer(false);
		setSelect(true);
	};

	const handleOnSubmit = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(user?.nss.toString() || '', key);
			const correo_base64 = await encrypt(
				user?.email.toString() || '',
				key
			);
			const responseSesion = await notariosAPI.validateURL(
				nss_base64,
				correo_base64
			);
			if (responseSesion?.error) {
				setAlert({
					show: true,
					message: responseSesion.error.description
						? responseSesion.error.description
						: INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
			} else if (responseSesion?.result?.url) {
				setUrl(responseSesion.result.url + '&Medio=MCI');
				setModalFile(true);
			} else {
				setAlert({
					show: true,
					message:
						responseSesion?.result?.description ||
						INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
			}
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description ? error.description : INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<Layout updateTagText={updateTagText}>
			<div ref={divRef}>
				<HeaderList
					title="Selecciona tu notario"
					date="Información al 12 julio 2021"
					list={[]}
				/>
				{!ver && !select ? (
					<Paper className={classes.divPrincipal}>
						{/* <span className={classes.txtPrincipales}>
							<b>Selecciona tu notario</b>
						</span> */}
						<br></br>
						<br></br>
						<span className={classes.txtPrincipales}>
							Como parte del trámite para solicitar tu crédito
							debes elegir el notario que realizará el proceso de
							titulación.
						</span>
						<br></br>
						<br></br>
						<CustomAlert
							show={alert.show}
							severity={alert.severity}
							message={alert.message}
							onClose={() => {
								setAlert({
									show: false,
									message: '',
									severity: alert.severity,
								});
							}}
						/>
						<br></br>
						<br></br>
						<div className={classes.divContainer}>
							<div className={classes.divBtn}>
								<CustomButton
									onClick={handleOnSubmit}
									disabled={false}
									label="Ver lista"
									variant="solid"
								/>
							</div>
						</div>
					</Paper>
				) : ver && !select ? (
					<Paper className={classes.divPrincipal}>
						<span className={classes.txtBlue}>
							Detalle del notario
						</span>
						<div className={classes.divContent}>
							<div className={classes.divName}>
								<b>Clave</b>
							</div>
							<div className={classes.divServices}>123456</div>
							<div className={classes.divName}>
								<b>Nombre</b>
							</div>
							<div className={classes.divServices}>
								Rendon Arce Octaviano
							</div>
							<div className={classes.divName}>
								<b>Correo electrónico</b>
							</div>
							<div className={classes.divServices}>
								notario03@gmail.com
							</div>
							<div className={classes.divName}>
								<b>Ubicación</b>
							</div>
							<div className={classes.divUbicacion}>
								CECI Tlalnepantla Av. Presidente Juárez 2034,
								Los Reyes, 54073 Tlalnepantla, Mex.
							</div>
							<div className={classes.divMaps}>
								<iframe
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d119209.91650360182!2d-89.70295872773242!3d20.98021152517315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f56715cab450d17%3A0x5dfc155715abeb09!2sM%C3%A9rida%2C%20Yucatan!5e0!3m2!1sen!2smx!4v1646263139145!5m2!1sen!2smx"
									width="100%"
									height="300px"
									loading="lazy"
									style={{ border: 0 }}
									title="mapa"
								/>
							</div>
							<div className={classes.divName}>
								<b>Horario laboral</b>
							</div>
							<div className={classes.divServices}>
								09:00 a 14:00 y 16:00 a 19:00
							</div>
							<div className={classes.divName}>
								<b>Teléfono</b>
							</div>
							<div className={classes.divServices}>
								9876543234567
							</div>
						</div>
						<div className={classes.divContainer}>
							<div className={classes.divBtn}>
								<CustomButton
									onClick={() => setVer(false)}
									disabled={false}
									label="Regresar"
									variant="outlined"
								/>
							</div>
							<div className={classes.divBtn}>
								<CustomButton
									onClick={() => finalice()}
									disabled={false}
									label="Seleccionar"
									variant="solid"
								/>
							</div>
						</div>
					</Paper>
				) : (
					<Paper className={classes.divPrincipal}>
						<span className={classes.txtPrincipales}>
							<b>
								Gracias por utilizar los servicios electrónicos
								del Infonavit
							</b>
						</span>
						<br></br>
						<br></br>
						<span className={classes.txtPrincipales}>
							Es importante que tengas a la mano el siguiente
							código, el asesor te lo solicitará para concluir tu
							trámite
						</span>
						<br></br>
						<br></br>
						<div className={classes.divContainer}>
							<div className={classes.divBtn}>
								<CustomButton
									onClick={() => navigate('/')}
									disabled={false}
									label="Finalizar"
									variant="solid"
								/>
							</div>
						</div>
					</Paper>
				)}
				<ModalLoading loading={loading} />
				<ModalConfirmation
					open={modalFile}
					onClose={() => setModalFile(false)}
					width="xl"
					showTitle
				>
					<div>
						<iframe
							width="100%"
							height="550px"
							src={url}
							title="lista"
						/>
					</div>
				</ModalConfirmation>
			</div>
		</Layout>
	);
};

export default SelectionOfNotaries;
