/* eslint-disable react-hooks/exhaustive-deps */
// DEPENDENCIES
import { Box, Divider, Paper } from '@mui/material';

// COMPONENTS
import ModalLoading from '../../../../components/ModalLoading';
import CustomAlert from '../../../../components/CustomAlert';
import CustomButton from '../../../../components/Button';
import TextInput from '../../../../components/TextInput';
// RESOUCES
import { isNumber } from '../../../../utils/expressions';
// ASSETS
import { useStyles } from '../styles';

import { useNavigate } from 'react-router-dom';
import { useContactReference } from './hooks/useContactReference';

const ContactReference = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const {
		handleConfirm,
		handleClose,
		loader,
		alert,
		handleChange,
		reference,
		errors
	} = useContactReference();

	return (
		<>
			<ModalLoading loading={loader} />
			<Paper className={classes.paperContainer}>
				<Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
					<label className={classes.tituloAcordion}>
						Datos de referencia
					</label>
				</Box>
				<Divider />
				<div className={classes.mailformContainer}>
					<TextInput
						isRequired
						label="Nombre"
						id="name"
						name="name"
						value={reference.name}
						error={Boolean(errors.name)}
						helperText={errors.name}
						onChange={handleChange}
					/>
					<TextInput
						isRequired
						label="Apellido paterno"
						id="firstSurname"
						name="firstSurname"
						value={reference.firstSurname}
						error={Boolean(errors.firstSurname)}
						helperText={errors.firstSurname}
						onChange={handleChange}
					/>
					<TextInput
						isRequired
						label="Apellido materno"
						id="secondSurname"
						name="secondSurname"
						value={reference.secondSurname}
						error={Boolean(errors.secondSurname)}
						helperText={errors.secondSurname}
						onChange={handleChange}
					/>
					<TextInput
						isRequired
						label="Teléfono celular"
						id="phoneNumber"
						name="phoneNumber"
						value={reference.phoneNumber}
						error={Boolean(errors.phoneNumber)}
						helperText={errors.phoneNumber}
						onChange={(event) =>
							isNumber(event.target.value) && handleChange(event)
						}
					/>
				</div>
				<CustomAlert
					show={Boolean(alert.message)}
					severity={alert.color}
					message={alert.message}
					onClose={handleClose}
				/>
				<div className={classes.actionsContainer}>
					<CustomButton
						disabled={false}
						variant="outlined"
						onClick={() => navigate('/')}
						label="Salir"
					/>
					<CustomButton
						disabled={
							!Boolean(
								reference.name &&
								reference.firstSurname &&
								reference.secondSurname &&
								reference.phoneNumber
							) || Boolean(alert.message)
						}
						variant="solid"
						onClick={handleConfirm}
						label="Guardar cambios"
					/>
				</div>
				<div className={classes.containerRequiredFields}>
					<Divider />
					<p className={classes.requiredFieldsLabel}>
						* Datos obligatorios
					</p>
				</div>
			</Paper>
		</>
	);
};

export default ContactReference;
