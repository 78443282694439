/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import Layout from '../../components/Layout';
// import { HeaderList } from '../../components/HeaderList';
// import Layout from '../../components/Layout';
import DataForm from './dataForm';
import DataInfo from './dataInfo';
import DocumentList from './documentList';
import ImportantAspects from './importantAspects';
import NoDocuments from './noDocumentsFinish';
import UpLoadDocuments from './upLoadDocuments';

import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface ICaso {
	or_description?: string;
	or_fecha?: string;
	or_object_id?: string;
	or_status?: string;
	or_status_reason?: string;
	or_fecha_stat?: string;
	or_status_message?: string;
	descripcion_estatus?: string;
}

const DeedsDelivery = () => {
	const { fontSizeLevel } = useSelector((state: RootState) => state.session);
	const [page, setPage] = useState(0);
	const [caso, setCaso] = useState<ICaso>({});
	const [documentos, setDocumentos] = useState([]);

	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<Layout updateTagText={updateTagText}>
			<div ref={divRef}>
				{/* <HeaderList title="Entrega de escrituras" date="12 Julio 2021" /> */}
				{page === 0 && <ImportantAspects setPage={setPage} setCaso={setCaso} />}
				{page === 1 && (
					<DataForm setPage={setPage} setCaso={setCaso} caso={caso} />
				)}
				{page === 2 && <DataInfo setPage={setPage} caso={caso} />}
				{page === 3 && (
					<DocumentList
						setPage={setPage}
						setDocumentos={setDocumentos}
						documentos={documentos}
						caso={caso}
					/>
				)}
				{page === 4 && (
					<NoDocuments setPage={setPage} caso={caso} setCaso={setCaso} />
				)}
				{page === 5 && (
					<UpLoadDocuments
						setPage={setPage}
						caso={caso}
						documentos={documentos}
					/>
				)}
			</div>
		</Layout>
	);
};

export default DeedsDelivery;
