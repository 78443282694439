export const PAGES_RFC = {
	HOME: 0,
	FIRMA_ELECTRONICA: 1,
	NOMINA: 2,
	SAT: 3,
	CITAS: 4,
	ESTATUS: 5,
	CASO_CERRADO: 6,
	NO_CURP: 7,
	NO_NOMBRE: 9,
	REGISTRO_CURP: 10
}