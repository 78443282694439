/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomButton from '../../components/Button';
import CustomAlert from '../../components/CustomAlert';
import { useStyles } from './style';
import Link from '@mui/material/Link';
import FormAmounts from './FormAmounts';
import { currencyFormat } from '../../utils/expressions';

import Questionnaire from './Questionnaire';

interface Props {
	convenios: any;
	reestructuras: any;
	typeSelected: any;
	onNextPage: (data?: boolean) => void;
	onPrevPage: () => void;
	FactorDcp: (data: any) => void;
	alertGral: any;
	factorPagos: any;
	activeQuestionnaire: boolean;
	setActiveQuestionnaire: (data: any) => void;
}

const Product = ({
	convenios,
	onNextPage,
	reestructuras,
	typeSelected,
	onPrevPage,
	FactorDcp,
	alertGral,
	factorPagos,
	activeQuestionnaire,
	setActiveQuestionnaire,
}: Props) => {
	const columnas = [
		'Sección ' + typeSelected,
		'VSM',
		'Pesos',
		'VSM',
		'Pesos',
		'VSM',
		'Pesos',
	];
	const [titleContent, setTitleContent] = useState({
		title: '',
		messages: [''],
	});
	const [values, setValues] = useState({} as any);

	const calculateFactor = (data: any) => {
		FactorDcp(data);
	};

	const sendQuestionnaire = (data: any) => {
		setActiveQuestionnaire(false);
		FactorDcp(data);
	};

	useEffect(() => {
		let filtrado: any[] = [];
		filtrado = convenios.filter((e: any) => e.producto === typeSelected);
		if (typeSelected === 'STM') {
			setTitleContent({
				title: 'Solución a Tu Medida (STM)',
				messages: [
					`Solución a tu Medida. Con base en lo que debes y tu capacidad de pago, 
					el instituto fija la cantidad que pagarás durante 12 meses. Los intereses 
					ordinarios se sumarán a lo que debes de tu crédito y los moratorios se 
					condonarán. Si eres cumplido con los pagos, podrás renovarlo de acuerdo con 
					lo establecido en las normas y políticas vigentes.`,
				],
			});
			setValues(filtrado[0]);
		} else if (typeSelected === 'DCP') {
			setTitleContent({
				title: 'Dictamen de Capacidad de Pago Privado (DCP)',
				messages: [
					`Ofrecer un convenio de reestructura del factor de pago acorde 
				 	a la capacidad de pago de los o las acreditados(as) que presentan 
					problemas económicos y que requieran apoyo para continuar con el pago de su crédito`,
				],
			});
			setValues(filtrado[0]);
		} else if (typeSelected === 'BCP') {
			setTitleContent({
				title: 'Borrón y Cuenta Nueva por Convenio Privado (BCP)',
				messages: [
					`Ofrecer a las personas acreditadas una alternativa de regularización 
					en aquellos créditos que tengan pendiente de 1 a 9 mensualidades, 
					manteniendo el mismo factor de pago antes de aplicar a esta reestructura.`,
					`Al dar clic en CONTINUAR, podrás descargar el Convenio y revisar si 
					tu crédito es elegible a recibir un beneficio económico y las condiciones 
					para recibirlo en la cláusula “SEGUNDA. CAPITALIZACIÓN Y BENEFICIO ECONÓMICO”. 
					Si en la cláusula segunda de tu convenio, el título no incluye la sección 
					BENEFICIO ECONÓMICO, no eres elegible a recibirlo.`,
				],
			});
			setValues(filtrado[0]);
		} else if (typeSelected === 'BCN') {
			setTitleContent({
				title: 'Borrón y Cuenta Nueva (BCN)',
				messages: [
					`Ofrecer a los acreditados(as) una alternativa de regularización 
					que abarque todo los créditos con morosidad de 1 a 24 omisiones en ROA
					y REA, manteniendo el factor de pago contratado antes de la reestructura
					y capitalizando los intereses generados`,
				],
			});
			setValues(filtrado[0]);
		} else if (typeSelected === 'NTP') {
			setTitleContent({
				title: 'Nivela Tu Pago (NTP)',
				messages: [
					`Ofrecer un convenio de reestructura del factor de pago acorde a la 
					capacidad de pago de las personas acreditadas que presenten problemas 
					económicos y que requieran apoyo para continuar con el pago de su crédito.`,
				],
			});
			setValues(filtrado[0]);
		}
	}, [typeSelected, convenios, reestructuras]);

	const style = useStyles();
	return (
		<>
			{activeQuestionnaire && (
				<Questionnaire
					onNextPage={sendQuestionnaire}
					onPrevPage={onPrevPage}
				/>
			)}
			{!activeQuestionnaire && (
				<>
					<p className={`${style.bold_Center} ${style.maxContent} ${style.marginCenter}`}>{titleContent.title}</p>
					{titleContent.messages &&
						titleContent.messages.map((item: any, index: any) => (
							<p
								className={`${style.textAlign_Center} ${style.maxContent} ${style.marginCenter}`}
								style={{ padding: '0 60px' }}
								key={'textos' + index}
							>
								{item}
							</p>
						))}

					{(typeSelected === 'BCP' || typeSelected === 'NTP') && (
						<p
							className={`${style.textAlign_Center} ${style.maxContent} ${style.marginCenter}`}
							style={{ padding: '0 60px' }}
						>
							<span>
								Para más información sobre esta reestructura da clic{' '}
							</span>
							<Link
								href="https://portalmx.infonavit.org.mx/wps/portal/infonavitmx/mx2/derechohabientes/tengo-un-credito/soluciones_para_ti/!ut/p/z1/lZFLD4IwEIR_Uqc8tuVIICgSC43KoxfDyTRR9GD8_WLCxRBee9vsN8nMDjOsZqZrP_bWvu2za-_93hi60g7YRx5XuUgi6MSn0xEFZEas-gdkKUNoFSgnI9c5hMTM5DnNz-6gD4LY45mEU3AF6FLzOI0FfHjr9JiYEOv0kwHFRv0YMJv-NwbMfLyKmSWLc0BfkVgCaADmSlqy-XpcflPDpvYL7dw_tg!!/dz/d5/L2dBISEvZ0FBIS9nQSEh/"
								target="blank"
							>
								aquí.
							</Link>
						</p>
					)}

					<div style={{ margin: '50px 0 30px' }}>
						{typeSelected === 'DCP' && (
							<FormAmounts callEndPoint={calculateFactor} />
						)}
					</div>

					<Box className={style.table1}>
						<div>
							<div
								className="row"
								style={{
									display: 'flex',
									flexDirection: 'row',
									margin: '30px 0',
								}}
							>
								<div style={{ width: '14.28%' }}></div>
								<div className={style.tabla2TitulosDiv}>
									<div className={style.tabla2Titulos}>
										<p className={style.textGrayBox}>
											Condiciones actuales de tu crédito
										</p>
									</div>
								</div>
								<div className={style.tabla2TitulosDiv}>
									<div className={style.tabla2Titulos}>
										<p className={style.textGrayBox}>
											Condiciones si seleccionas <br />
											el apoyo de reestructura
										</p>
									</div>
								</div>
								<div className={style.tabla2TitulosDiv}>
									<div className={style.tabla2Titulos}>
										{typeSelected === 'NTP' && (
											<p className={style.textGrayBox}>
												Diferencia entre las
												condiciones.
												<br />
												<span className={style.textRed}>
													¹
												</span>
												Este monto es el beneficio
												económico que el Infonavit te
												otorga con NTP.
											</p>
										)}
										{typeSelected !== 'NTP' && (
											<p className={style.textGrayBox}>
												Diferencia entre las condiciones{' '}
												<br />
												actuales y con el apoyo de
												reestructura
											</p>
										)}
									</div>
								</div>
							</div>

							{/* Encabezados */}
							<div
								className="row"
								style={{
									display: 'flex',
									flexDirection: 'row',
									borderBottom: '1px solid #e1e1e1',
									padding: '10px 0',
									marginBottom: '5px',
								}}
							>
								{columnas.map((col, index) => (
									<div
										key={'encabezado' + index}
										className={style.tabla2Encabezados}
									>
										<p className={style.noMargin}>{col}</p>
									</div>
								))}
							</div>

							{/* REA */}
							<div
								className="row"
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									borderBottom: '1px solid #e1e1e1',
									padding: '10px 0',
								}}
							>
								<div
									className={style.tabla2Datos}
									style={{
										textAlign: 'left',
										paddingLeft: '20px',
										paddingRight: 0,
									}}
								>
									<p className={`${style.noMargin} ${style.maxContent}`}>Si pagas por tu cuenta (REA)</p>
								</div>
								<div className={style.tabla2Datos} style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<p className={`${style.noMargin} ${style.maxContent}`}>
										{typeSelected === 'BCP'
											? values.factorReaVsm
											: reestructuras.ctFacRea}
									</p>
								</div>
								<div className={style.tabla2Datos} style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<p className={`${style.noMargin} ${style.maxContent}`}>
										$
										{currencyFormat(
											typeSelected === 'BCP'
												? values.factorReaPesos
												: reestructuras.ctFacReaPes
										)}
									</p>
								</div>
								<div className={style.tabla2Datos} style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<p className={`${style.noMargin} ${style.maxContent}`}>
										{typeSelected === 'NTP'
											? factorPagos.factorReaVsm
											: values.factorReaVsm}
									</p>
								</div>
								<div className={style.tabla2Datos} style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<p className={`${style.noMargin} ${style.maxContent}`}>
										$
										{currencyFormat(
											typeSelected === 'NTP'
												? factorPagos.factorReaPesos
												: values.factorReaPesos
										)}
									</p>
								</div>
								<div className={style.tabla2Datos} style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<p className={`${style.noMargin} ${style.maxContent}`}>
										{typeSelected === 'BCP'
											? '0.000'
											: typeSelected === 'NTP'
												? factorPagos.difReaVsm
												: values.difReaVsm}
									</p>
								</div>
								<div className={style.tabla2Datos} style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<span className={style.textRed}>
										<b>¹</b>
									</span>{' '}
									<p className={`${style.noMargin} ${style.maxContent}`} style={{ display: 'inline' }}>
										$
										{currencyFormat(
											typeSelected === 'BCP'
												? '0.000'
												: typeSelected === 'NTP'
													? factorPagos.difReaPesos
													: values.difReaPesos
										)}
									</p>
								</div>
							</div>
							{/* ROA */}
							<div
								className="row"
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									borderBottom: '1px solid #e1e1e1',
									padding: '10px 0',
								}}
							>
								<div
									className={style.tabla2Datos}
									style={{
										textAlign: 'left',
										paddingLeft: '20px',
										paddingRight: 0,
									}}
								>
									<p className={`${style.noMargin} ${style.maxContent}`}>
										Si el descuento es por nómina (ROA)
									</p>
								</div>
								<div className={style.tabla2Datos} style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<p className={`${style.noMargin} ${style.maxContent}`}>
										{typeSelected === 'BCP'
											? values.factorRoaVsm
											: reestructuras.ctFacRoa}
									</p>
								</div>
								<div className={style.tabla2Datos} style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<p className={`${style.noMargin} ${style.maxContent}`}>
										$
										{currencyFormat(
											typeSelected === 'BCP'
												? values.factorRoaPesos
												: reestructuras.ctFacRoaPes
										)}
									</p>
								</div>
								<div className={style.tabla2Datos} style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<p className={`${style.noMargin} ${style.maxContent}`}>
										{typeSelected === 'NTP'
											? factorPagos.factorRoaVsm
											: values.factorRoaVsm}
									</p>
								</div>
								<div className={style.tabla2Datos} style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<p className={`${style.noMargin} ${style.maxContent}`}>
										$
										{currencyFormat(
											typeSelected === 'NTP'
												? factorPagos.factorRoaPesos
												: values.factorRoaPesos
										)}
									</p>
								</div>
								<div className={style.tabla2Datos} style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<p className={`${style.noMargin} ${style.maxContent}`}>
										{typeSelected === 'BCP'
											? '0.000'
											: typeSelected === 'NTP'
												? factorPagos.difRoaVsm
												: values.difRoaVsm}
									</p>
								</div>
								<div className={style.tabla2Datos} style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<p className={`${style.noMargin} ${style.maxContent}`}>
										$
										{currencyFormat(
											typeSelected === 'BCP'
												? '0.000'
												: typeSelected === 'NTP'
													? factorPagos.difRoaPesos
													: values.difRoaPesos
										)}
									</p>
								</div>
							</div>
						</div>
					</Box>

					<Typography
						sx={{
							fontWeight: 'bold',
							fontSize: 16.5,
							pt: 5,
							mb: 3,
						}}
						align="center"
					>
						Los montos aquí presentados NO consideran el costo de
						los accesorios y seguros. NO olvides agregarlos al
						momento de realizar tu pago. Para conocer el monto total
						de tu mensualidad, debes revisar en el menú Mi crédito,
						y seleccionar la opción Saldos y Movimientos.
					</Typography>

					<Box className={style.BoxAlert}>
						<CustomAlert
							message={alertGral?.message}
							severity={alertGral?.severity}
							show={alertGral?.show}
						/>
					</Box>

					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								width: '540px',
								paddingTop: '30px',
								paddingBottom: '50px',
							}}
						>
							<Box className={style.BoxButton}>
								<CustomButton
									onClick={onPrevPage}
									variant="outlined"
									label="Regresar"
									styles={{ height: '100%' }}
								/>
							</Box>
							<Box className={style.BoxButton}>
								<CustomButton
									onClick={() => {
										onNextPage(false);
									}}
									variant="solid"
									label="Continuar"
									disabled={
										(typeSelected === 'DCP' &&
											!factorPagos.show) ||
										alertGral?.show
									}
								/>
							</Box>
						</Box>
					</Box>
				</>
			)}
		</>
	);
};

export default Product;
