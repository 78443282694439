/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import { Paper, Box, Link } from '@mui/material';
import IconDes from '../../../../../assets/img/icono-descarga.png';
import { IProfileResponse } from '../../../../../api/modules/MIUC';
import Layout from '../../../../../components/Layout';
import { HeaderList } from '../../../../../components/HeaderList';
import CustomAlert from '../../../../../components/CustomAlert';
import CustomButton from '../../../../../components/Button';
import {
	CONSULTAR_CASO,
	INIT_PROFILE,
	URL_APLICACION,
} from '../../../../../config/miucConstants';
import ModalCancelWFI from '../../../components/ModalCancelWFI';
import { useStyles } from './styles/styles';
import { useDocumentComplete } from './hooks/useDocumentComplete';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

interface IDocumentComplete {
	data: IProfileResponse;
}

const DocumentComplete = (props: IDocumentComplete) => {
	const { data } = props;
	const classes = useStyles();
	const {
		getUrlWFI,
		openModalCancel,
		setOpenModalCancel,
		selectOption,
		setSelectOption,
		alert,
		setAlert,
		alertDocument,
		setAlertDocument,
		user,
	} = useDocumentComplete(props);

	const message =
		data.casoActivo?.tipificacion === INIT_PROFILE.MEJORAVIT ? (
			<p>
				Tu expediente se ha revisado y esta completo y correcto, al
				continuar obtendrás tu constancia de crédito y algunos
				documentos adicionales. <br /> <br /> Sigue las instrucciones
				respecto a lo que debe hacer tu patrón y una vez que esto
				suceda, debes hacer una cita para acudir al Centro de Servicio
				más cercano para formalizar tu crédito y finalizar con tu
				trámite.
			</p>
		) : (
			<p>
				Ahora puedes continuar con el registro de tu solicitud, para
				agilizar el trámite te sugerimos tener a la mano el formato de
				solicitud de crédito con la información necesaria, en caso
				contrario descargarlo.
			</p>
		);

	const { fontSizeLevel } = useSelector((state: RootState) => state.session);
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<Layout updateTagText={updateTagText}>
			<div ref={divRef}>
				<HeaderList
					title="Me interesa un crédito"
					list={['Registro de solicitud de crédito en línea']}
				/>
				<Paper>
					<Box padding={3} maxWidth={900} margin="auto">
						<h3 className={classes.textCenter}>
							Tu expediente está completo
						</h3>
						<p className={classes.margin20p0p}>{message}</p>

						{data.casoActivo?.tipificacion !==
							INIT_PROFILE.MEJORAVIT && (
								<Link
									href="https://portalmx.infonavit.org.mx/wps/wcm/connect/f2b56503-7a4c-44c3-9ef5-075b8325ae9e/Solicitud-inscripcion-credito.pdf?MOD=AJPERES&CVID=mV1qH37"
									target={'_blank'}
									underline="none"
									style={{
										padding: '20px 0px',
										display: 'flex',
										margin: 'auto',
										maxWidth: '200px',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									Descargar información
									<img
										src={IconDes}
										alt="pdflogo"
										style={{ marginLeft: 5 }}
									/>
								</Link>
							)}

						<p className={classes.margin20p0p}>
							<Link
								onClick={() => {
									setAlert({
										show: false,
										severity: 'error',
										message: '',
									});
									setAlertDocument({
										show: false,
										severity: 'error',
										message: '',
									});
									setOpenModalCancel(!openModalCancel);
								}}
							>
								Cancelar mi solicitud de crédito
							</Link>
						</p>
						{user?.estSol === CONSULTAR_CASO.INSCRITO ||
							(user?.estSol === CONSULTAR_CASO.PREAUTORIZADO && (
								<p className={classes.margin20p0p}>
									<Link
										onClick={() => {
											setAlertDocument({
												show: false,
												severity: 'error',
												message: '',
											});
											getUrlWFI(
												URL_APLICACION.REIMPRESION_WFI
											);
										}}
									>
										Impresión de documentos
									</Link>
								</p>
							))}
						<CustomAlert
							show={alertDocument.show}
							message={alertDocument.message}
							severity={alertDocument.severity}
						/>
						{data.urlWFI && <Box maxWidth={250} display="flex" margin="40px auto">
							<CustomButton
								label="Continuar"
								onClick={() => window.open(data.urlWFI, '_blank')}
								variant="solid"
							/>
						</Box>
						}

					</Box>
				</Paper>
				<ModalCancelWFI
					selectOption={selectOption}
					setSelectOption={setSelectOption}
					openModal={openModalCancel}
					setOpenModal={() => {
						setOpenModalCancel(!openModalCancel);
						setSelectOption('');
						setAlert({
							show: false,
							severity: 'error',
							message: '',
						});
					}}
					onContinue={() => getUrlWFI(URL_APLICACION.CANCELACION_WFI)}
					hasError={alert}
					isMejoravit={
						data.casoActivo?.tipificacion === INIT_PROFILE.MEJORAVIT
					}
				/>
			</div>
		</Layout>
	);
};

export default DocumentComplete;
