import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	titleNotice: {
		marginTop: 0,
		fontSize: 22,
		color: '#293990',
	},

	container: {
		borderRadius: 6,
		backgroundColor: 'white',
		padding: 20,
		paddingBlock: 50,
		marginTop: 30,
		boxShadow: '0px 5px 30px #00000017',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
	containerMin: {
		maxWidth: 900,
		display: 'flex',
		marginTop: '10px',
		marginBottom: '40px',
		[theme.breakpoints.down('md')]: {
			justifyContent: 'center',
		},
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap'
		},
	},
	phoneImage: {
		width: '90%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	containerImage: {
		width: '50%',
		display: 'flex',
		justifyContent: 'center',
		paddingLeft: '20%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			display: 'flex',
			justifyContent: 'flex-end',
			paddingLeft: '0',
		},
	},
	containerInfo: {
		width: '50%',
		paddingTop: '30px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	txtContainer: {
		width: '100%',
		maxWidth: 'max-content',
		color: '#000000',
		fontSize: 18,
		letterSpacing: 0,
		margin: 0,
		[theme.breakpoints.down('sm')]: {
			fontSize: 16,
			margin: '0 auto'
		},
	},
	normalText: {
		width: '100%',
		maxWidth: 'max-content',
		color: '#7F7F7F',
		fontSize: 16,
		marginTop: 0,
		[theme.breakpoints.down('sm')]: {
			margin: '0 auto'
		},
	},
	containerButtons: {
		display: 'flex',
		justifyContent: 'space-around',
		width: '500px',
		margin: '30px auto',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
	divChecks: {
		paddingTop: '5px',
	},
	iconsImg: {
		width: 15,
		marginRight: 5,
	},
	txtPrincipales: {
		color: '#333333',
		fontSize: '14px !important',
		verticalAlign: 'top',
		letterSpacing: 0,
		opacity: 1,
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px !important',
		},
	},
	alertContainer: {
		width: '90%',
	},
	txtDownload: {
		marginTop: '3%',
		marginBottom: '3%',
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: '12px',
	},
	containerStore: {
		width: '20%',
		display: 'flex',
		justifyContent: 'space-around',
		[theme.breakpoints.down('md')]: {
			marginTop: '10px',
			width: '100%',
			alignItems: 'center',
		},
	},
	stores: {
		width: '40px',
	},
}));
