import { Box } from '@mui/material';
import CustomButton from '../../../../components/Button';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import ModalLoading from '../../../../components/ModalLoading';
import { CODE_STATUS, MSG_ERROR } from '../utils';
import CustomAlert from '../../../../components/CustomAlert';
import { writingRequestAPI } from '../../../../api/modules/WritingRequest';
import { encodeAllJSONData } from '../../../../utils/encrypt';
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';
import { useEscrituras } from '../../hooks/useEscrituras';
import { CASE_FOLLOW_STATUS } from '../utils/pagesStatus';
import { useStyles } from '../styles/group-buttons-styles';

interface IProps {
	setPage: (arg: number) => void;
	nextProcess: () => void;
}

const GroupButtons = ({ setPage, nextProcess }: IProps) => {
	const { user } = useSelector((state: RootState) => state.session);
	const navigate = useNavigate();
	const styles = useStyles();
	const { escrituras, getConsultDetail } = useEscrituras();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState('');

	const NextDocuments = async () => {
		try {
			setMessage('');
			setLoading(true);
			const data = {
				caso: escrituras.noCaso,
			};
			const dataEncripted = await encodeAllJSONData(
				{ data: JSON.stringify(data) },
				user?.k || ''
			);
			const { result } = await writingRequestAPI.adjuntaDocumento(
				dataEncripted
			);
			if (result && Number(result?.code) === 0) {
				const resDetail = await getConsultDetail({});
				if (resDetail === '') {
					return;
				}
			}
			setMessage(MSG_ERROR);
		} catch (error) {
			setMessage(MSG_ERROR);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<ModalLoading loading={loading} />

			<CustomAlert
				message={message}
				severity="warning"
				show={Boolean(message)}
			/>

			<Box className={styles.containerButtons}>
				<Box className={styles.buttonItem}>
					<CustomButton
						label={'Salir'}
						onClick={() => navigate('/')}
						variant={
							escrituras.estatus === CODE_STATUS.ESPERA_DOCUMENTOS
								? 'text'
								: escrituras.estatus ===
								  CODE_STATUS.ESCRITURAS_RECIBIDAS_CESI
								? 'outlined'
								: 'solid'
						}
					/>
				</Box>

				{escrituras.estatus === CODE_STATUS.ESPERA_DOCUMENTOS && (
					<>
						<Box className={styles.buttonItem}>
							<CustomButton
								label={'Continuar sin documentos'}
								variant="outlined"
								onClick={NextDocuments}
							/>
						</Box>

						<Box className={styles.buttonItem}>
							<CustomButton
								label={'Adjuntar Documentos'}
								onClick={() =>
									setPage(CASE_FOLLOW_STATUS.ATTACH_DOCUMENTS)
								}
								variant="solid"
							/>
						</Box>
					</>
				)}

				{escrituras.estatus ===
					CODE_STATUS.ESCRITURAS_RECIBIDAS_CESI && (
					<Box className={styles.buttonItem}>
						<CustomButton
							label={'Hacer cita'}
							variant="solid"
							onClick={() => nextProcess()}
						/>
					</Box>
				)}
			</Box>
		</>
	);
};

export default GroupButtons;
