import { IBitacora } from '../interfaces/bitacora';
import { ENDPOINTS_ACLARACION_DE_PAGOS } from '../config/bitacora/aclaracion-de-pagos';
import { ENDPOINTS_ACTUALIZACION_DATOS_CONTACTO } from '../config/bitacora/actualizar-datos-contacto';
import { ENDPOINTS_AVISO_SUSPENSION_RETENCION_Y_MODIFICACION_DE_DESCUENTOS } from '../config/bitacora/aviso-suspension-retencion-y-modificacion-de-descuentos';
import { ENDPOINTS_CALCULADORA_DE_PESOS_UNIDAD_MEDIDA_UMA } from '../config/bitacora/calculadora-de-pesos-unidad-medida-uma';
import { ENDPOINTS_CALCULADORA_DEL_AHORRO } from '../config/bitacora/calculadora-del-ahorro';
import { ENDPOINTS_CAMBIAR_CONTRASENA } from '../config/bitacora/cambiar-contrasena';
import { ENDPOINTS_CARTA_DE_CANCELACION_DE_HIPOTECA } from '../config/bitacora/carta-de-cancelacion-de-hipoteca';
import { ENDPOINTS_CONFIRMACION_DE_TRAMITE } from '../config/bitacora/confirmacion-de-tramite';
import { ENDPOINTS_CONSTANCIA_DE_ECOTECNOLOGIAS } from '../config/bitacora/constancia-de-ecotecnologias';
import { ENDPOINTS_CONSTANCIA_INTERES_PARA_TU_DECLARACION_ANUAL } from '../config/bitacora/constancia-interes-para-tu-declaracion-anual';
import { ENDPOINTS_CONSULTA_RELACIONES_LABORABLES } from '../config/bitacora/consulta-relaciones-laborables';
import { ENDPOINTS_CORRECCION_CURP_NOMBRE } from '../config/bitacora/correccion-curp-nombre';
import { ENDPOINTS_CUANTO_AHORRO_TENGO } from '../config/bitacora/cuanto-ahorro-tengo';
import { ENDPOINTS_DESVINCULA_DISPOSITIVO_MOVIL } from '../config/bitacora/desvincula-dispositivo-movil';
import { ENDPOINTS_DEVOLUCION_DE_MIS_AHORROS } from '../config/bitacora/devolucion-de-mis-ahorros';
import { ENDPOINTS_DEVOLUCION_PAGOS_EN_EXCESO } from '../config/bitacora/devolucion-pagos-en-exceso';
import { ENDPOINTS_ENTREGA_ESCRITURAS } from '../config/bitacora/entrega-escritura';
import { ENDPOINTS_HAZ_APORTACIONES_EXTRAORDINARIAS } from '../config/bitacora/haz-aportaciones-extraordinarias';
import { ENDPOINTS_HAZ_UNA_CITA } from '../config/bitacora/haz-una-cita';
import { ENDPOINTS_HAZ_Y_SIGUE_TU_QUEJA } from '../config/bitacora/haz-y-sigue-tu-queja';
import { ENDPOINTS_HOGAR_A_TU_MEDIDA } from '../config/bitacora/hogar-a-tu-medida';
import { ENDPOINTS_NIP_INFONATEL } from '../config/bitacora/nip-infonatel';
import { ENDPOINTS_OFICINAS_DE_ANTENCION } from '../config/bitacora/oficinas-de-atencion';
import { ENDPOINTS_REGISTRO_CORRECCION_RFC } from '../config/bitacora/registro-correccion-rfc';
import { ENDPOINTS_RESPONSABILIDAD_COMPARTIDA } from '../config/bitacora/responsabilidad-compartida';
import { ENDPOINTS_RESUMEN_MOVIMIENTOS_MI_AHORRO } from '../config/bitacora/resumen-movimientos-mi-ahorro';
import { ENDPOINTS_SALDOS_Y_MOVIMIENTOS } from '../config/bitacora/saldos-y-movimientos';
import { ENDPOINTS_SELECCIONA_TU_NOTARIO } from '../config/bitacora/selecciona-tu-notario';
import { ENDPOINTS_SIGUE_TU_CASO_Y_ADJUNTA_DOCUMENTOS } from '../config/bitacora/sigue-tu-caso-y-adjunta-documentos';
import { ENDPOINTS_SOCIO_INFONAVIT } from '../config/bitacora/socio-infonavit';
import { ENDPOINTS_SOLICITUD_DE_PRORROGA } from '../config/bitacora/solicitud-de-prorroga';
import { ENDPOINTS_SOLICITUD_DE_RESTRUCTURA } from '../config/bitacora/solicitud-de-restructura';
import { ENDPOINTS_SUMAR_CREDITOS } from '../config/bitacora/sumar-creditos';
import { ENDPOINTS_TALLER_MAS_PARA_DECIDIR_MEJOR } from '../config/bitacora/taller-mas-para-decidir-mejor';
import { ENDPOINTS_PRECALIFICACION_PUNTOS } from './bitacora/precalificacion-de-puntos';
import { ENDPOINTS_LOGIN } from './bitacora/login';
import { ENDPOINTS_RESTABLECE_TU_CONTRASENIA } from './bitacora/restablece-tu-contrasenia';
import { ENDPOINTS_REGISTRO } from './bitacora/registros';
import { ENDPOINTS_INICIO } from './bitacora/inicio';
import { ENDPOINTS_IMPERSONALIZACION } from './bitacora/impersonalizacion';
import { ENDPOINTS_CONFIRMACION_DE_CORREO } from './bitacora/confirmacion-de-correo';

export const VARIABLES_BITACORA: IBitacora = {
	// --------------- Confirmación de Correo -----------
	...ENDPOINTS_CONFIRMACION_DE_CORREO,
	// --------------- Impersonalizacion -----------
	...ENDPOINTS_IMPERSONALIZACION,
	// --------------- Registro -----------
	...ENDPOINTS_REGISTRO,
	// --------------- Inicio -----------
	...ENDPOINTS_INICIO,
	// --------------- Login -----------
	...ENDPOINTS_LOGIN,
	// --------------- Cambiar-contraseña -----------
	...ENDPOINTS_CAMBIAR_CONTRASENA,
	// --------------- Restablecer tu contraseña -----------
	...ENDPOINTS_RESTABLECE_TU_CONTRASENIA,
	// --------------- Actualizar-datos-contacto -----------
	...ENDPOINTS_ACTUALIZACION_DATOS_CONTACTO,
	// --------------- sumar-créditos -----------
	...ENDPOINTS_SUMAR_CREDITOS,
	// --------------- registro-corrección-rfc -----------
	...ENDPOINTS_REGISTRO_CORRECCION_RFC,
	// --------------- corrección-curp-nombre -----------
	...ENDPOINTS_CORRECCION_CURP_NOMBRE,
	// --------------- socio-infonavit -----------
	...ENDPOINTS_SOCIO_INFONAVIT,
	// --------------- desvincula-tu-dispositivo-Movil -----------
	...ENDPOINTS_DESVINCULA_DISPOSITIVO_MOVIL,
	// --------------- Cuanto ahorro tengo -----------
	...ENDPOINTS_CUANTO_AHORRO_TENGO,
	// --------------- Haz aportaciones extraordinarias -----------
	...ENDPOINTS_HAZ_APORTACIONES_EXTRAORDINARIAS,
	// --------------- Calculadora del ahorro -----------
	...ENDPOINTS_CALCULADORA_DEL_AHORRO,
	// --------------- Resumen de movimientos de mi ahorro -----------
	...ENDPOINTS_RESUMEN_MOVIMIENTOS_MI_AHORRO,
	//---------------- Consulta-relaciones-laborales ---------------
	...ENDPOINTS_CONSULTA_RELACIONES_LABORABLES,
	//---------------- Devolución de mis ahorros ---------------
	...ENDPOINTS_DEVOLUCION_DE_MIS_AHORROS,
	//---------------- Seleccion tu notario ---------------
	...ENDPOINTS_SELECCIONA_TU_NOTARIO,
	//---------------- Confirmacion de tramite ---------------
	...ENDPOINTS_CONFIRMACION_DE_TRAMITE,
	//---------------- Taller mas para decidir mejor ---------------
	...ENDPOINTS_TALLER_MAS_PARA_DECIDIR_MEJOR,
	//---------------- Hogar a tu medida ---------------
	...ENDPOINTS_HOGAR_A_TU_MEDIDA,
	//---------------- Saldos y movimientos ---------------
	...ENDPOINTS_SALDOS_Y_MOVIMIENTOS,
	//---------------- Aviso y suspencion y modificacion de descuentos ---------------
	...ENDPOINTS_AVISO_SUSPENSION_RETENCION_Y_MODIFICACION_DE_DESCUENTOS,
	//---------------- Constancia de interes para tu declaracion anual ---------------
	...ENDPOINTS_CONSTANCIA_INTERES_PARA_TU_DECLARACION_ANUAL,
	//---------------- Constancia de ecotecnologias ---------------
	...ENDPOINTS_CONSTANCIA_DE_ECOTECNOLOGIAS,
	//---------------- Devolucion de pagos en exceso ---------------
	...ENDPOINTS_DEVOLUCION_PAGOS_EN_EXCESO,
	//---------------- Responsabilidad Compartida ---------------
	...ENDPOINTS_RESPONSABILIDAD_COMPARTIDA,
	//---------------- Entrega de escrituras ---------------
	...ENDPOINTS_ENTREGA_ESCRITURAS,
	//---------------- Carta de instruccion de cancelacion de hipoteca ---------------
	...ENDPOINTS_CARTA_DE_CANCELACION_DE_HIPOTECA,
	//---------------- Solicitud de reestructura ---------------
	...ENDPOINTS_SOLICITUD_DE_RESTRUCTURA,
	//---------------- Solicitud de prorroga ---------------
	...ENDPOINTS_SOLICITUD_DE_PRORROGA,
	//---------------- Aclaracion de pagos ---------------
	...ENDPOINTS_ACLARACION_DE_PAGOS,
	//---------------- Sigue tu caso y adjunta documentos ---------------
	...ENDPOINTS_SIGUE_TU_CASO_Y_ADJUNTA_DOCUMENTOS,
	//---------------- Haz y sigue tu queja ---------------
	...ENDPOINTS_HAZ_Y_SIGUE_TU_QUEJA,
	//---------------- Oficinas de atencion ---------------
	...ENDPOINTS_OFICINAS_DE_ANTENCION,
	//---------------- Haz una cita ---------------
	...ENDPOINTS_HAZ_UNA_CITA,
	//---------------- Calculadora de pesos y unidad de medida y actualizacion (UMA) ---------------
	...ENDPOINTS_CALCULADORA_DE_PESOS_UNIDAD_MEDIDA_UMA,
	//---------------- NIP Infonatel ---------------
	...ENDPOINTS_NIP_INFONATEL,
	//---------------- Precalificacion Puntos -------------
	...ENDPOINTS_PRECALIFICACION_PUNTOS,
};
