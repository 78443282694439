import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('110', 'desvincula-tu-dispositivo-movil');

export const ENDPOINTS_DESVINCULA_DISPOSITIVO_MOVIL: IBitacora = {
	'/vinculacion/verifica-vinculacion': _bitacora.Read(
		'110100',
		'Verificar vinculación'
	),
	'/vinculacion/realiza-desvinculacion': _bitacora.Update(
		'110101',
		'Realizar desvinculación'
	),
	'/vinculacion/envio-sms': _bitacora.Read(
		'110102',
		'Enviar Mensaje de texto'
	),
	'/vinculacion/envio-email': _bitacora.Read('110103', 'Enviar Correo'),
	'/vinculacion/valida-codigo': _bitacora.Read('110104', 'Validar código'),
	'/vinculacion/consultar-vinculacion': _bitacora.Read(
		'110105',
		'Consultar Dispositivos'
	),
};
