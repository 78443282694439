import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: '24px',
    },
    title: {
        fontWeight: 500,
    },
	subContainer: {
		maxWidth: 950,
        margin: '0px auto',

        [theme.breakpoints.down('md')]: {
            maxWidth: 'auto',
        },
	},
    buttons: {
		maxWidth: 250,
        margin: '20px auto',

        [theme.breakpoints.down('md')]: {
            maxWidth: 'auto',
        },
	},
}));
