import React, { useEffect, useState } from 'react';

import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import useCustomScript from '../../hooks/useCustomScript';

import { IDatosEfirma, IResponseEfirmaValidation } from '../../interfaces/Efirma';
import { IAlert } from '../../interfaces/alert';
import { useMediaQuery } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useStyles } from './styles';
import { useTheme } from '@mui/material/styles';

import CustomButton from '../Button';
import TextInput from '../TextInput';
import ModalLoading from '../../components/ModalLoading';
import BaseModal from '../../components/Modal';
import warning from '../../assets/img/icono-advertencia-rojo.png';
import CustomAlert from '../../components/CustomAlert';

interface IModuloEfirma {
	efirmaData: IDatosEfirma;
	setEfirmaData: (data: any) => void;
	handleCancel: () => void;
	handleAccept: () => void;
	labelAccept: string;
	labelCancel: string;
	files: any;
	setFiles: (data: any) => void;
	hasError: IAlert;
}

declare global {
	interface Window {
		firmar: any;
		validarFiel: any;
	}
}

const messageErrorName = 'Tu firma electrónica avanzada tiene asociado un nombre diferente al que tenemos registrado, actualiza estos datos con los servicios en línea de Mi Cuenta Infonavit. Si el problema persiste, haz una cita en cualquiera de nuestros centros de servicio.'
const messageErrorCurp = 'Tu firma electrónica avanzada tiene asociada una CURP diferente a la que tenemos registrada, actualiza estos datos con los servicios en línea de Mi Cuenta Infonavit. Si el problema persiste, haz una cita en cualquiera de nuestros centros de servicio.'
const messageErrorRfc = 'Tu firma electrónica avanzada tiene asociado un RFC diferente al que tenemos registrado, actualiza estos datos con los servicios en línea de Mi Cuenta Infonavit. Si el problema persiste, haz una cita en cualquiera de nuestros centros de servicio.'
const messageErrorVigencia = 'El certificado ha expirado. Acude al SAT a renovarlo.'

const ModuloEfirma = ({
	efirmaData,
	setEfirmaData,
	handleCancel,
	handleAccept,
	labelAccept,
	labelCancel,
	files,
	setFiles,
	hasError
}: IModuloEfirma) => {
	const { user } = useSelector((state: RootState) => state.session);
	const style = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [loading, setLoading] = useState(false);
	const [filesName, setFilesName] = useState({
		cerName: 'No se eligió ningún archivo',
		keyName: 'No se eligió ningún archivo'
	})
	const validarFiel = window.validarFiel;
	const [alert, setAlert] = useState({
		show: false,
		severity: 'success',
		message: '',
	} as IAlert);
	const [errorPasswordEfirma, setErrorPasswordEfirma] = useState({
		msg: '',
	});
	const [modalEfirma, setModalEfirma] = useState({
		show: false,
		date: ''
	})

	useCustomScript('/jquery/jquery.min.js');
	useCustomScript('/efirma/firma-documento.js');
	useCustomScript('/efirma/fiel-validacion.ofu.min.js');

	// var serverIP = 'https://serviciosweb.infonavit.org.mx:8993'; //QA
	const serverIP = process.env.REACT_APP_URL_VALIDA_FIEL; //prod

	useEffect(() => {
		if (hasError && hasError.show) {
			setAlert(hasError)
		}
	}, [hasError])

	const handlePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setAlert({
			show: false,
			message: '',
			severity: 'success',
		});
		if (event.target.value.length > 40) return;
		const { value } = event.target;
		if (value === '') {
			setErrorPasswordEfirma({
				msg: 'Escribe la contraseña de la e.firma.'
			})
		} else {
			setErrorPasswordEfirma({
				msg: ''
			})
		}
		setEfirmaData((prev: any) => ({ ...prev, password: value }));
	};

	const handleCerFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const fileList = event.target.files;
		if (!fileList) return;

		const type = fileList[0].type;
		if (/cer/g.test(type)) {
			setAlert({
				show: false,
				message: '',
				severity: 'success',
			});
		} else {
			setAlert({
				show: true,
				message: 'Verifica que el formato del archivo es el correcto. El formato correcto debe ser .cer.',
				severity: 'error',
			});
			return;
		}

		const readerFile = new FileReader();
		readerFile.onload = function (event) {
			setEfirmaData((prev: any) => ({ ...prev, cerBinary: event.target?.result }));
			setFilesName({
				...filesName,
				cerName: fileList[0].name
			})
			const b64 = window.btoa(
				new Uint8Array(event.target?.result as ArrayBuffer).reduce(
					(data, byte) => data + String.fromCharCode(byte),
					''
				)
			);
			setEfirmaData((prev: any) => ({ ...prev, cerB64: b64 }));
		};
		readerFile.readAsArrayBuffer(fileList[0]);

		setFiles({
			...files,
			[event.target.name]: {
				name: fileList[0].name,
				data: fileList[0],
				type: type,
			},
		});
	};

	const handleKeyFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const fileList = event.target.files;
		if (!fileList) return;
		let nameArray = fileList[0].name.split('.')
		const type = fileList[0].type;
		if (/key/g.test(type || nameArray[nameArray.length - 1])) {
			setAlert({
				show: false,
				message: '',
				severity: 'success',
			});
		} else {
			setAlert({
				show: true,
				message: 'Verifica que el formato del archivo es el correcto. El formato correcto debe ser .key.',
				severity: 'error',
			});
			return;
		}
		const readerFile = new FileReader();
		readerFile.onload = function (event) {
			setEfirmaData((prev: any) => ({ ...prev, keyBinary: event.target?.result }));
			setFilesName({
				...filesName,
				keyName: fileList[0].name
			})
		};
		readerFile.readAsArrayBuffer(fileList[0]);

		setFiles({
			...files,
			[event.target.name]: {
				name: fileList[0].name,
				data: fileList[0],
				type: type,
			},
		});
	};

	const handleDeleteKeyFile = () => {
		setEfirmaData((prev: any) => ({ ...prev, keyBinary: '' }));
		setFilesName({
			...filesName,
			keyName: 'No se eligió ningún archivo'
		})
	};

	const handleDeleteCerFile = () => {
		setEfirmaData((prev: any) => ({ ...prev, cerB64: '', cerBinary: '' }));
		setFilesName({
			...filesName,
			cerName: 'No se eligió ningún archivo'
		})
	};

	const handleSubmit = (i: number) => {
		if (i >= 5) {
			setLoading(false);
			setAlert({
				show: true,
				message: 'Por el momento el servicio no está disponible. Intenta más tarde.',
				severity: 'error',
			});
			return;
		}
		setLoading(true);
		const inputs = {
			keyBinary: efirmaData.keyBinary,
			cerBinary: efirmaData.cerBinary,
			cerB64: efirmaData.cerB64,
			password: efirmaData.password,
			serverIP: serverIP,
			pdfB64: '',
			pdfContentType: 'application/pdf',
		};
		if (typeof validarFiel === "function") {
			validarFiel(inputs, (error: any, response: IResponseEfirmaValidation) => {
				if (error === null) {
					if (response.valido === true) {
						if (response.curp === user?.curp) {
							if (response.rfc === user?.rfc) {
								if (response.nombre === user?.given_name) {
									setLoading(false);
									setModalEfirma({
										show: true,
										date: response?.fechaVigenciaFin ? response?.fechaVigenciaFin : ''
									})
								} else {
									setLoading(false);
									setAlert({
										show: true,
										message: messageErrorName,
										severity: 'error',
									});
								}
							} else {
								setLoading(false);
								setAlert({
									show: true,
									message: messageErrorRfc,
									severity: 'error',
								});
							}
						} else {
							setLoading(false);
							setAlert({
								show: true,
								message: messageErrorCurp,
								severity: 'error',
							});
						}
					} else {
						if (response.mensajeValidacion.includes('vigente')) {
							setLoading(false);
							setAlert({
								show: true,
								message: messageErrorVigencia,
								severity: 'error',
							});
						} else {
							setLoading(false);
							setAlert({
								show: true,
								message: response.mensajeValidacion,
								severity: 'error',
							});
						}
					}
				} else {
					if (error === 'No se proporcionó un pdf a firmar') {
						handleSubmit(i + 1);
					} else {
						setLoading(false);
						setAlert({
							show: true,
							message: error,
							severity: 'error',
						});
					}
				}
			});
		} else {
			setLoading(false);
			setAlert({
				show: true,
				message: 'Por el momento el servicio no está disponible. Intenta más tarde.',
				severity: 'error',
			});
		}
	};

	return (
		<div>
			<ModalLoading loading={loading}></ModalLoading>

			<div style={{ display: 'inline' }}>
				<hr className={style.hr} />
				<div
					className={style.container}
					style={{
						alignItems: matches ? 'normal' : 'center',
						paddingBottom: matches ? 20 : 10,
						flexDirection: efirmaData.cerBinary && matches ? 'column' : 'inherit',
					}}>
					<h3 className={style.label} style={{ width: matches ? '100%' : '30%', textAlign: matches ? 'center' : 'left' }}>
						Certificado (.cer)
						<label style={{ color: 'red' }}>*</label>
					</h3>
					<p
						className={style.fileName}
						style={{
							width: matches ? '100%' : 0,
							order: matches && !efirmaData.cerBinary ? 3 : 2,
							textAlign: matches ? 'center' : 'left',
						}}>
						{filesName.cerName}
					</p>
					<div className={style.options} style={{ width: matches ? '100%' : 300 }}>
						<label
							onClick={handleDeleteCerFile}
							style={{
								display: efirmaData.cerBinary ? 'flex' : 'none',
								alignItems: 'center',
								margin: matches ? '5px 0px' : '0px 5px',
								cursor: 'pointer',
								justifyContent: matches ? 'center' : 'right',
							}}
						>
							<ClearIcon style={{ fontSize: 20, marginRight: 2 }} />
							Eliminar archivo
						</label>
						<label
							style={{
								cursor: 'pointer',
								display: 'flex',
								alignItems: 'center',
								margin: matches ? '5px 0px' : '0px 5px',
								justifyContent: matches ? 'center' : 'right',
							}}>
							<label
								style={{
									display: efirmaData.cerBinary ? 'none' : 'flex',
									cursor: 'pointer',
									paddingBottom: matches ? 25 : 0,
								}}>
								<input
									type="file"
									accept=".cer"
									name="cer"
									onChange={handleCerFile}
									className={style.chooseFileOK}
								/>
								Elegir archivo
							</label>
						</label>
					</div>
				</div>
				<hr className={style.hr} />

				<div className={style.container}
					style={{
						alignItems: matches ? 'normal' : 'center',
						paddingBottom: matches ? 20 : 10,
						flexDirection: efirmaData.cerBinary && matches ? 'column' : 'inherit',
					}}>
					<h3 className={style.label} style={{ width: matches ? '100%' : '30%', textAlign: matches ? 'center' : 'left' }}>
						Clave privada (.key)
						<label style={{ color: 'red' }}>*</label>
					</h3>
					<p className={style.fileName}
						style={{
							width: matches ? '100%' : 0,
							order: matches && !efirmaData.cerBinary ? 3 : 2,
							textAlign: matches ? 'center' : 'left',
						}}>
						{filesName.keyName}
					</p>
					<div className={style.options} style={{ width: matches ? '100%' : 300 }}>
						<label
							onClick={handleDeleteKeyFile}
							style={{
								display: efirmaData.keyBinary ? 'flex' : 'none',
								alignItems: 'center',
								margin: matches ? '5px 0px' : '0px 5px',
								cursor: 'pointer',
								justifyContent: matches ? 'center' : 'right',
							}}>
							<ClearIcon style={{ fontSize: 20, marginRight: 2 }} />
							Eliminar archivo
						</label>
						<label
							style={{
								cursor: 'pointer',
								display: 'flex',
								alignItems: 'center',
								margin: matches ? '5px 0px' : '0px 5px',
								justifyContent: matches ? 'center' : 'right',
							}}>
							<label
								style={{
									display: efirmaData.keyBinary ? 'none' : 'flex',
									cursor: 'pointer',
									paddingBottom: matches ? 25 : 0,
								}}>
								<input
									type="file"
									accept=".key"
									name="key"
									onChange={handleKeyFile}
									className={style.chooseFileOK}
								/>
								Elegir archivo
							</label>
						</label>
					</div>
				</div>
				<hr className={style.hr} />

				<div style={{ paddingBottom: matches ? 20 : 10, paddingTop: 20 }}>
					<h3 className={style.label} style={{ width: matches ? '100%' : '30%', textAlign: matches ? 'center' : 'left' }}>
						Contraseña de clave privada
						<label style={{ color: 'red' }}>*</label>
					</h3>
					<div className={style.inputContainerFirmElec}>
						<TextInput
							data-testid="privPass"
							id="privPass"
							name="privPass"
							type="password"
							label=""
							value={efirmaData.password}
							onChange={handlePassword}
							helperText={errorPasswordEfirma.msg}
						/>
					</div>
				</div>

				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<div className={style.divButtons}>
						<div className={style.Buttons}>
							<CustomButton
								onClick={() => handleCancel()}
								variant="outlined"
								styles={{ width: '100%', height: '100%' }}
								label={labelCancel}
							/>
						</div>

						<div className={style.Buttons}>
							<CustomButton
								onClick={() => { handleSubmit(1) }}
								variant="solid"
								styles={{ width: '100%' }}
								disabled={efirmaData.cerBinary.size === 0 || efirmaData.keyBinary.size === 0 || efirmaData.password.length === 0 || alert.show}
								label={labelAccept}
							/>
						</div>
					</div>
				</div>

				<div>
					<CustomAlert
						show={alert.show}
						severity={alert.severity}
						message={alert.message}
					/>
				</div>

				<hr className={style.hr} />
			</div>

			<BaseModal
				title={
					<span style={{ color: '#293990', textAlign: 'center', display: 'block' }}>
						Firma electrónica
					</span>
				}
				open={modalEfirma.show}
				width="sm"
				disableOnConfirm={true}
				onClose={() => {
					setModalEfirma({
						show: false,
						date: ''
					})
				}}
			>
				<div style={{ textAlign: 'center' }}>
					<div style={{ textAlign: 'center' }}>
						<img src={warning} alt="warning" />
					</div>
					<p>Tu firma electrónica avanzada vence el:</p>
					<p>{modalEfirma.date.substring(0, 10)}</p>
					<div
						style={{
							display: 'flex',
							width: 540,
							justifyContent: 'center',
							margin: '20px auto 10px',
						}}
					>
						<div style={{ width: 260 }}>
							<CustomButton
								label="Continuar"
								variant="solid"
								onClick={() => {
									setModalEfirma({
										show: false,
										date: ''
									})
									handleAccept()
								}}
							/>
						</div>
					</div>
				</div>
			</BaseModal>
		</div>
	);
};

export default ModuloEfirma;