/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useStyles } from '../../styles';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store';
import { actions } from '../../../../store/modules/session';

import IconDislexia from '../../../../assets/img/iconsToolAccessibility/iconDislexia-1.svg';
import IconDislexiaActive from '../../../../assets/img/iconsToolAccessibility/iconDislexia-2.svg';

interface Props {
	isDislexic: boolean;
	setIsDislexic: (data: boolean) => void;
}

const Dislexia = ({ isDislexic, setIsDislexic }: Props) => {
	const { activeDislexic } = useSelector((state: RootState) => state.session);
	const dispatch = useDispatch();
	const classes = useStyles();

	useEffect(() => {
		setIsDislexic(activeDislexic);
	}, []);

	return (
		<div>
			<div className={`${classes.containerItem} ${isDislexic ? classes.itemActive : ''}`}
				onClick={() => {
					setIsDislexic(!isDislexic);
					dispatch(actions.changeDislexic(!isDislexic));
				}}>
				<div className={`${classes.iconItem} ${isDislexic ? classes.iconItemActive : ''}`}>
					<img src={!isDislexic ? IconDislexia : IconDislexiaActive} alt="Cambio de tipografía dislexia" />
				</div>
				<label className={classes.labelItem}>Cambio de tipografía dislexia</label>
			</div>
		</div>
	)
}

export default Dislexia;