import React from 'react';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import { ITabElement } from '../../interfaces/tabElement';

//Assets
import { useStyles } from './styles';
import Check from '../../assets/img/check_red.png';

export interface ITab {
	tabs: ITabElement[];
	containerPosition: number;
	active: number;
	onChangeTab?: (value: number, tab: object) => void;
	children?: React.ReactElement;
	containerColumns?: number;
	cardsType?: boolean;
}

const Tabs = (props: ITab) => {
	const {
		tabs,
		containerPosition,
		children,
		onChangeTab,
		active,
		containerColumns,
		cardsType,
	} = props;
	const classes: any = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const { fontSizeLevel } = useSelector((state: RootState) => state.session);

	const getClasses = () => {
		const classMap: any = {
			'0': classes.title,
			'1': classes.title1,
			'2': classes.title2,
			'3': classes.title3,
			'4': classes.title4,
			'5': classes.title5,
			'-1': classes.titleMin1,
			'-2': classes.titleMin2,
			'-3': classes.titleMin3,
		};
		return classMap[fontSizeLevel] || classes.title;
	};

	return (
		<div className={classes.container}>
			<Grid
				className={classes.containerTabs}
				container
				columns={containerColumns || 12}
				gridColumn={containerColumns || 12}
			// spacing={0}
			>
				{tabs.map((tab, indexTab) => {
					return (
						<Grid
							key={indexTab}
							className={`${cardsType
								? classes.tabHeaderCard
								: classes.tabHeader
								} ${classes[
								`tabOrder${matches ? tab.orderSM : tab.orderMD
								}`
								]
								}`}
							item
							xs={containerColumns || 12}
							sm={containerColumns || 12}
							md={tab.columns}
							lg={tab.columns}
							xl={tab.columns}
						>
							<div
								className={
									cardsType
										? classes.containerSubItemTabCard
										: classes.containerSubItemTab
								}
							>
								<button
									disabled={tab.disabled}
									name={tab.title}
									onClick={() =>
										onChangeTab &&
										onChangeTab(indexTab, tab)
									}
									className={`${active === indexTab
										? cardsType
											? classes.buttonTabCard
											: classes.buttonTab
										: tab.checked
											? cardsType
												? classes.buttonTabCheckedCard
												: classes.buttonTabChecked
											: cardsType
												? classes.buttonTabDisabledCard
												: classes.buttonTabDisabled
										} ${getClasses()} tab-item`}
								>
									{tab.title}
									{tab.checked && (
										<img
											src={Check}
											alt="check"
											style={{
												marginLeft: 10,
												width: 20,
											}}
										/>
									)}
								</button>
							</div>
						</Grid>
					);
				})}
				<Grid
					className={
						cardsType
							? classes.containerInformationCard
							: classes.containerInformation
					}
					style={{
						order: matches ? containerPosition : 7,
					}}
					item
					xs={containerColumns || 12}
					sm={containerColumns || 12}
					md={containerColumns || 12}
					lg={containerColumns || 12}
					xl={containerColumns || 12}
				>
					{children}
				</Grid>
			</Grid>
		</div>
	);
};

export default Tabs;
