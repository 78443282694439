import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import { StaticDatePicker, LocalizationProvider } from '@mui/lab';
import moment, { MomentInput } from 'moment';
import { PickersDayProps } from '@mui/lab/PickersDay/PickersDay';
import es from 'date-fns/locale/es';
import { CustomPickersDay } from '../../../SaldAndMovements/Style';

interface IProps {
	fecha: Date | null;
	meses: any[];
	onChange: (arg: any) => void;
	onMonthChange?: (arg: any) => void;
}

interface DaysFormat {
	startday: MomentInput | number;
	endday: MomentInput | number;
	typeday: string | null;
}

const CalenderCustom = ({ fecha, meses, onChange, onMonthChange }: IProps) => {
	const renderWeekPickerDay = (
		date: Date,
		selectedDates: Array<Date | null>,
		pickersDayProps: PickersDayProps<Date>
	) => {
		const dayCalender = moment(date);
		let selectDate: DaysFormat = {
			startday: null,
			endday: null,
			typeday: null,
		};

		meses.forEach((day) => {
			if (
				dayCalender.isBetween(
					moment(day.startday),
					moment(day.endday)
				) ||
				dayCalender.isSame(moment(day.startday)) ||
				dayCalender.isSame(moment(day.endday))
			) {
				selectDate = {
					startday: moment(day.startday).format('DD/MM/YYYY'),
					endday: moment(day.endday).format('DD/MM/YYYY'),
					typeday: day.typeday,
				};
			}
		});

		const isFirstDay =
			dayCalender.format('DD/MM/YYYY') === selectDate.startday
				? true
				: false;
		const isLastDay =
			dayCalender.format('DD/MM/YYYY') === selectDate.endday
				? true
				: false;

		return (
			<CustomPickersDay
				{...pickersDayProps}
				disableMargin
				isFirstDay={isFirstDay}
				isLastDay={isLastDay}
				typeDay={selectDate.typeday}
			/>
		);
	};

	const disabledDays = (date: Date) => {
		let disabled = true;
		if (meses.length > 0) {
			const dayCalender = moment(date);
			meses.forEach((day) => {
				const valid = dayCalender.isBetween(
					moment(day.startday),
					moment(day.endday)
				);
				if (
					day.typeday !== 'baja' &&
					(valid ||
						dayCalender.isSame(moment(day.startday)) ||
						dayCalender.isSame(moment(day.endday)))
				) {
					disabled = false;
				}
			});
		} else {
			disabled = true;
		}
		return disabled;
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
			<StaticDatePicker
				displayStaticWrapperAs="desktop"
				openTo="day"
				onMonthChange={onMonthChange}
				minDate={new Date()}
				value={fecha}
				onChange={onChange}
				renderInput={(params) => (
					<TextField style={{ margin: 0 }} {...params} />
				)}
				renderDay={renderWeekPickerDay}
				shouldDisableDate={disabledDays}
			/>
		</LocalizationProvider>
	);
};

export default CalenderCustom;
