export const LABELS = {
    HOME_NOW: 'Solicitud de escritura',
    HOME_OLD: 'Entrega de escrituras'
}

export const CODE_RESPONSE = {
    SUCCESS: '0000',
}

export const CODE_DEEDS = {
    EXIST_CASE: '0000',
    NOT_EXIST_CASE: '0001',
}

export const CODE_VALID_GENERIC = {
    ERROR: '0000',
    SUCCESS: '0001',
}

export const REQUEST_PAGES_STATUS = { 
    EDITION: 0,  
    SELECTION_BRANCH: 1,
}

export const WRITING_REQUEST_STATUS = {
    HOME: 0,
    PROCESS: 1,
    PAGE_OLD: 3,
}

export const PROCESS_PAGE_STATUS = {
    FORM_EDITION: 0,
    HISTORY_CASE: 1,
    CREATE_CESI: 2,
    FINISH_PAGE: 3
}

export const CASE_FOLLOW_STATUS = { 
    HOME: 0,  
    ATTACH_DOCUMENTS: 1,
}

export const CESI_QUOTE_STATUS = { 
    GENERAL: 0,  
    DETAIL: 1,
}