import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	containerMain: {
		padding: 30,
	},
	containerPaper: {
		borderRadius: '0px !important',
	},
	divider: {
		marginBottom: 20,
	},
	containerMessage: {
		width: '100%',
		display: 'flex',
		textAlign: 'center',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: 20,
	},
	labelImportant: {
		fontSize: 17,
		textAlign: 'center',
		'& > label': {
			color: theme.palette.primary.main,
			fontWeight: '500',
		},
	},
}));
