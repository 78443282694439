import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useStyles } from './styles';

type IProps = {
	date: string;
};

const SubheaderDate = ({ date }: IProps) => {
	const classes = useStyles();

	function camelize(str: string) {
		return str
			.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: string, index: number) {
				return index === 0 ? word.toLowerCase() : word.toUpperCase();
			})
			.replace(/\s+/g, '');
	}

	return (
		<Box className={classes.container}>
			<Typography sx={{ fontSize: 12 }} variant="body2">
				<span className={classes.text}>Fecha de último acceso:</span>{' '}
				<span>
					{camelize(date.replace(' ', ' / ').replaceAll('.', ''))
						.replace('/', ' - ')
						.replace('/', ' - ')
						.replace('/', ' / ')
						.replace('Pm', ' PM')
						.replace('Am', ' AM')}
				</span>
			</Typography>
		</Box>
	);
};

export default SubheaderDate;
