/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Paper, useMediaQuery } from '@mui/material';
import React from 'react';
import { HeaderList } from '../../components/HeaderList';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../../components/Button';
import { useStyles } from './styles';
import { useNavigate } from 'react-router-dom';

interface Props {
	setPage: (data: number) => void;
}

const NotName = ({ setPage }: Props) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const classes = useStyles();
	const navigate = useNavigate();
	return (
		<Box>
			<HeaderList title="Correción de RFC" date="12 Julio 2021" />
			<Paper sx={{ pt: 4, pb: 4, pr: matches ? 5 : 10, pl: matches ? 5 : 10 }}>
				<div style={{ paddingBottom: matches ? 10 : 25, textAlign: 'center' }}>
					<p className={`${classes.maxContent} ${classes.marginCenter}`}>
						EL nombre y/o apellido(s) asociados al RFC en el SAT, no
						corresponden con los registrados en el Infonavit. Ingresa a la
						opción de corrección de CURP y nombre y verifica tus datos,
						posteriormente podrás intentar nuevamente el tramite de corrección
						de RFC.
					</p>
					<p className={`${classes.maxContent} ${classes.marginCenter}`}>
						Si quieres verificar tus datos registrados en el SAT ingresa{' '}
						<a href="#" style={{ color: 'red' }}>
							aquí
						</a>
					</p>
				</div>
				<div className={classes.botonContainer}>
					<div>
						<CustomButton
							data-testid="buttonNo"
							label="Salir"
							onClick={() => setPage(0)}
							variant="outlined"
							styles={{
								width: matches ? 340 : 260,
								height: 40,
								marginBottom: 5,
							}}
						/>
					</div>
					<div>
						<CustomButton
							data-testid="buttonSí"
							label="Corrección de CURP y nombre"
							onClick={() => navigate('/mi-perfil/correccion-curp-nombre')}
							variant="solid"
							styles={{
								width: matches ? 340 : 260,
								height: 40,
								marginBottom: matches ? 20 : 5,
								marginLeft: matches ? 0 : 25,
							}}
						/>
					</div>
				</div>
			</Paper>
		</Box>
	);
};

export default NotName;
