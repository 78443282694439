/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useStyles } from '../../styles';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store';
import { actions } from '../../../../store/modules/session';

import IconCursor from '../../../../assets/img/iconsToolAccessibility/iconCursor-1.svg';
import IconCursorActive from '../../../../assets/img/iconsToolAccessibility/iconCursor-2.svg';

interface Props {
	zoomCursor: boolean;
	setZoomCursor: (data: boolean) => void;
}

const CursorZoom = ({ zoomCursor, setZoomCursor }: Props) => {
	const { zoomCursorActive } = useSelector((state: RootState) => state.session);
	const dispatch = useDispatch();
	const classes = useStyles();

	useEffect(() => {
		setZoomCursor(zoomCursorActive);
	}, []);

	return (
		<div>
			<div
				className={`${classes.containerItem} ${zoomCursor ? classes.itemActive : ''}`}
				onClick={() => {
					setZoomCursor(!zoomCursor);
					dispatch(actions.changeZoomCursor(!zoomCursor));
				}}>
				<div className={`${classes.iconItem} ${zoomCursor ? classes.iconItemActive : ''}`}>
					<img src={!zoomCursor ? IconCursor : IconCursorActive} alt="Cambiar tamaño del cursor" />
				</div>
				<label className={classes.labelItem}>Cambiar tamaño del cursor</label>
			</div>
		</div>
	)
}

export default CursorZoom;