import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	iconsImg: {
		width: 20,
		marginRight: 5,
	},
	txtPrincipales: {
		color: '#333333',
		fontSize: '16px !important',
		verticalAlign: 'top',
		letterSpacing: 0,
		opacity: 1,
		[theme.breakpoints.down('sm')]: {
			fontSize: '14px !important',
		},
	},
	divPrincipal: {
		padding: '40px 80px',
		[theme.breakpoints.down('sm')]: {
			padding: '30px',
		},
	},
	divBtn: {},
	divSelect: {
		marginLeft: '25%',
		marginRight: '25%',
		[theme.breakpoints.down('sm')]: {
			marginLeft: '0',
			marginRight: '0',
		},
		[theme.breakpoints.down('md')]: {
			marginLeft: '0',
			marginRight: '0',
		},
	},
	divSelectBTN: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginLeft: '25%',
		marginRight: '25%',
		marginTop: '20px',
		[theme.breakpoints.down('sm')]: {
			marginLeft: '0',
			marginRight: '0',
			flexDirection: 'column',
			justifyContent: 'space-around',
		},
		[theme.breakpoints.down('md')]: {
			marginLeft: '0',
			marginRight: '0',
			flexDirection: 'column',
			justifyContent: 'space-around',
		},
	},
	divSelectBTNdiv: {
		width: '240px',
		height: 40,
		[theme.breakpoints.down('sm')]: {
			marginTop: '10px',
			minWidth: '100%',
		},
		[theme.breakpoints.down('md')]: {
			marginTop: '10px',
			minWidth: '100%',
		},
	},
	maxContent: {
		width: '100%',
		maxWidth: 'max-content'
	},
	noMargin: {
		marginTop: 0,
		marginBottom: 0
	}
}));
