import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

//Assets
import { useStyles } from './styles';
import { RoundBtn } from '../../pages/AssociateNssCredit/Styles';
export interface IModalProps {
	open: boolean;
	title?: React.ReactChild;
	cancelButton?: boolean;
	width?: 'md' | 'lg' | 'xl' | 'sm' | 'xs';
	onClose?: () => void;
	onCancel?: () => void;
	onConfirm?: () => void;
	cancelLabel?: string;
	confirmLabel?: string;
	disableOnConfirm?: boolean;
	showDialogTitle?: boolean;
	showDialogActions?: boolean;
	color?:
	| 'primary'
	| 'inherit'
	| 'secondary'
	| 'success'
	| 'error'
	| 'info'
	| 'warning'
	| undefined;
	children?: React.ReactChild;
	stylesContent?: React.CSSProperties;
	stylesActions?: React.CSSProperties;
	hideCloseButton?: boolean;
	hideCircularProgress?: boolean;
}

const BaseModal = (props: IModalProps) => {
	const {
		open,
		title,
		cancelButton,
		width = 'md',
		onClose,
		onCancel,
		onConfirm,
		cancelLabel,
		confirmLabel,
		disableOnConfirm,
		showDialogTitle,
		color,
		children,
		showDialogActions,
		stylesContent,
		stylesActions,
		hideCloseButton,
		hideCircularProgress,
	} = props;
	const { activeDislexic, zoomCursorActive, activeGrayScale, activeContrast, fontSizeLevel } = useSelector((state: RootState) => state.session);

	const classes = useStyles();

	const getClass = (type: 'title' | 'subtitle' | 'buttonLargeSize') => {
		const classMap: any = {
			'0': classes[`${type}`],
			'1': classes[`${type}1`],
			'2': classes[`${type}2`],
			'3': classes[`${type}3`],
			'4': classes[`${type}4`],
			'5': classes[`${type}5`],
			'-1': classes[`${type}Min1`],
			'-2': classes[`${type}Min2`],
			'-3': classes[`${type}Min3`],
		};
		return classMap[fontSizeLevel] || classes[type];
	};

	return (
		<Dialog
			open={open}
			fullWidth
			onClose={onClose}
			maxWidth={width}
			className={`${zoomCursorActive ? 'custom-cursor' : ''} ${activeDislexic ? 'fontDislexic' : ''} ${activeGrayScale ? 'grayScales' : ''} ${activeContrast ? 'activeContrast' : ''}`}
			PaperProps={{
				elevation: 1,
			}}
			TransitionProps={{
				style: {
					backgroundColor: 'rgb(0 0 0 / 50%)',
				},
			}}
		>
			<DialogTitle
				className={classes.header}
				style={{ display: showDialogTitle ? 'none' : 'flex' }}
			>
				{title && (
					<p className={getClass('title')}>{title}</p>
				)}
				{/* <IconButton disabled={disableOnConfirm} onClick={onClose}> */}
				{!hideCloseButton && (
					<IconButton
						onClick={onClose}
						style={{ position: 'absolute', top: 20, right: 20 }}
						className='tag-to-read' aria-label="Cerrar modal"
					>
						<CloseIcon />
					</IconButton>
				)}
			</DialogTitle>
			<DialogContent style={{ ...stylesContent }} className={fontSizeLevel !== 0 ? getClass('subtitle') : ''}>
				{children}
			</DialogContent>
			{cancelButton || onConfirm ? (
				<DialogActions
					style={{
						...stylesActions,
						display: showDialogActions ? 'none' : 'flex',
						justifyContent: 'space-evenly',
					}}
				>
					{cancelButton && (
						<RoundBtn
							className={`${classes.buttonLarge} ${getClass('buttonLargeSize')}`}
							disableElevation
							color={color}
							variant="outlined"
							//disabled={disableOnConfirm}
							onClick={onCancel ? onCancel : onClose}
						>
							{cancelLabel || 'Cancelar'}
						</RoundBtn>
					)}
					{onConfirm && (
						<RoundBtn
							disabled={disableOnConfirm}
							color={color}
							disableElevation
							variant="contained"
							className={`${classes.buttonLarge} ${getClass('buttonLargeSize')}`}
							onClick={onConfirm}
							endIcon={
								disableOnConfirm &&
								!hideCircularProgress && (
									<CircularProgress size={18} />
								)
							}
						>
							{confirmLabel || 'Aceptar'}
						</RoundBtn>
					)}
				</DialogActions>
			) : null}
		</Dialog>
	);
};

export default BaseModal;
