import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('140', 'haz-una-cita');

export const ENDPOINTS_HAZ_UNA_CITA: IBitacora = {
	'/v1/citas-24/obtener-servicios-disponibles': _bitacora.Read(
		'140100',
		'Obtener servicios disponibles'
	),
	'/v1/citas-24/funcion/obtener-servicios-disponibles': _bitacora.Read(
		'140101',
		'Obtener servicios disponibles sin sesión'
	),
	'/v1/citas-24/obtener-regiones': _bitacora.Read(
		'140102',
		'Obtener regiones'
	),
	'/v1/citas-24/funcion/obtener-regiones': _bitacora.Read(
		'140103',
		'Obtener regiones sin sesión'
	),
	'/v1/citas-24/obtener-sucursales': _bitacora.Read(
		'140104',
		'Obtener sucursales'
	),
	'/v1/citas-24/funcion/obtener-sucursales': _bitacora.Read(
		'140105',
		'Obtener sucursales sin sesión'
	),
	'/v1/citas-24/obtener-programacion-mensual': _bitacora.Read(
		'140106',
		'Obtener programacion mensual'
	),
	'/v1/citas-24/funcion/obtener-programacion-mensual': _bitacora.Read(
		'140107',
		'Obtener programacion mensual sin sesión'
	),
	'/v1/citas-24/obtener-programacion-laboral': _bitacora.Read(
		'140108',
		'Obtener programa laboral'
	),
	'/v1/citas-24/funcion/obtener-programacion-laboral': _bitacora.Read(
		'140109',
		'Obtener programa laboral sin sesión'
	),
	'/v1/citas-24/reservar-cita': _bitacora.Update('140110', 'Reservar cita'),
	'/v1/citas-24/funcion/reservar-cita': _bitacora.Update(
		'140111',
		'Reservar cita sin sesión'
	),
	'/v1/citas-24/crear-cita': _bitacora.Create('140112', 'Crear cita'),
	'/v1/citas-24/funcion/crear-cita': _bitacora.Create(
		'140113',
		'Crear cita sin sesión'
	),
	'/v1/citas-24/obtener-cita': _bitacora.Read('140114', 'Obtener cita'),
	'/v1/citas-24/funcion/obtener-cita': _bitacora.Read(
		'140115',
		'Obtener cita sin sesión'
	),
	'/v1/citas-24/cancelar-cita': _bitacora.Update('140116', 'Cancelar cita'),
	'/v1/citas-24/funcion/cancelar-cita': _bitacora.Update(
		'140117',
		'Cancelar cita sin sesión'
	),
	'/v1/citas-24/obtener-requisitos': _bitacora.Read(
		'140118',
		'Obtener requisitos'
	),
	'/v1/citas-24/funcion/obtener-requisitos': _bitacora.Read(
		'140119',
		'Obtener requisitos sin sesión'
	),
	'/v1/citas-24/generar-comprobante': _bitacora.Read(
		'140120',
		'Generar comprobante'
	),
	'/v1/citas-24/funcion/generar-comprobante': _bitacora.Read(
		'140121',
		'Generar comprobante sin sesión'
	),
};
