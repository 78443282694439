import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			justifyContent: 'space-between',
			flexWrap: 'wrap',
			paddingTop: 20,
		},
		label: {
			color: 'black',
			fontSize: 16,
			order: 1,
			margin: 0,
		},
		options: {
			textDecoration: 'none',
			color: '#D1001F',
			paddingTop: 0,
			fontSize: 16,
			order: 3,
		},
		fileName: {
			flexGrow: 1,
			margin: 0,
			opacity: 0.5,
			padding: '0 20px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap'
		},
		chooseFileOK: {
			[theme.breakpoints.up('xs')]: {
				display: 'none',
				width: '30%',
			},
			[theme.breakpoints.up('sm')]: {
				display: 'none',
				width: '30%',
			},
			[theme.breakpoints.up('md')]: {
				display: 'none',
				width: '30%',
			},
		},
		hr: {
			width: '100%',
			opacity: 0.1,
			marginBottom: 0,
		},
		inputContainerFirmElec: {
			marginTop: '-20px',
			width: '40%',
			display: 'flex',
			marginLeft: 260,
			marginBottom: 30,
			[theme.breakpoints.down('md')]: {
				margin: '20px auto'
			},
		},
		divButtons: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			marginBottom: '20px',
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column-reverse',
			}
		},
		Buttons: {
			width: 260,
			margin: '30px 10px 0',
			height: 40,
			[theme.breakpoints.down('md')]: {
				margin: '30px auto 0'
			},
		},
	})
);
