import {
	CheckCircleOutlined,
	Info,
	PriceChangeOutlined,
	WarningAmber,
	CheckCircleOutline,
} from '@mui/icons-material';
import { Grid } from '@mui/material';
import CustomButton from '../../../../components/Button';
import { useStyles } from './styles';
import { useContextMoment } from '../../context';
import { formatMoney3 } from '../../../../utils/validators';
import { BALANCES_MAIN_PAGE, CODES, dateFormat } from '../../utils';
import { useNavigate } from 'react-router-dom';

interface IProps {
	onlyHeader?: boolean;
}

const CardTopInfo = ({ onlyHeader }: IProps) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const { balance, setPageMain } = useContextMoment();
	return (
		<>
			{onlyHeader ? (
				<div className={classes.titleContainerOnly}>
					<h2>Saldos y movimientos</h2>
				</div>
			) : (
				<>
					<div className={classes.titleContainer}>
						<h2>Saldos y movimientos</h2>
					</div>
					<label className={classes.titleDate}>
						Fecha de actualización:{' '}
						{balance?.datosGenerales?.fechaActualizacion &&
							dateFormat(
								balance?.datosGenerales?.fechaActualizacion
							)}
					</label>
					<div className={classes.containerTooltipTitle}>
						<Info color="info" className={classes.tooltipIcon} />
						<label className={classes.labelTooltipTitle}>
							Tu pago se refleja de 3 a 5 días hábiles posteriores
							a la fecha en que lo efectuaste y puedes consultarlo
							en el apartado de Movimientos y estado de cuenta.
						</label>
					</div>
					<Grid container marginBottom={7} spacing={2}>
						<Grid
							item
							xs={12}
							md={7}
							className={classes.containerDivider}
						>
							<p className={classes.listItem}>
								<CheckCircleOutlined
									color="info"
									style={{ marginRight: 5, width: 22 }}
								/>
								<label style={{ marginTop: 2.45 }}>
									En este servicio encuentras información
									necesaria de tu crédito; saldo, mensualidad,
									pagos y estado de cuenta.
								</label>
								<br />
							</p>
						</Grid>
						<Grid item xs={12} md={5}>
							<div className={classes.containerInfoHeader}>
								<div className={classes.subContainerInfoHeader}>
									<label
										className={classes.labelInfoHeaderTitle}
									>
										Derechohabiente
									</label>
									<label
										className={classes.labelInfoHeaderInfo}
									>
										{balance?.datosGenerales
											?.derechohabiente || '---'}
									</label>
								</div>
								<div className={classes.subContainerInfoHeader}>
									<label
										className={classes.labelInfoHeaderTitle}
									>
										Tipo de crédito
									</label>
									<label
										className={classes.labelInfoHeaderInfo}
									>
										{balance?.datosGenerales?.tipoCredito ||
											'---'}
									</label>
								</div>
								<div className={classes.subContainerInfoHeader}>
									<label
										className={classes.labelInfoHeaderTitle}
									>
										Monto total del crédito
									</label>
									<label
										className={classes.labelInfoHeaderInfo}
									>
										{formatMoney3(
											balance?.datosGenerales
												?.montoTotalCredito || '0'
										)}
									</label>
								</div>
								<div className={classes.subContainerInfoHeader}>
									<label
										className={classes.labelInfoHeaderTitle}
									>
										Mensualidades vencidas
									</label>
									<label
										className={classes.labelInfoHeaderInfo}
									>
										{balance?.datosGenerales
											?.mensualidadesVencidas || '---'}
									</label>
								</div>
							</div>
							<div className={classes.containerButtons}>
								<CustomButton
									styles={{ borderRadius: 5, height: 50 }}
									variant="outlined"
									label="Aclaraciones"
									disabled={
										!balance?.datosGenerales
											?.opcionAclaraciones
									}
									icon={
										<WarningAmber
											style={{ marginRight: 10 }}
										/>
									}
									onClick={() =>
										navigate('/mi-credito/aclaracion-pagos')
									}
								/>
								{[
									CODES.LIQUIDADOS_POR_PAGO,
									CODES.LIQUIDADOS_OTROS,
								].includes(balance.propiedades?.tipoCaso) && (
									<div className={classes.cardButton}>
										<CheckCircleOutline
											style={{ marginRight: 10 }}
										/>
										<label>Crédito liquidado</label>
									</div>
								)}
								{![
									CODES.LIQUIDADOS_POR_PAGO,
									CODES.LIQUIDADOS_OTROS,
								].includes(balance.propiedades?.tipoCaso) && (
									<CustomButton
										styles={{ borderRadius: 5, height: 50 }}
										label="Pagar mi crédito"
										icon={
											<PriceChangeOutlined
												style={{ marginRight: 10 }}
											/>
										}
										disabled={
											!balance?.datosGenerales
												?.opcionPagarCredito
										}
										onClick={() =>
											setPageMain(
												BALANCES_MAIN_PAGE.PAYMENT
											)
										}
									/>
								)}
							</div>
						</Grid>
					</Grid>
				</>
			)}
		</>
	);
};

export default CardTopInfo;
