/* eslint-disable react-hooks/exhaustive-deps */
// DEPENDENCIES
import React, { useState, useEffect, useRef } from 'react';
import { Paper } from '@mui/material';
// COMPONENTS
import { HeaderList } from '../../components/HeaderList';
import Layout from '../../components/Layout';
import Tabs from '../../components/Tabs';
//  VIEWS
import Procedure from './Procedure';
import Questions from './Questions';
// RESOURCES
import { formatDate } from '../../utils/dates';
import { tabsOptions } from './utils';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const SharedResponsability = () => {
	const [selectedTab, setSelectedTab] = useState(0);
	const classes = useStyles();
	const { fontSizeLevel } = useSelector((state: RootState) => state.session);
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (
				divRef.current &&
				fontSizeLevel !== undefined &&
				fontSizeLevel !== 0
			) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<Layout updateTagText={updateTagText}>
			<div ref={divRef}>
				<HeaderList
					title="Responsabilidad Compartida"
					date={formatDate('P')}
				/>
				<Tabs
					tabs={tabsOptions}
					onChangeTab={(index, tab) => setSelectedTab(index)}
					active={selectedTab}
					containerPosition={selectedTab + 1}
				>
					<Paper className={classes.paperContainer}>
						{selectedTab === 0 && <Procedure />}
						{selectedTab === 1 && <Questions />}
					</Paper>
				</Tabs>
			</div>
		</Layout>
	);
};

export default SharedResponsability;
