/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import { Paper } from '@mui/material';
import { useContext } from 'react';
import { HeaderList } from '../../components/HeaderList';
import Layout from '../../components/Layout';
import CaseInformationPage from './pages/caseInformationPage';

import { Dataprovider } from './providerData';
import { useStyles } from './style';
import { getHeaderList } from './utils';
import InfoPage from './pages/infoPage';
import ReturnOptions from './pages/returnOptions';
import NRPRequestPage from './pages/NRPRequest';
import SavingsInformation from './pages/savingsInformationPage';
import SelectReturnMethod from './pages/SelectReturnMethodPage';
import SelectStateClaim from './pages/selectStateClaim';
import QuestionDemand from './pages/questionDemand';
import SelectTypeDemand from './pages/selectTypeDemand';
import UploadCertificatesFiles from './pages/uploadCertificatesFiles';
import PensionResolutionDate from './pages/pensionResolutionDate';
import SelectTypePensionPage from './pages/selectTypePension';
import SelectAforePage from './pages/selectAfore';
import InterbankCLABERequest from './pages/interbankCLABERequest';
import VerifyBanckDetailsPage from './pages/verifyBankDetails';
import UploadFiles from './pages/uploadFiles';
import MessageHSR from './pages/message_HISR_91_92';
import MakeDate from './pages/makeDate';
import { dataReturnSavingsContext } from './contextData';
import MessagePage from './pages/MessagesPage';
import ExceptionPage from './pages/ExceptionPage';
import { checksReturnOptions } from './constants/options-savings.constants';
import { PAGES_SAVINGS } from './constants/pages-savings.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const ReturnSavingsConten = () => {
	const { page } = useContext(dataReturnSavingsContext);
	const style = useStyles();
	const { fontSizeLevel } = useSelector((state: RootState) => state.session);
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<div>
			<Layout updateTagText={updateTagText}>
				<div ref={divRef}>
					<HeaderList
						key="HeaderDevolucionAhorros"
						title="Devolución de mis ahorros"
						date="12 julio 2021"
						list={getHeaderList(page)}
					/>
					<Paper>
						<div className={style.divContainer}>
							{page === PAGES_SAVINGS.INFO_PAGE && (
								<InfoPage></InfoPage>
							)}
							{page === PAGES_SAVINGS.RETURN_OPTIONS && (
								<ReturnOptions
									ListChecksReturnOptions={
										checksReturnOptions
											? checksReturnOptions
											: undefined
									}
								></ReturnOptions>
							)}
							{page === PAGES_SAVINGS.NRPREQUEST_PAGE && (
								<NRPRequestPage></NRPRequestPage>
							)}
							{page === PAGES_SAVINGS.SAVINGS_INFORMATION && (
								<SavingsInformation></SavingsInformation>
							)}
							{page === PAGES_SAVINGS.SELECT_RETURN_METHOD && (
								<SelectReturnMethod></SelectReturnMethod>
							)}
							{page === PAGES_SAVINGS.SELECT_STATE_CLAIM && (
								<SelectStateClaim></SelectStateClaim>
							)}
							{page === PAGES_SAVINGS.QUESTION_DEMAND && (
								<QuestionDemand></QuestionDemand>
							)}
							{page === PAGES_SAVINGS.SELECT_TYPE_DEMAND && (
								<SelectTypeDemand></SelectTypeDemand>
							)}
							{page ===
								PAGES_SAVINGS.UPLOAD_CERTIFICATES_FILES && (
									<UploadCertificatesFiles></UploadCertificatesFiles>
								)}
							{page === PAGES_SAVINGS.PENSION_RESOLUTION_DATE && (
								<PensionResolutionDate></PensionResolutionDate>
							)}
							{page ===
								PAGES_SAVINGS.SELECT_TYPE_PENSION_PAGE && (
									<SelectTypePensionPage></SelectTypePensionPage>
								)}
							{page === PAGES_SAVINGS.SELECT_AFORE_PAGE && (
								<SelectAforePage></SelectAforePage>
							)}
							{page === PAGES_SAVINGS.INTERBANK_CLABE_REQUEST && (
								<InterbankCLABERequest></InterbankCLABERequest>
							)}
							{page ===
								PAGES_SAVINGS.VERIFY_BANK_DETAILS_PAGE && (
									<VerifyBanckDetailsPage></VerifyBanckDetailsPage>
								)}
							{page === PAGES_SAVINGS.CASE_INFORMATION_PAGE && (
								<CaseInformationPage></CaseInformationPage>
							)}
							{page === PAGES_SAVINGS.UPLOAD_FILES && (
								<UploadFiles></UploadFiles>
							)}
							{page === PAGES_SAVINGS.MESSAGE_HSR && (
								<MessageHSR></MessageHSR>
							)}
							{page === PAGES_SAVINGS.MAKE_DATE && (
								<MakeDate></MakeDate>
							)}
							{page === PAGES_SAVINGS.MESSAGE_PAGE && (
								<MessagePage></MessagePage>
							)}
							{page === PAGES_SAVINGS.EXCEPTION_PAGE && (
								<ExceptionPage></ExceptionPage>
							)}
						</div>
					</Paper>
				</div>
			</Layout>
		</div>
	);
};

const ReturnSavings = () => {
	return (
		<Dataprovider>
			<ReturnSavingsConten></ReturnSavingsConten>
		</Dataprovider>
	);
};

export default ReturnSavings;
