import { useContext, useEffect, useState } from 'react';
import CustomButton from '../../../components/Button';
import CustomAlert from '../../../components/CustomAlert';
import ModalLoading from '../../../components/ModalLoading';
import TextInput from '../../../components/TextInput';
import { IAlert } from '../../../interfaces/alert';
import { isNumber } from '../../../utils/expressions';
import { dataReturnSavingsContext } from '../contextData';
import { IRequestConsultaBanco } from '../interfaces';
import { useStyles } from '../style';

const InterbankCLABERequest = () => {
	const style = useStyles();
	const [clabe, setClabe] = useState<string>('');
	const [clabe2, setClabe2] = useState<string>('');
	const [disabledButton, setDisabledButton] = useState<boolean>(true);
	const {
		handlePrevPage,
		handlerConsultaBanco,
		alert,
		setAlert,
		loading,
		data,
	} = useContext(dataReturnSavingsContext);

	useEffect(() => {
		setDisabledButton(
			clabe.length === 18 && clabe2.length === 18 && clabe === clabe2
				? false
				: true
		);
	}, [clabe, clabe2]);

	const handlerOnSubmit = () => {
		if (clabe !== clabe2) {
			setAlert({
				show: true,
				message: 'Las clabes deben coincidir, por favor verifica.',
				severity: 'error',
			} as IAlert);
			return;
		}

		const params: IRequestConsultaBanco = {
			clabe: clabe,
			opcion: data?.returnOptionType || '',
			demanda: data?.hasDemand || false,
			tipoDemanda: data?.typeDemand || '',
		};

		handlerConsultaBanco(params);
	};

	return (
		<>
			<ModalLoading loading={loading}></ModalLoading>
			<div>
				<label className={style.TextBlack_H3}>
					Clave bancaria estandarizada (CLABE)
				</label>
				<div className={style.divContainerInputs}>
					<div className={style.divNRP}>
						<TextInput
							data-testid="CLABEInput"
							id="CLABE"
							name="CLABEInput"
							sx={{ color: 'red' }}
							value={clabe}
							onChange={(event) => {
								setAlert({
									show: false,
									severity: 'success',
									message: '',
								});
								const { value } = event.target;
								if (!isNumber(value)) return;
								if (value.length > 18) return;
								setClabe(value);
							}}
							placeholder={'Ingresa tu CLABE'}
						/>
					</div>
					<div className={style.divNRP}>
						<TextInput
							data-testid="CLABE2Input"
							id="CLABE2"
							name="CLABE2Input"
							sx={{ color: 'red' }}
							value={clabe2}
							onChange={(event) => {
								setAlert({
									show: false,
									severity: 'success',
									message: '',
								});
								const { value } = event.target;
								if (!isNumber(value)) return;
								if (value.length > 18) return;
								setClabe2(value);
								setAlert({
									show: value !== clabe ? true : false,
									message:
										'Las clabes deben coincidir, por favor verifica.',
									severity: 'error',
								});
							}}
							placeholder={'Confirma tu CLABE'}
						/>
					</div>
				</div>
				<div className={style.divAlert}>
					<CustomAlert
						message={alert.message}
						show={alert.show}
						severity={alert.severity}
						onClose={() => {
							setAlert({
								show: false,
								message: '',
								severity: alert.severity,
							});
						}}
					/>
				</div>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<div className={style.divButtons}>
						<div className={style.Buttons}>
							<CustomButton
								onClick={handlePrevPage}
								variant="outlined"
								styles={{
									width: '100%',
									height: '100%',
								}}
								label="Regresar"
							/>
						</div>
						<div className={style.Buttons}>
							<CustomButton
								onClick={handlerOnSubmit}
								variant="solid"
								styles={{
									width: '100%',
								}}
								label="Continuar"
								disabled={disabledButton}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default InterbankCLABERequest;
