import { useState } from 'react';
import { Paper, Grid } from '@mui/material';
import { useStyles } from './styles';
import CustomButton from '../../../components/Button';
import { HeaderList } from '../../../components/HeaderList';
import checkicon from '../../../assets/img/mini-check-azul.png';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { cancelLetterAPI } from '../../../api/modules/cancellationLetter';
import { IAlert } from '../../../interfaces/alert';
import { keyAPI } from '../../../api/modules/key';
import { encrypt } from '../../../utils/encrypt';
import CustomAlert from '../../../components/CustomAlert';
import ModalLoading from '../../../components/ModalLoading';

interface ListToHave {
	id: number;
	icon: string;
	title: string;
}

const LISTTODO: ListToHave[] = [
	{
		id: 1,
		icon: checkicon,
		title: 'La carta de instrucción de cancelación de hipoteca es el documento que te expide el Infonavit cuando terminas de pagar tu crédito.',
	},
	{
		id: 2,
		icon: checkicon,
		title: 'Esta carta debes entregarla al notario que elegiste para que lleve a cabo la liberación de tu hipoteca y quede registrada en el Registro Público de la Propiedad.',
	},
	{
		id: 3,
		icon: checkicon,
		title: 'En algunos estados, ese trámite también se puede realizar directamente ante el Registro Público de la Propiedad.',
	},
	{
		id: 4,
		icon: checkicon,
		title: 'La vigencia de la carta de cancelación es de 180 días naturales, si en ese tiempo no la entregas al notario (solo al notario), deberás iniciar el trámite nuevamente.',
	},
];

const REQUIREMENTSLIST: ListToHave[] = [
	{
		id: 1,
		icon: checkicon,
		title: 'Haber liquidado el crédito cuyo rango este dentro de los 2.8 UMAS',
	},
	{
		id: 2,
		icon: checkicon,
		title: 'Tener copia de escritura de originación con datos de Registro',
	},
];

interface Props {
	setPage: (data: number) => void;
	caso: boolean;
	hasError: IAlert;
}

export const InfoViewUMAS = ({ setPage, caso, hasError }: Props) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const { user } = useSelector((state: RootState) => state.session);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	// const handleClick = () => {
	// 	if (caso === true) {
	// 		setPage(6);
	// 	} else {
	// 		setPage(1);
	// 	}
	// };

	const handleOnSubmitUMA = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();

			const nss_base64 = await encrypt(user?.nss ? user?.nss.toString() : '', key);
			const nombre_base64 = await encrypt(user?.nombres ? user?.nombres.toString() : '', key);
			const apPaterno_base64 = await encrypt(user?.apPaterno ? user?.apPaterno.toString() : '', key);
			const apMaterno_base64 = await encrypt(user?.apMaterno ? user?.apMaterno.toString() : '', key);
			const clase_operacion_base64 = await encrypt(
				'ZACH',
				key
			);
			const tipificacion_base64 = await encrypt(
				'A1ZN000182ZN05',
				key
			);
			const dataFlag = {
				nss: nss_base64,
				clase_operacion: clase_operacion_base64,
				tipificacion: tipificacion_base64,
				nombre: nombre_base64,
				apellidoPaterno: apPaterno_base64,
				apellidoMaterno: apMaterno_base64
			};
			cancelLetterAPI
				.creaCarta(dataFlag)
				.then((response) => {
					setPage(8);
				})
				.catch((err) => {
					setAlert({
						show: true,
						message: err.description
							? err.description
							: 'Ha ocurrido un error al obtener los datos, intente nuevamente',
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error) { }
	};

	return (
		<div>

			<HeaderList
				title="Carta de instrucción de cancelación de hipoteca"
				date="Información al 12 julio 2021"
				list={[]}
			/>
			<ModalLoading loading={loading} />
			{LISTTODO.map((sm) => (
				<Grid
					item
					xs={12}
					lg={12}
					className={classes.divChecks}
					key={sm.id}
				>
					<img
						className={classes.iconsImg}
						alt="checkicon"
						src={sm.icon}
					></img>
					<p className={classes.txtPrincipales}>{sm.title}</p>
					<br></br>
				</Grid>
			))}
			<br></br>
			<Paper className={classes.divPrincipal}>
				<div>
					<p className={classes.txt}>
						<b>
							Has sido seleccionado como beneficiario del “Programa de Cancelación de Hipoteca 2.8 UMAS,
						</b>{' '}
						mismo que está dirigido a todos aquellos derechohabientes que hayan terminado de pagar su crédito cuyo ingreso sea hasta 2.8 UMAS a fin de que el INFONAVIT lleve a cabo la cancelación de hipoteca de manera gratuita.”
					</p>

					<p className={classes.txt}>
						Los requisitos para participar en el programa son los
						siguientes:
					</p>
				</div>

				{REQUIREMENTSLIST.map((sm) => {
					return (
						<Grid
							item
							xs={12}
							lg={12}
							className={classes.divChecks}
							key={sm.id}
						>
							<img
								className={classes.iconsImg}
								alt="checkicon"
								src={sm.icon}
							></img>
							<p className={classes.txtPrincipales}>
								{sm.title}
							</p>
							<br></br>
						</Grid>
					);
				})}

				<p className={classes.txt}>
					Es importante mencionar que al ser un Programa masivo
					los tiempos para llevar a cabo la cancelación de
					hipoteca varían de acuerdo a los Registros Públicos de
					cada entidad, por lo que la inscripción puede tardar en
					realizarse aproximadamente entre 6 u 8 meses.
				</p>

				<br></br>

				<p className={classes.txtQuestion}>
					¿Quieres aceptar la cancelación de tu hipoteca a través
					del programa?
				</p>

				<CustomAlert
					show={alert.show}
					severity={alert.severity}
					message={alert.message}
				/>

				<div className={classes.contentFooterData}>
					<div className={classes.contentFileDataButtons}>
						<CustomButton
							onClick={() => {
								setPage(0);
							}}
							styles={{
								marginTop: 20,
								width: '100%',
								marginInline: 10,
							}}
							variant="outlined"
							label="No"
						/>
						<CustomButton
							onClick={handleOnSubmitUMA}
							styles={{
								marginTop: 20,
								width: '100%',
								marginInline: 10,
							}}
							variant="solid"
							label="Sí"
						/>
					</div>
				</div>

				{/* <div style={{ height: 100 }}></div> */}
			</Paper>
		</div>
	);
};

export default InfoViewUMAS;
