import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	containerButtons: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'row',

		[theme.breakpoints.down('md')]: {
			flexDirection: 'column-reverse',
		},
	},
	buttonItem: {
		width: 250,
		margin: '10px',

		[theme.breakpoints.down('md')]: {
			width: 'auto',
		},
	},
}));
