import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('135', 'aclaracion-pagos');

export const ENDPOINTS_ACLARACION_DE_PAGOS: IBitacora = {
	'/v1/aclaracion-pagos/consulta-sici': _bitacora.Read(
		'135100',
		'Consulta sici'
	),
	'/v1/aclaracion-pagos/consulta-als': _bitacora.Read(
		'135101',
		'Consulta als'
	),
	'/v1/aclaracion-pagos/consulta-caso': _bitacora.Read(
		'135102',
		'Consultar caso'
	),
	'/v1/aclaracion-pagos/crea-caso': _bitacora.Create('135103', 'Crear caso'),
	'/v1/aclaracion-pagos/crea-fondo-ahorro': _bitacora.Create(
		'135104',
		'Crear fondo de ahorro'
	),
	'/v1/aclaracion-pagos/catalogo-emisoras': _bitacora.Read(
		'135105',
		'Consultar catalogo de emisoras'
	),
	'/v1/aclaracion-pagos/adjunta-archivos': _bitacora.Update(
		'135106',
		'Adjuntar archivos'
	),
};
