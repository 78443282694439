import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('142', 'nip-infonatel');

export const ENDPOINTS_NIP_INFONATEL: IBitacora = {
	'/nip-infonatel/consulta-nip': _bitacora.Read('142100', 'Consultar NIP'),
	'/nip-infonatel/desbloquear-nip': _bitacora.Read(
		'142101',
		'Desbloquear NIP'
	),
	'/nip-infonatel/bloquear-nip': _bitacora.Read('142102', 'Bloquear NIP'),
	'/nip-infonatel/activar-nip': _bitacora.Read('142103', 'Activar NIP'),
	'/nip-infonatel/cancelar-nip': _bitacora.Read('142104', 'Cancelar NIP'),
	'/nip-infonatel/cambiar-nip': _bitacora.Read('142105', 'Cambiar NIP'),
	'/nip-infonatel/envio-sms': _bitacora.Read('142106', 'Envíos de SMS'),
	'/nip-infonatel/valida-codigo': _bitacora.Read('142107', 'Validar código'),
	'/nip-infonatel/envio-email': _bitacora.Read('142108', 'Enviar correo'),
	'/nip-infonatel/envio-email-confirmation': _bitacora.Read(
		'142109',
		'Confirmar correo'
	),
};
