/* eslint-disable react-hooks/exhaustive-deps */
import { isNumberFloat } from '../../../utils/expressions';
import { IAlert } from '../../../interfaces/alert';
import { savingCalculator } from '../../../api/modules/savingCalculator';
import { keyAPI } from '../../../api/modules/key';
import { encrypt } from '../../../utils/encrypt';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import {
	incrementoSalarioActual,
	incrementoSalarioActualAux,
	rendimientoSCV2011,
	tasaDescuentoInflacion,
	topeAportacionPatronal,
	totalAge,
	VSMDF25,
} from '../utils/constants-values.utils';
import { useEffect, useState } from 'react';

export const useSavingCalculator = () => {
	const { user } = useSelector((state: RootState) => state.session);

	const [viewTable, setViewTable] = useState(false);
	const [loading, setLoading] = useState(false);
	const [disabledButton, setDisabledButton] = useState(false);
	const [page, setPage] = useState(1);
	const [rowsPerPage] = useState(8);
	const [currentAge, setCurrentAge] = useState(50);
	const [inYears, setInYears] = useState(0);
	const [currentSalary, setCurrentSalary] = useState<number | string>('');
	const [currentSaving, setCurrentSaving] = useState<number>(0);
	const [retireSaving, setRetireSaving] = useState<number>(0);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [rows, setRows] = useState<
		{
			columnaAhorro: number;
			columnaSalarioAnual: number;
			columnaValorPresente: number;
			columnaAportacionPatronalAnual: number;
			edad: number;
		}[]
	>([]);

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(user?.nss.toString() || '', key);
			savingCalculator
				.post<any, any>('', {
					nss: nss_base64,
				})
				.then((response) => {
					if (response?.result?.retCode === 1) {
						const salary9297 = {
							...(response?.result?.saldo9297 || {}),
						};
						const saldosar92 = Number(salary9297.saldosar92);
						const saldosar97 = Number(salary9297.saldosar97);
						const ed = salary9297.rfc.substring(4, 6);
						const fechaCorte = salary9297.fechaCorte.substring(
							salary9297.fechaCorte.lastIndexOf('/') + 1
						);
						const iEdad = Number(fechaCorte) - (1900 + Number(ed));
						const inAgeFlag = totalAge - iEdad;
						setInYears(inAgeFlag);
						setCurrentAge(iEdad);
						setCurrentSaving(saldosar92 + saldosar97);
					} else {
						setAlert({
							show: true,
							message:
								'Por el momento el servicio no está disponible. Intenta más tarde',
							severity: 'error',
						});
						setDisabledButton(true);
					}
				})
				.catch((err) => {
					setLoading(false);
					setAlert({
						show: true,
						message:
							err.description ||
							'Por el momento el servicio no está disponible. Intenta más tarde',
						severity: 'error',
					});
					setDisabledButton(true);
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setLoading(false);
			setDisabledButton(true);
		}
	};

	const handleChangePage = (newPage: number) => {
		setPage(newPage);
	};

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		if (isNumberFloat(value)) {
			setCurrentSalary(value);
		}
	};

	const handleCalculate = () => {
		if (!currentSalary) {
			return setAlert({
				show: true,
				message: 'Escribe el sueldo',
				severity: 'warning',
			});
		}
		const newArray = [];
		const currentSalaryFlag = Number(
			currentSalary.toString().replaceAll(',', '')
		);
		let columnaSalarioAnual = 0;
		let columnaAportacionPatronalAnual = 0;
		let columnaAhorro = 0;
		let columnaValorPresente = 0;
		let topePatronal = false;
		let tuAhorroActualidad = 0;
		let tuAhorroCuantoTeJubiles = 0;

		for (let fila = 0; fila <= inYears; fila++) {
			if (fila === 0) {
				columnaAhorro = currentSaving;
				columnaValorPresente =
					columnaAhorro / Math.pow(1 + tasaDescuentoInflacion, fila);
				tuAhorroActualidad = columnaValorPresente;
				newArray.push({
					columnaAhorro,
					columnaSalarioAnual,
					columnaValorPresente,
					columnaAportacionPatronalAnual,
					edad: currentAge,
				});
			} else if (fila === 1) {
				columnaSalarioAnual =
					currentSalaryFlag * incrementoSalarioActualAux;

				if (currentSalaryFlag >= VSMDF25) {
					columnaAportacionPatronalAnual = topeAportacionPatronal;
					topePatronal = true;
				} else {
					columnaAportacionPatronalAnual =
						columnaSalarioAnual * incrementoSalarioActual;
				}

				columnaAhorro =
					(columnaAhorro + columnaAportacionPatronalAnual) *
					(1 + rendimientoSCV2011);

				columnaValorPresente =
					columnaAhorro / Math.pow(1 + tasaDescuentoInflacion, fila);
				newArray.push({
					columnaAhorro,
					columnaSalarioAnual,
					columnaValorPresente,
					columnaAportacionPatronalAnual,
					edad: currentAge + fila,
				});
			} else {
				columnaSalarioAnual =
					columnaSalarioAnual * (1 + incrementoSalarioActual);
				if (topePatronal) {
					columnaAportacionPatronalAnual =
						columnaAportacionPatronalAnual *
						(1 + incrementoSalarioActual);
				} else {
					columnaAportacionPatronalAnual =
						columnaSalarioAnual * incrementoSalarioActual;
				}
				columnaAhorro =
					(columnaAhorro + columnaAportacionPatronalAnual) *
					(1 + rendimientoSCV2011);
				columnaValorPresente =
					columnaAhorro / Math.pow(1 + tasaDescuentoInflacion, fila);
				newArray.push({
					columnaAhorro,
					columnaSalarioAnual,
					columnaValorPresente,
					columnaAportacionPatronalAnual,
					edad: currentAge + fila,
				});
			}

			tuAhorroCuantoTeJubiles = columnaValorPresente;
		}
		setCurrentSaving(tuAhorroActualidad);
		setRetireSaving(tuAhorroCuantoTeJubiles);
		setRows([...newArray]);
		setAlert({
			show: false,
			message: '',
			severity: 'warning',
		});
	};

	return {
		viewTable,
		setViewTable,
		loading,
		disabledButton,
		page,
		rowsPerPage,
		retireSaving,
		alert,
		rows,
		handleChangePage,
		handleSetData,
		handleCalculate,
		setAlert,
		currentSalary,
		setCurrentSalary,
		currentSaving,
		inYears,
	};
};
