import React, { useState } from 'react';
import { Divider, Grid, IconButton } from '@mui/material';
import {
	ArrowBackIos,
	ArrowForwardIos,
	KeyboardBackspace,
} from '@mui/icons-material';
import TwoColumnTable from '../../../../components/TwoColumnTable';
import { RoundBtn } from '../../../AssociateNssCredit/Styles';
import {
	actualConditionsCompareRows,
	responsabilituyCompareRows,
} from '../../utils';
import { useStyles } from '../../styles';
import theme from '../../../../config/theme';

const CompareConditions = ({
	step,
	setStep,
	currentCredit,
	creditOptions,
	selectedCredit,
	setSelectedCredit,
}: any) => {
	const classes = useStyles();
	const [page, setPage] = useState(0);

	return (
		<>
			<p>
				Antes de aceptar el programa Responsabilidad Compartida te
				sugerimos revisar la información y tabla de amortización de cada
				opción para que puedas tomar una mejor decisión, ya que estas
				condiciones se van a mantener hasta que se liquide tu cuenta.
			</p>
			<h5 className={classes.maxContent}>
				Las condiciones al convertir tu crédito a pesos son las
				siguientes
			</h5>
			<Grid container spacing={2}>
				<Grid container item xs={12} md={6}>
					<Grid item xs={12}>
						<div className={classes.compareTableHeader}>
							<h4>Condiciones actuales del crédito</h4>
						</div>
					</Grid>
					<Grid item xs={12}>
						<TwoColumnTable
							data={currentCredit}
							principal={actualConditionsCompareRows}
						/>
					</Grid>
					<Grid item xs={12}>
						<RoundBtn
							onClick={() => setStep(step - 1)}
							style={{ width: 200 }}
							color="inherit"
							variant="text"
							startIcon={<KeyboardBackspace />}
						>
							Regresar
						</RoundBtn>
					</Grid>
				</Grid>
				<Grid container item xs={12} md={6} alignItems="center">
					<Grid item xs={12}>
						<div className={classes.compareTableHeader}>
							<h4>Responsabilidad Compartida</h4>
						</div>
						{creditOptions?.grupo === '1' && (
							<p className={classes.noMargin}>
								Opción de{' '}
								<b>
									{
										creditOptions?.beneficios[page]
											?.porcentaje
									}
								</b>{' '}
								de descuento en tu pago
							</p>
						)}
						{creditOptions?.grupo === '2A' && page === 0 && (
							<p className={classes.noMargin}>
								<b>Opción 1</b> Mantener el pago mensual
								considerado en la conversión y aceptar descuento
								al saldo calculado para el crédito
							</p>
						)}
						{creditOptions?.grupo === '2A' && page === 1 && (
							<p className={classes.noMargin}>
								<b>Opción 2</b> El pago mensual considerado en
								la conversión, más 10% adicional y un descuento
								al saldo del 25%. Este nuevo pago mensual
								quedará fijo hasta que se concluya el pago del
								crédito, si se mantiene al corriente la cuenta
							</p>
						)}
						{creditOptions?.grupo === '2B' && (
							<h5 style={{ margin: 0 }}>Conversión única</h5>
						)}
					</Grid>
					{creditOptions?.grupo !== '2B' && (
						<Grid item xs={1}>
							<IconButton
								disabled={page === 0}
								onClick={() => setPage(page - 1)}
							>
								<ArrowBackIos />
							</IconButton>
						</Grid>
					)}
					<Grid item xs={creditOptions?.grupo === '2B' ? 12 : 10}>
						<TwoColumnTable
							data={creditOptions?.beneficios[page]}
							principal={responsabilituyCompareRows(
								creditOptions?.grupo
							)}
						/>
					</Grid>
					{creditOptions?.grupo !== '2B' && (
						<Grid item xs={1}>
							<IconButton
								disabled={
									page ===
									creditOptions?.beneficios.length - 1
								}
								onClick={() => setPage(page + 1)}
							>
								<ArrowForwardIos />
							</IconButton>
						</Grid>
					)}
				</Grid>
			</Grid>
			<Divider />
			<h6 style={{ margin: '5px 0', color: theme.palette.info.main }} className={classes.maxContent}>
				*El monto no considera seguros y comisiones del crédito.
			</h6>
			<h6 style={{ margin: '5px 0', color: theme.palette.info.main }} className={classes.maxContent}>
				<sup>1</sup>Se consideran como pagos efectivos, aquellos que se
				realizan en tiempo, y por el monto completo requerido.
			</h6>
		</>
	);
};

export default CompareConditions;
