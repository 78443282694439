/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState, useRef } from 'react';
import Layout from '../../components/Layout';
import AdminNss from './AdminNss';
import ViewerNss from './ViewerNss';
import FinishNss from './FinishNss';
import { associateNssAPI } from '../../api/modules/AssociateNSS';
import { keyAPI } from '../../api/modules/key';
import { encrypt } from '../../utils/encrypt';
import { nssValid } from '../../utils/expressions';
import '../../assets/font/Geomanist.css';
import ModalLoading from '../../components/ModalLoading';
import { IAlert } from '../../interfaces/alert';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const AssociateNssCredit = () => {
	const [etapa, setEtapa] = useState(0);

	const [alert, setAlert] = useState<IAlert>({
		message: '',
		severity: 'success',
		show: false,
	});
	const [associated, setAssociated] = useState<any>({
		nssSecundario: '',
		nssError: '',
		parentesco: '',
		tipoRelacion: '',
		genero: '',
		tipoAsociacion: '',
	});
	const [isNew, setIsNew] = useState<Boolean>(false);
	const [loading, setLoading] = useState(false);
	const { fontSizeLevel } = useSelector((state: RootState) => state.session);
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	const fetchAssociated = useCallback(async () => {
		if (nssValid(associated?.nssSecundario).valid) {
			setLoading(true);
			try {
				setLoading(true);
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const ASSOCIATED_NSS_BASE64 = await encrypt(
					associated?.nssSecundario?.toString() || '',
					key
				);
				associateNssAPI.referenceData
					.post<{ nss: string }, any>('', {
						nss: ASSOCIATED_NSS_BASE64,
					})
					.then((res: any) => {
						if (res.error) {
							if (JSON.stringify(res.error) === '{}') {
								setAlert({
									message: INTERNAL_MESSAGES.ERROR_MESSAGE,
									severity: 'error',
									show: true,
								});
								return;
							} else {
								setAlert({
									message:
										res.error ||
										INTERNAL_MESSAGES.ERROR_MESSAGE,
									severity: 'error',
									show: true,
								});
							}
						}

						if (
							res.data?.description ||
							res.data?.code?.includes('MCI')
						)
							setAlert({
								message: res.data.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
								severity: 'error',
								show: true,
							});
						else {
							setAssociated({ ...associated, ...res.data });
							setAlert({
								message: '',
								severity: 'success',
								show: false,
							});
						}
					})
					.catch((error) => {
						setAlert({
							message: INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
							show: true,
						});
					})
					.finally(() => {
						setLoading(false);
					});
			} catch (error) { }
		}
	}, [associated.nssSecundario]);

	useEffect(() => {
		fetchAssociated();
	}, [fetchAssociated]);

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<>
			<ModalLoading loading={loading} />
			<Layout updateTagText={updateTagText}>
				<div style={{ marginBottom: 20 }} ref={divRef}>
					{(etapa === 0 || etapa === 3) && (
						<AdminNss
							etapa={etapa}
							setEtapa={setEtapa}
							associated={associated}
							setAssociated={setAssociated}
							msgError={alert}
							setAlert={setAlert}
						/>
					)}
					{etapa === 1 && (
						<ViewerNss
							setEtapa={setEtapa}
							associated={associated}
							setIsNew={setIsNew}
						/>
					)}
					{etapa === 2 && (
						<FinishNss
							setEtapa={setEtapa}
							associated={associated}
							setAssociated={setAssociated}
							isNew={isNew}
						/>
					)}
				</div>
			</Layout>
		</>
	);
};

export default AssociateNssCredit;
