// DEPENDENCIES
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// COMPONENTS
import TwoColumnTable from '../../../../components/TwoColumnTable';
import ReestructureAlert from '../../components/ReestructureAlert';
import ModalLoading from '../../../../components/ModalLoading';
import { RoundBtn } from '../../../AssociateNssCredit/Styles';
import { INTERNAL_MESSAGES } from '../../../../config/messageCatalog';
import CustomAlert from '../../../../components/CustomAlert';
import AlertModal from '../../components/AlertModal';
// RESOURCES
import { sharedResponsabilityAPI } from '../../../../api/modules/sharedResponsability';
import { selectedCreditRows } from '../../utils';
import { RootState } from '../../../../store';
import { keyAPI } from '../../../../api/modules/key';
import { encrypt } from '../../../../utils/encrypt';
import { useBitacora } from '../../../../hooks/useBitacora';
import { useStyles } from '../../styles';

const SelectedCredit = ({ step, setStep, credit, setSelectedCredit }: any) => {
	const classes = useStyles();
	const { regBitacoraNotOld } = useBitacora();
	const { creditNum, user } = useSelector((state: RootState) => ({
		user: state.session.user,
		creditNum: state.credit.credit,
	}));
	const [loading, setLoading] = useState(false);
	const [alertModal, setAlertModal] = useState(false);
	const [alert, setAlert] = useState<any>('');
	const [err, setErr] = useState<any>('');
	const [reestructure, setReestructure] = useState(false);

	const fetchAmortization = useCallback(async () => {
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const TIPO_OPERACION_BASE64 = await encrypt('3', key);
			const CREDIT_BASE64 = await encrypt(creditNum || '', key);
			const GRUPO_BASE64 = await encrypt(credit?.codigoGrupo || '', key);
			const { result } =
				await sharedResponsabilityAPI.consultAmortization(
					TIPO_OPERACION_BASE64,
					CREDIT_BASE64,
					GRUPO_BASE64
				);
			if (result?.MENSAJE) return setErr(result.MENSAJE);
			else if (result)
				setSelectedCredit({
					...credit,
					...result,
				});
		} catch (error: any) {
			setErr({
				message: error.description
					? error.description
					: INTERNAL_MESSAGES.ERROR_MESSAGE,
				color: 'error',
			});
		} finally {
			setLoading(false);
		}
	}, [credit, creditNum, setSelectedCredit]);

	useEffect(() => {
		fetchAmortization();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCreate = async () => {
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();

			const NSS_BASE64 = await encrypt(user?.nss || '', key);
			const CREDIT_BASE64 = await encrypt(creditNum || '', key);
			const GRUPO_BASE64 = await encrypt(credit?.codigoGrupo || '', key);
			const quita_BASE64 = await encrypt(
				credit?.montoBeneficio || credit?.montoDescuento || '0',
				key
			);
			const saldoPrevio_BASE64 = await encrypt(credit?.SDO_ACT || '', key);
			const marcaReestructura_BASE64 = await encrypt(
				credit?.reestructura || '',
				key
			);
			const reaVsm_BASE64 = await encrypt(credit?.PG_REA || '', key);
			const reaPesos_BASE64 = await encrypt(credit?.PG_REA || '', key);
			const roaVsm_BASE64 = await encrypt(credit?.PG_ROA || '', key);
			const roaPesos_BASE64 = await encrypt(credit?.PG_ROA || '', key);
			const intereses_BASE64 = await encrypt(credit?.TASA || '', key);
			const plazo_BASE64 = await encrypt(credit?.PLAZO || '', key);
			const porcentajeDescuento_BASE64 = await encrypt(
				credit?.DSC_SDO || '',
				key
			);
			const estado_BASE64 = await encrypt(credit?.estado || '', key);
			const cp_BASE64 = await encrypt(credit?.cp || '', key);

			const { result } = await sharedResponsabilityAPI.createCase(
				NSS_BASE64, //    nss: string | undefined,
				CREDIT_BASE64, //  credito: string,
				GRUPO_BASE64, // 		opcionAceptada: string,
				quita_BASE64, // 		quita: string,
				saldoPrevio_BASE64, // 		saldoPrevio: string,
				marcaReestructura_BASE64, // 		marcaReestructura: string,
				reaVsm_BASE64, // 		reaVsm: string,
				reaPesos_BASE64, // 		reaPesos: string,
				roaVsm_BASE64, // 		roaVsm: string,
				roaPesos_BASE64, // 		roaPesos: string,
				intereses_BASE64, // 		intereses: string,
				plazo_BASE64, // 		plazo: number,
				porcentajeDescuento_BASE64, // 		porcentajeDescuento: string,
				estado_BASE64, // 		estado: string,
				cp_BASE64 // 		cp: string
			);
			if (result?.code === '0000') {
				regBitacoraNotOld(sharedResponsabilityAPI.schemeUrl, {
					NSS: user?.nss, //    nss: string | undefined,
					CREDIT: creditNum, //  credito: string,
					GRUPO: credit?.codigoGrupo, // 		opcionAceptada: string,
					quita: credit?.montoBeneficio, // 		quita: string,
					saldoPrevio: credit?.SDO_ACT, // 		saldoPrevio: string,
					marcaReestructura: credit?.reestructura, // 		marcaReestructura: string,
					reaVsm: credit?.PG_REA, // 		reaVsm: string,
					reaPesos: credit?.PG_REA, // 		reaPesos: string,
					roaVsm_BASE64: credit?.PG_ROA, // 		roaVsm: string,
					roaPesos: credit?.PG_ROA, // 		roaPesos: string,
					intereses: credit?.TASA, // 		intereses: string,
					plazo: credit?.PLAZO, // 		plazo: number,
					porcentajeDescuento: credit?.DSC_SDO, // 		porcentajeDescuento: string,
					estado: credit?.estado, // 		estado: string,
					cp: credit?.cp // 		cp: string
				});
				setStep(step + 1);
				setSelectedCredit({ ...result, ...credit });
			} else {
				setAlertModal(true);
				setAlert(
					result?.description ||
					INTERNAL_MESSAGES.ERROR_MESSAGE,
				);
			}
		} catch (error: any) {
			setAlertModal(true);
			setAlert(
				(error && typeof error === 'string'
					? error
					: error?.description) ||
				INTERNAL_MESSAGES.ERROR_MESSAGE
			);
		} finally {
			setLoading(false);
		}
	};

	if (err)
		return (
			<CustomAlert message={err} severity={'error'} show={Boolean(err)} />
		);

	return (
		<>
			<ReestructureAlert
				open={reestructure}
				setOpen={setReestructure}
				onConfirm={handleCreate}
			/>
			<ModalLoading loading={loading} />
			<AlertModal
				title="No podemos realizar la conversión de tu crédito a pesos debido a que:"
				open={alertModal}
				description={alert}
				setOpen={setAlertModal}
			/>
			<div style={{ textAlign: 'center' }}>
				<h5 className={`${classes.marginCenter} ${classes.maxContent}`}>
					Seleccionaste la{' '}
					{credit?.grupo === '1' && (
						<>
							Opción de {credit?.porcentaje} de descuento en tu
							pago
						</>
					)}
					{credit?.grupo === '2A' &&
						credit.codigoGrupo === 'G2AO1' && (
							<>
								Opción 1 Mantener el pago mensual considerado en
								la conversión y aceptar descuento al saldo
								calculado para el crédito
							</>
						)}
					{credit?.grupo === '2A' &&
						credit.codigoGrupo === 'G2AO2' && (
							<>
								Opción 2 El pago mensual considerado en la
								conversión, más 10% adicional y un descuento al
								saldo del 25%. Este nuevo pago mensual quedará
								fijo hasta que se concluya el pago del crédito,
								si se mantiene al corriente la cuenta
							</>
						)}
					{credit?.grupo === '2B' && 'Conversión única'}
				</h5>
				<p className={`${classes.marginCenter} ${classes.maxContent}`}>
					<small>Esto significa que ahora con tu crédito en pesos:</small>
				</p>
				<TwoColumnTable
					data={credit}
					principal={selectedCreditRows(credit?.grupo)}
				/>
				<p className={`${classes.marginCenter} ${classes.maxContent}`}>
					<small>
						Una vez que aceptas esta conversión, no será posible
						regresar tu crédito a las condiciones que tenias antes
						de este beneficio.
						<br />
						¿Aceptas la conversión de tu crédito con las nuevas
						condiciones?
					</small>
				</p>
			</div>
			<div className="actions-buttons">
				<RoundBtn onClick={() => setStep(5)} variant="outlined">
					No
				</RoundBtn>
				<RoundBtn
					onClick={() =>
						credit?.reestructura === 'R'
							? setReestructure(true)
							: handleCreate()
					}
					variant="contained"
				>
					Si
				</RoundBtn>
			</div>
		</>
	);
};

export default SelectedCredit;
