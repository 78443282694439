/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import { Paper, Box, Link } from '@mui/material';
import { IProfileResponse } from '../../../../../api/modules/MIUC';
import Layout from '../../../../../components/Layout';
import { HeaderList } from '../../../../../components/HeaderList';
import CustomAlert from '../../../../../components/CustomAlert';
import ModalMiEspacio from '../../../components/ModalMiEspacio';
import { URL_APLICACION } from '../../../../../config/miucConstants';
import ModalCancelWFI from '../../../components/ModalCancelWFI';
import { useStyles } from './styles/styles';
import { useHowCreditGoing } from './hooks/useHowCreditGoing';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

interface IHowCreditGoing {
	data: IProfileResponse;
}

const HowCreditGoing = (props: IHowCreditGoing) => {
	const {
		getUrlMiespacio,
		getUrlWFI,
		openModal,
		setOpenModal,
		openModalCancel,
		setOpenModalCancel,
		selectOption,
		setSelectOption,
		alert,
		setAlert,
		alertDocument,
		setAlertDocument,
	} = useHowCreditGoing(props);

	const classes = useStyles();
	const { fontSizeLevel } = useSelector((state: RootState) => state.session);
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<Layout updateTagText={updateTagText}>
			<div ref={divRef}>
				<HeaderList
					title="Precalificación y puntos"
					list={['Registro de solicitud de crédito en línea']}
				/>
				<Paper>
					<Box padding={3} maxWidth={900} margin={'auto'}>
						<h3 className={classes.textCenter}>
							En que va mi solicitud de crédito
						</h3>

						<p className={classes.margin20p0p}>
							Para conocer el estatus en el que se encuentra tu
							solicitud entra a{' '}
							<Link onClick={getUrlMiespacio}>
								Mi espacio Infonavit
							</Link>
						</p>

						<p className={classes.margin20p0p}>
							Si tienes problemas para consultar tu información
							llama a Infonatel al 9171 5050 en la ciudad de
							México, o al 01 800 008 3900 desde cualquier parte
							del país, de lunes a viernes de 7:30 de la mañana a
							9 de la noche; sábados, domingos o días festivos de
							9 de la mañana a 3 de la tarde.
						</p>
						<CustomAlert
							show={alertDocument.show}
							message={alertDocument.message}
							severity={alertDocument.severity}
						/>

						{
							props.data.casoActivo && (
								<Box>
									<p className={classes.margin20p0p}>
										<Link
											onClick={() => {
												setAlert({
													show: false,
													severity: 'error',
													message: '',
												});
												setAlertDocument({
													show: false,
													severity: 'error',
													message: '',
												});
												setOpenModalCancel(!openModalCancel);
											}}
										>
											Cancelar mi solicitud de crédito
										</Link>
									</p>
									<p className={classes.margin20p0p}>
										<Link
											onClick={() => {
												setAlertDocument({
													show: false,
													severity: 'error',
													message: '',
												});
												getUrlWFI(URL_APLICACION.REIMPRESION_WFI);
											}}
										>
											Impresión de documentos
										</Link>
									</p>
								</Box>
							)
						}

					</Box>
				</Paper>
				<ModalMiEspacio
					openModal={openModal}
					setOpenModal={() => setOpenModal(!openModal)}
				/>
				<ModalCancelWFI
					selectOption={selectOption}
					setSelectOption={setSelectOption}
					openModal={openModalCancel}
					setOpenModal={() => {
						setOpenModalCancel(!openModalCancel);
						setSelectOption('');
						setAlert({
							show: false,
							severity: 'error',
							message: '',
						});
					}}
					onContinue={() => getUrlWFI(URL_APLICACION.CANCELACION_WFI)}
					hasError={alert}
				/>
			</div>
		</Layout>
	);
};

export default HowCreditGoing;
