import CustomButton from '../../../components/Button';
import { useStyles } from '../style';
import { TableInfo } from '../utils';
import { useContext, useEffect, useState } from 'react';
import iconDownload from '../../../assets/img/icono-descarga.png';
import { dataReturnSavingsContext } from '../contextData';
import { PropsRowInfo3 } from '../interfaces';
import { downloadPDF } from '../../../utils/downloadPDF';
import { useNavigate } from 'react-router-dom';
import CustomAlert from '../../../components/CustomAlert';
import ModalLoading from '../../../components/ModalLoading';

const CaseInformationPage = () => {
	const style = useStyles();
	const {
		handlerReintentoPDFAcuse,
		loading,
		data,
		setPage,
		alert,
		setAlert,
		handleGoPage,
		handleconsultaDatosDisponibilidadContinuaSolicitud,
	} = useContext(dataReturnSavingsContext);
	const [Labels, setLabels] = useState<PropsRowInfo3[] | undefined>(
		undefined
	);
	const navigate = useNavigate();

	const [viewDownloadLink, setViewDownloadLink] = useState(true);

	const onClickButtonCustom = (option: any) => {
		switch (option) {
			case 0: // Reintento
				handlerReintentoPDFAcuse();
				//setData({ ...data, isReintentoSolicitud: true });
				//handleGoPage(8);
				break;
			case 1:
				handleGoPage(8);
				break;
			case 2:
				handleGoPage(8);
				break;
			case 3:
				handleGoPage(17);
				break;
			case 4: //Adjunta documentos
				setPage(15);
				break;
			case 5: //Salir
				navigate('/inicio');
				break;
			case 9: //ContinuaSolicitud
				handleconsultaDatosDisponibilidadContinuaSolicitud(
					data?.caseInformation
				);
				break;
			default:
				handleGoPage(8);
				break;
		}
	};

	const onDownloadFile = () => {
		downloadPDF({
			payload: data?.ObtieneArchivoAcuseResponse?.data?.documento,
			name:
				data?.ObtieneArchivoAcuseResponse?.data?.nombreArchivo ||
				'undefined.pdf',
		});
	};

	useEffect(() => {
		setLabels([
			{
				label: 'Número de caso',
				value: data?.caseInformation?.numberCase || '',
			},
			{
				label: 'Fecha de solicitud',
				value: data?.caseInformation?.requestDate || '',
			},
			{
				label: 'Servicio',
				value: data?.caseInformation?.service || '',
			},
			{
				label: 'Estatus',
				value: data?.caseInformation?.status || '',
			},
			{
				label: 'Fecha de estatus',
				value: data?.caseInformation?.statusDate || '',
			},
		]);
	}, [data?.caseInformation]);

	useEffect(() => {
		setViewDownloadLink(
			data?.claseOperacion === 'ZDVU' ||
				data?.claseOperacion === 'ZFNE' ||
				data?.claseOperacion === 'ZAFA' ||
				data?.claseOperacion === 'ZBIM' ||
				data?.claseOperacion === 'ZASV'
				? false
				: true
		);
	}, [data?.claseOperacion]);

	return (
		<div>
			<ModalLoading loading={loading}></ModalLoading>
			<div className={style.divTotalSaving}>
				<h2 className={style.titleNotice}>
					Gracias por utilizar los servicios digitales del Infonavit
				</h2>
			</div>
			<div>
				<TableInfo tableLabels={Labels}></TableInfo>
			</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					paddingTop: '20px',
				}}
			>
				<label style={{ textAlign: 'center' }}>
					{data?.caseInformation?.description}
				</label>
			</div>
			{data?.caseInformation?.btnCustom?.visible !== true && (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						paddingTop: '10px',
					}}
				>
					{viewDownloadLink === true && (
						<div className={style.contentFooterDataLink}>
							<div
								onClick={onDownloadFile}
								style={{
									cursor: 'pointer',
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<label
									style={{ marginTop: 7, cursor: 'pointer' }}
								>
									Descargar comprobante
								</label>
								<img
									src={iconDownload}
									alt="icono de descarga comprobante"
								/>
							</div>
						</div>
					)}
				</div>
			)}

			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<div style={{ width: '50%' }}>
					<CustomAlert
						data-testid="mensageAlertthree"
						message={alert.message}
						severity={alert.severity}
						show={alert.show}
						onClose={() => {
							setAlert({
								show: false,
								message: '',
								severity: 'success',
							});
						}}
					/>
				</div>
			</div>

			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className={style.divButtonsNoReverse}>
					<div className={style.Buttons}>
						<CustomButton
							onClick={() => navigate('/inicio')}
							variant={
								data?.caseInformation?.btnCustom?.visible
									? 'outlined'
									: 'solid'
							}
							styles={{
								width: '100%',
								height: '100%',
								margin: '0 auto',
							}}
							label="Finalizar"
						/>
					</div>
					{data?.caseInformation?.btnCustom?.visible === true && (
						<div className={style.Buttons}>
							<CustomButton
								onClick={() => {
									let option =
										data?.caseInformation?.btnCustom
											?.onClickOption;
									onClickButtonCustom(option);
								}}
								variant="solid"
								styles={{
									width: '100%',
									height: '100%',
									margin: '0 auto',
								}}
								label={
									data?.caseInformation?.btnCustom?.label ||
									''
								}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default CaseInformationPage;
