import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useStyles } from '../Register/styles';
import CustomButton from '../../components/Button';
import TextInputPassword from '../../components/TextInputPassword';
import { IInputPasswordResult } from '../../interfaces/inputPassword';
import CustomAlert from '../../components/CustomAlert';
import { IPasswordData } from './interfaces/register.interface';

const Password = (props: IPasswordData) => {
	const classes = useStyles();
	const [password, setPassword] = useState({} as IInputPasswordResult);
	const [errorInputPassword, setErrorInputPassword] = useState(false);
	const { onContinue, onChange, hasError, onCloseError } = props;

	const onChangePass = (value: IInputPasswordResult) => {
		setPassword(value);
		onChange('password', value.password);
	};

	return (
		<div style={{ width: '100%' }}>
			<Grid container className={classes.container}>
				<Grid item xs={0} sm={0} md={3} lg={3}></Grid>
				<Grid item xs={12} sm={12} md={6} lg={6}>
					<TextInputPassword
						value={password.password}
						onChange={onChangePass}
						isRequired
						confirmPassword
						setErrorInputPassword={setErrorInputPassword}
						errorInputPassword={errorInputPassword}
					/>
					<div className={classes.buttonCenter}>
						<div className={classes.movilButton}>
							<div style={{ width: '100%' }}>
								<CustomAlert
									show={hasError.show}
									severity={hasError.severity}
									message={hasError.message}
									onClose={onCloseError}
								/>
							</div>
							<br />
							<CustomButton
								label="Continuar"
								onClick={onContinue}
								variant="solid"
								disabled={!password.valid || errorInputPassword}
							/>
						</div>
					</div>
					<br />
				</Grid>
				<Grid item xs={0} sm={0} md={3} lg={3}></Grid>
			</Grid>
		</div>
	);
};

export default Password;
