import { useNavigate } from 'react-router-dom';
import { Box, Divider, Grid, useMediaQuery, Link } from '@mui/material';
import { useStyles } from './styles';
import { useTheme } from '@mui/material/styles';
import {
	IResponse,
	FormatMoney,
	IDocument,
} from '../../interfaces/EcotechConstancy';
import descarga from '../../assets/img/icono-descarga.png';

interface Props {
	data: IResponse;
	pdf: IDocument;
}

const Caso1 = ({ data, pdf }: Props) => {
	const navigate = useNavigate();
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));

	const getPdfDowload = () => {
		if (pdf.documento) {
			let link = document.createElement('a');
			link.download = 'constancia_de_ecotecnologías.pdf';
			link.href = 'data:application/octet-stream;base64,' + pdf.documento;
			link.click();
		}
	};

	return (
		<Box>
			<h3 style={{ padding: '0px 40px', paddingTop: 40 }}>
				Aspectos importantes
			</h3>
			<Grid container columns={12}>
				<Grid item xs={12} sm={12} md={12} className={classes.parrafo}>
					<p>
						Cuando te entreguen las ecotecnologías que compraste y
						que serán instaladas en tu casa, el Proveedor debe
						entregarte las garantías, así como los manuales de uso y
						mantenimiento. <br /> <br /> No olvides solicitarle la
						factura por el canje de tus Ecotecnologías, y recuerda
						que hasta no estar satisfecho con el servicio brindado y
						las tecnologías instaladas funcionando en su totalidad,
						no debe solicitarte la firma de conformidad en tu
						Constancia. En caso de tener alguna inconformidad con el
						servicio brindado por tu proveedor o alguna queja
						relacionada con este proceso, podrás dejar tu queja a
						través del servicio{' '}
						<Link
							onClick={() =>
								navigate('/contactanos/presenta-sigue-queja')
							}
							style={{ cursor: 'pointer' }}
							underline="none"
						>
							Haz y sigue tu queja.
						</Link>
					</p>
				</Grid>
			</Grid>
			<Grid
				container
				columns={15}
				className={classes.divgris}
				sx={{ pt: 3, pb: matches ? 3 : 4 }}
			>
				<Grid item xs={12} sm={12} md={3}>
					<div>
						<p className={classes.label}>Monto otorgado:</p>
						<h3 className={classes.bluetitle}>
							{FormatMoney(data.montoOtorgado || 0)}
						</h3>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={5.5}>
					<div>
						<p className={classes.label}>Proveedor:</p>
						<h3 className={classes.bluetitle}>{data.proveedor}</h3>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={4}>
					<div>
						<p className={classes.label}>Estatus:</p>
						<h3 className={classes.bluetitle}>{data.estatus}</h3>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={2.5}>
					<div>
						<p className={classes.label}>Fecha de canje:</p>
						<h3 className={classes.bluetitle}>{data.fechaCanje}</h3>
					</div>
				</Grid>
			</Grid>

			{data?.pantalla === '1' && (
				<Grid
					container
					columns={12}
					className={classes.parrafocenter}
					sx={{
						pt: matches ? 3 : 4,
						pb: matches ? 1 : 3,
						pr: matches ? 3 : 6,
						pl: matches ? 3 : 6,
						textAlign: matches ? 'left' : 'center',
					}}
				>
					<Grid item xs={12} sm={12} md={12}>
						<Link
							href="#pdf"
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
							onClick={() => getPdfDowload()}
						>
							Constancia de crédito para ecotecnologías
							<img
								src={descarga}
								style={{
									width: 24,
									marginLeft: 5,
								}}
								alt="icono de descarga"
							/>
						</Link>

						<div>
							<p>
								Para consultar los documentos anteriores es
								necesario que:
								<br />
								<br />
								Tengas instalada la última versión de Acrobat
								Reader.{' '}
								<Link
									href="https://get.adobe.com/reader/?loc=es"
									target="_blank"
								>
									Descárgalo aquí.
								</Link>
								<br />
								Permitas las ventanas emergentes en tu
								explorador de internet.{' '}
								<Link
									href="https://support.google.com/chromebook/answer/95472?hl=es-419&co=GENIE.Platform%3DDesktop"
									target="_blank"
								>
									Consulta la guía.
								</Link>
							</p>
						</div>
					</Grid>
				</Grid>
			)}

			<Grid
				container
				columns={12}
				sx={{
					pt: matches ? 3 : 2,
					pb: matches ? 1 : 1,
					pr: matches ? 3 : 6,
					pl: matches ? 3 : 6,
				}}
			>
				<Grid item xs={12} sm={12} md={12} className={classes.title}>
					<h3 style={{ marginBottom: 0 }}>
						Ecotecnologías adquiridas:
					</h3>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					className={classes.parrafogris}
				>
					<ul style={{ padding: 0 }}>
						{data.lista?.map((element, i) => (
							<p key={i} className={classes.plist}>
								{element}
							</p>
						))}
					</ul>
				</Grid>
			</Grid>

			<Grid container columns={12} sx={{ pr: 3, pl: 3 }}>
				<Grid item xs={12} sm={12} md={12}>
					<Divider />
				</Grid>
			</Grid>
			<Grid
				container
				columns={12}
				sx={{
					pt: matches ? 1 : 1,
					pb: matches ? 4 : 5,
					pr: matches ? 3 : 6,
					pl: matches ? 3 : 6,
				}}
			>
				<Grid item xs={12} sm={12} md={12} className={classes.title}>
					<h3>Responsable de la información:</h3>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					className={classes.parrafogris}
				>
					<p className={classes.plist}>
						Gerencia de Evaluación y Análisis
					</p>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Caso1;
