import { useState, useEffect } from 'react';
import {
	defaultDataRegister,
	defaultUserData,
	IUserData,
	IUserDataRegister,
} from '../interfaces/register.interface';
import { TABS_REGISTER } from '../utils/tabs.utils';
import { createAccountAPI } from '../../../api/modules/createAccount';
import { INTERNAL_MESSAGES } from '../../../config/messageCatalog';
import { encrypt } from '../../../utils/encrypt';
import { IAlert } from '../../../interfaces/alert';
import { keyAPI } from '../../../api/modules/key';
import { useTabs } from '../../../hooks/useTabs';
import { useBitacora } from '../../../hooks/useBitacora';
import { useNavigate } from 'react-router-dom';

export const useRegister = () => {
	const [userData, setUserData] =
		useState<IUserDataRegister>(defaultDataRegister);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [loading, setLoading] = useState(false);
	const [keyParam, setKeyParam] = useState('');
	const [isActivated, setIsActivated] = useState(false);
	const [modalConfirm, setModalConfirm] = useState(false);
	const [superError, setSuperError] = useState(false);
	const [isActivatedEmail, setIsActivatedEmail] = useState(false);
	const [noResend, setNoResend] = useState(false);

	const [modalNameIncorrect, setModalNameIncorrect] = useState(false);
	const [messageNameIncorrect] = useState('');
	const [dataUser, setDataUser] = useState<IUserData>(defaultUserData);
	const [messageVerification, setMessageVerification] = useState('');
	const {
		containerPosition,
		tabActive,
		tabsEle,
		changeTabNext,
		changeTabPrevious,
		changeTabNextCustom,
	} = useTabs(TABS_REGISTER, 1, 0);
	const { regBitacoraNotOld, getMaskString } = useBitacora();
	const navigate = useNavigate();

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		if (params.verification && params.key) {
			setUserData({ ...userData, byPoE: 'byEmail' });
			changeTabNextCustom(4);
			handleValidateEmail(params.key || '');
			setNoResend(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleFirstStep = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(userData.nss || '', key);
			const curp_base64 = await encrypt(userData.curp || '', key);
			const rfc_base64 = await encrypt(userData.rfc || '', key);
			const dataFlag = {
				nss: nss_base64,
				curp: curp_base64,
				rfc: rfc_base64,
			};
			createAccountAPI
				.createAccountFirstStep({ ...dataFlag })
				.then((res) => {
					if (res?.ok) {
						regBitacoraNotOld(
							createAccountAPI.schemeUrl,
							{
								nss: userData.nss,
								curp: userData.curp,
								rfc: userData.rfc,
							},
							{
								identificador: userData.nss,
								signalKey: key,
							}
						);
						changeTabNext();
					} else {
						setAlert({
							show: true,
							message: INTERNAL_MESSAGES.SAVE_DATA,
							severity: 'error',
						});
					}
				})
				.catch((error) => {
					if (error.description) {
						setAlert({
							show: true,
							message:
								error.description ||
								INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
					} else {
						setAlert({
							show: true,
							message: INTERNAL_MESSAGES.SAVE_DATA,
							severity: 'error',
						});
					}
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.SAVE_DATA,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const handleSecondStep = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(userData.nss || '', key);
			const dataFlag = {
				nss: nss_base64,
			};
			createAccountAPI
				.createAccountSecondStep({ ...dataFlag })
				.then((res) => {
					if (res?.ok) {
						changeTabNext();
					} else {
						setAlert({
							show: true,
							message: INTERNAL_MESSAGES.SAVE_DATA,
							severity: 'error',
						});
					}
				})
				.catch((error) => {
					if (error?.description) {
						setAlert({
							show: true,
							message: error.description,
							severity: 'error',
						});
					} else {
						setAlert({
							show: true,
							message: INTERNAL_MESSAGES.SAVE_DATA,
							severity: 'error',
						});
					}
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.SAVE_DATA,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const handleThirdStep = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const email_base64 = await encrypt(userData.email, key);
			const phone_base64 = await encrypt(userData.phone, key);
			const nss_base64 = await encrypt(userData.nss, key);
			const byPoE_base64 = await encrypt(userData.byPoE, key);

			const password_base64 = await encrypt(userData.password, key);
			const dataFlag = {
				email: email_base64,
				phone: phone_base64,
				nss: nss_base64,
				byPoE: byPoE_base64,
				password: password_base64,
			};
			createAccountAPI
				.createAccountThirdStep({ ...dataFlag })
				.then((res) => {
					if (res?.ok) {
						regBitacoraNotOld(
							createAccountAPI.schemeUrl,
							{
								email: userData.email,
								phone: userData.phone,
								nss: userData.nss,
								byPoE: userData.byPoE,
								password: getMaskString(userData.password),
							},
							{
								identificador: userData.nss,
								signalKey: key,
							}
						);
						changeTabNext();
					} else {
						setAlert({
							show: true,
							message: INTERNAL_MESSAGES.SAVE_DATA,
							severity: 'error',
						});
					}
				})
				.catch((error) => {
					if (error) {
						if (error.codeM && error.codeM === 'MCI0005') {
							setNoResend(true);
						} else {
							setNoResend(false);
						}
						if (error.description) {
							setAlert({
								show: true,
								message: error.description
									? error.description
									: INTERNAL_MESSAGES.SAVE_DATA,
								severity: 'error',
							});
						} else {
							setAlert({
								show: true,
								message: INTERNAL_MESSAGES.SAVE_DATA,
								severity: 'error',
							});
						}
					} else {
						setAlert({
							show: true,
							message: INTERNAL_MESSAGES.SAVE_DATA,
							severity: 'error',
						});
					}
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.SAVE_DATA,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const handleValidateEmail = (key: string) => {
		setLoading(true);
		createAccountAPI.setIdentificador(userData.nss);
		createAccountAPI
			.validateEmail(key)
			.then((response) => {
				if (response.estatusEmail) {
					setIsActivatedEmail(true);
					if (response.activo) {
						navigate('/');
					} else {
						if (response.ok) {
							setIsActivated(true);
							setKeyParam(key);
							if (!response.epv) {
								changeTabNextCustom(5);
							}
						}
					}
				}
			})
			.catch((error) => {
				if (error?.codeM && error?.codeM === 'MCI0005') {
					setNoResend(true);
				}
				setLoading(false);
				setKeyParam(key);
				if (error?.description === INTERNAL_MESSAGES.VALIDATE_MAIL) {
					handleValidateNSS(error.nss as string);
				} else
					setAlert({
						show: true,
						message:
							error?.description ||
							INTERNAL_MESSAGES.ERROR_OCCURRED,
						severity: 'error',
					});
			})
			.finally(() => setLoading(false));
	};

	const handleValidateNSS = async (nss: string) => {
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const code_base64 = await encrypt(nss || '', key);
			createAccountAPI
				.validateNSS(code_base64)
				.then(() => {
					setIsActivatedEmail(true);
					setIsActivated(true);
				})
				.catch((err) => {
					if (err?.description) {
						let errorFlag = err.description;
						if (
							errorFlag === 'Cuenta en proceso de Unificación' ||
							errorFlag ===
								'Cuenta con marca de fallecido en SACI'
						) {
							errorFlag = err.message;
						}
						setAlert({
							show: true,
							message:
								errorFlag || INTERNAL_MESSAGES.ERROR_OCCURRED,
							severity: 'error',
						});
						setSuperError(true);
					}
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setLoading(false);
		}
	};

	const handleCreateAccount = async (code: string) => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const code_base64 = await encrypt(code, key);
			const nss_base64 = await encrypt(userData.nss, key);
			createAccountAPI
				.valideteCreation({
					code: code_base64,
					key: keyParam,
					nss: nss_base64,
				})
				.then((res) => {
					if (res?.ok) {
						regBitacoraNotOld(
							createAccountAPI.schemeUrl,
							{
								code,
								key: keyParam,
								nss: userData.nss,
							},
							{
								identificador: userData.nss,
								signalKey: key,
							}
						);
						changeTabNext();
					} else {
						setAlert({
							show: true,
							message: INTERNAL_MESSAGES.WRONG_NUMBER,
							severity: 'error',
						});
					}
				})
				.catch((error) => {
					if (error?.description) {
						setAlert({
							show: true,
							message: error.description,
							severity: 'error',
						});
					} else if (error === INTERNAL_MESSAGES.STATUS_NSS) {
						changeTabNext();
					} else {
						setAlert({
							show: true,
							message: INTERNAL_MESSAGES.ERROR_VALIDATION,
							severity: 'error',
						});
					}
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_VALIDATION,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const setUserDataRegister = (name: string, value: string | boolean) => {
		setUserData({ ...userData, [name]: value });
	};

	const handleResendCodeSms = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(userData.nss, key);
			createAccountAPI
				.resendSMS(keyParam, nss_base64)
				.then((res) => {
					if (res?.ok) {
						setAlert({
							show: true,
							message: INTERNAL_MESSAGES.FORWARDED_NUMBER,
							severity: 'success',
						});
					}
				})
				.catch((error) => {
					setAlert({
						show: true,
						message:
							error?.description ||
							INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_VALIDATION,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const handleResendEmail = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(userData.nss, key);
			createAccountAPI
				.resendEmail(keyParam, nss_base64)
				.then((res) => {
					if (res?.ok) {
						setAlert({
							show: true,
							message: INTERNAL_MESSAGES.EMAIL_SENT,
							severity: 'success',
						});
					}
				})
				.catch((error) => {
					setAlert({
						show: true,
						message:
							error?.description ||
							INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.EMAIL_NOT_SENT,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const handleContinueNSS = (value: number) => {
		if (value === 4) {
			setIsActivatedEmail(true);
		}
		if (value === 3) {
			return changeTabNextCustom(value + 1);
		}
		changeTabNextCustom(value);
	};

	const createCaso = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(userData.nss || '', key);
			const curp_base64 = await encrypt(
				userData.curp ? userData.curp : dataUser.curp,
				key
			);
			const rfc_base64 = await encrypt(
				userData.rfc ? userData.rfc : dataUser.rfc,
				key
			);
			createAccountAPI
				.createCaso(nss_base64, curp_base64, rfc_base64)
				.then((res: any) => {
					if (res?.description) {
						setMessageVerification(res.description);
					}
					setAlert({
						show: false,
						message: '',
						severity: 'success',
					});
					regBitacoraNotOld(
						createAccountAPI.schemeUrl,
						{
							rfc: userData.rfc ? userData.rfc : dataUser.rfc,
							curp: userData.curp ? userData.curp : dataUser.curp,
							nss: userData.nss,
						},
						{
							identificador: userData.nss,
							signalKey: key,
						}
					);
				})
				.catch((err: any) => {
					setAlert({
						show: true,
						message: err.description
							? err.description
							: 'Por el momento el servicio no se encuentra disponible, intenta más tarde',
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description
					? error.description
					: 'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const setData = (data: IUserData) => {
		setDataUser(data);
	};

	return {
		handleFirstStep,
		handleSecondStep,
		handleThirdStep,
		handleCreateAccount,
		setUserDataRegister,
		handleResendCodeSms,
		handleResendEmail,
		handleContinueNSS,
		createCaso,
		setData,
		alert,
		loading,
		isActivated,
		modalConfirm,
		superError,
		isActivatedEmail,
		noResend,
		modalNameIncorrect,
		setModalNameIncorrect,
		messageNameIncorrect,
		messageVerification,
		containerPosition,
		tabActive,
		tabsEle,
		changeTabPrevious,
		setModalConfirm,
		userData,
		setAlert,
		changeTabNext,
		dataUser,
		setIsActivatedEmail,
	};
};
