import { Grid, Paper } from '@mui/material';
import { useStyles } from './styles';
import MessageComponent from './components/MessageComponent';
import SolitaryModule from '../../components/SolitaryModule';
import {
	CODES,
	dateFormat,
	getValNumberOrNull,
	getVSMFormat,
} from '../../utils';
import { SectionBalance } from '../../components/SectionBalance';
import { CardBalance } from '../../components/CardBalance';
import { LabelCardBalanceValues } from '../../components/LabelCardBalanceValues';
import { LabelCardBalance } from '../../components/LabelCardBalance';
import { RowCreditOrigin } from '../../components/RowCreditOrigin';
import { useContextMoment } from '../../context';
import CertificateQuality from '../../components/CertificateQuality';

export const CreditOrigin = () => {
	const classes = useStyles();
	const { balance } = useContextMoment();

	return (
		<Paper className={classes.containerPaper}>
			<div className={classes.containerMain}>
				<MessageComponent />

				{[CODES.LIQUIDADOS_POR_PAGO, CODES.LIQUIDADOS_OTROS].includes(
					balance.propiedades?.tipoCaso
				) && <SolitaryModule />}

				<SectionBalance title="Condiciones financieras con las que firmaste tu crédito">
					<Grid container spacing={2} marginBottom={3}>
						<Grid item xs={12} sm={12} md={6} lg={4}>
							<CardBalance title="Monto del crédito otorgado en pesos">
								<LabelCardBalanceValues
									variant="info"
									mxnValue={Number(
										balance?.datosOriginacion
											?.condicionesIniciales
											?.montoDeCredito || '0'
									)}
									vsmValue={getValNumberOrNull(
										balance?.datosOriginacion
											?.condicionesIniciales
											?.montoDeCreditoVsm
									)}
								/>
							</CardBalance>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={4}>
							<CardBalance title="Tasa de interés">
								<LabelCardBalance
									label={
										balance?.datosOriginacion
											?.condicionesIniciales
											?.tasaInteres || '---'
									}
								/>
							</CardBalance>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={4}>
							<CardBalance title="Tipo de Crédito">
								<LabelCardBalance
									label={
										balance?.datosOriginacion
											?.condicionesIniciales
											?.tipoCredito || '---'
									}
								/>
							</CardBalance>
						</Grid>
					</Grid>
					<RowCreditOrigin
						firstRow
						title="Número de crédito"
						subTitle={
							balance?.datosOriginacion?.condicionesIniciales
								?.numeroCredito || '---'
						}
					/>
					<RowCreditOrigin
						title="Fecha de otorgamiento"
						tooltip="Día, mes y año en que fue otorgado el crédito."
						subTitle={
							balance?.datosOriginacion?.condicionesIniciales
								?.fechaOtorgamiento
								? dateFormat(
										balance?.datosOriginacion
											?.condicionesIniciales
											?.fechaOtorgamiento
								  )
								: '---'
						}
					/>
					<RowCreditOrigin
						title="Tipo de moneda"
						tooltip="Unidad de pago con la que fue contratado el crédito. Puede ser en VSM o en pesos."
						subTitle={
							balance?.datosOriginacion?.condicionesIniciales
								?.tipoMoneda || '---'
						}
					/>
					<RowCreditOrigin
						title="Saldo de otorgamiento en VSM"
						tooltip="Monto de dinero que el Infonavit otorgó en VSM al autorizar el crédito."
						subTitle={
							balance?.datosOriginacion?.condicionesIniciales
								?.saldoOtorgamientoVsm
								? getVSMFormat(
										balance?.datosOriginacion
											?.condicionesIniciales
											?.saldoOtorgamientoVsm
								  )
								: '---'
						}
					/>
					<RowCreditOrigin
						title="Tipo de tasa de interés"
						tooltip="Puede ser fija o variable, de acuerdo con las características del crédito."
						subTitle={
							balance?.datosOriginacion?.condicionesIniciales
								?.tasaInteres || '---'
						}
					/>
					<RowCreditOrigin
						title="Plazo"
						tooltip="Plazo"
						subTitle={
							balance?.datosOriginacion?.condicionesIniciales
								?.plazo
								? `${balance?.datosOriginacion?.condicionesIniciales?.plazo} años`
								: '---'
						}
					/>
					<RowCreditOrigin
						title="Tipo de pago"
						tooltip="Tipo de pago."
						subTitle={
							balance?.datosOriginacion?.condicionesIniciales
								?.tipoPago || '---'
						}
						lastRow
					/>
				</SectionBalance>
				<SectionBalance title="Datos y características financieras de mi crédito actuales">
					<RowCreditOrigin
						title="Fecha de movimiento"
						tooltip="Día, mes y año en que fue el actual movimiento del crédito."
						subTitle={
							balance?.datosOriginacion?.condicionesActuales
								?.fechaMovimiento || '---'
						}
						firstRow
					/>
					<RowCreditOrigin
						title="Tipo de moneda"
						tooltip="Unidad de pago con la que fue contratado el crédito. Puede ser en VSM o en pesos."
						subTitle={
							balance?.datosOriginacion?.condicionesActuales
								?.tipoMoneda || '---'
						}
					/>
					<RowCreditOrigin
						title="Tipo de tasa de interés"
						tooltip="Puede ser fija o variable, de acuerdo con las características del crédito."
						subTitle={
							balance?.datosOriginacion?.condicionesActuales
								?.tipoTasaInteres || '---'
						}
					/>
					<RowCreditOrigin
						title="Tipo de movimiento"
						tooltip="Tipo de movimiento"
						subTitle={
							balance?.datosOriginacion?.condicionesActuales
								?.tipoMovimiento || '---'
						}
					/>
					<RowCreditOrigin
						title="Plazo"
						tooltip="Plazo"
						subTitle={
							balance?.datosOriginacion?.condicionesActuales
								?.plazo
								? `${balance?.datosOriginacion.condicionesActuales?.plazo} años`
								: '---'
						}
					/>
					<RowCreditOrigin
						title="Tasa de interés"
						tooltip="Tasa de interés"
						subTitle={
							balance?.datosOriginacion?.condicionesActuales
								?.tasaInteres || '---'
						}
					/>
					<RowCreditOrigin
						title="Tipo de pago"
						tooltip="Tipo de pago."
						subTitle={
							balance?.datosOriginacion?.condicionesActuales
								?.tipoPago || '---'
						}
						lastRow
					/>
				</SectionBalance>

				<div className={classes.labelImportant}>
					<label>Importante:</label> Recuerda que desde noviembre de
					2024 congelamos los saldos y mensualidades de los créditos
					en Veces Salario Mínimo otorgados antes de 2013 para
					apoyarlos eliminando los incrementos anuales por la
					inflación.
				</div>

				<CertificateQuality />
			</div>
		</Paper>
	);
};
