/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { FormControlLabel, Checkbox, useTheme } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import CustomButton from '../../../components/Button';

import { dataReturnSavingsContext } from '../contextData';
import {
	IRequestAttachDocument,
	IRequestCreacionCaso,
	IRequestPersisteDatos,
	PropsRowInfo3,
	IRequestMarcarCuenta,
} from '../interfaces';
import { useStyles } from '../style';
import ModalLoading from '../../../components/ModalLoading';
import CustomAlert from '../../../components/CustomAlert';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import useCustomScript from '../../../hooks/useCustomScript';

interface TableProps {
	tableLabels?: PropsRowInfo3[] | undefined;
}
interface PropsRowInfo {
	label: string;
	value?: string;
}
const RowInfo = ({ value, label }: PropsRowInfo) => {
	const style = useStyles();
	return (
		<div style={{ marginTop: 15 }}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<label className={style.TextBlack_H3}>{label}</label>
				</div>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<label style={{ lineHeight: '0px' }}>{value || ''}</label>
				</div>
			</div>
			<hr
				style={{
					marginTop: 15,
					color: '#8A919C',
					height: '1px',
					opacity: '20%',
				}}
			/>
		</div>
	);
};
const TableInfo = ({ tableLabels }: TableProps) => {
	return (
		<div>
			{tableLabels?.map((row) => (
				<RowInfo value={row.value || ''} label={row.label}></RowInfo>
			))}
		</div>
	);
};
declare global {
	interface Window {
		firmar: any;
	}
}

const VerifyBanckDetailsPage = () => {
	const style = useStyles();
	const [disableBTNContinuar, setDisableBTNContinuar] = useState(false);
	const theme = useTheme();
	const {
		handlePrevPage,
		data,
		loading,
		alert,
		setAlert,
		handleCreacionCaso,
		handlerAttachDocument,
		dataEfirm,
		setLoading,
		pdfSigned,
		setPdfSigned,
		handleMarcarCuenta,
	} = useContext(dataReturnSavingsContext);
	useCustomScript('/jquery/jquery.min.js');
	useCustomScript('/efirma/firma-documento.js');
	useCustomScript('/efirma/fiel-validacion.ofu.min.js');
	var serverIP = process.env.REACT_APP_URL_VALIDA_FIEL;
	const firmar = window.firmar;

	const [BankDetails, setBankDetails] = useState<PropsRowInfo3[] | undefined>(
		undefined
	);

	const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDisableBTNContinuar(event.target.checked);
		setAlert({
			show: false,
			severity: 'success',
			message: '',
		});
	};
	const { credit } = useSelector((state: RootState) => state.credit);

	useEffect(() => {
		setBankDetails([
			{
				label: 'Banco',
				value: data?.bankData?.bank || '',
			},
			{
				label: 'Nombre',
				value: data?.bankData?.name || '',
			},
			{
				label: 'Clave Bancaria Estandarizada (CLABE)',
				value: data?.bankData?.CLABE || '',
			},
		]);
	}, [data?.bankData]);

	useEffect(() => {
		if (data?.startSignDocument && data?.startSignDocument === true) {
			if (!pdfSigned) {
				handleSubmitFirmar();
			}
		} else {
			return;
		}
	}, [data?.startSignDocument]);

	const handleSubmit = () => {
		if (data?.noCaso && data?.noCaso.length > 0) {
			//Crear Marca
			let paramsPremarca = {
				grupo: data?.grupo || '',
				entidadCaso: data?.returnSavingsStates || '',
				claseOperacion: data?.claseOperacion,
				caso: data?.noCaso,
				clabe: data?.bankData?.CLABE || '',
				opcion: data?.returnOptionType || '',
				nrp: data?.nrpNumber || '',
			} as IRequestMarcarCuenta;
			handleMarcarCuenta(paramsPremarca);
		} else {
			const params: IRequestCreacionCaso = {
				evento: '4',
				opcion: data?.returnOptionType || '',
				demanda: data?.hasDemand || false,
				tipoDemanda: data?.typeDemand || '',
				entidad: data?.returnSavingsStates || '',
				montoDevolver: data?.savingsInformationTotal || '0',
				montoSaldo92: data?.savingsInformation?.subaccount_1992 || '0',
				montoSaldo97: data?.savingsInformation?.subaccount_1997 || '0',
				montoSaldoFA: data?.savingsInformation?.savingFund || '0',
				montoAdicionalSaldoFA:
					data?.savingsInformation?.additionalQuantity || '0',
				descMontoAdicionalSaldoFA:
					data?.ConsultaDatosDisponibilidadResponse?.data
						?.desRechazoFa || '',
				numeroCredito: credit?.toString() || '',
				nrp: data?.nrpNumber || '',
				codigoRechazoFa:
					data?.ConsultaSaldoCeroResponse?.data?.codigoRechazo || '',
				grupo: data?.grupo || '',
			} as IRequestCreacionCaso;

			const paramsPersisteDatos: IRequestPersisteDatos = {
				opcion: data?.returnOptionType || '',
				grupo: data?.grupo || '',
			};
			handleCreacionCaso(params, paramsPersisteDatos);
		}
	};

	const handleSubmitFirmar = () => {
		var inputs = {
			keyBinary: dataEfirm.keyBinary,
			cerBinary: dataEfirm.cerBinary,
			cerB64: dataEfirm.cerB64,
			pdfB64: data?.marcarCuentaResponse?.data?.pdf,
			pdfContentType: 'application/pdf',
			password: dataEfirm.password,
			serverIP: serverIP,
		};
		firmar(inputs, (error: any, response: any) => {
			if (error === null) {
				setLoading(false);
				setPdfSigned(true);
				handlerAttachDocument({
					caso: data?.noCaso,
					archivo: response.pdfSignedB64,
				} as IRequestAttachDocument);
			} else {
				setLoading(false);
				setAlert({
					show: true,
					message: error,
					severity: 'error',
				});
			}
		});
	};

	return (
		<div>
			<ModalLoading loading={loading}></ModalLoading>
			<div className={style.divTotalSaving}>
				<label className={style.TextBlack_H3}>
					Verifica que los datos sean correctos
				</label>
			</div>
			<div>
				<TableInfo tableLabels={BankDetails}></TableInfo>
			</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'left',
					paddingTop: '10px',
				}}
			>
				<FormControlLabel
					control={
						<Checkbox
							icon={<CheckBoxOutlineBlankIcon />}
							checkedIcon={<CheckBoxIcon />}
							onChange={onChangeCheckbox}
							name="checkModal"
						/>
					}
					style={{
						color: disableBTNContinuar
							? theme.palette.primary.main
							: 'black',
						fontFamily: 'Roboto',
						lineHeight: 'normal',
						fontSize: 12,
					}}
					label={<p style={{ margin: 0 }}>Autorizo que mis recursos sean depositados en la cuenta bancaria que se muestra</p>}
					value="buttonAceptarCambios"
				/>
			</div>
			<div className={style.divAlert}>
				<CustomAlert
					message={alert.message}
					show={alert.show}
					severity={alert.severity}
					onClose={() => {
						setAlert({
							show: false,
							message: '',
							severity: alert.severity,
						});
					}}
				/>
			</div>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className={style.divButtons}>
					<div className={style.Buttons}>
						<CustomButton
							onClick={handlePrevPage}
							variant="outlined"
							styles={{
								width: '100%',
								height: '100%',
							}}
							label="Regresar"
						/>
					</div>

					<div className={style.Buttons}>
						<CustomButton
							onClick={handleSubmit}
							variant="solid"
							styles={{
								width: '100%',
							}}
							label="Continuar"
							disabled={!disableBTNContinuar}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VerifyBanckDetailsPage;
