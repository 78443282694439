/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import JSEncrypt from 'jsencrypt';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IAlert } from '../../../interfaces/alert';
import { keyAPI } from '../../../api/modules/key';
import { encrypt } from '../../../utils/encrypt';
import { INTERNAL_MESSAGES } from '../../../config/messageCatalog';
import { RootState } from '../../../store';
import { socioInfonavitAPI } from '../../../api/modules/InfonavitPartner';
import { actions } from '../../../store/modules/session';
import { actions as creditActions } from '../../../store/modules/credit';
import { actions as MiucActions } from '../../../store/modules/miuc';

export const useInfonavitPartner = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { user } = useSelector((state: RootState) => state.session);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [userRegister, setUserRegister] = useState(false);
	const [acceptConditions, setAcceptConditions] = useState(false);


	useEffect(() => {
		validateRegisterSocio();
	}, []);

	const validateRegisterSocio = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const paramsData = {
				email: user?.email || '',
			};
			const dataSendEncrypt = await encrypt(
				JSON.stringify(paramsData),
				key
			);
			const Response = await socioInfonavitAPI.IsRegistered(dataSendEncrypt);
			if (Response?.result?.code === '0000') {
				const value = Response.result.data?.registered || false;
				setUserRegister(value);
			} else {
				setAlert({
					show: true,
					message: Response.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
			}
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleEncrypt = async () => {
		setLoading(true);
		const email = user?.email || '';
		const rfc = user?.rfc || '';
		const name = user?.nombres || '';
		const lastName = user?.apPaterno || '';
		const phone = user?.telefonoCelular || '';

		const params = `${email}:${rfc}:${name}:${lastName}:${phone}`;
		const publicKey = process.env.REACT_APP_KEY_SOCIO || '';

		const encrypt = new JSEncrypt();
		encrypt.setPublicKey(publicKey);
		const base64_credentials = encrypt.encrypt(params);

		setLoading(false);
		window.open(`${process.env.REACT_APP_URL_REDIRECT_SOCIO}?${base64_credentials}`, '_blank');

		dispatch(actions.signOut());
		dispatch(creditActions.clearCredit());
		dispatch(MiucActions.clearData());
		setTimeout(() => {
			user?.isImpersonalized ? window.location.href = 'https://micuenta.infonavit.org.mx/' : navigate('/')
		}, 100)
	};

	return {
		handleEncrypt,
		loading,
		alert,
		userRegister,
		acceptConditions,
		setAcceptConditions,
		setAlert,
	}
}