import { Box } from '@mui/material';
import CaseFollowHome from './CaseFollowHome';
import { useState } from 'react';
import AttachDocuments from './AttachDocuments';
import { CASE_FOLLOW_STATUS } from './utils/pagesStatus';
import { useStyles } from './styles/styles';

interface IProps {
	nextProcess: () => void;
}

const CaseFollowUp = ({ nextProcess }: IProps) => {
	const [page, setPage] = useState(CASE_FOLLOW_STATUS.HOME);
	const styles = useStyles();

	return (
		<Box className={styles.containerCaseFollowUp}>
			{page === CASE_FOLLOW_STATUS.HOME && (
				<CaseFollowHome setPage={setPage} nextProcess={nextProcess} />
			)}
			{page === CASE_FOLLOW_STATUS.ATTACH_DOCUMENTS && (
				<AttachDocuments setPage={setPage} />
			)}
		</Box>
	);
};

export default CaseFollowUp;
