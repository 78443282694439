/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, FormControlLabel, Checkbox, Link } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import Layout from '../../components/Layout';
import ModalLoading from '../../components/ModalLoading';
import CustomButton from '../../components/Button';
import { HeaderList } from '../../components/HeaderList';
import CustomAlert from '../../components/CustomAlert';

import { useStyles } from './styles';
import logosocio from '../../assets/img/socioInfonavit/logo.png';
import categoriasSocio from '../../assets/img/socioInfonavit/categorias.png';
import startIcon from '../../assets/img/socioInfonavit/start.png';
import notificationIcon from '../../assets/img/socioInfonavit/notification.png';
import percentageIcon from '../../assets/img/socioInfonavit/percentage.png';
import imgPersona from '../../assets/img/socioInfonavit/person.png';
import cartIcon from '../../assets/img/socioInfonavit/cart.png';
import imgPersona2 from '../../assets/img/socioInfonavit/person2.png';
import { useInfonavitPartner } from './hooks/useInfonavitPartner';


const InfonavitPartnetView = () => {
	const classes = useStyles();
	const {
		handleEncrypt,
		loading,
		alert,
		userRegister,
		acceptConditions,
		setAcceptConditions,
		setAlert
	} = useInfonavitPartner()

	return (
		<>
			<Layout>
				<>
					<HeaderList title="Conoce Socio Infonavit"></HeaderList>
					<Paper className={classes.container}>

						<div className={classes.divLogo}>
							<img src={logosocio} alt="Logo socio infonavit"></img>
						</div>

						{!userRegister && (
							<>
								<div className={classes.divText}>
									<h3 className={classes.spanTitle}>¡Bienvenido!</h3>
									<h3 className={classes.spanTitle}>Regístrate hoy y accede a todos los beneficios</h3>
									<p className={classes.txtInicial}>
										Es un programa gratuito con descuentos, ofertas y beneficios exclusivos
										en tus marcas preferidas para distintos productos y servicios.
									</p>
								</div>

								<div className={classes.divLogos}>
									<img src={categoriasSocio} alt="Categorías socio infonavit" />
								</div>

								<div className={classes.divFlexSmall}>
									<div className={classes.itemFlex50}>
										<div>
											<div className={classes.divFlexSmall}>
												<div>
													<img className={classes.iconsImg} src={startIcon} alt="Imagen estrella de marcas"></img>
												</div>
												<div>
													<p className={classes.textInfo}>
														No te pierdas de las diferentes marcas
														que te ofrecen una experiencia única
														con nosotros.
													</p>
												</div>
											</div>
											<div className={classes.divFlexSmall}>
												<div>
													<img className={classes.iconsImg} src={notificationIcon} alt="Imagen de campana de beneficios"></img>
												</div>
												<div>
													<p className={classes.textInfo}>
														Cada mes nuevas marcas y beneficios.
													</p>
												</div>
											</div>
											<div className={classes.divFlexSmall}>
												<div>
													<img className={classes.iconsImg} src={percentageIcon} alt="Imagen de cupones de beneficios"></img>
												</div>
												<div>
													<p className={classes.textInfo}>
														Recibe correos semanales con los beneficios
														más atractivos.
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className={classes.itemFlex50}>
										<img src={imgPersona} alt="Imagen de persona de compras"></img>
									</div>
								</div>

								<div className={classes.divText}>
									<p className={classes.txtInicial}>
										Autoriza tu acceso en la sección de términos y condiciones en tu perfil de
										Mi Cuenta Infonavit y déjanos encaminarte hacia todas las oportunidades y
										beneficios que Socio Infonavit tiene para ofrecerte.
									</p>
								</div>

								<div className={classes.footer}>
									<FormControlLabel
										control={
											<Checkbox
												icon={<CheckBoxOutlineBlankIcon />}
												checkedIcon={<CheckBoxIcon />}
												onChange={(e) => setAcceptConditions(e.target.checked)}
												name="checkModal"
											/>
										}
										label={
											<div className={classes.textFooter}>
												Aceptar{' '}
												<Link rel="noopener" href="https://portalmx.infonavit.org.mx/wps/wcm/connect/eca2a7b7-eeb3-4fec-a4d0-cd49f219503c/terminos_condiciones_socio_Infonavit.pdf?MOD=AJPERES" className={classes.link} target="_blank">
													términos y condiciones
												</Link>
											</div>
										}
										value="checkAviso"
									/>
									<CustomButton
										data-testid="buttonContinuar"
										label="Ingresa a Socio Infonavit"
										onClick={() => handleEncrypt()}
										variant="solid"
										styles={{
											width: 260,
											height: 50,
											margin: '10px auto 20px'
										}}
										disabled={acceptConditions ? false : true}
									/>
									<p className={classes.textFooter}>
										Al ingresar a Socio Infonavit por seguridad de tu cuenta la
										sesión se cerrará en automático, puedes volver a ingresar a
										Mi Cuenta Infonavit en cualquier momento.
									</p>
								</div>
							</>
						)}

						{userRegister && (
							<>
								<div className={classes.divText}>
									<h3 className={classes.spanTitle}>
										Acércate a un mundo de posibilidades y diviértete ahorrando
									</h3>
								</div>

								<div className={classes.divFlex}>
									<div>
										<img className={classes.iconsImgLarge} src={cartIcon} alt="Imagen de carrito de promociones"></img>
									</div>
									<div>
										<p className={classes.textInfoCustom}>
											No te olvides que <b>Socio Infonavit</b> cuenta
											con una variedad de promociones en servicios de
											salud, viajes, cursos en línea, entretenimiento
											y muchas cosas más.
										</p>
									</div>
								</div>

								<div className={classes.divLogos}>
									<img src={categoriasSocio} alt="Categorías socio infonavit" />
								</div>

								<div className={classes.divText}>
									<h3 className={classes.spanTitle}>
										Ingresa a Socio Infonavit y descubre un abanico
										de beneficios especialmente ideados para ti
									</h3>
								</div>

								<div className={classes.divFlex}>
									<div>
										<img className={classes.iconsImgLarge} src={imgPersona2} alt="Imagen de persona de compras"></img>
									</div>
									<div>
										<p className={classes.textInfoCustom}>
											No te pierdas la oportunidad de seguir siendo
											parte de esta experiencia. Atrévete a disfrutar
											a lo grande y aprovecha de nuestros beneficios.
										</p>
									</div>
								</div>

								<div className={classes.footer}>
									<CustomButton
										data-testid="buttonContinuar"
										label="Ingresa a Socio Infonavit"
										onClick={() => handleEncrypt()}
										variant="solid"
										styles={{
											width: 260,
											height: 50,
											margin: '10px auto 20px'
										}}
									/>
									<p className={classes.textFooter}>
										Al ingresar a Socio Infonavit por seguridad de tu cuenta la
										sesión se cerrará en automático, puedes volver a ingresar a
										Mi Cuenta Infonavit en cualquier momento.
									</p>
								</div>
							</>
						)}

						<CustomAlert
							message={alert.message}
							severity={alert.severity}
							show={alert.show}
							onClose={() => {
								setAlert({
									show: false,
									message: '',
									severity: alert.severity,
								});
							}}
						/>

					</Paper>
					<ModalLoading loading={loading} />
				</>
			</Layout>
		</>
	);
};

export default InfonavitPartnetView;
