import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import CustomButton from '../../../components/Button';
import SelectInput from '../../../components/SelectInput';
import { writingRequestAPI } from '../../../api/modules/WritingRequest';
import ModalLoading from '../../../components/ModalLoading';
import { encodeAllJSONData } from '../../../utils/encrypt';
import { RootState } from '../../../store';
import { MSG_ERROR } from './utils';
import CustomAlert from '../../../components/CustomAlert';
import { useEscrituras } from '../hooks/useEscrituras';
import { CODE_RESPONSE } from './utils/pagesStatus';
import { useStyles } from './styles/choose-branch-styles';
import { IFormGeneral } from './interface';
import { useBitacora } from '../../../hooks/useBitacora';

interface IProps {
	nextProcess: () => void;
	formCreate: IFormGeneral;
	setFormCreate: (arg: IFormGeneral) => void;
}

interface IOptions {
	value: string;
	text: string;
}

const ChooseBranch = ({ nextProcess, formCreate, setFormCreate }: IProps) => {
	const { user } = useSelector((state: RootState) => state.session);
	const { credit } = useSelector((state: RootState) => state.credit);
	const [loading, setLoading] = useState(false);
	const [estados, setEstados] = useState<IOptions[]>([]);
	const [cesis, setCesis] = useState<IOptions[]>([]);
	const [msgError, setMsgError] = useState('');
	const { escrituras, setEscrituras } = useEscrituras();
	const navigate = useNavigate();
	const styles = useStyles();
	const { regBitacoraNotOld } = useBitacora();

	const validEdition = (): boolean => {
		if (!formCreate?.edoTramite) {
			return true;
		}
		if (!formCreate?.cesiTramite) {
			return true;
		}
		return false;
	};

	const consultarEstados = async () => {
		try {
			setLoading(true);
			const { result } = await writingRequestAPI.getCatalogoEstado();
			const dEstados = result?.data.estados.map((e: any) => {
				return {
					value: e.idDelEdo,
					text: e.delEdo,
				};
			});
			setEstados(dEstados);
		} catch (error) {
			setMsgError(MSG_ERROR);
		} finally {
			setLoading(false);
		}
	};

	const changeEstado = async ({ target }: any) => {
		try {
			setLoading(true);
			setMsgError('');
			const data = {
				idDelEdo: target.value,
			};
			const dataEncripted = await encodeAllJSONData(
				{ data: JSON.stringify(data) },
				user?.k || ''
			);
			const { result } = await writingRequestAPI.getCatalogoCesi(
				dataEncripted
			);
			const dCesis = result?.data.cesis.map((e: any) => {
				return {
					value: e.idPlaza,
					text: e.nombre,
				};
			});
			setCesis(dCesis);
			setFormCreate({
				...formCreate,
				[target.name]: target.value,
				cesiTramite: '',
			});
		} catch (error) {
			setMsgError(MSG_ERROR);
			setFormCreate({
				...formCreate,
				cesiTramite: '',
			});
		} finally {
			setLoading(false);
		}
	};

	const createCaso = async () => {
		try {
			setLoading(true);
			setMsgError('');
			const data = {
				...formCreate,
				credito: credit,
			};
			const dataEncripted = await encodeAllJSONData(
				{ data: JSON.stringify(data) },
				user?.k || ''
			);
			const { result } = await writingRequestAPI.createCaso(
				dataEncripted
			);
			if (result.code === CODE_RESPONSE.SUCCESS) {
				// Se registra el formulario en la bitacora
				regBitacoraNotOld(writingRequestAPI.schemeUrl, data);
				// Dato edoTramite
				const edoTramite =
					estados.find((f) => f.value === formCreate.edoTramite)
						?.text ?? '';
				// Dato cesiTramite
				const cesiTramite =
					cesis.find((f) => f.value === formCreate.cesiTramite)
						?.text ?? '';
				// Datos del caso creado consultamos
				const { result: rCaso } = await writingRequestAPI.getCaso();
				// Seteamos todos los datos
				setEscrituras({
					...escrituras,
					...rCaso?.data,
					noCaso: result?.data?.caso,
					cesi: formCreate.cesiTramite,
					edoCesi: formCreate.edoTramite,
					edoTramite,
					cesiTramite,
				});
				nextProcess();
				return;
			}

			setMsgError(result.message || MSG_ERROR);
		} catch (error) {
			setMsgError(MSG_ERROR);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		consultarEstados();
		/* eslint-disable react-hooks/exhaustive-deps */
	}, []);

	return (
		<Box className={styles.mainBox}>
			<ModalLoading loading={loading} />
			<p className={styles.labelBold}>
				Selecciona el Cesi en el que vas a recoger tus escrituras en el
				momento que estén listas
			</p>
			<Box className={styles.containerGroupInputs}>
				<Box className={styles.containerInput}>
					<SelectInput
						id="edoTramite"
						name="edoTramite"
						label="Estado de la república:"
						value={formCreate?.edoTramite || ''}
						onChange={changeEstado}
						options={estados}
					/>
				</Box>

				<Box className={styles.containerInput}>
					<SelectInput
						id="cesiTramite"
						name="cesiTramite"
						label="Centro de servicios Infonavit:"
						value={formCreate?.cesiTramite || ''}
						onChange={({ target }) =>
							setFormCreate({
								...formCreate,
								[target.name]: target.value,
							})
						}
						options={cesis}
					/>
				</Box>
			</Box>

			<CustomAlert
				message={msgError}
				severity="warning"
				show={Boolean(msgError)}
			/>

			<Box className={styles.btnGroup}>
				<Box className={styles.btnItem}>
					<CustomButton
						label={'Salir'}
						variant="outlined"
						onClick={() => navigate('/')}
					/>
				</Box>
				<Box className={styles.btnItem}>
					<CustomButton
						label={'Continuar'}
						variant="solid"
						onClick={createCaso}
						disabled={validEdition()}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default ChooseBranch;
