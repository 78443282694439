import { Dialog, Box, Link, useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../components/Button';
import iconoPDF from '../../assets/img/icono-descarga.png';
import { IStep } from './utils';
import { downloadFileGeneric } from '../../utils/downloadPDF';

interface IProps {
	step: IStep;
	setStep: (arg: IStep) => void;
	setPage: (arg: number) => void;
	handleSendData: () => void;
	resultPDF: string;
	parameters: any;
}

const DownloadFiles = ({
	step,
	setStep,
	setPage,
	handleSendData,
	resultPDF,
	parameters,
}: IProps) => {
	const navigate = useNavigate();
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));

	const nexStep = (arg: number) => {
		if (arg === 0) {
			setStep({
				...step,
				msg: '',
				nextStep: 0,
			});
			setPage(1);
		}

		if (arg === 1) {
			downloadFileGeneric({
				payload: resultPDF,
				name: 'Resumen de movimientos',
				type: parameters.tipoFormato === 1 ? 'PDF' : 'Excel',
			});
			localStorage.removeItem('parameters');
			setStep({
				...step,
				msg: 'Tu documento ha sido descargado',
				nextStep: 2,
			});
		}

		if (arg === 2) {
			setStep({
				...step,
				msg: '',
				nextStep: 3,
			});
		}
	};

	return (
		<Dialog
			open={step.nextStep === 1 || step.nextStep === 4}
			fullWidth
			maxWidth="md"
		>
			<Box
				padding={2}
				width={'100%'}
				maxWidth={800}
				margin={'auto'}
				textAlign={'center'}
			>
				<p style={{ fontWeight: 700, width: '100%', maxWidth: 'max-content', margin: '16px auto' }}>
					Descarga el archivo con los movimientos solicitados
				</p>

				<Link
					onClick={() => nexStep(1)}
					style={{
						display: 'flex',
						margin: '30px auto',
						justifyContent: 'center',
						alignItems: 'center',
						fontWeight: 400,
						width: '100%',
						maxWidth: 'max-content',
					}}
					underline={'none'}
				>
					Descargar el archivo
					<img
						src={iconoPDF}
						alt="pdfDowload"
						style={{ width: 24, marginLeft: 5 }}
					/>
				</Link>

				{step.nextStep > 3 && (
					<p
						style={{
							fontWeight: 400,
							margin: '0px 0px 30px 0px',
							color: '#333333',
							fontSize: 18,
						}}
					>
						El resumen de movimientos proporcionados en el formato
						Excel es únicamente para fines informativos y no será
						válido para ningún trámite o aclaración dentro del
						Infonavit.
					</p>
				)}

				<Box
					display={'flex'}
					justifyContent={'center'}
					alignItems={'center'}
					flexDirection={matches ? 'column-reverse' : 'row'}
					margin={'10px 0px'}
				>
					<Box width={matches ? '100%' : 200} margin={'10px 20px'}>
						{step.nextStep < 4 ? (
							<Link
								//underline="none"
								onClick={() => navigate('/')}
								style={{ fontWeight: 500 }}
							>
								Salir
							</Link>
						) : (
							<CustomButton
								label={'Salir'}
								onClick={() => navigate('/')}
								variant={'outlined'}
								styles={{ height: '40px' }}
							/>
						)}
					</Box>
					<Box width={matches ? '100%' : 200} margin={'10px 20px'}>
						<CustomButton
							label={'Haz otra consulta'}
							onClick={() => nexStep(0)}
							variant={step.nextStep > 3 ? 'solid' : 'outlined'}
							styles={{ height: '40px' }}
						/>
					</Box>

					{step.nextStep < 4 && (
						<Box
							width={matches ? '100%' : 200}
							margin={'10px 20px'}
						>
							<CustomButton
								label={'Descargar Excel'}
								onClick={handleSendData}
								variant="solid"
							/>
						</Box>
					)}
				</Box>
			</Box>
		</Dialog>
	);
};

export default DownloadFiles;
