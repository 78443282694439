export const TABS_REGISTER_VALUES = {
	PERSONAL_DATA: 0,
	VERIFY_NAME: 1,
	CONTACT_DATA: 2,
	PASSWORD: 3,
	ACCOUNT: 4,
	CONFIRMATION: 5,
};
export const TABS_REGISTER = [
	{
		id: TABS_REGISTER_VALUES.PERSONAL_DATA,
		title: '1.- Datos personales',
		disabled: true,
		checked: false,
		columns: 2,
		orderMD: 0,
		orderSM: 0,
	},
	{
		id: TABS_REGISTER_VALUES.VERIFY_NAME,
		title: '2.- Verifica tu nombre',
		disabled: true,
		checked: false,
		columns: 2,
		orderMD: 1,
		orderSM: 2,
	},
	{
		id: TABS_REGISTER_VALUES.CONTACT_DATA,
		title: '3.- Datos de contacto',
		disabled: true,
		checked: false,
		columns: 2,
		orderMD: 2,
		orderSM: 3,
	},
	{
		id: TABS_REGISTER_VALUES.PASSWORD,
		title: '4.- Contraseña',
		disabled: true,
		checked: false,
		columns: 2,
		orderMD: 3,
		orderSM: 4,
	},
	{
		id: TABS_REGISTER_VALUES.ACCOUNT,
		title: '5.- Activación de cuenta',
		disabled: true,
		checked: false,
		columns: 2,
		orderMD: 4,
		orderSM: 5,
	},
	{
		id: TABS_REGISTER_VALUES.CONFIRMATION,
		title: '6.- Confirmación',
		disabled: true,
		checked: false,
		columns: 2,
		orderMD: 5,
		orderSM: 6,
	},
];
