import { useEffect, useState } from 'react';
import CesiQuoteGeneral from './CesiQuoteGeneral';
import CesiQuoteDetail from './CesiQuoteDetail';
import { useEscrituras } from '../hooks/useEscrituras';
import { CESI_QUOTE_STATUS } from './utils/pagesStatus';

interface IProps {
	nextProcess: () => void;
}

const CesiQuote = ({ nextProcess }: IProps) => {
	const [page, setPage] = useState(CESI_QUOTE_STATUS.GENERAL);
	const { escrituras } = useEscrituras();

	useEffect(() => {
		if (escrituras.consultarCita) {
			setPage(CESI_QUOTE_STATUS.DETAIL);
		}
		/* eslint-disable react-hooks/exhaustive-deps */
	}, []);

	return (
		<>
			{page === CESI_QUOTE_STATUS.GENERAL && (
				<CesiQuoteGeneral setPage={setPage} />
			)}
			{page === CESI_QUOTE_STATUS.DETAIL && (
				<CesiQuoteDetail setPage={setPage} nextProcess={nextProcess} />
			)}
		</>
	);
};

export default CesiQuote;
