/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import Layout from '../../components/Layout';
import InfoDevice from './InfoDevice';
import WayToUnlink from './WayToUnlink';
import SendCode from './SendCode';
import CloseCase from './CloseCase';
import NotInfoDevice from './NotInfoDevice';
import ServiceNotAvailable from './ServiceNotAvailable';
import { useStyles } from './InfoDevice/styles';
import ConfirmPassword from './ConfirmPassword';
import { PAGES_UNPAIR_DEVICE } from './utils/pages.utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const UnpairDevice = () => {
	const { fontSizeLevel } = useSelector((state: RootState) => state.session);
	const classes = useStyles();
	const [page, setPage] = useState(PAGES_UNPAIR_DEVICE.CONFIRM_PASSWORD); //Estado para el cambio de vistas
	const [device, setDevice] = useState(0); //Estado para elegir el medio de desvinculación
	const dataRequest = {
		grupo: 'cn=GS_MICUENTA,ou=atencionservicios,ou=areasapoyo,O=INFONAVIT',
		id_cat: 'APL0104',
	};
	const [request, setRequest] = useState({
		datetime_creation: '',
		fechaCreacionTDS: '',
		idDispositivo: '',
	});
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<div>
			<Layout updateTagText={updateTagText}>
				<div ref={divRef}>
					<div className={classes.titleNoticeContainer}>
						<div>
							<h2 className={classes.titleNotice}>
								Desvincula tu dispositivo móvil{' '}
							</h2>
						</div>
					</div>
					{page === PAGES_UNPAIR_DEVICE.CONFIRM_PASSWORD && (
						<ConfirmPassword
							setPage={setPage}
							dataRequest={dataRequest}
							setRequest={setRequest}
						/>
					)}
					{page === PAGES_UNPAIR_DEVICE.INFO_DEVICE && (
						<InfoDevice setPage={setPage} request={request} />
					)}
					{page === PAGES_UNPAIR_DEVICE.WAY_TO_UNLINK && (
						<WayToUnlink setDevice={setDevice} setPage={setPage} />
					)}
					{page === PAGES_UNPAIR_DEVICE.SEND_CODE && (
						<SendCode
							device={device}
							setDevice={setDevice}
							setPage={setPage}
							dataRequest={dataRequest}
						/>
					)}
					{page === PAGES_UNPAIR_DEVICE.CLOSE_CASE && <CloseCase />}
					{page === PAGES_UNPAIR_DEVICE.NOT_INFO_DEVICE && <NotInfoDevice />}
					{page === PAGES_UNPAIR_DEVICE.SERVICE_NOT_AVAILABLE && <ServiceNotAvailable />}
				</div>
			</Layout>
		</div>
	);
};

export default UnpairDevice;
