// RESOURCES
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// COMPONENTS
import CustomButton from '../../../components/Button';
// ASSETS
import checkBlue from '../../../assets/img/mini-check-azul.png';
import { useStyles } from './styles';
import { IAlert } from '../../../interfaces/alert';
import CustomAlert from '../../../components/CustomAlert';
import { ISesionData } from '../utils';
import { apiCancelledPayments } from '../../../api/modules/CancelledPayments';
// import { returnOverpaymentsAPI } from '../../../api/modules/returnOverpayments';
// import { encrypt } from '../../../utils/encrypt';
// import { useSelector } from 'react-redux';
// import { RootState } from '../../../store';

interface Props {
	setPage: (data: number) => void;
	setLoading: (arg: boolean) => void;
	setCaso: (arg: boolean) => void;
	setSession: (arg: ISesionData) => void;
}

const InfoPayments = ({ setPage, setLoading, setCaso, setSession }: Props) => {
	//const { user } = useSelector((state: RootState) => state.session);
	const classes = useStyles();
	const navigate = useNavigate();
	const [buttonAction, setButtonAction] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});

	useEffect(() => {
		consultCases();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const consultCases = async () => {
		try {
			setLoading(true);

			// --------------- Se comenta temporalmente esta validacion del curp

			//setCaso(false);
			// const curp_base64 = await encrypt(user?.curp || '', user?.k || '');
			// const responseTotal = await returnOverpaymentsAPI.validateCURP(
			// 	curp_base64
			// );
			//setSession(responseTotal.result);
			// if (responseTotal.result.descripcion !== 'activo') {
			// 	setAlert({
			// 		show: true,
			// 		message:
			// 			responseTotal.result.descripcion ||
			// 			responseTotal.result.description ||
			// 			'Por el momento el servicio no está disponible. Intenta más tarde.',
			// 		severity: 'error',
			// 	});
			// 	return;
			// }

			// --------------- fin de lo comentado

			const responseCaso = await apiCancelledPayments.getCaso();
			const { code, data, message } = responseCaso.result;
			if (code === '0001') {
				return;
			}
			if (code === '0000') {
				if (Object.keys(data || {}).length > 0) {
					if ((data?.estatus || '') !== 'E0005') {
						//setCaso(true);
						setButtonAction(true);
						setPage(3);
					}
				}
				return;
			}
			setButtonAction(true);
			setAlert({
				show: true,
				message:
					message ||
					'Por el momento el servicio no está disponible. Intenta más tarde.',
				severity: 'error',
			});
		} catch (error) {
			setButtonAction(true);
			setAlert({
				show: true,
				message:
					'Por el momento el servicio no está disponible. Intenta más tarde.',
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<div className={classes.container}>
				<div className={classes.containerMin}>
					<CustomAlert
						message={alert.message}
						severity={alert.severity}
						show={alert.show}
					/>

					{!alert.show && (
						<>
							<div className={classes.checkHeader}>
								<div className={classes.checkHeaderText}>
									<p className={classes.noMargin}>
										Si cancelaste tu trámite de crédito antes de
										la firma de escrituras y tu patrón realizó
										descuentos para el pago del crédito, puedes
										solicitar la devolución de los pagos
										realizados.
									</p>
								</div>
							</div>
							<div className={classes.checkHeader}>
								<div className={classes.checkHeaderText}>
									<p className={classes.noMargin}>
										El monto a devolver corresponde a la
										amortización del crédito, el 5% que tu
										patrón aporta, se restituirá a tu Saldo de
										Subcuenta de Vivienda.
									</p>
								</div>
							</div>
							<div className={classes.checkHeader}>
								<div className={classes.checkHeaderText}>
									<p className={classes.noMargin}>
										En este servicio puedes consultar si tenemos
										identificados pagos y de ser así, podrás
										solicitar la devolución.
									</p>
								</div>
							</div>
							<div className={classes.checkHeader}>
								<div className={classes.checkHeaderText}>
									<p className={classes.noMargin}>
										Para realizar el trámite es necesario que
										cuentes con:
									</p>
								</div>
							</div>
							<div
								className={classes.checkHeader}
								style={{ marginTop: '15px' }}
							>
								<img
									src={checkBlue}
									className={classes.checkHeaderImg}
									alt="ícono de check"
								/>
								<p className={classes.checkHeaderText}>
									Firma electrónica (e.firma) vigente para
									poder consultar el monto disponible y para
									solicitar la devolución. En caso de estar
									vencida, acude al SAT para su renovación.
								</p>
							</div>
							<div className={classes.checkHeader}>
								<img
									src={checkBlue}
									className={classes.checkHeaderImg}
									alt="ícono de check"
								/>
								<p className={classes.checkHeaderText}>
									Estado de cuenta bancario a tu nombre con
									una vigencia no mayor a 3 meses, con CLABE
									interbancaria a 18 dígitos.
								</p>
							</div>
							<div className={classes.contentFileDataButtons}>
								<CustomButton
									onClick={() => {
										navigate('/inicio');
									}}
									variant="outlined"
									styles={{
										marginTop: 25,
										width: 200,
										marginInline: 10,
									}}
									label="Salir"
								/>
								<CustomButton
									onClick={() => {
										setPage(1);
									}}
									variant="solid"
									disabled={buttonAction}
									styles={{
										marginTop: 25,
										width: 200,
										marginInline: 10,
									}}
									label="Consultar"
								/>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default InfoPayments;
