import { useState, useEffect } from 'react';
import { Box, Paper, useMediaQuery } from '@mui/material';
import CustomButton from '../../components/Button';
import DownloadFiles from './DownloadFiles';
import FinishDownload from './FinishDownload';
import theme from '../../config/theme';
import { useNavigate } from 'react-router';
import { IStep } from './utils';
import { summarySavingsAPI } from '../../api/modules/summarySavings';
import { IAlert } from '../../interfaces/alert';
import ModalLoading from '../../components/ModalLoading/index';
import { createObjectEncript } from '../../utils/encrypt';
import { useStyles } from './styles';

interface IProps {
	setPage: (arg: number) => void;
}

const ApplicationProcess = ({ setPage }: IProps) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [loading, setLoading] = useState(false);
	const [step, setStep] = useState<IStep>({
		msg: '',
		nextStep: 0,
	});
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [parameters, setParameters] = useState({
		docTodo: 0,
		docViv97Todo: 0,
		subCatA: 0,
		subCatB: 0,
		subCatC: 0,
		docViv92: 0,
		docFa: 0,
		tipoFormato: 1,
	});
	const [resultPDF, setResultPDF] = useState('');

	useEffect(() => {
		const savedParameters = localStorage.getItem('parameters');
		const parametersParsed = savedParameters
			? JSON.parse(savedParameters)
			: null;

		setParameters(parametersParsed);
		if (parametersParsed && parametersParsed?.tipoFormato && parametersParsed?.tipoFormato === 2) {
			setStep({
				...step,
				nextStep: 3,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = () => {
		if (step.nextStep > 2) {
			setStep({
				...step,
				nextStep: 4,
			});
			setParameters({
				...parameters,
				tipoFormato: 2,
			});
			return;
		}

		setStep({
			...step,
			nextStep: 1,
		});
	};

	const handleVerifyFile = async () => {
		try {
			const { result } = await summarySavingsAPI.verifyFile();
			switch (result.codigo) {
				case '00':
					//Se descarga el pdf, pero lo guardamos en un estado resultPDF
					setLoading(false);
					setResultPDF(result.pdf);
					handleChange();
					break;
				case '01':
					setLoading(false);
					//No existe PDF asociado a NSS
					setAlert({
						show: true,
						message: result.description,
						severity: 'error',
					});
					break;
				case '02':
					setLoading(false);
					//No se encontró solicitud de registro asociado a NSS
					setAlert({
						show: true,
						message: result.description,
						severity: 'error',
					});
					break;
				case '03':
					setLoading(false);
					//Error desconocido en la consulta al servicio Orquestador SACI
					setAlert({
						show: true,
						message: result.description,
						severity: 'error',
					});
					break;
				default:
					setLoading(false);
					break;
			}
		} catch (error) {
			setLoading(false);
			setAlert({
				show: true,
				message:
					'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'error',
			});
		}
	};

	const handleVerifyStatus = async () => {
		try {
			setLoading(true);
			const { result } = await summarySavingsAPI.validStatus();
			switch (result.codigo) {
				case '02':
					//Error el servicio no se encuentra disponible
					setAlert({
						show: true,
						message: result.description,
						severity: 'error',
					});
					setLoading(false);
					break;
				case '03':
					//Esta en proceso
					setAlert({
						show: true,
						message: result.description,
						severity: 'success',
					});
					setPage(2);
					setLoading(false);
					break;
				case '04':
					//Ya se cumplio el proceso pero aún no esta listo el archivo
					//ajuste para revert
					setAlert({
						show: true,
						message: result.description,
						severity: 'success',
					});
					setPage(2);
					setLoading(false);
					break;
				case '05':
					handleVerifyFile();
					break;
				default:
					setLoading(false);
					break;
			}
		} catch (error) {
			setLoading(false);
			setAlert({
				show: true,
				message:
					'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'error',
			});
		}
	};

	const handleSendData = async () => {
		try {
			setLoading(true);
			const data = {
				...parameters,
				tipoFormato: 2,
			};
			localStorage.setItem('parameters', JSON.stringify(data)); //guardar datos en localstorge
			const dataEncripted = await createObjectEncript(data);

			const { result } = await summarySavingsAPI.fileRequired(
				dataEncripted
			);

			if (result.codigo === '01') {
				//Solicitud de Ticket enviada exitosa
				setStep({
					...step,
					msg: '',
					nextStep: 3,
				});
				return;
			}
			setAlert({
				show: true,
				message: result.description,
				severity: 'error',
			});
		} catch (error) {
			setAlert({
				show: true,
				message:
					'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			{/* Modal de desiciones para descargar , nextStep = 1 */}
			<DownloadFiles
				step={step}
				setStep={setStep}
				setPage={setPage}
				handleSendData={handleSendData}
				resultPDF={resultPDF}
				parameters={parameters}
			/>

			{/* Modal de finalizacion de descarga , nextStep = 2 */}
			<FinishDownload step={step} setStep={setStep} />

			<Paper>
				<Box
					padding={2}
					maxWidth={1000}
					textAlign={'center'}
					margin={'auto'}
				>
					<p
						style={{
							fontWeight: 500,
							margin: '20px auto 30px auto',
							color: '#333333',
						}}
						className={classes.maxContent}
					>
						Tu solicitud está siendo procesada.
					</p>

					<p
						style={{
							fontWeight: 400,
							margin: '0px 0px 30px 0px',
							color: '#333333',
							fontSize: 18,
						}}
					>
						El trámite puede demorar hasta 5 minutos dependiendo de
						la cantidad de información solicitada, da clic en el
						botón continuar para visualizar el documento en formato{' '}
						{step.nextStep > 2 ? 'Excel' : 'PDF'}.
					</p>

					{/* <CustomAlert
						message={alert.message}
						severity={alert.severity}
						show={alert.show}
					/> */}

					<Box
						display={'flex'}
						flexDirection={matches ? 'column-reverse' : 'row'}
						justifyContent={'center'}
					>
						<Box width={260} margin={matches ? '10px auto' : '20px auto'}>
							<CustomButton
								label={'Salir'}
								onClick={() => navigate('/')}
								variant={'outlined'}
								styles={{ height: '40px' }}
							/>
						</Box>
						<Box width={260} margin={matches ? '10px auto' : '20px auto'}>
							<CustomButton
								label={'Continuar'}
								onClick={handleVerifyStatus}
								variant={'solid'}
							/>
						</Box>
					</Box>

					<p style={{ color: '#283990', fontSize: 14 }}>
						Si tienes problemas para descargar el archivo, llama a
						Infonatel al <b>55 9171 5050</b> en la ciudad de México
						y al <b>800 008 3900</b> desde cualquier parte del pais.
					</p>
				</Box>
			</Paper>

			<ModalLoading loading={loading} />
		</>
	);
};

export default ApplicationProcess;
