// Dependencies
import { useState } from 'react';

// Material Components
import { Box } from '@mui/system';
import CustomButton from '../../components/Button';

// Components
import TextInputPassword from '../../components/TextInputPassword';
import CustomAlert from '../../components/CustomAlert';

// Assets
import { useStyles } from './styles';

// Intefaces
import { IEventTab } from '../../interfaces/eventTab';
import { IInputPasswordResult } from '../../interfaces/inputPassword';

const ContraseñaView = (ActionsButtons: IEventTab) => {
	const [Password, setPassword] = useState({} as IInputPasswordResult);
	const [errorInputPassword, setErrorInputPassword] = useState(false);

	const onChange = (value: IInputPasswordResult) => {
		setPassword(value);
	};

	const onContinuar = () => {
		ActionsButtons.onValues({ name: 'Password', value: Password.password });
		ActionsButtons.onNextTab();
	};

	const styles = useStyles();
	return (
		<>
			<Box className={styles.container}>
				<Box>
					<TextInputPassword
						labelStyles={{}}
						value={Password.password}
						onChange={onChange}
						isRequired
						confirmPassword
						setErrorInputPassword={setErrorInputPassword}
						errorInputPassword={errorInputPassword}
					/>

					<Box className={styles.divboton}>
						<CustomAlert
							message={ActionsButtons.onAlert.message}
							severity={ActionsButtons.onAlert.severity}
							show={ActionsButtons.onAlert.show}
							onClose={() => {
								ActionsButtons.onClearAlert('Password');
							}}
						/>
					</Box>

					<div className={styles.divBotones}>
						<div className={styles.divBotonFlex}>
							<CustomButton
								onClick={onContinuar}
								variant="solid"
								styles={{ margin: 5 }}
								label="Continuar"
								disabled={!Password.valid || errorInputPassword}
							/>
						</div>
					</div>
				</Box>
			</Box>
		</>
	);
};

export default ContraseñaView;
