import { Box, Divider, Grid, Paper, useMediaQuery } from '@mui/material';
import React from 'react';
import { HeaderList } from '../../components/HeaderList';
import Layout from '../../components/Layout';
import oficinas from '../../assets/img/oficinas.png';
import portal from '../../assets/img/portal.png';
import infonatel from '../../assets/img/infonatel.png';
import kioscos from '../../assets/img/kioscos.png';
import appMovil from '../../assets/img/app-movil.png';
import celular from '../../assets/img/celular.png';
import correo from '../../assets/img/correo.png';
import infomovil from '../../assets/img/infomovil.png';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const ServiceChannels = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const navigate = useNavigate();
	const describeText = [
		'Si necesitas saber sobre el Instituto, los trámites de tu crédito o tu ahorro, el Infonavit pone a tu disposición diversos canales de comunicación que le ayudarán a resolver de manera clara y sencilla todas tus dudas y necesidades de información, elige el que más se adapte a tus intereses.',
	];
	return (
		<Layout>
			<Box>
				<HeaderList
					title="Canales de servicio"
					date="12 Julio 2021"
					list={describeText}
				/>
				<Paper
					style={{
						paddingTop: matches ? 25 : 50,
						paddingBottom: 10,
					}}
				>
					<Grid container columns={12}>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{ pr: matches ? 3 : 15, pl: matches ? 1 : 15, pb: 1 }}
							style={{ display: 'flex', flexDirection: 'row' }}
						>
							<div style={{ display: matches ? 'none' : 'inline-block' }}>
								<img
									src={oficinas}
									style={{ width: matches ? 35 : 64 }}
									alt="ícono Oficinas de Atención"
								/>
							</div>
							<div style={{ marginLeft: 15 }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ display: matches ? 'flex' : 'none' }}>
										<img
											src={oficinas}
											style={{ width: matches ? 35 : 64 }}
											alt="ícono Oficinas de Atención"
										/>
									</div>
									<h3
										style={{
											fontSize: 16,
											color: '#293990',
											textAlign: 'left',
											margin: 0,
											marginLeft: matches ? 10 : 0,
										}}
									>
										Oficinas de Atención
									</h3>
								</div>
								<div
									style={{
										fontSize: matches ? 15 : 16,
										textAlign: 'left',
										marginTop: -6,
									}}>
									<p>
										Durante la contingencia por el COVID19, la atención y
										servicio al público en los Centros de Servicio
										Infonavit de los estados que permanezcan en semáforo
										naranja o rojo será de 8:30 a 13:00 horas. En el caso
										de los estados que pasen a semáforo amarillo, el
										horario se extiende de 8:30 a 14:30 horas.
									</p>
									<p>
										Estamos en todo el país para brindarte una atención
										personalizada. Te recomendamos que hagas una cita para
										atenderte más rápido.
									</p>
								</div>
								<p
									style={{
										width: '100%',
										maxWidth: 'max-content',
										fontSize: 16,
										color: '#D1001F',
										textAlign: 'left',
										cursor: 'pointer',
									}}
									onClick={() =>
										navigate('/contactanos/oficinas-atencion')
									}
								>
									Ubicar oficinas de atención
								</p>
							</div>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{ pl: matches ? 2 : 5, pr: matches ? 2 : 5 }}
						>
							<Divider />
						</Grid>
					</Grid>
					<Grid container columns={12}>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{
								pr: matches ? 3 : 15,
								pl: matches ? 1 : 15,
								pb: 3,
								pt: matches ? 2 : 3,
							}}
							style={{ display: 'flex', flexDirection: 'row' }}
						>
							<div style={{ display: matches ? 'none' : 'inline-block' }}>
								<img
									src={portal}
									style={{ width: matches ? 35 : 64 }}
									alt="ícono Portal"
								/>
							</div>
							<div style={{ marginLeft: 15 }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ display: matches ? 'flex' : 'none' }}>
										<img
											src={portal}
											style={{ width: matches ? 35 : 64 }}
											alt="ícono Portal"
										/>
									</div>
									<h3
										style={{
											fontSize: 16,
											color: '#293990',
											textAlign: 'left',
											margin: 0,
											marginLeft: matches ? 10 : 0,
										}}
									>
										Portal
									</h3>
								</div>
								<p
									style={{
										fontSize: matches ? 15 : 16,
										textAlign: 'left',
										marginTop: 10,
									}}
								>
									Haz consultas y trámites en línea los 365 días del año
									desde cualquier lugar, lo único que necesitas es una
									computadora conectada a Internet.
								</p>
								<a
									target="_blank"
									href="https://portalmx.infonavit.org.mx/wps/portal/infonavit.web/trabajadores"
									style={{
										fontSize: matches ? 15 : 16,
										color: '#D1001F',
										textAlign: 'left',
										textDecoration: 'red',
									}}
									rel="noreferrer"
								>
									Consultar
								</a>
							</div>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{ pl: matches ? 2 : 5, pr: matches ? 2 : 5 }}
						>
							<Divider />
						</Grid>
					</Grid>
					<Grid container columns={12}>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{
								pr: matches ? 3 : 15,
								pl: matches ? 1 : 15,
								pb: 3,
								pt: matches ? 2 : 3,
							}}
							style={{ display: 'flex', flexDirection: 'row' }}
						>
							<div style={{ display: matches ? 'none' : 'inline-block' }}>
								<img
									src={infonatel}
									style={{ width: matches ? 35 : 64 }}
									alt="ícono Infonatel"
								/>
							</div>
							<div style={{ marginLeft: 15 }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ display: matches ? 'flex' : 'none' }}>
										<img
											src={infonatel}
											style={{ width: matches ? 35 : 64 }}
											alt="ícono Infonatel"
										/>
									</div>
									<h3
										style={{
											fontSize: 16,
											color: '#293990',
											textAlign: 'left',
											margin: 0,
											marginLeft: matches ? 10 : 0,
										}}
									>
										Infonatel
									</h3>
								</div>
								<div style={{
									fontSize: matches ? 15 : 16,
									textAlign: 'left',
									marginTop: -6,
								}}>
									<p>
										La atención telefónica personalizada por medio de un
										asesor telefónico, está disponible de lunes a viernes
										de 7:30 a 21:00 horas, sábados, domingos y días
										festivos de 9:00 15:00 horas.
									</p>
									<p>
										La atención automatizada por medio del sistema
										automático de respuesta está disponible las 24 horas
										los 365 días del año.
									</p>
								</div>

								<label style={{ fontSize: matches ? 15 : 16 }}>
									<span>
										Ciudad de México{' '}
									</span>
									<a
										href="tel:5591715050"
										style={{
											fontSize: matches ? 15 : 16,
											color: '#D1001F',
											textAlign: 'left',
											textDecoration: 'red',
										}}
									>
										55 9171 5050
									</a>
								</label>
								<br />
								<label style={{ fontSize: matches ? 15 : 16 }}>
									<span>
										Desde cualquier parte del país{' '}
									</span>
									<a
										href="tel:8000083900"
										style={{
											fontSize: matches ? 15 : 16,
											color: '#D1001F',
											textAlign: 'left',
											textDecoration: 'red',
										}}
									>
										800 008 3900
									</a>
								</label>
							</div>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{ pl: matches ? 2 : 5, pr: matches ? 2 : 5 }}
						>
							<Divider />
						</Grid>
					</Grid>
					<Grid container columns={12}>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{
								pr: matches ? 3 : 15,
								pl: matches ? 1 : 15,
								pb: 3,
								pt: matches ? 2 : 3,
							}}
							style={{ display: 'flex', flexDirection: 'row' }}
						>
							<div style={{ display: matches ? 'none' : 'inline-block' }}>
								<img
									src={kioscos}
									style={{ width: matches ? 35 : 64 }}
									alt="ícono Kioscos"
								/>
							</div>
							<div style={{ marginLeft: 15 }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ display: matches ? 'flex' : 'none' }}>
										<img
											src={kioscos}
											style={{ width: matches ? 35 : 64 }}
											alt="ícono Kioscos"
										/>
									</div>
									<h3
										style={{
											fontSize: 16,
											color: '#293990',
											textAlign: 'left',
											margin: 0,
											marginLeft: matches ? 10 : 0,
										}}
									>
										Kioscos
									</h3>
								</div>
								<p
									style={{
										fontSize: matches ? 15 : 16,
										textAlign: 'left',
										marginTop: 10,
									}}
								>
									En estos módulos digitales puedes consultar e imprimir
									fácilmente la información de tu ahorro, tu Resumen de
									Movimientos y el saldo de tu crédito, entre otros
									servicios.
								</p>

								<a
									target="_blank"
									href="https://portalmx.infonavit.org.mx/wps/wcm/connect/9a6191fb-1aa9-4ffc-892b-b1e963f1cb76/Directorio_de_Kioscos_externos.pdf?MOD=AJPERES&CVID=mXXJggF"
									style={{
										fontSize: matches ? 15 : 16,
										color: '#D1001F',
										textAlign: 'left',
										textDecoration: 'red',
									}}
									rel="noreferrer"
								>
									Ubicar kioscos externos
								</a>
							</div>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{ pl: matches ? 2 : 5, pr: matches ? 2 : 5 }}
						>
							<Divider />
						</Grid>
					</Grid>
					<Grid container columns={12}>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{
								pr: matches ? 3 : 15,
								pl: matches ? 1 : 15,
								pb: 1,
								pt: matches ? 2 : 3,
							}}
							style={{ display: 'flex', flexDirection: 'row' }}
						>
							<div style={{ display: matches ? 'none' : 'inline-block' }}>
								<img
									src={appMovil}
									style={{ width: matches ? 35 : 64 }}
									alt="ícono Aplicación móvil"
								/>
							</div>
							<div style={{ marginLeft: 15 }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ display: matches ? 'flex' : 'none' }}>
										<img
											src={appMovil}
											style={{ width: matches ? 35 : 64 }}
											alt="ícono Aplicación móvil"
										/>
									</div>
									<h3
										style={{
											fontSize: 16,
											color: '#293990',
											textAlign: 'left',
											margin: 0,
											marginLeft: matches ? 10 : 0,
										}}
									>
										Aplicación móvil
									</h3>
								</div>
								<p
									style={{
										fontSize: matches ? 15 : 16,
										textAlign: 'left',
										marginTop: 10,
									}}
								>
									Consulta información sobre nuestros servicios desde tu
									teléfono celular, tenemos disponible una versión móvil
									de este portal y una aplicación con información
									práctica.
								</p>
							</div>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{ pl: matches ? 2 : 5, pr: matches ? 2 : 5 }}
						>
							<Divider />
						</Grid>
					</Grid>
					<Grid container columns={12}>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{
								pr: matches ? 3 : 15,
								pl: matches ? 1 : 15,
								pb: 3,
								pt: matches ? 2 : 3,
							}}
							style={{ display: 'flex', flexDirection: 'row' }}
						>
							<div style={{ display: matches ? 'none' : 'inline-block' }}>
								<img
									src={celular}
									style={{ width: matches ? 35 : 64 }}
									alt="ícono Mensajes en tu celular"
								/>
							</div>
							<div style={{ marginLeft: 15 }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ display: matches ? 'flex' : 'none' }}>
										<img
											src={celular}
											style={{ width: matches ? 35 : 64 }}
											alt="ícono Mensajes en tu celular"
										/>
									</div>
									<h3
										style={{
											fontSize: 16,
											color: '#293990',
											textAlign: 'left',
											margin: 0,
											marginLeft: matches ? 10 : 0,
										}}
									>
										Mensajes en tu celular
									</h3>
								</div>
								<p
									style={{
										fontSize: matches ? 15 : 16,
										textAlign: 'left',
										marginTop: 10,
									}}
								>
									Si quieres recibir alertas e información del Infonavit
									envía al 30900 tu Número de Seguridad Social (NSS) o
									tu Número de Crédito.
								</p>
								<a
									target="_blank"
									href="https://portalmx.infonavit.org.mx/wps/portal/infonavit.web/contactanos/mensaje-celular/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8zizdwNDDycTQz93L0MjAwC_Z1CAkKNnIzd_Yz1w8EKjCwMDNydDIAKLDwsDBzdXD1dLX2MjdxDDfWjiNFvgAM4GhCnH4-CKPzGh-tH4bPCwNgMqgCfFwlZUpAbGhphkOkJADfbe5Q!/dz/d5/L2dBISEvZ0FBIS9nQSEh/"
									style={{
										fontSize: matches ? 15 : 16,
										color: '#D1001F',
										textAlign: 'left',
										textDecoration: 'red',
									}}
									rel="noreferrer"
								>
									Conocer los servicios
								</a>
							</div>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{ pl: matches ? 2 : 5, pr: matches ? 2 : 5 }}
						>
							<Divider />
						</Grid>
					</Grid>
					<Grid container columns={12}>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{
								pr: matches ? 3 : 15,
								pl: matches ? 1 : 15,
								pb: 1,
								pt: matches ? 2 : 3,
							}}
							style={{ display: 'flex', flexDirection: 'row' }}
						>
							<div style={{ display: matches ? 'none' : 'inline-block' }}>
								<img
									src={correo}
									style={{ width: matches ? 35 : 64 }}
									alt="ícono Mensajes en tu correo electrónico"
								/>
							</div>
							<div style={{ marginLeft: 15 }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ display: matches ? 'flex' : 'none' }}>
										<img
											src={correo}
											style={{ width: matches ? 35 : 64 }}
											alt="ícono Mensajes en tu correo electrónico"
										/>
									</div>
									<h3
										style={{
											fontSize: 16,
											color: '#293990',
											textAlign: 'left',
											margin: 0,
											marginLeft: matches ? 10 : 0,
										}}
									>
										Mensajes en tu correo electrónico
									</h3>
								</div>
								<p
									style={{
										fontSize: matches ? 15 : 16,
										textAlign: 'left',
										marginTop: 10,
									}}
								>
									Registra o actualiza tus datos y recibe en tu correo
									electrónico información de tu crédito, tu estado de
									cuenta y el boletín Saber para decidir.
								</p>
							</div>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{ pl: matches ? 2 : 5, pr: matches ? 2 : 5 }}
						>
							<Divider />
						</Grid>
					</Grid>
					<Grid container columns={12}>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							sx={{
								pr: matches ? 3 : 15,
								pl: matches ? 1 : 15,
								pb: 1,
								pt: matches ? 2 : 3,
							}}
							style={{ display: 'flex', flexDirection: 'row' }}
						>
							<div style={{ display: matches ? 'none' : 'inline-block' }}>
								<img
									src={infomovil}
									style={{ width: matches ? 35 : 64 }}
									alt="ícono Infomóvil"
								/>
							</div>
							<div style={{ marginLeft: 15 }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ display: matches ? 'flex' : 'none' }}>
										<img
											src={infomovil}
											style={{ width: matches ? 35 : 64 }}
											alt="ícono Infomóvil"
										/>
									</div>
									<h3
										style={{
											fontSize: 16,
											color: '#293990',
											textAlign: 'left',
											margin: 0,
											marginLeft: matches ? 10 : 0,
										}}
									>
										Infomóvil
									</h3>
								</div>
								<div style={{
									fontSize: matches ? 15 : 16,
									textAlign: 'left',
									marginTop: -6,
								}}>
									<p>
										Contamos con camionetas y autos equipados con sistemas
										de atención integral para llevar al Infonavit hasta tu
										comunidad.
									</p>
									<p>
										Próximamente te informaremos cuándo y dónde
										estarán los Infomóvil...
									</p>
								</div>
							</div>
						</Grid>
					</Grid>
				</Paper>
			</Box>
		</Layout>
	);
};

export default ServiceChannels;
