/* eslint-disable react-hooks/exhaustive-deps */
// DEPENDENCIES
import { useCallback, useEffect, useState } from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Chip,
	Link,
	Paper,
} from '@mui/material';
import { useSelector } from 'react-redux';
// VIEWS
import ContactAltEmail from './ContactAltEmail';
import ContactEmail from './ContactEmail';
import ContactMobile from './ContactMobile';
// COMPONENTS
import ModalLoading from '../../../../components/ModalLoading';
import { INTERNAL_MESSAGES } from '../../../../config/messageCatalog';
import CustomAlert from '../../../../components/CustomAlert';
// RESOURCES
import { useStyles } from '../styles';
import { defaultContactInfo, IAlert } from '../utils/utils';
import { encrypt } from '../../../../utils/encrypt';
// ASSETS
import { RootState } from '../../../../store';
import { contactRequestAPI } from '../../../../api/modules/profile';
import { keyAPI } from '../../../../api/modules/key';
import { useBitacora } from '../../../../hooks/useBitacora';
import { CONTACT_ACTIONS } from '../../../../config/actions-bitacora/Contact-actions';

const ContactInformation = () => {
	const { user } = useSelector((state: RootState) => state.session);
	const classes = useStyles();
	const [loader, setLoader] = useState(true);
	const [expanded, setExpanded] = useState('');
	const [contactInfo, setContactInfo] = useState(defaultContactInfo);
	const [alert, setAlert] = useState<IAlert>({ message: '', color: 'error' });

	const { setDataBitacora } = useBitacora();

	const fetchContactData = useCallback(async () => {
		try {
			setLoader(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const USER_NSS_BASE64 = await encrypt(
				user?.nss?.toString() || '',
				key
			);
			contactRequestAPI.fetchContactInfo
				.post<{ nss: string }, any>('', {
					nss: USER_NSS_BASE64,
				})
				.then((res: any) => {
					setAlert({ message: '', color: 'error' });
					if (res?.result) {
						setContactInfo(res?.result);
						setDataBitacora({
							[CONTACT_ACTIONS.UPDATE_EMAIL]: {
								valorAnterior: {
									email: res?.result?.email,
								},
							},
							[CONTACT_ACTIONS.UPDATE_EMAIL_ALTER]: {
								valorAnterior: {
									email: res?.result?.emailAlternativo,
								},
							},
							[CONTACT_ACTIONS.UPDATE_TELEPHONE]: {
								valorAnterior: {
									celular: res?.result?.phoneNumber,
								},
							},
						});
					} else {
						setAlert({
							message: INTERNAL_MESSAGES.ERROR_MESSAGE,
							color: 'error',
						});
					}

				})
				.catch((error) => {
					setAlert({
						message:
							error?.description ||
							INTERNAL_MESSAGES.ERROR_MESSAGE,
						color: 'error',
					});
				})
				.finally(() => setLoader(false));
		} catch (error: any) {
			setAlert({
				message: error?.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				color: 'error',
			});
			setLoader(false);
		}
	}, [user?.nss]);

	useEffect(() => {
		fetchContactData();
	}, [fetchContactData]);

	const handleChange = (panel: string, isExpanded: boolean) => {
		if (!Boolean(alert.message)) {
			setExpanded(isExpanded ? panel : '');
		}
	};

	return (
		<>
			<ModalLoading loading={loader} />
			<Paper>
				<Box sx={{ padding: 1 }}>
					<Accordion
						expanded={expanded === 'panel1'}
						elevation={0}
						classes={{ root: classes.expandedContainer }}
					>
						<AccordionSummary>
							<Box className={classes.userInfoContainer}>
								<label className={classes.tituloAcordion}>
									Correo electrónico
								</label>
								{expanded !== 'panel1' && (
									<span className='tag-to-read' aria-label={contactInfo?.email}>{contactInfo?.email}</span>
								)}
								<Box style={{ marginRight: 15 }}>
									<Chip
										size="small"
										color={
											contactInfo.verificationEmail
												? 'success'
												: 'warning'
										}
										label={
											contactInfo.verificationEmail
												? <label>Confirmado</label>
												: <label>Pendiente</label>
										}
									/>
								</Box>
							</Box>
							{!Boolean(alert.message) &&
								contactInfo.enableUpdateEmail && (
									<Link
										component="button"
										variant="body2"
										onClick={() =>
											handleChange(
												'panel1',
												expanded === 'panel1'
													? false
													: true
											)
										}
									>
										Editar
									</Link>
								)}
						</AccordionSummary>
						<AccordionDetails
							className={classes.accordionDetailContainer}
						>
							<ContactEmail fetchContactData={fetchContactData} cancel={() => setExpanded('')} />
						</AccordionDetails>
					</Accordion>

					<Accordion
						expanded={expanded === 'panel2'}
						elevation={0}
						// onChange={(event, isExpanded) =>
						// 	handleChange('panel2', isExpanded)
						// }
						classes={{ root: classes.expandedContainer }}
					>
						<AccordionSummary>
							<Box className={classes.userInfoContainer}>
								<label className={classes.tituloAcordion}>
									Correo Alterno
								</label>
								{expanded !== 'panel2' && (
									<span className='tag-to-read' aria-label={contactInfo?.emailAlternativo}>{contactInfo?.emailAlternativo}</span>
								)}
								<Box style={{ marginRight: 15 }}>
									<Chip
										size="small"
										color={
											contactInfo.verificationEmailAlternate
												? 'success'
												: 'warning'
										}
										label={
											contactInfo.verificationEmailAlternate
												? <label>Confirmado</label>
												: <label>Pendiente</label>
										}
									/>
								</Box>
							</Box>
							{!Boolean(alert.message) &&
								contactInfo.enableUpdateEmail && (
									<Link
										component="button"
										variant="body2"
										onClick={() =>
											handleChange(
												'panel2',
												expanded === 'panel2'
													? false
													: true
											)
										}
									>
										Editar
									</Link>
								)}
						</AccordionSummary>

						<AccordionDetails
							className={classes.accordionDetailContainer}
						>
							<ContactAltEmail
								fetchContactData={fetchContactData}
								cancel={() => setExpanded('')}
							/>
						</AccordionDetails>
					</Accordion>

					<Accordion
						expanded={expanded === 'panel3'}
						elevation={0}
						// onChange={(event, isExpanded) =>
						// 	handleChange('panel3', isExpanded)
						// }
						classes={{ root: classes.expandedContainer }}
					>
						<AccordionSummary>
							<Box className={classes.userInfoContainer}>
								<label className={classes.tituloAcordion}>
									Teléfono celular
								</label>

								{expanded !== 'panel3' && (
									<span className='tag-to-read' aria-label={String(contactInfo?.phoneNumber || '').replaceAll('', ' ')}>{contactInfo?.phoneNumber || ''}</span>
								)}
								<Box style={{ marginRight: 15 }}>
									<Chip
										size="small"
										color={
											contactInfo.verificationSms
												? 'success'
												: 'warning'
										}
										label={
											contactInfo.verificationSms
												? <label>Confirmado</label>
												: <label>Pendiente</label>
										}
									/>
								</Box>
							</Box>
							{!Boolean(alert.message) &&
								contactInfo.enableUpdatePhone && (
									<Link
										component="button"
										variant="body2"
										onClick={() =>
											handleChange(
												'panel3',
												expanded === 'panel3'
													? false
													: true
											)
										}
									>
										Editar
									</Link>
								)}
						</AccordionSummary>
						<AccordionDetails
							className={classes.accordionDetailContainer}
						>
							<ContactMobile
								fetchContactData={fetchContactData}
								cancel={() => setExpanded('')}
							/>
						</AccordionDetails>
					</Accordion>

					<CustomAlert
						show={Boolean(alert.message)}
						severity={alert.color}
						message={alert.message}
					/>
					<CustomAlert
						show={!loader && (!contactInfo.enableUpdateEmail && !contactInfo.enableUpdatePhone)}
						severity={'error'}
						message={'Servicio no disponible temporalmente'}
					/>
				</Box>
			</Paper>
		</>
	);
};

export default ContactInformation;
