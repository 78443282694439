import { Box, Paper } from '@mui/material';
import CustomAlert from '../../components/CustomAlert';
import CustomButton from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';

const ErrorPage = () => {
	const navigate = useNavigate();

	return (
		<Paper>
			<Box padding={2} textAlign={'center'} margin={'auto'}>
				<CustomAlert message={INTERNAL_MESSAGES.ERROR_MESSAGE} severity={'error'} show />
				<Box display={'block'} width={260} margin={'20px auto'}>
					<CustomButton
						label={'Salir'}
						onClick={() => navigate('/')}
					/>
				</Box>
			</Box>
		</Paper>
	);
};

export default ErrorPage;
