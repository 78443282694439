import { AxiosRequestHeaders } from 'axios';
import { cleanStringRoute } from '../utils/strings';

export interface ITypeBitacora {
	TYPE: string;
	EVENT: string;
	ACTION: string;
	ID_EVENT: string;
	ID_TYPE: string;
}

export interface IBitacora {
	[key: string]: ITypeBitacora;
}

export const BITACORA_ACTION = {
	LECTURA: '1',
	ACTUALIZACION: '2',
	CREACION: '3',
	ELIMINACION: '4',
};

export interface IRequestBitacora {
	request?: { [key: string]: string };
	identificador?: string;
	valorAnterior?: {
		[key: string]: string | number | Object | Array<Object> | undefined;
	};
	valorNuevo?: {
		[key: string]: string | number | Object | Array<Object> | undefined;
	};
}

export interface IApiBitacora {
	data: IRequestBitacora;
	headers?: AxiosRequestHeaders;
}

export interface IRegisterData {
	[key: string]: IRequestBitacora;
}

export interface IPropsRegister {
	key: string;
	scheme: string;
	valorNuevo: {
		[key: string]: string | number | Object | Array<Object> | undefined;
	};
}

export class Bitacora {
	ID_TYPE = '';
	TYPE = '';

	constructor(ID_TYPE: string, TYPE: string) {
		this.ID_TYPE = ID_TYPE;
		this.TYPE = cleanStringRoute(TYPE);
	}

	Read(ID_EVENT: string, EVENT: string): ITypeBitacora {
		return {
			ACTION: BITACORA_ACTION.LECTURA,
			ID_TYPE: this.ID_TYPE,
			TYPE: this.TYPE,
			ID_EVENT,
			EVENT,
		};
	}

	Update(ID_EVENT: string, EVENT: string): ITypeBitacora {
		return {
			ACTION: BITACORA_ACTION.ACTUALIZACION,
			ID_TYPE: this.ID_TYPE,
			TYPE: this.TYPE,
			ID_EVENT,
			EVENT,
		};
	}

	Create(ID_EVENT: string, EVENT: string): ITypeBitacora {
		return {
			ACTION: BITACORA_ACTION.CREACION,
			ID_TYPE: this.ID_TYPE,
			TYPE: this.TYPE,
			ID_EVENT,
			EVENT,
		};
	}

	Delete(ID_EVENT: string, EVENT: string): ITypeBitacora {
		return {
			ACTION: BITACORA_ACTION.ELIMINACION,
			ID_TYPE: this.ID_TYPE,
			TYPE: this.TYPE,
			ID_EVENT,
			EVENT,
		};
	}

	Custom({
		ACTION,
		ID_TYPE,
		TYPE,
		ID_EVENT,
		EVENT,
	}: ITypeBitacora): ITypeBitacora {
		return {
			ACTION,
			ID_TYPE,
			TYPE,
			ID_EVENT,
			EVENT,
		};
	}
}
