import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		tool: {
			position: 'absolute',
			top: 50,
		},
		toolSmall: {
			left: -590,
		},
		container: {
			position: 'relative',
			width: '50vw',
			maxWidth: '860px',
			zIndex: 10,
			[theme.breakpoints.down('lg')]: {
				maxWidth: '600px',
			},
			[theme.breakpoints.down('md')]: {
				width: '420px',
			},
			[theme.breakpoints.down('sm')]: {
				width: '300px',
			},
		},
		activeTool: {
			left: 0
		},
		header: {
			position: 'relative',
			zIndex: 1,
			backgroundColor: '#D1001F',
			color: 'white',
			fontSize: 30,
			fontWeight: '700',
			height: '74px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			borderTopRightRadius: '10px',
			'& p': {
				margin: '0px'
			},
			[theme.breakpoints.down('xl')]: {
				fontSize: 22,
				height: '60px',
			},
			[theme.breakpoints.down('md')]: {
				fontSize: 18,
				height: '50px',
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: 14,
			},
		},
		close: {
			position: 'absolute',
			width: 52,
			height: 52,
			right: 11,
			top: 11,
			cursor: 'pointer',
			[theme.breakpoints.down('xl')]: {
				width: 40,
				height: 40,
				right: 10,
				top: 10,
			},
			[theme.breakpoints.down('md')]: {
				width: 32,
				height: 32,
				right: 9,
				top: 9,
			},
		},
		body: {
			backgroundColor: '#F6F5F5',
			borderBottomRightRadius: '10px',
			padding: '30px',
			boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 4px',
			scrollbarWidth: 'none',
			overflow: 'scroll',
			'&::-webkit-scrollbar': {
				display: 'none',
			},
			[theme.breakpoints.down('lg')]: {
				padding: '20px',
			},
			[theme.breakpoints.down('sm')]: {
				padding: '10px'
			},
		},
		iconShow: {
			position: 'absolute',
			right: -126,
			top: 124,
			width: 140,
			height: 122,
			backgroundColor: '#F6F5F5',
			borderTopRightRadius: '60px',
			borderBottomRightRadius: '60px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
			paddingRight: 10,
			boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 4px',
			cursor: 'pointer',
			zIndex: 10,
			[theme.breakpoints.down('xl')]: {
				height: 85,
				right: -90,
			},
			[theme.breakpoints.down('md')]: {
				display: 'none'
			},
		},
		iconShowSmall: {
			position: 'absolute',
			right: -70,
			top: 124,
			width: 180,
			height: 66,
			backgroundColor: '#F6F5F5',
			borderTopRightRadius: '30px',
			borderBottomRightRadius: '30px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
			paddingRight: 5,
			boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 4px',
			zIndex: 0,
			transition: 'right 0.9s',
			cursor: 'pointer',
			'&:hover': {
				right: -110
			}
		},
		iconArrow: {
			position: 'absolute',
			height: '26px',
			right: 80,
			top: 20,
		},
		iconShowRed: {
			width: 106,
			height: 106,
			backgroundColor: '#D1001F',
			borderRadius: '50%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			[theme.breakpoints.down('xl')]: {
				width: 70,
				height: 70,
				'& img': {
					width: 30
				}
			},
		},
		iconShowRedSmall: {
			width: 57,
			height: 57,
			backgroundColor: '#D1001F',
			borderRadius: '50%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		containerItem: {
			width: '100%',
			height: '190px',
			borderRadius: '10px',
			boxShadow: '0px 3px 6px #00000029',
			border: '1px solid #DFDFDF',
			textAlign: 'center',
			padding: '18px',
			boxSizing: 'border-box',
			cursor: 'pointer',
			overflow: 'scroll',
			'&::-webkit-scrollbar': {
				display: 'none',
			},
			[theme.breakpoints.down('lg')]: {
				width: '100%',
				maxWidth: '220px',
				height: '160px',
				padding: '10px',
			},
			[theme.breakpoints.down('md')]: {
				width: '100%',
				maxWidth: 'unset',
				height: '150px',
			},
			[theme.breakpoints.down('sm')]: {
				padding: '5px',
				width: '100%',
				maxWidth: '130px',
				height: '120px',
			},
		},
		containerItemLarge: {
			width: '100%',
			height: '120px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			[theme.breakpoints.down('lg')]: {
				height: '130px',
				flexWrap: 'wrap',
				maxWidth: 'unset',
				'& div': {
					margin: '-10px 0 0'
				},
				'& label': {
					width: '100%',
					textAlign: 'center',
					order: '3'
				}
			},
			[theme.breakpoints.down('sm')]: {
				position: 'relative',
				width: '130px',
				marginBottom: '5px',
				height: '120px',
				'& div': {
					position: 'absolute',
					top: 5,
					'&:first-child': {
						left: -5
					},
					'&:last-child': {
						right: -5
					}
				},
				'& label': {
					marginTop: 64
				}
			},
		},
		containerItemSmall: {
			height: '120px',
			[theme.breakpoints.down('lg')]: {
				width: '100%',
				maxWidth: '200px',
				height: '130px',
				paddingTop: '20px',
			},
			[theme.breakpoints.down('sm')]: {
				width: '130px',
				maxWidth: '130px',
				marginBottom: '10px',
				height: '120px',
			},
		},
		iconItem: {
			width: 86,
			height: 86,
			background: '#DAE2EC',
			border: '2px solid #283990',
			borderRadius: '50%',
			margin: '0 auto 10px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			[theme.breakpoints.down('xl')]: {
				transform: 'scale(0.9)',
			},
			[theme.breakpoints.down('lg')]: {
				transform: 'scale(0.6)',
				marginBottom: '-5px',
				marginTop: '-5px',
			},
			[theme.breakpoints.down('sm')]: {
				transform: 'scale(0.4)',
				marginBottom: '-15px',
				marginTop: '-15px',
			},
		},
		iconItemActive: {
			background: '#283990'
		},
		iconItemSmall: {
			width: 52,
			height: 52,
			marginBottom: 5,
			'& img': {
				height: 26,
			},
			[theme.breakpoints.down('md')]: {
				width: 74,
				height: 74,
				marginTop: '-10px',
				marginBottom: 0,
			},
			[theme.breakpoints.down('sm')]: {
				transform: 'scale(0.5)',
			},
		},
		iconItemMedium: {
			width: 74,
			height: 74,
			margin: '0 22px',
			[theme.breakpoints.down('xl')]: {
				margin: '0 auto',
			},
			[theme.breakpoints.down('sm')]: {
				transform: 'scale(0.5)',
			},
		},
		itemActive: {
			border: '2px solid #283990',
		},
		labelItem: {
			fontSize: 20,
			fontWeight: '700',
			color: '#283990',
			[theme.breakpoints.down('xl')]: {
				fontSize: 18,
			},
			[theme.breakpoints.down('lg')]: {
				fontSize: 16,
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: 12,
			},
		},
		hide: {
			display: 'none'
		},
		readingTop: {
			position: 'fixed',
			top: 0,
			left: 0,
			width: '100vw',
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
			zIndex: 99,
			borderBottom: '5px solid #D1001F'
		},
		readingBottom: {
			position: 'fixed',
			left: 0,
			width: '100vw',
			height: '100vh',
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
			zIndex: 99,
			borderTop: '5px solid #D1001F'
		}
	})
);
