import { Box } from '@mui/material';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useStyles } from './styles/styles';
import { MESSAGES_ERROR, SERVICESID, getDateParse } from './utils';
import { ESTATUS } from './utils/stepsStatus';
import CustomButton from '../../../components/Button';
import { RootState } from '../../../store';
import { useEffect, useState } from 'react';
import ModalLoading from '../../../components/ModalLoading';
import CustomAlert from '../../../components/CustomAlert';
import { encodeAllJSONData } from '../../../utils/encrypt';
import { writingRequestAPI } from '../../../api/modules/WritingRequest';
import { downloadPDF } from '../../../utils/downloadPDF';
import { formatDate } from '../../../utils/dates';
import iconoDescarga from '../../../assets/img/icono-descarga.png';
import { INTERNAL_MESSAGES } from '../../../config/messageCatalog';
import { ICita } from './interface';
import { useEscrituras } from '../hooks/useEscrituras';
import { CESI_QUOTE_STATUS, CODE_RESPONSE } from './utils/pagesStatus';

interface IProps {
	setPage: (arg: number) => void;
	nextProcess: () => void;
}

const CesiQuoteDetail = ({ setPage, nextProcess }: IProps) => {
	const styles = useStyles();
	const { user } = useSelector((state: RootState) => state.session);
	const { escrituras, setEscrituras } = useEscrituras();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState('');
	const [docRequeridos, setDocRequeridos] = useState([]);
	const [cita, setCita] = useState<ICita>({} as ICita);
	const [disabledBtn, setDisabledBtn] = useState(false);

	const handleCancel = async () => {
		try {
			setMessage('');
			setLoading(true);
			const data = {
				pAppointmentId: cita.id,
				motivoCancelacion: '',
			};
			const dataEncrypt = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const response = await writingRequestAPI.cancelar(dataEncrypt);
			if (response.code === CODE_RESPONSE.SUCCESS) {
				nextProcess();
				return;
			}
			setMessage(response.message || MESSAGES_ERROR.errorService);
		} catch (error) {
			setMessage(MESSAGES_ERROR.errorService);
		} finally {
			setLoading(false);
		}
	};

	const donwloadPDF = async () => {
		try {
			setMessage('');
			setLoading(true);
			const params = {
				service: cita.serviceDes, // serviceDes
				estatus: ESTATUS[escrituras.estatus]?.label, // Vacio esto es igual en lo de citas 24
				fechaEstatus: cita.creationDate, // creationDate
				codigoCita: cita.appointmentCode, // appointmentCode
				fechaCita: cita.date, // date
				horarioCita: cita.date, // date
				organizationDes: cita.organizationDes, // organizationDes
				organizationLocation: cita.organizationLocation, // organizationLocation
				listadoFormateado: docRequeridos, // Consulta documentos bullets
				numeroCaso: escrituras.noCaso, // No de caso del modulo
				CreationDate: cita.creationDate, // creationDate
			};
			const dataEncrypt = await encodeAllJSONData(
				{
					data: JSON.stringify(params),
				},
				user?.k || ''
			);
			const responsePDF = await writingRequestAPI.downloadPDF(
				dataEncrypt
			);
			if (
				responsePDF.code === CODE_RESPONSE.SUCCESS &&
				responsePDF.data?.base64
			) {
				downloadPDF({
					payload: responsePDF.data.base64,
					name: `Haz_tu_cita-${formatDate('P')}`,
				});
				return;
			}
			setMessage(responsePDF.message || MESSAGES_ERROR.errorService);
		} catch (error) {
			setMessage(MESSAGES_ERROR.errorService);
		} finally {
			setLoading(false);
		}
	};

	const getDocuments = async (pOrganizationCode: string) => {
		try {
			setMessage('');
			setLoading(true);
			const params = {
				pOrganizationCode,
				pServiceId: SERVICESID,
			};
			const dataEncrypt = await encodeAllJSONData(
				{
					data: JSON.stringify(params),
				},
				user?.k || ''
			);
			const result = await writingRequestAPI.documentosRequeridos(
				dataEncrypt
			);
			if (result.code === CODE_RESPONSE.SUCCESS) {
				const { requirementText } = result?.data?.requirementsList[0];
				const docsRequired = requirementText
					.replaceAll('&bull;', '')
					.replaceAll('• ', '')
					.split('<br/>');
				setDocRequeridos(
					docsRequired.filter(
						(f: any) =>
							f !==
							'Cualquier de los siguientes documentos dependiendo cómo se llevará a cabo el trámite'
					)
				);
				return;
			}
			setMessage(result?.message || INTERNAL_MESSAGES.ERROR_MESSAGE);
		} catch (ex) {
			setMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
		} finally {
			setLoading(false);
		}
	};

	const consultarCita = async () => {
		try {
			setMessage('');
			setLoading(true);
			const data = {
				pAppointmentCode: '',
			};
			const dataEncrypt = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const response = await writingRequestAPI.citasActuales(dataEncrypt);
			if (response.code === CODE_RESPONSE.SUCCESS) {
				const { citas } = response.data;
				if (citas && citas.length > 0) {
					const findCita: ICita = citas.find(
						(cita: any) => cita.serviceId === SERVICESID
					);
					if (findCita) {
						setCita(findCita);
						getDocuments(findCita.organizationCode);
						setEscrituras({
							...escrituras,
							citaId: findCita?.id,
						});
						setDisabledBtn(false);
						return;
					}
				}
			}
			setDisabledBtn(true);
			setMessage(response.message || MESSAGES_ERROR.errorService);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		consultarCita();
		/* eslint-disable react-hooks/exhaustive-deps */
	}, []);

	return (
		<>
			<ModalLoading loading={loading} />
			<Box className={styles.boxMainCesiGeneral}>
				<Box className={styles.headerLabel}>
					<h2 className={styles.labelColorPrimary}>
						Cita confirmada
					</h2>
				</Box>
				<Box className={styles.container}>
					<span>Número de caso</span>
					<Box>{escrituras.noCaso}</Box>
				</Box>
				<Box className={styles.container}>
					<span>Fecha de solicitud</span>
					<Box>{getDateParse(escrituras.fecha)}</Box>
				</Box>
				<Box className={styles.container}>
					<span>Servicio</span>
					<Box>Solicitud de escritura</Box>
				</Box>
				<Box className={styles.container}>
					<span>Estatus</span>
					<Box>{ESTATUS[escrituras.estatus]?.label}</Box>
				</Box>
				<Box className={styles.container}>
					<span>Fecha de estatus</span>
					<Box>{getDateParse(escrituras.fechaEstatus)}</Box>
				</Box>
				<Box className={styles.container}>
					<span>Descripción</span>
					<Box>
						Tu cita <b>es personal</b> y cuando acudas a ella debes
						presentar tu <b>identificación oficial vigente</b>,
						requisito indispensable para efectuar tu trámite. Te
						recomendamos que llegues con{' '}
						<b>10 minutos de anticipación</b> para que no corras él
						riesgo de perder tu cita y tengas que programar una
						nueva. Si por alguna razón no puedes acudir,
						reprogramarla o cancelarla.
					</Box>
				</Box>
				<Box className={styles.container}>
					<span>Documentos necesarios</span>
					<Box>
						<p className={styles.labelItem}>
							Identificación oficial vigente
						</p>
						Presenta alguno de la lista
						<ul className={styles.ulList}>
							{docRequeridos.map((doc, k) => (
								<li key={k}>{doc}</li>
							))}
						</ul>
						<p>
							Consulta los documentos que debes llevar para el
							trámite de tu crédito en nuestra{' '}
							<b>
								www.infonavit.org.mx {' > '}
								Trabajadores {' > '} Quiero un crédito.
							</b>{' '}
							Recuerda que los requisitos varían en función del
							producto que elijas.
						</p>
						<p>
							Es importante que agendes correctamente tu cita al
							servicio o trámite que deseas, si agendas una cita a
							un servicio diferente, tu atención en el Cesi se
							demorará.
						</p>
					</Box>
				</Box>
				<Box className={styles.container}>
					<span>Código de cita</span>
					<Box>{cita.appointmentCode}</Box>
				</Box>
				<Box className={styles.container}>
					<span>Fecha de cita</span>
					<Box>
						{cita.date
							? format(new Date(cita.date), 'dd/MM/yyyy')
							: ''}
					</Box>
				</Box>
				<Box className={styles.container}>
					<span>Horario de cita</span>
					<Box>
						{cita.date
							? format(new Date(cita.date), 'hh:mm aa')
							: ''}
					</Box>
				</Box>
				<Box className={styles.container}>
					<span>Ubicación</span>
					<Box>{cita.organizationDes}</Box>
				</Box>
				<CustomAlert
					message={message}
					severity="warning"
					show={Boolean(message)}
				/>

				<p className={styles.labelCenter}>
					Se ha enviado esta información a tu correo.
				</p>

				<Box className={styles.labelDownload} onClick={donwloadPDF}>
					Descargar comprobante
					<img
						src={iconoDescarga}
						alt="download icon"
						className={styles.imgDownload}
					/>
				</Box>
				<Box className={styles.boxButtonsGroup}>
					<Box className={styles.buttonItem}>
						<CustomButton
							disabled={disabledBtn}
							label={'Cancelar cita'}
							variant={'text'}
							onClick={handleCancel}
						/>
					</Box>
					<Box className={styles.buttonItem}>
						<CustomButton
							disabled={disabledBtn}
							label={'Reprogramar'}
							variant={'outlined'}
							onClick={() => setPage(CESI_QUOTE_STATUS.GENERAL)}
						/>
					</Box>
					<Box className={styles.buttonItem}>
						<CustomButton
							label={'Finalizar'}
							onClick={() => nextProcess()}
						/>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default CesiQuoteDetail;
