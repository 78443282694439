import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'wrap',
	},
	containerTabs: {
		display: 'flex',
		flexWrap: 'wrap',
		[theme.breakpoints.down('sm')]: {
			marginTop: 10,
			flexDirection: 'column',
		},
	},
	containerTabsCard: {
		display: 'flex',
		flexWrap: 'wrap',
		backgroundColor: '#FFFFFF',
		[theme.breakpoints.down('sm')]: {
			marginTop: 10,
			flexDirection: 'column',
		},
	},
	tabHeader: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
		height: 50,
		marginBottom: 10,
	},
	tabHeaderCard: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
		height: 50,
		[theme.breakpoints.down('md')]: {
			marginBottom: '10px !important',
		},
		// '&:nth-child(1)': {
		// 	padding: 0,
		// },
	},
	containerSubItemTab: {
		width: '100%',
		backgroundColor: '#FFFFFF',
		marginBottom: 10,
	},
	containerSubItemTabCard: {
		width: '100%',
		paddingRight: 10,
		backgroundColor: 'transparent',
	},
	buttonTab: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
		height: 50,
		backgroundColor: 'transparent',
		width: '100%',
		border: 0,
		borderBottomWidth: 1,
		borderBottomStyle: 'solid',
		borderBottomColor: theme.palette.primary.main,
		cursor: 'pointer',
		color: theme.palette.primary.main,
		fontSize: 14,
		fontWeight: '600',
	},
	buttonTabCard: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
		height: 50,
		backgroundColor: '#FFFFFF',
		width: '100%',
		border: 0,
		cursor: 'pointer',
		color: theme.palette.primary.main,
		fontSize: 14,
		fontWeight: '600',
		borderTopRightRadius: 8,
		borderTopLeftRadius: 8,
		boxShadow: 'rgba(0, 0, 0, 0.3) 0px 4px 22px',
		[theme.breakpoints.down('md')]: {
			boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 22px',
		},
	},
	buttonTabDisabled: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
		height: 50,
		width: '100%',
		backgroundColor: 'transparent',
		border: 0,
		color: '#CBCBCB',
		fontSize: 14,
		fontWeight: '600',
		cursor: 'pointer',
		[theme.breakpoints.down('md')]: {
			border: 0,
		},
	},
	buttonTabDisabledCard: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
		height: 50,
		backgroundColor: '#FFFFFF',
		width: '100%',
		border: 0,
		color: '#CBCBCB',
		fontSize: 14,
		fontWeight: '600',
		cursor: 'pointer',
		borderTopRightRadius: 8,
		borderTopLeftRadius: 8,
		// boxShadow: '1px 1px 60px -32px rgba(0,0,0,0.1) inset',
		[theme.breakpoints.down('md')]: {
			border: 0,
		},
	},
	buttonTabChecked: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
		height: 50,
		backgroundColor: 'transparent',
		border: 0,
		borderBottomWidth: 1,
		borderBottomStyle: 'solid',
		borderBottomColor: theme.palette.primary.main,
		color: theme.palette.primary.main,
		fontSize: 14,
		fontWeight: '600',
		cursor: 'pointer',
		[theme.breakpoints.down('md')]: {
			border: 0,
		},
	},
	buttonTabCheckedCard: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
		height: 50,
		backgroundColor: '#FFFFFF',
		width: '100%',
		border: 0,
		// borderBottomWidth: 1,
		borderBottomStyle: 'solid',
		borderBottomColor: theme.palette.primary.main,
		color: theme.palette.primary.main,
		borderTopRightRadius: 8,
		borderTopLeftRadius: 8,
		fontSize: 14,
		fontWeight: '600',
		cursor: 'pointer',
		[theme.breakpoints.down('md')]: {
			border: 0,
		},
	},
	textTabName: {
		letterSpacing: 0.8,
		'&:active': {
			transition: '0.5s',
		},
	},
	containerInformation: {
		// display: 'flex',
		// flex: 1,
		marginTop: -10,
		[theme.breakpoints.down('md')]: {
			marginBottom: '30px !important',
		},
	},
	containerInformationCard: {
		paddingRight: 10,
		[theme.breakpoints.down('md')]: {
			marginBottom: '30px !important',
			marginTop: '-10px !important',
		},
	},
	tabOrder2: {
		display: 'flex',
		order: 2,
		'-webkit-box-ordinal-group': 2,
		'-moz-box-ordinal-group': 2,
		'-ms-flex-order': 2,
		'-webkit-order': 2,
	},
	tabOrder1: {
		display: 'flex',
		order: 1,
		'-webkit-box-ordinal-group': 1,
		'-moz-box-ordinal-group': 1,
		'-ms-flex-order': 1,
		'-webkit-order': 1,
	},
	tabOrder0: {
		display: 'flex',
		order: 0,
		'-webkit-box-ordinal-group': 0,
		'-moz-box-ordinal-group': 0,
		'-ms-flex-order': 0,
		'-webkit-order': 0,
	},
	tabOrder3: {
		display: 'flex',
		order: 3,
		'-webkit-box-ordinal-group': 3,
		'-moz-box-ordinal-group': 3,
		'-ms-flex-order': 3,
		'-webkit-order': 3,
	},
	tabOrder4: {
		display: 'flex',
		order: 4,
		'-webkit-box-ordinal-group': 4,
		'-moz-box-ordinal-group': 4,
		'-ms-flex-order': 4,
		'-webkit-order': 4,
	},
	tabOrder5: {
		display: 'flex',
		order: 5,
		'-webkit-box-ordinal-group': 5,
		'-moz-box-ordinal-group': 5,
		'-ms-flex-order': 5,
		'-webkit-order': 5,
	},
	tabOrder6: {
		display: 'flex',
		order: 6,
		'-webkit-box-ordinal-group': 6,
		'-moz-box-ordinal-group': 6,
		'-ms-flex-order': 6,
		'-webkit-order': 6,
	},
	title: {
		fontSize: 14,
	},
	title1: {
		fontSize: 15,
	},
	title2: {
		fontSize: 16,
	},
	title3: {
		fontSize: 17,
	},
	title4: {
		fontSize: 18,
	},
	title5: {
		fontSize: 19,
	},
	titleMin1: {
		fontSize: 13,
	},
	titleMin2: {
		fontSize: 12
	},
	titleMin3: {
		fontSize: 11
	},
}));
