import { Box, Paper } from '@mui/material';
import { useStyles } from './styles';

const HowItWorks = () => {
	const classes = useStyles();

	return (
		<Paper sx={{ pt: 3, pb: 2 }}>
			<Box className={classes.backgroundContenMargin}>
				<iframe
					className={`${classes.video} tag-to-read`}
					src="https://www.youtube.com/embed/w1h1-BmB87c"
					title="YouTube video player"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					aria-label="Video de: ¿Cómo realizo mis aportaciones extraordinarias?"
				></iframe>
			</Box>
		</Paper>
	);
};

export default HowItWorks;
