export const PAGES_SAVINGS = {
	INFO_PAGE: 0,
	RETURN_OPTIONS: 1,
	NRPREQUEST_PAGE: 2,
	SAVINGS_INFORMATION: 3,
	SELECT_RETURN_METHOD: 4,
	SELECT_STATE_CLAIM: 5,
	QUESTION_DEMAND: 6,
	SELECT_TYPE_DEMAND: 7,
	UPLOAD_CERTIFICATES_FILES: 8,
	PENSION_RESOLUTION_DATE: 9,
	SELECT_TYPE_PENSION_PAGE: 10,
	SELECT_AFORE_PAGE: 11,
	INTERBANK_CLABE_REQUEST: 12,
	VERIFY_BANK_DETAILS_PAGE: 13,
	CASE_INFORMATION_PAGE: 14,
	UPLOAD_FILES: 15,
	MESSAGE_HSR: 16,
	MAKE_DATE: 17,
	MESSAGE_PAGE: 18,
	EXCEPTION_PAGE: 19,
};
