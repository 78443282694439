import { Box } from '@mui/material';
import { useStyles } from './styles/styles';
import { useEffect, useState } from 'react';
import { ESTATUS } from './utils/stepsStatus';
import ButtonsDelivery from './components/ButtonsDelivery';
import { useEscrituras } from '../hooks/useEscrituras';
import { getDateParse } from './utils';
import ModalLoading from '../../../components/ModalLoading';

interface IProps {
	customProcess: (arg: number) => void;
	setPage: (arg: number) => void;
}

const DeliveryOfDeeds = ({ customProcess, setPage }: IProps) => {
	const styles = useStyles();
	const { escrituras, getConsultDetail } = useEscrituras();
	const stepInfo = ESTATUS[escrituras?.estatus];
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const req = async () => {
			try {
				setLoading(true);
				await getConsultDetail({});
			} catch (error) {
			} finally {
				setLoading(false);
			}
		};
		req();
		/* eslint-disable react-hooks/exhaustive-deps */
	}, []);

	return (
		<>
			<ModalLoading loading={loading} />
			<Box className={styles.boxMain}>
				<h2 className={styles.titlePage}>
					Gracias por utilizar los servicios digitales del Infonavit
				</h2>
				<Box className={styles.container}>
					<span>Número de caso</span>
					<Box>{escrituras.noCaso}</Box>
				</Box>
				<Box className={styles.container}>
					<span>Fecha de solicitud</span>
					<Box>{getDateParse(escrituras.fecha)}</Box>
				</Box>
				<Box className={styles.container}>
					<span>Servicio</span>
					<Box>Entrega de escrituras</Box>
				</Box>
				<Box className={styles.container}>
					<span>Cesi de entrega</span>
					<Box>
						{escrituras.cesiTramite}
						<p className={styles.pMargin}>
							{escrituras.edoTramite}
						</p>
					</Box>
				</Box>
				<Box className={styles.container}>
					<span>Estatus</span>
					<Box>
						<p className={styles.title}>{stepInfo?.label ?? ''}</p>
						{stepInfo?.justify &&
							stepInfo?.justify[escrituras?.razonEstatus || '']
								.label && (
								<p className={styles.justificacion}>
									{
										stepInfo?.justify[
											escrituras?.razonEstatus || ''
										].label
									}
								</p>
							)}
						<div className={styles.message}>
							{stepInfo?.justify
								? stepInfo?.justify[
										escrituras?.razonEstatus || ''
								  ].description || ''
								: stepInfo?.description ?? ''}
						</div>
					</Box>
				</Box>
				<ButtonsDelivery
					nextProcess={customProcess}
					setPage={setPage}
				/>
			</Box>
		</>
	);
};

export default DeliveryOfDeeds;
