/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Grid } from '@mui/material';
import { useStyles } from './styles';

//Assets
import imgPerfilCero from '../../../assets/img/PerfilCero.png';

interface Props {
	profile: string;
}

const ProfileCero = ({ profile }: Props) => {
	const classes = useStyles();

	return (
		<Grid container spacing={2}>
			<Paper className={classes.cardPerfilCero}>
				<div className={classes.titleCard}>
					<h2>
						¡Actualizando información!
					</h2>
				</div>
				<div className={classes.textCardPerfilCero}>
					<img
						src={imgPerfilCero}
						alt="ícono Actualizando información"
						className={classes.iconPerfilCero}
					/>
					{profile === '6.4' && (
						<>
							<p>
								<b>Es necesario que actualices tu Registro Federal de Contribuyentes (RFC) </b>
								accede al menú de Mi Perfil y selecciona la opción correspondiente,
								una vez que realices la actualización <b>deberás esperar 72 hrs.</b> para que
								se pueda replicar en nuestros sistemas y de esta manera tener acceso a
								todos los servicios que se brindan por este medio.
							</p>
							<p>
								En el caso de que no puedas realizar la actualización mediante
								la opción <b>"Validación con información del SAT"</b>, deberás elegir
								la opción <b>"Haz una cita"</b> para acudir a un Centro de Servicio Infonavit.
							</p>
						</>
					)}
					{profile === '6.5' && (
						<>
							<p>
								<b>Es necesario que actualices tu Clave Única de Registro de Población
									(CURP)</b> accede al menú de <b>Mi Perfil</b> y selecciona la opción correspondiente,
								una vez que realices la actualización deberás esperar <b>72 hrs.</b> para que se
								pueda replicar en nuestros sistemas y de esta manera tener acceso a todos
								los servicios que se brindan por este medio.
							</p>
						</>
					)}
					{profile !== '6.4' && profile !== '6.5' && (
						<>
							<p>
								<b>Por el momento estamos actualizando tu información
									para brindarte servicios acorde a tus necesidades.</b>
							</p>
							<p>
								No es necesario que reportes esta situación a través
								de otros canales de atención, <b>disculpa las molestias que esto ocasiona.</b>
							</p>
						</>
					)}

				</div>
			</Paper>
		</Grid>
	);
};

export default ProfileCero;
