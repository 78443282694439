// Dependencies
import { Box, InputAdornment } from '@mui/material';
import React, { useState } from 'react';

// Material Components
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';

// Resources
import {
	containsUppercaseValid,
	containsLowercaseValid,
	containsDigitValid,
	containsSpecialCharactersValid,
} from '../../utils/expressions';
import { IAlert } from '../../interfaces/alert';
import CustomAlert from '../../components/CustomAlert';

// Components
import TextInput from '../TextInput';

// Assets
import { useStyle } from './styles';

// Interfaces
import { IInputPasswordResult } from '../../interfaces/inputPassword';

export interface ITextField {
	value: string | number | '';
	error?: boolean;
	helperText?: string;
	size?: 'small' | 'medium' | undefined;
	isRequired?: boolean;
	labelStyles?: React.CSSProperties;
	onChange: (props: IInputPasswordResult) => void;
	placeholder?: string;
	placeholderConfirm?: string;
	confirmPassword?: boolean;
	labelCurrentPass?: string;
	labelNewPass?: string;
	setErrorInputPassword?: (data: boolean) => void;
	errorInputPassword?: boolean;
}

export interface IPasswordConditions {
	minusculas: boolean;
	mayusculas: boolean;
	caracteres: boolean;
	digitos: boolean;
	longitud: boolean;
}

const TextInputPassword = (props: ITextField) => {
	const [passwordConditions, setPasswordConditions] = useState(
		{} as IPasswordConditions
	);
	const styles = useStyle();
	const [Password, setPassword] = useState('');
	const [Password2, setPassword2] = useState('');
	const [NivelSeguridad, setNivelSeguridad] = useState('');
	const [NivelSeguridadNumber, setNivelSeguridadNumber] = useState(0);
	const [newStyle, setnewStyle] = useState({
		background: '#C7C6C5',
		width: '',
		border: '1px solid #C7C6C5',
	});
	const [errorPassword2, setErrorPassword2] = useState({
		valid: false,
		msg: '',
	});
	const [showPassword, setShowPassword] = useState(false);
	const [showPassword2, setShowPassword2] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'warning',
	});

	const changeStylePass = (nSeguridad: number) => {
		switch (nSeguridad) {
			case 1:
				setnewStyle({
					background: '#C60707',
					width: '20%',
					border: '1px solid #C60707',
				});

				setNivelSeguridad('BAJO');
				break;
			case 2:
				setnewStyle({
					background: '#C60707',
					width: '40%',
					border: '1px solid #C60707',
				});

				setNivelSeguridad('BAJO');
				break;
			case 3:
				setnewStyle({
					background: '#EFA22A',
					width: '60%',
					border: '1px solid #EFA22A',
				});
				setNivelSeguridad('MEDIO');
				break;
			case 4:
				setnewStyle({
					background: '#EFA22A',
					width: '80%',
					border: '1px solid #EFA22A',
				});
				setNivelSeguridad('MEDIO');
				break;
			case 5:
				setnewStyle({
					background: '#28A439',
					width: '',
					border: '1px solid #28A439',
				});
				setNivelSeguridad('ALTO');
				break;
			default:
				setnewStyle({
					background: '#EBEBEB',
					width: '',
					border: '1px solid #EBEBEB',
				});
				setNivelSeguridad('');
		}
	};

	const validNumberRepeat = (letras: string) => {
		let valid = 0;
		let numberAnterior = -1;
		letras.split('').forEach((e) => {
			const numberActual = Number(e);
			if (!isNaN(Number(numberActual)) && valid < 3) {
				if (numberAnterior < 0) {
					numberAnterior = numberActual;
					return;
				}
				const diferencia = numberActual - numberAnterior;
				numberAnterior = numberActual;
				if (diferencia === 1) {
					valid++;
					return;
				}
				valid = 0;
				return;
			}
			if (valid < 3) {
				valid = 0;
				numberAnterior = -1;
			}
		});
		return valid >= 3;
	};

	const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
		setErrorPassword2({
			valid: true,
			msg: '',
		});
		const { value } = event.target;
		let countValidations: number = 0;
		let validations: any = {};

		if (validNumberRepeat(value)) {
			setAlert({
				show: true,
				message:
					'Tu contraseña no debe tener más de tres números consecutivos, ejem. 1234, 5678',
				severity: 'error',
			});
			return;
		}

		if (![...value].every(c => /^[a-zA-Z0-9\-_$#.]+$/.test(c))) {
			let caracterror = null;

			[...value].forEach(c => {
				if (!/^[a-zA-Z0-9\-_$#.]+$/.test(c)) {
					caracterror = c;
				}
			});

			if (props.setErrorInputPassword) {
				props.setErrorInputPassword(true);
			}

			setAlert({
				show: true,
				message: `El signo de ${caracterror} no está permitido, puedes intentar con los siguientes signos (-,$,_,#,.)`,
				severity: 'error',
			});
		} else {
			if (props.setErrorInputPassword) {
				props.setErrorInputPassword(false);
			}
			setAlert({ show: false, message: '', severity: 'error' });
		}
		if (value.length > 25) return;

		if (containsUppercaseValid(value)) {
			countValidations += 1;
			validations.mayusculas = true;
		}
		if (containsLowercaseValid(value)) {
			countValidations += 1;
			validations.minusculas = true;
		}
		if (containsDigitValid(value)) {
			countValidations += 1;
			validations.digitos = true;
		}
		if (containsSpecialCharactersValid(value)) {
			countValidations += 1;
			validations.caracteres = true;
		}

		if (value.replace(' ', '').length >= 10) {
			countValidations += 1;
			validations.longitud = true;
		}

		setNivelSeguridadNumber(countValidations);
		setPassword(value);
		changeStylePass(countValidations);
		setPasswordConditions(validations);

		let result: boolean = false;

		if (props.confirmPassword) {
			if (countValidations === 5) {
				if (value === Password2) {
					result = true;
				}
			}
			props.onChange({
				valid: result,
				password: value,
			});
		} else {
			if (NivelSeguridadNumber === 5) {
				result = true;
			}

			props.onChange({
				valid: result,
				password: value,
			});
		}

		setErrorPassword2({
			valid: value !== Password2 && Password2.length > 0 ? false : true,
			msg: 'Revisa tu contraseña ya que no coinciden e intenta de nuevo',
		});
	};
	const onChangeConfirmarPassword = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setAlert({ show: false, message: '', severity: 'error' });
		const { value } = event.target;
		if (value.length > 25) return;
		if (validNumberRepeat(value)) {
			setAlert({
				show: true,
				message:
					'Tu contraseña no debe tener más de tres números consecutivos, ejem. 1234, 5678',
				severity: 'error',
			});
			return;
		}
		setPassword2(value);
		let result: boolean = false;

		if (props.confirmPassword) {
			if (NivelSeguridadNumber === 5) {
				if (Password === value) {
					result = true;
				}
			}
			props.onChange({
				valid: result,
				password: Password,
			});

			if (!value) {
				setErrorPassword2({
					valid: Password === value ? true : false,
					msg: '',
				});
			} else {
				setErrorPassword2({
					valid: value === Password ? true : false,
					msg: 'Revisa tu contraseña ya que no coinciden e intenta de nuevo',
				});
			}
		} else {
			if (NivelSeguridadNumber === 5) {
				result = true;
			}
			setErrorPassword2({
				valid: Password === value ? true : false,
				msg: '',
			});
			props.onChange({
				valid: result,
				password: Password,
			});
		}
	};

	return (
		<>
			<TextInput
				id="Contraseña"
				name="Contraseña"
				label={
					props.labelCurrentPass
						? props.labelCurrentPass
						: 'Contraseña'
				}
				value={Password}
				error={props.error}
				helperText={props.helperText}
				type={showPassword ? 'text' : 'password'}
				size={props.size}
				isRequired={props.isRequired}
				labelStyles={props.labelStyles}
				placeholder={props.placeholder}
				onChange={onChangePassword}
				sx={{ border: 'black' }}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<VisibilityIcon
								onClick={() => {
									setShowPassword(!showPassword);
								}}
								className={styles.iconPointer}
							/>
						</InputAdornment>
					),
				}}
			/>
			<div className={styles.progressBarContent}>
				<div className={styles.progressBar} style={newStyle}></div>
			</div>
			<div className={styles.LabelContent}>
				<label className={styles.Label}>
					Nivel de seguridad <strong>{NivelSeguridad}</strong>
				</label>
			</div>

			<Box sx={{ display: props.confirmPassword ? 'block' : 'none' }}>
				<TextInput
					id="ConfirmContraseña"
					name="ConfirmContraseña"
					label={
						props.labelNewPass
							? props.labelNewPass
							: 'Confirmar Contraseña'
					}
					type={showPassword2 ? 'text' : 'password'}
					value={Password2}
					onChange={onChangeConfirmarPassword}
					placeholder={props.placeholderConfirm}
					isRequired
					error={!errorPassword2.valid}
					helperText={!errorPassword2.valid ? errorPassword2.msg : ''}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<VisibilityIcon
									onClick={() => {
										setShowPassword2(!showPassword2);
									}}
									className={styles.iconPointer}
								/>
							</InputAdornment>
						),
					}}
					disabled={props.errorInputPassword}
				/>
			</Box>
			<CustomAlert
				message={alert.message}
				severity={alert.severity}
				show={alert.show}
			/>
			<Box sx={{ paddingLeft: '3%', marginTop: 2 }}>
				<strong>
					<label>Recuerda usar los siguientes elementos</label>
				</strong>
				<br />
				<br />
				<div className={styles.ContraseñaSpanGray}>
					<div className={styles.containerElement}>
						<CheckCircleTwoToneIcon
							color={
								passwordConditions.mayusculas
									? 'info'
									: 'disabled'
							}
							style={{
								marginRight: '5px',
								height: '20px',
								marginBottom: '2px',
							}}
						/>{' '}
						<label className={styles.fontSize}>Una mayúscula</label>{' '}
						<br />
					</div>
					<div className={styles.containerElement}>
						<CheckCircleTwoToneIcon
							color={
								passwordConditions.minusculas
									? 'info'
									: 'disabled'
							}
							style={{
								marginRight: '5px',
								height: '20px',
								marginBottom: '2px',
							}}
						/>{' '}
						<label className={styles.fontSize}>Una minúscula</label>{' '}
						<br />
					</div>
					<div className={styles.containerElement}>
						<CheckCircleTwoToneIcon
							color={
								passwordConditions.digitos ? 'info' : 'disabled'
							}
							style={{
								marginRight: '5px',
								height: '20px',
								marginBottom: '2px',
							}}
						/>{' '}
						<label className={styles.fontSize}>Un número</label>{' '}
						<br />
					</div>
					<div className={styles.containerElement}>
						<CheckCircleTwoToneIcon
							color={
								passwordConditions.caracteres
									? 'info'
									: 'disabled'
							}
							style={{
								marginRight: '5px',
								height: '20px',
								marginBottom: '2px',
							}}
						/>{' '}
						<label className={styles.fontSize}>
							Un carácter especial [-,$,_,#,.]
						</label>{' '}
						<br />
					</div>
					<div className={styles.containerElement}>
						<CheckCircleTwoToneIcon
							color={
								passwordConditions.longitud
									? 'info'
									: 'disabled'
							}
							style={{
								marginRight: '5px',
								height: '20px',
								marginBottom: '2px',
							}}
						/>
						<label className={styles.fontSize}>
							10 caracteres mínimo
						</label>
						<br />
					</div>
				</div>
			</Box>
		</>
	);
};

export default TextInputPassword;
