/* eslint-disable @typescript-eslint/no-redeclare */
import { Paper, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import CustomButton from '../../components/Button';
import { HeaderList } from '../../components/HeaderList';
import TextInput from '../../components/TextInput';
import { useStyles } from './styles';
import { useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import BaseModal from '../../components/ModalConfirmation';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import CustomAlert from '../../components/CustomAlert';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import ModalLoading from '../../components/ModalLoading';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import { apiRfcCorrection } from '../../api/modules/rfcCorrection';
import { IAlert } from '../../interfaces/alert';
import useCustomScript from '../../hooks/useCustomScript';
import { useBitacora } from '../../hooks/useBitacora';
import { IDataElecSig, IDataStatus, IRfcViewProps } from './interfaces/register.interface';
import { messageErrorCurp, messageErrorName } from './utils/messages.utils';

declare global {
	interface Window {
		validarFiel: any;
	}
}


const GotElecSig = ({ setPage }: IRfcViewProps) => {
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [displayView, setDisplayView] = useState(0);
	const [inputValue, setInputValue] = useState('');
	const [modalFile, setModalFile] = useState(false);
	const { user } = useSelector((state: RootState) => state.session);
	const [loading, setLoading] = useState(false);
	const setDataElecSig = useState<IDataElecSig>({})[1];
	const validarFiel = window.validarFiel;
	const [cerB, setcerB64] = useState('');
	const [cer, setCer] = useState('');
	const [key, setKey] = useState('');
	const [dataStatus, setDataStatus] = useState<IDataStatus>({});
	const { regBitacoraNotOld, getMaskString } = useBitacora();
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [files, setFiles] = useState({
		file1: {
			name: 'No se eligió ningún archivo',
			data: null,
			type: '',
		},
		file2: {
			name: 'No se eligió ningún archivo',
			data: null,
			type: '',
		},
	});

	useCustomScript('/jquery/jquery.min.js');
	useCustomScript('/efirma/firma-documento.js');
	useCustomScript('/efirma/fiel-validacion.ofu.min.js');

	// var serverIP = 'https://serviciosweb.infonavit.org.mx:8993'; //QA
	var serverIP = process.env.REACT_APP_URL_VALIDA_FIEL; //prod


	const handleOnSubmit = async (rfc: string) => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const rfc_base64 = await encrypt(rfc.toString() || '', key);
			const curp_base64 = await encrypt(user?.curp.toString() || '', key);
			const nss_base64 = await encrypt(user?.nss.toString() || '', key);
			const email_base64 = await encrypt(
				user?.email.toString() || '',
				key
			);
			const given_name_base64 = await encrypt(
				user?.given_name.toString() || '',
				key
			);
			const telefonoCelular_base64 = await encrypt(
				user?.telefonoCelular.toString() || '',
				key
			);

			const responseElecSig = await apiRfcCorrection.firmaElectronica(
				nss_base64,
				email_base64,
				given_name_base64,
				telefonoCelular_base64,
				rfc_base64,
				curp_base64
			);
			let dataBitacora = {
				cer: files.file1.name,
				key: files.file2.name,
				password: getMaskString(inputValue),
			}
			setDataElecSig(responseElecSig);
			if (responseElecSig) {
				consultaStatus();
				regBitacoraNotOld(apiRfcCorrection.schemeUrl, dataBitacora);
			} else {
				setLoading(false);
			}
		} catch (error: any) {
			setLoading(false);
			setAlert({
				show: true,
				message: error.description,
				severity: 'error',
			});
		}
	};

	const consultaStatus = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();

			const rfc_base64 = await encrypt(user?.rfc.toString() || '', key);
			const nss_base64 = await encrypt(user?.nss.toString() || '', key);
			const curp_base64 = await encrypt(user?.curp.toString() || '', key);

			const responseStatusRFC = await apiRfcCorrection.getInitialStatus(
				nss_base64,
				rfc_base64,
				curp_base64
			);
			if (responseStatusRFC?.result) {
				setDataStatus(responseStatusRFC.result);
				setDisplayView(1);
			}
		} catch (error: any) {
			setLoading(false);
			setAlert({
				show: true,
				message:
					INTERNAL_MESSAGES.ERROR_GETTING_DATA,
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleFileCer = (e: any) => {
		if (e.target.files.length > 0) {
			var readerFile = new FileReader();
			readerFile.readAsDataURL(e.target.files[0]);
			readerFile.onload = function (event) {
				setcerB64(
					(event.target?.result as string).replace(
						/^data:(.*;base64,)?/,
						''
					)
				);
			};
		}
		if (e.target.files.length > 0) {
			var readerFile = new FileReader();
			readerFile.onload = function (event) {
				setCer(event?.target?.result as string);
			};
			readerFile.readAsArrayBuffer(e.target.files[0]);
			setFiles({
				...files,
				[e.target.name]: {
					name: e.target.files[0].name,
					data: e.target.files[0],
					type: e.target.files[0].type,
				},
			});
			return cer;
		}
	};

	const handleFileKey = (e: any) => {
		if (e.target.files.length > 0) {
			var readerFile = new FileReader();
			readerFile.onload = function (event) {
				setKey((event?.target?.result as string) || '');
			};
			readerFile.readAsArrayBuffer(e.target.files[0]);
			setFiles({
				...files,
				[e.target.name]: {
					name: e.target.files[0].name,
					data: e.target.files[0],
				},
			});
		}
	};

	const handleSubmitFile = (i: number) => {
		if (i >= 5) {
			setLoading(false);
			setAlert({
				show: true,
				message: 'Por el momento el servicio no está disponible. Intenta más tarde',
				severity: 'error',
			});
			return;
		}
		setLoading(true);
		let inputs = {
			keyBinary: key,
			cerBinary: cer,
			cerB64: cerB,
			password: inputValue,
			serverIP: serverIP,
			pdfB64: '',
			pdfContentType: 'application/pdf',
		};

		if (typeof validarFiel === "function") {
			validarFiel(inputs, (error: any, response: any) => {
				if (error === null) {
					if (response && response?.valido === true) {
						//Respuesta
						if (response?.curp && response?.curp === user?.curp) {
							if (response?.nombre && response?.nombre === user?.given_name && response?.rfc) {
								handleOnSubmit(response.rfc);
							} else {
								setLoading(false);
								setAlert({
									show: true,
									message: messageErrorName,
									severity: 'error',
								});
							}
						} else {
							setLoading(false);
							setAlert({
								show: true,
								message: messageErrorCurp,
								severity: 'error',
							});
						}
					} else {
						setLoading(false);
						setAlert({
							show: true,
							message: response?.mensajeValidacion,
							severity: 'error',
						});
					}
				} else {
					if (error === 'No se proporcionó un pdf a firmar') {
						handleSubmitFile(i + 1);
					} else {
						setLoading(false);
						setAlert({
							show: true,
							message: error,
							severity: 'error',
						});
					}
				}
			});
		} else {
			setLoading(false);
			setAlert({
				show: true,
				message: 'Por el momento el servicio no está disponible. Intenta más tarde',
				severity: 'error',
			});
		}
	};

	const handleDeleteFile = (index: string) => {
		setAlert({
			show: false,
			message: '',
			severity: 'error',
		});
		setFiles({
			...files,
			[index]: {
				name: 'No se eligió ningún archivo',
				data: null,
			},
		});
	};

	const handleTextInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setInputValue(value);
	};
	return (
		<div>
			<HeaderList
				title="Registro o corrección de RFC"
				date="12 julio 2021"
			/>
			<ModalLoading loading={loading} />
			<Paper>
				<div
					id="gotElecSig"
					style={{ display: displayView === 0 ? 'inline' : 'none' }}
				>
					<div
						style={{
							display: 'flex',
							alignItems: matches ? 'normal' : 'center',
							justifyContent: 'space-between',
							flexWrap: 'wrap',
							paddingLeft: matches ? 25 : 20,
							paddingRight: 20,
							paddingBottom: matches ? 70 : 0,
							paddingTop: 10,
							flexDirection:
								files.file1.data && matches
									? 'column'
									: 'inherit',
						}}
					>
						<div style={{ color: 'black', width: matches ? 180 : 380, order: 1 }}>
							<h3 className={classes.maxContent} style={{ fontSize: matches ? 16 : 18 }}>
								Certificado (.cer)
								<label style={{ color: 'red' }}>*</label>
							</h3>
						</div>
						<div
							style={{
								flexGrow: 1,
								width: matches ? '100%' : 0,
								order: matches && !files.file1.data ? 3 : 2,
								margin: 0,
								opacity: 0.5,
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
							}}
						>
							<p className={classes.maxContent}>
								{files.file1.name}
							</p>
						</div>
						<div
							style={{
								textDecoration: 'none',
								color: '#D1001F',
								paddingTop: 0,
								fontSize: 16,
								order: matches && !files.file1.data ? 2 : 3,
								display: 'flex',
								alignItems:
									files.file1.data && matches
										? 'normal'
										: 'center',
								flexDirection:
									files.file1.data && matches
										? 'column'
										: 'inherit',
							}}
						>
							<label
								onClick={() => handleDeleteFile('file1')}
								style={{
									display: files.file1.data ? 'flex' : 'none',
									alignItems: 'center',
									margin: matches ? '5px 0px' : '0px 5px',
									cursor: 'pointer',
								}}
							>
								<Close
									style={{ fontSize: 20, marginRight: 2 }}
								/>
								Eliminar archivo
							</label>
							<label
								style={{
									cursor: 'pointer',
									display: 'flex',
									alignItems: 'center',
									margin: matches ? '5px 0px' : '0px 5px',
								}}
							>
								<label
									style={{
										display: files.file1.data
											? 'none'
											: 'flex',
										cursor: 'pointer',
										paddingBottom: matches ? 25 : 0,
									}}
								>
									<input
										type="file"
										accept=".cer"
										name="file1"
										onChange={handleFileCer}
										className={classes.chooseFileOK}
									/>
									Elegir archivo
								</label>
							</label>
						</div>
					</div>
					<hr className={classes.hr} />

					<div
						style={{
							display: 'flex',
							alignItems: matches ? 'normal' : 'center',
							justifyContent: 'space-between',
							flexWrap: 'wrap',
							paddingLeft: matches ? 25 : 20,
							paddingRight: 20,
							paddingBottom: matches ? 70 : 0,
							paddingTop: 10,
							flexDirection:
								files.file2.data && matches
									? 'column'
									: 'inherit',
						}}
					>
						<div style={{ color: 'black', width: matches ? 180 : 380, order: 1 }}>
							<h3 className={classes.maxContent} style={{ fontSize: matches ? 16 : 18 }}>
								Clave privada (.key)
								<label style={{ color: 'red' }}>*</label>
							</h3>
						</div>
						<div
							style={{
								flexGrow: 1,
								width: matches ? '100%' : 0,
								order: matches && !files.file1.data ? 3 : 2,
								margin: 0,
								opacity: 0.5,
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
							}}
						>
							<p className={classes.maxContent}>
								{files.file2.name}
							</p>
						</div>
						<div
							style={{
								textDecoration: 'none',
								color: '#D1001F',
								paddingTop: 0,
								fontSize: 16,
								order: matches && !files.file2.data ? 2 : 3,
								display: 'flex',
								alignItems:
									files.file2.data && matches
										? 'normal'
										: 'center',
								flexDirection:
									files.file2.data && matches
										? 'column'
										: 'inherit',
							}}
						>
							<label
								onClick={() => handleDeleteFile('file2')}
								style={{
									display: files.file2.data ? 'flex' : 'none',
									alignItems: 'center',
									margin: matches ? '5px 0px' : '0px 5px',
									cursor: 'pointer',
								}}
							>
								<Close
									style={{ fontSize: 20, marginRight: 2 }}
								/>
								Eliminar archivo
							</label>
							<label
								style={{
									cursor: 'pointer',
									display: 'flex',
									alignItems: 'center',
									margin: matches ? '5px 0px' : '0px 5px',
								}}
							>
								<label
									style={{
										display: files.file2.data
											? 'none'
											: 'flex',
										cursor: 'pointer',
										paddingBottom: matches ? 25 : 0,
									}}
								>
									<input
										type="file"
										accept=".key"
										name="file2"
										onChange={handleFileKey}
										className={classes.chooseFileOK}
									/>
									Elegir archivo
								</label>
							</label>
						</div>
					</div>
					<hr className={classes.hr} />

					<div>
						<div
							className={classes.h4}
							style={{
								color: 'black',
								marginBottom: 0,
								width: '100%',
							}}
						>
							<h3 className={classes.maxContent} style={{ fontSize: matches ? 16 : 18 }}>
								Contraseña de clave privada
								<label style={{ color: 'red' }}>*</label>
							</h3>
						</div>
						<div className={classes.inputContainerFirmElec}>
							<TextInput
								data-testid="privPass"
								id="privPass"
								name="privPass"
								type="password"
								label=""
								value={inputValue}
								onChange={handleTextInput}
							/>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								paddingLeft: 50,
								paddingRight: 50,
								margin: 0,
								paddingBottom: 10,
							}}
						>
							<CustomAlert
								show={alert.show}
								severity={alert.severity}
								message={alert.message}
							/>
						</div>

						<div className={classes.botonContainerFirmElec}>
							<CustomButton
								data-testid="gotElecCancelar"
								label="Cancelar"
								onClick={() => {
									setPage(0);
								}}
								variant="outlined"
								styles={{
									width: matches ? 340 : 250,
									height: 40,
									marginBottom: 20,
									marginRight: matches ? 0 : 25,
								}}
							/>
							<CustomButton
								data-testid="gotElecContinuar"
								label="Continuar"
								onClick={() => {
									handleSubmitFile(1)
								}}
								disabled={
									files.file1.data &&
										inputValue &&
										files.file2.data
										? false
										: true
								}
								variant="solid"
								styles={{
									width: matches ? 340 : 250,
									height: 40,
									marginBottom: 20,
								}}
							/>
						</div>
						<hr className={classes.hr} style={{}} />
						<h3
							className={classes.h4}
							style={{
								paddingBottom: 20,
								width: '100%',
								marginTop: 5,
								marginLeft: 15,
								maxWidth: 'max-content'
							}}
						>
							* Datos obligatorios
						</h3>
					</div>
				</div>

				<div
					id="thanksgotElecSig"
					className={classes.containerThanksElecSig}
					style={{ display: displayView === 1 ? 'inline' : 'none' }}
				>
					<h3 className={`${classes.title} ${classes.maxContent} ${classes.marginCenter}`} style={{ paddingTop: 20 }}>
						Gracias por utilizar los servicios electrónicos del
						Infonavit
					</h3>
					<br />
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<h3 className={classes.h4} style={{ color: 'black' }}>
							Número de caso
						</h3>
						<p className={classes.phidelargethanks}>
							{dataStatus.noCaso}
						</p>
					</div>
					<p className={classes.phidesmallthanks}>
						{dataStatus.noCaso}
					</p>
					<hr className={classes.hr} />

					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<h3 className={classes.h4} style={{ color: 'black' }}>
							Fecha de solicitud
						</h3>
						<p className={classes.phidelargethanks}>
							{dataStatus.fecha}
						</p>
					</div>
					<p className={classes.phidesmallthanks}>
						{dataStatus.fecha}
					</p>
					<hr className={classes.hr} />

					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<h3 className={classes.h4} style={{ color: 'black' }}>
							Servicio
						</h3>
						<p className={classes.phidelargethanks}>
							{dataStatus.descripcionServicio}
						</p>
					</div>
					<p className={classes.phidesmallthanks}>
						{dataStatus.descripcionServicio}
					</p>
					<hr className={classes.hr} />

					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<h3 className={classes.h4} style={{ color: 'black' }}>
							Estatus
						</h3>
						<p className={classes.phidelargethanks}>
							{dataStatus.status?.description}
						</p>
					</div>
					<p className={classes.phidesmallthanks}>
						{dataStatus.status?.description}
					</p>
					<hr className={classes.hr} />

					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<h3 className={classes.h4} style={{ color: 'black' }}>
							Fecha de estatus
						</h3>
						<p className={classes.phidelargethanks}>
							{dataStatus.fechaStatus}
						</p>
					</div>
					<p className={classes.phidesmallthanks}>
						{dataStatus.fechaStatus}
					</p>
					<hr className={classes.hr} />
					<div className={classes.botonContainerThanksElecSig}>
						<div>
							<CustomButton
								label="Finalizar"
								onClick={() => {
									setPage(0);
								}}
								variant="solid"
								styles={{
									width: 260,
									height: 40,
									marginBottom: 10,
									marginTop: 20,
								}}
							/>
						</div>
					</div>
				</div>
				<BaseModal
					open={modalFile}
					title="S"
					onConfirm={() => {
						setModalFile(false);
					}}
					onClose={() => setModalFile(false)}
					confirmLabel="Aceptar"
					width="xs"
					children={
						<div
							style={{
								paddingLeft: 40,
								paddingRight: 40,
								paddingTop: 30,
								paddingBottom: 10,
							}}
						>
							<h3
								style={{
									color: '#293990',
									fontSize: 20,
									display: 'flex',
									textAlign: 'center',
									justifyContent: 'center',
									margin: 0,
								}}
							>
								Por favor, adjunta un tipo de documento válido.
							</h3>
						</div>
					}
				/>
			</Paper>
		</div>
	);
};

export default GotElecSig;
