import { Box, Paper, useMediaQuery } from '@mui/material';
import { useState, useEffect } from 'react';
import { HeaderList } from '../../components/HeaderList';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../../components/Button';
import { useStyles } from './styles';
import { useNavigate } from 'react-router-dom';
import CustomAlert from '../../components/CustomAlert';
import { IAlert } from '../../interfaces/alert';

interface Props {
	setPage: (data: number) => void;
	alert?:
	| IAlert
	| {
		show: false;
		message: '';
		severity: 'success';
	};
}

const CurpRegister = ({ alert }: Props) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const classes = useStyles();
	const navigate = useNavigate();
	const [alertDos, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});

	useEffect(() => {
		setAlert({
			show: alert?.show || false,
			message: alert?.message || '',
			severity: alert?.severity || 'success',
		});
	}, [alert]);

	return (
		<Box>
			<HeaderList title="Correción de RFC" date="12 Julio 2021" />
			<Paper sx={{ pt: 4, pb: 4, pr: matches ? 5 : 15, pl: matches ? 5 : 15 }}>
				<div>
					<p style={{ paddingBottom: 10, textAlign: 'center' }}>
						Para actualizar tu RFC es necesario que registres tu CURP. En esta
						misma sección “Mi Perfil” encontrarás la opción para la corrección
						de CURP y nombre. Posteriormente podrás continuar con el trámite
						de corrección de tu RFC.
					</p>
				</div>
				<div style={{ paddingBottom: matches ? 10 : 25 }}>
					<CustomAlert
						message={alertDos.message}
						severity={alertDos.severity}
						show={alertDos.show}
					/>
				</div>
				<div className={classes.botonContainer}>
					<div>
						<CustomButton
							data-testid="buttonNo"
							label="Salir"
							onClick={() => navigate('/inicio')}
							variant="outlined"
							styles={{
								width: matches ? 340 : 260,
								height: 40,
								marginBottom: 5,
							}}
						/>
					</div>
					<div>
						<CustomButton
							data-testid="buttonSí"
							label="Corrección de CURP y nombre"
							onClick={() => navigate('/mi-perfil/correccion-curp-nombre')}
							variant="solid"
							styles={{
								width: matches ? 340 : 260,
								height: 40,
								marginBottom: matches ? 20 : 5,
								marginLeft: matches ? 0 : 25,
							}}
						/>
					</div>
				</div>
			</Paper>
		</Box>
	);
};

export default CurpRegister;
