import { useState } from 'react';
import {
	Box,
	useMediaQuery,
	FormControlLabel,
	Radio,
	FormControl,
	RadioGroup,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useStyles } from '../NipInfonatel/styles';
import CustomButtom from '../../components/Button';
import { nipInfonatelAPI } from '../../api/modules/NipInfonatel';
import ModalLoading from '../../components/ModalLoading';

import { IAlert } from '../../interfaces/alert';
import CustomAlert from '../../components/CustomAlert';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';

interface IPersonalData {
	onContinue: () => void;
	isEmail: boolean;
	setIsEmail: (arg: boolean) => void;
}
const ActivationOption = (props: IPersonalData) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const { onContinue, isEmail, setIsEmail } = props;
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const style = useStyles();
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'error',
	});

	const sendCode = async () => {
		try {
			setLoading(true);
			setAlert({
				...alert,
				show: false,
			});

			if (isEmail) {
				const { result } = await nipInfonatelAPI.sendEmail();
				if (
					result.description ===
					'Se ha enviado correctamente el correo'
				) {
					onContinue();
				} else {
					setAlert({
						show: true,
						message: result.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				}
			} else {
				const { result } = await nipInfonatelAPI.sendSMS();
				if (result.description === 'Mensaje enviado con exito') {
					onContinue();
				} else {
					setAlert({
						show: true,
						message: result.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				}
			}
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<ModalLoading loading={loading} />

			{/* <div className={style.parrafospace}>
				Te recomendamos que realices el registro de tu token móvil
				Infonavit para que puedas seguir disfrutando de todos los
				servicios que Infonavit te ofrece.
			</div> */}
			{/* <Box
				display={'flex'}
				justifyContent={'center'}
				flexDirection={matches ? 'column-reverse' : 'row'}
			>
				<Box margin={'20px 10px'} width={matches ? '100%' : 250}>
					<CustomButtom
						label={'Registrar Token móvil Infonavit'}
						onClick={() => navigate('/')}
						variant="solid"
					/>
				</Box>
			</Box> */}
			<p className={style.parrafospace}>
				Para crear tu NIP Infonatel deberás seleccionar una de las siguientes opciones
				para recibir un código de confirmación y continuar con tu proceso.
			</p>
			<Box className={style.formControlRadioGroupContainer}>
				<FormControl>
					<RadioGroup
						aria-labelledby="type"
						name="typeRadio"
						value={isEmail}
						onChange={(e) => {
							setIsEmail(
								e.target.value === 'true' ? true : false
							);
						}}
					>
						<FormControlLabel
							value={false}
							label={<p className={style.noMargin}>Por mensaje a tu celular</p>}
							control={<Radio />}
						/>
						<FormControlLabel
							value={true}
							label={<p className={style.noMargin}>Por correo electrónico</p>}
							control={<Radio />}
						/>
					</RadioGroup>
				</FormControl>
			</Box>
			<Box
				sx={{
					marginTop: '50px',
					paddingLeft: '130px',
					paddingRight: '130px',
				}}
			>
				<div style={{ width: '100%' }}>
					<CustomAlert
						message={alert.message}
						severity={alert.severity}
						show={alert.show}
					/>
				</div>
			</Box>
			<Box className={style.containerButtonsEnd}>
				<Box margin={'10px'} width={260}>
					<CustomButtom
						label={'Salir'}
						onClick={() => navigate('/')}
						variant="outlined"
						styles={{ height: '40px' }}
					/>
				</Box>
				<Box margin={'10px'} width={260}>
					<CustomButtom
						label={'Continuar'}
						onClick={() => sendCode()}
						variant="solid"
					/>
				</Box>
			</Box>
		</>
	);
};

export default ActivationOption;
