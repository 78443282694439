import { FormControl, Select, MenuItem, SelectChangeEvent } from '@mui/material';

interface Props {
	options: Array<{ value: string | number; label: JSX.Element | string }>;
	onChange?: (arg: SelectChangeEvent<string | number>) => void;
	id?: string;
	name?: string;
	label?: string;
	value?: string | number;
	placeholder?: string | number;
	disabled?: boolean;
}

const SelectCustom = ({
	value,
	options,
	onChange,
	label,
	placeholder,
	id,
	name,
	disabled,
}: Props) => (
	<FormControl fullWidth>
		<span style={{ color: '#636363', marginBottom: 5 }} className='tag-to-read' aria-label={label}>{label}</span>
		<Select
			disabled={disabled}
			id={id}
			value={value}
			name={name}
			onChange={(e) => (onChange ? onChange(e) : () => { })}
			displayEmpty={true}
			renderValue={
				value !== ''
					? undefined
					: () => <span style={{ color: '#7F7F7F' }}>{placeholder}</span>
			}
			style={{ height: 40 }}
		>
			{options.map((element, index) => (
				<MenuItem key={index} value={element.value}>
					{element.label}
				</MenuItem>
			))}
		</Select>
	</FormControl>
);

export default SelectCustom;
