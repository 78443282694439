import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	containerButtons: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		gap: 4,
		margin: '20px 0px',

		[theme.breakpoints.down('md')]: {
			flexDirection: 'column-reverse',
		},
	},
	buttonItem: {
		width: '100%',
		maxWidth: 250,

		[theme.breakpoints.down('md')]: {
			maxWidth: 'auto',
		},
	},
}));
