import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('144', 'inicio');

export const ENDPOINTS_INICIO: IBitacora = {
	'/validation/datos-contacto': _bitacora.Read(
		'144100',
		'Validación de datos'
	),
	'/credit-perfil': _bitacora.Read(
		'144101',
		'Consulta de crédito'
	),
};
