/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Paper } from '@mui/material';

import { HeaderList } from '../../components/HeaderList';
import ModalLoading from '../../components/ModalLoading';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import CustomAlert from '../../components/CustomAlert';
import CustomButton from '../../components/Button';
import Layout from '../../components/Layout';

import CreditNone from './CreditNone';
import DownloadDoc from './DownloadDoc';
import Info from './Info';

import { apiNotices } from '../../api/modules/notices';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import { RootState } from '../../store';

interface IDataNotice {
	responsable_nombre: string;
	pdf: string;
	aviso: string;
	codigo_respuesta: string;
}

const Notices = () => {
	const navigate = useNavigate();
	const { user, fontSizeLevel } = useSelector((state: RootState) => state.session);
	const { credit } = useSelector((state: RootState) => state.credit);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(0);
	const [mensajeBaja, setMensajeBaja] = useState('');
	const [dataNotices, setDataNotices] = useState<IDataNotice>({
		responsable_nombre: '',
		pdf: '',
		aviso: '',
		codigo_respuesta: '',
	});
	const [alert, setAlert] = useState<string>('');
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	useEffect(() => {
		const handleOnSubmit = async () => {
			setLoading(true);
			try {
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const numero_credito_base64 = await encrypt(
					credit.toString() || '',
					key
				);
				const email_base64 = await encrypt(
					user?.email.toString() || '',
					key
				);
				const { result } = await apiNotices.getNoticesData(
					numero_credito_base64,
					email_base64
				);
				if (['01', '02'].includes(result?.codigo_respuesta)) {
					setPage(1);
				} else if (
					result?.codigo_respuesta &&
					(result?.codigo_respuesta === '00' ||
						result?.codigo_respuesta.toString() === '102')
				) {
					setDataNotices(result);
					setPage(2);
				} else {
					setAlert(
						result?.description || INTERNAL_MESSAGES.ERROR_MESSAGE
					);
				}
			} catch (error: any) {
				setMensajeBaja(
					error?.description || INTERNAL_MESSAGES.ERROR_MESSAGE
				);
			} finally {
				setLoading(false);
			}
		};
		handleOnSubmit();
	}, [credit, user?.email]);

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<>
			<ModalLoading loading={loading} />
			<Layout updateTagText={updateTagText}>
				<div ref={divRef}>
					{page !== 3 && (
						<HeaderList title="Aviso de Suspensión, Retención y Modificación de Descuentos" />
					)}
					{(mensajeBaja || alert) && (
						<Paper
							style={{
								padding: '5% 8%',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							{mensajeBaja && (
								<CustomAlert
									show
									severity="error"
									message={
										<span
											dangerouslySetInnerHTML={{
												__html: mensajeBaja,
											}}
										/>
									}
								/>
							)}
							{alert}
							<CustomButton
								onClick={() => navigate('/')}
								label="Regresar"
								variant="solid"
								styles={{ maxWidth: 200, marginTop: 10 }}
							/>
						</Paper>
					)}
					{page === 1 && <CreditNone setPage={setPage} />}
					{page === 2 && (
						<DownloadDoc
							setPage={setPage}
							DataNotice={dataNotices}
						/>
					)}
					{page === 3 && <Info setPage={setPage} />}
				</div>
			</Layout>
		</>
	);
};

export default Notices;
