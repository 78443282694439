import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		padding: '20px',

		'& > h2': {
            margin: '0',
            padding: '0',
        },

        '& > p': {
            margin: '0',
            padding: '0',
            textAlign: 'justify',
        },
	},
	containerButton: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'row',

		[theme.breakpoints.down('md')]: {
			flexDirection: 'column-reverse',
		},
	},
	button: {
		width: '200px',

		[theme.breakpoints.down('md')]: {
			width: 'auto',
		},
	},
}));
