import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('129', 'devolucion-pagos-exceso');

export const ENDPOINTS_DEVOLUCION_PAGOS_EN_EXCESO: IBitacora = {
	'/valid-curp': _bitacora.Read('129100', 'Validar curp'),
	'/consult-cases': _bitacora.Read('129101', 'Consultar casos'),
	'/availability-retirement': _bitacora.Read(
		'129102',
		'Consultar los retiros disponibles'
	),
	'/valid-data-efirma': _bitacora.Read('129103', 'Validar eFirma'),
	'/valid-clabe': _bitacora.Read('129104', 'Validar clabe interbancaria'),
	'/valid-retirement': _bitacora.Read('129105', 'Validar retiro'),
	'/attached-file': _bitacora.Update('129106', 'Adjuntar documentos'),
	'/reintento-documento': _bitacora.Read('129107', 'Reintento de documento'),
};
