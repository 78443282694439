import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	// MAIN PAIGE CONTAINER
	paperContainer: {
		padding: '1em 2em',
	},
	titleContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		'&>h4': {
			color: theme.palette.info.dark,
		},
	},
	infoContainer: {
		'&>p': {
			display: 'flex',
			alignItems: 'center',
		},
	},

	// CONTACT INFO TAB
	stepLabel: {
		color: '#293990',
		fontWeight: 'bold',
		width: '100%',
		maxWidth: 'max-content',
		[theme.breakpoints.down('sm')]: {
			maxWidth: 'inherit',
		},
	},
	mailformContainer: {
		marginTop: 20,
		display: 'grid',
		gridTemplateColumns: 'repeat(2, 1fr)',
		gridGap: '0 10px',
		gridAutoRows: 'minmax(75px, auto)',
		[theme.breakpoints.down('sm')]: {
			gridTemplateColumns: 'repeat(1, 1fr)',
		},
		'&>*': {
			alignSelf: 'start',
		},
	},
	numbersVerification: {
		width: '100%',
		maxWidth: '600px',
		margin: '0 auto',
	},

	// CONFIRMATION TAB
	confirmationContainer: {
		paddingTop: 25,
		textAlign: 'center',
		'&>svg': {
			fontSize: 65,
		},
		'&>h3': {
			color: theme.palette.info.main,
		},
		'&>.valid-time-label': {
			color: 'gray',
		},
	},

	// CONGRATULATIONS TAB
	congratulationsContainer: {
		textAlign: 'center',
		'&>h3': {
			color: theme.palette.info.main,
		},
	},
	VerificacionLogo: {
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
	},
	VerificacionCodigoTitle: {
		fontSize: '35px',
		textAlign: 'center',
		color: '#293990',
		width: '100%',
		display: 'block',
		clear: 'both',
	},
	VerificacionText: {
		fontSize: '17px',
		textAlign: 'center',
		width: '100%',
	},

	// ACTIONS CONTAINERS
	actionsContainer: {
		display: 'flex',
		justifyContent: 'space-evenly',
		marginTop: 15,
		'&>button': {
			width: '25%',
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			'&>button': {
				width: '100%',
				marginBottom: 10,
			},
		},
	},

	// ACCORDION
	accordionDetailContainer: {
		// DESKTOP VIEW
		[theme.breakpoints.up('sm')]: {
			paddingLeft: '12vw !important',
		},
	},

	// INFO INTO ACCORDIONS
	userInfoContainer: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
	},

	// LOADER CONTAINER
	loaderContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
	containerRequiredFields: {
		marginTop: 20,
		width: '100%',
		padding: 15,
	},
	requiredFieldsLabel: {
		fontWeight: 'bold',
		color: '#293990',
		width: '100%',
		maxWidth: 'max-content',
		[theme.breakpoints.down('sm')]: {
			maxWidth: 'inherit',
		},
	},
	expandedContainer: {
		borderTop: '1px solid #CBCBCB',
		boxShadow: 'none',
		border: 'none',
	},
	tituloAcordion: {
		fontFamily: 'Geomanist, Medium',
		fontSize: '16px',
		fontWeight: 'bold',
	},
	textoNormal: {
		fontFamily: 'Geomanist, Regular',
		fontSize: '16px',
	},

	// ValidateContact
	containerUpdateData: {
		'& p': {
			margin: '16px 0 !important'
		}
	},
	labelheader: {
		fontWeight: 'bold',
		fontSize: 16,
		paddingTop: 40,
		marginBottom: 3,
		textAlign: 'center',
		color: theme.palette.info.dark,
		[theme.breakpoints.down('sm')]: {
			marginLeft: 30,
			marginRight: 30,
		},
	},
	divContainer: {
		display: 'flex',
		padding: '0px 100px',
		'&> div:last-child': {
			textAlign: 'right',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0 20px',
		},
	},
	divContainerCustom: {
		padding: '0px 100px',
		'&>p': {
			color: theme.palette.info.dark,
			padding: '0 20px',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0 20px',
		},
	},
	divItem: {
		width: '50%',
		'&>p': {
			minHeight: '24px',
			padding: '0 20px',
		},
	},
	hr: {
		opacity: 0.3,
		[theme.breakpoints.down('xs')]: {
			marginRight: 18,
			opacity: 0.5,
		},
	},
	link: {
		width: '100%',
		margin: '20px auto',
		textAlign: 'center',
		color: theme.palette.primary.main,
		cursor: 'pointer',
	},
	buttonsAceptDatos: {
		display: 'flex',
		justifyContent: 'center',
		paddingBottom: '50px',
		'&>button': {
			width: '260px',
			margin: '0 20px'
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			'&>button': {
				width: '100%',
				marginBottom: 10,
			},
		},
	},
}));
