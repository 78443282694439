/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { contactRequestAPI } from '../../../../../api/modules/profile';
import { useBitacora } from '../../../../../hooks/useBitacora';
import { CONTACT_ACTIONS } from '../../../../../config/actions-bitacora/Contact-actions';
import { encrypt } from '../../../../../utils/encrypt';
import { INTERNAL_MESSAGES } from '../../../../../config/messageCatalog';
import { defaultReferenceForm, IAlert, reference_base64 } from '../../utils/utils';

export const useContactReference = () => {
	const { user } = useSelector((state: RootState) => state.session);
	const [reference, setReference] = useState(defaultReferenceForm);
	const [errors, setErrors] = useState(defaultReferenceForm);
	const [loader, setLoader] = useState(false);
	const [alert, setAlert] = useState<IAlert>({ message: '', color: 'error' });
	const { setDataOld, regBitacora } = useBitacora();

	const fetchReferenceData = useCallback(async () => {
		try {
			setLoader(true);
			const USER_NSS_BASE64 = await encrypt(
				user?.nss?.toString() || '',
				user?.k || ''
			);

			contactRequestAPI.fetchReferenceInfo
				.post<{ nss: string }, any>('', {
					nss: USER_NSS_BASE64,
				})
				.then((res: any) => {
					if (res?.result) {
						setReference({
							...res?.result,
							name: res?.result?.name
								.replace(/(\\)/gm, '')
								.toString('ascii'),
							firstSurname: res?.result?.firstSurname
								.replace(/(\\)/gm, '')
								.toString('ascii'),
							secondSurname: res?.result?.secondSurname
								.replace(/(\\)/gm, '')
								.toString('ascii'),
						});
						setDataOld(CONTACT_ACTIONS.UPDATE_REFERENCES, {
							...res?.result,
							name: res?.result?.name
								.replace(/(\\)/gm, '')
								.toString('ascii'),
							firstSurname: res?.result?.firstSurname
								.replace(/(\\)/gm, '')
								.toString('ascii'),
							secondSurname: res?.result?.secondSurname
								.replace(/(\\)/gm, '')
								.toString('ascii'),
						});
					}
				})
				.catch((error: any) => {
					setAlert({
						message:
							error?.description ||
							INTERNAL_MESSAGES.ERROR_MESSAGE,
						color: 'error',
					});
				})
				.finally(() => setLoader(false));
		} catch (error: any) {
			setAlert({
				message: error?.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				color: 'error',
			});
			setLoader(false);
		}
	}, [user?.nss]);

	useEffect(() => {
		fetchReferenceData();
	}, [fetchReferenceData]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		setReference({ ...reference, [name]: value });
		if (!value) setErrors({ ...errors, [name]: 'Campo obligatorio' });
		else if (name === 'phoneNumber' && value.length !== 10) {
			setErrors({ ...errors, [name]: 'Teléfono celular a 10 números. ' });
		} else setErrors({ ...errors, [name]: '' });
	};

	const handleConfirm = async () => {
		try {
			setLoader(true);
			const dataOrigin = {
				...reference,
				nss: user?.nss?.toString() || '',
			};
			const data_base64 = await reference_base64(
				dataOrigin,
				user?.k || ''
			);
			contactRequestAPI.updateReference
				.post<
					{
						nss: string;
						name: string;
						firstSurname: string;
						secondSurname: string;
						phoneNumber: string;
					},
					any
				>('', data_base64)
				.then((res) => {
					if (res?.ok) {
						setAlert({
							message: INTERNAL_MESSAGES.APPLICATION_COMPLETED,
							color: 'success',
						});
						regBitacora({
							key: CONTACT_ACTIONS.UPDATE_REFERENCES,
							scheme: contactRequestAPI.updateReference.scheme,
							valorNuevo: reference,
						});
					}
				})
				.catch((e) => {
					setAlert({
						message: INTERNAL_MESSAGES.ERROR_MESSAGE,
						color: 'error',
					});
				})
				.finally(() => setLoader(false));
		} catch (error: any) {}
	};

	const handleClose = () => {
		setAlert({ message: '', color: 'success' });
	};

	return {
		handleConfirm,
		handleClose,
		loader,
		alert,
		handleChange,
		reference,
		errors,
	}
}