/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Grid } from '@mui/material';
import { useStyles } from './styles';

import { useNavigate } from 'react-router-dom';
//Assets
import imgAltanto from '../../../assets/img/mantente-al-tanto.png';
import imgAgendaCita from '../../../assets/img/Agenda-tu-cita.png';
import imgPagaCredit from '../../../assets/img/paga-credito-banner.jpg';
import imgAclaracion from '../../../assets/img/aclaracion.png';
import imgPension from '../../../assets/img/pensionado-banner.png';

const PensionerJudicialProcess = () => {
	const classes = useStyles();

	const navigate = useNavigate();

	return (
		<Grid container spacing={2}>
			<Grid item sm={12} md={12} lg={4}>
				<Paper className={classes.card}>
					<div className={classes.titleCard}>
						<h3>
							¿Tienes problemas con los pagos de tus mensualidades?
						</h3>
					</div>
					<div className={classes.textCard}>
						<p>
							Ingresa a Saldos y Movimientos para conocer el estatus
							de tu crédito. Si tienes problemas de pago, agenda tu
							cita para recibir una asesoría en tu Centro de Servicio
							Infonavit (Cesi) más cercano.
						</p>
						<p>
							¿Identificaste que no se te registró algún pago de tu
							crédito? Levanta una Aclaración de pagos para que
							analicen tu situación.
						</p>
					</div>
				</Paper>
			</Grid>

			<Grid item sm={12} md={12} lg={8}>
				<Grid container spacing={2}>
					<Grid item sm={12} md={6} lg={6}>
						<div
							style={{ height: '100%' }}
							onClick={() =>
								navigate('/mi-credito/saldos-movimientos')
							}
						>
							<img
								src={imgAltanto}
								className={classes.imageStyle}
								alt="Mantente al tanto con toda la información de tu saldo y movimientos de tu credito"
							/>
						</div>
					</Grid>
					<Grid item md={6} lg={6}>
						<Grid item lg={12}>
							<div
								onClick={() =>
									navigate('/contactanos/haz-una-cita')
								}
							>
								<img
									src={imgAgendaCita}
									className={classes.imageStyle}
									alt="Agenda tu cita"
								/>
							</div>
						</Grid>
						<Grid item lg={12} mt={2}>
							<a href="/mi-ahorro/resumen-movimientos-ahorro">
								<img
									src={imgPagaCredit}
									className={classes.imageStyle}
									alt="Paga tu crédito en línea"
								/>
							</a>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Grid container justifyContent="flex-end" mt={2}>
				<Grid
					container
					spacing={2}
					direction="row"
					xs={12}
					sm={12}
					md={12}
					lg={8}
				>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<div
							onClick={() =>
								navigate('/mi-credito/aclaracion-pagos')
							}
						>
							<img
								src={imgAclaracion}
								className={classes.imageStyle}
								alt="¡Realiza la aclaración de un pago aquí!"
							/>
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<div
							onClick={() =>
								navigate(
									'/mi-ahorro/devolucion-fondo-ahorro-72-92'
								)
							}
						>
							<img
								src={imgPension}
								className={classes.imageStyle}
								alt="Solicita la devolución de tu ahorro"
							/>
						</div>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default PensionerJudicialProcess;
