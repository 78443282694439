import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { actions } from '../store/modules/bitacora';
import {
	ApiBitacora,
	getHeadersByReferences,
	getHeadersByScheme,
} from '../api/api-bitacora';
import {
	IRequestBitacora,
	IPropsRegister,
	IRegisterData,
	ITypeBitacora,
} from '../interfaces/bitacora';
import { encodeAllJSONData } from '../utils/encrypt';
import { AxiosRequestHeaders } from 'axios';

interface IPropsBitacora {
	signalToken?: string;
	signalKey?: string;
	identificador?: string;
}

export const useBitacora = () => {
	const dispatch = useDispatch();
	const { user } = useSelector((state: RootState) => state.session);
	const { dataBitacora } = useSelector((state: RootState) => state.bitacora);

	const setDataBitacora = (data: IRegisterData) => {
		const objectos: IRegisterData = Object.keys(data).reduce(
			(arr, current) => {
				return {
					...arr,
					[current]: {
						...data[current],
					},
				};
			},
			{}
		);
		dispatch(
			actions.setData({
				...dataBitacora,
				...objectos,
			})
		);
	};

	const setDataOld = (key: string, valorAnterior: any) => {
		dispatch(
			actions.setData({
				...dataBitacora,
				[key]: {
					valorAnterior,
				},
			})
		);
	};

	const regBitacora = ({ key, valorNuevo, scheme }: IPropsRegister) => {
		try {
			const request: IRequestBitacora = {
				...dataBitacora[key],
				valorNuevo,
				identificador: user?.nss || '',
			};
			encodeAllJSONData(
				{ data: JSON.stringify(request) },
				user?.k || ''
			).then((requestEncrypted) => {
				let headers: AxiosRequestHeaders = {};
				if (user?.token) {
					headers = {
						Authorization: `Bearer ${user?.token}`,
					};
				}
				ApiBitacora({
					data: requestEncrypted,
					headers: {
						...getHeadersByScheme(scheme),
						...headers,
					},
				});
				dispatch(
					actions.setData({
						...dataBitacora,
						[key]: {
							valorAnterior: valorNuevo,
						},
					})
				);
			});
		} catch (error) {}
	};

	const regBitacoraNotOld = (
		scheme: string,
		valorNuevo: any,
		options?: IPropsBitacora
	) => {
		try {
			const valorAnterior = Object.keys(valorNuevo).reduce((acc, key) => {
				acc[key] = 'N/A';
				return acc;
			}, {} as any);
			const request: IRequestBitacora = {
				valorAnterior,
				valorNuevo,
				identificador: options?.identificador || user?.nss || '',
			};
			encodeAllJSONData(
				{ data: JSON.stringify(request) },
				user?.k || options?.signalKey || ''
			).then((requestEncrypted) => {
				let headers: AxiosRequestHeaders = {};
				if (user?.token || options?.signalToken) {
					headers = {
						Authorization: `Bearer ${
							user?.token || options?.signalToken
						}`,
					};
				}
				ApiBitacora({
					data: requestEncrypted,
					headers: {
						...getHeadersByScheme(scheme),
						...headers,
					},
				});
			});
		} catch (error) {}
	};

	const regBitacoraReferences = (
		reference: ITypeBitacora,
		valorNuevo: any = {},
		options?: IPropsBitacora
	) => {
		try {
			const valorAnterior = Object.keys(valorNuevo).reduce((acc, key) => {
				acc[key] = 'N/A';
				return acc;
			}, {} as any);
			const request: IRequestBitacora = {
				valorAnterior,
				valorNuevo,
				identificador: options?.identificador || user?.nss || '',
			};
			encodeAllJSONData(
				{ data: JSON.stringify(request) },
				user?.k || options?.signalKey || ''
			).then((requestEncrypted) => {
				let headers: AxiosRequestHeaders = {};
				if (user?.token || options?.signalToken) {
					headers = {
						Authorization: `Bearer ${
							user?.token || options?.signalToken
						}`,
					};
				}
				ApiBitacora({
					data: requestEncrypted,
					headers: {
						...getHeadersByReferences(reference),
						...headers,
					},
				});
			});
		} catch (error) {}
	};

	const cleanDataBitacora = () => {
		dispatch(actions.clearData());
	};

	const getMaskString = (cadena?: string) => {
		if (cadena) {
			return cadena.replace(/./g, '*');
		}
		return cadena;
	};

	return {
		setDataBitacora,
		setDataOld,
		regBitacora,
		regBitacoraNotOld,
		cleanDataBitacora,
		getMaskString,
		regBitacoraReferences,
	};
};
