/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useStyles } from './styles';
import TextInput from '../../../components/TextInput';
import CustomButton from '../../../components/Button';
import InstrumentoView from './instrumentoData';
import DireccionView from './direccionData';
import { IAddressData, IInstrumentData, MortgageCancellationType } from '.';
import { cancelLetterAPI } from '../../../api/modules/cancellationLetter';
import { CARTA_REG_PUB_ESCRITURA_PUBLICA } from '../../../config/constants';
import { INTERNAL_MESSAGES } from '../../../config/messageCatalog';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { formatDate, formatStringToDateChar } from '../../../utils/dates';
import { states } from '../../../config/states';
import ModalLoading from '../../../components/ModalLoading';
import { encrypt } from '../../../utils/encrypt';
import { keyAPI } from '../../../api/modules/key';
import SelectInput from '../../../components/SelectInput';
import { IAlert } from '../../../interfaces/alert';
import CustomAlert from '../../../components/CustomAlert';
import { useCancellationForm } from '../../../hooks/useCancellationForm';
import { FormCancellation } from '../contextFormCancellation';
import { removeAccents } from '../../../utils/expressions';
import { useBitacora } from '../../../hooks/useBitacora';

interface IDataNotario {
	numeroNotario: string;
	estado: string;
}
interface IPublicRegistroVieww {
	setPage: (data: number) => void;
	dataHipoteca: {
		caso: string;
		fecha_liq: string;
		est_credito: string;
		casollave: string;
		tipificacion: string;
	};
	handlerConsultaCaso: () => void;
	docType: MortgageCancellationType;
}

export const PublicRegistroView = ({
	setPage,
	dataHipoteca,
	handlerConsultaCaso,
	docType,
}: IPublicRegistroVieww) => {
	const { regBitacoraNotOld } = useBitacora();
	const [buttonValidate, setButtonValidate] = useState(true);
	const [loading, setLoading] = useState(false);
	const [addressData, setAddressData] = useState<IAddressData>({
		cp: '',
		estado: '',
		municipio: '',
		colonia: '',
		calle: '',
		noint: '',
		noext: '',
	} as IAddressData);
	const [instrumentData, setInstrumentData] = useState<IInstrumentData>({
		noInstrumento: '',
		date: new Date(),
		folio: '',
		libro: '',
		seccion: '',
	} as IInstrumentData);
	const [dataNotario, setDataNotario] = useState<IDataNotario>({
		numeroNotario: '',
		estado: '',
	});
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { user } = useSelector((state: RootState) => state.session);
	const { credit } = useSelector((state: RootState) => state.credit);
	const [optionsSelect, setOptionsSelect] = useState<{ value: string; text: string }[]>(
		[]
	);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const setData = useCancellationForm()[1];

	const handleChangeInstrument = (target: {
		name: string;
		value: string | Date | null;
	}) => {
		const { name, value } = target;
		setInstrumentData({ ...instrumentData, [name]: value });
	};

	const handleOnSubmit = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(user?.nss.toString() || '', key);
			const credit_base64 = await encrypt(credit || '', key);
			const no_instrumento_base64 = await encrypt(instrumentData.noInstrumento || '', key);
			const fecha_base64 = await encrypt(formatDate('dd.MM.yyyy', new Date((instrumentData.date || new Date()) as Date)).toString() || '', key);
			const folio_base64 = await encrypt(removeAccents(instrumentData.folio) || '', key);
			const libro_base64 = await encrypt(removeAccents(instrumentData.libro) || '', key);
			const seccion_base64 = await encrypt(removeAccents(instrumentData.seccion) || '', key);

			const estado_base64 = await encrypt(removeAccents(addressData.estado) || '', key);
			const colonia_base64 = await encrypt(removeAccents(`${addressData.colonia} ${addressData.municipio} ${addressData.cp}`) || '', key);
			const calle_base64 = await encrypt(removeAccents(`${addressData.calle} ${addressData.noext} ${addressData.noint}`) || '', key);

			const tipo_carta_base64 = await encrypt(CARTA_REG_PUB_ESCRITURA_PUBLICA || '', key);
			const fecha_liq_base64 = await encrypt(formatStringToDateChar(dataHipoteca.fecha_liq, '.') || '', key);
			const caso_llave_base64 = await encrypt(dataHipoteca.casollave || '', key);
			const tipificacion_base64 = await encrypt(dataHipoteca.tipificacion || '', key);
			const est_credito_base64 = await encrypt(removeAccents(dataHipoteca.est_credito) || '', key);

			const numero_notario_base64 = await encrypt(removeAccents(dataNotario.numeroNotario) || '', key);
			const estado_notario_base64 = await encrypt(removeAccents(dataNotario.estado) || '', key);

			const dataValues: FormCancellation = {
				nss: user?.nss.toString() || '',
				numero_credito: credit || '',
				numero_instrumento: removeAccents(instrumentData.noInstrumento) || '',
				libro: removeAccents(instrumentData.libro) || '',
				calle: removeAccents(`${addressData.calle} ${addressData.noext} ${addressData.noint}`) || '',
				colonia: removeAccents(`${addressData.colonia} ${addressData.municipio} ${addressData.cp}`) || '',
				ciudad: removeAccents(addressData.estado) || '',
				seccion: removeAccents(instrumentData.seccion) || '',
				numero_escritura: removeAccents(instrumentData.noInstrumento) || '',
				fecha_elaboracion: formatDate('dd.MM.yyyy', new Date((instrumentData.date || new Date()) as Date)).toString() || '',
				numero_notario: '',
				nombre_notario: '',
				correo_notario: '',
				ciudad_notario: '',
				folio_real: removeAccents(instrumentData.folio) || '',
				nombre_notario_origen: '',
				numero_notario_origen: removeAccents(dataNotario.numeroNotario) || '',
				ciudad_notario_origen: removeAccents(dataNotario.estado) || '',
				tipo_carta: CARTA_REG_PUB_ESCRITURA_PUBLICA || '',
				fecha_liquidacion: formatStringToDateChar(dataHipoteca.fecha_liq, '.') || '',
				clase_operacion: dataHipoteca.casollave || '',
				tipificacion: dataHipoteca.tipificacion || '',
				estado_credito: removeAccents(dataHipoteca.est_credito) || '',
			};
			const data: FormCancellation = {
				nss: nss_base64,
				numero_credito: credit_base64,
				numero_instrumento: no_instrumento_base64,
				fecha_elaboracion: fecha_base64,
				folio_real: folio_base64,
				libro: libro_base64,
				calle: calle_base64,
				colonia: colonia_base64,
				ciudad: estado_base64,
				seccion: seccion_base64,
				numero_escritura: no_instrumento_base64,

				numero_notario: '',
				nombre_notario: '',
				correo_notario: '',
				ciudad_notario: '',

				nombre_notario_origen: '',
				numero_notario_origen: numero_notario_base64,
				ciudad_notario_origen: estado_notario_base64,

				tipo_carta: tipo_carta_base64,
				fecha_liquidacion: fecha_liq_base64,
				clase_operacion: caso_llave_base64,
				tipificacion: tipificacion_base64,
				estado_credito: est_credito_base64,
			};
			cancelLetterAPI
				.creaCarta(data)
				.then((response) => {
					regBitacoraNotOld(cancelLetterAPI.schemeUrl, dataValues);
					handlerConsultaCaso();
					setData(dataValues);
					setPage(6);
				})
				.catch((err) => {
					setAlert({
						show: true,
						message:
							err.description ||
							INTERNAL_MESSAGES.ERROR_GETTING_DATA,
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error) { }
	};

	useEffect(() => {
		if (
			instrumentData.noInstrumento &&
			instrumentData.libro &&
			instrumentData.seccion &&
			instrumentData.date &&
			instrumentData.folio &&
			addressData.calle &&
			addressData.colonia &&
			addressData.estado &&
			dataHipoteca.fecha_liq &&
			dataHipoteca.casollave &&
			dataHipoteca.tipificacion &&
			dataHipoteca.est_credito &&
			dataNotario.numeroNotario &&
			dataNotario.estado
		) {
			setButtonValidate(false);
		} else {
			setButtonValidate(true);
		}
	}, [instrumentData, addressData, dataHipoteca, dataNotario]);

	useEffect(() => {
		let newArray: { value: string; text: string }[] = [];
		states.forEach((state: { name: string; code: string }) => {
			newArray.push({ value: state.code, text: state.name });
		});

		setOptionsSelect(newArray);
	}, [states]);

	return (
		<>
			<InstrumentoView
				data={instrumentData}
				changeData={handleChangeInstrument}
				docType={docType}
			/>
			<DireccionView data={addressData} changeData={setAddressData} />
			<Grid container columns={12}>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<hr
						style={{
							opacity: 0.4,

							marginTop: 15,
							marginBottom: 15,
						}}
					/>
				</Grid>
			</Grid>
			<p className={`${classes.noMargin} ${classes.maxContent}`}>
				<b>Datos del notario</b>
			</p>
			<div style={{ paddingLeft: matches ? 0 : 200, paddingTop: 10 }}>
				<TextInput
					id="numeroNotario"
					name="numeroNotario"
					onChange={(event) => {
						const { value } = event.target;
						if (!isNaN(Number(value)) && Number(value) >= 0) {
							setDataNotario({
								...dataNotario,
								numeroNotario: value,
							});
						}
					}}
					value={dataNotario.numeroNotario}
					isRequired
					label="Número de la notaría que originó"
					maxLength={30}
				/>
			</div>
			<div style={{ paddingLeft: matches ? 0 : 200, paddingTop: 10 }}>
				<SelectInput
					data-testid="estado"
					id="estado"
					name="estado"
					label="Estado donde se localiza la notaría"
					isRequired
					value={dataNotario.estado}
					helperText={''}
					onChange={(e: any) => {
						setDataNotario({ ...dataNotario, estado: e.target.value });
					}}
					options={optionsSelect}
					placeholder="Selecciona"
				/>
			</div>

			<ModalLoading loading={loading} />
			<div className={classes.contentFileDataButtons}>
				<CustomAlert
					data-testid="mensageAlerttwo"
					message={alert.message}
					severity={alert.severity}
					show={alert.show}
					onClose={() => {
						setAlert({
							show: false,
							message: '',
							severity: alert.severity,
						});
					}}
				/>
			</div>
			<div className={classes.contentFileDataButtons}>
				<CustomButton
					onClick={() => {
						setPage(2);
					}}
					variant="outlined"
					styles={{
						width: 260,
						marginInline: 10,
					}}
					label="Regresar"
				/>
				<CustomButton
					onClick={handleOnSubmit}
					disabled={buttonValidate}
					variant="solid"
					styles={{
						width: 260,
						marginInline: 10,
					}}
					label="Continuar"
				/>
			</div>
			<Grid container columns={12}>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<hr
						style={{
							opacity: 0.4,
							marginTop: 50,
							marginBottom: 0,
						}}
					/>
					<h3
						style={{
							marginLeft: matches ? 10 : 20,
							marginTop: 8,
							color: '#293990',
							fontSize: matches ? 16 : 18,
							paddingBottom: 15,
						}}
						className={classes.maxContent}
					>
						* Datos obligatorios
					</h3>
				</Grid>
			</Grid>
		</>
	);
};

export default PublicRegistroView;
