/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import { Paper, Box, Link } from '@mui/material';
import Layout from '../../../../../components/Layout';
import CustomAlert from '../../../../../components/CustomAlert';
import {
	INIT_PROFILE,
	URL_APLICACION,
} from '../../../../../config/miucConstants';
import CustomButton from '../../../components/CustomButton';
import ModalCancelWFI from '../../../components/ModalCancelWFI';
import { HeaderList } from '../../../../../components/HeaderList';
import { IProfileResponse } from '../../../../../api/modules/MIUC';
import { useStyles } from './styles/styles';

import { useWaitingDocument } from './hooks/useWaitingDocument';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

interface IWaitingDocument {
	data: IProfileResponse;
}
const WaitingDocument = (props: IWaitingDocument) => {
	const { data } = props;
	const classes = useStyles();
	const {
		getUrlWFI,
		openModalCancel,
		setOpenModalCancel,
		selectOption,
		setSelectOption,
		alert,
		setAlert,
		alertDocument,
		setAlertDocument,
	} = useWaitingDocument(props);
	const { fontSizeLevel } = useSelector((state: RootState) => state.session);
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<Layout updateTagText={updateTagText}>
			<div ref={divRef}>
				<HeaderList
					title="Me interesa un crédito"
					list={[
						'En este servicio puedes dar seguimiento a tu solicitud de crédito en línea.',
					]}
				/>
				<Paper>
					<Box padding={3} maxWidth={900} margin="auto">
						<h3 className={classes.textCenter}>
							En espera de documentación
						</h3>
						{data.casoActivo?.tipificacion ===
							INIT_PROFILE.MEJORAVIT ? (
							<p className={classes.margin20p0p}>
								Para continuar con tu trámite de crédito, tu
								expediente de crédito debe quedar integrado
								totalmente para que pueda ser enviado para su
								validación, asegúrate de tener tus documentos
								digitalizados, antes de continuar.
							</p>
						) : (
							<p className={classes.margin20p0p}>
								Para concluir tu trámite de crédito, tu
								expediente de crédito deberá quedar integrado
								totalmente para que pueda ser enviado para su
								validación, ten a la mano tus documentos
								digitalizados.
							</p>
						)}
						<CustomAlert
							show={alertDocument.show}
							message={alertDocument.message}
							severity={alertDocument.severity}
						/>
						{data.urlWFI && <Box maxWidth={250} display="flex" margin="40px auto">
							<CustomButton
								label="Continuar"
								onClick={() => window.open(data.urlWFI, '_blank')}
								variant="solid"
							/>
						</Box>
						}
						<Link
							className={classes.linkPaddingDisplay}
							onClick={() => {
								setAlert({
									show: false,
									severity: 'error',
									message: '',
								});
								setAlertDocument({
									show: false,
									severity: 'error',
									message: '',
								});
								setOpenModalCancel(!openModalCancel);
							}}
						>
							Cancelar mi solicitud de crédito
						</Link>
					</Box>
				</Paper>
				<ModalCancelWFI
					selectOption={selectOption}
					setSelectOption={setSelectOption}
					openModal={openModalCancel}
					setOpenModal={() => {
						setOpenModalCancel(!openModalCancel);
						setSelectOption('');
						setAlert({
							show: false,
							severity: 'error',
							message: '',
						});
					}}
					onContinue={() => getUrlWFI(URL_APLICACION.CANCELACION_WFI)}
					hasError={alert}
					isMejoravit={
						data.casoActivo?.tipificacion === INIT_PROFILE.MEJORAVIT
					}
				/>
			</div>
		</Layout>
	);
};

export default WaitingDocument;
