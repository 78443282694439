/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState, useEffect, useRef } from "react";
import { useNavigation } from "../../hooks/useNavigation"
import { useGeneralStyles } from "../styles/general.styles";
import { useMejoraHogar } from "./useMejoraHogar";
import { MIUC_PROCESS_VIEWS } from "../../constants/process";
import { SelectionCredit } from "../../components/SelectionCredit/selectionCredit";
import Layout from "../../../../components/Layout";
import { Box, Grid, Paper } from "@mui/material";
import { AmpliarReparar } from "../AmpliarReparar/ampliarReparar";
import ViewPoints from "../../components/ViewPoints";
import { ConstruYO } from "../ConstruYO/construYO";
import ErrnoPage from "../../../CreditRequest/ErrnoPage";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

export const MejoraHogar = () => {
	const { fontSizeLevel } = useSelector((state: RootState) => state.session);
	const { view, onSelect, extraMessage, errorMessage } = useMejoraHogar();
	const generalStyle = useGeneralStyles();
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	const size = useMemo(() => {
		if (view !== MIUC_PROCESS_VIEWS.SELECTION_CREDIT) {
			return 12;
		} else {
			return 8;
		}
	}, [view]);

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<Layout updateTagText={updateTagText}>
			<Box className={generalStyle.container} ref={divRef}>
				<Grid container spacing={2}>
					{
						errorMessage !== '' &&
						<Paper>
							<ErrnoPage msgString={errorMessage} />
						</Paper>
					}
					{
						errorMessage === '' &&
						<>
							<Grid item xs={12} md={size}>
								{view === MIUC_PROCESS_VIEWS.SELECTION_CREDIT && (
									<Paper>
										<SelectionCredit onSelectCredit={onSelect} extraMessage={extraMessage} />
									</Paper>
								)}
								{view === MIUC_PROCESS_VIEWS.MEJORAVIT && (
									<AmpliarReparar />
								)}
								{view === MIUC_PROCESS_VIEWS.AUTOPRODUCCION && (
									<ConstruYO />
								)}
							</Grid>
							{
								view === MIUC_PROCESS_VIEWS.SELECTION_CREDIT &&
								<Grid item xs={12} md={4}>
									<Box sx={{ textAlign: 'center' }}>
										<ViewPoints />
									</Box>
								</Grid>
							}
						</>
					}
				</Grid>
			</Box>
		</Layout>
	);
}