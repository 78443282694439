import { useState, useEffect } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useStyles } from './styles';
import CustomButton from '../../../components/Button';
import InstrumentoView from './instrumentoData';
import DireccionView from './direccionData';
import { IAddressData, IInstrumentData, MortgageCancellationType } from '.';
import ModalLoading from '../../../components/ModalLoading';
import { keyAPI } from '../../../api/modules/key';
import { encrypt } from '../../../utils/encrypt';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { formatDate, formatStringToDateChar } from '../../../utils/dates';
import { INTERNAL_MESSAGES } from '../../../config/messageCatalog';
import { CARTA_REG_PUB_INSTRUMENTO_PRIVADO } from '../../../config/constants';
import { IAlert } from '../../../interfaces/alert';
import CustomAlert from '../../../components/CustomAlert';
import { useCancellationForm } from '../../../hooks/useCancellationForm';
import { FormCancellation } from '../contextFormCancellation';
import { cancelLetterAPI } from '../../../api/modules/cancellationLetter';
import { removeAccents } from '../../../utils/expressions';
import { useBitacora } from '../../../hooks/useBitacora';

interface IPrivRegistroView {
	setPage: (data: number) => void;
	dataHipoteca: {
		caso: string;
		fecha_liq: string;
		est_credito: string;
		casollave: string;
		tipificacion: string;
	};
	handlerConsultaCaso: () => void;
	docType: MortgageCancellationType;
}

export const PrivRegistroView = ({
	setPage,
	dataHipoteca,
	handlerConsultaCaso,
	docType,
}: IPrivRegistroView) => {
	const { regBitacoraNotOld } = useBitacora();
	const [buttonValidate, setButtonValidate] = useState(true);
	const [instrumentData, setInstrumentData] = useState<IInstrumentData>({
		noInstrumento: '',
		date: new Date(),
		folio: '',
		libro: '',
		seccion: '',
	} as IInstrumentData);
	const [addressData, setAddressData] = useState<IAddressData>({
		cp: '',
		estado: '',
		municipio: '',
		colonia: '',
		calle: '',
		noint: '',
		noext: '',
	} as IAddressData);
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [loading, setLoading] = useState(false);
	const { user } = useSelector((state: RootState) => state.session);
	const { credit } = useSelector((state: RootState) => state.credit);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const setData = useCancellationForm()[1];

	const handleChange = (target: { name: string; value: string | Date | null }) => {
		const { name, value } = target;
		setInstrumentData({ ...instrumentData, [name]: value });
	};

	const handleOnSubmit = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();

			const nss_base64 = await encrypt(user?.nss.toString() || '', key);
			const numero_credito_base64 = await encrypt(credit || '', key);
			const numero_instrumento_base64 = await encrypt(removeAccents(instrumentData.noInstrumento) || '', key);
			const fecha_elaboracion_base64 = await encrypt(formatDate('dd.MM.yyyy', new Date((instrumentData.date || new Date()) as Date)).toString(), key);
			const folio_base64 = await encrypt(removeAccents(instrumentData.folio) || '', key);
			const libro_base64 = await encrypt(removeAccents(instrumentData.libro) || '', key);
			const seccion_base64 = await encrypt(removeAccents(instrumentData.seccion) || '', key);

			const calle_base64 = await encrypt(removeAccents(`${addressData.calle} ${addressData.noext} ${addressData.noint}`) || '', key);
			const colonia_base64 = await encrypt(removeAccents(`${addressData.colonia} ${addressData.municipio} ${addressData.cp}`) || '', key);
			const ciudad_base64 = await encrypt(removeAccents(addressData.estado) || '', key);

			const tipo_carta_base64 = await encrypt(CARTA_REG_PUB_INSTRUMENTO_PRIVADO || '', key);
			const fecha_liquidacion_base64 = await encrypt(formatStringToDateChar(dataHipoteca.fecha_liq, '.'), key);
			const clase_operacion_base64 = await encrypt(dataHipoteca.casollave || '', key);
			const tipificacion_base64 = await encrypt(dataHipoteca.tipificacion || '', key);
			const estado_credito_base64 = await encrypt(dataHipoteca.est_credito ? removeAccents(dataHipoteca.est_credito) : '', key);

			const dataValues: FormCancellation = {
				nss: user?.nss ? user?.nss.toString() : '',
				numero_credito: credit ? credit : '',
				numero_instrumento: removeAccents(instrumentData.noInstrumento) || '',
				libro: removeAccents(instrumentData.libro) || '',
				calle: removeAccents(`${addressData.calle} ${addressData.noext} ${addressData.noint}`) || '',
				colonia: removeAccents(`${addressData.colonia} ${addressData.municipio} ${addressData.cp}`) || '',
				ciudad: removeAccents(addressData.estado) || '',
				seccion: removeAccents(instrumentData.seccion) || '',
				numero_escritura: removeAccents(instrumentData.noInstrumento) || '',
				fecha_elaboracion: formatDate('dd.MM.yyyy', new Date((instrumentData.date || new Date()) as Date)).toString(),
				numero_notario: '',
				nombre_notario: '',
				correo_notario: '',
				ciudad_notario: '',
				folio_real: instrumentData.folio || '',
				nombre_notario_origen: '',
				numero_notario_origen: '',
				ciudad_notario_origen: '',
				tipo_carta: CARTA_REG_PUB_INSTRUMENTO_PRIVADO || '',
				fecha_liquidacion: formatStringToDateChar(dataHipoteca.fecha_liq, '.'),
				clase_operacion: dataHipoteca.casollave || '',
				tipificacion: dataHipoteca.tipificacion || '',
				estado_credito: removeAccents(dataHipoteca.est_credito) || '',
			};
			const dataFlag: FormCancellation = {
				nss: nss_base64,
				numero_credito: numero_credito_base64,
				numero_instrumento: numero_instrumento_base64,
				fecha_elaboracion: fecha_elaboracion_base64,
				folio_real: folio_base64,
				libro: libro_base64,
				calle: calle_base64,
				colonia: colonia_base64,
				ciudad: ciudad_base64,
				seccion: seccion_base64,
				numero_escritura: numero_instrumento_base64,

				numero_notario: '',
				nombre_notario: '',
				correo_notario: '',
				ciudad_notario: '',

				nombre_notario_origen: '',
				numero_notario_origen: '',
				ciudad_notario_origen: '',

				tipo_carta: tipo_carta_base64,
				fecha_liquidacion: fecha_liquidacion_base64,
				clase_operacion: clase_operacion_base64,
				tipificacion: tipificacion_base64,
				estado_credito: estado_credito_base64,
			};
			cancelLetterAPI
				.creaCarta(dataFlag)
				.then((response) => {
					if (response) {
						regBitacoraNotOld(cancelLetterAPI.schemeUrl, dataValues);
						handlerConsultaCaso();
						setData({ ...dataValues, noCaso: response.noCaso });
						setPage(4);
					}
				})
				.catch((err) => {
					setAlert({
						show: true,
						message:
							err.description ||
							INTERNAL_MESSAGES.ERROR_GETTING_DATA,
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (
			instrumentData.noInstrumento &&
			instrumentData.libro &&
			instrumentData.seccion &&
			instrumentData.folio &&
			addressData.calle &&
			addressData.colonia &&
			addressData.estado &&
			addressData.noext &&
			dataHipoteca.fecha_liq &&
			dataHipoteca.casollave &&
			dataHipoteca.tipificacion &&
			dataHipoteca.est_credito
		) {
			setButtonValidate(false);
		} else {
			setButtonValidate(true);
		}
	}, [instrumentData, addressData, dataHipoteca]);

	return (
		<>
			<ModalLoading loading={loading} />
			<InstrumentoView
				data={instrumentData}
				changeData={handleChange}
				docType={docType}
			/>
			<DireccionView data={addressData} changeData={setAddressData} />

			<div className={classes.contentFileDataButtons}>
				<CustomAlert
					data-testid="mensageAlerttwo"
					message={alert.message}
					severity={alert.severity}
					show={alert.show}
					onClose={() => {
						setAlert({
							show: false,
							message: '',
							severity: alert.severity,
						});
					}}
				/>
			</div>
			<div className={classes.contentFileDataButtons}>
				<CustomButton
					onClick={() => {
						setPage(2);
					}}
					variant="outlined"
					styles={{
						width: 260,
						marginInline: 10,
					}}
					label="Regresar"
				/>
				<CustomButton
					onClick={handleOnSubmit}
					disabled={buttonValidate}
					variant="solid"
					styles={{
						width: 260,
						marginInline: 10,
					}}
					label="Continuar"
				/>
			</div>
			<Grid container columns={12}>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<hr
						style={{
							opacity: 0.4,
							marginTop: 50,
							marginBottom: 0,
						}}
					/>
					<h3
						style={{
							marginLeft: matches ? 10 : 20,
							marginTop: 8,
							color: '#293990',
							fontSize: matches ? 16 : 18,
						}}
						className={classes.maxContent}
					>
						* Datos obligatorios
					</h3>
				</Grid>
			</Grid>
		</>
	);
};

export default PrivRegistroView;
