import {
	Pagination,
	Stack,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead,
	Box,
	TableSortLabel,
} from '@mui/material';
import { useState } from 'react';
import { Styles } from './useStyles';

interface Props {
	data: Array<Array<string | number>>;
	columns: Array<string>;
	widthColum?: Array<number | string>;
	rows_per_page?: number;
	orderByCol?: boolean;
	hide_pagination?: boolean;
	no_found_title?: string;
}

const TableCustom = ({
	data,
	columns,
	rows_per_page,
	orderByCol,
	widthColum,
	hide_pagination,
	no_found_title,
}: Props) => {
	const classes = Styles();
	const [pageNumber, setPageNumber] = useState(1);
	const [order, setOrder] = useState<'asc' | 'desc' | undefined>('asc');
	const [orderBy, setOrderBy] = useState(0);

	const ROWS_PER_PAGE = rows_per_page ? rows_per_page : 4;

	const handleChageSort = (index: number) => {
		const isAsc = orderBy === index && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(index);
	};

	function stableSort<T>(
		array: readonly T[],
		comparator: (a: T, b: T) => number
	) {
		const stabilizedThis = array.map(
			(el, index) => [el, index] as [T, number]
		);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) {
				return order;
			}
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}

	function getComparator<Key extends keyof any>(
		order: 'asc' | 'desc' | undefined,
		orderBy: Key
	): (
		a: { [key in Key]: number | string },
		b: { [key in Key]: number | string }
	) => number {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}

	return (
		<>
			<TableContainer>
				<Table sx={{ minWidth: 700 }} stickyHeader size="small">
					<TableHead>
						<TableRow>
							{orderByCol
								? columns.map((column, index) => (
									<TableCell
										key={index}
										className={classes.cell}
										component="p"
										style={{
											verticalAlign: 'baseline',
											width: widthColum
												? widthColum[index]
												: 'auto',
											fontSize: '15px !important'
										}}
									>
										<TableSortLabel
											active={orderBy === index}
											direction={
												orderBy === index
													? order
													: 'asc'
											}
											onClick={() =>
												handleChageSort(index)
											}
										>
											{column}
										</TableSortLabel>
									</TableCell>
								))
								: columns.map((column, index) => (
									<TableCell
										key={index}
										component="p"
										className={classes.cell}
										style={{
											verticalAlign: 'baseline',
											width: widthColum
												? widthColum[index]
												: 'auto',
										}}
									>
										{column}
									</TableCell>
								))}
						</TableRow>
					</TableHead>
					<TableBody>
						{data.length > 0 ? (
							<>
								{stableSort(data, getComparator(order, orderBy))
									.slice(
										(pageNumber - 1) * ROWS_PER_PAGE,
										(pageNumber - 1) * ROWS_PER_PAGE +
										ROWS_PER_PAGE
									)
									.map((dataRow, index) => {
										return (
											<TableRow key={index}>
												{dataRow.map((row, xndex) => (
													<TableCell
														key={xndex}
														className={
															classes.tableCell
														}
														component="p"
													>
														{row}
													</TableCell>
												))}
											</TableRow>
										);
									})}
							</>
						) : (
							<TableRow>
								<TableCell
									colSpan={columns.length}
									style={{
										textAlign: 'center',
										padding: '10px 0px',
									}}
								>
									<label>
										{no_found_title
											? no_found_title
											: 'No hay información disponible'}
									</label>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<Box
				display={hide_pagination ? 'none' : 'flex'}
				justifyContent="center"
				flex={1}
				padding={1}
				margin={2}
			>
				<Stack spacing={2}>
					<Pagination
						color="primary"
						count={Math.ceil(data.length / ROWS_PER_PAGE)}
						variant="text"
						shape="rounded"
						onChange={(e, page) => setPageNumber(page)}
					/>
				</Stack>
			</Box>
		</>
	);
};

export default TableCustom;
