/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Grid, useMediaQuery, IconButton } from '@mui/material';
import TextInput from '../../../components/TextInput';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { IAddressData } from '.';
import { states } from '../../../config/states';
import SelectInput from '../../../components/SelectInput';
import { isNumber } from '../../../utils/expressions';
import { keyAPI } from '../../../api/modules/key';
import { encrypt } from '../../../utils/encrypt';
import { contactRequestAPI } from '../../../api/modules/profile';
import { useStyles } from './styles';

interface IDireccionView {
	data: IAddressData;
	changeData: React.Dispatch<React.SetStateAction<IAddressData>>;
}

export const DireccionView = ({ data, changeData }: IDireccionView) => {
	const classes = useStyles();
	const theme = useTheme();
	const [flagOpen, setFlagOpen] = useState(0);
	const [open, setOpen] = React.useState(false);
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [optionsSelect, setOptionsSelect] = useState<{ value: string; text: string }[]>(
		[]
	);
	const handleTooltipClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		let newArray: { value: string; text: string }[] = [];
		states.forEach((state: { name: string; code: string }) => {
			newArray.push({ value: state.name, text: state.name });
		});

		setOptionsSelect(newArray);
	}, [states]);

	useEffect(() => {
		getAddressCatalog(data.cp);
	}, [data.cp]);

	const getAddressCatalog = async (_cp: string) => {
		if (_cp.length === 5)
			try {
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const ZIPCODE_BASE64 = await encrypt(_cp || '', key);

				contactRequestAPI.fetchAddressCatalog
					.post<{ cp: string }, any>('', {
						cp: ZIPCODE_BASE64,
					})
					.then((res: any) => {
						let _estado: string = res?.result?.denominacionSociedad
							? res?.result?.denominacionSociedad
							: '';

						let _municipio: string = res?.result?.delegacion
							? res?.result?.delegacion
							: '';

						changeData({
							...data,
							municipio: _municipio,
							estado: _estado,
						});
					})
					.catch();
			} catch (error) { }
	};

	const handleTooltipOpen = () => {
		if (flagOpen === 1) {
			setOpen(false);
			setFlagOpen(-1);
		} else {
			setOpen(true);
			setFlagOpen(+1);
		}
	};

	const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} arrow classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.arrow}`]: {
			color: theme.palette.common.white,
		},
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.common.white,
			color: theme.palette.common.black,
			fontSize: 14,
		},
	}));

	return (
		<div>
			<Grid container columns={12}>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<hr
						style={{
							opacity: 0.4,

							marginTop: 15,
							marginBottom: 15,
						}}
					/>
				</Grid>
			</Grid>
			<p className={`${classes.noMargin} ${classes.maxContent}`}>
				<b><span>Dirección de la vivienda</span></b>
				<BootstrapTooltip
					PopperProps={{
						disablePortal: true,
					}}
					onClose={handleTooltipClose}
					open={open}
					disableFocusListener
					disableHoverListener
					disableTouchListener
					title={<p className={classes.noMargin}>Escribe la dirección exactamente igual como viene en tu escritura.</p>}
					placement={matches ? 'bottom' : 'right'}
					id="1"
				>
					<IconButton
						aria-label="add an alarm"
						onClick={handleTooltipOpen}
						size="small"
						disableRipple
						disableFocusRipple
						component="span"
					>
						<InfoIcon fontSize="small" sx={{ ml: 0.5, color: '#D1001F' }} />
					</IconButton>
				</BootstrapTooltip>
			</p>
			<div
				style={{
					paddingLeft: matches ? 0 : 200,
					paddingTop: 10,
					display: 'flex',
					flexWrap: 'wrap',
				}}
			>
				<div
					style={{
						width: matches ? '100%' : '32%',
						marginRight: matches ? 0 : 10,
					}}
				>
					<TextInput
						id="cp"
						name="cp"
						onChange={(event) => {
							isNumber(event.target.value) &&
								event.target.value.length <= 5 &&
								changeData({
									...data,
									[event.target.name]: event.target.value,
								});
						}}
						value={data.cp}
						label="C.P."
						isRequired
					/>
				</div>
				<div
					style={{
						width: matches ? '100%' : '32%',
						marginRight: matches ? 0 : 10,
					}}
				>
					<SelectInput
						data-testid="estado"
						id="estado"
						name="estado"
						label="Estado"
						isRequired
						value={data.estado}
						disabled={
							data.cp === ''
								? true
								: false
						}
						helperText={''}
						onChange={(event: any) => {
							changeData({
								...data,
								[event.target.name]: event.target.value,
							});
						}}
						options={optionsSelect}
						placeholder="Selecciona"
					/>
				</div>
				<div
					style={{
						width: matches ? '100%' : '32%',
						marginRight: matches ? 0 : 10,
					}}
				>
					<TextInput
						id="municipio"
						name="municipio"
						onChange={(event) => {
							changeData({
								...data,
								[event.target.name]: event.target.value,
							});
						}}
						disabled={
							data.cp === '' && data.estado === ''
								? true
								: false
						}
						value={data.municipio}
						isRequired
						label="Municipio o delegación"
					/>
				</div>
			</div>
			<div
				style={{
					paddingLeft: matches ? 0 : 200,
					paddingTop: 10,
					display: 'flex',
					flexWrap: 'wrap',
				}}
			>
				<div
					style={{
						width: matches ? '100%' : '32%',
						marginRight: matches ? 0 : 10,
					}}
				>
					<TextInput
						id="colonia"
						name="colonia"
						onChange={(event) => {
							changeData({
								...data,
								[event.target.name]: event.target.value,
							});
						}}
						value={data.colonia}
						label="Colonia"
						disabled={
							data.cp === '' && data.estado === '' && data.municipio === ''
								? true
								: false
						}
						isRequired
					/>
				</div>
				<div
					style={{
						width: matches ? '100%' : '32%',
						marginRight: matches ? 0 : 10,
					}}
				>
					<TextInput
						id="calle"
						name="calle"
						onChange={(event) => {
							changeData({
								...data,
								[event.target.name]: event.target.value,
							});
						}}
						disabled={
							data.cp === '' && data.estado === '' && data.municipio === '' && data.colonia === ''
								? true
								: false
						}
						value={data.calle}
						label="Calle"
						isRequired={true}
					/>
				</div>
				<div
					style={{
						width: matches ? '48%' : '15%',
						marginRight: matches ? 5 : 12,
					}}
				>
					<TextInput
						id="noext"
						name="noext"
						onChange={(event) => {
							changeData({
								...data,
								[event.target.name]: event.target.value,
							});
						}}
						value={data.noext}
						label="Num. Ext."
						disabled={
							data.cp === '' && data.estado === '' && data.municipio === '' && data.colonia === '' && data.calle === ''
								? true
								: false
						}
						isRequired={true}
					/>
				</div>
				<div
					style={{
						width: matches ? '48%' : '15%',
					}}
				>
					<TextInput
						id="noint"
						name="noint"
						onChange={(event) => {
							changeData({
								...data,
								[event.target.name]: event.target.value,
							});
						}}
						value={data.noint}
						label="Num. Int."
					/>
				</div>
			</div>
		</div>
	);
};

export default DireccionView;
