// RESOURCES
import React, { useState } from 'react';
import {
	Box,
	FormControl,
	FormControlLabel,
	RadioGroup,
	Radio,
} from '@mui/material';
// COMPONENTS
import CustomButton from '../../../components/Button';
// ASSETS
import { useStyles } from './styles';
import { unpairDeviceAPI } from '../../../api/modules/unpairDevice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { keyAPI } from '../../../api/modules/key';
import { encrypt } from '../../../utils/encrypt';
import ModalLoading from '../../../components/ModalLoading';
import { IAlert } from '../../../interfaces/alert';
import CustomAlert from '../../../components/CustomAlert';
import { PAGES_UNPAIR_DEVICE } from '../utils/pages.utils';

interface Props {
	setPage: (data: number) => void; //Prop para setear la vista
	setDevice: (data: number) => void; //Prop para elegir el medio de desvinculación
}

const WayToUnlink = ({ setPage, setDevice }: Props) => {
	const { user } = useSelector((state: RootState) => state.session);
	const [loading, setLoading] = useState(false);
	const [typeInformation, setTypeInformation] = useState(0); //Toma el valor de los radiobutons para definir flujo
	const classes = useStyles();
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'error',
	});

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setTypeInformation(parseInt(value));
		setDevice(parseInt(value));
	};

	const SendCodeDevice = async () => {
		try {
			setLoading(true);
			setAlert({
				...alert,
				show: false,
			});

			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_encript = await encrypt(user?.nss.toString() || '', key);
			if (typeInformation === 1) {
				await unpairDeviceAPI.sendSMS(nss_encript);
			}
			if (typeInformation === 2) {
				await unpairDeviceAPI.sendEmail(nss_encript);
			}
			// setPage(2);
			setPage(3);
		} catch (error) {
			// setPage(2);
			setPage(3);
			setAlert({
				show: true,
				message:
					'Ha ocurrido un error al obtener los datos, intente nuevamente',
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<ModalLoading loading={loading} />
			<div className={classes.container}>
				<div className={classes.panelAsociacion}>
					<p
						style={{
							// color: '#7F7F7F',
							color: '#747474',
							fontSize: 16,
							marginLeft: -50,
							fontWeight: 420,
						}}
					>
						¿Por cuál medio te gustaría desvincular tu dispositivo?
					</p>
					<div className={classes.radioButton}>
						<FormControl>
							<RadioGroup
								aria-labelledby="type"
								name="typeRadio"
								value={typeInformation}
								onChange={handleChange}
							>
								<FormControlLabel
									value={1}
									label="Por SMS"
									control={<Radio />}
								/>
								<FormControlLabel
									value={2}
									label="Por correo electrónico"
									control={<Radio />}
								/>
							</RadioGroup>
						</FormControl>
					</div>
				</div>

				<CustomAlert
					message={alert.message}
					severity={alert.severity}
					show={alert.show}
				/>

				<Box className={classes.containerButtons}>
					<div className={classes.custombuttom}>
						<CustomButton
							label={'Regresar'}
							onClick={() => {
								setPage(PAGES_UNPAIR_DEVICE.CONFIRM_PASSWORD);
							}}
							styles={{ width: '260px', height: '100%' }}
							variant="outlined"
						/>
					</div>
					<div className={classes.custombuttom}>
						<CustomButton
							onClick={SendCodeDevice}
							styles={{ width: '260px', height: '100%' }}
							variant="solid"
							label="Continuar"
							disabled={typeInformation === 0 ? true : false}
						/>
					</div>
				</Box>
			</div>
		</>
	);
};

export default WayToUnlink;
