import { Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { HeaderList } from '../../components/HeaderList';
import Layout from '../../components/Layout';
import ValeHome from './valeHome';
import { useStyles } from './styles';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { apiHomeMeasure } from '../../api/modules/homeMeasure';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { keyAPI } from '../../api/modules/key';
import ModalLoading from '../../components/ModalLoading';
import { IAlert } from '../../interfaces/alert';
import { encrypt } from '../../utils/encrypt';
import CustomAlert from '../../components/CustomAlert';

interface IDataAnterior {
	data?: string;
}

const HomeMeasure = () => {
	const { user } = useSelector((state: RootState) => state.session);
	const [dataStatus, setDataStatus] = useState<IDataAnterior>({});
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	useEffect(() => {
		const handleOnSubmit = async () => {
			setLoading(true);
			try {
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const nss_base64 = await encrypt(user?.nss.toString() || '', key);
				apiHomeMeasure
					.getStatus(nss_base64)
					.then((res: any) => {
						setDataStatus(res);
					})
					.catch((err: any) => {
						setAlert({
							show: true,
							message:
								err && err.description
									? err.description
									: INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
					})
					.finally(() => setLoading(false));
			} catch (error: any) {
				setAlert({
					show: true,
					message:
						error && error.description
							? error.description
							: INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
			}
		};

		handleOnSubmit();
	}, [user?.nss]);

	const classes = useStyles();
	return (
		<Layout>
			<>
				<HeaderList title="Hogar a tu medida" />
				<ModalLoading loading={loading} />
				<Paper
					sx={{
						display: !dataStatus.data ? 'flex' : 'none',
					}}
				>
					<div style={{ display: 'block', textAlign: 'center' }}>
						<div>
							<h3 className={`${classes.title} ${classes.maxContent}`}>
								No tenemos registrada tu solicitud.
							</h3>
						</div>
						<div>
							<p className={classes.pText}>
								Para obtener este beneficio debes solicitarlo al momento
								de tramitar tu crédito y acreditar la discapacidad con el
								certificado que expide el Instituto Mexicano del Seguro
								Social, el Instituto de Seguridad y Servicios Sociales de
								los Trabajadores del Estado; la Secretaría del Trabajo y
								Previsión Social, o los médicos de la Procuraduría Federal
								de la Defensa del Trabajo, las secretarias de Salud de
								cada entidad federativa, o del Sistema Nacional para el
								Desarrollo Integral de la Familia (DIF).
							</p>
						</div>
						<div style={{ padding: '0 40px 20px' }}>
							<CustomAlert
								message={alert.message}
								severity={alert.severity}
								show={alert.show}
							/>
						</div>
					</div>
				</Paper>
				{dataStatus.data && <ValeHome pdfDoc={dataStatus.data} />}
			</>
		</Layout>
	);
};

export default HomeMeasure;
