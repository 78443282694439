import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useStyles } from './styles';
import { IUser } from '../../../interfaces/user';
import { useDispatch } from 'react-redux';
import { actions } from '../../../store/modules/session';
import { actions as creditActions } from '../../../store/modules/credit';
import { actions as MiucActions } from '../../../store/modules/miuc';
import { useNavigate } from 'react-router-dom';
import theme from '../../../config/theme';
import { loginAPI } from '../../../api/modules/auth';
import { actions as saldAction } from '../../../store/modules/saldo';
import { keyAPI } from '../../../api/modules/key';
import { encrypt } from '../../../utils/encrypt';

type Props = {
	userInfo: IUser | null;
};

const TopBar = ({ userInfo }: Props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const signOff = async () => {
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const paramsData = {
				nss: userInfo?.nss?.toString(),
			};
			const dataSendEncrypt = await encrypt(
				JSON.stringify(paramsData),
				key
			);
			loginAPI
				.logout(dataSendEncrypt)
				.then(() => {
					logout();
				})
				.catch(() => {
					logout();
				})
		} catch (error) {
			logout();
		} finally {
			logout();
		}
	};

	const logout = () => {
		dispatch(saldAction.cleanSaldo());
		dispatch(actions.signOut());
		dispatch(creditActions.clearCredit());
		dispatch(MiucActions.clearData());
		setTimeout(() => {
			userInfo?.isImpersonalized ? window.location.href = 'https://micuenta.infonavit.org.mx/' : navigate('/')
		}, 100)
	};

	return (
		<Box sx={{ display: 'flex', justifyContent: 'end' }}>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					width: 'fit-content',
					color: 'text.secondary',
					marginBottom: 0.5,
					'& hr': {
						mx: 3,
					},
				}}
			>
				<Avatar
					sx={{ fontSize: 12, width: 25, height: 25 }}
					className={classes.avatar}
				>
					{userInfo?.given_name.charAt(0)}
				</Avatar>
				<Typography sx={{ fontSize: 12 }} className={classes.typography}>
					{userInfo?.given_name}
				</Typography>
				<Divider orientation="vertical" flexItem className={classes.divider} />
				<Typography className={classes.typography} sx={{ fontSize: 12 }}>
					NSS: {userInfo?.nss}
				</Typography>
				{userInfo?.curp && (
					<>
						<Divider orientation="vertical" flexItem className={classes.divider} />
						<Typography className={classes.typography} sx={{ fontSize: 12 }}>
							CURP: {userInfo?.curp}
						</Typography>
					</>
				)}
				{userInfo?.rfc && (
					<>
						<Divider orientation="vertical" flexItem className={classes.divider} />
						<Typography className={classes.typography} sx={{ fontSize: 12 }}>
							RFC: {userInfo?.rfc}
						</Typography>
					</>
				)}
				{/* <Divider orientation="vertical" flexItem />
				<Badge variant="dot" color="primary">
					<NotificationsRoundedIcon color="info" />
				</Badge> */}
				<Divider orientation="vertical" flexItem className={classes.divider} />
				<Button
					sx={{
						textTransform: 'none',
						fontWeight: 400,
					}}
					style={{
						color: theme.palette.info.main,
					}}
					variant="text"
					endIcon={<ArrowRightAltIcon />}
					onClick={signOff}
				>
					<span>Cerrar sesión</span>
				</Button>
			</Box>
		</Box>
	);
};

export default TopBar;
