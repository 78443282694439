import { useState, useEffect } from 'react';

import { InputAdornment, Box } from '@mui/material';
import TextInput from '../../components/TextInput';
import SelectInput from '../../components/SelectInput';
import CustomButton from '../../components/Button';
import CustomAlert from '../../components/CustomAlert';
import ReciboLuz from '../../assets/img/recibo-luz.png';

import { isNumberFloat } from '../../utils/expressions';
import { useStyles } from './style';
import { IAlert } from '../../interfaces/alert';

type QuestionKey = `question${number}`;
type FormState = Record<QuestionKey, string>;

const QUESTIONS = [
	{
		id: 1,
		label: '1.- ¿Cuántas personas viven de manera constante en tu vivienda, contando a los niños, niñas, y personas de la tercera edad?',
		placeholder: 'Selecciona',
		type: 'select',
		options: [
			{ value: '1', text: '1' },
			{ value: '2', text: '2' },
			{ value: '3', text: '3' },
			{ value: '4', text: '4' },
			{ value: '5', text: '5' },
			{ value: '6', text: '6' },
			{ value: '7', text: '7' },
			{ value: '8', text: '8' },
			{ value: '9', text: '9' },
			{ value: '10', text: '10' },
			{ value: '11', text: '11' },
			{ value: '12', text: '12' },
			{ value: '13', text: '13' },
			{ value: '14', text: '14' },
			{ value: '15', text: '15' },
			{ value: '16', text: '16' },
			{ value: '17', text: '17' },
			{ value: '18', text: '18' },
			{ value: '19', text: '19' },
			{ value: '20', text: '20' },
			{ value: '21', text: '21' },
			{ value: '22', text: '22' },
			{ value: '23', text: '23' },
			{ value: '24', text: '24' },
		],
		required: true,
		helperText:
			'<p>(sin contar trabajadores domésticos ni huéspedes)</p>',
		labelError: '',
		labelStyles: {
			fontSize: 16,
			color: 'black',
			display: 'inline',
		},
	},
	{
		id: 2,
		label: '2.- ¿Cuántas de las personas que viven de manera constante en tu vivienda son mujeres?',
		placeholder: 'Selecciona',
		type: 'select',
		options: [
			{ value: '0', text: '0' },
			{ value: '1', text: '1' },
			{ value: '2', text: '2' },
			{ value: '3', text: '3' },
			{ value: '4', text: '4' },
			{ value: '5', text: '5' },
			{ value: '6', text: '6' },
			{ value: '7', text: '7' },
			{ value: '8', text: '8' },
			{ value: '9', text: '9' },
			{ value: '10', text: '10' },
			{ value: '11', text: '11' },
			{ value: '12', text: '12' },
		],
		required: true,
		helperText: '',
		labelError: '',
	},
	{
		id: 3,
		label: '3.- ¿Cuántas de las personas que viven de manera constante en tu vivienda son menores de 14 años?',
		placeholder: 'Selecciona',
		type: 'select',
		options: [
			{ value: '0', text: '0' },
			{ value: '1', text: '1' },
			{ value: '2', text: '2' },
			{ value: '3', text: '3' },
			{ value: '4', text: '4' },
			{ value: '5', text: '5' },
			{ value: '6', text: '6' },
			{ value: '7', text: '7' },
			{ value: '8', text: '8' },
			{ value: '9', text: '9' },
			{ value: '10', text: '10' },
			{ value: '11', text: '11' },
			{ value: '12', text: '12' },
			{ value: '13', text: '13' },
			{ value: '14', text: '14' },
		],
		required: true,
		helperText: '',
		labelError: '',
	},
	{
		id: 4,
		label: '4.- ¿Cuántas de las personas que viven de manera constante en tu vivienda tuvieron trabajo pagado el mes pasado?',
		placeholder: 'Selecciona',
		type: 'select',
		options: [
			{ value: '0', text: '0' },
			{ value: '1', text: '1' },
			{ value: '2', text: '2' },
			{ value: '3', text: '3' },
			{ value: '4', text: '4' },
			{ value: '5', text: '5' },
			{ value: '6', text: '6' },
			{ value: '7', text: '7' },
			{ value: '8', text: '8' },
			{ value: '9', text: '9' },
		],
		required: true,
		helperText:
			'<p>Ya sea trabajo formal o informal, considera solo a los mayores de 14 años.</p>',
		labelError: '',
	},
	{
		id: 5,
		label: '5.- ¿La persona que más aporta en la economía del hogar es hombre o mujer?',
		placeholder: 'Selecciona',
		type: 'select',
		options: [
			{ value: '2', text: 'Mujer' },
			{ value: '1', text: 'Hombre' },
		],
		required: true,
		helperText: '',
		labelError: '',
	},
	{
		id: 6,
		label: '6.- ¿Cuántos años cumplidos tiene la persona que más aporta a la economía del hogar?',
		placeholder: '50',
		type: 'text',
		options: [],
		required: true,
		helperText: '',
		labelError: '',
		max: 107,
		min: 18,
	},
	{
		id: 7,
		label: '7.- ¿Qué grado de escolaridad alcanzó la persona que más aporta a la economía del hogar?',
		placeholder: 'Selecciona',
		type: 'select',
		options: [
			{ text: 'Sin escolaridad', value: '1' },
			{ text: 'Primaria', value: '2' },
			{ text: 'Secundaria', value: '3' },
			{ text: 'Preparatoria', value: '4' },
			{ text: 'Profesional', value: '5' },
		],
		required: true,
		helperText:
			'<p>Si la persona se encuentra estudiando actualmente, ingresar el último grado aprobado.</p>',
		labelError: '',
	},
	{
		id: 8,
		label: '8.- ¿Cuánto pagaste de luz eléctrica el último bimestre?',
		placeholder: '7 dígitos con 2 decimales',
		type: 'currency',
		options: [],
		required: true,
		helperText:
			'<p>En caso de que tu pago sea mensual, suma los dos últimos recibos. Estos recibos no deben tener una antigüedad mayor a 3 meses.</p>',
		labelError: '',
		max: 50000,
		min: 0.01,
	},
	{
		id: 9,
		label: '9.- Coloca los 12 dígitos del número de servicio de tu último recibo bimestral de luz eléctrica.',
		placeholder: '12 dígitos',
		type: 'text',
		options: [],
		required: true,
		helperText: '',
		labelError: '',
		tooltipText: '',
		tooltipModal: {
			show: true,
			type: 'image',
			urlImage: ReciboLuz,
			alt: 'Recibo de luz'
		},
		length: 12,
	},
];

const validateInput = (value: string) => {
	const regex = /^(\d*\.?\d{0,2})?$/;
	return regex.test(value);
};

const validateIntegerInput = (value: string) => {
	const regex = /^\d*$/;
	return regex.test(value);
};

interface Props {
	onNextPage: (data: any) => void;
	onPrevPage: () => void;
}

const Questionnaire = ({ onNextPage, onPrevPage }: Props) => {
	const style = useStyles();
	const [form, setForm] = useState<FormState>({
		question1: '',
		question2: '',
		question3: '',
		question4: '',
		question5: '',
		question6: '',
		question7: '',
		question8: '',
		question9: '',
	});
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});

	const handleChange = (
		id: number,
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const { value } = event.target;
		const key: QuestionKey = `question${id}`;
		setForm((prevForm) => ({
			...prevForm,
			[key]: value,
		}));
	};

	const validateValue = (
		value: string,
		max: number,
		type: string,
		min?: number
	) => {
		if (type === 'currency') {
			if (value.length > 0) {
				if (validateInput(value)) {
					if (min) {
						return (
							parseFloat(value) >= min && parseFloat(value) <= max
						);
					}
					return parseFloat(value) <= max;
				}
			} else {
				return true;
			}
		} else {
			if (value.length > 0) {
				if (validateIntegerInput(value) && value !== '000') {
					if (min) {
						return (
							parseFloat(value) >= min && parseFloat(value) <= max
						);
					}
					return parseFloat(value) <= max;
				} else {
					return false;
				}
			} else {
				return true;
			}
		}
	};

	const validateDisabled = () => {
		const allValuesNotEmpty = Object.values(form).every(
			(value) => value !== ''
		);
		if (
			allValuesNotEmpty &&
			parseFloat(form.question6) > 0 &&
			form.question9.length === 12
		) {
			return false;
		} else {
			return true;
		}
	};

	useEffect(() => {
		if (Number(form.question1) === Number(form.question2) && Number(form.question5) === 1) {
			setAlert({
				show: true,
				message: 'La persona que aporta más dinero no puede ser un hombre, ya que has indicado que todos los integrantes son mujeres.',
				severity: 'error',
			});
		} else if (Number(form.question5) === 2 && Number(form.question2) === 0) {
			setAlert({
				show: true,
				message: 'La persona que aporta más dinero no puede ser mujer, ya que has indicado que no hay integrantes mujeres.',
				severity: 'error',
			});
		} else if (Number(form.question1) === Number(form.question3)) {
			setAlert({
				show: true,
				message: 'El número de menores no puede ser igual al número total de integrantes de la familia.',
				severity: 'error',
			});
		} else if ((Number(form.question3) + Number(form.question4)) > Number(form.question1)) {
			setAlert({
				show: true,
				message: 'El número de menores más la cantidad de personas que trabajan no pueden ser mayor al número total de integrantes de la familia.',
				severity: 'error',
			});
		} else {
			setAlert({
				show: false,
				message: '',
				severity: 'success',
			});
		}
	}, [form])

	return (
		<div className={style.container}>
			<div className={style.textAlign_Center_spaciado}>
				<h3 className={`${style.textGrayBox} ${style.maxContent} ${style.marginCenter}`}>
					Un requisito indispensable para otorgarte este apoyo, es que
					respondas a todas las preguntas de este cuestionario manera
					veraz.
				</h3>
				<p className={`${style.textAlign_Bold} ${style.maxContent} ${style.marginCenter}`}>
					Para determinar tu nueva mensualidad se analizarán tanto la
					documentación solicitada, como la veracidad de tus
					respuestas
				</p>
				<p className={`${style.maxContent} ${style.marginCenter}`}>
					(El infonavit se reserva el derecho de validar los datos
					proporcionados para otorgar el apoyo).
				</p>
			</div>
			<div className={style.containerQuestionnaire}>
				{QUESTIONS.map((item, index) =>
					item.type === 'text' || item.type === 'currency' ? (
						<div
							className={style.itemQuestion}
							key={'question' + index}
						>
							<TextInput
								id={item.id + 'question'}
								name={'otherType'}
								value={
									form[`question${item.id}` as QuestionKey]
								}
								onChange={(e) => {
									const newValue = e.target.value;
									if (item.max) {
										if (
											validateValue(
												newValue,
												item.max,
												item.type
											)
										) {
											handleChange(item.id, e);
										}
									} else if (item.length) {
										if (
											validateIntegerInput(newValue) &&
											newValue.length < 13
										) {
											handleChange(item.id, e);
										}
									} else {
										if (isNumberFloat(newValue)) {
											handleChange(item.id, e);
										}
									}
								}}
								startAdornment={
									item.type === 'currency' ? (
										<InputAdornment position="start">
											$
										</InputAdornment>
									) : (
										<></>
									)
								}
								onBlur={(e) => {
									if (item.min) {
										if (
											Number(
												form[
												`question${item.id}` as QuestionKey
												]
											) < item.min
										) {
											handleChange(item.id, {
												...e,
												target: { value: '' },
											});
										}
									}
								}}
								label={item.label}
								isRequired={item.required}
								placeholder={item.placeholder}
								labelStyles={{ fontSize: 16, color: 'black' }}
								labelHelper={item.helperText}
								tooltipText={item.tooltipText}
								tooltipIconStyle={{
									position: 'absolute',
									top: '-4px',
									left: '0px',
									cursor: 'pointer',
								}}
								tooltipModal={item.tooltipModal}
							/>
						</div>
					) : item.type === 'select' ? (
						<div
							className={style.itemQuestion}
							key={'question' + index}
						>
							<SelectInput
								id={item.id + 'question'}
								name="estado"
								label={item.label}
								isRequired={item.required}
								placeholder={item.placeholder}
								value={
									form[`question${item.id}` as QuestionKey]
								}
								onChange={(e) => handleChange(item.id, e)}
								//options={item.options}
								options={
									item.id !== 2 &&
										item.id !== 3 &&
										item.id !== 4
										? item.options
										: item?.options?.map((option: any) => ({
											...option,
											disabled:
												form.question1 !== null &&
												Number(option.value) >
												Number(form.question1),
										}))
								}
								labelStyles={
									item.labelStyles || {
										fontSize: 16,
										color: 'black',
									}
								}
								labelHelper={item.helperText}
								tooltipText={item.tooltipText}
								tooltipIconStyle={{
									position: 'absolute',
									top: '-4px',
									left: '0px',
									cursor: 'pointer',
								}}
							/>
						</div>
					) : null
				)}

				<div className={style.textAlign_Center_spaciado}>
					<p>
						Ten en cuenta que{' '}
						<b>
							debes tener preparado tu recibo de luz en formato
							PDF, JPEG ó WORD
						</b>
						, ya que más adelante lo debes adjuntar y con el cual se
						corroborará el monto de luz y número de servicio que
						capturaste.
					</p>
					<p>
						<b>
							Si no adjuntas tu recibo de luz más adelante, tu
							caso no procederá.
						</b>
					</p>
				</div>

				<Box className={style.BoxAlert}>
					<CustomAlert
						message={alert.message}
						severity={alert.severity}
						show={alert.show}
					/>
				</Box>

				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '540px',
							paddingTop: '30px',
						}}
					>
						<Box className={style.BoxButton}>
							<CustomButton
								onClick={onPrevPage}
								variant="outlined"
								label="Regresar"
								styles={{ height: '100%' }}
							/>
						</Box>
						<Box className={style.BoxButton}>
							<CustomButton
								onClick={() => {
									onNextPage(form);
								}}
								variant="solid"
								label="Continuar"
								disabled={validateDisabled() || alert.show}
							/>
						</Box>
					</Box>
				</Box>
			</div>
		</div>
	);
};

export default Questionnaire;
