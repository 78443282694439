// RESOURCES
import { useNavigate } from 'react-router-dom';
// COMPONENTS
import CustomButton from '../../../components/Button';
// ASSETS
import { useStyles } from './styles';

interface Props {
	setPage: (data: number) => void;
}

const UnregisteredPayment = ({ setPage }: Props) => {
	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<>
			<div className={classes.container}>
				<div className={classes.containerMin}>
					<h3 className={classes.title}>
						No tienes pagos registrados por trámites de créditos cancelados.
					</h3>
					<div className={classes.textContainer}>
						<p className={classes.text}>
							Si cancelaste tu trámite de crédito antes de la firma de
							escritura y tu patrón realizó descuentos para el pago del
							crédito, te sugerimos consultar nuevamente en un mes.
						</p>
					</div>
					<div className={classes.contentFileDataButtons}>
						<CustomButton
							onClick={() => {
								navigate('/inicio');
							}}
							variant="outlined"
							styles={{
								marginTop: 25,
								width: 200,
								marginInline: 10,
								height: 40
							}}
							label="Salir"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default UnregisteredPayment;
