import { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { INTERNAL_MESSAGES } from '../../../config/messageCatalog';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import TextInput from '../../../components/TextInput';
import { IInstrumentData, MortgageCancellationType } from '.';
import parseISO from 'date-fns/parseISO';
import { useStyles } from './styles';

interface IPrivNotarioView {
	data: IInstrumentData;
	changeData: (event: { name: string; value: string | Date | null }) => void;
	docType: MortgageCancellationType;
}

export const InstrumentoView = ({ data, changeData, docType }: IPrivNotarioView) => {
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [FlagDate, setFlagDate] = useState(false);

	const getLabelNoInstrumento = (): string => {
		let text: string = '';

		switch (docType.value) {
			case 'Private-Notary':
				text = INTERNAL_MESSAGES.PRIVATE_INSTRUMENT_NUMBER;
				break;
			case 'Private-Registry':
				text = INTERNAL_MESSAGES.PRIVATE_INSTRUMENT_NUMBER;
				break;
			case 'Public-Notary':
				text = INTERNAL_MESSAGES.PUBLIC_DEED_NUMBER;
				break;
			case 'Public-Registry':
				text = INTERNAL_MESSAGES.PUBLIC_DEED_NUMBER;
				break;
			default:
				text = '';
		}

		return text;
	};
	const getLabelFechaElaboracion = (): string => {
		let text: string = '';

		switch (docType.value) {
			case 'Private-Notary':
				text = INTERNAL_MESSAGES.PREPARATION_PRIVATE_INSTRUMENT;
				break;
			case 'Private-Registry':
				text = INTERNAL_MESSAGES.PREPARATION_PRIVATE_INSTRUMENT;
				break;
			case 'Public-Notary':
				text = INTERNAL_MESSAGES.DATE_ELABORATION_PUBLIC_DEED;
				break;
			case 'Public-Registry':
				text = INTERNAL_MESSAGES.DATE_ELABORATION_PUBLIC_DEED;
				break;
			default:
				text = '';
		}

		return text;
	};

	return (
		<>
			<div>
				<p className={`${classes.noMargin} ${classes.maxContent}`}>
					<b>Escribe los siguientes datos</b>
				</p>
				<div style={{ paddingLeft: matches ? 0 : 200, paddingTop: 10 }}>
					<TextInput
						id="noInstrumento"
						name="noInstrumento"
						onChange={(event) =>
							changeData({
								name: event.target.name,
								value: event.target.value,
							})
						}
						value={data.noInstrumento}
						label={getLabelNoInstrumento()}
						isRequired
						maxLength={30}
					/>
				</div>
				<div style={{ paddingLeft: matches ? 0 : 200, paddingTop: 10 }}>
					<p
						style={{
							margin: 0,
							fontSize: 14,
							color: '#7E8094',
							marginBottom: '8px',
						}}
						className={`${classes.maxContent}`}
					>
						{getLabelFechaElaboracion()}
						<label style={{ color: theme.palette.primary.main }}>* </label>
					</p>
					<DesktopDatePicker
						inputFormat="dd/MM/yyyy"
						value={data.date}
						minDate={parseISO('1972-05-01')}
						onChange={(value) => {
							if (!FlagDate) {
								changeData({
									name: 'date',
									value: value ? value : '',
								});
							}
							setFlagDate(false);
						}}
						InputProps={{ style: { height: 40, width: '100%' } }}
						renderInput={(params) => <TextField fullWidth {...params} />}
						onYearChange={(year) => {
							setFlagDate(true);
							changeData({
								name: 'date',
								value: year ? year : '',
							});
						}}
					/>
				</div>

				{docType.value !== 'Public-Notary' && (
					<div style={{ paddingLeft: matches ? 0 : 200, paddingTop: 10 }}>
						<TextInput
							id="folio"
							name="folio"
							onChange={(event) =>
								changeData({
									name: event.target.name,
									value: event.target.value,
								})
							}
							value={data.folio}
							label="Folio real"
							isRequired
							maxLength={30}
						/>
					</div>
				)}
				{docType.value !== 'Public-Notary' && (
					<div style={{ paddingLeft: matches ? 0 : 200, paddingTop: 10 }}>
						<TextInput
							id="libro"
							name="libro"
							onChange={(event) =>
								changeData({
									name: event.target.name,
									value: event.target.value,
								})
							}
							value={data.libro}
							label="Libro"
							isRequired
							maxLength={30}
						/>
					</div>
				)}

				<div style={{ paddingLeft: matches ? 0 : 200, paddingTop: 10 }}>
					<TextInput
						id="seccion"
						name="seccion"
						onChange={(event) =>
							changeData({
								name: event.target.name,
								value: event.target.value,
							})
						}
						value={data.seccion}
						label="Sección"
						isRequired
						maxLength={30}
					/>
				</div>
			</div>
		</>
	);
};

export default InstrumentoView;
