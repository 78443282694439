/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FloatMenu from '../../../components/FloatMenu';
import { MenuElements } from '../utils';
import { useNavigate, useLocation } from 'react-router-dom';
import theme from '../../../config/theme';

type Props = {
	menuElements: MenuElements[];
};

const Menu = ({ menuElements }: Props) => {
	const navigate = useNavigate();
	const location = useLocation();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [fatherElement, setFatherElement] = useState<string>('');
	const [childrenElements, setChildrenElements] = useState<MenuElements[]>(
		[]
	);
	const [current, setCurrent] = useState<string>('');

	const open = Boolean(anchorEl);

	const handleClick = (
		event: React.MouseEvent<HTMLElement>,
		id: number,
		path: string
	) => {
		const elements = menuElements.filter((child) => child.id === id)[0]
			.children;
		setChildrenElements(elements);
		setFatherElement(path);
		setAnchorEl(event.currentTarget);
	};

	const activateMenuItem = (): void => {
		setCurrent(location.pathname.split('/')[1]);
	};

	useEffect(() => {
		activateMenuItem();
	}, [location]);

	return (
		<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
			<Button
				disableRipple={true}
				variant="text"
				sx={{
					fontSize: 12,
					my: 0.5,
					mr: 1.5,
					color: current === 'inicio' ? 'primary' : '#333333',
					textTransform: 'none',
					'&:hover': {
						backgroundColor: 'transparent',
						color: theme.palette.primary.main
					}
				}}
				onClick={(e: React.MouseEvent<HTMLElement>) => {
					navigate('/inicio');
				}}
			>
				Inicio
			</Button>
			{menuElements.map((page, index) => (
				<Button
					disableRipple={true}
					variant="text"
					key={index}
					sx={{
						fontSize: 12,
						my: 0.5,
						mr: 1.5,
						color:
							current === page.path
								? theme.palette.primary.main
								: '#333333',
						textTransform: 'none',
						'&:hover': {
							backgroundColor: 'transparent',
							color: theme.palette.primary.main
						}
					}}
					endIcon={
						page.children.length > 0 ? <ArrowDropDownIcon /> : null
					}
					onClick={(e: React.MouseEvent<HTMLElement>) => {
						page.children.length > 0
							? handleClick(e, page.id, page.path)
							: navigate(`/${page.path}`);
					}}
				>
					<span>{page.name}</span>
				</Button>
			))}
			{open && (
				<FloatMenu
					subMenuItems={childrenElements}
					mainItem={fatherElement}
					open={open}
					setAnchorEl={setAnchorEl}
					anchorEl={anchorEl}
				/>
			)}
		</Box>
	);
};
export default Menu;
