/* eslint-disable jsx-a11y/anchor-is-valid */
//Dependencies
import React from 'react';

// Material Components
import { Box } from '@mui/material';

// Components
import CustomButton from '../Button';
import CustomAlert from '../CustomAlert';

// Assets
import { useStyles } from './styles';
import mail from '../../assets/img/mail.png';
import cel from '../../assets/img/cel.png';

// Resources
import { isNumberValidate } from '../../utils/expressions';

// Interface
import { IVerificationCode } from '../../interfaces/verificationCode';

const VerificationCode = (props: IVerificationCode) => {
	const [code1, setCode1] = React.useState({ valid: false, value: '' });
	const [code2, setCode2] = React.useState({ valid: false, value: '' });
	const [code3, setCode3] = React.useState({ valid: false, value: '' });
	const [code4, setCode4] = React.useState({ valid: false, value: '' });
	const [code5, setCode5] = React.useState({ valid: false, value: '' });
	const [code6, setCode6] = React.useState({ valid: false, value: '' });

	const setFocusElementByName = (name: string) => {
		let element = document.getElementsByName(name)[0];
		element.focus();
	};

	const validateNumber = (value: any) => {
		const esValido = isNumberValidate(value.target.value);
		if (esValido && value.target.value.length > 0) {
			let valString = value.target.value;

			if (valString.length > 1) {
				valString = valString.charAt(valString.length - 1);
			}

			if (value.target.name === 'code1') {
				setCode1({ valid: true, value: valString });
				setFocusElementByName('code2');
			}
			if (value.target.name === 'code2') {
				setCode2({ valid: true, value: valString });
				setFocusElementByName('code3');
			}
			if (value.target.name === 'code3') {
				setCode3({ valid: true, value: valString });
				setFocusElementByName('code4');
			}
			if (value.target.name === 'code4') {
				setCode4({ valid: true, value: valString });
				if (props.isSixDigits) {
					setFocusElementByName('code5');
				}
			}
			if (value.target.name === 'code5') {
				setCode5({ valid: true, value: valString });
				setFocusElementByName('code6');
			}
			if (value.target.name === 'code6') {
				setCode6({ valid: true, value: valString });
			}
		} else {
			if (value.target.name === 'code1') {
				setCode1({ valid: false, value: '' });
			}
			if (value.target.name === 'code2') {
				setCode2({ valid: false, value: '' });
			}
			if (value.target.name === 'code3') {
				setCode3({ valid: false, value: '' });
			}
			if (value.target.name === 'code4') {
				setCode4({ valid: false, value: '' });
			}
			if (value.target.name === 'code5') {
				setCode5({ valid: false, value: '' });
			}
			if (value.target.name === 'code6') {
				setCode6({ valid: false, value: '' });
			}
		}
	};

	const disableBTN = () => {
		if (props.isEmail === true) {
			return false;
		} else {
			if (!props.isSixDigits) {
				return !code1.valid || !code2.valid || !code3.valid || !code4.valid
					? true
					: false;
			} else {
				return !code1.valid || !code2.valid || !code3.valid || !code4.valid || !code5.valid || !code6.valid
					? true
					: false;
			}
		}
	};

	const styles = useStyles();
	return (
		<>
			<Box className={styles.VerificacionLogo}>
				<img
					alt={props.isEmail ? 'icono de email' : 'icono de celular'}
					src={props.isEmail ? mail : cel}
				/>
			</Box>
			<Box
				sx={{
					alignItems: 'center',
					justifyContent: 'center',
					display: 'flex',
				}}
			>
				<label className={styles.VerificacionCodigoTitle}>
					{props.isEmail && (
						<strong>Correo de confirmación</strong>
					)}
					{!props.isEmail && (
						<strong>Código de confirmación</strong>
					)}
				</label>
			</Box>
			{!props.isEmail && (
				<Box
					sx={{
						alignItems: 'center',
						justifyContent: 'center',
						display: 'flex',
						marginBottom: '35px',
					}}
				>
					<label className={styles.VerificacionText}>
						<strong>
							Se envió un mensaje de texto al número de celular
						</strong>{' '}
						que nos proporcionaste.
						<br />
						Para activar tu cuenta ingresa el código que te hemos
						enviado.
						<br />
					</label>
				</Box>
			)}
			{props.isEmail && (
				<Box
					sx={{
						alignItems: 'center',
						justifyContent: 'center',
						display: 'flex',
						marginBottom: '35px',
					}}
				>
					<span className={styles.VerificacionText}>
						<p style={{ margin: 0, padding: 0 }}>
							<b>
								Se envió un correo electrónico al e-mail
							</b>{' '}
							que nos proporcionaste.
						</p>
						<br />
						<p style={{ margin: 0, padding: 0 }}>
							<b>Para continuar con el proceso confirma tu correo, en
								caso de no recibir el mensaje, <br />
								comunícate al Infonatel
							</b>
						</p>
						<br />
						<p style={{ margin: 0, padding: 0 }}>
							De no encontrar en la Bandeja de entrada el correo,
							revisa tu carpeta de no <br />
							deseados.
						</p>
					</span>
				</Box>
			)}
			{!props.isEmail && (
				<Box
					className={styles.divCodigos}
					sx={{
						display: 'flex',
					}}
					style={{ maxWidth: props.isSixDigits ? '400px' : '300px' }}
				>
					<input
						id="code1"
						name="code1"
						pattern="[0-9]"
						className={styles.VerificacionCodigo}
						value={code1.value}
						onChange={validateNumber}
					/>
					<input
						id="code2"
						name="code2"
						pattern="[0-9]"
						className={styles.VerificacionCodigo}
						value={code2.value}
						onChange={validateNumber}
					/>
					<input
						id="code3"
						name="code3"
						pattern="[0-9]"
						className={styles.VerificacionCodigo}
						value={code3.value}
						onChange={validateNumber}
					/>
					<input
						id="code4"
						name="code4"
						pattern="[0-9]"
						className={styles.VerificacionCodigo}
						value={code4.value}
						onChange={validateNumber}
					/>
					{props.isSixDigits && (
						<div style={{ display: 'flex', width: '120px', justifyContent: 'space-between' }}>
							<input
								id="code5"
								name="code5"
								pattern="[0-9]"
								className={styles.VerificacionCodigo}
								value={code5.value}
								onChange={validateNumber}
							/>
							<input
								id="code6"
								name="code6"
								pattern="[0-9]"
								className={styles.VerificacionCodigo}
								value={code6.value}
								onChange={validateNumber}
							/>
						</div>
					)}
				</Box>
			)}
			<Box
				sx={{
					alignItems: 'center',
					justifyContent: 'center',
					marginBottom: '2%',
					display: 'flex',
				}}
			>
				<label className={styles.textGray14}>Válido por 4 horas </label>
			</Box>
			<Box
				sx={{
					alignItems: 'center',
					justifyContent: 'center',
					display: 'flex',
				}}
			>
				{props.reSend && (
					<label className={styles.link} onClick={props.reSend}>
						Volver a enviar
					</label>
				)}
			</Box>

			<Box
				sx={{
					margin: '50px auto 0'
				}}
			>
				<div style={{ width: '100%' }}>
					<CustomAlert
						show={props.hasError?.show || false}
						severity={props.hasError?.severity || 'success'}
						message={props.hasError?.message || ''}
						onClose={() => {
							if (props.onCloseError) {
								props.onCloseError();
							}
						}}
					/>
				</div>
				<CustomButton
					onClick={() => {
						if (!props.isSixDigits) {
							props.setCode(
								code1.value +
								code2.value +
								code3.value +
								code4.value
							);
						} else {
							props.setCode(
								code1.value +
								code2.value +
								code3.value +
								code4.value +
								code5.value +
								code6.value
							);
						}
					}}
					variant="solid"
					styles={{ width: '260px', margin: '0 auto' }}
					label="Continuar"
					disabled={disableBTN()}
				/>
			</Box>
		</>
	);
};

export default VerificationCode;
