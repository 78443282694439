/* eslint-disable react-hooks/exhaustive-deps */
import Layout from '../../components/Layout';
import { useEffect, useState, useRef } from 'react';
import HomePanel from './components/HomePanel';
import InfoData from './components/InfoData';
import { Box, Grid } from '@mui/material';
import ViewPoints from '../MIUC/components/ViewPoints';
import FlowCard from '../MIUC/components/FlowCard';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../store/modules/loading';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { apiMIUC } from '../../api/modules/MIUC';
import { RootState } from '../../store';

const SupportCertificate = () => {
	const { fontSizeLevel } = useSelector((state: RootState) => state.session);
	const dispatch = useDispatch();
	const [message, setMessage] = useState('');
	const [pdf64, setPdf64] = useState('');
	const [view, setView] = useState(0);
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	useEffect(() => {
		const init = async () => {
			try {
				setMessage('');
				dispatch(actions.startLoading());
				const { data, message } =
					await apiMIUC.descargarCertificadoApoyo();
				const pdf64 = data?.certificado;
				if (pdf64) {
					setPdf64(pdf64);
					return;
				}
				setMessage(message || INTERNAL_MESSAGES.ERROR_MESSAGE);
			} catch (error) {
				setMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
			} finally {
				dispatch(actions.stopLoading());
			}
		};

		init();
	}, []);

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<Layout updateTagText={updateTagText}>
			<Grid container columns={12} spacing={2} ref={divRef}>
				<Grid item xs={12} md={8}>
					{view === 0 && (
						<HomePanel
							setView={setView}
							message={message}
							pdf64={pdf64}
						/>
					)}
					{view === 1 && <InfoData setView={setView} />}
				</Grid>
				<Grid item xs={12} md={4}>
					<Box sx={{ textAlign: 'center' }}>
						<ViewPoints showPoints={false} />
					</Box>
					<Box mt={2}>
						<FlowCard
							title="Tu selección"
							flow={[
								{
									title: 'Crédito seleccionado para:',
									description: 'Apoyo Infonavit',
								},
							]}
							progress={view === 0 ? 50 : 100}
						/>
					</Box>
				</Grid>
			</Grid>
		</Layout>
	);
};

export default SupportCertificate;
