/* eslint-disable react-hooks/exhaustive-deps */
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../components/Button';
import iconDownload from '../../../assets/img/icono-descarga.png';
import { useStyles } from './styles';
import { formatDate as formatDateString } from '../../../interfaces/SaldAndMovement';
import CustomAlert from '../../../components/CustomAlert';
import { IAlert } from '../../../interfaces/alert';
import { useEffect, useState } from 'react';

interface Props {
	setPage: (data: number) => void;
	params: any;
	hasError: IAlert;
	downloadFile: () => void;
	value: string;
	setValue: React.Dispatch<React.SetStateAction<string>>;
	handleConsultCase: () => void;
}

const FinishView = ({
	setPage,
	params,
	hasError,
	downloadFile,
	handleConsultCase,
	value,
	setValue
}: Props) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const [alertEstatus, setAlertEstatus] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'warning',
	});
	const [estatus, setEstatus] = useState<string>('');

	const formatStatus = () => {
		setAlertEstatus({ ...alertEstatus, show: false });
		let status: string = '';
		if (params && params.estatus && params.estatus.length > 0) {
			status = params.estatus;
			status = status
				.replaceAll('ZCHI_', '')
				.replaceAll('ZCHB_', '')
				.replaceAll('_', ' ');
		}

		if (
			status.replace(/\w+/g, function (w) {
				return w[0].toUpperCase() + w.slice(1).toLowerCase();
			}) === 'Espera Documento' ||
			status.replace(/\w+/g, function (w) {
				return w[0].toUpperCase() + w.slice(1).toLowerCase();
			}) === 'Espera Carta'
		) {
			setAlertEstatus({ ...alertEstatus, show: true });
		}

		setEstatus(
			status.replace(/\w+/g, function (w) {
				return w[0].toUpperCase() + w.slice(1).toLowerCase();
			})
		);
	};

	useEffect(() => {
		formatStatus();
	}, [params]);

	return (
		<>
			<div className={classes.titleNoticeContainer}>
				<div>
					<h2 className={classes.titleNotice}>
						Carta de instrucción de cancelación de hipoteca{' '}
					</h2>
				</div>

				<div className={classes.subTitleNotice}></div>
			</div>

			<div className={classes.container}>
				<div>
					<h3 className={classes.principalTitle}>
						Gracias por utilizar los servicios digitales del
						Infonavit
					</h3>
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<h4 className={classes.contentFileDataTitle}>
									Número de caso
								</h4>
							</div>
							<div>
								<p className={classes.contentFileDataLink}>
									{params.numeroCaso}
								</p>
							</div>
						</div>
					</div>
					<Divider style={{ marginBlock: -5 }} />
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<h4 className={classes.contentFileDataTitle}>
									Fecha de solicitud
								</h4>
							</div>
							<div>
								<p className={classes.contentFileDataLink}>
									{formatDateString(params.fechaSolicitud)}
								</p>
							</div>
						</div>
					</div>
					<Divider style={{ marginBlock: -5 }} />
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<h4 className={classes.contentFileDataTitle}>
									Servicio
								</h4>
							</div>
							<div>
								<p className={classes.contentFileDataLink}>
									Carta de instrucción de cancelación de
									hipoteca
								</p>
							</div>
						</div>
					</div>
					<Divider style={{ marginBlock: -5 }} />
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<h4 className={classes.contentFileDataTitle}>
									Estatus
								</h4>
							</div>
							<div>
								<p className={classes.contentFileDataLink}>
									{estatus}
								</p>
							</div>
						</div>
					</div>
					<Divider style={{ marginBlock: -5 }} />
					{/* <div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<h4 className={classes.contentFileDataTitle}>
									Fecha de estatus
								</h4>
							</div>
							<div>
								<p className={classes.contentFileDataLink}>
									{formatDateString(params.fechaEstatus)}
								</p>
							</div>
						</div>
					</div>
					<Divider style={{ marginBlock: -5 }} /> */}
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<h4 className={classes.contentFileDataTitle}>
									Descripción
								</h4>
							</div>
							<div>
								<p className={classes.contentFileDataLink}>
									{
										'Descarga tu carta de cancelación de hipoteca o consúltala en tu correo electrónico'
									}
								</p>
							</div>
						</div>
					</div>
					<div className={classes.contentFooterData}>
						<div className={classes.contentFooterDataLink}>
							<div
								onClick={downloadFile}
								style={{
									cursor: 'pointer',
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<label
									style={{ marginTop: 7, cursor: 'pointer' }}
								>
									Descarga carta
								</label>
								<img src={iconDownload} alt="ícono de descargar" />
							</div>
						</div>
						<p className={classes.txtPrincipales} style={{ fontWeight: 'bold' }}>
							Si los datos de tu carta no son correctos,
							solicítala de nuevo.
						</p>
					</div>
					<CustomAlert
						show={alertEstatus.show}
						severity={'warning'}
						message={''}
					>
						<>
							Tu trámite está en proceso. El estatus de tu caso se
							actualizará en dos minutos, para consultarlo
							refresca tu pantalla.
							<br />
							Gracias por utilizar los servicios digitales del
							Infonavit.
						</>
					</CustomAlert>

					<div className={classes.contentFooterData}>
						<CustomAlert
							show={hasError.show}
							severity={hasError.severity}
							message={hasError.message}
						/>
						<div className={classes.contentFileDataButtons}>
							<CustomButton
								onClick={() => {
									setPage(0);
									setValue('');
								}}
								variant="outlined"
								styles={{
									marginTop: 20,
									width: '100%',
									marginInline: 10,
								}}
								label="Solicitar carta"
							/>
							<CustomButton
								onClick={() => {
									handleConsultCase();
									navigate('/');
								}}
								variant="solid"
								styles={{
									marginTop: 20,
									width: '100%',
									marginInline: 10,
								}}
								disabled={false}
								label="Finalizar"
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default FinishView;
