import { itemCheck } from '../interfaces';

export const checksReturnOptions: itemCheck[] | undefined = [
	{
		Id: 1,
		label: 'Por terminación de relación laboral',
		value: 'terminacionLaboral',
		sublabel:
			'El trabajador tiene por lo menos un año sin relación laboral y 50 años de edad.',
	},
	{
		Id: 2,
		label: 'Por resolución de pensión del IMSS',
		value: 'resolucionPensionImss',
		sublabel: 'Dictamen de otorgamiento de pensión emitido por el IMSS.',
	},
	{
		Id: 3,
		label: 'Por plan privado de pensión de aportaciones solo al Infonavit',
		value: 'planPrivadoPension',
		sublabel:
			'Esquema de retiro establecido por el patrón y soportado por el contrato colectivo de trabajo que busca otorgar un beneficio a sus trabajadores.',
	},
	{
		Id: 4,
		label: 'Por resolución de la comisión de inconformidades',
		value: 'porInconformidades',
		sublabel:
			'Dictamen administrativo que emite la Comisión de Inconformidades del Infonavit para que sean devueltas al trabajador las aportaciones que constituyen el Fondo de Ahorro 72-92.',
	},
	{
		Id: 5,
		label: 'Tengo una pensión anterior a julio de 1997',
		value: 'pensionAnterior1997',
		sublabel:
			'Dictamen de otorgamiento de pensión emitido por el IMSS con fecha anterior a julio 1997.',
	},
];
