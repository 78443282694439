/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';

import CustomButton from '../../../components/Button';
import CustomAlert from '../../../components/CustomAlert';
import ModalLoading from '../../../components/ModalLoading';
import { Select, MenuItem } from '@mui/material';

import { dataReturnSavingsContext } from '../contextData';
import { useStyles } from '../style';

const SelectStateClaim = () => {
	const style = useStyles();
	const [disabledButton, setDisabledButton] = useState<boolean>(true);

	const [optionSelected, setOptionSelected] = useState<string>('');
	const {
		handleNextPage,
		handlePrevPage,
		setData,
		data,
		alert,
		setAlert,
		handleGetStates,
		states,
		loading,
	} = useContext(dataReturnSavingsContext);

	useEffect(() => {
		handleGetStates();
	}, []);

	useEffect(() => {
		setData(
			{
				...(data || undefined),
				returnSavingsStates: optionSelected,
			}
		);
		setAlert({
			show: false,
			severity: 'success',
			message: '',
		});
	}, [optionSelected]);

	return (
		<div>
			<ModalLoading loading={loading} />
			<label className={style.TextBlack_H3}>
				Elige el estado en donde realizaste tu demanda
			</label>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className={style.divNRP}>
					{/*<p
						style={{
							margin: 0,
							fontSize: 14,
							marginBottom: 10,
						}}
					>
						Estado
						<label style={{ color: '#D1001F' }}>
							*{' '}
						</label>
					</p>*/}
					<Select
						displayEmpty
						name="estado"
						value={optionSelected}
						onChange={(event: any) => {
							setDisabledButton(false);
							setOptionSelected(event.target.value);
						}}
						renderValue={
							optionSelected !== ''
								? undefined
								: () => (
									<label
										style={{
											fontSize: 15,
											color: '#7F7F7F',
										}}
									>
										Selecciona tu estado
									</label>
								)
						}
						style={{
							height: 40,
							width: '100%',
						}}
					>
						{states.map(
							(element: { value: string; text: string }) => (
								<MenuItem
									key={element.value}
									value={element.value}
								>
									{element.text}
								</MenuItem>
							)
						)}
					</Select>
				</div>
			</div>
			<div className={style.divAlert}>
				<CustomAlert
					message={alert.message}
					show={alert.show}
					severity={alert.severity}
				/>
			</div>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className={style.divButtons}>
					<div className={style.Buttons}>
						<CustomButton
							onClick={handlePrevPage}
							variant="outlined"
							styles={{
								width: '100%',
								height: '100%',
							}}
							label="Regresar"
						/>
					</div>
					<div className={style.Buttons}>
						<CustomButton
							onClick={handleNextPage}
							variant="solid"
							styles={{
								width: '100%',
							}}
							label="Continuar"
							disabled={disabledButton}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SelectStateClaim;
