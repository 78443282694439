import { Box } from '@mui/material';
import { useStyles } from './styles/styles';
import SelectCustom from '../../../components/SelectCustom';
import CustomButton from '../../../components/Button';
import { useEffect, useState } from 'react';
import CalenderText from './components/CalenderText';
import CalenderCustom from './components/CalenderCustom';
import {
	CesiDefaultConsulta,
	MESSAGES_ERROR,
	SERVICESID,
	getDateParse,
	getHourParse,
} from './utils';
import { ESTATUS } from './utils/stepsStatus';
import { encodeAllJSONData } from '../../../utils/encrypt';
import ModalLoading from '../../../components/ModalLoading';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { writingRequestAPI } from '../../../api/modules/WritingRequest';
import { INTERNAL_MESSAGES } from '../../../config/messageCatalog';
import CustomAlert from '../../../components/CustomAlert';
import { useEscrituras } from '../hooks/useEscrituras';
import { CESI_QUOTE_STATUS, CODE_RESPONSE } from './utils/pagesStatus';
import { useBitacora } from '../../../hooks/useBitacora';

interface IProps {
	setPage: (arg: number) => void;
}

const CesiQuoteGeneral = ({ setPage }: IProps) => {
	const styles = useStyles();
	const { user } = useSelector((state: RootState) => state.session);
	const { escrituras, getConsultCita } = useEscrituras();
	const [loading, setLoading] = useState(false);
	const [estados, setEstados] = useState([]);
	const [cedis, setCedis] = useState([]);
	const [meses, setMeses] = useState([]);
	const [horarios, setHorarios] = useState([]);
	const [agendaId, setAgendaId] = useState('');
	const [message, setMessage] = useState('');
	const [docRequeridos, setDocRequeridos] = useState([]);
	const [dataInfo, setDataInfo] = useState(CesiDefaultConsulta);
	const { regBitacoraNotOld } = useBitacora();

	const disabledSubmit = (): boolean => {
		return (
			dataInfo.centro === '' &&
			dataInfo.horario === '' &&
			dataInfo.estado === '' &&
			dataInfo.fecha === null
		);
	};

	const getEstados = async () => {
		try {
			setMessage('');
			setLoading(true);
			const data = {
				pServiceId: SERVICESID,
			};
			const dataEncrypt = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const response = await writingRequestAPI.statusService(dataEncrypt);

			if (response?.err) {
				setMessage(
					response?.err?.description ||
						INTERNAL_MESSAGES.ERROR_MESSAGE
				);
				return;
			}

			if (
				response?.data?.regiones &&
				response?.data?.regiones.length > 0
			) {
				const estados = response.data.regiones
					.sort((a: any, b: any) => {
						if (a.regionDes > b.regionDes) {
							return 1;
						}
						if (a.regionDes < b.regionDes) {
							return -1;
						}
						return 0;
					})
					.map((opt: any) => {
						return {
							label: opt.regionDes,
							value: opt.regionCode,
						};
					});
				setEstados(estados);
				await getConsultCita();
				return;
			}
			setMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
		} catch (error) {
			setMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const getCesis = async (entidadFederativaId: string) => {
		try {
			setMessage('');
			setLoading(true);
			const data = {
				entidadFederativaId,
				pServiceId: SERVICESID,
			};
			const dataEncrypt = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const response = await writingRequestAPI.cesis(dataEncrypt);
			if (response?.code === CODE_RESPONSE.SUCCESS) {
				if (
					response?.data?.sucursales &&
					response?.data?.sucursales.length > 0
				) {
					const cedis = response.data.sucursales
						.sort((a: any, b: any) => {
							if (
								a.organizationDescription >
								b.organizationDescription
							) {
								return 1;
							}
							if (
								a.organizationDescription <
								b.organizationDescription
							) {
								return -1;
							}
							return 0;
						})
						.map((opt: any) => {
							return {
								label: opt.organizationDescription,
								value: opt.organizationCode,
							};
						});
					setCedis(cedis);
					return;
				}
				setMessage(MESSAGES_ERROR.errorSucursales);
				return;
			}

			setCedis([]);
			setMessage(response?.message || INTERNAL_MESSAGES.ERROR_MESSAGE);
		} catch (error) {
			setMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const getDocuments = async (pOrganizationCode: string) => {
		try {
			setMessage('');
			setLoading(true);
			const params = {
				pOrganizationCode,
				pServiceId: SERVICESID,
			};
			const dataEncrypt = await encodeAllJSONData(
				{
					data: JSON.stringify(params),
				},
				user?.k || ''
			);
			const result = await writingRequestAPI.documentosRequeridos(
				dataEncrypt
			);
			if (result.code === CODE_RESPONSE.SUCCESS) {
				const { requirementText } = result?.data?.requirementsList[0];
				const docsRequired = requirementText
					.replaceAll('&bull;', '')
					.replaceAll('• ', '')
					.split('<br/>');
				setDocRequeridos(
					docsRequired.filter(
						(f: any) =>
							f !==
							'Cualquier de los siguientes documentos dependiendo cómo se llevará a cabo el trámite'
					)
				);
				return;
			}
			setMessage(result?.message || INTERNAL_MESSAGES.ERROR_MESSAGE);
		} catch (ex) {
			setMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
		} finally {
			setLoading(false);
		}
	};

	const getCalendario = async (fecha: Date, centroAtencionId: string) => {
		try {
			setMessage('');
			setLoading(true);
			const month = (fecha || new Date()).getMonth() + 1;
			const year = (fecha || new Date()).getFullYear();
			const params = {
				pMonth: month.toString(),
				pYear: year.toString(),
				servicioId: SERVICESID,
				centroAtencionId,
				grupoClienteId: '1',
			};
			const dataEncrypt = await encodeAllJSONData(
				{
					data: JSON.stringify(params),
				},
				user?.k || ''
			);
			const response = await writingRequestAPI.meses(dataEncrypt);

			if (response.code === CODE_RESPONSE.SUCCESS) {
				let arrayMes: any = [];
				let Status: string = 'none';
				let contMes: number = 0;
				setAgendaId(response?.data?.agendaId);
				if (
					response?.data?.monthDays &&
					response?.data?.monthDays.length > 0
				) {
					response.data.monthDays.forEach((mes: any) => {
						if (Status !== mes.available) {
							if (mes.available === false) {
								arrayMes[contMes] = {
									startday: mes.dateTime,
									disabled: true,
									endday: null,
									typeday: 'baja',
								};
							} else {
								arrayMes[contMes] = {
									startday: mes.dateTime,
									endday: null,
									typeday: 'alta',
								};
							}
							contMes++;
							Status = mes.available;
						} else {
							const mesAnt = contMes - 1 < 0 ? 0 : contMes - 1;
							arrayMes[mesAnt] = {
								...arrayMes[mesAnt],
								endday: mes.dateTime,
							};
						}
					});
					setMeses(arrayMes);
					return;
				}
				setMessage(MESSAGES_ERROR.errorDays);
				return;
			}
			setMessage(
				response.err?.description || INTERNAL_MESSAGES.ERROR_MESSAGE
			);
		} catch (error) {
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const getHorarios = async (fecha: Date) => {
		try {
			setMessage('');
			setLoading(true);
			const day = String((fecha || new Date()).getDate()).padStart(
				2,
				'0'
			);
			const month = String((fecha || new Date()).getMonth() + 1).padStart(
				2,
				'0'
			);
			const year = (fecha || new Date()).getFullYear();
			const params = {
				servicioId: SERVICESID,
				pDate: year + '-' + month + '-' + day || '',
				agendaId: agendaId,
			};
			const dataEncrypt = await encodeAllJSONData(
				{
					data: JSON.stringify(params),
				},
				user?.k || ''
			);
			const response = await writingRequestAPI.horario(dataEncrypt);
			if (response.code === CODE_RESPONSE.SUCCESS) {
				if (
					response?.data?.intervals &&
					response.data?.intervals.length > 0
				) {
					const horarios = response.data.intervals.map(
						(hora: any) => {
							return {
								...hora,
								label:
									getHourParse(hora.horarioInicio) +
									' - ' +
									getHourParse(hora.horarioFin),
								value: hora.horarioInicio,
							};
						}
					);
					setHorarios(horarios);
					return;
				}
				setMessage(MESSAGES_ERROR.errorHorario);
				return;
			}
			setMessage(response?.message || MESSAGES_ERROR.errorService);
		} catch (error) {
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const handleChange = (key: string, value: any) => {
		if (key === 'estado') {
			setDataInfo({
				...CesiDefaultConsulta,
				[key]: value,
			});
			setCedis([]);
			setMeses([]);
			setHorarios([]);
			getCesis(value);
			return;
		}
		if (key === 'centro') {
			setDataInfo({
				...dataInfo,
				fecha: null,
				horario: '',
				[key]: value,
			});
			setMeses([]);
			setHorarios([]);
			getDocuments(value);
			getCalendario(new Date(), value);
			return;
		}
		if (key === 'fecha') {
			setDataInfo({
				...dataInfo,
				horario: '',
				[key]: value,
			});
			setHorarios([]);
			getHorarios(value);
			return;
		}
		if (key === 'mountChange') {
			setDataInfo({
				...dataInfo,
				fecha: value,
				horario: '',
			});
			setMeses([]);
			setHorarios([]);
			getCalendario(value, dataInfo.centro);
			return;
		}
	};

	const handleCreateCita = async (responseRsv: any, horaFind: any) => {
		const day = String(dataInfo.fecha?.getDate()).padStart(2, '0');
		const month = String((dataInfo.fecha?.getMonth() || 0) + 1).padStart(
			2,
			'0'
		);
		const year = dataInfo.fecha?.getFullYear();
		const hr = dataInfo.horario.split(':', 3);
		const data = {
			pCentroAtencionId: dataInfo.centro,
			pServicioId: SERVICESID,
			pDate: `${year}-${month}-${day} ${hr[0]}:${hr[1]}:00`,
			pHour: hr[0],
			pMinute: hr[1],
			pReservationId: responseRsv.data.appointmentReservationId,
			agendaId: agendaId,
			intervaloId: horaFind?.intervaloId,
			cupoCanalId: horaFind?.cupoCanalId,
			canalId: '4',
			numeroDeCupo: horaFind?.numeroDeCupo,
			noCaso: escrituras.noCaso,
		};
		const dataEncrypt = await encodeAllJSONData(
			{
				data: JSON.stringify(data),
			},
			user?.k || ''
		);
		const response = await writingRequestAPI.crear(dataEncrypt);
		if (response.code === CODE_RESPONSE.SUCCESS && response.data) {
			regBitacoraNotOld(writingRequestAPI.schemeUrl, data);
			setPage(CESI_QUOTE_STATUS.DETAIL);
			return;
		}
		setMessage(response.message || MESSAGES_ERROR.errorService);
	};

	const handleUpdateCita = async (responseRsv: any, horaFind: any) => {
		let day = String(dataInfo.fecha?.getDate()).padStart(2, '0');
		let month = String((dataInfo.fecha?.getMonth() || 0) + 1).padStart(
			2,
			'0'
		);
		let year = dataInfo.fecha?.getFullYear();
		const hr = dataInfo.horario.split(':', 3);
		const data = {
			citaId: escrituras.citaId,
			motivoReprogracion: '',
			tipoReprogramacion: '1',
			pCentroAtencionId: dataInfo.centro,
			pServicioId: SERVICESID,
			pDate: `${year}-${month}-${day} ${hr[0]}:${hr[1]}:00`,
			pHour: hr[0],
			pMinute: hr[1],
			pReservationId: responseRsv.data.appointmentReservationId,
			agendaId: agendaId,
			intervaloId: horaFind.intervaloId,
			cupoCanalId: horaFind.cupoCanalId,
			canalId: '4',
			numeroDeCupo: horaFind.numeroDeCupo,
		};
		const dataEncrypt = await encodeAllJSONData(
			{
				data: JSON.stringify(data),
			},
			user?.k || ''
		);
		const response = await writingRequestAPI.actualizar(dataEncrypt);
		if (response.code === CODE_RESPONSE.SUCCESS && response.data) {
			regBitacoraNotOld(writingRequestAPI.schemeUrl, data);
			setPage(CESI_QUOTE_STATUS.DETAIL);
			return;
		}
		setMessage(response.message || MESSAGES_ERROR.errorService);
	};

	const handleConfirm = async () => {
		try {
			setMessage('');
			setLoading(true);
			const horaFind: any = horarios.find(
				(f: any) => dataInfo.horario === f.value
			);
			let day = String(dataInfo.fecha?.getDate()).padStart(2, '0');
			let month = String((dataInfo.fecha?.getMonth() || 0) + 1).padStart(
				2,
				'0'
			);
			let year = dataInfo.fecha?.getFullYear();
			const hr = dataInfo.horario.split(':', 3);
			const data = {
				pRequestedDate: `${year}-${month}-${day} ${hr[0]}:${hr[1]}:00`,
				servicioId: SERVICESID,
				agendaId: agendaId,
				numeroCupo: horaFind?.numeroDeCupo,
			};
			const dataEncrypt = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const responseRsv = await writingRequestAPI.reservar(dataEncrypt);
			if (
				responseRsv.code === CODE_RESPONSE.SUCCESS &&
				responseRsv?.data
			) {
				if (escrituras.citaId) {
					await handleUpdateCita(responseRsv, horaFind);
					return;
				}
				await handleCreateCita(responseRsv, horaFind);
				return;
			}
			setMessage(responseRsv.message || MESSAGES_ERROR.errorService);
		} catch (error) {
			setMessage(MESSAGES_ERROR.errorService);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getEstados();
		/* eslint-disable react-hooks/exhaustive-deps */
	}, []);

	return (
		<>
			<ModalLoading loading={loading} />
			<Box className={styles.boxMainCesiGeneral}>
				<Box className={styles.container2}>
					<span className={styles.labelColorPrimary}>
						Haz una cita
					</span>
				</Box>
				<Box className={styles.container2}>
					<span>Número de caso</span>
					<Box>{escrituras.noCaso}</Box>
				</Box>
				<Box className={styles.container2}>
					<span>Fecha de solicitud</span>
					<Box>{getDateParse(escrituras.fecha)}</Box>
				</Box>
				<Box className={styles.container2}>
					<span>Servicio</span>
					<Box>Solicitud de escritura</Box>
				</Box>
				<Box className={styles.container2}>
					<span>Estatus</span>
					<Box>{ESTATUS[escrituras.estatus]?.label}</Box>
				</Box>
				<Box className={styles.container2}>
					<span>Fecha de estatus</span>
					<Box>{getDateParse(escrituras.fechaEstatus)}</Box>
				</Box>
				<Box className={styles.container}>
					<span>Descripción</span>
					<Box>
						Tu cita es personal y cuando acudas a ella debes
						presentar tu identificación oficial vigente, requisito
						indispensable para efectuar tu trámite. Te recomendamos
						que llegues con 10 minutos de anticipación para que no
						corras el riesgo de perder tu cita y tengas que
						programar una nueva. Si por alguna razón no puedes
						acudir, reprogramarla o cancelarla.
					</Box>
				</Box>
				<Box className={styles.container}>
					<span>Documentos necesarios</span>
					<Box>
						<p className={styles.labelItem}>
							Identificación oficial vigente
						</p>
						Presenta alguna de la lista
						<ul className={styles.ulList}>
							{docRequeridos.map((doc, k) => (
								<li key={k}>{doc}</li>
							))}
						</ul>
					</Box>
				</Box>
				<Box className={styles.container2}>
					<span>Ubicación</span>
					<Box>
						<SelectCustom
							label="Estado"
							name="estado"
							value={dataInfo.estado}
							options={estados}
							onChange={(e) =>
								handleChange('estado', e.target.value)
							}
						/>
					</Box>
					<Box>
						<SelectCustom
							label="Centro de servicio"
							name="centro"
							disabled={cedis.length === 0}
							value={dataInfo.centro}
							options={cedis}
							onChange={(e) =>
								handleChange('centro', e.target.value)
							}
						/>
					</Box>
				</Box>
				<Box className={styles.container2}>
					<span>Fecha de estatus</span>
					<Box>
						<CalenderCustom
							meses={meses}
							fecha={dataInfo.fecha}
							onMonthChange={(arg) => {
								handleChange('mountChange', arg);
							}}
							onChange={(newValue) =>
								handleChange('fecha', newValue)
							}
						/>
					</Box>
					<Box>
						<SelectCustom
							disabled={horarios.length === 0}
							label="Seleccionar horario"
							name="horario"
							value={dataInfo.horario}
							options={horarios}
							onChange={(e) =>
								setDataInfo({
									...dataInfo,
									[e.target.name]: e.target.value,
								})
							}
						/>
						<CalenderText />
					</Box>
				</Box>

				<CustomAlert
					message={message}
					severity="warning"
					show={Boolean(message)}
				/>

				<Box className={styles.boxButtonsGroup}>
					<Box className={styles.buttonItem}>
						<CustomButton
							label={'Agendar cita'}
							disabled={disabledSubmit()}
							onClick={handleConfirm}
						/>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default CesiQuoteGeneral;
