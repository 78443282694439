import { useState } from 'react';
import {
	Box,
	Paper,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
} from '@mui/material';
import iconAd from '../../../assets/img/admiracion-icon.png';

import { useStyles } from '../../NipInfonatel/styles';

import { useNavigate } from 'react-router-dom';
import CustomButtom from '../../../components/Button';
import UnlockType from './unlockType';
import ChangeNip from '../ChangeNip/index';
import CancellationPin from '../cancellationPin/index';
import LockNip from '../LockNip';

interface Validations {
	[index: number]: string;
}

interface IData {
	nip: string;
	blockNip: boolean;
	nipM: string;
	validations: Validations;
	nipsRecents: Validations;
}

const PinBlocked = ({ nip, blockNip, nipM, validations, nipsRecents }: IData) => {
	const [typeInformation, setTypeInformation] = useState(0);
	const [page, setPage] = useState(0);
	const navigate = useNavigate();
	const style = useStyles();

	const handleContinuar = () => {
		setPage(typeInformation);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setTypeInformation(parseInt(value));
	};

	return (
		<>
			<Paper>
				{page === 0 && (
					<>
						<br />
						{blockNip === true && (
							<Box className={style.containerCheck}>
								<h2>Tu NIP Infonatel está bloquedo</h2>
							</Box>
						)}

						<Box className={style.nipTitle}>
							{/* <h2>Tu NIP es : {nipM}</h2> */}
							{/* <span style={{ fontSize: '24px' }}>{nipM}</span> */}
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'center'
								}}
							>
								<h2>
									Tu NIP es:&nbsp;&nbsp;
									<span style={{ fontSize: '32px', fontWeight: 'bold' }}>{' '}{nipM}</span>
								</h2>
							</div>
							<p className={style.maxContent} style={{ margin: '0px auto 20px' }}>y puedes elegir una de las siguientes opciones.</p>
						</Box>

						<Box className={style.unlokNipContainerFormControl}>
							<FormControl>
								<RadioGroup
									aria-labelledby="type"
									name="typeRadio"
									value={typeInformation}
									onChange={handleChange}
								>
									{blockNip === true ? (
										<div>
											<FormControlLabel
												value="2"
												label="Desbloqueo de NIP"
												control={<Radio />}
											/>
											<Box
												style={{
													display: 'flex',
													alignItems: 'center',
													marginLeft: 10,
													marginRight: 30,
												}}
											>
												<img
													src={iconAd}
													alt="ícono de ayuda"
													style={{
														width: 15,
														marginRight: 5,
													}}
												/>
												<p
													style={{
														fontSize: '14px',
														width: '100%',
														color: '#293990',
													}}
												>
													Selecciona esta opción si
													recuerdas el NIP
												</p>
											</Box>
										</div>
									) : (
										<div>
											<FormControlLabel
												value="1"
												label="Bloqueo de NIP"
												control={<Radio />}
											/>
											<Box
												style={{
													display: 'flex',
													alignItems: 'center',
													marginLeft: 10,
													marginRight: 30,
												}}
											>
												<img
													src={iconAd}
													alt="ícono de ayuda"
													style={{
														width: 15,
														marginRight: 5,
													}}
												/>
												<p
													style={{
														fontSize: '14px',
														width: '100%',
														color: '#293990',
													}}
												>
													Selecciona esta opción si
													quieres bloquer tu NIP
												</p>
											</Box>
										</div>
									)}
									<div>
										<FormControlLabel
											value="3"
											label="Cambio de NIP"
											control={<Radio />}
										/>
										<Box
											style={{
												display: 'flex',
												alignItems: 'center',
												marginLeft: 10,
												marginRight: 30,
											}}
										>
											<img
												src={iconAd}
												alt="ícono de ayuda"
												style={{
													width: 15,
													marginRight: 10,
												}}
											/>
											<p
												style={{
													fontSize: '14px',
													width: '150%',
													color: '#293990',
												}}
											>
												Seleciona esta opción en caso de
												no recordar tu NIP
											</p>
										</Box>
									</div>
									<div>
										<FormControlLabel
											value="4"
											label="Cancelación de NIP"
											control={<Radio />}
										/>
										<Box
											style={{
												display: 'flex',
												alignItems: 'center',
												marginLeft: 10,
												marginRight: 30,
											}}
										>
											<img
												src={iconAd}
												alt="ícono de ayuda"
												style={{
													width: 15,
													marginRight: 10,
												}}
											/>
											<p
												style={{
													fontSize: '14px',
													width: '150%',
													color: '#293990',
												}}
											>
												Seleciona esta opción si quieres
												cancelar tu NIP
											</p>
										</Box>
									</div>
								</RadioGroup>
							</FormControl>
						</Box>

						<Box className={style.containerButtonsEnd}>
							<Box
								margin={'10px'}
								width={260}
							>
								<CustomButtom
									label={'Salir'}
									onClick={() => navigate('/')}
									variant="outlined"
									styles={{ height: '40px' }}
								/>
							</Box>
							<Box
								margin={'10px'}
								width={260}
							>
								<CustomButtom
									label={'Continuar'}
									onClick={handleContinuar}
									variant="solid"
									disabled={
										typeInformation > 0 ? false : true
									}
								/>
							</Box>
						</Box>
					</>
				)}
			</Paper>
			{page === 1 && (
				<LockNip
					nip={nip}
					blockNip={blockNip}
					typeInformation={typeInformation}
				/>
			)}
			{page === 2 && (
				<UnlockType
					nip={nip}
					blockNip={blockNip}
					typeInformation={typeInformation}
				/>
			)}
			{page === 3 && (
				<ChangeNip
					nip={nip}
					blockNip={blockNip}
					typeInformation={typeInformation}
					validations={validations}
					nipsRecents={nipsRecents}
				/>
			)}
			{page === 4 && (
				<CancellationPin
					nip={nip}
					blockNip={blockNip}
					typeInformation={typeInformation}
				/>
			)}
		</>
	);
};

export default PinBlocked;
