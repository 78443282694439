/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { address_base64, defaultAddressForm, IAlert } from '../../utils/utils';
import { INTERNAL_MESSAGES } from '../../../../../config/messageCatalog';
import { encrypt } from '../../../../../utils/encrypt';
import {
	contactRequestAPI,
	profileAPI,
} from '../../../../../api/modules/profile';
import { RootState } from '../../../../../store';
import { useBitacora } from '../../../../../hooks/useBitacora';
import { CONTACT_ACTIONS } from '../../../../../config/actions-bitacora/Contact-actions';

export const useContactAddress = () => {
	const { user } = useSelector((state: RootState) => state.session);

	const [address, setAddress] = useState(defaultAddressForm);
	const [errors, setErrors] = useState(defaultAddressForm);
	const [loader, setLoader] = useState(false);
	const [alert, setAlert] = useState<IAlert>({ message: '', color: 'error' });
	const [stateOptions, setStateOptions] = useState<any>([]);
	const [delegacionOptions, setDelegacionOptions] = useState<any>([]);
	const [coloniaOptions, setColoniaOptions] = useState<any>([]);
	const { setDataOld, regBitacora } = useBitacora();

	const fetchAddressData = async () => {
		try {
			setLoader(true);
			const USER_NSS_BASE64 = await encrypt(
				user?.nss?.toString() || '',
				user?.k || ''
			);
			const resFetchAddress = await profileAPI.fetchAddressInfo({
				nss: USER_NSS_BASE64,
			});
			if (resFetchAddress?.result?.cp) {
				let fetchData: any = {};
				const ZIPCODE_BASE64 = await encrypt(
					resFetchAddress?.result?.cp || ' ',
					user?.k || ''
				);
				const resFetchCatalog = await profileAPI.fetchAddressCatalog({
					cp: ZIPCODE_BASE64,
				});
				if (Array.isArray(resFetchCatalog?.result)) {
					setStateOptions([
						{
							value: resFetchCatalog?.result[0]
								?.entidadFederativa,
							text: resFetchCatalog?.result[0]
								?.denominacionSociedad,
						},
					]);
					fetchData = {
						...resFetchAddress?.result,
						state: resFetchCatalog?.result[0]?.entidadFederativa,
					};
				} else {
					setStateOptions([
						{
							value: resFetchCatalog?.result?.entidadFederativa,
							text: resFetchCatalog?.result?.denominacionSociedad,
						},
					]);
					fetchData = {
						...resFetchAddress?.result,
						state: resFetchCatalog?.result?.entidadFederativa,
						delegacion: resFetchCatalog?.result?.estadoMunicipio,
					};
					setDelegacionOptions([
						{
							value: resFetchCatalog?.result?.estadoMunicipio,
							text: resFetchCatalog?.result?.delegacion,
						},
					]);
					const _Colonias = resFetchCatalog?.result?.colonias.map(
						(value: any) => {
							return {
								value: value,
								text: value,
							};
						}
					);
					setColoniaOptions(_Colonias);
				}
				setAddress(fetchData);
				setDataOld(CONTACT_ACTIONS.UPDATE_ADRESS, fetchData);
				return;
			}
		} catch (error: any) {
			setAlert({
				message: error?.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				color: 'error',
			});
			setLoader(false);
		} finally {
			setLoader(false);
		}
	};

	const getAddressCatalog = async () => {
		if (address.cp.length === 5) {
			console.log(address);
			setAlert({
				message: '',
				color: 'error',
			});
			try {
				setLoader(true);
				const ZIPCODE_BASE64 = await encrypt(
					address.cp || ' ',
					user?.k || ''
				);
				const resFetchCatalog = await profileAPI.fetchAddressCatalog({
					cp: ZIPCODE_BASE64,
				});
				if (Array.isArray(resFetchCatalog?.result)) {
					setStateOptions([
						{
							value: resFetchCatalog?.result[0]
								?.entidadFederativa,
							text: resFetchCatalog?.result[0]
								?.denominacionSociedad,
						},
					]);
					setAddress({
						...address,
						state: resFetchCatalog?.result[0]?.entidadFederativa,
					});
				} else {
					setStateOptions([
						{
							value: resFetchCatalog?.result?.entidadFederativa,
							text: resFetchCatalog?.result?.denominacionSociedad,
						},
					]);
					setAddress({
						...address,
						state: resFetchCatalog?.result?.entidadFederativa,
						delegacion: resFetchCatalog?.result?.estadoMunicipio,
					});
					setDelegacionOptions([
						{
							value: resFetchCatalog?.result?.estadoMunicipio,
							text: resFetchCatalog?.result?.delegacion,
						},
					]);
					const _Colonias = resFetchCatalog?.result?.colonias.map(
						(value: any) => {
							return {
								value: value,
								text: value,
							};
						}
					);
					setColoniaOptions(_Colonias);
				}
			} catch (error) {
			} finally {
				setLoader(false);
			}
		} else {
			setAddress({ ...address, state: '', delegacion: '', colony: '' });
		}
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		setAddress({ ...address, [name]: value });
		if (!value) setErrors({ ...errors, [name]: 'Campo obligatorio' });
		else setErrors({ ...errors, [name]: '' });
	};

	const handleConfirm = async () => {
		try {
			setLoader(true);
			const dataOrigin = { ...address, nss: user?.nss?.toString() || '' };
			const data_base64 = await address_base64(dataOrigin, user?.k || '');
			contactRequestAPI.updateAddress
				.post<
					{
						nss: string;
						cp: string;
						state: string;
						delegacion: string;
						colony: string;
						street: string;
						noExterior: string;
						noInterior: string;
					},
					any
				>('', data_base64)
				.then((res: any) => {
					if (res?.ok) {
						setAlert({
							message: INTERNAL_MESSAGES.APPLICATION_COMPLETED,
							color: 'success',
						});
						regBitacora({
							key: CONTACT_ACTIONS.UPDATE_ADRESS,
							scheme: contactRequestAPI.updateAddress.scheme,
							valorNuevo: address,
						});
					} else {
						setAlert({
							message: INTERNAL_MESSAGES.ERROR_MESSAGE,
							color: 'error',
						});
					}
				})
				.catch((e) => {
					setAlert({
						message: INTERNAL_MESSAGES.ERROR_MESSAGE,
						color: 'error',
					});
				})
				.finally(() => setLoader(false));
		} catch (error: any) {
			setAlert({
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				color: 'error',
			});
		}
	};

	const handleClose = () => {
		setAlert({ message: '', color: 'success' });
	};

	useEffect(() => {
		getAddressCatalog();
	}, [address.cp]);

	useEffect(() => {
		fetchAddressData();
	}, []);

	return {
		loader,
		alert,
		stateOptions,
		delegacionOptions,
		coloniaOptions,
		handleChange,
		handleConfirm,
		handleClose,
		address,
		errors,
	};
};
