// DEPENDENCIES
import { WarningRounded } from '@mui/icons-material';
// COMPONENTS
import BaseModal from '../../../components/Modal';
// RESOURCES
import { RoundBtn } from '../../AssociateNssCredit/Styles';

const ReestructureAlert = ({ open, setOpen, onConfirm }: any) => {
	return (
		<BaseModal open={open} onClose={() => setOpen(!open)} showDialogTitle width="sm">
			<div style={{ textAlign: 'center', paddingTop: 22 }}>
				<WarningRounded
					fontSize="inherit"
					style={{ fontSize: 65, color: '#fac862' }}
				/>
				<br />
				<p>
					Actualmente tienes una reestructura vigente por la que para aplicar
					las nuevas condiciones del programa de Responsabilidad Compartida, que
					ya revisaste, se procederá a cancelarla de manera automática.
				</p>
				<p>¿Quieres continuar?</p>
				<div>
					<RoundBtn
						style={{ maxWidth: 250 }}
						disableElevation
						variant="outlined"
						onClick={() => setOpen(!open)}
					>
						No
					</RoundBtn>
					<RoundBtn
						style={{ maxWidth: 250 }}
						disableElevation
						variant="contained"
						onClick={onConfirm}
					>
						Si
					</RoundBtn>
				</div>
			</div>
		</BaseModal>
	);
};

export default ReestructureAlert;
