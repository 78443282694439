import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('103', 'restablece-tu-contrasena');

export const ENDPOINTS_RESTABLECE_TU_CONTRASENIA: IBitacora = {
    '/send-message': _bitacora.Read('103100', 'Envio de mensaje'),
    '/valid-code': _bitacora.Read('103101', 'Validar codigo'),
    '/reset-password': _bitacora.Read('103102', 'Restablecer contrasena'),
    '/delete-account': _bitacora.Delete('103103', 'Eliminar cuenta'),
    '/password-recovery': _bitacora.Update('103104', 'Recuperar contrasena'),
    '/valid-code-email': _bitacora.Read('103105', 'Validar codigo por correo'),
    '/valid-check-nss': _bitacora.Read('103106', 'Validar NSS'),
    '/death-mark': _bitacora.Read('103107', 'Validar marca'),
    '/reset-password-email': _bitacora.Update('103108', 'Actualizar contraseña'),
};
