import axios from 'axios';
import { VARIABLES_BITACORA } from '../config/bitacora';
import { IApiBitacora, ITypeBitacora } from '../interfaces/bitacora';

export const ApiBitacora = async ({ data, headers }: IApiBitacora) => {
	axios.post('/v1/bitacora/registrar', data, {
		baseURL: process.env.REACT_APP_API_URL,
		headers: {
			...headers,
		},
	});
};

export const getHeadersByScheme = (scheme: string) => {
	try {
		const pathScheme = scheme[0] !== '/' ? '/' + scheme : scheme;
		const schemeBit = VARIABLES_BITACORA[pathScheme];
		if (schemeBit) {
			return {
				'mci-accion': schemeBit?.ACTION || '',
				'mci-tipo': schemeBit?.TYPE || '',
				'mci-id-tipo': schemeBit?.ID_TYPE || '',
				'mci-evento': schemeBit?.EVENT || '',
				'mci-id-evento': schemeBit?.ID_EVENT || '',
			};
		}
		return {
			'mci-accion': '',
			'mci-tipo': '',
			'mci-id-tipo': '',
			'mci-evento': '',
			'mci-id-evento': '',
		};
	} catch (error) {
		return {
			'mci-accion': '',
			'mci-tipo': '',
			'mci-id-tipo': '',
			'mci-evento': '',
			'mci-id-evento': '',
		};
	}
};

export const getHeadersByReferences = (reference: ITypeBitacora) => {
	try {
		return {
			'mci-accion': reference?.ACTION || '',
			'mci-tipo': reference?.TYPE || '',
			'mci-id-tipo': reference?.ID_TYPE || '',
			'mci-evento': reference?.EVENT || '',
			'mci-id-evento': reference?.ID_EVENT || '',
		};
	} catch (error) {
		return {
			'mci-accion': '',
			'mci-tipo': '',
			'mci-id-tipo': '',
			'mci-evento': '',
			'mci-id-evento': '',
		};
	}
};
