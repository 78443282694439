import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		margin: '20px 0px',

        [theme.breakpoints.down('md')]: {
			padding: '20px',
		},
	},
	textHeader: {
		textAlign: 'center',
		color: '#283990',
        marginBottom: 30,
        fontWeight: 500,
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'center',
        marginTop: 10,

		[theme.breakpoints.down('md')]: {
			flexDirection: 'column-reverse',
		},
	},
	buttonItem: {
		width: 200,
		margin: '10px',

		[theme.breakpoints.down('md')]: {
			width: 'auto',
		},
	},
}));
