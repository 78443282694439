import { makeStyles } from '@mui/styles';
import { Theme, styled } from '@mui/material/styles';
import StepConnector, {
	stepConnectorClasses,
} from '@mui/material/StepConnector';
import { CheckCircleOutlineOutlined } from '@mui/icons-material';
import {
	StepContent,
	StepIconProps,
	StepLabel,
	stepLabelClasses,
} from '@mui/material';
import theme from '../../../../config/theme';

export const useStyles = makeStyles((theme: Theme) => ({
	iconAttach: {
		fontSize: 16,
		margin: '0px 2px',
	},
	inputFileAttach: {
		display: 'none',
		width: '100%',
		height: '100%',
	},
	titleAttach: {
		margin: 0,
		fontWeight: 500,
		padding: '0px 10px',
	},
	labelAttach: {
		fontWeight: 500,
		margin: 0,
		color: '#283990',
	},
	containerPaperAttach: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
	containerAttchMain: {
		padding: '10px 25px',

		[theme.breakpoints.down('md')]: {
			padding: '5px',
		},
	},
	containerAttch: {
		textAlign: 'center',
		marginTop: 2,
		display: 'block',
	},
	boxButtonsGroupAttach: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'row',
		marginTop: 2,
		gap: 4,

		[theme.breakpoints.down('md')]: {
			flexDirection: 'column-reverse',
		},
	},
	sinMarginP: {
		margin: 0,
	},
	labelCaseFollowHome: {
		fontWeight: 500,
		textAlign: 'center',
		marginTop: '30px',
	},
	containerCaseFollowHome: {
		maxWidth: '950px',
		margin: '0px auto',

		[theme.breakpoints.down('md')]: {
			maxWidth: '100%',
		},
	},
	containerCaseFollowUp: {
		padding: '10px 20px',
	},
	labelDownload: {
		display: 'flex',
		justifyContent: 'center',
		color: '#DD0528',
		marginBottom: 2,
		cursor: 'pointer',
		userSelect: 'none',
	},
	imgDownload: {
		marginLeft: 5,
		width: 24,
	},
	labelCenter: {
		textAlign: 'center',
	},
	headerLabel: {
		display: 'flex',
		justifyContent: 'center',
	},
	labelColorPrimary: {
		color: '#283990',
	},
	boxMainCesiGeneral: {
		padding: '15px',
	},
	labelItem: {
		margin: 0,
		fontWeight: 500,
	},
	ulList: {
		padding: '0px 0px 0px 16px',
		margin: '5px 0px 0px 0px',
		color: 'gray',
	},
	boxButtonsGroup: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'row',

		[theme.breakpoints.down('md')]: {
			flexDirection: 'column-reverse',
		},
	},
	buttonItem: {
		width: 250,
		margin: '10px',

		[theme.breakpoints.down('md')]: {
			width: 'auto',
		},
	},
	boxMain: {
		padding: '20px',
	},
	titlePage: {
		color: '#283990',
		textAlign: 'center',
	},
	estatusItem: {
		margin: '0px 0px 10px 0px',
		fontWeight: 500,
	},
	pMargin: {
		margin: 0,
	},
	stepRoot: {
		borderLeftStyle: 'dotted',
		borderLeftcolor: '#283990',
	},
	containerAttachDoc: {
		display: 'flex',
		flexDirection: 'row',
		borderBottom: '1px solid rgba(0,0,0,0.1)',
		padding: '10px',
		margin: '10px 0px',

		'& span': {
			fontWeight: 500,
			flexGrow: 1,
		},

		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
		},
	},
	container: {
		display: 'grid',
		gridTemplateColumns: '280px auto',
		gap: '20px',
		borderBottom: '1px solid rgba(0,0,0,0.1)',
		padding: '10px',
		margin: '10px 0px',

		'& span': {
			fontWeight: 500,
		},

		[theme.breakpoints.down('md')]: {
			gridTemplateColumns: '1fr',
		},
	},
	container2: {
		display: 'grid',
		gridTemplateColumns: '280px minmax(100px, 1fr) minmax(100px, 1fr)',
		gap: '20px',
		borderBottom: '1px solid rgba(0,0,0,0.1)',
		padding: '10px',
		margin: '10px 0px',

		'& span': {
			fontWeight: 500,
		},

		[theme.breakpoints.down('md')]: {
			gridTemplateColumns: '1fr',
		},
	},
	container3: {
		display: 'flex',
		flexDirection: 'row',
		borderBottom: '1px solid rgba(0,0,0,0.1)',
		padding: '10px',
		margin: '10px 0px',

		'& p': {
			margin: 0,
			color: '#808080',
		},

		'& section.title': {
			width: '480px',
		},

		'& section.file': {
			flexGrow: 1,
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},

		'& span': {
			fontWeight: 500,
		},

		[theme.breakpoints.down('md')]: {
			'& section.title': {
				width: '100%',
			},
			flexDirection: 'column',
		},
	},
	containerWhitout: {
		width: '100%',
		borderBottom: '1px solid rgba(0,0,0,0.1)',
		padding: '10px',
		margin: '10px 0px',
	},
	formFile: {
		display: 'flex',
		justifyContent: 'end',
		minWidth: 250,

		'& label': {
			display: 'flex',
			alignItems: 'center',
			color: '#DD0528',
			cursor: 'pointer',
			margin: '0px 5px',
		},

		[theme.breakpoints.down('md')]: {
			justifyContent: 'center',
			minWidth: 'auto',
		},
	},
    title: {
        padding: 0,
		margin: 0,
        fontWeight: 500,
    },
	justificacion: {
		padding: 0,
		margin: 0,
        fontWeight: 500,
	},
    message: {
        padding: 0,
		margin: '10px 0px',
    }
}));

export const QontoConnector = styled(StepConnector)(({ theme }) => ({
	[`& .${stepConnectorClasses.line}`]: {
		borderLeftColor: '#283990',
		borderLeftStyle: 'dotted',
	},
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
	({ theme, ownerState }) => ({
		display: 'flex',
		height: 22,
		alignItems: 'center',
		'& .QontoStepIcon-completedIcon': {
			color: '#283990',
			zIndex: 1,
			fontSize: 18,
		},
		'& .QontoStepIcon-basicIcon': {
			color: ownerState.active ? '#4caf50' : '#283990',
			zIndex: 1,
			fontSize: 18,
			opacity: ownerState.active ? 1 : 0.5,
		},
	})
);

export const QontoStepIcon = (props: StepIconProps) => {
	const { active, completed, className } = props;

	return (
		<QontoStepIconRoot ownerState={{ active }} className={className}>
			{completed ? (
				<CheckCircleOutlineOutlined className="QontoStepIcon-completedIcon" />
			) : (
				<CheckCircleOutlineOutlined className="QontoStepIcon-basicIcon" />
			)}
		</QontoStepIconRoot>
	);
};

export const StepLabelCustom = styled(StepLabel)(() => ({
	[`& .${stepLabelClasses.label}`]: {
		color: theme.palette.grey[700],

		[`&.${stepLabelClasses.active}`]: {
			color: '#4caf50',
		},
		[`&.${stepLabelClasses.completed}`]: {
			color: '#283990',
		},
	},
}));

export const StepContenCustom = styled(StepContent)({
	borderLeftColor: '#283990',
	borderLeftStyle: 'dotted',
});
