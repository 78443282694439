import { useBitacora } from "../../../../hooks/useBitacora";
import { useState } from "react";
import { IAlert } from "../../../../interfaces/alert";
import { keyAPI } from '../../../../api/modules/key';
import { encrypt } from '../../../../utils/encrypt';
import { unpairDeviceAPI } from '../../../../api/modules/unpairDevice';
import { IConfirmPasswordProps } from "../interface/props-confirm-password.interface";
import { PAGES_UNPAIR_DEVICE } from "../../utils/pages.utils";

export const useConfirmPassword = ({ dataRequest, setRequest, setPage }: IConfirmPasswordProps) => {
	const [loading, setLoading] = useState(false);
	const [password, setPassword] = useState('');
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'error',
	});
	const { regBitacoraNotOld, getMaskString } = useBitacora();

	//funcion para consumir api obtener informacion del dispositivo
	const getInformationDevice = async () => {
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const grupo_encript = await encrypt(dataRequest.grupo, key);
			const idCat_encript = await encrypt(dataRequest.id_cat, key);
			const password_encript = await encrypt(password, key);

			const { result: consultar } = await unpairDeviceAPI.consultDevices(
				grupo_encript,
				idCat_encript
			);

			const idDevice_encript = await encrypt(
				consultar?.dispositivosHistoricos[0] || '',
				key
			);

			const { result } = await unpairDeviceAPI.getInfoDevice(
				grupo_encript,
				idCat_encript,
				password_encript,
				idDevice_encript
			);

			if ((result.codigo_login || '').includes('Datos Incorrectos')) {
				setAlert({
					show: true,
					message:
						'La contraseña que escribiste es incorrecta. Inténtalo de nuevo.',
					severity: 'error',
				});
				return;
			}

			if ((result.codigo_login || '').includes('locked')) {
				setAlert({
					show: true,
					message: 'NSS bloqueado.',
					severity: 'error',
				});
				return;
			}

			if (Number(result.respuesta.code) === 1001) {
				setRequest({
					fechaCreacionTDS: result.fechaCreacionTDS,
					idDispositivo: result.idDispositivo,
					datetime_creation: result.datetime_creation,
				});
				regBitacoraNotOld(unpairDeviceAPI.schemeUrl, { password: getMaskString(password) });
				setPage(PAGES_UNPAIR_DEVICE.INFO_DEVICE);
				return;
			}

			if (Number(result.respuesta.code) === 1002) {
				regBitacoraNotOld(unpairDeviceAPI.schemeUrl, { password: getMaskString(password) });
				setPage(PAGES_UNPAIR_DEVICE.NOT_INFO_DEVICE);
				return;
			}

			setAlert({
				show: true,
				message:
					result.respuesta.message ||
					'Ha ocurrido un error al obtener los datos, intente nuevamente.',
				severity: 'error',
			});
		} catch (error) {
			setPage(PAGES_UNPAIR_DEVICE.SERVICE_NOT_AVAILABLE);
		} finally {
			setLoading(false);
		}
	};

	return {
		getInformationDevice,
		loading,
		setPassword,
		alert,
		password,
	}
}