import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	mainBox: {
		padding: '0px 20px',
	},
	labelBold: {
		fontWeight: 500,
	},
	btnGroup: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'row',

		[theme.breakpoints.down('md')]: {
			flexDirection: 'column-reverse',
		},
	},
	btnItem: {
		width: 250,
		margin: '10px',

		[theme.breakpoints.down('md')]: {
			width: 'auto',
		},
	},
	containerGroupInputs: {
		padding: '10px 50px',
	},
	containerInput: {
		padding: '10px 0px',
	},
}));
