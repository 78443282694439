import { useState } from 'react';
import { Box } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { useStyles } from '../NipInfonatel/styles';

import CustomAlert from '../../components/CustomAlert';
import CustomButtom from '../../components/Button';

import React from 'react';
import { IAlert } from '../../interfaces/alert';
import mail from '../../assets/img/mail.png';
import cel from '../../assets/img/cel.png';
import { isNumberValidate } from '../../utils/expressions';
import { nipInfonatelAPI } from '../../api/modules/NipInfonatel';
import { encrypt } from '../../utils/encrypt';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';

import ModalLoading from '../../components/ModalLoading';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useBitacora } from '../../hooks/useBitacora';

interface IPersonalData {
	onContinue: (codes: string) => void;
	isEmail: boolean;
	onCloseError?: () => void;
	reSend?: () => void;
	hasError?: IAlert;
	typeInformation: number;
	nip: string;
	action: string;
}
export const ActivationMedium = (props: IPersonalData) => {
	const { regBitacoraNotOld } = useBitacora();
	const { onContinue, typeInformation, isEmail, action } = props;
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [code1, setCode1] = React.useState({ valid: false, value: '' });
	const [code2, setCode2] = React.useState({ valid: false, value: '' });
	const [code3, setCode3] = React.useState({ valid: false, value: '' });
	const [code4, setCode4] = React.useState({ valid: false, value: '' });
	const [code5, setCode5] = React.useState({ valid: false, value: '' });
	const [code6, setCode6] = React.useState({ valid: false, value: '' });
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'error',
	});
	const { user } = useSelector((state: RootState) => state.session);

	const setFocusElementByName = (name: string) => {
		let element = document.getElementsByName(name)[0];
		element.focus();
	};

	const resetInputs = () => {
		setCode1({ valid: false, value: '' });
		setCode2({ valid: false, value: '' });
		setCode3({ valid: false, value: '' });
		setCode4({ valid: false, value: '' });
		setCode5({ valid: false, value: '' });
		setCode6({ valid: false, value: '' });
	};

	const validateNumber = (value: any) => {
		const esValido = isNumberValidate(value.target.value);
		if (esValido) {
			setAlert({
				show: false,
				message: '',
				severity: 'error',
			});
			let valString = value.target.value;

			if (valString.length > 1) {
				valString = valString.charAt(valString.length - 1);
			}

			if (value.target.name === 'code1') {
				setCode1({ valid: true, value: valString });
				setFocusElementByName('code2');
			}
			if (value.target.name === 'code2') {
				setCode2({ valid: true, value: valString });
				setFocusElementByName('code3');
			}
			if (value.target.name === 'code3') {
				setCode3({ valid: true, value: valString });
				setFocusElementByName('code4');
			}
			if (value.target.name === 'code4') {
				setCode4({ valid: true, value: valString });
				setFocusElementByName('code5');
			}
			if (value.target.name === 'code5') {
				setCode5({ valid: true, value: valString });
				setFocusElementByName('code6');
			}
			if (value.target.name === 'code6') {
				setCode6({ valid: true, value: valString });
			}
		} else {
			if (value.target.name === 'code1') {
				setCode1({ valid: false, value: '' });
			}
			if (value.target.name === 'code2') {
				setCode2({ valid: false, value: '' });
			}
			if (value.target.name === 'code3') {
				setCode3({ valid: false, value: '' });
			}
			if (value.target.name === 'code4') {
				setCode4({ valid: false, value: '' });
			}
			if (value.target.name === 'code5') {
				setCode5({ valid: false, value: '' });
			}
			if (value.target.name === 'code6') {
				setCode6({ valid: false, value: '' });
			}
		}
	};

	const disableBTN = () => {
		return (
			!code1.valid ||
			!code2.valid ||
			!code3.valid ||
			!code4.valid ||
			!code5.valid ||
			!code6.valid ||
			code1.value === '' ||
			code2.value === '' ||
			code3.value === '' ||
			code4.value === '' ||
			code5.value === '' ||
			code6.value === ''
		);
	};

	const sendEmailConfirmation = async (serv: string, desc: string) => {
		try {
			setLoading(true);
			setAlert({
				...alert,
				show: false,
			});

			const data = {
				servicio: serv,
				descripcion: desc,
			};
			const dataEncripted = await encrypt(
				JSON.stringify(data),
				user?.k || ''
			);

			const { result } = await nipInfonatelAPI.emailConfirmation({
				data: dataEncripted,
			});

			if (result.code === 'EM0000') {
				onContinue(
					code1.value +
					code2.value +
					code3.value +
					code4.value +
					code5.value +
					code6.value
				);
			} else {
				setAlert({
					show: true,
					message: result.message || INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
			}
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const unlokedNip = async () => {
		try {
			setLoading(true);
			setAlert({
				...alert,
				show: false,
			});

			const { result } = await nipInfonatelAPI.unlockNip();

			if (Number(result.contenido.code) === 0) {
				const serv = 'Desbloqueo de NIP';
				const desc =
					'Tu NIP Infonatel ha sido desbloqueado exitosamente, continúa disfrutando de los servicios que Mi Cuenta Infonavit tiene para ti.';
				//Ejecutar endpoint del email
				sendEmailConfirmation(serv, desc);
			} else {
				setAlert({
					show: true,
					message: result.message || INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
				setLoading(false);
			}
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const lokedNip = async () => {
		try {
			setLoading(true);
			setAlert({
				...alert,
				show: false,
			});

			const { result } = await nipInfonatelAPI.lockNip();

			if (Number(result.contenido.code) === 0) {
				const serv = 'Bloqueo de NIP';
				const desc =
					'Tu NIP Infonatel ha sido bloqueado exitosamente, continúa disfrutando de los servicios que Mi Cuenta Infonavit tiene para ti.';
				//Ejecutar endpoint del email
				sendEmailConfirmation(serv, desc);
			} else {
				setAlert({
					show: true,
					message: result.message || INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
				setLoading(false);
			}
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const cancelledNip = async () => {
		try {
			setLoading(true);
			setAlert({
				...alert,
				show: false,
			});

			const { result } = await nipInfonatelAPI.cancellNip();

			if (Number(result.contenido.code) === 0) {
				const serv = 'Cancelación de NIP';
				const desc =
					'Tu NIP Infonatel ha sido cancelado exitosamente, continúa disfrutando de los servicios que Mi Cuenta Infonavit tiene para ti.';
				//Ejecutar endpoint del email
				sendEmailConfirmation(serv, desc);
			} else {
				setAlert({
					show: true,
					message: result.message || INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
				setLoading(false);
			}
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const validateCode = async () => {
		try {
			setLoading(true);
			setAlert({
				...alert,
				show: false,
			});

			const data = {
				code:
					code1.value +
					code2.value +
					code3.value +
					code4.value +
					code5.value +
					code6.value,
			};

			const dataEncripted = await encrypt(
				JSON.stringify(data),
				user?.k || ''
			);

			const { result } = await nipInfonatelAPI.validCode({
				data: dataEncripted,
			});

			if (Number(result.code) === 0) {
				regBitacoraNotOld(nipInfonatelAPI.schemeUrl, data);
				//Si typeInformation es 5 crea el nip, si es igual a 3 lo cambia
				if (typeInformation === 1) {
					lokedNip();
				} else if (typeInformation === 2) {
					unlokedNip();
				} else if (typeInformation === 4) {
					cancelledNip();
				} else if (typeInformation === 5 || typeInformation === 3) {
					onContinue(
						code1.value +
						code2.value +
						code3.value +
						code4.value +
						code5.value +
						code6.value
					);
				}
			} else if (Number(result.code) === 1) {
				setAlert({
					show: true,
					message: result.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
				setLoading(false);
			}
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const sendCode = async () => {
		try {
			setLoading(true);
			setAlert({
				...alert,
				show: false,
			});

			if (isEmail) {
				const { result } = await nipInfonatelAPI.sendEmail();
				if (
					result.description ===
					'Se ha enviado correctamente el correo'
				) {
					resetInputs();
				} else {
					setAlert({
						show: true,
						message: result.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				}
			} else {
				const { result } = await nipInfonatelAPI.sendSMS();
				if (result.description === 'Mensaje enviado con exito') {
					resetInputs();
				} else {
					setAlert({
						show: true,
						message: result.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				}
			}
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleContinue = () => {
		validateCode();
	};

	const styles = useStyles();
	return (
		<>
			<ModalLoading loading={loading} />
			<br />
			<br />
			<Box className={styles.VerificacionLogo}>
				<img
					alt={props.isEmail ? 'ícono Email' : 'ícono movil'}
					src={props.isEmail ? mail : cel}
				/>
			</Box>
			<Box
				sx={{
					alignItems: 'center',
					justifyContent: 'center',
					display: 'flex',
					margin: '0 auto	 25px',
				}}
			>
				<p style={{ fontSize: '30px' }} className={`${styles.VerificacionCodigoTitle} ${styles.noMargin} ${styles.maxContent}`}>
					{props.isEmail === true && (
						<strong>Código de confirmación</strong>
					)}
					{props.isEmail === false && (
						<strong>Código de confirmación</strong>
					)}
				</p>
			</Box>
			{props.isEmail === false && (
				<Box
					sx={{
						alignItems: 'center',
						justifyContent: 'center',
						display: 'flex',
						width: '100%',
						maxWidth: '630px',
						margin: '0 auto 35px'
					}}
				>
					<p className={`${styles.VerificacionText} ${styles.noMargin}`}>
						<strong>
							Se envió un mensaje de texto al número de teléfono celular que tienes registrado.
						</strong>
						<br />
						Para {action} tu NIP Infonatel proporciona el código de
						confirmación que recibiste por mensaje de texto en tu celular.
						<br />
					</p>
				</Box>
			)}
			{props.isEmail === true && (
				<Box
					sx={{
						alignItems: 'center',
						justifyContent: 'center',
						display: 'flex',
						width: '100%',
						maxWidth: '630px',
						margin: '0 auto 35px'
					}}
				>
					<p className={`${styles.VerificacionText} ${styles.noMargin}`}>
						<strong>
							Se envió un correo electrónico con un código de confirmación
						</strong>{' '}
						para que puedas {action} tu NIP Infonatel.
						<br />
						<br />
						De no encontrar en la bandeja de entrada el correo, revisa tu carpeta de no deseados (SPAM).
						<br />
						<br />
						Para {action} tu NIP escribe el código que te hemos enviado.
					</p>
				</Box>
			)}
			<Box
				className={styles.divCodigos}
				sx={{
					display: 'flex',
				}}
			>
				<input
					id="code1"
					name="code1"
					pattern="[0-9]"
					className={styles.VerificacionCodigo}
					value={code1.value}
					onChange={validateNumber}
				/>
				<input
					id="code2"
					name="code2"
					pattern="[0-9]"
					className={styles.VerificacionCodigo}
					value={code2.value}
					onChange={validateNumber}
				/>
				<input
					id="code3"
					name="code3"
					pattern="[0-9]"
					className={styles.VerificacionCodigo}
					value={code3.value}
					onChange={validateNumber}
				/>
				<input
					id="code4"
					name="code4"
					pattern="[0-9]"
					className={styles.VerificacionCodigo}
					value={code4.value}
					onChange={validateNumber}
				/>
				<input
					id="code5"
					name="code5"
					pattern="[0-9]"
					className={styles.VerificacionCodigo}
					value={code5.value}
					onChange={validateNumber}
				/>
				<input
					id="code6"
					name="code6"
					pattern="[0-9]"
					className={styles.VerificacionCodigo}
					value={code6.value}
					onChange={validateNumber}
				/>
			</Box>
			<Box
				sx={{
					alignItems: 'center',
					justifyContent: 'center',
					marginBottom: '2%',
					display: 'flex',
				}}
			>
				<p className={`${styles.textGray14} ${styles.noMargin}`}>Válido por 4 horas </p>
			</Box>
			<Box
				sx={{
					alignItems: 'center',
					justifyContent: 'center',
					display: 'flex',
				}}
			>
				<p className={`${styles.link} ${styles.noMargin}`} onClick={() => sendCode()}>
					Volver a enviar
				</p>
			</Box>

			<Box className={styles.lookConfirmationAlert}>
				<div style={{ width: '100%' }}>
					<CustomAlert
						message={alert.message}
						severity={alert.severity}
						show={alert.show}
					/>
				</div>
			</Box>
			<Box className={styles.containerButtonsEnd}>
				<Box margin={'10px'} width={260}>
					<CustomButtom
						label={'Salir'}
						onClick={() => navigate('/')}
						variant="outlined"
						styles={{ height: '40px' }}
					/>
				</Box>
				<Box margin={'10px'} width={260}>
					<CustomButtom
						onClick={handleContinue}
						variant="solid"
						label="Continuar"
						disabled={disableBTN()}
					/>
				</Box>
			</Box>
		</>
	);
};

export default ActivationMedium;
