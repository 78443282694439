import { ListToHave } from '../interfaces';
import checkicon from '../../../assets/img/mini-check-azul.png';

export const LIST_TODO: ListToHave[] = [
	{
		id: 1,
		icon: checkicon,
		title: 'No tener un crédito del Infonavit vigente.',
	},
	{
		id: 2,
		icon: checkicon,
		title: 'Verificar con el banco que la cuenta bancaria acepte el monto a devolver.',
	},
	{
		id: 3,
		icon: checkicon,
		title: 'No haber iniciado un trámite de Devolución previo en la Afore o en el Infonavit.',
	},
	{
		id: 4,
		icon: checkicon,
		title: 'No deberán tener interpuesto un trámite legal en el Infonavit o Afore.',
	},
	{
		id: 5,
		icon: checkicon,
		title: 'Verificar que los datos personales (Nombre completo, CURP, RFC y NSS) que se encuentran en tu Resumen de Movimientos coincidan con los que se encuentran en el IMSS y tu Afore, así como con los que aparecen en tu estado de cuenta bancario e identificación oficial.',
	},
];
