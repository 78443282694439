import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

export const useStyles = makeStyles((theme: Theme) => ({
	// MAIN PAIGE CONTAINER
	paperContainer: {
		padding: '2vh 130px',
		[theme.breakpoints.down('sm')]: {
			padding: '2vh 20px',
		},
		'&>.location-form': {
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column',
			'&>*': {
				[theme.breakpoints.up('sm')]: {
					width: '300px! important',
				},
			},
		},
		'&>.actions-buttons': {
			display: 'flex',
			justifyContent: 'space-evenly',
			padding: '0 30px',
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column-reverse',
				alignItems: 'center',
				padding: '0',
			},
			'&>button': {
				[theme.breakpoints.up('sm')]: {
					width: 260,
					paddingTop: '7px',
					paddingBottom: '7px'
				},
			},
		},
	},
	accordion: {
		'&>.MuiAccordionSummary-root': {
			flexDirection: 'row-reverse',
			'&>.MuiAccordionSummary-expandIconWrapper': {
				marginRight: 10,
			},
		},
		'&>.MuiCollapse-root': {
			paddingLeft: 34,
		},
	},
	compareTableHeader: {
		...centeredItems,
		padding: 15,
		background: grey[300],
		'&>h4': {
			color: theme.palette.info.main,
			margin: 0,
		},
	},
	withoutBold: {
		margin: 0,
	},
	withBold: {
		margin: 0,
		fontWeight: 700,
	},
	noMargin: {
		marginTop: 0,
		marginBottom: 0
	},
	maxContent: {
		width: '100%',
		maxWidth: 'max-content'
	},
	marginCenter: {
		marginLeft: 'auto',
		marginRight: 'auto'
	},
	boxCustom: {
		marginTop: '-40px !important',
		[theme.breakpoints.down('md')]: {
			marginTop: '0px !important',
		},
	}
}));

export const outlineBorder = {
	border: `${grey[500]} solid 1.2px`,
	borderRadius: 3,
};

export const centeredItems = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};
