/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../store';
import { PROFILES, MESSAGES } from './utils/profilesData';
import { useStyles } from './styles';
import warning from '../../assets/img/icono-advertencia-rojo.png';
import MensajeServicios from '../../assets/img/mensaje-aviso-servicios-no-disponibles.jpg';

import { Typography } from '@mui/material';
import Layout from '../../components/Layout';
import ProfileCero from './views/ProfileCero';
import AccreditedHistory from './views/AccreditedCreditClose';
import AccreditedPaymentProblem from './views/AccreditedPaymentProblem';
import AccreditedCompliance from './views/AccreditedCompliance';
import AccreditedJudicialProcess from './views/AccreditedJudicialProcess';
import SaverCreditClose from './views/SaverCreditClose';
import SaverNewAffiliate from './views/SaverNewAffiliate';
import SaverUnemployed from './views/SaverUnemployed';
import SavernoNoPunctuation from './views/SavernoNoPunctuation';
import PensionerCreditClose from './views/PensionerCreditClose';
import PensionerFulfilledCredit from './views/PensionerFulfilledCredit';
import PensionerPaymentProblems from './views/PensionerPaymentProblems';
import PensionerJudicialProcess from './views/PensionerJudicialProcess';
import PensionerWithoutCredit from './views/PensionerWithoutCredit';
import AccreditedDemand from './views/AccreditedDemand';
import AccreditedWithCreditProcess from './views/AccreditedWithCreditProcess';
import PensionerWithEmployment from './views/PensionerWithEmployment';
import BaseModal from '../../components/Modal';
import CustomButton from '../../components/Button';

import { useDispatch } from 'react-redux';
import { actions } from '../../store/modules/session';
import { actions as creditActions } from '../../store/modules/credit';
import { useNavigate } from 'react-router-dom';

import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import { contactRequestAPI } from '../../api/modules/profile';

const UserProfile = () => {
	const { user, validateDatos, validatePensionado, validateCorreccion } = useSelector((state: RootState) => state.session);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const classes = useStyles();
	const [view] = useState(user?.idPerfil || '0');
	const [showModal, setShowModal] = useState({
		show: false,
		message: ''
	});
	const [showModalPensionado, setShowModalPensionado] = useState({
		show: false,
		message: ''
	});
	const views = {
		[PROFILES.PERFIL_CERO]: <ProfileCero profile={user?.idPerfil || '0'} />,
		[PROFILES.AHORRADOR_NUEVO_AFILIADO]: <SaverNewAffiliate />,
		[PROFILES.AHORRADOR_SIN_PUNTUACION]: <SavernoNoPunctuation />,
		[PROFILES.AHORRADOR_PERDIO_RELACION_LABORAL]: <SaverUnemployed />,
		[PROFILES.AHORRADOR_CREDITO_CERRADO]: <SaverCreditClose />,
		[PROFILES.PROSPECTO_PUNTUACION_REQUERIDA]: <ProfileCero profile={user?.idPerfil || '0'} />,
		[PROFILES.PROSPECTO_TRAMITE_CREDITO]: <AccreditedWithCreditProcess />,
		[PROFILES.PROSPECTO_CREDITO_CERRADO]: <ProfileCero profile={user?.idPerfil || '0'} />,
		[PROFILES.ACREDITADO_CUMPLIDO]: <AccreditedCompliance />,
		[PROFILES.ACREDITADO_PROBLEMAS_PAGO]: <AccreditedPaymentProblem />,
		[PROFILES.ACREDITADO_PROCESO_JUDICIAL]: <AccreditedJudicialProcess />,
		[PROFILES.ACREDITADO_DEMANDADO]: <AccreditedDemand />,
		[PROFILES.ACREDITADO_HISTORICO]: <AccreditedHistory />,
		[PROFILES.PENSIONADO_SIN_CREDITO]: <PensionerWithoutCredit />,
		[PROFILES.PENSIONADO_CREDITO_CUMPLIDO]: <PensionerFulfilledCredit />,
		[PROFILES.PENSIONADO_PROBLEMAS_PAGO]: <PensionerPaymentProblems />,
		[PROFILES.PENSIONADO_PROCESO_JUDICIAL]: <PensionerJudicialProcess />,
		[PROFILES.PENSIONADO_CREDITO_CERRADO]: <AccreditedDemand />,
		[PROFILES.PENSIONADO_HISTORICO]: <PensionerCreditClose />,
		[PROFILES.PENSIONADO_RELACION_LABORAL]: <PensionerWithEmployment />,
		[PROFILES.ACLARACIONES_NO_RFC]: <ProfileCero profile={user?.idPerfil || '0'} />,
		[PROFILES.ACLARACIONES_NO_CURP]: <ProfileCero profile={user?.idPerfil || '0'} />,
	};
	const [correction, setCorrection] = useState(false as any);
	const [modalUpdateDatos, setModalUpdateDatos] = useState(false);
	const [modalDateDisable, setModalDateDisable] = useState({
		show: false,
		message: MESSAGES.DATE_DISABLED,
		step: 0
	});

	/*const handleCorrectionRFC = async () => {
		let values = {
			nss: user?.nss,
			curp: user?.curp,
			rfc: user?.rfc,
			name: `${user?.nombres} ${user?.apPaterno}  ${user?.apMaterno}`,
			rfcCrm: user?.rfc
		};
		setShowModal({ show: false, message: '' });
		localStorage.setItem('dataUserRegister', JSON.stringify(values));
		dispatch(actions.signOut());
		dispatch(creditActions.clearCredit());
		setTimeout(() => {
			navigate('/correccion-rfc');
		}, 100)
	};*/

	const getStatusContacto = async () => {
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const paramsData = {
				nss: user?.nss.toString() || '',
			}
			const dataSendEncrypt = await encrypt(
				JSON.stringify(paramsData),
				key
			);
			contactRequestAPI.validateDatos
				.post<any,
					any
				>('', { data: dataSendEncrypt })
				.then((res) => {
					if (res && res?.result?.code === '0000') {
						if (res?.result?.dateDisabled) {
							setModalDateDisable({
								...modalDateDisable,
								show: true,
							})
						}
						if (res?.result?.data?.indicador) {
							setModalUpdateDatos(true)
						}
					}
					if (res?.dateDisabled) {
						setModalDateDisable({
							...modalDateDisable,
							show: true,
						});
					}

				})
				.catch(() => { })
				.finally(() => {
					dispatch(actions.changeValidateData(false));
				});
		} catch {

		}
	};

	useEffect(() => {
		if (validateDatos) {
			getStatusContacto();
		}
	}, [validateDatos])

	useEffect(() => {
		if (view === PROFILES.BENEFICIARIO_SIN_CREDITO) {
			setShowModal({ show: true, message: MESSAGES.BENEFICIARIO_SIN_CREDITO })
		} else if (view === PROFILES.BENEFICIARIO_CREDITO_VIGENTE) {
			setShowModal({ show: true, message: MESSAGES.BENEFICIARIO_CREDITO_VIGENTE })
		} else if (view === PROFILES.BENEFICIARIO_CREDITO_CERRADO) {
			setShowModal({ show: true, message: MESSAGES.BENEFICIARIO_CREDITO_CERRADO })
		} else if (view === PROFILES.ACLARACIONES_UNIFICACION_CUENTAS) {
			setShowModal({ show: true, message: MESSAGES.ACLARACIONES_UNIFICACION_CUENTAS })
		} else if (view === PROFILES.ACLARACIONES_SEPARACION_CUENTAS) {
			setShowModal({ show: true, message: MESSAGES.ACLARACIONES_SEPARACION_CUENTAS })
		} else if (view === PROFILES.ACLARACIONES_VINCULACION_NSS) {
			setShowModal({ show: true, message: MESSAGES.ACLARACIONES_VINCULACION_NSS })
		} else if (view === PROFILES.ACLARACIONES_NO_RFC && validateCorreccion) {
			setShowModal({ show: true, message: MESSAGES.ACLARACIONES_NO_RFC })
			setCorrection('rfc')
		} else if (view === PROFILES.ACLARACIONES_NO_CURP && validateCorreccion) {
			setShowModal({ show: true, message: MESSAGES.ACLARACIONES_NO_CURP })
			setCorrection('curp')
		} else {
			setShowModal({ show: false, message: '' });
		}
	}, [view, validateCorreccion]);

	useEffect(() => {
		if (validatePensionado) {
			if (view === PROFILES.PENSIONADO_SIN_CREDITO || view === PROFILES.PENSIONADO_CREDITO_CUMPLIDO || view === PROFILES.PENSIONADO_PROBLEMAS_PAGO) {
				setShowModalPensionado({ show: true, message: MESSAGES.PENSIONADO });
			}
			dispatch(actions.changeValidatePensionado(false));
		}
	}, [validatePensionado]);

	return (
		<Layout>
			<>
				<Typography variant="h6" className={classes.maxContent}>
					{`¡Hola ${user?.given_name
						? user?.given_name.split(' ')[0][0] +
						user?.given_name
							.split(' ')[0]
							.slice(1)
							.toLowerCase()
						: ''
						}, te damos la bienvenida!`}
				</Typography>
				<br />
				{views[view]}
				<BaseModal
					open={showModal.show}
					width={'sm'}
					hideCloseButton={true}
					onClose={() => {
						setShowModal({ show: false, message: '' });
						dispatch(actions.changeValidateCorreccion(false));
					}}
				>
					<div className={classes.containerModalInfo}>
						{correction === 'rfc' && (
							<>
								<p>
									El Registro Federal de Contribuyentes (RFC) que proporcionaste no
									corresponde al que tenemos registrado, por lo que debes actualizarlo.<br />
									Da clic para corregir tu RFC, en el caso de que no puedas realizar la
									actualización mediante la opción "Validación con información del SAT",
									deberás elegir la opción "Haz una cita" para acudir a un Centro de
									Servicio Infonavit.
								</p>
								<div className={classes.containerBtns}>
									<CustomButton
										data-testid="buttontwoAceptar"
										onClick={() => {
											setShowModal({ show: false, message: '' });
											dispatch(actions.changeValidateCorreccion(false));
										}}
										variant="outlined"
										styles={{ marginBottom: 20, width: 260 }}
										label="Salir"
									/>
									<CustomButton
										data-testid="buttonRFC"
										onClick={() => {
											setShowModal({ show: false, message: '' });
											dispatch(actions.changeValidateCorreccion(false));
											navigate('/mi-perfil/correccion-rfc');
										}}
										variant="solid"
										styles={{ marginBottom: 20, width: 260 }}
										label="Corregir tu RFC"
									/>
								</div>
							</>

						)}

						{correction === 'curp' && (
							<>
								<p>
									La Clave Única de Registro de Población (CURP) que proporcionaste no
									coincide con la registrada en nuestras bases de datos, verifica que
									esté escrita correctamente.<br />
									Da clic para corregir tu CURP, si no puedes realizar la corrección
									por este medio, acude a un Centro de Servicio Infonavit. Es importante
									que también verifiques la CURP registrada en tu AFORE, y si no es
									correcta, acude a esta y solicita la corrección.
								</p>
								<div className={classes.containerBtns}>
									<CustomButton
										data-testid="buttontwoAceptar"
										onClick={() => {
											setShowModal({ show: false, message: '' });
											dispatch(actions.changeValidateCorreccion(false));
										}}
										variant="outlined"
										styles={{ marginBottom: 20, width: 260 }}
										label="Salir"
									/>
									<CustomButton
										data-testid="buttonRFC"
										onClick={() => {
											setShowModal({ show: false, message: '' });
											dispatch(actions.changeValidateCorreccion(false));
											navigate('/mi-perfil/correccion-curp-nombre');
										}}
										variant="solid"
										styles={{ marginBottom: 20, width: 260 }}
										label="Corregir tu CURP"
									/>
								</div>
							</>
						)}

						{!correction && (
							<>
								<p dangerouslySetInnerHTML={{ __html: showModal.message }}></p>
								<div className={classes.containerBtnAcceptModal}>
									<CustomButton
										data-testid="buttontwoAceptar"
										onClick={() => {
											setShowModal({ show: false, message: '' });
											dispatch(actions.signOut());
											dispatch(creditActions.clearCredit());
											setTimeout(() => {
												user?.isImpersonalized ? window.location.href = 'https://micuenta.infonavit.org.mx/' : navigate('/')
											}, 100)
										}}
										variant="solid"
										styles={{ marginBottom: 20 }}
										label="Aceptar"
									/>
								</div>
							</>
						)}
					</div>
				</BaseModal>

				<BaseModal
					open={modalUpdateDatos}
					width="sm"
					hideCloseButton
					onClose={() => navigate(`/mi-perfil/actualizar-datos-contacto/${true}`)}
				>
					<div className={classes.containerModalInfo}>

						{modalDateDisable.step === 0 && (
							<>
								<div style={{ textAlign: 'center' }}>
									<img src={warning} alt="ícono advertencia" />
								</div>
								<p>
									Para garantizar nuestra comunicación contigo, debes actualizar tus
									datos de contacto. Confirma que tu información esté correcta en la
									sección de datos de contacto, de lo contrario, corrígela.
								</p>
								<a
									href={require('../../assets/files/aviso-de-privacidad.pdf')}
									style={{ textDecoration: 'none' }}
									download="aviso-de-privacidad.pdf"
									className={classes.txtRed}
								>
									<span style={{ color: 'rgba(0, 0, 0, 0.87)', fontWeight: 'normal' }}>
										Para más información, consulta nuestro
									</span>{' '}
									aviso de privacidad
								</a>
							</>
						)}

						{modalDateDisable.step === 1 && (
							<img src={MensajeServicios} alt={modalDateDisable.message} style={{ width: '100%', marginBottom: 20 }} />
						)}

						<div className={classes.containerBtnAcceptModal}>
							<CustomButton
								onClick={() => {
									if (modalDateDisable.show) {
										setModalDateDisable({ ...modalDateDisable, show: false, step: 1 });
									} else {
										navigate(`/mi-perfil/actualizar-datos-contacto/${true}`);
									}
								}}
								variant="solid"
								styles={{ marginBottom: modalDateDisable.step === 1 ? 0 : 20, width: 260 }}
								label={modalDateDisable.step === 1 || modalDateDisable.show ? 'Aceptar' : 'Continuar'}
								data-testid={!modalDateDisable.show ? 'buttonRFC' : undefined}
							/>
						</div>
					</div>
				</BaseModal>

				<BaseModal
					open={modalDateDisable.show && !modalUpdateDatos}
					width={'sm'}
					hideCloseButton={true}
					onClose={() => {
						setModalDateDisable({
							...modalDateDisable,
							show: false
						});
					}}
				>
					<div className={classes.containerModalInfo}>
						<>
							<img src={MensajeServicios} alt={modalDateDisable.message} style={{ width: '100%', marginBottom: 20 }} />
							<div className={classes.containerBtnAcceptModal}>
								<CustomButton
									onClick={() => {
										setModalDateDisable({
											...modalDateDisable,
											show: false
										});
									}}
									variant="solid"
									styles={{ marginBottom: 0, width: 260 }}
									label="Aceptar"
								/>
							</div>
						</>
					</div>
				</BaseModal>

				<BaseModal
					open={showModalPensionado.show}
					width={'sm'}
					hideCloseButton={true}
					onClose={() => {
						setShowModalPensionado({ show: false, message: '' });
					}}
				>
					<div className={classes.containerModalInfo}>
						<>
							<h2
								style={{
									color: '#293990',
									fontSize: 26,
									display: 'flex',
									textAlign: 'center',
									justifyContent: 'center',
									margin: '0px 0px 10px 0px',
								}}
							>
								¡Importante!
							</h2>
							<p dangerouslySetInnerHTML={{ __html: showModalPensionado.message }}></p>
							<div className={classes.containerBtnAcceptModal}>
								<CustomButton
									onClick={() => {
										setShowModalPensionado({ show: false, message: '' });
									}}
									variant="solid"
									styles={{ marginBottom: 20 }}
									label="Salir"
								/>
							</div>
						</>
					</div>
				</BaseModal>
			</>
		</Layout>
	);
};

export default UserProfile;
