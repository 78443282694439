import { useState } from 'react';
import { Divider, Grid, Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import IconDes from '../../assets/img/icono-descarga.png';
import { RoundBtn } from './styles';
import BaseModal from '../../components/ModalConfirmation';
import { getFormatDateShort, getFormatHour } from '../../utils/dates';
import TextInput from '../../components/TextInput';
import { useStyles } from './styles';

interface IDataConfirm {
	appointmentCode: string;
	appointmentEndDate: string;
	centroAtencionDes: string;
	centroAtencionId: string;
	centroAtencionLocation: string;
	clientNSS: string;
	creationDate: Date;
	customField1: number;
	date: Date;
	day: string;
	hour: string;
	id: string;
	minute: string;
	serviceDes: string;
	serviceId: string;
	stateId: string;
	organizationLocation: string;
}

interface Props {
	setPage: (data: number) => void;
	setTabAct: (data: number) => void;
	dataConfirm: IDataConfirm;
	motivo: any;
	handleCancelar: (pAppoimentId: string) => void;
	setMotivo: (motivo: any) => void;
	setReprogramar: any;
	downloadPDF: (value: IDataConfirm, bash: any) => void;
	doctosRequeridos?: string;
}

const TicketAppointment = ({
	setPage,
	setTabAct,
	dataConfirm,
	handleCancelar,
	motivo,
	setMotivo,
	setReprogramar,
	downloadPDF,
	doctosRequeridos,
}: Props) => {
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [modalCancelar, setModalCancelar] = useState(false);


	return (
		<Paper
			sx={{
				pt: matches ? 5 : 2,
				pb: matches ? 5 : 5,
				pl: matches ? 3 : 3,
				pr: matches ? 3 : 3,
			}}
		>
			<Grid container columns={12}>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<h3
						style={{
							color: '#293990',
							fontSize: 28,
							textAlign: 'center',
							paddingBottom: 30,
						}}
						className={`${classes.maxContent} ${classes.marginCenter}`}
					>
						Cita confirmada{' '}
					</h3>
				</Grid>
				{/* <Grid item xs={12} sm={12} md={3} lg={3}>
					<h3
						style={{
							fontSize: matches ? 14 : 16,
							marginBottom: matches ? 0 : 16,
							color: '#000000',
						}}
					>
						Número de caso
					</h3>
				</Grid>
				<Grid item xs={12} sm={12} md={9} lg={9}>
					<p
						style={{
							fontSize: matches ? 14 : 16,
							marginTop: matches ? 0 : 16,
							color: '#000000',
						}}
					>
						{dataCaso?.caso}
					</p>
				</Grid> */}
				{/* <Grid item xs={12} sm={12} md={12} lg={12}>
					<Divider />
				</Grid> */}
				<Grid item xs={12} sm={12} md={3} lg={3}>
					<h3
						style={{
							fontSize: matches ? 14 : 16,
							marginBottom: matches ? 0 : 16,
							color: '#000000',
						}}
						className={classes.maxContent}
					>
						Fecha de solicitud
					</h3>
				</Grid>
				<Grid item xs={12} sm={12} md={9} lg={9}>
					<p
						style={{
							fontSize: matches ? 14 : 16,
							marginTop: matches ? 0 : 16,
							color: '#000000',
						}}
						className={classes.maxContent}
					>
						{getFormatDateShort(dataConfirm?.creationDate)}
					</p>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Divider />
				</Grid>
				<Grid item xs={12} sm={12} md={3} lg={3}>
					<h3
						style={{
							fontSize: matches ? 14 : 16,
							marginBottom: matches ? 0 : 16,
							color: '#000000',
						}}
						className={classes.maxContent}
					>
						Servicio
					</h3>
				</Grid>
				<Grid item xs={12} sm={12} md={9} lg={9}>
					<p
						style={{
							fontSize: matches ? 14 : 16,
							marginTop: matches ? 0 : 16,
							color: '#000000',
						}}
						className={classes.maxContent}
					>
						{dataConfirm.serviceDes}
					</p>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Divider />
				</Grid>
				{/* <Grid item xs={12} sm={12} md={3} lg={3}>
					<h3
						style={{
							fontSize: matches ? 14 : 16,
							marginBottom: matches ? 0 : 16,
							color: '#000000',
						}}
					>
						Estatus
					</h3>
				</Grid>
				<Grid item xs={12} sm={12} md={9} lg={9}>
					<p
						style={{
							fontSize: matches ? 14 : 16,
							marginTop: matches ? 0 : 16,
							color: '#000000',
						}}
					>
						{dataCaso?.textoStatus}
					</p>
				</Grid> */}
				{/* <Grid item xs={12} sm={12} md={12} lg={12}>
					<Divider />
				</Grid> */}
				{/* <Grid item xs={12} sm={12} md={3} lg={3}>
					<h3
						style={{
							fontSize: matches ? 14 : 16,
							marginBottom: matches ? 0 : 16,
							color: '#000000',
						}}
					>
						Fecha de estatus
					</h3>
				</Grid>
				<Grid item xs={12} sm={12} md={9} lg={9}>
					<p
						style={{
							fontSize: matches ? 14 : 16,
							marginTop: matches ? 0 : 16,
							color: '#000000',
						}}
					>
						{formatStringToDate(dataCaso?.fechaModificacion) || 'N/A'}
					</p>
				</Grid> */}
				{/* <Grid item xs={12} sm={12} md={12} lg={12}>
					<Divider />
				</Grid> */}
				{/* <Grid item xs={12} sm={12} md={3} lg={3}>
					<h3
						style={{
							fontSize: matches ? 14 : 16,
							marginBottom: matches ? 0 : 16,
							color: '#000000',
						}}
					>
						Descripción
					</h3>
				</Grid>
				<Grid item xs={12} sm={12} md={9} lg={9} sx={{ pr: 15 }}>
					<p
						style={{
							fontSize: matches ? 14 : 16,
							marginTop: matches ? 0 : 16,
							lineHeight: 1.3,
							color: '#000000',
						}}
					>
						Tu cita <label style={{ fontWeight: 'bold' }}>es personal </label>
						y cuando acudas a ella debes presentar tu{' '}
						<label style={{ fontWeight: 'bold' }}>
							Identificación oficial vigente
						</label>
						, requisito indispensable para efectuar tu trámite. Te
						recomendamos que llegues con{' '}
						<label style={{ fontWeight: 'bold' }}>
							10 minutos de anticipación{' '}
						</label>
						para que no corras el riesgo de perder tu cita y tengas que
						programar una nueva. Si por alguna razón no puedes acudir,
						reprográmala o cancélala.
					</p>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Divider />
				</Grid> */}
				<Grid item xs={12} sm={12} md={3} lg={3}>
					<h3
						style={{
							fontSize: matches ? 14 : 16,
							marginBottom: matches ? 0 : 16,
							color: '#000000',
						}}
						className={classes.maxContent}
					>
						Código de cita
					</h3>
				</Grid>
				<Grid item xs={12} sm={12} md={9} lg={9}>
					<p
						style={{
							fontSize: matches ? 14 : 16,
							marginTop: matches ? 0 : 16,
							color: '#000000',
						}}
						className={classes.maxContent}
					>
						{dataConfirm.appointmentCode}
					</p>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Divider />
				</Grid>
			</Grid>
			<Grid container columns={12}>
				<Grid item xs={12} sm={12} md={3} lg={3}>
					<h3 style={{ fontSize: matches ? 14 : 16, color: '#000000' }} className={classes.maxContent}>
						Documentos necesarios
					</h3>
				</Grid>

				<Grid item xs={12} sm={12} md={9} lg={9} sx={{ pr: 15 }}>
					<p
						dangerouslySetInnerHTML={{
							__html: doctosRequeridos || '',
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Divider />
				</Grid>
				<Grid item xs={12} sm={12} md={3} lg={3}>
					<h3
						style={{
							fontSize: matches ? 14 : 16,
							marginBottom: matches ? 0 : 16,
							color: '#000000',
						}}
						className={classes.maxContent}
					>
						Fecha de cita
					</h3>
				</Grid>
				<Grid item xs={12} sm={12} md={9} lg={9}>
					<p
						style={{
							fontSize: matches ? 14 : 16,
							marginTop: matches ? 0 : 16,
							color: '#000000',
						}}
						className={classes.maxContent}
					>
						{getFormatDateShort(dataConfirm?.date)}
					</p>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Divider />
				</Grid>
				<Grid item xs={12} sm={12} md={3} lg={3}>
					<h3
						style={{
							fontSize: matches ? 14 : 16,
							marginBottom: matches ? 0 : 16,
							color: '#000000',
						}}
						className={classes.maxContent}
					>
						Horario de cita
					</h3>
				</Grid>
				<Grid item xs={12} sm={12} md={9} lg={9}>
					<p
						style={{
							fontSize: matches ? 14 : 16,
							marginTop: matches ? 0 : 16,
							color: '#000000',
						}}
						className={classes.maxContent}
					>
						{getFormatHour(dataConfirm?.date || undefined)}
					</p>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Divider />
				</Grid>
				<Grid item xs={12} sm={12} md={3} lg={3}>
					<h3
						style={{
							fontSize: matches ? 14 : 16,
							marginBottom: matches ? 0 : 16,
							color: '#000000',
						}}
						className={classes.maxContent}
					>
						Ubicación
					</h3>
				</Grid>
				<Grid item xs={12} sm={12} md={9} lg={4} sx={{ pr: 5 }}>
					<p
						style={{
							fontSize: matches ? 14 : 16,
							marginTop: matches ? 0 : 16,
							color: '#000000',
							lineHeight: 1.3,
						}}
						className={classes.maxContent}
					>
						{dataConfirm.organizationLocation}
					</p>
				</Grid>
				<Grid item xs={12} sm={12} md={9} lg={5} sx={{ pt: 2, pb: 4 }}></Grid>
				<Grid item xs={12} sm={12} md={12} lg={12} sx={{ pb: 4 }}>
					<p
						style={{
							fontSize: matches ? 14 : 16,
							marginTop: matches ? 0 : 16,
							textAlign: 'center',
							color: '#000000',
						}}
						className={`${classes.maxContent} ${classes.marginCenter}`}
					>
						Se ha enviado esta información a tu correo.
					</p>

					<div
						style={{
							width: 'max-content',
							margin: '0 auto',
							fontSize: matches ? 14 : 16,
							marginTop: matches ? 0 : 5,
							color: '#D1001F',
							textAlign: 'center',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							cursor: 'pointer',
						}}
						onClick={() => {
							downloadPDF(dataConfirm, '');
						}}
					>
						<span>Descargar comprobante</span>
						<div>
							<img src={IconDes} style={{ marginLeft: 5 }} alt="Descarga" />
						</div>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<div
						style={{
							textAlign: 'center',
							margin: '20px 0px',
							display: 'flex',
							flexDirection: matches ? 'column-reverse' : 'inherit',
							justifyContent: 'center',
						}}
					>
						<div>
							<RoundBtn
								style={{
									width: '260px',
									height: '40px',
								}}
								disableRipple={true}
								onClick={() => setModalCancelar(true)}
							>
								Cancelar cita
							</RoundBtn>
						</div>
						{/* <div
							style={{
								width: matches ? '100%' : 250,
								display: 'inline-flex',
							}}
						>
							<RoundBtn
								variant="outlined"
								onClick={() => {
									setModal(true);
								}}
							>
								Reprogramar
							</RoundBtn>
						</div> */}
						<div>
							<RoundBtn
								variant="contained"
								onClick={() => {
									setTabAct(1);
									setPage(0);
								}}
								style={{
									width: '260px',
									height: '40px',
								}}
								disableRipple={true}
							>
								Finalizar
							</RoundBtn>
						</div>
					</div>
				</Grid>
			</Grid>
			{/* <BaseModal
				open={modal}
				title="S"
				onConfirm={() => {
					setReprogramar(true);
					setPage(1);
					setModal(false);
				}}
				onClose={() => setModal(false)}
				cancelButton={true}
				confirmLabel="Sí"
				cancelLabel="No"
				width="sm"
				children={
					<div
						style={{
							paddingLeft: 40,
							paddingRight: 40,
							paddingTop: 30,
							paddingBottom: 10,
						}}
					>
						<h3
							style={{
								color: '#293990',
								fontSize: 26,
								display: 'flex',
								textAlign: 'center',
								justifyContent: 'center',
								margin: 0,
							}}
						>
							¿Estás seguro que quieres reprogramar tu cita?
						</h3>
					</div>
				}
			/> */}
			<BaseModal
				open={modalCancelar}
				title="S"
				disableOnConfirm={!motivo || motivo.length === 0 ? true : false}
				onConfirm={() => {
					handleCancelar(dataConfirm.id);
					setModalCancelar(false);
				}}
				onClose={() => {
					setModalCancelar(false)
					setMotivo('')
				}}
				cancelButton={true}
				confirmLabel="Sí"
				cancelLabel="No"
				width="sm"
				children={
					<div
						style={{
							paddingLeft: 40,
							paddingRight: 40,
							paddingTop: 30,
							paddingBottom: 10,
						}}
					>
						<h3
							style={{
								color: '#293990',
								fontSize: 18,
								display: 'flex',
								textAlign: 'center',
								justifyContent: 'center',
								margin: 0,
							}}
						>
							¿Estas seguro que quieres cancelar esta cita?
						</h3>
						<TextInput
							id="motivo"
							name="motivo"
							value={motivo}
							placeholder="Escribe el motivo"
							onChange={(e: any) => {
								if (e.target.value.length > 50) {
									return;
								} else {
									setMotivo(e.target.value);
								}
							}}
						/>
					</div>
				}
			/>
		</Paper>
	);
};

export default TicketAppointment;
