import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Margin } from '@mui/icons-material';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		backgroundColor: '#F8F8F8',
	},
	contConfirm: {
		textAlign: 'center',
		paddingTop: 15,
		[theme.breakpoints.down('sm')]: {
			paddingTop: 0,
		},
	},
	subContainer: {
		width: '100%',
		backgroundColor: '#FFFFFF',
		borderRadius: 8,
		boxShadow: '0px 5px 30px #00000017',
		marginTop: 15,
		padding: 10,
		[theme.breakpoints.down('md')]: {
			overflow: 'auto',
			padding: 20,
			paddingTop: 20,
			paddingBottom: 20,
			borderRadius: 2,
		},
	},
	txtInicial: {
		color: '#293990',
		fontSize: 24,
		fontWeight: 'bold',
		[theme.breakpoints.down('sm')]: {
			fontSize: 20,
		},
	},
	iconsImg: {
		width: 20,
		marginRight: 5,
	},
	txtPrincipales: {
		color: '#333333',
		fontSize: '16px !important',
		verticalAlign: 'top',
		letterSpacing: 0,
		opacity: 1,
		[theme.breakpoints.down('sm')]: {
			fontSize: '14px !important',
		},
	},
	txtContainer: {
		color: '#000000',
		fontSize: 18,
		letterSpacing: 0,
		fontWeight: 700,
		[theme.breakpoints.down('sm')]: {
			fontSize: 16,
		},
	},
	txtInfo: {
		color: '#333333',
		fontSize: 16,
		textAlign: 'right',
		[theme.breakpoints.down('sm')]: {
			fontSize: 14,
		},
	},
	links: {
		color: '#D1001F',
		float: 'right',
		letterSpacing: 0,
		fontSize: 18,
		paddingTop: 15,
		paddingRight: 15,
		opacity: 1,
		cursor: 'pointer',
		[theme.breakpoints.down('sm')]: {
			fontSize: 16,
		},
	},
	inputs: {
		width: '80%',
		marginBottom: 20,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginBottom: 10,
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			marginBottom: 10,
		},
	},
	containerBtns: {
		display: 'flex',
		marginTop: '20px !important',
		marginBottom: '20px !important',
		[theme.breakpoints.down('lg')]: {
			width: '100%',
			maxWidth: '570px',
			margin: '20px auto'
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column-reverse !important',
		},
	},
	subContainerButtons: {
		width: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingRight: 5,
		paddingLeft: 5,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			paddingRight: 0,
			paddingLeft: 0,
		},
	},
	titleModalAlert: {
		textAlign: 'center',
	},
	maxContent: {
		maxWidth: 'max-content',
	}
}));
