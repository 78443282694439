import { useState } from 'react';
import { Divider } from '@mui/material';
// COMPONENTS
import CustomAlert from '../../../components/CustomAlert';
import CustomButton from '../../../components/Button';
// RESOURCES
import { IAlert } from '../../../interfaces/alert';
import { useStyles } from './styles';
import useCustomScript from '../../../hooks/useCustomScript';
import { getBase64File, getBinaryFile, IFiles2 } from '../utils';
import { apiCancelledPayments } from '../../../api/modules/CancelledPayments';
import { createObjectEncript } from '../../../utils/encrypt';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useBitacora } from '../../../hooks/useBitacora';

interface Props {
	setPage: (data: number) => void;
	onContinue: any;
	setLoading: (arg: boolean) => void;
}

declare global {
	interface Window {
		validarFiel: any;
	}
}

const LayoutNotice = ({ setPage, onContinue, setLoading }: Props) => {
	const { regBitacoraNotOld } = useBitacora();
	const classes = useStyles();
	const { user } = useSelector((state: RootState) => state.session);
	const validarFiel = window.validarFiel;
	// const serverIP = 'https://serviciosweb.infonavit.org.mx:8993'; //QA
	// const serverIP = 'https://serviciosweb.infonavit.org.mx:8991/firma'; //prod
	const serverIP = process.env.REACT_APP_URL_VALIDA_FIEL;
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [files, setFiles] = useState<IFiles2>({
		file1: {
			name: 'No se eligió ningún archivo',
			data: null,
			binary: '',
			base64: '',
			type: '',
		},
		file2: {
			name: 'No se eligió ningún archivo',
			data: null,
			binary: '',
			type: '',
		},
	});
	const [password, setPassword] = useState('');

	useCustomScript('/jquery/jquery.min.js');
	useCustomScript('/efirma/firma-documento.js');
	useCustomScript('/efirma/fiel-validacion.ofu.min.js');

	const handleFiles = async (e: any) => {
		const file = e.target.files[0];

		if (e.target.name === 'file1') {
			const base64 = await getBase64File(file);
			const binary = await getBinaryFile(file);
			setFiles({
				...files,
				file1: {
					name: file.name,
					data: file,
					type: file.type,
					base64,
					binary,
				},
			});
			return;
		}

		if (e.target.name === 'file2') {
			const binary = await getBinaryFile(file);
			setFiles({
				...files,
				file2: {
					name: file.name,
					data: file,
					type: file.type,
					binary,
				},
			});
			return;
		}
	};

	const validarCertificado = async (rfcSat: string, nombreSat: string) => {
		try {
			const data = await createObjectEncript({
				nss: user?.nss.toString(),
				rfc: rfcSat,
				nombre: nombreSat,
			});
			const { result } = await apiCancelledPayments.validEfirma(data);
			const { valid, description } = result;
			if (valid) {
				regBitacoraNotOld(apiCancelledPayments.schemeUrl, {
					nss: user?.nss.toString(),
					rfc: rfcSat,
					nombre: nombreSat,
				});
				onContinue();
				return;
			}
			setAlert({
				show: true,
				message:
					description ||
					'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'error',
			});
		} catch (error: any) {
			setAlert({
				show: true,
				message:
					error ||
					'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleSubmitFile = () => {
		setLoading(true);
		const inputs = {
			keyBinary: files.file2.binary,
			cerBinary: files.file1.binary,
			cerB64: files.file1.base64,
			password: password,
			serverIP: serverIP,
			pdfB64: '',
			pdfContentType: 'application/pdf',
		};
		validarFiel(
			inputs,
			(
				error: string,
				response: {
					valido: boolean;
					rfc: string;
					nombre: string;
					mensajeValidacion: string;
					fechaVigenciaInicio: string;
					fechaVigenciaFin: string;
				}
			) => {
				if (error === null) {
					if (response.valido === true) {
						validarCertificado(response.rfc, response.nombre);
					} else {
						setLoading(false);
						setAlert({
							show: true,
							message:
								response.mensajeValidacion ||
								'Por el momento el servicio no se encuentra disponible, intenta más tarde',
							severity: 'error',
						});
					}
				} else {
					setLoading(false);
					setAlert({
						show: true,
						message:
							error ||
							'Por el momento el servicio no se encuentra disponible, intenta más tarde',
						severity: 'error',
					});
				}
			}
		);
	};

	return (
		<>
			<div className={classes.containerFirma}>
				<div>
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<p className={classes.contentFileDataTitle}>
									Certificado (.cer)
									<span style={{ color: '#DD0528' }}>*</span>
								</p>
								<p
									className={classes.contentFileDataText}
									style={{
										color:
											files.file1.name ===
												'No se eligió ningún archivo'
												? '#7F7F7F'
												: 'black',
									}}
								>
									{files.file1.name}
								</p>
							</div>
							<div className={classes.contentFileDataLink}>
								<label style={{ cursor: 'pointer' }}>
									<input
										type="file"
										accept=".cer"
										name="file1"
										onChange={handleFiles}
										style={{ display: 'none' }}
									/>
									Elegir archivo
								</label>
							</div>
						</div>
					</div>
					<Divider style={{ marginBlock: 20 }} />
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<p className={classes.contentFileDataTitle}>
									Clave privada (.key)
									<span style={{ color: '#DD0528' }}>*</span>
								</p>
								<p
									className={classes.contentFileDataText}
									style={{
										color:
											files.file2.name ===
												'No se eligió ningún archivo'
												? '#7F7F7F'
												: 'black',
									}}
								>
									{files.file2.name}
								</p>
							</div>
							<div className={classes.contentFileDataLink}>
								<label style={{ cursor: 'pointer' }}>
									<input
										type="file"
										accept=".key"
										name="file2"
										onChange={handleFiles}
										style={{ display: 'none' }}
									/>
									Elegir archivo
								</label>
							</div>
						</div>
					</div>
					<Divider style={{ marginBlock: 20 }} />
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<p className={classes.contentFileDataTitle}>
									Contraseña de clave privada
									<span style={{ color: '#DD0528' }}>*</span>
								</p>
							</div>
						</div>
						<input
							className={classes.contentFileDataInput}
							type="password"
							name="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</div>

					<CustomAlert
						message={alert.message}
						show={alert.show}
						severity={alert.severity}
					/>

					<div className={classes.contentFileDataButtons}>
						<CustomButton
							onClick={() => {
								setPage(0);
							}}
							variant="outlined"
							styles={{
								marginTop: 80,
								width: 200,
								marginInline: 10,
								height: 40
							}}
							label="Regresar"
						/>
						<CustomButton
							onClick={() => {
								handleSubmitFile();
							}}
							variant="solid"
							disabled={
								files.file1.data === null ||
									files.file2.data === null ||
									password === ''
									? true
									: false
							}
							styles={{
								marginTop: 80,
								width: 200,
								marginInline: 10,
							}}
							label="Continuar"
						/>
					</div>
					<Divider style={{ marginBlock: 20 }} />
					<h3 className={classes.principalTitle}>
						*Datos obligatorios
					</h3>
				</div>
			</div>
		</>
	);
};

export default LayoutNotice;
