import { Fragment } from 'react';
import styles from '../styles/styles.module.css';
import CheckIcon from '@mui/icons-material/Check';
import { useEscrituras } from '../../hooks/useEscrituras';
import { ESTATUS } from '../utils/stepsStatus';
import { CODE_STATUS } from '../utils';

const StepCustom = () => {
	const { escrituras, getSubStatus } = useEscrituras();
	const stepActive = ESTATUS[escrituras?.estatus]?.position || 0;
	const stepSubActive = getSubStatus();

	return (
		<div className={styles.container}>
			{/* Se imprime solo si tiene mas del primer detalle */}
			{stepActive > 2 && (
				<>
					<aside className={styles.asider}>
						{stepActive > 1 && (
							<span className={styles.dontPoint}></span>
						)}
						{stepActive > 0 ? (
							<section className={styles.lineStep}></section>
						) : (
							<section className={styles.lineStepNot}></section>
						)}
					</aside>
					<article className={styles.article}>
						<p className={styles.textLine}>Descripción</p>
						<div></div>
					</article>
				</>
			)}

			{/* Se imprimen los demas detalles */}
			{Object.keys(ESTATUS)
				.filter((key) => {
					const valid = ESTATUS[key].tantos.includes(
						escrituras.noTantos ?? 0
					);
					if (valid) {
						if (
							![
								CODE_STATUS.REGULARICACION_ADMINISTRATIVA,
								CODE_STATUS.REGULARICACION_JUDICIAL,
							].includes(key)
						) {
							return true;
						}
						if (
							key === CODE_STATUS.REGULARICACION_ADMINISTRATIVA &&
							escrituras.fechaRegAdministrativa
						) {
							return true;
						}
						if (
							key === CODE_STATUS.REGULARICACION_JUDICIAL &&
							escrituras.fechaRegJudical
						) {
							return true;
						}
						return false;
					}
					return false;
				})
				.map(
					(key, k) =>
						ESTATUS[key].position <= stepActive && (
							<Fragment key={k}>
								<aside className={styles.asider}>
									<div>
										<label
											className={
												ESTATUS[key].position ===
												stepActive
													? styles.asiderActive
													: undefined
											}
										>
											<CheckIcon
												className={
													ESTATUS[key].position ===
													stepActive
														? styles.iconoCheckActive
														: styles.iconoCheck
												}
												sx={{
													fontSize: 10,
													fontWeight: 600,
												}}
											/>
										</label>
									</div>

									{ESTATUS[key].position < stepActive ? (
										<section
											className={styles.lineStep}
										></section>
									) : (
										<section
											className={styles.lineStepNot}
										></section>
									)}
								</aside>
								<article className={styles.article}>
									<header
										className={
											ESTATUS[key].position === stepActive
												? styles.headerActive
												: undefined
										}
									>
										{ESTATUS[key].label}
									</header>
									<div
										className={
											ESTATUS[key].position === stepActive
												? styles.contentActive
												: undefined
										}
									>
										{ESTATUS[key].description}
									</div>

									{/* ------------------------ Se desglosa los subEstatus ---------------------------- */}
									{ESTATUS[key]?.subEstatus &&
										ESTATUS[key].subEstatus
											?.filter(
												(f) =>
													(f.position <=
														stepSubActive &&
														key ===
															escrituras?.estatus) ||
													key !== escrituras?.estatus
											)
											.map((stepSub, j) => (
												<div
													key={j}
													className={styles.stepSub}
												>
													<header
														className={
															stepSub.position ===
																stepSubActive &&
															key ===
																escrituras?.estatus
																? styles.headerActive
																: undefined
														}
													>
														{stepSub.label}
													</header>
													<div
														className={
															stepSub.position ===
																stepSubActive &&
															key ===
																escrituras?.estatus
																? styles.contentActive
																: undefined
														}
													>
														{stepSub.description}
													</div>
												</div>
											))}
								</article>
							</Fragment>
						)
				)}
		</div>
	);
};

export default StepCustom;
