import { Grid, Box } from '@mui/material';
import TextInput from '../../../components/TextInput';
import CustomButton from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import { REQUEST_PAGES_STATUS } from './utils/pagesStatus';
import { useStyles } from './styles/form-edition-styles';
import { IFormGeneral } from './interface';

interface IProps {
	setPage: (arg: number) => void;
	formCreate: IFormGeneral;
	setFormCreate: (arg: IFormGeneral) => void;
}

const FormEdition = ({ setPage, formCreate, setFormCreate }: IProps) => {
	const navigate = useNavigate();
	const styles = useStyles();

	const nextProcess = () => {
		setPage(REQUEST_PAGES_STATUS.SELECTION_BRANCH);
	};

	const validEdition = (): boolean => {
		if (
			!formCreate?.colonia ||
			!formCreate?.calle ||
			!formCreate?.noExterior ||
			!formCreate?.municipio ||
			!formCreate?.delegacion
		) {
			return true;
		}
		if ((formCreate?.cp || '').length < 5) {
			return true;
		}
		return false;
	};

	return (
		<Box className={styles.boxMain}>
			<p className={styles.pBoldWouthColor}>
				Revisar la dirección del inmueble del que solicitas las
				escrituras, en caso de que contenga algún error puedes editarlo
			</p>
			<Grid container columns={12} className={styles.containerGrid}>
				<Grid item xs={12} md={4}>
					<Box className={styles.boxPadding}>
						<TextInput
							id="colonia"
							name="colonia"
							value={formCreate?.colonia || ''}
							onChange={({ target }) =>
								setFormCreate({
									...formCreate,
									[target.name]: target.value.replace(
										/[^a-zA-Z0-9ñÑ ]/g,
										''
									),
								})
							}
							label="Colonia"
							isRequired
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={4}>
					<Box className={styles.boxPadding}>
						<TextInput
							id="calle"
							name="calle"
							value={formCreate?.calle || ''}
							onChange={({ target }) =>
								setFormCreate({
									...formCreate,
									[target.name]: target.value.replace(
										/[^a-zA-Z0-9ñÑ ]/g,
										''
									),
								})
							}
							label="Calle"
							isRequired
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={2}>
					<Box className={styles.boxPadding}>
						<TextInput
							id="noExterior"
							name="noExterior"
							value={formCreate?.noExterior || ''}
							onChange={({ target }) =>
								setFormCreate({
									...formCreate,
									[target.name]: target.value.replace(
										/[^a-zA-Z0-9 ]/g,
										''
									),
								})
							}
							label="No. Exterior"
							isRequired
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={2}>
					<Box className={styles.boxPadding}>
						<TextInput
							id="noInterior"
							name="noInterior"
							value={formCreate?.noInterior || ''}
							onChange={({ target }) =>
								setFormCreate({
									...formCreate,
									[target.name]: target.value.replace(
										/[^a-zA-Z0-9 ]/g,
										''
									),
								})
							}
							label="No. Interior"
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={4}>
					<Box className={styles.boxPadding}>
						<TextInput
							id="cp"
							name="cp"
							value={formCreate?.cp || ''}
							onChange={({ target }) =>
								!isNaN(Number(target.value)) &&
								setFormCreate({
									...formCreate,
									[target.name]: target.value,
								})
							}
							label="C.P"
							maxLength={5}
							isRequired
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={4}>
					<Box className={styles.boxPadding}>
						<TextInput
							id="municipio"
							name="municipio"
							value={formCreate?.municipio || ''}
							onChange={({ target }) =>
								setFormCreate({
									...formCreate,
									[target.name]: target.value.replace(
										/[^a-zA-Z0-9ñÑ ]/g,
										''
									),
								})
							}
							label="Municipio"
							isRequired
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={4}>
					<Box className={styles.boxPadding}>
						<TextInput
							id="delegacion"
							name="delegacion"
							value={formCreate?.delegacion || ''}
							onChange={({ target }) =>
								setFormCreate({
									...formCreate,
									[target.name]: target.value.replace(
										/[^a-zA-Z0-9ñÑ ]/g,
										''
									),
								})
							}
							label="Delegación"
							isRequired
						/>
					</Box>
				</Grid>
			</Grid>

			<Box className={styles.boxButtons}>
				<Box className={styles.buttons}>
					<CustomButton
						label={'Salir'}
						variant="outlined"
						onClick={() => navigate('/')}
					/>
				</Box>
				<Box className={styles.buttons}>
					<CustomButton
						label={'Continuar'}
						variant="solid"
						onClick={nextProcess}
						disabled={validEdition()}
					/>
				</Box>
			</Box>
			<hr className={styles.divider} />
			<p className={styles.pBold}>*Datos obligatorios</p>
		</Box>
	);
};

export default FormEdition;
